import {object, string} from "yup";
import {translate} from "../../../i18n/i18n";

export type Material = {
    id: string,
    text: string,
}

export function EmptyMaterial() {
    let material: Material = {
        id: "",
        text: "",
    };
    return material;
}

export const materialSchema = object({
    id: string().required(translate('de', 'UI.validation.material.id.required', [])),
})