<script lang="ts">

    import {analyticNames} from "./stores";

    export let evaluation;

    let entries;

    $: if (evaluation) {
        entries = Object.entries(evaluation);
    }

</script>

<div class="evaluation">
    {#if (evaluation)}
        {#if (entries.length === 0)}
            /
        {:else if (entries.length === 1)}
            {entries[0][1].description}
        {:else}
            {#each entries as entry}
                {#if (entry[1].enumName != 'NOT_APPLICABLE')}
                    {$analyticNames[entry[0]]}: <span class="result">{entry[1].description}</span>;&nbsp;
                {/if}
            {/each}
        {/if}
    {/if}
</div>

<style lang="scss">
  @import "../../styles/global";

  .evaluation {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #000000;
    max-width: 345px;
  }

  .result {
    color: #58C079;
  }
</style>