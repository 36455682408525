<script lang="ts" context="module">
    export enum LabelPosition {
        RIGHT = "right",
        LEFT = "left"
    }
</script>

<script lang="ts">
    import {createEventDispatcher} from 'svelte';
    import {type FontProps, getFontPropsVarString} from "../types/fontProps";

    const dispatch = createEventDispatcher();

    export let label: string = "";
    export let labelFontProps: FontProps;

    /**
     * If the box is ticked or not.
     */
    export let checked: boolean = false;
    export let id: string;

    /**
     * Give the position of the label, at the moment we can chose between 'left' & 'right'. Defaults to right.
     */
    export let labelPosition: LabelPosition = LabelPosition.RIGHT;
    export let tagName: string = "";
    export let disabled: boolean = false;

    export let size: string = "24px";

    function onCheckBoxChange() { // in the parent, we can react to that method with on:checkBoxChange with our own parent function
        // trigger event when the checkbox changes -> can be necessary to react to that, as we want to journal agb acceptance
        dispatch('checkboxChange', {checked, id});
    }

    $: fontPopsVarString = getFontPropsVarString(labelFontProps, "cb");
</script>


<div class="checkbox-container" style={fontPopsVarString}>
    {#if label !== "" && labelPosition === LabelPosition.LEFT}
        <label for={id}>{@html label}</label>
    {/if}
    <input type="checkbox"
           class="checkbox-green"
           {id} {disabled}
           bind:checked
           name={tagName}
           on:change={onCheckBoxChange}
           style="width: {size}; height: {size}"/>
    {#if label !== "" && labelPosition === LabelPosition.RIGHT}
        <label for={id}>{@html label}</label>
    {/if}
</div>


<style lang="scss">
  @import "../../styles/global";

  .checkbox-container {
    @include flex-row(0.5em);
  }

  .checkbox-green {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 24px;
    height: 24px;
    background: $grey-200;
    border: 1px solid $grey-700;
    border-radius: 2px;
    transform: translateY(-0.075rem);
    display: grid;
    place-content: center;

    &:hover {
      border: 1px solid $grey-775;
    }

    &::before {
      content: "";
      width: 13px;
      height: 13px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      transform-origin: bottom left;
      background: url('@/icons/icon_checkmark.svg') no-repeat;
    }

    &:checked::before {
      transform: scale(1);
    }


    &:hover {
      cursor: pointer;
      border: 1px solid #C4C4C4;
    }

    &:disabled, &:disabled + label {
      --form-control-disabled: $bluish-grey-700;
      color: $bluish-grey-700;
      cursor: not-allowed;
    }
  }

  label {
    @include roboto-font(var(--cb-line-height, 1.875rem), var(--cb-font-weight, 400), var(--cb-font-size, 0.875rem), var(--cb-color, black));
    text-align: left;
  }

  span {
    @include roboto-font(30px, 600, 0.875rem, black);
    text-align: left;
    text-decoration: none;
  }

</style>

