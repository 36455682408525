<script lang="ts">
    import {fade} from "svelte/transition";
    import {t} from "../../i18n/i18n";
    import fileUploadAnimation from '@/animations/FileUploadAnimation.json';
    import {LottiePlayer} from "@lottiefiles/svelte-lottie-player";


</script>
<div class="blur-background-overlay" style="background-color: #F0F2F8" in:fade={{duration: 400}} out:fade={{duration:400}}>
    <div class="blur-background-white-box"></div>
    <div class="blur-background-overlay-content">
        <div class="content-container">
            <span>{@html $t('UI.pleaseWaitAMoment')}</span>
            <div id="fileUploadAnimation">
                <LottiePlayer
                    src={fileUploadAnimation}
                    renderer="svg"
                    loop autoplay />
            </div>
            <span>
                {@html $t('UI.FileUpload.animation.bottom')}
            </span>
        </div>
    </div>
</div>

<style lang="scss">
  @import "../../styles/animations";


  .invisible {
    display: none;
  }
</style>