import {t} from "../../i18n/i18n"
import {get} from 'svelte/store'
import dayjs from "dayjs";

export const inputFieldFormats = {
    EXPANDED: {id: "expanded", maxWidth: "104px"},
    NORMAL: {id: "normal", maxWidth: "70px"},
    COMPACT: {id: "compact", maxWidth: "45px"},
    FULL: {id: "full", maxWidth: '100%'},
    SEARCH: {id: "search", maxWidth: '255px'}
}

export function formatIsoDateString(isoDateString: string) {
    if (!isoDateString || isoDateString.length == 0) return ""
    return formatDate(new Date(isoDateString))
}

export function formatDate(date: Date) {
    if (!date) return "";
    let day = date.getDate();
    let month = date.getMonth();
    month = month + 1;
    if ((String(day)).length == 1) day = '0' + day;
    if ((String(month)).length == 1) month = '0' + month;

    return day + '.' + month + '.' + date.getFullYear()
}

export function formatNumber(number: string) {
    let parsed = parseFloat(number)
    if (isNaN(parsed)) {
        return "&nbsp;-&nbsp;"
    } else {
        return parsed.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })
    }
}

export function checkNumber(number: number) {
    if (!number) {
        return;
    }
    if (isNaN(number)) {
        return 0;
    }
    return number;
}

export function formatVolume(volume: string, showUnit: boolean = false) {
    let parsed = parseFloat(volume)
    if (isNaN(parsed)) {
        return "&nbsp;-&nbsp;"
    } else {
        return parsed.toLocaleString('de-DE', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }) + (showUnit ? ("&nbsp;" + get(t)("UI.unit.ton.short.small")) : "");
    }
}

export function formatPrice(price: string) {
    let parsed = parseFloat(price)
    if (isNaN(parsed)) {
        return "&nbsp;-&nbsp;"
    } else {
        return parsed.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) + "&nbsp;" + get(t)("unit.eur.short")
    }
}

export function markFieldLabelRequired(label: string): string {
    if (label == null) {
        return '';
    }

    if (!label.endsWith('*')) {
        return label + '*';
    }
    return label;
}


export function localDateToUnixNumber(date: string | null): number | null {
    if (!date) {
        return null;
    }
    return dayjs(date, "YYYY-MM-DD").unix();
}

export function unixToLocalDate(unixDate: number | null): string {
    if (!unixDate) {
        return "";
    }
    return dayjs.unix(unixDate).format('DD.MM.YYYY');
}

export function unixToIsoDate(unixDate: number|null): string {
    if (!unixDate) {
        return "";
    }
    return dayjs.unix(unixDate).add(12, 'h').toISOString();
}
