import type {Writable} from 'svelte/store';
import {writable} from 'svelte/store';
import type {Lot} from "../../types/lot";
import type {Project} from "../../../types/project";
import {DefaultProject} from "../../../types/project";
import type {Catalog} from "../../types/catalog";
import type {Material} from "../../types/material";
import type {FormError} from "../../../types/formError";

// level < 1 means map form is closed
export const mapFormControls: Writable<{
    projectId: string | null,
    lotId: string | null,
    level: number,
    saving: boolean
}> = writable({projectId: null, lotId: null, level: 0, saving: false});

/*project/position autocomplete open store
* store to check that only one of the autocomplete fields for which we can add new entries is opened at the same time*/
export const projectAutocompleteVisible = writable({company: true, contactPerson: true, addressData: true});
export const positionAutocompleteVisible = writable({company: true, contactPerson: true, addressData: true});

export const localLotsStore: Writable<Map<string, Lot>> = writable(new Map<string, Lot>());
export const currentLot: Writable<Lot | null> = writable(null);
export const currentProject: Writable<Project | null> = writable(DefaultProject());
export const lotSavingTimeout: Writable<NodeJS.Timeout> = writable();

export const formErrors: Writable<FormError[]> = writable([{path: "", message: ""}]);

export const defaultCatalogOptions: Writable<Catalog[]> = writable([]);
export const defaultMaterialOptions: Writable<Map<string, Material[]>> = writable(new Map<string, Material[]>());
