<script lang="ts">
    import {fade} from 'svelte/transition';
    import {onDestroy} from "svelte";

    export let minWidth = '180px';
    export let right = '0';
    export let top = '0.75rem';
    export let appendToElement: HTMLElement | null = null;
    export let titleAttr: string = "";

    let actionMenuContainer: HTMLElement;
    let absoluteItem: HTMLElement;

    function toggleMenu() {
        active = !active;
    }

    let active: boolean = false;

    function appendToTarget() {
        if (appendToElement && actionMenuContainer && absoluteItem) {
            let appendToRect = appendToElement.getBoundingClientRect();
            let targetRect = actionMenuContainer.getBoundingClientRect();

            // Remove from default position
            absoluteItem.remove();

            // Position menu (absoluteItem) relative to the position of actionMenuContainer
            absoluteItem.style.top = (targetRect.top - appendToRect.top - actionMenuContainer.offsetHeight) + "px";
            absoluteItem.style.left = (targetRect.left - appendToRect.left) + "px";
            // Add padding on top to extend the item over the action-btn s.t. the mouseleave event will fire correctly
            absoluteItem.style.paddingTop = (actionMenuContainer.offsetHeight * 2) + "px";

            appendToElement.appendChild(absoluteItem);
        }
    }

    function removeFromTarget() {
        if (appendToElement && absoluteItem) {
            absoluteItem.remove();
        }
    }

    $: if (absoluteItem) {
        if (active) {
            appendToTarget();
            absoluteItem.style.display = 'block';
        } else {
            removeFromTarget();
            absoluteItem.style.display = 'none';
        }
    }

    onDestroy(() => {
        if (absoluteItem) {
            // Necessary if menu item is appended to another element and is destroyed before the mouseleave event fires.
            absoluteItem.remove();
        }
    })

</script>

<!-- We only toggle the mouse leave here, if the menu item is not appended somewhere else. Otherwise, hovering over the menu item is not possible -->
<div bind:this={actionMenuContainer} class="action-menu-container" class:active style="--min-width: {minWidth}; --right:{right}"
     on:mouseenter={toggleMenu} on:mouseleave={() => {if (!appendToElement) toggleMenu()}}>
    <slot class="actionMenuToggle" name="trigger">
        <div class="action-btn" title={titleAttr}></div>
    </slot>
    <!-- We toggle the mouse leave here, if the menu item is appended somewhere else to allow hovering correctly -->
    <div class="action-menu-item" bind:this={absoluteItem} style="position: absolute; display: none;"
         on:mouseleave={() => {if (appendToElement) toggleMenu()}}>
        <ul transition:fade={{duration: 300}} style="--top: {top}">
            <slot name="content">
                <!--always use the ActionMenuItem to use the correct style-->
            </slot>
        </ul>
    </div>
</div>


<style lang="scss">
  @import "../../styles/global";


  .action-menu-container {
    cursor: pointer;

  }

  .action-menu-item {
    cursor: pointer;

    & ul {
      position: relative;
      top: 0.25rem;
      right: var(--right);
      background: white;
      min-width: var(--min-width);
      padding: 0.25rem 0.75rem;
      border-radius: 4px;
      transition: 0.3s;
      border: 1px solid #DDDDDD;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
      z-index: 11;
    }

    &.active ul {
      top: var(--top);
      transition: 0.3s;
    }
  }

  .action-menu-container :global(li.action-item:not(:last-of-type)) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .action-btn {
    background: url("@/icons/icon_3dot_menu.svg") no-repeat;
    width: 20px;
    height: 20px;
    background-size: contain;
  }


</style>