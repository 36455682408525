import {addNotification, addPageError} from "../stores";
import {DefaultNotification, NotificationType} from "../types/notification";

export const fetchUtils = {
    get,
    post,
    put,
    delete: _delete,
    showError: showError,
    catchErrorAndShowNotification: catchErrorAndShowNotification,
};

function showError(page, customMessage) {
    return (error) => {
        console.log(error)
        page.showErrorMsg(customMessage ?? error.message ?? error)
    }
}

function get(url: string, publishPageError = true) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(url, requestOptions).then(doHandle(publishPageError));
}

function post(url: string, body, publishPageError = true, stringify = true) {
    let bodyData = stringify ? JSON.stringify(body) : body;
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: bodyData
    };
    return fetch(url, requestOptions).then(doHandle(publishPageError));
}

function put(url: string, body, publishPageError = true) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(doHandle(publishPageError));
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url: string, publishPageError = true) {
    const requestOptions = {
        method: 'DELETE'
    };
    return fetch(url, requestOptions).then(doHandle(publishPageError));
}

function catchErrorAndShowNotification(target: string | null = null) {
    return error => {
        let notification = DefaultNotification(error.message ?? error, NotificationType.ERROR, true, 0, target);
        addNotification(notification);
    };
}

export enum SpecialHandledErrors {
    NOT_AUTHORIZED = 401,
    MISSING_PERMISSION = 403,
    NOT_FOUND = 404,
    GONE = 410,
}

// helper functions
function doHandle(publishPageError: boolean) {
    return function (response: Response): JSONResponse | Promise<any> {
        return response.json()
            .then((json: JSONResponse) => {
                if (!response.ok || !json.success) {
                    const error = json?.errorMessage || response.statusText;
                    let currentError = {
                        code: response.status,
                        message: error,
                        data: json.data
                    }
                    if (response.status in SpecialHandledErrors && publishPageError) {
                        addPageError(currentError)
                    }
                    return Promise.reject(currentError);
                }

                return json.data;
            });
    }
}

type JSONResponse = {
    data: any,
    errorCode: number,
    hasValidationErrors: boolean,
    success: boolean,
    successCode: any,
    validationErrors: any[],
    errorMessage?: string
}
