<script lang="ts">

    export let classes: string[];
</script>

<div class="installation-classes-categorisation-cell-container">
    {#each classes as c, i}
        <span>{c}</span>
    {/each}

</div>

<style lang="scss">
  @use "../../styles/global";

  .installation-classes-categorisation-cell-container {
    @include global.flex-row(2rem, $justify: flex-start);
    & > span:not(:first-child) {
      opacity: 0.5;
    }
  }
</style>
