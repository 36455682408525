<script lang="ts">

    import MenuAvatar from "../../MenuAvatar.svelte";
    import DropdownMenu from "../../dropdown/SimpleDropdownMenu.svelte";
    import SimpleDropdownLinkItem from "../../dropdown/SimpleDropdownLinkItem.svelte";
    import DropdownMenuSection from "../../dropdown/DropdownMenuSection.svelte";
    import {t} from "../../../../i18n/i18n";
    import {UserPermission} from "../../../types/enums";

    export let hamburgerActive: boolean = false;
</script>

{#if !hamburgerActive}
    <MenuAvatar let:active>
        <DropdownMenu {active}>
            <DropdownMenuSection>
                <SimpleDropdownLinkItem titleKey="CompanyProfile.label" url="/company-profile"/>
                <SimpleDropdownLinkItem titleKey="ProfileController.myProfile" url="/profile"/>
                <SimpleDropdownLinkItem titleKey="Model.password.set" url="/profile/password"/>
                <SimpleDropdownLinkItem titleKey="Tenant.select" url="/tenants/select"
                                        permissions={UserPermission.SELECT_TENANT}/>
                <SimpleDropdownLinkItem titleKey="UserAccount.select" url="/user-accounts/select"
                                        permissions={UserPermission.SELECT_USER_ACCOUNT}/>
                <SimpleDropdownLinkItem title="Sicherheitsprotokoll" url="/audit-log"/>
                <SimpleDropdownLinkItem titleKey="UserAccountController.logout" url="/logout"/>
            </DropdownMenuSection>
        </DropdownMenu>
    </MenuAvatar>
{/if}
