<script lang="ts">

    import {writable, type Writable} from "svelte/store";
    import {onMount, setContext, tick} from "svelte";
    import {t} from "../../i18n/i18n";
    import {fly} from 'svelte/transition';
    import {breadcrumbs, closeModalOnOutsideClick, manualOpenWhitelist} from "../stores";
    import {LotType} from "../types/enums";
    import Notifications from "../organisms/Notifications.svelte";
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import Breadcrumbs from "../organisms/Breadcrumbs.svelte";
    import {
        WhitelistFinishedHandler,
        WhitelistMaterialTypePageHandler,
        whitelistModalUtils,
        WhitelistOverviewHandler,
        WhitelistStartPageHandler
    } from "./whitelistModalUtil";
    import {WhitelistView} from "./whitelist";
    import {DefaultRobotoFontProps} from "../types/fontProps";
    import {AllWhitelistData, type WhitelistCatalog, type WhitelistData} from "./whitelistData";
    import WhitelistOverviewPage from "./WhitelistOverviewPage.svelte";
    import WhitelistSelectMaterialsPage from "./WhitelistSelectMaterialsPage.svelte";
    import WhitelistConfigFinishedPage from "./WhitelistConfigFinishedPage.svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import WhitelistSelectTypesPage from "./WhitelistSelectTypesPage.svelte";
    import ProgressBar from "../molecules/ProgressBar.svelte";
    import {closeModal} from "svelte-modals";
    import {getJwt} from "../utils/jwt";

    // constants
    const progressBarStates: WhitelistView[] = [WhitelistView.START_PAGE, WhitelistView.MATERIAL_TYPE, WhitelistView.OVERVIEW];

    // exported variables
    export let isOpen: boolean = true;

    // context stores
    let whitelistConfigData: Writable<Map<LotType, WhitelistCatalog[]>> = setContext('whitelistConfigData', writable(new Map<LotType, WhitelistCatalog[]>()))
    let selectedUserData: Writable<AllWhitelistData> = setContext('selectedWhitelistData', writable(new AllWhitelistData()));
    let selectedLotTypes: Writable<LotType[]> = setContext('selectedLotTypes', writable([]));
    let currentLotType: Writable<LotType> = setContext('currentLotType', writable());


    // view handling
    let view: WhitelistView = WhitelistView.START_PAGE;
    let viewHandler: WhitelistStartPageHandler | WhitelistMaterialTypePageHandler | WhitelistOverviewHandler | WhitelistFinishedHandler = new WhitelistStartPageHandler();
    let padding: string = "56px 56px 50px";
    let modalHeight: string = "max-content";
    let progressBarMargin: string = "24px";

    //globals
    let lotTypes: LotType[] = [];
    let errorMaterials: string[] = []; // once the check is made on WhitelistSelectMaterialsPage, it contains the materials that have no properties/list elements
    let firstActionButtonDisabled: boolean = $selectedLotTypes.length === 0;
    //@ts-ignore
    let fourthButtonText: string = $t('UI.whitelist.modal.fourthActionButton.label.selectAll');

    let selectMaterialsPage;


    onMount(async () => {
        await loadData();
        $closeModalOnOutsideClick = false;
        viewHandler = new WhitelistStartPageHandler()
        updateBreadcrumbs(viewHandler.breadcrumbTitle, WhitelistView.START_PAGE);
    })


    // fetch functions

    async function loadData() {
        await getConfigData();
        await getUserData();
    }

    async function getConfigData() {
        await fetchUtils.get(`/api/whitelist/fetchConfig`, false)
            .then(data => {
                //@ts-ignore
                for (let whitelistData: WhitelistData of data.whitelistData) {
                    $whitelistConfigData.set(whitelistData.lotType, whitelistData.catalogs);
                    if (whitelistData && whitelistData?.catalogs && whitelistData.catalogs.length > 0) {
                        for (let catalog of whitelistData.catalogs) {
                            if (catalog.materials && catalog.materials.length > 0) {
                                lotTypes = [...lotTypes, whitelistData.lotType];
                                break; // to continue with next whitelistData
                            }
                        }
                    }
                }
                updateFourthButtonText();
            });
    }

    async function getUserData() {
        await fetchUtils.get("/api/whitelist/fetch")
            .then(data => {
                if (data?.whitelistData) {
                    $selectedUserData.init(data.whitelistData);
                    data.whitelistData.forEach(data => $selectedLotTypes = [...$selectedLotTypes, data.lotType]);
                }
            })
    }

    async function saveWhitelist() {
        let whitelistData: WhitelistData[] = $selectedUserData.getAllDataAsObject();
        let dataJSON = {whitelistData: whitelistData}; // Convert to JsonObject because Sirius can't handle JsonArrays
        await fetchUtils.post("/api/whitelist/save", dataJSON, false);
    }


    // Breadcrumbs handling

    function updateBreadcrumbsFromSelectMaterialsPage(e) {
        updateBreadcrumbs(e.detail.title, WhitelistView.MATERIAL_TYPE, e.detail.lotType)
    }

    function updateBreadcrumbs(title: string, newView: WhitelistView, lotType: LotType = undefined) {
        $breadcrumbs.push({
            title,
            data: {
                view: newView,
                lotType
            },
            callback: () => handleBreadcrumbs(newView, lotType)
        });
        $breadcrumbs = $breadcrumbs;
    }

    function handleBreadcrumbs(newView: WhitelistView, lotType: LotType = undefined) {
        view = newView;
        if (view !== WhitelistView.OVERVIEW) {
            while ($breadcrumbs.length > 1) {
                $breadcrumbs.pop();
            }
        }
        if (view === WhitelistView.MATERIAL_TYPE) {
            const breadcrumbTitle = whitelistModalUtils.getBreadcrumbTitleMaterialType($currentLotType);
            updateBreadcrumbs(breadcrumbTitle, WhitelistView.MATERIAL_TYPE, lotType)
        }
        if (lotType) {
            $currentLotType = lotType as LotType
        }
        if (view === WhitelistView.START_PAGE) {
            $breadcrumbs = $breadcrumbs;
        }
    }


    function handleFirstActionButtonStartPage() {
        view = WhitelistView.MATERIAL_TYPE;
        $currentLotType = $selectedLotTypes[0];
        const breadcrumbTitle = whitelistModalUtils.getBreadcrumbTitleMaterialType($currentLotType)
        updateBreadcrumbs(breadcrumbTitle, WhitelistView.MATERIAL_TYPE, $currentLotType);
        updateFourthButtonText();

        // we need to reset the selection for deselected lot types
        [...lotTypes]
            .filter(lotType => !$selectedLotTypes.includes(lotType as LotType)) // find LotTypes that are no more selected
            .forEach(deselectedLotType => $selectedUserData.resetSelection(deselectedLotType as LotType)); // reset selection for those

        updateFirstActionButtonDisabled();
    }

    function handleNextPageOrNextLotType() {
        const topElement = $breadcrumbs.slice(-1)[0];
        const allLotTypesSelected = $selectedLotTypes.findIndex(type => type === topElement.data.lotType) === $selectedLotTypes.length - 1;
        if (allLotTypesSelected) {
            // go to next page
            $breadcrumbs.pop();
            //@ts-ignore
            updateBreadcrumbs($t('UI.whitelist.modal.breadcrumb.materialTypeTitle.finished'), WhitelistView.MATERIAL_TYPE, $selectedLotTypes[$selectedLotTypes.length - 1]);
            view = WhitelistView.OVERVIEW;
        } else {
            // go to next LotType
            const nextLotTypeIndex = $selectedLotTypes.findIndex(chip => chip === topElement.data.lotType) + 1;
            while ($breadcrumbs.findIndex(breadcrumb => breadcrumb.data.lotType) > -1) {
                $breadcrumbs.pop();
            }
            $currentLotType = $selectedLotTypes[nextLotTypeIndex];
            const breadcrumbTitle = whitelistModalUtils.getBreadcrumbTitleMaterialType($currentLotType)
            updateBreadcrumbs(breadcrumbTitle, WhitelistView.MATERIAL_TYPE, $selectedLotTypes[nextLotTypeIndex])
            updateFourthButtonText();
        }
    }

    // action button handling
    async function handleFirstActionButton() {
        switch (view) {
            case WhitelistView.START_PAGE:
                handleFirstActionButtonStartPage();
                break;
            case WhitelistView.MATERIAL_TYPE:
                errorMaterials = $selectedUserData.checkSelectedMaterials($currentLotType);
                // in case we have any error materials, we return until the errors are resolved
                if (errorMaterials.length > 0) {
                    await tick(); // somehow we need to wait here until the page is binded
                    if (selectMaterialsPage) {
                        selectMaterialsPage.updateActiveElementsWithError();
                    }
                    return;
                }
                handleNextPageOrNextLotType();
                break;
            case WhitelistView.OVERVIEW:
                view = WhitelistView.FINISHED;
                await saveWhitelist();
                break;
            case WhitelistView.FINISHED:
                await getJwt();
                closeModal();
                isOpen = false;
                $manualOpenWhitelist = false;
                $closeModalOnOutsideClick = true; // reset the value to default
        }
    }



    // back button functionality

    function handleBackButtonMaterialTypePage() {
        // if breadcrumbs only contain 1 lot type
        // switch view and update breadcrumb
        const actualBreadcrumbLotType = $breadcrumbs[$breadcrumbs.length - 1].data.lotType; // get last breadcrumb entry
        const backToStartpage = actualBreadcrumbLotType === $selectedLotTypes[0]
        if (backToStartpage) {
            view = WhitelistView.START_PAGE;
            $breadcrumbs.pop();
            $breadcrumbs = $breadcrumbs;
        } else {
            $breadcrumbs.pop();
            const previousLotTypeIndex = $selectedLotTypes.findIndex(chip => chip === actualBreadcrumbLotType) - 1;
            if (previousLotTypeIndex > -1) {
                $currentLotType = $selectedLotTypes[previousLotTypeIndex];
                const breadcrumbTitle = whitelistModalUtils.getBreadcrumbTitleMaterialType($currentLotType);
                updateBreadcrumbs(breadcrumbTitle, WhitelistView.MATERIAL_TYPE, $selectedLotTypes[previousLotTypeIndex])
            }
        }
    }
    function handleBackButtonOverviewPage() {
        $breadcrumbs.pop();
        $breadcrumbs.pop(); // remove also last material type element
        const breadcrumbTitle = whitelistModalUtils.getBreadcrumbTitleMaterialType($currentLotType ?? $selectedLotTypes[$selectedLotTypes.length - 1]);
        updateBreadcrumbs(breadcrumbTitle, WhitelistView.MATERIAL_TYPE, $selectedLotTypes[$selectedLotTypes.length - 1])
        $breadcrumbs = $breadcrumbs;
        view = WhitelistView.MATERIAL_TYPE;
        updateFourthButtonText();
    }
    function handleBackButton() {
        // switch view
        switch (view) {
            // second view
            case WhitelistView.MATERIAL_TYPE:
                handleBackButtonMaterialTypePage();
                break;
            // third view
            case WhitelistView.OVERVIEW:
                handleBackButtonOverviewPage();
        }
    }

    // second action button, used on the first page to select ALL lotTypes
    function selectAllLotTypesAndMaterials() {
        let uniqueSelectedLotTypes = new Set($selectedLotTypes || []);
        lotTypes.forEach(lotType => {
            let catalogsForLotType = $whitelistConfigData.get(lotType as LotType) || []; // get available data from config
            $selectedUserData.resetSelection(lotType)
            $selectedUserData.addAllCatalogs(lotType, catalogsForLotType);
            uniqueSelectedLotTypes.add(lotType as LotType);
        })
        $selectedLotTypes = Array.from(uniqueSelectedLotTypes);
        selectedUserData = selectedUserData;
        let lastSelectedLotType = $selectedLotTypes[$selectedLotTypes.length - 1];
        $currentLotType = lastSelectedLotType; //update, as this would be set when going step by step and prevents a crash
        updateBreadcrumbs($t('UI.whitelist.modal.breadcrumb.materialTypeTitle.finished'), WhitelistView.MATERIAL_TYPE, lastSelectedLotType);
        view = WhitelistView.OVERVIEW;
        updateFirstActionButtonDisabled();
    }

    function areAllMaterialsAndPropertiesSelected() {
        let allSelected = true;
        let configCatalogs = $whitelistConfigData.get($currentLotType as LotType) || [];
        let catalogs = $selectedUserData.getDataForLotType($currentLotType as LotType);
        for (let catalog of configCatalogs) {
            let selectedCatalog = catalogs.find(c => c.id === catalog.id);
            if (!selectedCatalog || !selectedCatalog.materials) {
                return false;
            }
            let [allPropertiesSelected, allMaterialsSelected] = $selectedUserData.getMaterialEqualData(catalog.materials, selectedCatalog.materials);
            allSelected = allSelected && allMaterialsSelected && allPropertiesSelected;
        }
        return allSelected;
    }

    function selectAllMaterials() {
        let shouldSelectAll = !areAllMaterialsAndPropertiesSelected();
        let catalogsForLotType = $whitelistConfigData.get($currentLotType) || [];
        if (shouldSelectAll) {
            $selectedUserData.addAllCatalogs($currentLotType, catalogsForLotType);
            errorMaterials = [];
        } else {
            for (let catalog of catalogsForLotType) {
                for (let material of catalog.materials) {
                    $selectedUserData.removeMaterialKey($currentLotType, material, catalog);
                }
            }
        }
        fourthButtonText = shouldSelectAll ? $t('UI.whitelist.modal.fourthActionButton.label.deselectAll') : $t('UI.whitelist.modal.fourthActionButton.label.selectAll');
        $selectedUserData = $selectedUserData;
        updateFirstActionButtonDisabled();
    }

    function handleMaterialSelectionChange() {
        updateFourthButtonText();
        updateFirstActionButtonDisabled();
    }

    function updateFourthButtonText() {
        if ($currentLotType && view === WhitelistView.MATERIAL_TYPE) {
            if (areAllMaterialsAndPropertiesSelected()) {
                fourthButtonText = $t('UI.whitelist.modal.fourthActionButton.label.deselectAll');
            } else {
                fourthButtonText = $t('UI.whitelist.modal.fourthActionButton.label.selectAll');
            }
        }
    }

    function updateViewVariables() {
        switch (view) {
            case WhitelistView.START_PAGE:
                progressBarMargin = "24px";
                viewHandler = new WhitelistStartPageHandler();
                break;
            case WhitelistView.MATERIAL_TYPE:
                progressBarMargin = "32px";
                viewHandler = new WhitelistMaterialTypePageHandler();
                break;
            case WhitelistView.OVERVIEW:
                progressBarMargin = "32px";
                viewHandler = new WhitelistOverviewHandler();
                updateBreadcrumbs(viewHandler.breadcrumbTitle, WhitelistView.OVERVIEW);
                break;
            case WhitelistView.FINISHED:
                viewHandler = new WhitelistFinishedHandler();
                break;
        }
    }

    function updateFirstActionButtonDisabled() {
        if (view === WhitelistView.START_PAGE) {
            firstActionButtonDisabled = $selectedLotTypes.length === 0;
        } else if (view === WhitelistView.MATERIAL_TYPE) {
            firstActionButtonDisabled = $selectedUserData.getMaterialCountForType($currentLotType) === 0;
        } else if (view === WhitelistView.OVERVIEW) {
            let lotTypeSelected = $selectedLotTypes.length > 0;
            let materialsSelected = true;
            $selectedLotTypes.forEach((lotType) => {
                materialsSelected = materialsSelected && $selectedUserData.getMaterialCountForType(lotType) > 0;
            });
            firstActionButtonDisabled = !lotTypeSelected || !materialsSelected;
        }
    }

    $: view, updateViewVariables();
    $: $currentLotType, updateFourthButtonText();
    $: $selectedLotTypes, $selectedUserData, $currentLotType, view, updateFirstActionButtonDisabled();
    $: if (isOpen) {
        document.body.style.overflowY = "hidden";
    } else {
        document.body.style.overflowY = "initial";
    }

</script>

{#if isOpen}
    <div role="dialog" class="modal modal-backdrop" in:fly="{{ y: -1000, duration: 400 }}" out:fly={{y: -1000, duration: 400}}>
        <div class="contents"
             style="--content-padding: {padding}; height: {modalHeight}; max-height:48rem; max-width: 57rem; width: 57rem;justify-content: flex-start">
            <Notifications target={"modal"}/>
            {#if view !== WhitelistView.FINISHED}
                <Breadcrumbs fontProps={DefaultRobotoFontProps('1.3125rem', '0.875rem', '#9d9d9d', '#8D8D8D', 500)}/>
            {:else}
                <div style="{view !== WhitelistView.FINISHED ? `min-height: 5rem` : ''}"></div>
            {/if}
            <div class="whitelist-modal-title">
                <h2>{@html viewHandler.title}</h2>
            </div>
            <div class="actual-content">
                <p class="modal-message">{@html viewHandler.description}</p>
                {#if view !== WhitelistView.FINISHED}
                    <div class="wrapper-whitelist-progress-bar"
                         style="--progress-bar-margin-bottom:{progressBarMargin}">
                        <ProgressBar states={progressBarStates} actualState={view}
                                     barHeight={1.25} iconDistance={23}/>
                    </div>
                {/if}
                {#if view === WhitelistView.START_PAGE}
                    <WhitelistSelectTypesPage {lotTypes}/>
                {:else if view === WhitelistView.MATERIAL_TYPE}
                    <WhitelistSelectMaterialsPage bind:errorMaterials bind:this={selectMaterialsPage}
                                                  on:updateBreadcrumbs={updateBreadcrumbsFromSelectMaterialsPage}
                                                  on:materialSelectionChange={handleMaterialSelectionChange}/>
                {:else if view === WhitelistView.OVERVIEW}
                    <WhitelistOverviewPage {lotTypes}/>
                {:else if view === WhitelistView.FINISHED}
                    <WhitelistConfigFinishedPage/>
                {/if}
            </div>
            <div class="whitelist-button-actions">
                <div>
                    <!-- the back button -->
                    {#if whitelistModalUtils.showThirdActionButton(view)}
                        <div class="third-action-button">
                            <SecondaryButton label={$t('UI.whitelist.modal.thirdActionButton.label')}
                                             color={SecondaryBtnColors.GREEN}
                                             on:click={handleBackButton} sizeAdaptingToText/>
                        </div>

                    {/if}
                    <!-- this button is only visible on the Material Select Page and it selects all materials for this LotType -->
                    {#if whitelistModalUtils.showFourthActionButton(view)}
                        <div class="fourth-action-button">
                            <SecondaryButton label={fourthButtonText}
                                             color={SecondaryBtnColors.GREY}
                                             on:click={selectAllMaterials} sizeAdaptingToText/>
                        </div>

                    {/if}
                </div>
                <div>
                    <!-- this is only visible on the startpage and selects all Materials for LotTypes and jumps to the Overview page -->
                    {#if whitelistModalUtils.showSecondActionButton(view)}
                        <div class="second-action-button">
                            <SecondaryButton label={$t('UI.whitelist.modal.startpage.secondaryButton')}
                                             color={SecondaryBtnColors.GREEN}
                                             on:click={selectAllLotTypesAndMaterials} sizeAdaptingToText/>
                        </div>

                    {/if}
                    <!-- the next button -->
                    <div class="first-action-button">
                        <PrimaryButton label={whitelistModalUtils.getFirstActionButtonText(view)}
                                       color={PrimaryBtnColors.GREEN}
                                       on:click={handleFirstActionButton} disabled={firstActionButtonDisabled}
                                       sizeAdaptingToText/>
                    </div>
                </div>
            </div>
        </div>
    </div>
{/if}

<style lang="scss">
  @import "../../styles/modals"; // also includes global.scss

  p {
    @include roboto-font(1.25rem, 400, 0.75rem);
    text-align: left;
  }

  h2 {
    text-align: left;
    width: max-content;
  }

  .actual-content {
    width: 100%;
    @include flex-col(0, self-start)
  }

  div.whitelist-modal-title {
    @include flex-row(0.5rem, $justify: flex-start);
    width: 100%;

    & li {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: $primaryBlue;
      }
    }
  }

  .modal-message {
    margin-top: 0.75rem;
    margin-bottom: 0;
  }

  .whitelist-button-actions {
    @include flex-row($justify: space-between);
    width: 100%;
    border-radius: 0 0 6px 6px;

    & > div {
      @include flex-row(6px);
    }
  }

  .modal {
    justify-content: center;
    background: rgba(58, 58, 58, .4);
    pointer-events: all;
  }

  .wrapper-whitelist-progress-bar {
    width: inherit;
    overflow-x: hidden;
    display: grid;
    justify-items: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: var(--progress-bar-margin-bottom, 24px);
  }

  div.whitelist-view-header {
    padding-bottom: 0.7rem;

    & > span {
      @include roboto-font(1rem, 500, 0.75rem, black);
    }
  }

</style>