<script lang="ts">
    import TextInput from "./TextInput.svelte";
    import {inputFieldFormats} from "../utils/formatters";
    import type {FontProps} from "../types/fontProps";

    export let id: string = '';
    export let value;
    export let format = inputFieldFormats.NORMAL;
    export let placeholder: string | Number = "";
    export let unit: string = "";
    export let forcedDecimalPlaces: number;
    export let borderless: boolean = false;
    export let fontProps: FontProps;
    export let readOnly: boolean = false;
    export let materialWidth: string;
    export let materialLabel: string;
    export let errorHighlight: boolean = false;

    let inputValue = "";

    $: {
        if (onlyNumbers(placeholder)) {
            if (typeof placeholder === 'string') {
                placeholder = placeholder.replace('.', ''); // first the 1000 separators are removed
                placeholder = placeholder.replace(',', '.'); // then the commas are replaced with dots so that the parsing works
            }
            placeholder = parseFloat(placeholder)
                .toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                .replace(",00", "")
        }
        inputValue = formatNumber(value)
    }

    function onlyNumbers(str) {
        let regEx = new RegExp('^[0-9.,]+' + unit + '?$');
        return regEx.test(str);
    }

    function formatValue() {
        if (inputValue) {
            inputValue = inputValue
                .replace(/[^0-9,]+/g, '')
                .replace(/[,](?=.*[,])/g, "")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                .replace(/([0-9.]+,?)([0-9]*)$/g, function (full, beforePoint, afterPoint) {
                    if (forcedDecimalPlaces) {
                        let difference = forcedDecimalPlaces - afterPoint.length;
                        beforePoint = beforePoint.includes(",") ? beforePoint : beforePoint + ",";
                        if (parseFloat(forcedDecimalPlaces) === 0) {
                            beforePoint = beforePoint.slice(0, beforePoint.length - 1);
                        }
                        afterPoint = difference < 0 ? afterPoint.slice(0, forcedDecimalPlaces) :
                            afterPoint + "0".repeat(difference);
                        return beforePoint + afterPoint;
                    }
                    return full;
                })
            if (unit) { // otherwise we have trailing whitespace at the end
                inputValue = inputValue.replace(/([0-9,.]+)$/g, '$1 ' + unit)
            }
            value = parseFloat(inputValue.replace(".", "").replace(",", ".").replace(unit, ""))
        } else if (inputValue === '') {
            value = undefined;
        }
    }

    function formatNumber(numberValue) {
        let formattedNumber = ('' + value).replace(".", ",")
            .replace(/[^0-9,]+/g, '')
            .replace(/[,](?=.*[,])/g, "")
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            .replace(/([0-9]+,?)([0-9]*)$/g, function (full, beforePoint, afterPoint) {
                if (forcedDecimalPlaces) {
                    let difference = forcedDecimalPlaces - afterPoint.length;
                    beforePoint = beforePoint.includes(",") ? beforePoint : beforePoint + ",";
                    if (parseFloat(forcedDecimalPlaces) === 0) {
                        beforePoint = beforePoint.slice(0, beforePoint.length - 1);
                    }
                    afterPoint = difference < 0 ? afterPoint.slice(0, forcedDecimalPlaces) :
                        afterPoint + "0".repeat(difference);
                    return beforePoint + afterPoint;
                }
                return full;
            });
        if (unit) { // otherwise we have trailing whitespace at the end
            formattedNumber = formattedNumber.replace(/([0-9,.]+)$/g, '$1 ' + unit);
        }
        return formattedNumber;
    }

</script>
<TextInput {id} bind:value={inputValue} {format} {placeholder} {borderless} {errorHighlight} {fontProps}
           {readOnly} {materialWidth} {materialLabel} onInputFinished={formatValue}
           on:focusout on:input on:keydown on:change/>

