import type {Result} from "./analyticalEvaluation";

export type AnalyticalValue = {
    id: string,
    text: string,
    key: string,
    name: string,
    title: string,
    unit: string,
    extractionMethod: string,
    value: number | null,
    operation: string,
    footnote: string,
    additional: boolean,
    valueError: string
    evaluation?: Record<string, Result>
}

export function DefaultAnalyticalValue(): AnalyticalValue {
    return {
        extractionMethod: "",
        footnote: "",
        key: "",
        operation: "",
        title: "",
        id: "",
        name: "",
        text: "",
        unit: "",
        value: null,
        additional: false,
        valueError: "",
        evaluation: undefined,
    };
}