<script lang="ts">

    import type {HelpEntry} from "./types/helpEntry";
    import collapse from 'svelte-collapse'
    import expandableIcon from "@/icons/icon_expandable_closed.svg";
    import Icon from "../atoms/Icon.svelte";
    import {t} from "../../i18n/i18n";
    import iconEditGreen from "@/icons/icon_edit_green.svg";
    import iconTrashRed from "@/icons/icon_trash_red.svg";
    import ActionMenu from "../organisms/ActionMenu.svelte";
    import ActionMenuItem from "../molecules/ActionMenuItem.svelte";

    export let helpEntry: HelpEntry;
    export let previewMode: boolean = false;
    export let onEditClick: () => void = () => {
    };
    export let onDeleteClick: () => void = () => {
    };

    let params = {
        open: previewMode,
        duration: .5,
        easing: 'ease',
    };
</script>

<div class="help-entry-container" aria-expanded={params.open}>
    <div class="help-entry-header {params.open ? 'help-entry-header-opened' : 'help-entry-header-closed'}"
         on:click={() => {if (!previewMode) params.open = !params.open}}
    >
        <span title="{helpEntry.headline}">{helpEntry.headline}</span>
        {#if previewMode}
            <div style="position: relative">
                <ActionMenu appendToElement={document.body}>
                    <svelte:fragment slot="content">
                        <ActionMenuItem label={$t('UI.edit')} iconSrc={iconEditGreen}
                                        on:click={onEditClick}/>
                        <ActionMenuItem label={$t('UI.delete')} iconSrc={iconTrashRed}
                                        on:click={onDeleteClick}/>
                    </svelte:fragment>
                </ActionMenu>
            </div>
        {:else}
            <div style="transform: rotate({params.open ? '90deg' : '0'}); transition: transform .4s ease-out;">
                <Icon src={expandableIcon} size={32} clickable/>
            </div>
        {/if}
    </div>
    <div class="help-entry-body" use:collapse={params}>
        <div style="padding-bottom: 1.37rem; padding-right: 1.65rem; padding-left: 1.31rem;">
            <div class="help-entry-desc">
                <span>{helpEntry.description}</span>
                {#if helpEntry.link && helpEntry.linkText}
                    <br><br>
                    <a href="{helpEntry.link}" target="_blank">{helpEntry.linkText}</a>
                {/if}
            </div>
        </div>
        {#if previewMode}
            <div class="help-entry-preview-container">
                <hr>
                <div class="help-entry-preview-grid">
                    <div>
                        <span>{$t("UI.help.entry.visibility")}:</span>
                        <div style="text-align: left;">
                            {#each helpEntry.visibility as vis}
                                <span id="help-entry-preview-visibility" class="help-entry-preview-tags">{$t("UI.help.visibility." + vis.toLowerCase())}</span>
                            {/each}
                        </div>
                    </div>
                    <div>
                        <span>{$t("UI.help.entry.priority")}:</span>
                        <span id="help-entry-preview-priority">{helpEntry.priority}</span>
                    </div>
                    <div style="grid-column: 1 / 3;">
                        <span>{$t("UI.help.entry.tags")}:</span>
                        <div style="text-align: left;">
                            {#each helpEntry.searchStrings as searchString}
                                <span id="help-entry-preview-search-strings" class="help-entry-preview-tags">{searchString}</span>
                            {/each}
                        </div>
                    </div>
                </div>
            </div>
        {/if}
    </div>
</div>

<style lang="scss">
  @import "../../styles/global";

  .help-entry-container {
    border-radius: 0.125rem;
    position: relative;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    max-width: 36.1875rem;
    min-width: 15rem;
  }

  .help-entry-header {
    @include flex-row($col-gap: 0.375rem, $justify: space-between);
    user-select: none;
    cursor: pointer;
    background: #FFFFFF;
    height: 3.625rem;
    padding-right: 1.31rem;

    & > span {
      @include roboto-font(1.125rem, 400, 0.75rem);
      padding-left: 1.31rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .help-entry-header-opened {
    border-radius: 0.125rem 0.125rem 0 0;
    transition-property: border-bottom-left-radius, border-bottom-right-radius;
    transition-duration: 0.01s;
    transition-timing-function: linear;
  }

  .help-entry-header-closed {
    border-radius: 0.125rem;
    transition-property: border-bottom-left-radius, border-bottom-right-radius;
    transition-duration: 0.3s;
    transition-delay: .5s;
    transition-timing-function: ease-in-out;
  }

  .help-entry-body {
    background: #FFFFFF;
    border-bottom-left-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .help-entry-desc {
    max-height: 6.75rem;
    overflow-y: auto;
    text-align: left;
    line-height: 1.125rem;

    & > span {
      @include roboto-font(1.125rem, 400, 0.75rem);
    }

    & > a {
      @include roboto-font(1.125rem, 400, 0.75rem, $primaryGreen);
      text-decoration: none;
    }

    &::-webkit-scrollbar {
      width: 20px;
      margin: 2px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px $bluish-grey-200;
      border: solid 8px transparent;
      border-radius: 20px;
      background-clip: padding-box;

    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px $primaryGreen;
      border: solid 8px transparent;
      border-radius: 20px;
      background-clip: padding-box;
      min-height: 50px;
    }
  }

  .help-entry-preview-container {
    padding: 0 1rem 1rem;

    & > hr {
      width: 100%;
      margin-top: 0;
    }
  }

  .help-entry-preview-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: start;
    row-gap: 1rem;
    column-gap: 1rem;

    & > div {
      @include flex-col($alignment: flex-start, $justify: normal);
      word-break: break-all; // Needed s.t. tags don't overflow in Firefox

      & > span {
        @include roboto-font(1rem, 400, 0.75rem);
      }

      & > span:first-child {
        font-weight: 500;
      }
    }
  }

  .help-entry-preview-tags {
    @include roboto-font(1rem, 400, 0.75rem);
    color: $primaryGreen;
    background: rgba(88, 192, 121, .1);
    padding: 0.2rem;
    border-radius: 3px;
    margin-right: 0.2rem;
    white-space: nowrap;
  }

  .help-entry-preview-link {
    @include roboto-font(1rem, 400, 0.75rem);
    padding-top: 1rem;
    text-align: left;

    & > span {
      font-weight: 500;

      & > a {
        color: $primaryGreen;
        text-decoration: none;
      }
    }
  }
</style>
