<script lang="ts">
    import FooterContactBox from "../atoms/FooterContactBox.svelte";
    import {t} from "../../i18n/i18n";
    import {getContext} from "svelte";
    import type {Writable} from "svelte/store";

    let actualYear = new Date().getFullYear();
    export let elevated: boolean = false;

    let isSaasInternalLot: Writable<boolean> = getContext("isSaasInternalLot");
</script>


<div class="pp-footer" class:elevated style="--pp-footer-height: {$isSaasInternalLot ? 50 : 240}px; {$isSaasInternalLot ? 'padding: 0;' : ''}">
    <div class="pp-footer-contact-info">
        {#if !$isSaasInternalLot}
            <span>{$t('UI.MineralMinds.thereForYou')}</span>
            <FooterContactBox/>
        {/if}
    </div>
    {#if $isSaasInternalLot}
        <div class="pp-footer-saas">
            <a href="https://mineral-minds.de/impressum/">{$t('UI.footer.saas.poweredByMM')}</a>
        </div>
    {/if}
    <div class="pp-footer-icon-agb">
        {#if !$isSaasInternalLot}
            <div class="pp-footer-icon-small"></div>
            <div class="pp-footer-agb">
                <span class="d-block d-md-inline-block">© {actualYear} Mineral Minds Deutschland GmbH</span>&nbsp;·&nbsp;
                <a href="https://www.mineral-minds.de/impressum">{$t('Imprint')}</a>&nbsp;·&nbsp;
                <a href="/agb">{$t('TermsAndConditions')}</a>&nbsp;·&nbsp;
                <a href="https://mineral-minds.de/datenschutzerklaerung/">{$t('Privacy')}</a>
            </div>
        {/if}
    </div>

</div>

<style lang="scss">
  @import "../../styles/global";

  .pp-footer {
    @include flex-row(1rem, stretch, space-between);
    height: var(--pp-footer-height);
    width: 100%;
    position: relative;
    left: 0;
    padding: 2.625rem 3rem 3rem;
    margin-top: auto;
    background: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    z-index: 3;
    bottom: 0;
  }

  .pp-footer-contact-info {
    @include flex-col(1.875rem, $alignment: flex-start);

    & > span {
      @include roboto-font(13px, 500, 1rem, black);
    }
  }

  .pp-footer-icon-agb {
    @include flex-col($alignment: flex-end, $justify: space-between);
  }

  .pp-footer-icon-small {
    background: url("@/images/mm_logo_text_short.png") no-repeat;
    width: 57px;
    height: 58px;
    background-size: contain;
  }

  .pp-footer-agb {
    line-height: 11px;

    & > span {
      @include roboto-font(11px, 400, 0.75rem, black);
    }

    & > a {
      @include roboto-font(11px, 400, 0.75rem, $primaryGreen);
      text-decoration: none;
    }
  }

  .pp-footer-saas {
    @include flex-row();

    & > a {
      color: black;
      text-decoration: none;
    }
  }

  .elevated {
    z-index: 1002;
    position: fixed;
    bottom: 0;
  }
</style>