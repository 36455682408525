<script lang="ts">
    import OfferInfoCards from "./OfferInfoCards.svelte";
    import {CalcMethod} from "../types/enums";
    import {t} from "../../i18n/i18n";
    import type {Lot} from "../optimizer/lot";
    import RadioButton from "../atoms/RadioButton.svelte";
    import {getContext} from "svelte";
    import {EmptyOffer, type Offer, OfferState} from "./types/offer";
    import {RequestType} from "./types/requestDto";
    import {type Writable} from "svelte/store";
    import type {Deal} from "./types/deal";
    import {
        areBothRejectedOrEmpty,
        isEmptyOffer,
        isOfferProvided,
        isOfferRejected,
        isOfferRejectedByOptimizer
    } from "./offerUtils";

    let lot: Writable<Lot> = getContext("lot");
    const offerMaterial: Writable<Offer> = getContext("offerMaterial");
    const offerMaterialWithTransport: Writable<Offer> = getContext("offerMaterialWithTransport");
    const deal: Writable<Deal | null> = getContext("deal");

    export let selected: RequestType | null = null;
    export let selectable: boolean = false;
    export let onOptimizerPage: boolean = false;

    let materialProvided: boolean;
    let materialWithTranspProvided: boolean;

    let headerText: string;

    let disableRadioButtons: boolean = false;

    const getBlurText = (offer, requestType: RequestType) => {
        let payload = {requestType};
        return isOfferRejectedByOptimizer(offer) ? $t('UI.offers.rejectedOfferBlur.Info', payload) : $t('UI.offers.noOfferBlur.Info', payload)
    }

    $: if (selectable) {
        if (materialProvided && !materialWithTranspProvided) {
            disableRadioButtons = true;
            selected = RequestType.MATERIAL;
        } else if (!materialProvided && materialWithTranspProvided) {
            disableRadioButtons = true;
            selected = RequestType.MATERIAL_WITH_TRANSPORT;
        }

    }
    // We show both of them if anyone not SENT (OfferState.READY) OR if any SENT then show only this one
    $: $offerMaterial, materialProvided = isOfferProvided($offerMaterial, RequestType.MATERIAL, $offerMaterialWithTransport, selectable);
    $: $offerMaterialWithTransport, materialWithTranspProvided = isOfferProvided($offerMaterialWithTransport, RequestType.MATERIAL_WITH_TRANSPORT, $offerMaterial, selectable);

    $: {
        if (($offerMaterial.offerState === OfferState.ACCEPTED || $offerMaterialWithTransport.offerState === OfferState.ACCEPTED) && $deal !== null) {
            headerText = $t("UI.optimizerPage.offer.accepted.text");
        } else if (areBothRejectedOrEmpty($offerMaterial, $offerMaterialWithTransport) && $deal === null) {
            headerText = $t("UI.optimizerPage.offer.rejected.text");
        } else {
            let onOptimizerPageAndOfferExisting = onOptimizerPage && (!isEmptyOffer($offerMaterial, RequestType.MATERIAL) || !isEmptyOffer($offerMaterialWithTransport, RequestType.MATERIAL_WITH_TRANSPORT));
            let onOffererPageAndOfferExisting = selectable && (!isEmptyOffer($offerMaterial, RequestType.MATERIAL) || !isEmptyOffer($offerMaterialWithTransport, RequestType.MATERIAL_WITH_TRANSPORT));
            let prefix = (onOptimizerPageAndOfferExisting ? "Abgegebenes " : onOffererPageAndOfferExisting ? "Eingegangenes " : "");
            headerText = prefix + ($lot.calcMethod === CalcMethod.REQUEST_OFFER ? $t("UI.optimizerPage.offer.text") : $t("UI.optimizerPage.counterOffer.text"));
        }
    }
</script>
<div style="width: 100%;">
    <div class="offer-facts-title">
        <h2>{@html headerText}</h2>
    </div>
    <div class="offer-facts-info__container">
        <div class="material_with_transport offer-facts-info_header"
             class:selectable={selectable && materialWithTranspProvided}
             class:offer-unselected={selectable && selected !== RequestType.MATERIAL_WITH_TRANSPORT || !materialWithTranspProvided}>
            <div>
                {#if selectable && materialWithTranspProvided}
                    <div class="offer-radio-btn-container">
                        <RadioButton bind:selected groupName="requestType"
                                     value={RequestType.MATERIAL_WITH_TRANSPORT}
                                     disabled={disableRadioButtons}/>
                    </div>
                {/if}
                <div class="offer-facts-info__title">
                    <h3>{$t('RequestType.MATERIAL_WITH_TRANSPORT')}</h3>
                </div>
            </div>
        </div>
        <OfferInfoCards
                offer={materialWithTranspProvided ? ((!onOptimizerPage && isOfferRejected($offerMaterialWithTransport)) ? EmptyOffer(RequestType.MATERIAL_WITH_TRANSPORT) : $offerMaterialWithTransport) : EmptyOffer(RequestType.MATERIAL_WITH_TRANSPORT)}
                requestType={RequestType.MATERIAL_WITH_TRANSPORT}/>
        {#if !materialWithTranspProvided || (!onOptimizerPage && isOfferRejected($offerMaterialWithTransport))}
            <div class="material_with_transport offer-unselected-blur">
                <h2>{@html getBlurText($offerMaterialWithTransport, $t('RequestType.MATERIAL_WITH_TRANSPORT'))}</h2>
            </div>
        {/if}
        <div class="material offer-facts-info_header"
             class:selectable={selectable && materialProvided}
             class:offer-unselected={selectable && selected !== RequestType.MATERIAL || !materialProvided}>
            <div>
                {#if selectable && materialProvided}
                    <div class="offer-radio-btn-container">
                        <RadioButton bind:selected groupName="requestType" value={RequestType.MATERIAL}
                                     disabled={disableRadioButtons}/>
                    </div>
                {/if}
                <div class="offer-facts-info__title">
                    <h3>{$t('RequestType.MATERIAL')}</h3>
                </div>
            </div>
        </div>
        <OfferInfoCards offer={materialProvided ? ((!onOptimizerPage && isOfferRejected($offerMaterial)) ? EmptyOffer(RequestType.MATERIAL) : $offerMaterial) : EmptyOffer(RequestType.MATERIAL)}
                        requestType={RequestType.MATERIAL}/>
        {#if !materialProvided || (!onOptimizerPage && isOfferRejected($offerMaterial))}
            <div class="material offer-unselected-blur">
                <h2>{@html getBlurText($offerMaterial, $t("RequestType.MATERIAL"))}</h2>
            </div>
        {/if}
    </div>
</div>


<style lang="scss">

  @use "../../styles/global";

  .offer-facts-info__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 7rem;
    grid-row-gap: 1.375rem;
    width: 100%;
  }

  .offer-facts-title {
    margin: 0 0 1rem;
    text-align: left;
  }

  .offer-facts-info__title {
    margin-left: 4px; // because of the border of the cards, we add a small margin

    & h3 {
      margin: 0;
    }
  }

  // in case we want to use it to select an offer (as offerer), we need to blur down one of the offers
  .selectable {
    :global(span) {
      transition: filter 0.4s;
      filter: none;
    }

    & > div:first-child {
      @include global.flex-row($justify: flex-start);
    }
  }

  .offer-facts-info_header {
    grid-row: 1/1;
  }

  .offer-unselected-blur {
    position: relative;
    opacity: 0.9;
    background: white;
    grid-row: 2/5;
    @include global.flex-row();
    padding: 25%;
    text-align: center;
  }

  .material {
    grid-column: 2/2;
  }

  .material_with_transport {
    grid-column: 1/1;
  }

  /* Tablets*/
  @media screen and (max-width: 992px) {
    .offer-facts-info__container {
      grid-template-columns: 1fr;
    }

    .material,
    .material_with_transport {
      grid-column: 1/1 !important;
    }

    .material.offer-facts-info_header {
      grid-row: 5/5;
    }

    .material.offer-unselected-blur {
      display: none; // we can hide the blur, since we show offers below each other on mobile and would just create a huge whitespace
    }

    .offer-unselected {
      display: none;

      & :global(+ div.offer-facts-info__data) {
        display: none; // we can hide them, since we show offers below each other on mobile and would just create a huge whitespace
      }

    }
  }

</style>
