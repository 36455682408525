<script lang="ts">

    import {t} from "../../i18n/i18n";
    import TextArea from "../atoms/TextArea.svelte";
    import InputField from "../molecules/InputField.svelte";
    import type {FontProps} from "../types/fontProps";
    import {DefaultRobotoFontProps} from "../types/fontProps";
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import Modal from "../organisms/Modal.svelte";
    import {closeModal, openModal} from "svelte-modals";
    import Icon from "../atoms/Icon.svelte";
    import {ButtonIcons} from "../types/enums";
    import type {Material} from "./types/material";
    import {EmptyMaterial, materialSchema} from "./types/material";
    import {inputFieldFormats} from "../utils/formatters";
    import {fetchUtils} from "../utils/fetchUtils";
    import {addNotification} from "../stores";
    import type {Notification} from "../types/notification";
    import {DefaultNotification, NotificationType} from "../types/notification";
    import {createEventDispatcher, getContext, onMount} from "svelte";
    import MaterialPropertyCard from "./MaterialPropertyCard.svelte";
    import type {MaterialProperty} from "./types/materialProperty";
    import {DefaultMaterialProperty} from "./types/materialProperty";
    import {AnimatePresence, AnimateSharedLayout, Motion, MotionConfig} from "svelte-motion";
    import {openCloseVariants} from "../utils/svelteMotionUtils";
    import {getFormErrors} from "../utils/formValidation";
    import type {Writable} from "svelte/store";
    import MaterialPropertySelection from "./MaterialPropertySelection.svelte";
    import MaterialPropertyModal from "./MaterialPropertyModal.svelte";
    import Checkbox from "../atoms/Checkbox.svelte";
    import type {Catalog} from "./types/catalog";
    import {checkUnedited} from "../utils/misc";
    import iconCancel from "@/icons/icon_cancel_black.svg";
    import iconTrash from "@/icons/icon_trash.svg";
    import addIcon from "@/icons/icon_add_rectangle.svg";
    import {deepEqual} from "../utils/utils";
    import PrimaryButton from "../atoms/PrimaryButton.svelte";

    const fontProps: FontProps = DefaultRobotoFontProps('0.875rem', '0.75rem');
    const headerFontProps: FontProps = DefaultRobotoFontProps('1.125rem', '1rem');
    const dispatch = createEventDispatcher();
    const MATERIAL_FORM_ERROR_PATH_PREFIX = 'material.';

    let properties: Writable<Map<string, MaterialProperty>> = getContext('materialProperties');
    let material: Writable<Material> = getContext('selectedMaterial');
    export let materialCopy: Material = {...$material};
    export let isNew: boolean = false;

    let selectedCatalog: Writable<Catalog> = getContext('selectedCatalog');
    let materialNameForHeader = ($material.parentCode ?? '') + $material.code + ' ...';
    $: $material, materialNameForHeader = ($material.parentCode ?? '') + $material.code + ($material.highlighted ? $selectedCatalog.highlightedValue : "") + ' ' + $material.name;

    $: if ($properties && $material.properties) {
        updateMaterialProperties()
    }

    let editedProperty: MaterialProperty = DefaultMaterialProperty();
    export let showEditProperty: boolean = false;

    let formErrors: Writable<{ path: string, message: string }[]> = getContext("formErrors");

    onMount(() => {
        // needed by first load of mmpv page with material in url to show and update properties
        if ($material.propertyIds) {
            dispatch('changeMaterial', {material: $material});
        }
    })

    function handleDelete() {
        openModal(Modal, {
            title: $t('UI.mmpv.delete.modal.title', {entity: $material.name}),
            message: !($material.childrenIds === undefined || $material.childrenIds.length === 0) ?
                $t('UI.mmpv.delete.not.possible.modal.message', {material: $material.name}) : $t('UI.mmpv.delete.modal.message', {material: $material.name}),
            denyText: $t('UI.delete'),
            denyDisabled: !($material.childrenIds === undefined || $material.childrenIds.length === 0),
            onDeny: deleteMaterial
        })
    }

    function deleteMaterial() {
        fetchUtils.post(`/api/catalog/${$selectedCatalog.id}/material/${$material.id}/delete`, $material)
            .then(() => {
                let deletedMaterialId = $material.id;
                let indexOfDeletedNode = $selectedCatalog.nodes.map(node => node.id).indexOf($material.id);
                let parentNodeId = $material.parentId;
                // remove old material from nodes and push new material
                if (indexOfDeletedNode > -1) {
                    $selectedCatalog.nodes.splice(indexOfDeletedNode, 1);
                    $selectedCatalog.nodes = $selectedCatalog.nodes; // self assignment to update
                }
                // remove old material in list of childrenIds of parent node
                let indexOfParentNode = $selectedCatalog.nodes.map(node => node.id).indexOf(parentNodeId);
                if (indexOfParentNode > -1) {
                    let indexToRemoveChildId = $selectedCatalog.nodes[indexOfParentNode].childrenIds.indexOf(deletedMaterialId);
                    $selectedCatalog.nodes[indexOfParentNode].childrenIds.splice(indexToRemoveChildId, 1);
                    $selectedCatalog.nodes = $selectedCatalog.nodes; // self assignment to update
                }
                // switch to next material after node was deleted
                // Case 1: node was root node and there are no other materials => create new empty node
                if (parentNodeId === 'root' && $selectedCatalog.nodes.length === 0) {
                    dispatch('addRoot');
                    // Case 2: node was root node and there are other materials => switch to first next root material
                } else if (parentNodeId === 'root') {
                    let indexNextRootMaterial = $selectedCatalog.nodes.map(node => node.parentId).indexOf('root');
                    dispatch('changeMaterial', {material: $selectedCatalog.nodes[indexNextRootMaterial]});
                }
                // Case 3: node was not parent node => switch to next parent node
                else {
                    dispatch('changeMaterial', {material: $selectedCatalog.nodes[indexOfParentNode]});
                }
                let notification = DefaultNotification($t('UI.mmpv.material.deleted.notification.success'), NotificationType.SUCCESS);
                addNotification(notification);
                closeModal();
            }).catch(fetchUtils.catchErrorAndShowNotification())
    }


    function handleRemoveEmpty() {
        let isMaterialUnedited: boolean = checkUnedited($material, materialCopy)
        if (isMaterialUnedited) {
            dispatch('removeNode', {material: $material})
        } else {
            openModal(Modal, {
                title: $t('UI.modal.unsavedChanges.header'),
                message: $t('UI.mmpv.material.delete.modal.message'),
                denyText: $t('UI.mmpv.material.delete.modal.button'),
                onDeny: () => {
                    dispatch('removeNode', {material: $material});
                    closeModal();
                }
            })
        }
    }

    async function addPropertyToMaterial(propertyId: string) {
        if ($material.propertyIds === undefined) {
            $material.propertyIds = []
        }
        $material.propertyIds.push(propertyId);
        let propertyToAdd = $properties.get(propertyId);
        propertyToAdd.isEditable = true;
        $material.properties.push(propertyToAdd);
        $material = $material; // needed if we're adding an existing prop
        showPropertyList();
    }

    function updateMaterialProperties() {
        let parentProperties = $material.properties.filter((prop: MaterialProperty) => !prop.isEditable);
        $material.properties = []
        if ($material.propertyIds === undefined) {
            $material.propertyIds = []
        }
        $material.propertyIds.forEach((propId: string) => {
            $material.properties.push($properties.get(propId))
        })
        parentProperties.forEach((prop: MaterialProperty) => $material.properties.push(prop));
        $material.properties = $material.properties;
    }

    async function saveMaterialAndGoToCatalog() {
        try {
            $material.properties = $material.properties.filter((property: MaterialProperty) => property.isEditable)
            let validatedMaterial = await materialSchema.validate($material, {
                stripUnknown: false,
                abortEarly: false
            });
            fetchUtils.post(`/api/catalog/${$selectedCatalog.id}/material/${validatedMaterial.id}/update`, validatedMaterial)
                .then((data) => {
                    let notification: Notification = DefaultNotification($t('UI.mmpv.material.saved.notification'), NotificationType.SUCCESS);
                    addNotification(notification);
                    // get indices of node in all nodes and in parent's children list before changing material
                    let indexOfOldMaterial = $selectedCatalog.nodes.map((node: Material) => node.id).indexOf($material.id);
                    indexOfOldMaterial = indexOfOldMaterial > -1 ? indexOfOldMaterial : $selectedCatalog.nodes.map((node: Material) => node.id).indexOf('new');
                    let parentNodeId = $material.parentId;
                    // update the material
                    let generatedLabel = (data.parentCode ?? '') + data.code + (data.highlighted ? $selectedCatalog.highlightedValue : "") + ' ' + data.name;
                    $material = {...EmptyMaterial(), ...data, ...{label: generatedLabel}} // also add regenerated label
                    // remove old material from nodes and push new material
                    if (indexOfOldMaterial > -1) {
                        $selectedCatalog.nodes.splice(indexOfOldMaterial, 1);
                        $selectedCatalog.nodes.push($material);
                        $selectedCatalog.nodes = $selectedCatalog.nodes; // self assignment to update
                    }
                    // remove the children id new and replace it with new publicId, if it has a parent (not root)
                    if (parentNodeId && parentNodeId !== null && parentNodeId !== "root") {
                        let parentNode = $selectedCatalog.nodes.find((node: Material) => node.id === parentNodeId);
                        let indexOfOldChild = parentNode.childrenIds.indexOf('new');
                        if (indexOfOldChild > -1) {
                            parentNode.childrenIds.splice(indexOfOldChild, 1);
                            parentNode.childrenIds.push($material.id)
                        }
                    }
                    $selectedCatalog.nodes = $selectedCatalog.nodes;
                    dispatch('changeMaterial', {material: $material});
                }).catch(fetchUtils.catchErrorAndShowNotification())
        } catch (error) {
            let err = getFormErrors(error, MATERIAL_FORM_ERROR_PATH_PREFIX);
            console.log('ValidationError: ');
            console.dir(err);
            $formErrors = err;
        }
    }

    function handleBackToCatalogs() {
        // $selectedCatalog = null; // unset, such that we are redirected to the other page
        materialCopy?.properties?.sort((a, b) => a.priority - b.priority).sort((a, b) => a.name - b.name);
        let isMaterialUnedited: boolean = deepEqual($material, materialCopy);
        if (isMaterialUnedited) {
            dispatch('backToCatalogs');
        } else {
            openModal(Modal, {
                title: $t('UI.modal.unsavedChanges.header'),
                message: $t('UI.mmpv.modal.material.unsavedChanges.message'),
                denyText: $t('UI.saveAndDiscardChanges'),
                onDeny: () => {
                    $material = materialCopy;
                    dispatch('backToCatalogs');
                    closeModal();
                }
            })
        }
    }

    function showPropertyList() {
        editedProperty = DefaultMaterialProperty();
        showEditProperty = false;
    }

    function editProperty(e) {
        if (e?.detail?.property ?? false) {
            editedProperty = {...e.detail.property};
        }
        showEditProperty = true;
    }

    function removePropertyFromCatalogStore(propertyToRemove: MaterialProperty) {
        // also remove property in catalog to prevent rebuild property after changing back to material
        const idxOfPropertyIdInCatalogToDelete = $selectedCatalog.nodes.map((node: Material) => node.id).indexOf($material.id)
        if (idxOfPropertyIdInCatalogToDelete > -1) {
            let idxOfPropertyIdInCatalog = $selectedCatalog.nodes[idxOfPropertyIdInCatalogToDelete].propertyIds.indexOf(propertyToRemove.id)
            if (idxOfPropertyIdInCatalog > -1) {
                $selectedCatalog.nodes[idxOfPropertyIdInCatalogToDelete].propertyIds.splice(idxOfPropertyIdInCatalogToDelete, 1);
            }
        }
        $material = $material;
    }

    function updateMaterialProperty(e) {
        const materialPropertyId = e.detail.propertyId;
        const updatedMaterialProperty = $properties.get(materialPropertyId);
        const idx = $material.properties.findIndex(prop => prop.id === materialPropertyId);
        const idxCopy = materialCopy.properties.findIndex(prop => prop.id === materialPropertyId);
        if (idx > -1 && idxCopy > -1) {
            $material.properties[idx] = updatedMaterialProperty;
            $material.properties?.sort((a, b) => a.priority - b.priority).sort((a, b) => a.name - b.name);
            $material = $material;
            materialCopy.properties[idxCopy] = updatedMaterialProperty;
            materialCopy = materialCopy;
        }
    }

    function removeProperty(e) {
        let propertyToRemove = e?.detail?.property;
        // remove property of persisted material with property
        if (e !== null && propertyToRemove !== null && $material.id !== "new") {
            fetchUtils.get(`/api/material/${$material.id}/property/${propertyToRemove.id}/remove`)
                .then(_ => {
                    // first remove property in selected material
                    const idxOfPropertyToDelete = $material.properties.indexOf(propertyToRemove);
                    if (idxOfPropertyToDelete > -1) {
                        $material.properties.splice(idxOfPropertyToDelete, 1);
                    }
                    // then remove propertyIDs in selected material
                    const idxOfPropertyIdToDelete = $material.propertyIds.indexOf(propertyToRemove.id);
                    if (idxOfPropertyToDelete > -1) {
                        $material.propertyIds.splice(idxOfPropertyIdToDelete, 1);
                    }
                    removePropertyFromCatalogStore(propertyToRemove);
                    $selectedCatalog = $selectedCatalog;
                })
                .catch(fetchUtils.catchErrorAndShowNotification());
            // remove property of local material with property
        } else if (e !== null && propertyToRemove !== null && $material.id === "new") {
            const idxOfPropertyToDelete = $material.properties.indexOf(propertyToRemove);
            if (idxOfPropertyToDelete > -1) {
                $material.properties.splice(idxOfPropertyToDelete, 1);
            }
            const idxOfPropertyIdToDelete = $material.propertyIds.indexOf(propertyToRemove.id);
            if (idxOfPropertyIdToDelete > -1) {
                $material.propertyIds.splice(idxOfPropertyIdToDelete, 1);
            }
            removePropertyFromCatalogStore(propertyToRemove)
        }
    }

    function createNewProperty() {
        openModal(MaterialPropertyModal, {
            materialProperty: DefaultMaterialProperty(),
            materialId: $material.id,
            updateMaterial: (propertyId: string) => {
                addPropertyToMaterial(propertyId);
            },
            parent: $material,
            isNew: true,
        })
    }

    $: disableAddNewPropertyBtn = !editedProperty.id || editedProperty.id === "new"

</script>


<div class="material-details-container">
    <div class="material-details-header">
        <span>{isNew ? $t('UI.mmpv.materialDetails.header.new', {material: materialNameForHeader}) : $t('UI.mmpv.materialDetails.header.edit', {material: materialNameForHeader})}</span>
        <div>
            {#if isNew}
                <Icon src={iconCancel} titleAttr={$t('UI.delete')} size={20} on:click={handleRemoveEmpty} clickable/>
            {:else}
                <Icon src={iconTrash} titleAttr={$t('UI.delete')} size={20} on:click={handleDelete} clickable/>
            {/if}
        </div>
    </div>
    <div class="material-details-body">
        <div>
            <InputField id="code_prefix" value={$material.parentCode} {fontProps} {headerFontProps}
                        label={$t('UI.mmpv.material.code.prefix')} format={inputFieldFormats.FULL}
                        errorPath={MATERIAL_FORM_ERROR_PATH_PREFIX + 'parentCode'} readOnly/>
            <InputField bind:value={$material.code} id="code_suffix" {fontProps} {headerFontProps}
                        placeholder="01" label={"Code-Suffix"} readOnly={!isNew} format={inputFieldFormats.FULL}
                        errorPath={MATERIAL_FORM_ERROR_PATH_PREFIX + 'code'} required/>
        </div>
        <div>
            <InputField bind:value={$material.name} id="material_name" {fontProps}
                        placeholder={$t('UI.mmpv.material.name.ph')}
                        label={isNew ? $t('UI.mmpv.materialName.header.newMaterial') : $t('UI.mmpv.materialName.header')}
                        format={inputFieldFormats.FULL} {headerFontProps}
                        errorPath={MATERIAL_FORM_ERROR_PATH_PREFIX + 'name'} required/>
            <Checkbox bind:checked={$material.highlighted} id="material_highlighted"
                      label={$t('UI.mmpv.material.highlighted.label')}/>
        </div>
        <div>
            <TextArea bind:text={$material.description} id="material_description"
                      title={$t('UI.mmpv.material.description')} placeholder={$t('UI.writeHere.ph')} marginTop="0"
                      labelGap="0.375rem" inputFontProps={fontProps} {headerFontProps}
                      errorPath={MATERIAL_FORM_ERROR_PATH_PREFIX + 'description'}/>
            <TextArea id="material_internalRemarks" bind:text={$material.comment}
                      title={$t('UI.mmpv.material.internalRemarks')} placeholder={$t('UI.writeHere.ph')} marginTop="0"
                      labelGap="0.375rem" inputFontProps={fontProps} {headerFontProps}
                      errorPath={MATERIAL_FORM_ERROR_PATH_PREFIX + 'internalRemarks'}/>
        </div>
    </div>
    <div style="width: 100%">
        <MotionConfig transition={{duration: 0.3, type: 'tween', stiffness: 80, damping: 9, mass: 0.55}}>
            <AnimateSharedLayout>
                <Motion>
                    <div style="width: 100%">
                        <AnimatePresence list={[{key: showEditProperty, showForm: showEditProperty}]} let:item>
                            <Motion let:motion={collapse} initial="collapsed" animate="open" exit="collapsed"
                                    variants={openCloseVariants}>
                                <div style="width: 100%" use:collapse class:propertySelection={item.showForm}>
                                    {#if item.showForm}
                                        <div class="material-property-create-row">
                                            <MaterialPropertySelection bind:materialProperty={editedProperty}
                                                                       alreadyMappedProperties={$material.properties}/>

                                            <PrimaryButton bind:disabled={disableAddNewPropertyBtn}
                                                           label={$t('UI.mmpv.property.addToMaterial')}
                                                           leftIcon={ButtonIcons.PLUS} whiteIcon sizeAdaptingToText
                                                           on:click={() => addPropertyToMaterial(editedProperty.id)}/>
                                        </div>
                                        <div class="property-buttons-row">
                                            <SecondaryButton label={$t('UI.button.cancel')}
                                                             color={SecondaryBtnColors.GREY} sizeAdaptingToText
                                                             on:click={showPropertyList}/>
                                            <SecondaryButton label={$t('UI.mmpv.materialProperty.createNew')}
                                                             sizeAdaptingToText on:click={createNewProperty}/>
                                        </div>
                                    {:else}
                                        <div class="material-properties">
                                            <div>
                                                <span>{$t('UI.mmpv.material.selectedProperties')}</span>
                                                <Icon src={addIcon} size={32}
                                                      titleAttr={$t('UI.mmpv.property.addToMaterial')} clickable
                                                      on:click={()=> editProperty(null)}/>
                                            </div>
                                            <div>
                                                {#if $material.properties && $material.properties.length > 0}
                                                    {#each $material.properties.sort((a, b) => a.priority - b.priority).sort((a, b) => a.name - b.name) as prop, idx}
                                                        <MaterialPropertyCard bind:property={prop} {idx}
                                                                              isEditable={prop.isEditable}
                                                                              on:editProperty={editProperty}
                                                                              on:removeProperty={removeProperty}
                                                                              on:updateMaterialProperty={updateMaterialProperty}/>
                                                    {/each}
                                                {/if}
                                            </div>
                                        </div>
                                        <div class="buttons-row">
                                            <SecondaryButton label={$t('UI.mmpv.backToCatalogs')}
                                                             leftIcon={ButtonIcons.LEFT_ARROW} sizeAdaptingToText
                                                             on:click={handleBackToCatalogs}/>
                                            <PrimaryButton id="btn-create-new-material" leftIcon={ButtonIcons.CHECKMARK}
                                                           label={isNew ? $t('UI.mmpv.createMaterial') : $t('UI.button.save')}
                                                           whiteIcon sizeAdaptingToText
                                                           on:click={saveMaterialAndGoToCatalog}/>
                                        </div>
                                    {/if}
                                </div>
                            </Motion>
                        </AnimatePresence>
                    </div>
                </Motion>
            </AnimateSharedLayout>
        </MotionConfig>
    </div>
</div>


<style lang="scss">
  @import "../../styles/global";

  .material-details-container {
    width: 100%;
    max-width: 1000px; // TODO remove later
    position: sticky;
    top: 3rem;
    @include flex-col($row-gap: 1.88rem);

    & > .material-details-header {
      @include flex-row($justify: space-between);
      width: 100%;

      & > span {
        @include roboto-font(1.5rem, 400, 1.25rem, black);
        width: 90%;
        user-select: text;
      }

      & > div {
        width: 32px;
        height: 32px;
        border: 2px solid $primaryRed;
        border-radius: 2px;
        @include flex-col();
      }
    }

    & > .material-details-body {
      width: 100%;
      @include flex-col($row-gap: 1.88rem);

      & > div {
        width: 100%;
        @include flex-row($col-gap: 1.88rem, $alignment: flex-end);
      }
    }
  }

  div.propertySelection {
    @include flex-col($row-gap: 1.5rem);
    width: 100%;
  }

  .buttons-row {
    width: 100%;
    @include flex-row(0.875rem, $justify: flex-end);
    margin-top: 20px;
  }

  .property-buttons-row {
    @include flex-row(0.375rem, $justify: flex-end);
    width: 100%;
  }

  .material-properties {
    width: 100%;
    @include flex-col(1.25rem, $justify: flex-start, $alignment: flex-start);

    & > div:first-child {
      @include flex-row($justify: space-between);
      width: 100%;

      & > span:first-child {
        @include roboto-font(19px, 400, 1rem, black);
      }
    }

    & > div:last-child {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, 160px);
      grid-template-rows: repeat(auto-fill, 66px);
      grid-column-gap: 0.875rem;
      grid-row-gap: 0.875rem;
      min-height: fit-content;
      margin-bottom: 20px;
    }
  }

  div.material-property-create-row {
    @include flex-row($col-gap: 0.375rem, $alignment: flex-end);
    width: 100%;
  }

  .add-icon {
    width: 24px;
    height: 24px;
    background: url("@/icons/icon_add_rectangle.svg") no-repeat;
    background-size: contain;

    &:hover {
      cursor: pointer;
    }
  }
</style>
