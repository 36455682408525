import {DiscardReason} from "../../types/enums";
import type {Facts} from "./facts";

export enum RequestType {
    MATERIAL_WITH_TRANSPORT = "MATERIAL_WITH_TRANSPORT", // Frei Bau
    MATERIAL = "MATERIAL", // Annahme / Abgabe
    // TRANSPORT = "TRANSPORT",
}

// renamed to request dto, as there already exists a Request class and like this we can prevent clashes
export type RequestDto = {
    publicId: string,
    facts: Facts,
    created: number, // local date
    rejectedByOptimizer: boolean,
    visibleComment: string,
    types: RequestType[],
}

export enum RequestedEntityType {
    LOT = "lot",
    POP = "pop",
}
