<script lang="ts">

    import FileUpload from "../../../organisms/FileUpload.svelte";
    import {fetchUtils} from "../../../utils/fetchUtils";
    import {currentProject} from "../utils/fakStores";


    let attachments;
    let uploadUrl;
    const filePrefix = ""; // TODO make file prefix
    const hideHints = true;
    const visibilityUrl = "/api/project/attachment/";

    async function fetchAttachments() {
        await fetchUtils.get(`/api/project/${$currentProject.id}/attachments`, false)
            .then(data => {
                attachments = data;
            })
            .catch(fetchUtils.catchErrorAndShowNotification());
    }

    $: uploadUrl = `/api/project/${$currentProject.id}/attachment/new`;
</script>

<div style="padding: 0 1.5625rem 1.5rem;">
    <FileUpload bind:attachments
                readOnly={$currentProject.isSaasProject}
                {uploadUrl}
                {filePrefix}
                hideHints={!$currentProject.isSaasProject}
                updateFun={fetchAttachments}
                showVisibilityManagement={true}
                {visibilityUrl}/>
</div>
