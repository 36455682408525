<script lang="ts">
    /* https://svelte-modals.mattjennings.io/api */
    import {closeModal} from 'svelte-modals';
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import Icon from "../atoms/Icon.svelte";
    import {t} from "../../i18n/i18n";
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import {fly} from 'svelte/transition';
    import type {ButtonIcons} from "../types/enums";
    import iconClose from "@/icons/icon_close.svg";


    // provided by <Modals/>
    export let isOpen: boolean;
    export let title: string;
    export let showCloseIcon: boolean = false;
    export let message: string = "";
    export let component = null;
    export let componentProps = {};
    // apart from specifying components that are displayed in the modal, we can also make a slot in the middle and create different version of the modal,
    // then use them via openModal(ModalXYZ, {...}) as done with this modal


    // these are functions, that need to be defined when calling openModal(ModalXYComponent, {title: ..., ...})
    export let onAccept = () => {
    };
    export let acceptText: string = "";
    export let acceptIcon: ButtonIcons;
    export let acceptDisabled: boolean = false;
    export let onDeny = () => {
    };
    export let denyText: string = "";
    export let denyIcon: ButtonIcons;
    export let denyDisabled: boolean = false;
    export let padding: string = "1.75rem 4.75rem";
    export let maxWidth: string = "100%";

</script>

{#if isOpen}
    <div role="dialog" class="modal" in:fly="{{ y: -100, duration: 600 }}" out:fly={{y: -200, duration: 600}}>
        <div class="contents" style="--content-padding: {padding}; max-width: {maxWidth}">
            {#if showCloseIcon}
                <div class="cancel-btn" on:click={closeModal}>
                    <Icon src={iconClose} size={24}/>
                </div>
            {/if}

            <div class="actual-content">
                <h1 style="text-align: center">{@html title}</h1>
                {#if message && message !== ""}
                    <p>{@html message}</p>
                {/if}
                {#if component && component !== null}
                    <svelte:component this={component} {...componentProps}/>
                {/if}
                <slot name="content"/>
            </div>
            <div class="actions">
                <SecondaryButton label={$t('UI.button.cancel')} color={denyText && denyText !== "" ? SecondaryBtnColors.RED : SecondaryBtnColors.GREY} on:click={closeModal}
                                 sizeAdaptingToText/>
                {#if acceptText && acceptText !== ""}
                    <PrimaryButton label={acceptText} color={PrimaryBtnColors.GREEN} on:click={onAccept}
                                   leftIcon={acceptIcon} disabled={acceptDisabled} sizeAdaptingToText whiteIcon/>
                {/if}
                {#if denyText && denyText !== ""}
                    <PrimaryButton label={denyText} color={PrimaryBtnColors.RED} on:click={onDeny} leftIcon={denyIcon}
                                   disabled={denyDisabled} sizeAdaptingToText/>
                {/if}
            </div>
        </div>
    </div>
{/if}

<style lang="scss">
  @import "../../styles/modals"; // also includes global.scss

  p {
    @include roboto-font(14px, 400, 0.75rem);
    margin-top: 1rem;
    text-align: center;
  }

  .actual-content {
    width: 100%;
    @include flex-col();

    & p:first-child {
      color: #949494;
      @include roboto-font(1.125rem, 500, 0.75rem, #949494);
      text-align: center;
    }
  }

</style>
