/**
 * Change the URL pathname of the current window.
 * The window's history state is appended to the history.
 *
 * @param {string} newUrl - The new URL pathname to change to.
 * @param {object} window - The window object.
 *
 * @return {void}
 */
export function changeUrl(newUrl: string, window: Window): void {
    const url = new URL(window.location.href);
    url.pathname = newUrl;
    window.history.pushState({}, document.title, url);
}

/**
 * Replaces the current URL path with a new path and updates the URL parameters.
 * If searchParams are provided, the search params of the URL will be updated using the given parameters.
 * If searchParams are not provided, only the URL path will be updated.
 * The window's history state is replaced.
 *
 * @param {string} newUrl - The new URL path to replace with.
 * @param {Window} window - The window object.
 * @param {URLSearchParams} [searchParams] - The search parameters to update the URL with.
 * @returns {void}
 */
export function replaceUrl(newUrl: string, window: Window, searchParams: URLSearchParams | undefined = undefined): void {
    const url = new URL(window.location.href);
    if (searchParams) {
        url.search = searchParams.toString();
    }
    url.pathname = newUrl;
    window.history.replaceState({}, document.title, url);
}

/**
 * Check if an object hasn't been modified. We compare two objects whereas one object should be a copy from the other
 * object made at the beginning before any (potential) editing
 * @param {any} el - Original element.
 * @param {any} elCopy - Copied element.
 * @return {boolean} Object has not been edited.
 */
export function checkUnedited(el: any, elCopy: any): boolean {
    return Object.keys(el).every(key => (elCopy?.hasOwnProperty(key) && el?.hasOwnProperty(key)) ? el[key] === elCopy[key] : false)
}

/**
 * taken from https://github.com/Rich-Harris/yootils/blame/master/src/number/clamp.js
 * Clamp `num` to the range `[min, max]`
 * @param {number} num
 * @param {number} min
 * @param {number} max
 */
export function clamp(num: number, min: number, max: number) {
    return num < min ? min : num > max ? max : num;
}

