<script lang="ts">

    import Permission from "../Permission.svelte";
    import {t} from "../../../i18n/i18n";

    export let title: string | null = null;
    export let titleKey: string | null = null;
    export let url: string = "#";
    export let permissions: string | null = null;
</script>

<Permission requiredPermissions={permissions}>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <li role="menuitem" on:click style="width: 100%">
        <a class="dropdown__item" href={url}>{title ?? $t(titleKey)}</a>
    </li>
</Permission>

<style lang="scss">
  @use "../../../styles/menubar";
</style>