import type {AddressData} from "./addressData";
import {addressSchema} from "./addressData";
import {ProjectState} from "./enums";
import type {UserAccountData} from "./userAccountData";
import {DefaultUserAccountData, userAccountDataSchema} from "./userAccountData";
import {translate} from "../../i18n/i18n";
import {object, string} from "yup";
import type {PersonAndCompanyData} from "./personAndCompanyData";
import {copyPersonAndCompanyData, personAndCompanySchema} from "./personAndCompanyData";

export type Project = {
    address: AddressData,
    caseOwner: UserAccountData, //possibly ContactData // todo differentiate between caseOwner and owner
    code: string,
    commentAccounting: string,
    commentBackoffice: string,
    commentSales: string,
    contactPersonAndCompany: PersonAndCompanyData,
    correlationId: string,
    endDate: string,
    id: string,
    isEditable: boolean;
    label: string,
    owner: UserAccountData,
    projectName: string,
    remarks: string,
    startDate: string,
    state: ProjectState,
    canBeCanceled: boolean,
    isSaasProject: boolean,
}

export function DefaultProject() {
    let project: Project = {
        address: null,
        caseOwner: null,
        code: "",
        commentAccounting: "",
        commentBackoffice: "",
        commentSales: "",
        contactPersonAndCompany: null,
        correlationId: "",
        endDate: "",
        id: "new",
        isEditable: true,
        label: "Projekt MM-1",
        owner: DefaultUserAccountData(),
        projectName: "",
        remarks: "",
        startDate: "",
        state: ProjectState.OPEN,
        canBeCanceled: false,
        isSaasProject: false,
    };
    return project;
}

// Keep in mind that once we change anything here or in the typescript type, it could be that we need to adapt the path for the error messgaes as well
export const projectSchema = object({
    address: addressSchema.required(translate('de', 'UI.validation.address.required', [])),
    caseOwner: userAccountDataSchema.required(translate('de', 'UI.validation.caseOwner.required', [])),
    // code: , not needed for server
    commentAccounting: string().notRequired(),
    commentBackoffice: string().notRequired(),
    commentSales: string().notRequired(),
    contactPersonAndCompany: personAndCompanySchema.required(translate('de', 'UI.validation.person.required', [])),
    correlationId: string().notRequired(),
    endDate: string().notRequired(),
    id: string().required(),
    // isEditable: not needed for server
    // label: not needed for server
    // owner: not needed yet TODO validate for saas
    projectName: string().required(translate('de', 'UI.validation.project.name.required', [])),
    remarks: string().notRequired(),
    startDate: string().notRequired(),
    // state: not needed for server
    // isSaasProject: not needed for server
}).required()

export function copyProject(project: Project, regenerateIds = false): Project {
    let copy: Project = DefaultProject();
    Object.assign(copy, project);
    if (project.address) {
        Object.assign(copy.address, project.address);
    }
    copy.contactPersonAndCompany = copyPersonAndCompanyData(project.contactPersonAndCompany, regenerateIds);
    if (project.caseOwner) {
        Object.assign(copy.caseOwner, project.caseOwner);
    }
    copy.owner = {...project.owner}
    return copy;
}
