window.sirius = window.sirius || {};

function asFunction(possibleFunction) {
    return typeof possibleFunction === "function" ? possibleFunction : Function();
}

sirius.createAutocomplete = function () {

    /**
     * Small object which defines some properties (width & position) of the completion div.
     *
     * The anchor can be the same as the input field but it is not necessary.
     * @@type {{name: string, element: undefined, init: init}}
     */
    var anchor = {
        selector: "#autocomplete-anchor",
        element: undefined,

        init: function (selector) {
            if (selector !== undefined) {
                this.selector = selector;
            }

            this.element = $(this.selector);

            if (!this.element.length) {
                throw "Anchor does not exist"
            }
        }
    };

    /**
     * Represents the input field of the autocomplete.
     *
     * Events on the input field like onChange are defined here.
     *
     * @@type {{name: string, element: undefined, spinnerElement: undefined, spinnerTemplate: string, showSpinner: showSpinner, hideSpinner: hideSpinner, onChange: onChange, onNonInputKeys: onNonInputKeys, init: init}}
     */
    var input = {
        name: "autocomplete-input",
        element: undefined,
        spinnerElement: undefined,
        spinnerTemplate: '<a href="#" class="autocomplete-spinner input-width-js hide" tabindex="-1"><i class="fa fa-spinner fa-spin"></i></a>',
        preventCreateToken: false,

        /**
         * Shows a small spinner in the input field whenever the service loads data.
         */
        showSpinner: function () {
            if (input.spinnerElement) {
                input.spinnerElement.removeClass("hide");

                events.onSpinnerShow.forEach(function (handler) {
                    handler();
                });
            }
        },

        /**
         * Hides the spinner.
         */
        hideSpinner: function () {
            if (input.spinnerElement) {
                input.spinnerElement.addClass("hide");

                events.onSpinnerHide.forEach(function (handler) {
                    handler();
                });
            }
        },

        /**
         * This method gets called each time the user input is changed.
         */
        onChange: function () {
            var value = this.element.val();

            events.beforeLoad.forEach(function (handler) {
                handler(value);
            });

            if (service.configured) {
                service.load(value);
            }

            if (localSource.configured) {
                localSource.load(value);
            }
        },

        /**
         * Will handle key up events that should not be handled as input change but as something else.
         *
         * @@return boolean true if the event was handled, else false
         */
        onNonInputKeys: function (event) {
            if (event.keyCode === sirius.keys.KEY_ENTER || event.keyCode === sirius.keys.KEY_TAB) {
                completions.select(completions.selectedRow);
                return true;
            }

            if (event.keyCode === sirius.keys.KEY_ARROW_DOWN) {
                if (!completions.isVisible()) {
                    // completions are not loaded yet, just wait untill the service.load() function calls
                    // completions.show()
                    return false;
                }

                if (completions.selectedRow === undefined) {
                    completions.hoverRow(completions.element.find(".autocomplete-row-js").first())
                } else {
                    var nextRow = completions.selectedRow.nextAll(".autocomplete-row-js").first();

                    if (nextRow.length) {
                        completions.unHoverRow(completions.selectedRow);
                        completions.hoverRow(nextRow);
                    }
                }
                return true;
            }

            if (event.keyCode === sirius.keys.KEY_ARROW_UP) {
                if (completions.selectedRow !== undefined) {
                    var prevRow = completions.selectedRow.prevAll(".autocomplete-row-js").first();

                    completions.unHoverRow(completions.selectedRow);

                    if (prevRow.length) {
                        completions.hoverRow(prevRow);
                    }
                } else {
                    completions.hideImmediate();
                }

                return true;
            }

            if (event.keyCode === sirius.keys.KEY_ESC) {
                completions.hideImmediate();
                return true;
            }


            completions.selectedRow = undefined;
            return false;
        },


        /**
         * Initializes the input field.
         *
         */
        init: function (config) {
            this.element = $("#" + this.name);

            if (config && !config.noSpinner) {
                input.spinnerElement = $(input.spinnerTemplate);
                input.spinnerElement.insertAfter(input.element);
            }

            this.element.keyup(function (event) {
                sirius.keys.enableKeyUpIpadSupport(event);

                if (input.onNonInputKeys(event) === false) {
                    input.onChange();
                }
            });

            this.element.keydown(function (event) {
                if (!completions.isVisible()) {
                    return;
                }

                // disables the default key_arrow_down event (moves cursor after the last char of the input)
                // and key_arrow_up event (moves cursor before the first char of the input)
                if (event.keyCode === sirius.keys.KEY_ARROW_UP || event.keyCode === sirius.keys.KEY_ARROW_DOWN) {
                    event.preventDefault();
                }
            });

            this.element.focusout(function (event) {
                var $relatedTarget = $(event.relatedTarget);
                if ($relatedTarget.hasClass('autocomplete-wrapper') || $relatedTarget.hasClass('autocomplete-row-js')) {
                    if (this.value.length) {
                        input.preventCreateToken = true;
                    }

                    // focus has to be called in setTimeout because of 19+ years old firefox bug..
                    // else the input field will not be re-focused in firefox
                    // https://bugzilla.mozilla.org/show_bug.cgi?id=53579
                    var that = this;
                    setTimeout(function () {
                        that.focus();
                    });

                    return false;
                }
                completions.hide();
            });

            this.element.focusin(function () {
                if (localSource.configured) {
                    localSource.load($(this).val());
                }
                if (service.configured && service.minSize === 0) {
                    service.load($(this).val());
                }
            });

            this.element.click(function () {
                if (completions.isVisible()) {
                    return;
                }
                if (localSource.configured) {
                    localSource.load($(this).val());
                }
                if (service.configured) {
                    service.load($(this).val());
                }
            });
        }
    };

    /**
     * Represents autocomplete suggestions html element.
     *
     * Handles the events & actions on the different rows in the element.
     */
    var completions = {
        element: undefined,
        selectedRow: undefined,
        rows: [],
        rowTemplates: undefined,
        HIDDEN: 0,
        VISIBLE: 1,
        SOON_TO_BE_HIDDEN: 2,
        state: undefined,

        config: undefined,

        /**
         * Initializes the completions element.
         *
         * Keys of the config object are:
         * - id: the id of the autocomplete wrapper div
         * - width: the width for the element if it should differ from the anchor
         * - height: the max-height of the element
         */
        init: function (config) {
            this.config = config;
            var wrapper = "<div tabindex='0' class='well autocomplete-wrapper autocomplete-wrapper-js' {{#id}}id='{{id}}'{{/id}}></div>";

            this.state = completions.HIDDEN;

            this.element = $(Mustache.render(wrapper, config));


            this.element.on("mouseup.sirius-autocomplete", ".autocomplete-row-js", function () {
                completions.select($(this));
            });

            anchor.element.after(this.element);
        },

        rePosition: function () {
            if (this.config.width) {
                this.element.width(this.config.width);
            } else {
                this.element.width(anchor.element.outerWidth());
            }

            if (this.config.height) {
                this.element.css('max-height', this.config.height);
                this.element.css('overflow', 'auto');
            }

            var offset = this.element.offset();
            var top = anchor.element.offset().top + anchor.element.outerHeight();
            var left = anchor.element.offset().left;

            if (offset.top !== top || offset.left !== left) {
                offset.top = top;
                offset.left = left;

                this.element.offset(offset);
            }
        },

        /**
         * Adds the hoverRow && unHoverRow events to the given autocompletion row.
         *
         * @@param $row the row element or a child element or a autocompletion row.
         */
        addHoverRowEvent: function ($row) {
            $row.find(".autocomplete-row-js").hover(function () {
                completions.hoverRow($row);
            }, function () {
                completions.unHoverRow($row);
            });

            if ($row.hasClass("autocomplete-row-js")) {
                $row.hover(function () {
                    completions.hoverRow($row);
                }, function () {
                    completions.unHoverRow($row);
                });
            }
        },

        hoverRow: function ($row) {
            completions.selectedRow = $row;
            $row.addClass("autocomplete-selected-element");
            completions.scrollRowIntoView($row.get(0));

            events.onHoverRow.forEach(function (handler) {
                handler($row);
            });
        },

        /**
         * If the given row is outside the view, scroll it into view.
         *
         * Only used if the completions.height config is set, or else the element will just be as high as it
         * needs to be to display all completions without scrolling.
         *
         * @@param row the row to be scrolled into the view
         */
        scrollRowIntoView: function (row) {
            var container = row.parentNode;

            if (container.scrollTop > row.offsetTop) {
                // row is above the position the container scrolled to
                // -> we should scroll up so the row is on the top of the viewable space
                container.scrollTop = row.offsetTop;
                return;
            }

            var containerBottom = container.scrollTop + container.offsetHeight;
            var rowBottom = row.offsetTop + row.offsetHeight;
            if (containerBottom < rowBottom) {
                // row is below the bottom position the container scrolled to
                // -> we should scroll down so the row is on the bottom of the viewable space
                container.scrollTop = rowBottom - container.offsetHeight;
            }
        },

        unHoverRow: function ($row) {
            if ($row) {
                $row.removeClass("autocomplete-selected-element");
            }
            completions.selectedRow = undefined;

            events.onUnhoverRow.forEach(function (handler) {
                handler($row);
            });
        },

        setTemplates: function (templates) {
            if (typeof templates !== "object") {
                throw "templates is no object";
            }

            this.rowTemplates = templates;
        },

        show: function () {
            if (this.rows.length > 0 && document.activeElement === input.element.get(0)) {
                this.update();
                completions.element.show();
                this.state = completions.VISIBLE;
                completions.rePosition();

                events.onShow.forEach(function (handler) {
                    handler();
                });
            }
        },

        hide: function () {
            if (completions.state === completions.HIDDEN) {
                return;
            }

            completions.state = completions.SOON_TO_BE_HIDDEN;

            window.setTimeout(function () {
                /*
                 * This hack is necessary to allow the click event to be handled before the element is hidden.
                 *
                 * Order of events:
                 * 1. mouseDown
                 * 2. focusOut ( will hide the autocompletion)
                 * 3. click
                 *
                 * The focus check is necessary because the input field could already be focused (again).
                 * In that case the autocomplete should not be hidden.
                 */
                if (completions.state === completions.VISIBLE || input.element.is(":focus")) {
                    return;
                }

                completions.hideImmediate();
            }, 500);
        },

        hideImmediate: function () {
            if (completions.state === completions.HIDDEN) {
                return;
            }
            completions.state = completions.HIDDEN;
            if (completions.selectedRow) {
                events.onBeforeHide.forEach(function (handler) {
                    handler(completions.selectedRow);
                });
            }
            completions.unHoverRow(completions.selectedRow);

            completions.element.hide();
            input.hideSpinner();

            events.onHide.forEach(function (handler) {
                handler();
            });
        },

        isVisible: function () {
            return completions.state === completions.VISIBLE;
        },

        /**
         * Retrieves the template for the given type.
         *
         * @@param type string which represents the type name
         * @@returns string representing the template of the given row type
         */
        templateForType: function (type) {
            if (type && Object.keys(this.rowTemplates).includes(type)) {
                return this.rowTemplates[type];
            }

            return this.rowTemplates.basic;
        },

        /**
         * Updates the rows in the autocomplete wrapper
         */
        update: function () {
            this.element.empty();

            if (this.rows.length < 1) {
                completions.hideImmediate();
                return;
            }

            $.each(this.rows, function (index, row) {
                var template = completions.templateForType(row.type);

                events.beforeRenderRow.forEach(function (handler) {
                    handler(row);
                });

                var $row = $(Mustache.render(template, row));
                completions.addHoverRowEvent($row);
                completions.element.append($row);

                events.afterRenderRow.forEach(function (handler) {
                    handler($row);
                });
            });

            events.afterRender.forEach(function (handler) {
                handler();
            });
        },

        /**
         * Called if a row of the autocomplete is selected.
         *
         * Retrieves the actual row element associated with the selected $element
         * and calls events.onSelect(selectedRow).
         *
         * Will call events.onSelect() if no valid current row was selected.
         *
         * @@param $element the element that was selected
         */
        select: function ($element) {
            var result = false;

            if (events.onSelect !== undefined) {
                if ($element) {
                    var selectedElement = $element.closest(".autocomplete-row-js");
                    if (selectedElement.length) {
                        result = events.onSelect(selectedElement);
                    } else {
                        result = events.onSelect();
                    }
                } else {
                    result = events.onSelect();
                }
            }

            if (!result) {
                completions.hideImmediate();
            }
        }
    };

    var events = {
        onReady: [],
        onShow: [],
        onHide: [],
        onBeforeHide: [],

        /**
         * Handle the selection of a row element.
         * <p>
         * Allows only one handler.
         *
         * @@param the selected element, optional
         * @@return <tt>true</tt> if the result overlay should stay visible, optional
         */
        onSelect: undefined,
        onSpinnerShow: [],
        onSpinnerHide: [],
        beforeLoad: [],

        /**
         * Called when the user hovers over a row of the sugestions or jumps to a row with the arrow keys.
         *
         * Called with the jQuery object of the hovered row.
         */
        onHoverRow: [],

        /**
         * Called when the user stops hovering over a certain row or jumps to another row with the arrow keys.
         *
         * Called with the jQuery object of the now unhovered row.
         */
        onUnhoverRow: [],

        /**
         * Handles the loaded autocompletion data, after a successful request.
         * <p>
         * Allows only one handler.
         *
         * @@param value the autocomplete string value
         * @@param the response of the autocomplete service
         * @@return an array of row objects.
         */
        afterLoad: undefined,

        /**
         * Called when the request to the service returned an error.
         */
        onServiceError: undefined,

        /**
         * Is called before a row of the autocomplete is rendered.
         *
         * Called with the 'row'-object, which can be changed inside the handler.
         */
        beforeRenderRow: [],
        afterRenderRow: [],
        afterRender: []
    };

    /**
     * An object to get the autocomplete data from a service.
     */
    var service = {
        uri: undefined,
        minSize: 1,
        delay: 400,
        configured: false,

        responseCache: {},
        lastLoadTriggered: undefined,
        loading: false,

        /**
         * Provides the request object to be sent to the autocomplete service.
         *
         * Method can be overwritten with config.service.getRequest.
         *
         * @@param inputValue the actual string in the search input field
         * @@returns {{query: *}} request object to be sent to the autocomplete service
         */
        getRequest: function (inputValue) {
            return {query: inputValue};
        },

        isLoading: function () {
            return service.loading;
        },

        load: function (value) {
            service.lastLoadTriggered = Date.now();
            service.loading = true;
            input.showSpinner();

            setTimeout(function () {
                if (service.lastLoadTriggered + service.delay <= Date.now()) {
                    service.loadImmediate(value);
                }
            }, service.delay)
        },

        loadImmediate: function (value) {
            var request = this.getRequest(value);
            if (request.query === undefined || request.query.length < this.minSize) {
                completions.hide();
                input.hideSpinner();
                return;
            }

            // load only if query is not already cached
            if (this.responseCache[request.query] === undefined) {
                $.getJSON(service.uri, request, function (response) {
                    if (response.error) {
                        throw response.message;
                    } else {
                        // successful response
                        service.responseCache[request.query] = events.afterLoad(value, response);
                        service.afterLoad(request);
                    }
                }).error(function () {
                    if (events.onServiceError) {
                        service.responseCache[request.query] = events.onServiceError(request);
                        service.afterLoad(request);
                    }
                });
            } else {
                service.afterLoad(request);
            }
        },

        afterLoad: function (request) {
            completions.rows = service.responseCache[request.query]
            service.loading = false;

            input.hideSpinner();

            if (completions.rows.length > 0) {
                completions.show();
            } else {
                completions.hide();
            }
        },

        /**
         * Keys of the config object are:
         * - serviceUri: the uri of the autocompletion service
         * - minSize: the minimal length of the input field value before a service request is created
         * - getRequest: function to build the request object
         * - cache: object to be used for caching
         * - delay: the delay time for a service call
         */
        init: function (config) {
            if (config.serviceUri === undefined) {
                throw "ServiceUri required";
            }
            if (events.afterLoad === undefined) {
                throw "The event afterLoad must be set.";
            }

            this.configured = true;

            this.uri = config.serviceUri;

            if (config.minSize !== undefined) {
                this.minSize = config.minSize;
            }

            if (typeof config.getRequest === "function") {
                this.getRequest = config.getRequest;
            }

            if (typeof config.cache === "object") {
                this.responseCache = config.cache;
            }

            if (typeof config.delayTime !== 'undefined') {
                this.delay = config.delayTime;
            }
        }
    };

    /**
     * An object to get the autocomplete data from a local javascript function.
     */
    var localSource = {
        configured: false,
        callback: undefined,

        init: function (config) {
            this.configured = true;
            this.callback = config.callback
        },

        load: function (value) {
            completions.rows = this.callback(value);

            if (completions.rows.length > 0) {
                completions.show();
            } else {
                completions.hide();
            }
        }
    };

    return {
        eventNames: function () {
            return Object.keys(events);
        },

        /**
         * Adds event handlers.
         *
         * @@param name of the event
         * @@param callback the function to be executed if the event is triggered
         */
        on: function (name, callback) {
            if (!this.eventNames().includes(name)) {
                throw "Callback " + name + " is no valid event.";
            }

            if (Array.isArray(events[name])) {
                events[name].push(asFunction(callback));
            } else {
                events[name] = asFunction(callback);
            }
        },

        /**
         * Unbinds one or all event handlers.
         *
         * @@param name of the event
         * @@param callback the function to unbind, if left empty all handlers are removed
         */
        off: function (name, callback) {
            if (!this.eventNames().includes(name)) {
                throw "Callback " + name + " is no valid event.";
            }

            if (Array.isArray(events[name])) {
                if (callback === undefined) {
                    events[name] = [];
                    return;
                }

                var remainingHandlers = events[name].filter(function (value) {
                    return value !== asFunction(callback);
                });

                events[name] = remainingHandlers;
            } else if (callback === undefined || events[name] === asFunction(callback)) {
                events[name] = undefined;
            }
        },

        config: undefined,
        init: function (config) {
            if (config) {
                this.config = config;
            }
        },

        /**
         * Method to initialize and start the autocompleter.
         *
         * Keys of the config object are:
         * - service: the config object of the service
         * - inputField: the input field id to autocomplete
         * - anchor: the id of the anchor div
         * - completions: the config object of the completions
         * - templates: the row templates
         */
        start: function (config) {
            this.init(config);

            if (this.config.service) {
                service.init(this.config.service);
            }

            if (this.config.localSource) {
                localSource.init(this.config.localSource)
            }

            if (!this.config.localSource && !this.config.service) {
                throw "Either 'service' or 'localSource' must be configured.";
            }

            input.name = this.config.inputField;

            input.init(this.config.input);
            anchor.init(this.config.anchor);
            completions.init(this.config.completions || {});

            completions.setTemplates(this.config.templates);

            events.onReady.forEach(function (handler) {
                handler();
            });
        },

        /**
         * Allows to manually set the input of the autocomplete input field.
         *
         * @@param value of the input field
         * @@param silent if true the onChange will not be called
         */
        setInput: function (value, silent) {
            input.element.val(value);

            if (!silent) {
                input.onChange();
            }
        },

        getInput: function () {
            return input.element;
        },

        focus: function () {
            input.element.focus();
        },

        isVisible: function () {
            return completions.isVisible();
        },

        rePosition: function () {
            return completions.rePosition();
        },

        /**
         * Getter for the currently selected row.
         *
         * A selected row is a row of the suggested completions. It is selected by the arrow keys on the keyboard
         * or by hovering over the row with the mouse pointer.
         *
         * If no completions are shown no row can be selected and the return value is undefined.
         * @@returns jquery object representing the selected row or undefined if non is selected
         */
        getCurrentSelection: function () {
            return this.isVisible() ? completions.selectedRow : undefined;
        },

        getSelectedRow: function () {
            return completions.selectedRow;
        },

        /**
         * Hides the suggested completions.
         */
        hide: function () {
            completions.hideImmediate();
        },

        getCompletionRows: function () {
            return completions.rows || [];
        },

        isPreventCreateToken: function () {
            return input.preventCreateToken;
        },

        setPreventCreateToken: function (preventCreateToken) {
            input.preventCreateToken = preventCreateToken;
        }
    };
};

sirius.autocomplete = sirius.createAutocomplete();
