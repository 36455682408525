<script lang="ts">
    import {inputFieldFormats} from "../utils/formatters";
    import Card from "../atoms/Card.svelte";
    import InputField from "../molecules/InputField.svelte";
    import type {Offer} from "./types/offer";
    import {t} from "../../i18n/i18n"
    import {createEventDispatcher, setContext} from "svelte";

    const dispatch = createEventDispatcher();
    setContext("useMaterialDesign", true);

    export let offer: Offer;

</script>

<Card borderRadius="0.625rem" boxShadow=""> <!-- to unset, boxShadow needs to be empty -->
    <div class="offer-facts-place-container">
        <InputField id="exclPlace"
                    bind:value={offer.offerLocation} format={inputFieldFormats.FULL}
                    label={$t('UI.counterOffer.acceptPlace.label')}
                    placeholder={$t("UI.counterOffer.acceptPlace.ph")}
                    on:change={() => dispatch("editOffer")}/>
    </div>
</Card>

<style lang="scss">
  @use "../../styles/global";

  .offer-facts-place-container {
    width: 100%;
    padding: 1.25rem;
  }

</style>

