<script lang="ts">
    /* https://svelte-modals.mattjennings.io/api */
    import {closeModal} from 'svelte-modals';
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import {t} from "../../i18n/i18n";
    import {fly} from 'svelte/transition';
    import Tooltip from "../atoms/Tooltip.svelte";
    import {TooltipPlacements} from "../types/enums";


    // provided by <Modals/>
    export let isOpen;
    export let title;
    export let ttContent;
    export let content = "";
    let paddingHorizontal = '1.375rem';
    let paddingTop = '0.75rem';
    let paddingBottom = '0.688rem';
    let borderRadius = '2px';


</script>

{#if isOpen}
    <div role="dialog" class="modal" in:fly="{{ y: -100, duration: 600 }}" out:fly={{y: -200, duration: 600}}>
        <div class="contents-large">
            <div class="contents-header">
                <h1 class="header">{@html title}</h1>
                <Tooltip maxWidthTtip={370}
                         msg={ttContent}
                         placementTtip={TooltipPlacements.RIGHT}
                         isHtml/>
            </div>

            <div class="actual-content">
                {#if content && content !== ""}
                    <div>{@html content}</div>
                {/if}
                <slot name="content"/>
            </div>
            <div class="actions">
                <SecondaryButton label={$t('UI.button.cancel')} color={SecondaryBtnColors.GREEN} on:click={closeModal} {paddingHorizontal} {paddingBottom} {paddingTop} {borderRadius} sizeAdaptingToText/>
            </div>
        </div>
    </div>
{/if}

<style lang="scss">
  @import "../../styles/modals"; // also includes global.scss

  .actual-content {
    width: 100%;
    @include flex-col();
  }

  .actions {
    @include flex-row(0.375rem, $justify: flex-end);
    width: 100%;
    margin-top: 1.125rem;
    border-radius: 0 0 6px 6px;
    margin-right: 5.75rem;
    margin-bottom: 3.875rem;
  }

  .contents-large {
    @include roboto-font(1rem);
    @include flex-col($justify: space-between);
    position: relative;
    width: 920px;
    max-width: 100%;
    line-height: 150%;
    border-radius: 6px;
    padding: 1.75rem 4.75rem;
    background: white;
    pointer-events: auto;
    box-shadow: 0 20px 25px -5px rgba(9, 9, 9, 0.1), 0 10px 10px -5px rgba(9, 9, 9, 0.04);

  }

  .contents-header {
    display: flex;
    align-self: flex-start;
    margin-top: 50px;

    & > h2 {
      @include roboto-font(24px, 500, 16px, #1E1E1E);
      letter-spacing: -0.176px;
      margin-right: 5px;
    }
  }

</style>
