<script lang="ts">
    import {t} from "../../i18n/i18n";
    import {analyticNames, findValue} from "./stores";
    import type {Writable} from "svelte/store";
    import {getContext, onDestroy, onMount} from "svelte";
    import type {EvaluationResult} from "./types/analyticalEvaluation";

    export let evaluationResult: Record<string, EvaluationResult>;

    export let valueMetadata = {}

    let evaluationContainer: HTMLElement;
    let resizeObserver: ResizeObserver;
    let stickyHeightInformation: Writable<string> = getContext("stickyHeightInformation");

    function handleClick(value) {
        let input = document.getElementById(value)?.querySelector('input');
        let row = document.getElementById(value);

        if (input && row) {
            if (input.disabled) {
                row.scrollIntoView({block: "center", behavior: "smooth"});
                document.getElementById(value).querySelector('td').style.animation = "color-change 2s 1";
                setTimeout(() => {
                    document.getElementById(value).querySelector('td').style.animation = "";
                }, 2000);
            } else {
                input.focus();
            }
        }
    }

    onMount(() => {
        resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                $stickyHeightInformation = (<HTMLElement>entry.target).offsetHeight + "px";
            }
        });

        resizeObserver.observe(evaluationContainer);
    });

    onDestroy(() => {
        if (resizeObserver) {
            resizeObserver.disconnect();
        }
    })

</script>

<div bind:this={evaluationContainer} class="evaluationContainer">
    <div class="innerContainer scrollable">

        {#if evaluationResult}
            {#each Object.entries(evaluationResult) as [analyticTechnicalName, result], i}
                <div class="header" style="grid-row-start: {2*i + 1}">
                    {$t('Analytics.testMethod')}
                </div>
                <div class="header-evaluation header" style="grid-row-start: {2*i + 1}">
                    {$t('Analytics.evaluation')}
                </div>
                <div class="header" style="grid-row-start: {2*i + 1}">
                    {$t('Analytics.maxValue')}
                </div>
                <div class="header" style="grid-row-start: {2*i + 1}">
                    {$t('Analytics.missingValues')}
                </div>

                <div class="procedure subrow" style="grid-row-start: {2*i + 2}">
                    {$analyticNames[analyticTechnicalName]}
                </div>
                <div class="evaluation subrow" style="grid-row-start: {2*i + 2}">
                    {result.result.description}
                </div>
                <div class="worst subrow scrollable" style="grid-row-start: {2*i + 2}">
                    {#each result.worstValues as worstValue}
                        {@const worstVal = findValue(worstValue)}
                        <span class="focus-value" on:click={handleClick(valueMetadata[worstValue]?.key)}>
                            {valueMetadata[worstValue].name}: {worstVal?.value} {worstVal?.unit || ""}
                        </span><br/>
                    {/each}
                </div>
                <div class="missing subrow scrollable" style="grid-row-start: {2*i + 2}">
                    {#each result.missingValues as missingValue}
                        <span class="focus-value" on:click={handleClick(valueMetadata[missingValue].key)}>
                            {valueMetadata[missingValue].name}
                        </span><br/>
                    {/each}
                </div>
            {/each}
        {:else }
            <div class="header" style="grid-row-start: {1}">
                {$t('Analytics.testMethod')}
            </div>
            <div class="header-evaluation header" style="grid-row-start: {1}">
                {$t('Analytics.evaluation')}
            </div>
            <div class="header" style="grid-row-start: {1}">
                {$t('Analytics.maxValue')}
            </div>
            <div class="header" style="grid-row-start: {1}">
                {$t('Analytics.missingValues')}
            </div>

            <div class="procedure subrow" style="grid-row-start: {2}">
                /
            </div>
            <div class="evaluation subrow" style="grid-row-start: {2}">
                /
            </div>
            <div class="worst subrow scrollable" style="grid-row-start: {2}">
                /
            </div>
            <div class="missing subrow scrollable" style="grid-row-start: {2}">
                /
            </div>
        {/if}

    </div>
</div>


<style lang="scss">
  @import "../../styles/global";

  .evaluationContainer {
    width: 100%;
    border: 1px solid $grey-700;
    border-radius: 4px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);

    grid-template-columns: auto 120px 120px 120px;
    grid-template-rows: auto auto;
    padding: 20px 24px 18px 24px;
  }

  .innerContainer {
    display: grid;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 20px;
    padding-bottom: 2px;
  }

  .header {
    @include roboto-font(0.875rem, 500, 0.75rem, #999999);
    text-transform: uppercase;
    margin-top: 6px;
  }

  .subrow {
    margin-top: 6px;
  }

  .scrollable::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  .scrollable::-webkit-scrollbar-track {
    background: $bluish-grey-200;
    border-radius: 4px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 12px;
  }

  /* Handle */
  .scrollable::-webkit-scrollbar-thumb {
    background: $primaryGreen;
    border-radius: 4px;
  }

  .procedure {
    grid-column-start: 1;
    grid-column-end: 1;
    @include roboto-font(1rem, 500, 0.875rem);
  }

  .evaluation {
    grid-column-start: 2;
    grid-column-end: 2;
    @include roboto-font(1rem, 500, 0.875rem);
  }

  .worst {
    grid-column-start: 3;
    grid-column-end: 3;
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    @include roboto-font(0.75rem, 500, 0.625rem);
  }

  .missing {
    grid-column-start: 4;
    grid-column-end: 4;
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    @include roboto-font(0.75rem, 500, 0.625rem, #F24822);
  }

  .header-evaluation {
    color: $primaryGreen;
  }

  .focus-value {
    color: black;
  }

  .focus-value:hover {
    cursor: pointer;
    color: $primaryGreen;
  }

  @keyframes -global-color-change {
    0% {
      color: #212529;
    }
    25% {
      color: $primaryGreen;
    }
    75% {
      color: $primaryGreen;
    }
    100% {
      color: #212529;
    }
  }

</style>