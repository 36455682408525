import type {Writable} from 'svelte/store';
import {writable} from 'svelte/store';
import type {Notification} from "./types/notification";
import {NotificationType} from "./types/notification";
import {LoadingAnimation} from "./types/enums";
import type {ContactData} from "./types/contactData";
import {fetchUtils} from "./utils/fetchUtils";
import type {UserInfo} from "./types/userInfo";

/**
 * This is false by default. We define this by checking, whether a JWT exists or not. If yes, this will be set to true.
 */
export const userIsLoggedIn: Writable<boolean> = writable(false);


export const pageError: Writable<{code: number, message: string}> = writable();
export const addPageError = (error: {code: number, message: string}) => {
    pageError.set(error);
}


/**https://svelte.dev/repl/0091c8b604b74ed88bb7b6d174504f50?version=3.35.0 */
export const notifications: Writable<Notification[]> = writable([]);
export const addNotification = (notification: Partial<Omit<Notification, 'id'>>) => { // we don't need to give the id, but the rest
    // Create a unique ID as Symbol so we can easily find/remove it and check if it is dismissible/has a timeout.
    const id: Symbol = Symbol(); //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Symbol?retiredLocale=de

    // Setup some sensible defaults for a notification.
    const defaults: Notification = {
        id,
        type: NotificationType.INFO,
        dismissible: true,
        timeout: 3000,
        target: null,
        message: ""
    };

    // Push the notification to the top of the list of toasts
    let mergedNotification: Notification = {...defaults, ...notification};
    notifications.update((all) => [mergedNotification, ...all]);

    // If notification is dismissible, dismiss it after "timeout" amount of time.
    if (mergedNotification.timeout) {
        setTimeout(() => dismissNotification(mergedNotification.id), mergedNotification.timeout);
    }

    return id;
}
export const dismissNotification = (id: Symbol) => {
    notifications.update((all) => all.filter((t: Notification) => t.id !== id)); // delete that toast with a specific id
};

/* loading animation store */
export const displayLoadingAnimation = writable({display: false, animation: LoadingAnimation.MmLoader});

// if we want to show the animation on mount until a specific condition is met, we can say that it should not be hidden, if the animation is pending
export const manualAnimationPending: Writable<boolean> = writable(false);
export const showAnimation = (animation: LoadingAnimation) => {
    displayLoadingAnimation.set({display: true, animation: animation});
    manualAnimationPending.set(true);
}
export const hideAnimation = (offset = 2300) => {
    setTimeout(() => {
        displayLoadingAnimation.update((all) => ({...all, display: false}))
        manualAnimationPending.set(false);
    }, offset);
}

let defaultUserInfo: UserInfo = {
    userLogin: "",
    tenantName: "",
};

export const userInfo: Writable<UserInfo> = writable(defaultUserInfo);
export const loadUserInfo = () => {
    fetchUtils.get('/api/userLogin')
        .then(data => {
            userInfo.set(data)
        })
        .catch(error => console.log(error));
}
let defaultCaseOwnerData: ContactData = {
    fullName:"",
    phone:"",
    mobile:"",
    eMail:""
}
export const caseOwnerContact: Writable<ContactData> = writable(defaultCaseOwnerData)

let defaultTreeExpansionStates = new Map();
defaultTreeExpansionStates.set('mmpv', []);
export const treeExpansionStates: Writable<TreeExpansionState> = writable(defaultTreeExpansionStates);
type TreeExpansionState = Map<string, any> // id of the tree, in case we would have several trees on one page at any time; mapped to list of expanded nodes

export type Breadcrumb = {
    title: string;
    data: any;
    callback: (data: any, index: number) => void;
};
export const breadcrumbs: Writable<Breadcrumb[]> = writable([]);

// If we have a (more or less) important form in a modal, we maybe don't want to close the modal when the user clicks
// outside of the modal -> we can set the value to true before opening the model or even directly afterward and can reset
// it before or directly after closing the modal.
// To make sure, that clicking outside the modal closes it, we can also explicitly set the value to true
export const closeModalOnOutsideClick = writable(true);

export const manualOpenWhitelist: Writable<boolean> = writable(false);

export const themeProps: Writable<Map<string, string>> = writable(new Map<string, string>());
