import {Facts} from "../bid/types/facts";
import type {Price} from "./price";

export type DealActualValues = {
    transportPrice: Price,
    materialPrice: Price,
    totalFacts: Facts,
}

export function DefaultActualValues() {
    return {
        transportPrice: {
            value: null,
            currency: "EUR"
        },
        materialPrice: {
            value: null,
            currency: "EUR"
        },
        totalFacts: new Facts(),
    }
}
