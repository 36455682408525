<script lang="ts">
    import type {AttachmentView} from "../types/attachment";
    import {AttachmentVisibility, AttachmentVisibilityManagement, DefaultAttachmentView} from "../types/attachment";
    import Checkbox from "../atoms/Checkbox.svelte";
    import {createEventDispatcher, setContext} from "svelte";
    import {AttachmentParentType} from "../types/enums";
    import {t} from "../../i18n/i18n";
    import {fetchUtils} from "../utils/fetchUtils";
    import {addNotification} from "../stores";
    import {createTippy} from "svelte-tippy";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import type {AutocompleteFieldConfig} from "../types/autocompleteFieldConfig";
    import {DefaultAutocompleteConfig} from "../types/autocompleteFieldConfig";
    import {NotificationType} from "../types/notification";

    export let attachment: AttachmentView = DefaultAttachmentView();
    export let visibilityManagement = AttachmentVisibilityManagement.HIDE;
    export let visibilityUrl = "";

    let showVisibilityManagement = visibilityManagement != AttachmentVisibilityManagement.HIDE;

    const visibilityOptions = Object.values(AttachmentVisibility).map(function (x) {
        return {value: x.toUpperCase(), text: $t(("AttachmentVisibility." + x))};
    });
    const dispatch = createEventDispatcher();
    setContext('useMaterialDesign', true);

    function onAttachmentSelected() {
        // received event from component checkbox select
        dispatch('attachmentSelected', {
            canDelete: attachment.canDelete,
            attachmentId: attachment.attachmentId,
            parentType: attachment.parentType,
            entityId: attachment.entityId
        });
    }

    async function changeVisibility() {
        await fetchUtils.post(visibilityUrl + attachment.entityId + "/changeVisibility", {visibility: attachment.visibility}, false)
            .then(data => {
                let notification = {message: $t('UI.attachment.visibility.change.success'), type: NotificationType.SUCCESS, timeout: 3000, dismissible: true};
                addNotification(notification)
            })
            .catch(error => {
                let notification = {message: $t('UI.attachment.visibility.change.error'), type: NotificationType.ERROR, timeout: 3000, dismissible: true};
                addNotification(notification);
                window.dispatchEvent(new CustomEvent("fetchAttachments"));
            });
    }

    let tippy = createTippy({
        interactive: true,
        placement: 'top-start',
        maxWidth: 231,
        theme: 'mmttip',
        content: $t('UI.attachments.checkbox.tooltip'),
        hideOnClick: true
    });

    let isPopOrProfileAttachment;

    $: isPopOrProfileAttachment = (attachment.parentType === AttachmentParentType.PARENT_PROFILE || attachment.parentType === AttachmentParentType.PARENT_POP);

    let autocompleteConfig: AutocompleteFieldConfig = DefaultAutocompleteConfig();
    autocompleteConfig.searchable = false;
    autocompleteConfig.clearable = false;

</script>

<div class="f-attachment-container"
     style="max-width: {showVisibilityManagement ? 'unset' : '15rem'}; height: {showVisibilityManagement ? '5' : '4.375'}rem;">
    <div data-title={attachment.filename} style="max-width: {showVisibilityManagement ? '60%' : '80%'}; align-items: flex-start;
      justify-content: center; row-gap: 0.25rem;">
        {#if isPopOrProfileAttachment === true}
            <span class="f-attachment-label">{$t('UI.FileUpload.popOrProfile.prefix') + "-" + attachment.filename}</span>
        {:else }
            <span class="f-attachment-label">{attachment.filename}</span>
        {/if}
        <span class="f-attachment-date">{attachment.changeDate}</span>
    </div>
    <div style="align-items: flex-end; {showVisibilityManagement ? 'min-width: 35%;' : ''}
        justify-content: {showVisibilityManagement ? 'space-between' : 'flex-start'};
        padding-top: {showVisibilityManagement ? '' : '0.25rem'};
        padding-right: {showVisibilityManagement ? '' : '0.25rem'};">
        <div use:tippy style="padding-right: 1px; padding-top: 2px;">
            <Checkbox id={"checkbox-" + attachment.attachmentId} tagName={"checkbox-attachment"} size="18px"
                      on:checkboxChange={onAttachmentSelected}/>
        </div>
        {#if showVisibilityManagement}
            <div class="visibility-management">
                <!-- FIXME: Replace AutocompleteInputField with normal input field, we don't need the autocomplete overhead here -->
                <AutocompleteInputField bind:value={attachment.visibility} {autocompleteConfig}
                                        options={visibilityOptions} label={$t('Model.visibility')}
                                        disabled={visibilityManagement === AttachmentVisibilityManagement.READONLY}
                                        hideDropdownArrow={false}
                                        on:change={changeVisibility}/>
            </div>
        {/if}
    </div>
</div>


<style lang="scss">
  @import "../../styles/global";

  .f-attachment-container {
    display: flex;
    justify-content: space-between;
    background: white;
    border: 1px solid black;
    border-radius: 4px;
    padding: 0.4rem 0.375rem 0.4rem 0.875rem;
    width: 100%;
    min-width: 11.625rem;

    & > div {
      display: flex;
      flex-direction: column;
    }
  }

  .f-attachment-label {
    @include roboto-font(normal, 500, 0.875rem, black);
    text-align: left;
    text-align-last: left;
    word-break: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 100%;
  }

  .f-attachment-date {
    @include roboto-font(normal, 400, 0.875rem, black);
  }

  [data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
  }

  [data-title]:after {
    content: attr(data-title);
    word-wrap: break-word;
    position: absolute;
    z-index: 99999;
    top: 1.6rem;
    left: -0.875rem;
    margin-right: 30px;
    padding: 8px;
    max-width: 230px;
    @include roboto-font(18px, 400, 0.75rem, black);
    background-color: white;
    opacity: 0;
    visibility: hidden;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-filter: drop-shadow(0px 2px 3px #bababa);
    filter: drop-shadow(0px 2px 3px #bababa);
    -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=1, Color='#bababa')";
    filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=1, Color='#bababa')";
  }

  [data-title] {
    position: relative;
  }

  .visibility-management {
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

</style>
