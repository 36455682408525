<script lang="ts">

    import type {MaterialProperty} from "./types/materialProperty";
    import {DefaultMaterialProperty} from "./types/materialProperty";
    import {createEventDispatcher, getContext} from "svelte";
    import {openModal} from "svelte-modals";
    import MaterialPropertyModal from "./MaterialPropertyModal.svelte";
    import type {Writable} from "svelte/store";
    import type {Material} from "./types/material";

    const dispatch = createEventDispatcher();

    export let property: MaterialProperty;
    export let idx: number; // idx is important for the color scheme

    export let isEditable: boolean = true;

    let properties: Writable<Map<string, MaterialProperty>> = getContext('materialProperties');
    let material: Writable<Material> = getContext('selectedMaterial');

    function editProperty() {
        openModal(MaterialPropertyModal, {
            materialProperty: {...DefaultMaterialProperty(), ...$properties.get(property.id)},
            isNew: false,
            parent: null,
            materialId: $material.id,
            updateMaterial: (propertyId: string) => {
                dispatch('updateMaterialProperty', {propertyId: propertyId});
            },
        })
    }

</script>

{#if isEditable}
    <div class="material-property-card color-{idx}" title={property.name} on:click={editProperty}>
        <span title="{property.name}">{property.name}</span>
        <div class="remove-icon"
             on:click|stopPropagation={() => dispatch('removeProperty', {property: property})}></div>
    </div>
{/if}
{#if !isEditable}
    <div class="material-property-card disabled" title={property.name}>
        <span>{property.name}</span>
    </div>
{/if}

<style lang="scss">
  @import "../../styles/global";

  .material-property-card {
    min-height: 66px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 0.75rem 2rem 0.75rem 0.75rem;
    position: relative;
    display: flex; /*adjusts that the font is directly on top and left and does not use too much space for two rows*/
    & > span {
      @include roboto-font(14px, 400, 0.75rem, white);
      text-align: start;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .remove-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: .5rem;
    right: .5rem;
    background: url("@/icons/icon_cancel_20_white.svg") no-repeat;
    background-size: contain;
  }

  @for $i from 0 through 200 { /*todo adapt number*/
    .color-#{$i} {
      background-color: getPaletteColor(material-properties, $i%3);
    }
  }

  .disabled {
    background-color: $grey-800;;
  }

</style>
