import type {SearchProps} from 'svelecte/dist/utils/list';

export type AutocompleteFieldConfig = {
    /*https://mskocik.github.io/svelecte*/
    clearable: boolean, // shows an x icon at the end to clear all selections
    multiple: boolean, // if multiple values can be selected
    disabled: boolean,
    options?: any, // options array, list of objects
    fetch?: string | null, // the url to fetch the options from
    fetchMode?: string, // can be set to either 'init' or 'auto'
    groupLabelField?: string, // when using grouped options, this is the header field in the object
    groupItemsField?: string, // when using grouped options, this is the list of options in the object
    selectOnTab: boolean, // select currently active item on TAB
    resetOnBlur: boolean, // when focussing out of the input field, the text is deleted
    valueField?: string,
    labelField?: string,
    creatable?: boolean,
    allowEditing: boolean,
    searchable?: boolean,
    disableHighlight?: boolean,
    fetchResetOnBlur?: boolean,
    creatablePrefix?: string,
    valueAsObject?: boolean,
    delimiter?: string,
    searchProps?: Partial<SearchProps>,
    strictMode?: boolean, // when this is set to false, svelecte does not check if the selection is in the list
}


export function DefaultAutocompleteConfig() {
    let config: AutocompleteFieldConfig = {
        clearable: true,
        disabled: false,
        multiple: false,
        selectOnTab: true,
        resetOnBlur: false,
        allowEditing: true
    };
    return config;
}

export function AutocompleteConfig(valueField: string | null = null, labelField: string | null = null, multiple: boolean = false, disabled: boolean = false,
                                   selectOnTab: boolean = true, clearable: boolean = true, resetOnBlur: boolean = true, options?: any,
                                   fetch?: string) {
    let config: AutocompleteFieldConfig = {
        clearable: clearable,
        disabled: disabled,
        multiple: multiple,
        selectOnTab: selectOnTab,
        resetOnBlur: resetOnBlur,
        valueField: valueField,
        labelField: labelField,
        options: options,
        fetch: fetch,
        allowEditing: true,
        // There is a bug that does not show spaces in specific cases, so its better we disable the highlighting in general for now
        disableHighlight: true,
        // Disable further filtering from Svelecte, since this is done in the BE already, this can lead to very strange or missing results
        searchProps: {skipSort: true}
    };
    return config;
}

export function getFormAcConfigFetch(fetchKey: string, multiple = false, valueField = 'value', labelField = 'text', disabled: boolean = false, fetchResetOnBlur: boolean = true, fetchSuffix: string = "") {
    let config = AutocompleteConfig(valueField, labelField, multiple, disabled);
    config.fetch = "/api/" + fetchKey + "/autocomplete/svelte?qry=[query]" + fetchSuffix;
    config.fetchResetOnBlur = fetchResetOnBlur;
    return config;
}

export type SimpleAutocompleteObject = { id: number, label: string };

