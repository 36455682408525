<script lang="ts">
    import {fetchUtils} from "../../../utils/fetchUtils";
    import {addNotification} from "../../../stores";
    import {t} from "../../../../i18n/i18n";
    import type {Analytical} from "../../../analytics/types/analytical";
    import {closeModal, openModal} from "svelte-modals";
    import Modal from "../../../organisms/Modal.svelte";
    import {createEventDispatcher} from "svelte";
    import {createTippy} from "svelte-tippy";
    import {AnalyticalParentType, AnalyticalType} from "../../../types/enums";
    import {NotificationType} from "../../../types/notification";

    const dispatch = createEventDispatcher();
    export let analytical: Analytical;
    export let analyticalParentType: AnalyticalParentType;

    function deleteAnalytical() {
        fetchUtils.post("/api/analysis/delete", {
            parentType: analyticalParentType,
            analysisId: analytical.id,
            isAnalysis: analytical.type === AnalyticalType.ANALYSIS
        }).then(() => {
            dispatch('updateAnalytical');
            closeModal();
            addNotification({
                message: $t('UI.map.analytic.notification.deleted'),
                type: NotificationType.SUCCESS,
                timeout: 5000,
                dismissible: true
            });
        }).catch(fetchUtils.catchErrorAndShowNotification());
    }

    function editAnalytical() {
        // add the parent href as origin to be able to navigate back, encoded because lotFormId will be lost otherwise
        let origin = encodeURIComponent(window.parent.location.href);
        window.parent.location.assign(`/${analytical.type.toLowerCase()}?${analyticalParentType.toLowerCase()}${analytical.type.toLowerCase()}=${analytical.id}&origin=${origin}`);
    }

    function handleRedirect() {
        openModal(Modal, {
            title: $t("UI.map.editAnalysis.modal.title"),
            message: $t("UI.map.editAnalysis.modal.message"),
            onAccept: () => {
                editAnalytical();
            },
            acceptText: $t("UI.button.saveAndNext"),
        })
    }

    function handleDelete() {
        openModal(Modal, {
            title: $t("UI.map.deleteAnalysis.modal.title"),
            message: $t('UI.map.deleteAnalysis.modal.text', {analysis: analytical.name}),
            onDeny: () => {
                deleteAnalytical();
            },
            denyText: $t("UI.map.analysis.delete.tt")
        })
    }

    const deleteTippy = createTippy({
        animation: 'scale-extreme',
        content: $t("UI.map.analysis.delete.tt"),
        delay: [100, 200],
        hideOnClick: false,
        interactive: true,
        maxWidth: 367,
        placement: 'right',
        theme: 'mmttip',
        appendTo: document.getElementsByClassName('fak-base-layout')[0],
    });

    const editTippy = createTippy({
        animation: 'scale-extreme',
        content: $t("UI.map.analysis.edit.tt"),
        delay: [100, 200],
        hideOnClick: false,
        interactive: true,
        maxWidth: 367,
        placement: 'right',
        theme: 'mmttip',
        appendTo: document.getElementsByClassName('fak-base-layout')[0],
    });

</script>

<div class="analytic-row-container">
    <span>{analytical.name}</span>
    <div>
        <div class="delete-icon" use:deleteTippy on:click={handleDelete}>
        </div>
        <div class="green-icon" use:editTippy on:click={handleRedirect}>
            <div class="edit-icon"></div>
        </div>
    </div>
</div>


<style lang="scss">
  @import "../../../../styles/global";
  @import "../../../../styles/autocomplete";

  .analytic-row-container {
    border: 1px solid $grey-700;
    border-radius: 2px;
    padding: 0.6825rem 0.25rem 0.625rem 0.5rem;
    @include flex-row($justify: space-between);
    height: 32px;
    width: 100%;

    & > span {
      @include roboto-font(14px, 400, 0.75rem, black);
    }

    & > div {
      @include flex-row(0.25rem, $justify: flex-start);
    }
  }

  .delete-icon {
    border: 2px solid $primaryRed;
    background-color: white;
    border-radius: 4px;
    margin-right: 0.375rem;
    width: 24px;
    height: 24px;
    @include flex-col();

    &:hover {
      cursor: pointer;
      border: 2px solid $primaryDarkRed;
    }
  }

  .green-icon {
    margin-right: 0;
  }


</style>