<script lang="ts">

    import normalAvatar from '@/icons/icon-user-grey.svg'
    import otherTenantAvatar from '@/icons/icon-user-red.svg'
    import otherUserAvatar from '@/icons/icon-user-blue.svg'
    import type {Writable} from "svelte/store";
    import {getContext} from "svelte";
    import {jwt} from "../utils/jwt";
    import Icon from "../atoms/Icon.svelte";
    import Tooltip from "../atoms/Tooltip.svelte";
    import {TooltipPlacements} from "../types/enums";

    const openDropdown: Writable<string> = getContext('openDropdown');
    const AVATAR_TITLE_FOR_STORE: string = 'profile-dropdown';

    let impersonateTenant: boolean = false;
    let impersonateUser: boolean = false;


    function updateImpersonation() {
        impersonateTenant = $jwt.spytid && $jwt.tid !== $jwt.spytid;
        impersonateUser = $jwt.spyuid && $jwt.uid !== $jwt.spyuid;
        updateIcon();
    }

    let src = normalAvatar;

    function updateIcon() {
        src = impersonateTenant ? otherTenantAvatar : (impersonateUser ? otherUserAvatar : normalAvatar);
    }


    // determines if the dropdown menu is opened, if a dropdown menu exists
    let active: boolean = false;
    $: $openDropdown, active = $openDropdown === AVATAR_TITLE_FOR_STORE;
    $: $jwt, updateImpersonation();

    function handleDropdownClick() {
        if (!active) {
            $openDropdown = AVATAR_TITLE_FOR_STORE;
        } else {
            $openDropdown = "";
            active = false;
        }
    }

</script>


<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="menu__avatar nav_link dropdown__btn" aria-haspopup="true" id="menuAvatar"
     aria-expanded={active? "true" : "false"} aria-label={AVATAR_TITLE_FOR_STORE}
     on:click|stopPropagation={handleDropdownClick}>
    <Tooltip msg={`${$jwt.spyuname}<br><span style="font-weight: 500">${$jwt.tname}</span>`}
             placementTtip={TooltipPlacements.BOTTOM_START} offsetTtip={[0, 11]} appendToObject="parent">
        <Icon {src} alt="Avatar Icon" size={40} clickable/>
    </Tooltip>
    <slot {active}/>
</div>


<style lang="scss">
  @import "../../styles/menubar";
</style>
