<script lang="ts">
    import {addRenderer} from 'svelecte';
    import Notification from "../molecules/Notification.svelte";
    import {
        analyticalMetadata,
        analyticNames,
        loadRecommendations,
        parameterStore,
        recommendationParameters,
        recommendations,
        recommendationValues,
        selectedRecommendation,
        values
    } from "./stores";
    import SecondaryButton from "../atoms/SecondaryButton.svelte";
    import {get, type Unsubscriber, writable} from "svelte/store";
    import AnalysisTable from "./AnalysisTable.svelte";
    import {t} from "../../i18n/i18n";
    import CollapsibleCard from "../molecules/CollapsibleCard.svelte";
    import {createEventDispatcher, onDestroy, onMount} from "svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import {debounce} from "../utils/utils";
    import {AutocompleteConfig} from "../types/autocompleteFieldConfig";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import {AnalyticalParentType} from "../types/enums";
    import type {Recommendation} from "./types/recommendation";
    import {NotificationType} from "../types/notification";

    const dispatch = createEventDispatcher();

    const urlParams = new URLSearchParams(window.location.search);


    export let editable: boolean = true;

    let {analyticalType, analyticalId, parentId, parentType} = $analyticalMetadata;
    let unsubscribeValues: Unsubscriber;

    onMount(async () => {
        if (urlParams.get('selectedRecommendation')) {
            $selectedRecommendation = urlParams.get('selectedRecommendation')
        }
        unsubscribeValues = values.subscribe((_) => {
            for (let i = 0; i < $recommendations.length; i++) {
                let recommendation = $recommendations[i];
                updateEvaluation(recommendation.analytic)();
            }
        })
    })


    let showHint = true;
    const onHintDismiss = () => {
        showHint = false;
    }

    const transformParametersForRequest = (analytic: string) => {
        let parameters = [];
        for (let parameterKey in $recommendationParameters[analytic]) {
            parameters.push({
                name: parameterKey,
                value: $recommendationParameters[analytic][parameterKey]
            })
        }
        return parameters;
    }

    function addValues(analytic: string) {
        return () => {
            let storedValues = get($recommendationValues[analytic]);
            storedValues.forEach(value => value.additional = true);
            fetchUtils.post(`/api/${analyticalType?.toLowerCase()}/addValues`,
                {
                    analyticalId,
                    parentId,
                    parentType,
                    values: storedValues,
                    parameters: transformParametersForRequest(analytic)
                }
            ).then(_ => {
                dispatch("valuesChanged")
                loadRecommendations();
            })
        }
    }

    function addAnalysis(recommendation: Recommendation) {
        return () => {
            let recommendationValuesStoreForAnalytic = get($recommendationValues[recommendation.analytic]);
            if (parentType === AnalyticalParentType.NONE || parentId === "-1") {
                let url = new URL(`/${analyticalType?.toLowerCase()}`, window.parent.location.href);
                url.searchParams.set("analyticTechnicalName", recommendation.analytic);
                for (let requiredParam in recommendation.requiredParameters) {
                    url.searchParams.set(requiredParam, $recommendationParameters[recommendation.analytic][requiredParam]);
                }
                let valuesToStore = $values.concat(recommendationValuesStoreForAnalytic.filter(value => !$values.map(v => v.key).includes(value.key)));
                window.sessionStorage.setItem("analyticalValues", JSON.stringify(valuesToStore));
                window.open(url, "_blank");
            } else {
                fetchUtils.post(`/api/${analyticalType?.toLowerCase()}/addRecommendationAnalytic`,
                    {
                        analytic: recommendation.analytic,
                        analyticalId,
                        parentId,
                        parentType,
                        values: recommendationValuesStoreForAnalytic,
                        parameters: transformParametersForRequest(recommendation.analytic),
                    }
                ).then(recommendationId => {
                    loadRecommendations();
                    let valuesToStore = $values.concat(recommendationValuesStoreForAnalytic.filter(value => !$values.map(v => v.key).includes(value.key)));
                    window.sessionStorage.setItem("analyticalValues", JSON.stringify(valuesToStore));
                    window.parent.location.href = `/${analyticalType?.toLowerCase()}?${parentType?.toLowerCase()}${analyticalType?.toLowerCase()}=${recommendationId}`
                });
            }

        }
    }

    let evaluationResults = {};

    const updateEvaluationCallbacks: Record<string, any> = {};

    function updateEvaluation(analytic: string) {
        if (!updateEvaluationCallbacks[analytic]) {
            updateEvaluationCallbacks[analytic] = debounce(() => {
                let parameters = transformParametersForRequest(analytic);

                let recommendationValuesForAnalytic = get($recommendationValues[analytic]);
                let recommendationVals: any[] = [...recommendationValuesForAnalytic.filter(value => !$values.map(v => v.key).includes(value.key))];
                fetchUtils.post(`/api/analytics/evaluateAnalysis`, {
                    analyticTechnicalName: analytic,
                    parameters: parameters,
                    values: $values.concat(recommendationVals)
                }).then((data: any) => {
                    for (let i in recommendationVals) {
                        let value = recommendationVals[i];
                        value.evaluation = data.valueResults[value.key];
                    }
                    $recommendationValues[analytic].set(recommendationVals);

                    evaluationResults[analytic] = data.combinedResult[analytic];
                })
            })
        }

        return updateEvaluationCallbacks[analytic];
    }

    function recommendationParameterChanged(analytic, parameter) {
        return (data) => {
            $recommendationParameters[analytic][parameter] = data.detail.value;
            updateEvaluation(analytic);
        }
    }

    const addParameterRenderer = (parameter) => {
        function renderer(item) {
            // noinspection TypeScriptValidateTypes
            return $t(`Analytics.evaluationParameter.${parameter}.${item.text}`);
        }

        addRenderer(parameter + '-render-rec', renderer);
        return renderer;
    }

    onDestroy(() => {
        unsubscribeValues();
    });

    function openRecommendation(id) {
        if (id) {
            let card = document.getElementById(id);
            card?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
    }

    $: {
        for (let i = 0; i < $recommendations.length; i++) {
            let recommendation = $recommendations[i];
            if (!$recommendationValues[recommendation.analytic]) {
                $recommendationValues[recommendation.analytic] = writable([]);
            }

            if (!$recommendationParameters[recommendation.analytic]) {
                $recommendationParameters[recommendation.analytic] = {};
                for (const [key, _] of Object.entries(recommendation.requiredParameters)) {
                    $recommendationParameters[recommendation.analytic][key] = $parameterStore.parameters[key];
                }
            }
            $recommendationValues[recommendation.analytic].set(recommendation.values);
            updateEvaluation(recommendation.analytic);
        }
    }

    $: $analyticalMetadata, {analyticalType, analyticalId, parentId, parentType} = $analyticalMetadata;
    $: $recommendations, openRecommendation($selectedRecommendation);
</script>

<h1>{$t("Analytics.recommendations.title")}</h1>
{#if showHint}
    <Notification type={NotificationType.INFO_INLINE} message={$t('Analytics.recommendations.hint')}
                  on:dismiss={onHintDismiss} dismiss/>
{/if}
<div>
    <h2>{$t("Analytics.recommendations.procedures")}</h2>
    {#if $recommendations.length === 0}
        {$t("Analytics.recommendations.empty")}
    {/if}

    {#each $recommendations as recommendation}
        <CollapsibleCard openByDefault={$selectedRecommendation === recommendation.analytic} collapseHeader>
            <div id="{recommendation.analytic}" slot="header" class="recommendation-header">
                <div>
                    <h3>{$t("Analytics.recommendations.evaluationAccordingTo")}</h3>
                    <h2>{$analyticNames[recommendation.analytic]}</h2>
                </div>

                <div>
                    <h4>{$t("Analytics.recommendations.evaluation.overall")}</h4>
                    <div class="evaluation">
                        {evaluationResults[recommendation.analytic] ?
                            evaluationResults[recommendation.analytic].result.description : "-"}
                    </div>
                </div>
            </div>
            <div slot="body" class="recommendation">
                <h3>{$t("Analytics.recommendations.evaluationAccordingTo")}</h3>
                <h2 style="padding-bottom: 0.88rem;">{$analyticNames[recommendation.analytic]}</h2>
                <div class="parameter-container">
                    {#each Object.entries(recommendation.requiredParameters) as [parameter, parameterValues], i}
                        <!-- had to do it this way, as we are in an each loop -->
                        <AutocompleteInputField
                                bind:value={$recommendationParameters[recommendation.analytic][parameter]}
                                label={$t(`Analytics.evaluationParameter.${parameter}`)}
                                autocompleteConfig={AutocompleteConfig(null,null)}
                                options={parameterValues} renderer={addParameterRenderer(parameter)}
                                on:change={recommendationParameterChanged(recommendation.analytic, parameter)}/>
                    {/each}
                </div>

                {#if recommendation.values.length > 0}
                    <AnalysisTable analysisValues={$recommendationValues[recommendation.analytic]} compact
                                   on:updateValue={updateEvaluation(recommendation.analytic)}/>

                {:else}
                    <div style="margin-bottom: 25px;">{$t("Analytics.recommendations.noFurtherValuesNeeded")}</div>
                {/if}

                <h4>{$t("Analytics.recommendations.evaluation.overall")}</h4>
                <div class="evaluation">
                    {evaluationResults[recommendation.analytic] ?
                        evaluationResults[recommendation.analytic].result.description : "-"}
                </div>


                {@const secondaryLabel = parentType === AnalyticalParentType.NONE && parentId === "-1"
                    ? $t("Analytics.tool.recommendations.createSeparate")
                    : $t("Analytics.recommendations.createSeparate")}
                {@const secondaryTitleAttr = parentType === AnalyticalParentType.NONE && parentId === "-1"
                    ? $t("Analytics.tool.recommendations.createSeparate.titleAttr")
                    : $t("Analytics.recommendations.createSeparate")}
                <div class="buttons">
                    <SecondaryButton label={secondaryLabel}
                                     titleAttr={secondaryTitleAttr}
                                     disabled={!editable}
                                     on:click={addAnalysis(recommendation)}/>
                    {#if recommendation.values.length > 0}
                        <SecondaryButton label={$t("Analytics.recommendations.adoptValues")}
                                         titleAttr={parentType === AnalyticalParentType.NONE || !parentId || parentId === "-1" ? $t("Analytics.recommendations.adoptValues.titleAttr.notPossible") : $t("Analytics.recommendations.adoptValues")}
                                         disabled={!editable || parentType === AnalyticalParentType.NONE || !parentId || parentId === "-1"}
                                         on:click={addValues(recommendation.analytic)}/>
                    {/if}
                </div>
            </div>
        </CollapsibleCard>
        <div class="cardspacer"></div>
    {/each}
</div>

<style lang="scss">
  @import "../../styles/global";

  .recommendation h3, .recommendation-header h3 {
    @include roboto-font(12px, 500, 10px, #999999);
    text-transform: uppercase;
  }

  .recommendation h2 {
    @include roboto-font(14px, 500, 12px, #000000);
  }

  .recommendation h4, .recommendation-header h4 {
    @include roboto-font(18px, 500, 10px, #58C079);
    text-transform: uppercase;
    margin-bottom: 6px;
  }

  .recommendation {
    width: 100%;
    padding: 26px 24px;
    margin-bottom: 10px;
  }

  .recommendation-header {
    width: 100%;
    padding: 26px 24px;
    display: grid;
    grid-template-columns: 60% 40%;
    grid-column-gap: 0.25rem;
  }

  .recommendation-header h2 {
    @include roboto-font(14px, 500, 12px, #000000);
    width: 100%;
    margin-bottom: 0;
  }

  .recommendation :global(.secondary-button) {
    width: unset;
  }

  .buttons {
    @include flex-row(0.75rem, $justify: flex-end);
    margin-top: 1.5rem;
  }

  .evaluation {
    @include roboto-font(12px, 500, 10px, #240B11);
  }

  .cardspacer {
    height: 10px;
  }

  .parameter-container {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 5px;
    row-gap: 10px;
  }

  .recommendation-hint {
    @include roboto-font(18px, 400, 12px, black);
  }

</style>
