import {type MaterialProperty, MaterialPropertyType} from "../types/materialProperty";
import {SubstituteMaterialMapping} from "../types/substitutes";
import {translate} from "../../../i18n/i18n";
import type {Catalog} from "../types/catalog";
import type {ExtendedMaterial, Material} from "../types/material";

/**
 * creates first extended materials as multiple arrays for each catalog
 * finally build one array with ExtendedMaterials by the flat method
 * @param catalogs
 */
export function buildExtendedMaterials(catalogs: Catalog[]) {
    return catalogs.map(catalog => catalog.nodes.map(node => getExtendedMaterial(node, catalog))).flat();
}

/**
 * Extends the given material object with additional properties.
 *
 * @param {Material} material - The material object to be extended.
 * @param {Catalog} catalog - the catalog of the material
 * @return {ExtendedMaterial} An object containing the original material data along with
 * additional data: fullCode (combination of parentCode and code) and fullName
 * (formatted string with catalogName, parentCode, code, and name).
 */
function getExtendedMaterial(material: Material, catalog: Catalog): ExtendedMaterial {
    return {
        ...material,
        fullCode: material.parentCode + material.code,
        fullName: `${catalog.name} ${material.parentCode}${material.code} ${material.name}`
    };
}
export function concatProperty(prop: MaterialProperty, props: MaterialProperty[], allProperties: Map<string, MaterialProperty>) {
    if (!prop.concattedId) {
        prop.concattedId = prop.id;
        prop.deepName = prop.displayName;
    }
    props.push(prop);
    prop?.propertyListElements.forEach(propertyListElement => {
        propertyListElement?.propertyChildIds.forEach(propChildId => {
            if (!props.some(innerProp => innerProp.concattedId === prop.concattedId + "." + propChildId)) {
                let propToPush = structuredClone(allProperties.get(propChildId));
                propToPush.concattedId = prop.concattedId + "." + propertyListElement.id + "." + propToPush.id;
                propToPush.deepName = prop.deepName + " | " + propertyListElement.name + " | " + propToPush?.displayName;
                concatProperty(propToPush, props, allProperties);
            }
        });
    });
}

export function getStringNameOfPropertyType(propertyType: MaterialPropertyType) {
    switch (propertyType) {
        case MaterialPropertyType.MULTISELECT:
        case MaterialPropertyType.SINGLESELECT:
            return 'ListProperty';
        case MaterialPropertyType.STRING:
            return 'TextProperty';
        case MaterialPropertyType.NUMBER:
            return 'NumberProperty';
        case MaterialPropertyType.RANGE:
            return 'RangeProperty';
        default:
            return 'Property'
    }
}

export function getSuffix(materialType: SubstituteMaterialMapping) {
    if (materialType === SubstituteMaterialMapping.REFERENCE_MATERIAL) {
        return "_" + translate('de', 'UI.mmpv.modal.substitute.import.reference.suffix', []) + "_" + getRandomNumber();
    } else {
        return "_" + translate('de', 'UI.mmpv.modal.substitute.import.candidate.suffix', []) + "_" + getRandomNumber();
    }
}

function getRandomNumber() {
    const randomNumber = Math.floor(Math.random() * 999) + 1;
    return String(randomNumber).padStart(3, '0');
}

export function replaceSpecialCharacters(value: string) {
    return value?.replace(/</g, "_kleiner_")
        .replace(/>/g, "_groesser_")
        .replace(/-/g, "_")
        .replace(/\s+/g, '')
        .replace(/\*/g, "_Sternchen")
        .replace(/%/g, 'Prozent')
        .replace(/&/g, 'und')
        .replace(/\//g, '_')
        .replace(/,/g, '_')
        .replace(/\./g, '_')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ä/g, 'ae')
        .replace(/[^a-zA-Z0-9_]/g, ''); // finally replace all non allowed chars with empty string
}