import {LotType} from "../types/enums";


export class AllWhitelistData {
    // if not private, please do NOT use the variables and call functions on the variables, as we have only specific functions exposed
    #whitelistData: Map<LotType, WhitelistCatalog[]>;

    #errorMaterials: string[];

    constructor() {
        this.#whitelistData = new Map<LotType, WhitelistCatalog[]>();
        this.#errorMaterials = [];
    }

    /**
     * Initializes the whitelist data, e.g. after fetching it from the server. This initially fills the lists
     *
     * @param {WhitelistData[]} data - An array of objects containing whitelist data.
     */
    init(data: WhitelistData[]) {
        data.forEach((item) => {
            this.#whitelistData.set(item.lotType, item.catalogs);
        });
    }

    /**
     * When deselecting a LotType on page 1, also reset the user selections for that material, Thus, we need to
     * set the list to an empty list.
     * @param lotType
     */
    resetSelection(lotType: LotType) {
        this.#whitelistData.set(lotType, []);
    }

    /**
     * This method is used in the generation of the test data
     */
    #setWhitelistData(lotType: LotType, catalogs: WhitelistCatalog[]) {
        this.#whitelistData.set(lotType, catalogs);
    }

    /**
     * This method simply adds all listElements from all catalogs.
     * @param lotType
     * @param catalogs
     */
    addAllCatalogs(lotType: LotType, catalogs: WhitelistCatalog[]) {
        for (let catalog of catalogs) {
            for (let material of catalog.materials) {
                for (let property of material.properties) {
                    for (let listElement of property.propertyListElements) {
                        this.addListElement(lotType, listElement, catalog, material, property);
                    }
                }
            }
        }
    }

    /**
     * This method adds a list element 'listElement' to the material property 'materialProperty' of the given material 'material',
     * belonging to the catalog 'catalog' for the given LotType. If the necessary structure is not yet existing, the method handles this
     * and creates the necessary objects.
     */
    addListElement(lotType: LotType, listElement: WhitelistMaterialPropertyListElement, catalog: WhitelistCatalog, material: WhitelistMaterial, materialProperty: WhitelistMaterialProperty) {
        let callback = (currentMaterial: WhitelistMaterial) => {
            let propertyIndex = currentMaterial.properties.findIndex((p) => p.name === materialProperty.name);
            if (propertyIndex === -1) {
                currentMaterial.properties.push({
                    name: materialProperty.name,
                    propertyListElements: []
                });
                propertyIndex = currentMaterial.properties.length - 1;
            }
            // now add the listElement, if it does not already exist in the list
            let currentMaterialProperty = currentMaterial.properties[propertyIndex];
            currentMaterialProperty.propertyListElements = currentMaterialProperty.propertyListElements || [];
            // Check if there exists a listElement with the same id already.
            const exists = currentMaterialProperty.propertyListElements.some(lE => lE.name === listElement.name);
            if (!exists) {
                currentMaterialProperty.propertyListElements.push(listElement);

                // remove the list element from the errors, such that errors that were triggered once can be removed by adding properties
                let materialIdx = this.#errorMaterials.indexOf(material.id);
                if (materialIdx > -1) {
                    this.#errorMaterials = [
                        ...this.#errorMaterials.slice(0, materialIdx),
                        ...this.#errorMaterials.slice(materialIdx + 1),
                    ];
                }
            }
        }
        this.#propagateToMaterialAndExecute(lotType, catalog, material, callback);
    }

    /**
     * This methods climbs down the structure until we reached the given material. If necessary, it creates the given objects.
     * Then, we additionally have the possibility of executing a callback on that material.
     * @param lotType
     * @param catalog
     * @param material
     * @param callback
     * @private
     */
    #propagateToMaterialAndExecute(lotType: LotType, catalog: WhitelistCatalog, material: WhitelistMaterial, callback: (material: WhitelistMaterial) => void): void {
        // if the map does not yet contain the LotType, we add it to the map and initialize it with an empty list
        if (!this.#whitelistData.has(lotType)) {
            this.#whitelistData.set(lotType, []);
        }

        let whitelistCatalogs: WhitelistCatalog[] = this.#whitelistData.get(lotType) || [];

        // find the correct catalog that matches the active catalogId or create it, if it does not exist yet
        let catalogIndex = whitelistCatalogs.findIndex((c) => c.id === catalog.id);
        if (catalogIndex === -1) {
            whitelistCatalogs.push({
                name: catalog.name,
                id: catalog.id,
                materials: []
            });
            catalogIndex = whitelistCatalogs.length - 1;
        }

        //within the catalog, find the material that matches the active materialId, and if it does not yet exist, push it to the list
        let currentCatalog = whitelistCatalogs[catalogIndex];
        let materialIndex = currentCatalog.materials.findIndex((m) => m.id === material.id);
        if (materialIndex === -1) {
            currentCatalog.materials.push({
                id: material.id,
                name: material.name,
                properties: []
            });
            materialIndex = currentCatalog.materials.length - 1;
        }

        // Now that we got the material, we can execute our callback on this material
        let currentMaterial = currentCatalog.materials[materialIndex];
        callback(currentMaterial);
    }

    /**
     * This method removes a listElement from the list mapped to the materialProperty. If any of the objects does not exist,
     * we early return.
     * The method returns it also from the short list in the selectedMaterials
     * @param lotType
     * @param listElement
     * @param catalog
     * @param material
     * @param materialProperty
     */
    removeListElement(lotType: LotType, listElement: WhitelistMaterialPropertyListElement, catalog: WhitelistCatalog, material: WhitelistMaterial, materialProperty: WhitelistMaterialProperty) {
        if (!this.#whitelistData.has(lotType)) {
            console.log("Something strange happened, listElement was not selected for lotType anyways");
            return;
        }
        let catalogs = this.#whitelistData.get(lotType) || [];
        let selectedCatalog = catalogs.find(c => c.id === catalog.id);
        if (!selectedCatalog) {
            return;
        }
        let selectedMaterial = selectedCatalog.materials.find(m => m.id === material.id)
        if (!selectedMaterial) {
            return;
        }
        let selectedProperty = selectedMaterial.properties.find(p => p.name === materialProperty.name);
        if (!selectedProperty) {
            return;
        }
        selectedProperty.propertyListElements = [...selectedProperty.propertyListElements.filter(lE => lE.name !== listElement.name)];

        if (selectedProperty.propertyListElements.length === 0) {
            selectedMaterial.properties = [...selectedMaterial.properties.filter(p => p.name !== selectedProperty?.name)];
            if (selectedMaterial.properties.length === 0) {
                selectedCatalog.materials = [...selectedCatalog.materials.filter(m => m.id !== selectedMaterial?.id)];
            }
        }
    }

    /**
     * For a given material, it removes all the properties.
     * This means that all the listElements of a specific material are deselected and the material itself must be removed.
     * @param lotType
     * @param catalog
     * @param material
     * @private
     */
    #removeAllListElementsForMaterial(lotType: LotType, catalog: WhitelistCatalog, material: WhitelistMaterial) {
        if (!this.#whitelistData.has(lotType)) {
            return;
        }
        let catalogs = this.#whitelistData.get(lotType) || [];
        let selectedCatalog = catalogs.find(c => c.id === catalog.id);
        if (!selectedCatalog) {
            return;
        }
        if (selectedCatalog?.materials && material?.id) {
            let selectedMaterialIdx = selectedCatalog.materials.findIndex(m => m.id === material.id)

            if (selectedMaterialIdx > -1) {
                // here, create a new array rather than mutating the original
                selectedCatalog.materials = [
                    ...selectedCatalog.materials.slice(0, selectedMaterialIdx),
                    ...selectedCatalog.materials.slice(selectedMaterialIdx + 1),
                ];
            }
        }
    }


    /**
     * This method should be used when ticking the checkbox of a material. We then add it to the selected materials, but
     * do NOT add the properties/list elements belonging to that material. They remain unchecked and thus the list for that material remains empty.
     * On click of the 'Next' button, we want to find possible 'empty' materials and prompt the user to select properties for those.
     * @param lotType the current LotType
     * @param catalog
     * @param material
     */
    addMaterialKey(lotType: LotType, catalog: WhitelistCatalog, material: WhitelistMaterial): void {
        // give an empty callback, as the material is already created with an empty property list
        let callback = () => {
        }
        this.#propagateToMaterialAndExecute(lotType, catalog, material, callback)
    }

    /**
     * This method should be used for unticking the checkbox of a material. It removes the material and all the properties/
     * list elements that belonged to the material. This also means that it is completely deselected.
     * We therefore remove it from the actually selected data (i.e. the whitelistData, with #removeAllListElementsForMaterial),
     * and we remove it from the selectedMaterials, as the checkbox is deselected and thus the listElements belonging
     * to that material.
     * @param lotType the current LotType
     * @param material the material that was unchecked
     * @param catalog the catalog to which this material belongs
     */
    removeMaterialKey(lotType: LotType, material: WhitelistMaterial, catalog: WhitelistCatalog): void {
        this.#removeAllListElementsForMaterial(lotType, catalog, material);
    }

    /**
     * This method just fills the errors, so this method should be called on the next button.
     * However, to update error-materials after selection of a property, we must look in the errorMaterials list with the getter.
     * This method just checks against the selectedMaterials if there are any materials ticked with a checkbox that have no
     * selected properties/listElements. It updates errorMaterials accordingly.
     * Basically, this method populates the error list, while we can reduce it in the UI by selecting properties.
     * Calling this method runs the check again.
     */
    checkSelectedMaterials(lotType: LotType): string[] {
        this.#errorMaterials = [];
        let catalogsForLotTypes = this.#whitelistData.get(lotType) || [];
        for (let catalog of catalogsForLotTypes) {
            if (catalog.materials) {
                for (let material of catalog.materials) {
                    if (!material.properties || material.properties.length === 0) {
                        this.#errorMaterials = [...this.#errorMaterials, material.id]
                        continue;
                    }
                    for (let property of material.properties) {
                        if (!property.propertyListElements || property.propertyListElements.length === 0) {
                            this.#errorMaterials = [...this.#errorMaterials, material.id]
                        }
                    }
                }
            }
        }
        return this.#errorMaterials;
    }

    getErrorMaterials(): string[] {
        return this.#errorMaterials;
    }

    getPropertyListElementsPerProperty(lotType: LotType, activeCatalog: WhitelistCatalog, activeMaterial: WhitelistMaterial): Map<string, string[]> {
        let result: Map<string, string[]> = new Map<string, string[]>();
        let catalogs = this.#whitelistData.get(lotType);
        if (!catalogs) {
            return result;
        }

        let catalog: WhitelistCatalog | undefined = catalogs.find(c => c.id === activeCatalog.id);
        if (!catalog || !catalog.materials) {
            return result;
        }

        let material = catalog.materials.find(m => m.id === activeMaterial.id);
        if (!material) {
            return result;
        }

        material?.properties.forEach(property => {
            if (property?.propertyListElements && property.propertyListElements.length > 0) {
                result.set(property.name, property.propertyListElements.map(ple => ple.name));
            }
        });

        return result;
    }

    /**
     * This returns the LotTypes, that are actually set in the object. This can either be all available or all selected LotTypes,
     * depending on the object you are calling it on.
     */
    getLotTypes(): LotType[] {
        return Array.from(this.#whitelistData.keys()).filter(lotType => (this.#whitelistData.get(lotType) ?? []).length > 0);
    }

    getAllDataAsObject(): WhitelistData[] {
        let res = [];
        for (let [lotType, catalogs] of this.#whitelistData.entries()) {
            if (catalogs && catalogs.length > 0) {
                // check if any of the catalogs actually has materials
                for (let catalog of catalogs) {
                    if (catalog.materials && catalog.materials.length > 0) {
                        let data: WhitelistData = {lotType, catalogs}
                        res.push(data);
                        break;
                    }
                }
            }
        }
        return res;
    }

    /**
     * For a given lotType, return all the data as list of selected catalogs containing the materials, properties, listElements.
     * @param lotType
     */
    getDataForLotType(lotType: LotType) {
        return this.#whitelistData.get(lotType) || [];
    }

    /**
     * This method returns whether a materialId is included for a specific lotType. The return value of this method
     * states whether the checkbox is checked, but not necessarily whether we also have selected properties for this
     * materialId and the given LotType.
     * @param lotType
     * @param materialId
     */
    hasMaterialForType(lotType: LotType, catalogId: string, materialId: string): boolean {
        let catalogsForLotType = this.#whitelistData.get(lotType);
        if (!catalogsForLotType) {
            return false;
        }
        let catalog = catalogsForLotType.find(c => c.id === catalogId);
        if (!catalog || !catalog.materials) {
            return false;
        }
        return catalog.materials.some(m => m.id === materialId);
    }


    /**
     * Returns the number of selected materials as a sum for all types.
     */
    getOverallMaterialCount(): number {
        let res = 0;
        for (const catalogs of this.#whitelistData.values()) {
            res += catalogs.reduce((sum, catalog) => sum + (catalog.materials ? catalog.materials.length : 0), 0);
        }
        return res;
    }


    /**
     * Returns the number of materials per LotType
     * @param lotType
     */
    getMaterialCountForType(lotType: LotType): number {
        let catalogsForType = this.#whitelistData.get(lotType) || [];
        return catalogsForType.reduce((sum, catalog) => sum + (catalog.materials ? catalog.materials.length : 0), 0);
    }

    /**
     * In respect to the possibly selectable data given by 'configData', this method returns a Map of the LotTypes and the
     * respective overview texts created for the selections of that LotType.
     * @param configData
     * @param availableLotTypes the LotTypes selected by the user
     */
    getOverviewTextMappings(configData: Map<LotType, WhitelistCatalog[]>, availableLotTypes: LotType[]): Map<LotType, string> {
        const result = new Map<LotType, string>();
        availableLotTypes.forEach(l => result.set(l, "-"));
        for (let [lotType, catalogs] of this.#whitelistData) {
            result.set(lotType, this.#getOverviewText(configData.get(lotType) || [], catalogs)
                || '-') // this 'casts' any falsy values and also '' to '-' such that the active/inactive switch of the table cells work
        }
        return result;
    }

    /**
     * Creates the overview text for one LotType. The text of the selected WhitelistCatalogs is created in respect to the available
     * reference data. If everything is selected, this is shortened in order to save space.
     * Also, further save-spacing efforts are included in this method, e.g. combination of properties or list elements.
     * @param reference
     * @param selected
     * @private
     */
    #getOverviewText(reference: WhitelistCatalog[], selected: WhitelistCatalog[]): string {
        let resText: string = ''; // the complete result for a given lotType
        let allMaterialsEqual: boolean = true; // if all materials of all catalogs are equal
        let completelySpecifiedMaterials: WhitelistMaterial[] = []; // materials with all properties selected
        let materialTexts: string[] = []; // already build materialTexts for non-completely specified properties
        let catalogMaterialNumberTexts: string[] = []; // texts of the form #MATERIALS CATALOG_NAME
        let numberOfMaterials: number = 0;
        let allMaterialsOfAllCatalogsSelected: boolean = true;

        for (const refCat of reference) {
            const selectedCatalog = selected.find(cat => cat.id === refCat.id);
            // if the catalog has no selected data, we simply continue with the next catalog
            // still, those catalogs that actually have data, can still be complete and contain all selections
            if (!selectedCatalog || selectedCatalog?.materials?.length === 0) {
                // we must not set allMaterialEqual to false, as still all selected materials can be completely selected
                continue;
            }
            // build the texts and get the data for all materials
            let [materialsEqual, allMaterialsSelected, matTexts, allSelectedMaterials] = this.getMaterialEqualData(refCat.materials, selectedCatalog.materials);
            allMaterialsEqual = allMaterialsEqual && materialsEqual; // AND of all the material equalities
            allMaterialsOfAllCatalogsSelected = allMaterialsOfAllCatalogsSelected && allMaterialsSelected;
            materialTexts = [...matTexts, ...materialTexts]; // put all material texts in one list
            completelySpecifiedMaterials = [...allSelectedMaterials, ...completelySpecifiedMaterials]; // put all materials in a list that have all properties selected
            catalogMaterialNumberTexts = [...catalogMaterialNumberTexts, `${matTexts.length + allSelectedMaterials.length} ${refCat.name}`];
            numberOfMaterials = numberOfMaterials + matTexts.length + allSelectedMaterials.length; // summed number of all materials
        }

        // if there are any materials selected at all
        if (numberOfMaterials > 0) {
            // if all materials of all catalogs are selected, but not necessarily all specifications are selected
            if (allMaterialsOfAllCatalogsSelected) {
                resText = `Alle Materialien ausgewählt (${catalogMaterialNumberTexts.join(', ')})`;
            } else {
                // otherwise state how many are selected and give the information of the #materials per catalog
                resText = `${numberOfMaterials} ${numberOfMaterials === 1 ? 'Material' : 'Materialien'} ausgewählt (${catalogMaterialNumberTexts.join(', ')})`;
            }

            // in case we have some materials, that were not completely selected, we must render a different string at the beginning
            // and we must create a list with all the data including some styling for that list
            if (materialTexts.length > 0) {
                resText = resText + `; <br>Teilweise Spezifikationen pro Material ausgewählt:`;
                resText = resText +
                    `<style>
                    ul.whitelist-data {
                        padding-inline-start: 20px;
                        & > li:first-child {
                            padding-top: 10px;
                        }
                        & > li:not(:last-child){
                            padding-bottom: 10px;
                        }
                    }
                </style>` +
                    `<ul class="whitelist-data" style="">` +
                    (completelySpecifiedMaterials.length > 0 ? `<li>${completelySpecifiedMaterials.map(m => m.name).join(', ')}: Alle Spezifikationen pro Material ausgewählt` : '') +
                    `<li>${materialTexts.join('</li><li>')}` +
                    `</li>` +
                    `</ul>`;
            } else { // otherwise we simply add that all specifications are added
                resText = resText + '; Alle Spezifikationen pro Material ausgewählt'
            }
        }
        return resText;

    }

    /**
     * This method does the same check as the getOverviewText method, but rather on the material level than on the catalog level.
     * It checks for all materials of a catalog, if the properties and the propertyListElements are equal to the reference (the config).
     * We return a tuple with the form
     * [
     *     are all properties of every material completely, selected, i.e. are they completely equal to the config,
     *     whether all materials of the catalog are selected -> determines if we can show 'Alle Materialien' or 'X Materialien',
     *     the texts of the properties for those materials whose properties are not completely equal to the config and have built an own text,
     *     the materials for which the selections are like the config, that means which materials' properties are completely selected
     * ]
     * @param reference
     * @param selected
     */
    getMaterialEqualData(reference: WhitelistMaterial[], selected: WhitelistMaterial[]): [boolean, boolean, string[], WhitelistMaterial[]] {
        let allPropertiesEqual: boolean = true; // all properties of all materials are equal to the reference, i.e. are completely selected
        let materialTexts: string[] = [];
        let allPropertyMats: WhitelistMaterial[] = [];
        let allMaterialsSelected: boolean = true; // whether or not all materials of a catalog are selected
        for (let refMat of reference) {
            const selectedMaterial = selected.find(mat => mat.id === refMat.id);
            // if the refMat does not exist in the selected materials, we continue with the next
            if (!selectedMaterial) {
                allMaterialsSelected = false;
                continue;
            }

            // get the data about whether the properties are equal to the reference, i.e. are completely selected
            let [propertiesEqual, propertiesText] = this.#getPropertyEqualData(refMat.properties, selectedMaterial.properties);
            allPropertiesEqual = allPropertiesEqual && propertiesEqual;

            // if all the properties of ref and selected materials are equal, then we push this material into a list
            // where the properties are later abbreviated with 'Alle' as all properties of these materials are selected
            // we also continue with the next material in this case
            if (propertiesEqual) {
                allPropertyMats.push(refMat);
                continue;
            }
            // otherwise we push the material and the text that was created in the materialtexts
            // they are later possibly appended as a list
            materialTexts.push(`${refMat.name}: ${propertiesText}`);
        }
        // return [areAllPropertiesCompletelySelected,
        // if all the materials of the reference are actually selected -> determines if we show 'Alle Materialien' or 'x Materialien'
        // the texts of the materials where not all properties are selected,
        // the materials where all properties are selected]
        if (allPropertiesEqual) {
            return [true, allMaterialsSelected, materialTexts, allPropertyMats];
        } else {
            return [false, allMaterialsSelected, materialTexts, allPropertyMats]
        }
    }

    /**
     * Finds matching properties between a reference array and a selected array and checks if their elements are equal.
     *
     * @param {WhitelistMaterialProperty[]} reference - The reference array of material properties.
     * @param {WhitelistMaterialProperty[]} selected - The selected array of material properties.
     * @return {[boolean, string]} - An array containing a boolean indicating if all properties are equal,
     *          and a string summarizing the property comparison. If all properties are equal, i.e. completely selected,
     *          this can be abbreviated with 'Alle Spezifikationen ausgewählt'
     * @private
     */
    #getPropertyEqualData(reference: WhitelistMaterialProperty[], selected: WhitelistMaterialProperty[]): [boolean, string] {
        let allElementsEqual: boolean = true;
        let propertyTexts: string[] = [];
        for (let refProp of reference) {
            let selectedProperty = selected.find(p => p.name === refProp.name);
            if (!selectedProperty) {
                allElementsEqual = false;
                continue;
            }
            let [listElementsEqual, listElementsText] = this.#getPropertyElementsEqualData(refProp.propertyListElements, selectedProperty.propertyListElements || []);
            allElementsEqual = allElementsEqual && listElementsEqual;
            if (listElementsText !== '') { // only if the selection is not empty, we want to add this property
                propertyTexts.push(`${refProp.name}: ${listElementsText}`);
            }
        }
        return [allElementsEqual, allElementsEqual ? 'Alle Spezifikationen ausgewählt' : propertyTexts.join(', ')]
    }

    /**
     * Returns whether the given property elements have equal data.
     *
     * @param {WhitelistMaterialPropertyListElement[]} reference - The reference list of property elements.
     * @param {WhitelistMaterialPropertyListElement[]} selected - The selected list of property elements.
     * @returns {[boolean, string]} - A tuple containing a boolean value indicating whether the data is equal,
     *  and a string representing the data.
     * @private
     */
    #getPropertyElementsEqualData(reference: WhitelistMaterialPropertyListElement[], selected: WhitelistMaterialPropertyListElement[]): [boolean, string] {
        let referenceListElements = new Set(reference.map(element => element.name));
        let selectedListElements = new Set(selected.map(element => element.name));
        if (selectedListElements.size === 0) {
            return [false, ''];
        }

        let equalData = this.#areSetsEqual(referenceListElements, selectedListElements);
        if (equalData[0]) {
            return [true, 'Alle'];
        }
        return [false, selected.map(lE => lE.name).join(', ')];
    }

    #areSetsEqual(reference: Set<any>, toCheck: Set<any>): [boolean, Set<any>] {
        let sameElements = new Set<any>();
        let hasAllElements = reference.size === toCheck.size;
        for (let item of reference) {
            if (!toCheck.has(item)) {
                hasAllElements = false;
                continue;
            }
            sameElements.add(item)
        }
        return [hasAllElements, sameElements];
    }

}

/**
 * This is the structure as it comes back from the server, but it will be reformatted to a map of <LotType, WhitelistCatalog[]>.
 * Also, the logic can be done in AllWhitelistData then.
 */
export type WhitelistData = {
    lotType: LotType,
    catalogs: WhitelistCatalog[]
}

export type WhitelistCatalog = {
    id: string,
    name: string,
    materials: WhitelistMaterial[]
}

export type WhitelistMaterial = {
    id: string,
    name: string,
    properties: WhitelistMaterialProperty[]
}

export type WhitelistMaterialProperty = {
    name: string,
    propertyListElements: WhitelistMaterialPropertyListElement[],
}

export type WhitelistMaterialPropertyListElement = {
    name: string,
}
