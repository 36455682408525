import type {Material} from "./material";
import type {MaterialProperty} from "./materialProperty";


export type SubstituteMatrixData = {
    substituteType: string,
    substitutes: Map<string, SubstituteData[]>
}

export type SubstituteData = {
    publicId: string,
    substituteTypeName: string,
    referenceMaterialId: string,
    referenceMaterial: Material,
    candidateMaterialId: string,
    candidateMaterial: Material,
    type: MatchType,
    substitutionRules: string,
    properties: Map<string, SubstitutePropertyData>
}

export type SubstitutePropertyData = {
    publicId: string,
    refPropertyId: string,
    refPropertyName: string,
    matchPropertyId: string,
    matchPropertyName: string,
    definition: string
}

export type SubstituteType = {
    publicId: string,
    name: string
}

export enum  MatchType {
    MATCH, MATCH_SECOND_CLASS, SUBSTITUTE, RECOMMENDATION
}

export enum SubstituteMaterialMapping {
    REFERENCE_MATERIAL = "REFERENCE_MATERIAL" ,  CANDIDATE_MATERIAL = "CANDIDATE_MATERIAL"
}

export type Declares = {
    id: string,
    ownName: string,
    defaultName: string
}

export type SubstituteScriptImportData = {
    properties: MaterialProperty[],
    defaultPropertyName: string,
    ownPropertyName: string,
    identifier: number,
    text: string,
    materialType: SubstituteMaterialMapping
}

export type SubstituteMaterialHolder = {
    referenceMaterialId: string,
    substitutes: Map<string, SubstituteData>
}

export function DefaultSubstituteMatrixData() {
    let substituteMatrixData: SubstituteMatrixData = {
        substituteType: "",
        substitutes: new Map<string, SubstituteData[]>
    }
    return substituteMatrixData;
}
