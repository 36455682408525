<script lang="ts">

    import {ProjectState} from "../types/enums";
    import Icon from "../atoms/Icon.svelte";
    import {t} from "../../i18n/i18n";
    import projectOpen from "@/icons/projectstates/icon_project_open.svg";
    import projectClosed from "@/icons/projectstates/icon_project_closed.svg";
    import projectCanceled from "@/icons/projectstates/icon_project_canceled.svg";

    export let state: ProjectState;
    export let label: string;
    export let projectId: string;
    export let code: string;

    let shortLabel = label;
    $: if (label.length > 45) {
        let shortened = label.substring(0, 45);
        shortLabel = (shortened.endsWith("|") || shortened.endsWith(" ")
            ? shortened.substring(0, shortened.length - 2)
            : shortened) + "...";
    } else {
        shortLabel = label;
    }

    let iconSrc;
    $: switch (state) {
        case ProjectState.CANCELED:
            iconSrc = projectCanceled;
            break;
        case ProjectState.CLOSED:
            iconSrc = projectClosed;
            break;
        case ProjectState.OPEN:
            iconSrc = projectOpen;
            break;
    }
</script>

<div class="project-state-container">
    <div>
        <Icon size={28} src={iconSrc}/>
        <div class="project-state-label">
            <span><span class="uppercase {state.toString().toLowerCase()}">{$t('UI.status.withColon')}</span>&nbsp;{$t('UI.project.' + state)}</span>
            <span title={label}>{shortLabel}</span>
        </div>
    </div>
    <a href="/project/{projectId}" target="_blank" style="min-width: fit-content;">
        <span class="mm-code-link">{code}</span>
    </a>
</div>


<style lang="scss">
  @import "../../styles/global";

  .project-state-container {
    @include flex-row($justify: space-between, $alignment: flex-end);
    width: 100%;

    & > div {
      @include flex-row(0.75rem, $justify: flex-start);
    }
  }

  .project-state-label {
    @include flex-col(0.25rem, $alignment: flex-start);

    & > span:first-child {
      @include roboto-font(0.75rem, 500, 0.625rem, black);
    }

    & > span:last-child {
      @include roboto-font(19px, 400, 1rem, black);
    }
  }

  .uppercase-blue {
    text-transform: uppercase;
    //@include roboto-font(0.75rem, 500, 0.625rem, $primaryDarkBlue);
  }

  .open {
    color: $primaryDarkBlue;
  }

  .closed {
    color: #43D936;
  }

  .canceled {
    color: #FF3E3E;
  }

  .mm-code-link {
    float: right;
    @include roboto-font(normal, 400, 0.75rem, $primaryGreen);

    &:hover {
      text-decoration: underline;
    }
  }

</style>
