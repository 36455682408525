<script lang="ts">
    import {t} from "../../i18n/i18n";
    import {AllWhitelistData, type WhitelistCatalog} from "./whitelistData";
    import {LotType} from "../types/enums";
    import HeadlessTable from "../organisms/HeadlessTable.svelte";
    import {writable, type Writable} from "svelte/store";
    import {getContext, onMount, setContext} from "svelte";
    import {createCol} from "../types/headlessTable";
    import {createRender} from "svelte-headless-table";
    import ActiveTextCell from "../headlessTableCells/ActiveTextCell.svelte";
    import HtmlCell from "../headlessTableCells/HtmlCell.svelte";
    import {whitelistModalUtils} from "./whitelistModalUtil";

    export let lotTypes: LotType[] = [];
    let lotTypeTranslations = new Map<LotType, string>();

    function initializeTranslations() {
        lotTypes.forEach(lotType => lotTypeTranslations.set(<LotType>lotType, whitelistModalUtils.getLotTypeStringResource(lotType as LotType)));
    }


    type WhitelistOverviewTableEntry = {
        lotType: LotType,
        selectedDataText: string
    }

    // context stores
    let whitelistConfigData: Writable<Map<LotType, WhitelistCatalog[]>> = getContext('whitelistConfigData')
    let selectedData: Writable<AllWhitelistData> = getContext('selectedWhitelistData');


    // table data
    const tableData: Writable<any[]> = setContext('table-data', writable([]));
    const colData: Writable<any[]> = setContext('table-cols', writable([]));

    const NameCell = ({row}) => createRender(ActiveTextCell, {
        text: lotTypeTranslations.get(row.original.lotType),
        active: row.original.selectedDataText !== "-", // row is 'active' when we have properties mapped to the LotType
    });

    // when we have no active text, show it as inactive, if we have text it should be possibly scrollable
    const DataCell = ({row}) => createRender(row.original.selectedDataText !== '-' ? HtmlCell : ActiveTextCell, {
        text: row.original.selectedDataText,
        active: row.original.selectedDataText !== "-"
    })
    const tablePlugins = {
        sort: {disable: true},
        filter: {exclude: true}
    };

    $colData.push(createCol($t('UI.whitelist.overview.table.name.header'), 'name', {
        plugins: tablePlugins,
        cell: NameCell,
    }));
    $colData.push(createCol($t('UI.whitelist.overview.table.value.header'), 'selectedDataText', {
        plugins: tablePlugins,
        cell: DataCell,
    }))

    onMount(() => {
        let tD: WhitelistOverviewTableEntry[] = [];
        for (const [lotType, text] of $selectedData.getOverviewTextMappings($whitelistConfigData, lotTypes).entries()) { // interestingly works even without .entries()
            tD.push({lotType, selectedDataText: text})
        }
        $tableData = tD;
    })

    $: lotTypes, initializeTranslations();
</script>

<div class="whitelist-view-overview">
    <div>
        <span>{$t('UI.whitelist.modal.overview.header')}</span>
    </div>
    <div class="whitelist-view-overview-table scrollable">
        <HeadlessTable/>
    </div>
</div>

<style lang="scss">
  @use "../../styles/global";

  .whitelist-view-overview {
    width: 100%;

    & span {
      text-align: left;
      @include global.roboto-font(1rem, 500, 0.75rem, black);
    }
  }

  .whitelist-view-overview {
    & :global(.headless-table-header-cell) {
      --table-header-font-size: 0.75rem;
    }

    & :global(.headless-table-cell) {
      --table-cell-font-size: 0.75rem;
    }

    & :global(tr.headless-table-row > td.headless-table-cell:first-of-type),
    & :global(tr.headless-table-header > th.headless-table-header-cell:first-of-type) {
      width: 280px;
      max-width: 285px;
      display: flex;
      justify-content: flex-start;
    }

    & :global(tr.headless-table-row > td.headless-table-cell:last-of-type),
    & :global(tr.headless-table-header > th.headless-table-header-cell:last-of-type) {
      width: 100%;
    }

    & :global(tr.headless-table-header > th.headless-table-header-cell:last-of-type > div) {
      display: flex;
      justify-content: flex-start;
    }

  }

  .whitelist-view-overview-table {
    overflow-y: auto;
    max-height: 375px;
    margin-top: 22px;
  }

</style>
