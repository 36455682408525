export type LotEditFieldsView = {
    canEditLot: boolean,
    canEditCaseManager: boolean,
    canEditAfterApproval: boolean,
    canEditPubRemark: boolean,
    canEditFurtherDetails: boolean,
    canEditCommentOptimizerPublic: boolean,
    canEditCommentSales: boolean,
    canEditCommentBackoffice: boolean,
    canEditCommentAccounting:boolean
}

export function DefaultLotEditFieldsView(): LotEditFieldsView {
    return {
        canEditCaseManager: true,
        canEditLot: true,
        canEditAfterApproval: true,
        canEditPubRemark: true,
        canEditFurtherDetails: true,
        canEditCommentOptimizerPublic: true,
        canEditCommentBackoffice: true,
        canEditCommentSales: true,
        canEditCommentAccounting: true
    };
}