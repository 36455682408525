<script lang="ts">
    import {getContext} from "svelte";
    import FakBaseLayout from "../fak/FakBaseLayout.svelte";
    import {mapFormControls} from "../fak/utils/fakStores";
    import type {Writable} from "svelte/store";

    export let position: google.maps.ControlPosition;
    let map: Writable<google.maps.Map> = getContext('map');

    function createControl(element: HTMLElement) {
        $map.controls[position].push(element);
    }

    window.addEventListener("openMapFormProject", (e) => {
        $mapFormControls.projectId = e.detail.projectId;
        $mapFormControls.level = 1;
    });

    window.addEventListener("openMapFormProjectLot", (e) => {
        $mapFormControls.projectId = e.detail.projectId;
        $mapFormControls.lotId = e.detail.lotId;
        $mapFormControls.level = 1;
    });

</script>

<div style="display: none">
    <div use:createControl>
        <div class="map-form-control"
             style="opacity: {$mapFormControls.level > 0 ? '1' : '0'}; position: {$mapFormControls.level > 0 ? 'fixed' : 'unset'};">
            {#if $mapFormControls.level > 0}
                <FakBaseLayout/>
            {/if}
        </div>
    </div>
</div>

<style>
    .map-form-control {
        position: fixed;
        z-index: 1000002; /* This will also overlay the Google Maps footer in the bottom right with z-index 1000001 */
        transition: opacity .5s ease-out;
        backdrop-filter: brightness(40%);
        -webkit-backdrop-filter: brightness(40%);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>