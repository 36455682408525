<script lang="ts">

    import {createEventDispatcher, getContext, hasContext} from "svelte";
    import MaterialInputField from "../molecules/MaterialInputField.svelte";
    import {DefaultInputFieldProps, type FontProps} from "../types/fontProps";
    import {DefaultRobotoFontProps, getFontPropsVarString} from "../types/fontProps";
    import {t} from "../../i18n/i18n";
    import Tooltip from "../atoms/Tooltip.svelte";
    import {inputFieldFormats} from "../utils/formatters";
    import iconAddAutocomplete from "@/icons/icon_add_autocomplete_entry.svg";

    const dispatch = createEventDispatcher();
    const useMaterialDesign: boolean = hasContext('useMaterialDesign') ? getContext('useMaterialDesign') : false;
    export let showInput: boolean = true; // with this flag, we can decide to only show the chiplist without the possibility to add new entries into the list
    export let placeholder: string; // placeholder for the input field
    export let list = [];
    export let readOnlyEntries: string[] = null;
    export let readOnly: boolean;
    export let onInputFinished = () => {
    };
    export let materialWidth: string = "100%";
    export let materialLabel: string;
    export let borderless: boolean = false;
    export let fontProps: FontProps = DefaultInputFieldProps();
    export let format = inputFieldFormats.FULL;

    export let chipFontProps: FontProps = DefaultRobotoFontProps('0.75rem', '0.625rem', '#9d9d9d', 'white', 400);
    export let chipPadding: string ="0.25rem 0.5rem 0.2rem 0.5rem";
    export let chipRadius: string = null;
    let pad, radius;
    $: chipPadding, pad = chipPadding && chipPadding !== "" ? "--chip-padding: " + chipPadding + ";" : "";
    $: chipRadius, radius = chipRadius && chipRadius !== "" ? "--chip-radius: " + chipRadius + ";" : "";

    let value;

    function addValue() {
        if (!list.includes(value)) {
            list = [...list, value];
        }
        value = "";
    }

    function removeItem(itemToRemove: string) {
        if (list.includes(itemToRemove)) {
            list = list.filter(item => item !== itemToRemove);
            dispatch('chipRemoved');
        }
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            addValue();
        }
    }
</script>


<div class="chip-list-container">
    {#if showInput}
        <div class="input-with-plus">
            {#if useMaterialDesign}
                <MaterialInputField bind:value {placeholder} {onInputFinished} {fontProps} {borderless}
                                    width={materialWidth} disabled={readOnly} label={materialLabel}
                                    on:keydown={handleKeyDown}/>
            {:else}
                <input class="input-field-focus" type="text" class:borderless
                       style="width: {format.maxWidth}; max-width: {format.maxWidth}"
                       bind:value {placeholder} disabled={readOnly}
                       on:focusout on:blur={onInputFinished} on:keydown={handleKeyDown}>
            {/if}
            {#if !readOnly}
                <div class="add-chip-button" on:click={addValue}>
                    <div class="green-icon">
                        <Tooltip msg={$t("UI.chipList.addEntry")}
                                 ttipIconImage={iconAddAutocomplete}
                                 ttipIconWidth="20px" ttipIconHeight="20px"/>
                    </div>
                </div>
            {/if}
        </div>
    {/if}
    <div class="chip-list" style="{getFontPropsVarString(chipFontProps)}">
        {#each list.filter(i => i !== "") as item, i}
            {#if readOnlyEntries && readOnlyEntries.includes(item)}
                <div class="chip" style="{pad}{radius}">
                    <span>{item}</span>
                </div>
            {:else}
                <div class="chip" style="{pad}{radius}">
                    <span>{item}</span>
                    <div class="remove-chip-btn" on:click={() => removeItem(item)}>
                        <div></div>
                    </div>
                </div>
            {/if}
        {/each}
    </div>
</div>


<style lang="scss">
  @import "../../styles/global";

  .chip-list-container {
    @include flex-col(0.375rem, $alignment: flex-start);
    width: 100%;

    & > .input-with-plus {
      width: 100%;
      position: relative;

      & > .add-chip-button {
        position: absolute;
        top: 4px;
        right: 0;
      }

    }


    & > .chip-list {
      @include flex-row(1rem, $justify: flex-start);
      flex-wrap: wrap;
      row-gap: 0.375rem;

      & > div {
        @include roboto-font(var(--line-height), var(--font-weight), var(--font-size), var(--color));
        @include flex-row(0.25rem, $alignment: center);
        border-radius: var(--chip-border-radius, 34px);
        padding: var(--chip-padding);
      }
    }
  }

  .remove-chip-btn {
    @include flex-col();
    width: 15px;
    height: 15px;
    cursor: pointer;

    & > div {
      width: 11px;
      height: 11px;
      background: url("@/icons/icon_delete_chip.svg") no-repeat center;
    }
  }


  .chip {
    border: 1px solid $primaryGreen;

    & > span {
      line-height: normal;
    }
  }

  .green-icon {
    background-color: $primaryGreen;
    border-radius: 4px;
    margin-right: 0.375rem;
    width: 24px;
    height: 24px;
    @include flex-col();
  }

</style>
