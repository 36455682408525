<script lang="ts">

    import type {MatchMarkerDto} from "../types/matchMarkerDto";
    import CollapsibleCard from "../../molecules/CollapsibleCard.svelte";
    import Switch from "../../atoms/Switch.svelte";
    import iconCatchment from "@/icons/catchmentIcon.svg";
    import iconMatch from "@/icons/matches_icon.svg";
    import {t} from "../../../i18n/i18n";
    import {getContext} from "svelte";
    import Icon from "../../atoms/Icon.svelte";
    import MapMatchingTableRow from "./MapMatchingTableRow.svelte";
    import {closeModal, openModal} from "svelte-modals";
    import Modal from "../../organisms/Modal.svelte";
    import type {Writable} from "svelte/store";
    import ConditionalMatchMapTooltip from "./ConditionalMatchMapTooltip.svelte";

    export let markers: any[] = [];
    export let match: any;
    export let referenceMarker: any;
    export let showPublishLotOnMM: boolean = false;
    let matchMarker = markers[0];
    let showCard: boolean = false;
    let hideSubstitutes: boolean = false;
    let hideConditionalMatches: boolean = false;
    let hidePrices: boolean = false;
    let maxNumberOfMatches: number = 5;

    let mapStore: Writable<google.maps.Map> = getContext('map');
    let map = $mapStore;

    let matchData: MatchMarkerDto[] = [];
    let filteredMatchData: MatchMarkerDto[] = [];

    function createSubstituteTooltip(container: HTMLElement) {
        // this is not the best solution as we can refactor this in leaflet map
        document.getElementsByClassName('full-screen')[0].append(container);
    }

    function onMarkerChange() {
        showCard = true;
        Object.assign(matchData, markers.map(abstractMarker => abstractMarker.item));
        matchData = matchData;
        referenceMarker = referenceMarker;
    }

    function isMatchMarkerPriceNotZeroOrEmpty(price: string) {
        return price && "0,00&nbsp€" !== price.trim() && "0,00 €" !== price.trim() && price.trim() !== "Auf Anfrage";
    }

    function filterMatches() {
        filteredMatchData = structuredClone(matchData);
        // filter match Data by selected filters
        if (hideConditionalMatches) {
            filteredMatchData = matchData.filter(matchMarker => !matchMarker.isConditionalMatch || matchMarker.isSubstituteMatch);
        }
        if (hideSubstitutes) {
            filteredMatchData = filteredMatchData.filter(matchMarker => !matchMarker.isSubstituteMatch);
        }
        if (hidePrices) {
            filteredMatchData = filteredMatchData.filter(matchMarker => isMatchMarkerPriceNotZeroOrEmpty(matchMarker.price));
        }

        if (matchData.length > maxNumberOfMatches && filteredMatchData.length > maxNumberOfMatches) {
            filteredMatchData.pop();
        }
        filteredMatchData = filteredMatchData;
        // update markers on map
        markers.forEach(abstractMarker => {
            const idx = filteredMatchData.findIndex(matchDataItem => matchDataItem.objectId === abstractMarker.item.objectId)
            if (abstractMarker.googleMarker) {
                if (idx === -1) {
                    abstractMarker.googleMarker.setMap(null)
                } else {
                    abstractMarker.googleMarker.setMap(map)
                }
            }
        });
        if (match && filteredMatchData.findIndex(matchDataItem => matchDataItem.objectId === match.objectId) === -1 && filteredMatchData.length < markers.length && maxNumberOfMatches <= markers.length) {
            increaseMatches();
        }
    }

    function routeToMatchAndOpenPopup(event) {
        const endMarkerIndex = markers.findIndex(marker => marker.item.objectId === event.detail.endMarkerId);
        matchMarker = markers.at(endMarkerIndex);
        window.dispatchEvent(new CustomEvent<any>('trigger-route-calculating', {detail: [referenceMarker, markers.at(endMarkerIndex)]}));
        setTimeout(function () {
            markers.at(endMarkerIndex).showInfoWindow(false)
        }, 600);
        matchMarker.centerOnMap();
    }

    function increaseMatches() {
        maxNumberOfMatches = maxNumberOfMatches + 5;
    }

    function openModalOfPublishSaasLotOnMM() {
        openModal(Modal, {
            title: $t("MarkVisible.mm.confirm.header"),
            message: $t("MarkVisible.mm.confirm.message"),
            onAccept: async () => {
                closeModal();
                switchToLot();
            },
            acceptText: $t("UI.button.next"),
        });
    }

    function switchToLot() {
        window.parent.location.href = "/saas/lot/" + referenceMarker.item.lotId + "/markVisible?visibility=PUB&nomatches=true";
    }

    $: markers, referenceMarker, onMarkerChange();
    $: hideConditionalMatches, hideSubstitutes, hidePrices, maxNumberOfMatches, matchData, filterMatches();

</script>

<ConditionalMatchMapTooltip/>

{#if showCard}
    <div style="width: 100%;">
        <CollapsibleCard openByDefault>
            <div slot="header" class="matches-table-header">
                <span>{$t("MatchesTable.link")}</span>
            </div>
            <div slot="body" class="matches-table-body">
                {#if markers.length === 0}
                    <span class="no-match-result">{$t("Matching.noResults.title")}</span>
                    {#if showPublishLotOnMM}
                        <div>
                            <span class="no-match-result">{$t("Matching.noResults.publishOnMM.text")}</span>
                            <a on:click={openModalOfPublishSaasLotOnMM}
                               class="no-match-result">{$t("Lot.buttons.publishOnMM")}</a>
                        </div>
                    {/if}
                {:else}
                    <div class="filter-control-headline">
                        {$t("UI.filters")}
                    </div>
                    <div class="matches-filter-control">
                        <div>
                            <span>{$t("UI.Map.MatchingTableRow.Filter.showPrices")}</span>
                            <div>
                                <Switch bind:checked={hidePrices}/>
                            </div>
                        </div>
                        <div>
                            <span>{@html $t("UI.Map.MatchingTableRow.Filter.hideSubstitutes")}</span>
                            <div>
                                <Switch bind:checked={hideSubstitutes}/>
                            </div>
                        </div>
                        <div>
                            <span>{@html $t("UI.Map.MatchingTableRow.Filter.hideRecommendations")}</span>
                            <div>
                                <Switch bind:checked={hideConditionalMatches}/>
                            </div>
                        </div>
                    </div>
                    <div class="card-matches-table">
                        <table>
                            <thead>
                            <tr>
                                <th>{$t("Map.matches.head.price")}</th>
                                <th>{$t("Map.matches.head.transportCost")}</th>
                                <th>{$t("Map.matches.head.cost")}</th>
                                <th>{$t("Map.matches.head.economy")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#if filteredMatchData.filter(matchMarker => matchMarker.isCatchmentAreaRequirement).length > 0 }
                                <tr class="catchment-area-requirement">
                                    <td colspan=4>
                                        <div>
                                            <div>
                                                <Icon src="{iconCatchment}" size={24}/>
                                            </div>
                                            <span>{$t("Map.catchmentArea.titel")}</span>
                                        </div>
                                    </td>
                                </tr>
                                {#each filteredMatchData.filter(matchMarker => matchMarker.isCatchmentAreaRequirement) as data}
                                    <MapMatchingTableRow matchData={data} on:triggerRouting={routeToMatchAndOpenPopup}/>
                                {/each}
                            {/if}
                            <tr class="default-matches-row">
                                <td colspan=4>
                                    <div>
                                        <div class="match-icon">
                                            <Icon src={iconMatch} size={24}/>
                                        </div>
                                        <span>
                                        {filteredMatchData.filter(matchMarker => matchMarker.isCatchmentAreaRequirement).length === 0 ? $t("Map.matches.matchType.text.default") : $t("Map.matches.matchType.text.other")}
                                    </span>
                                    </div>
                                </td>
                            </tr>
                            {#each filteredMatchData.filter(matchMarker => !matchMarker.isCatchmentAreaRequirement) as data}
                                <MapMatchingTableRow matchData={data} on:triggerRouting={routeToMatchAndOpenPopup}/>
                            {/each}
                            </tbody>
                        </table>
                    </div>
                    {#if showPublishLotOnMM}
                        <div class="few-match-results">
                            <span>{$t("Matching.fewResults.publishOnMM.text")}</span>
                            <a on:click={openModalOfPublishSaasLotOnMM}>{$t("Lot.buttons.publishOnMM")} </a>
                        </div>
                    {/if}
                    {#if filteredMatchData.length !== matchData.length && matchData.length > maxNumberOfMatches }
                        <div class="increase-matches">
                            <div on:click={increaseMatches}>
                                {$t("Map.matches.head.footer.moreMatches")}
                            </div>
                        </div>
                    {/if}
                {/if}
            </div>
        </CollapsibleCard>
    </div>
{/if}

<style lang="scss">
  @import "../../../styles/global";
  @import "../../../styles/colors";

  th, tr, td {
    border: none;
    padding: 0 0.5rem 0 0.5rem;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  .matches-table-header {
    @include roboto-font(18.75px, 400, 1rem, black);
    padding: 23px 50px 19px 34px;
    cursor: pointer;
  }

  .matches-table-body {
    @include roboto-font(15px, 400, 10PX, black);
    @include flex-col(0, flex-start);
    padding: 0 0 .5rem 0;
    row-gap: 0;
    width: 100%;
    max-width: 420px;

    & > {
      .matches-filter-control {
        @include flex-row(0.5rem, $justify: flex-start);
        flex-wrap: wrap;
        padding: 0 0.5rem 0 2.1rem;

        & > div {
          @include flex-row($justify: flex-start);
          flex-flow: row;
          width: 11.5rem;
          align-items: center;
          padding-bottom: 0.5rem;

          & > span {
            @include roboto-font(0.875rem, 400, 0.725rem, #5e6468);
            max-width: 10rem;
          }
        }

        & > div:first-child {
          column-gap: 4.2rem;
        }

        & > div:nth-child(2) {
          column-gap: 3.8rem;
        }

        & > div:last-child {
          column-gap: .5rem;
        }
      }
    }

    .card-matches-table::-webkit-scrollbar {
      display: none;
    }

    .card-matches-table {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      padding-top: 1rem;
      border: none;
      width: 100%;
      max-height: 290px;
      overflow-x: hidden;
      overflow-y: auto;

      table {
        width: 100%;
        border: none;

        tr > th {
          @include roboto-font(0.875rem, 400, 0.7rem, black);
          width: 64px;
          text-align: end;
          padding-bottom: .5rem;
        }

        tr {
          th:first-child {
            text-align: start;
            padding-left: .8rem;
          }

          th:last-child {
            padding-right: 1rem;
          }
        }
      }

      table > tbody > tr.catchment-area-requirement {
        background-color: #ECF5FF;
        height: 30px;

        td {
          color: #23456D;
          font-weight: 500;

          div {
            display: flex;
            padding-left: .05rem;

            div {
              background-size: 100% auto;
              width: 24px;
              height: 24px;
            }

            span {
              padding-left: 0.5rem;
              padding-top: 0.2rem
            }
          }
        }
      }

      .default-matches-row {
        background-color: #E2FFEB;
        height: 30px;

        div {
          display: flex;
          padding-left: .15rem;
        }

        & > {
          td {
            color: #58C079;
            font-weight: 500;
            padding-left: .35rem;
          }

          .match-icon {
            background-size: 100% auto;
            width: 24px;
            height: 24px;
          }
        }

        span {
          padding-left: 0.5rem;
          padding-top: 0.35rem
        }
      }
    }

    .increase-matches {
      width: 100%;
      padding: .5rem 1rem 0;
      box-sizing: border-box;

      div {
        float: right;
        @include roboto-font(18.75px, 400, 0.8rem, #58C079);
        cursor: pointer;
      }
    }
  }

  .few-match-results {
    display: contents;

    span {
      padding-top: 1rem;
      padding-left: 1.4rem;
      @include roboto-font(0.875rem, 500, 0.825rem, #5e6468);
      display: inline-block;
    }

    a {
      padding-left: 1.4rem;
      @include roboto-font(0.875rem, 500, 0.825rem, #5e6468);
      cursor: pointer;
      padding-top: 0.3rem;
    }
  }

  .no-match-result {
    padding-left: 1.4rem;
    @include roboto-font(0.875rem, 500, 0.825rem, #5e6468);
    display: inline-block
  }

  // override some global styles for map
  div > :global(.card) {
    position: relative;
    background-color: white;
    border: none !important;
    border-radius: 10px !important;
    box-shadow: none !important;
  }

  div > :global(.card .arrow-handle) {
    top: 16px !important;
    right: 21px !important;
  }

  .filter-control-headline {
    padding-left: 2.15rem;
    padding-bottom: 0.2rem;
    @include roboto-font(18.75px, 400, 0.875rem, black);
  }

</style>