<script lang="ts">
    export let size = 24;
    export let color = "#58C079";
</script>

<svg width={size} height={size} viewBox="0 0 {size} {size}" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" focusable="false" on:click>
    <path d="M8 8L16 16" stroke={color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M16 8L8 16" stroke={color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
</svg>

<style lang="scss">
    svg:hover {
        cursor: pointer;
    }
</style>