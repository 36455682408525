<script lang="ts">
    import {t} from "../../i18n/i18n"
    import Tooltip from "../atoms/Tooltip.svelte";
    import {formatNumber, formatVolume} from "../utils/formatters"
    import {createEventDispatcher, getContext, hasContext} from "svelte";
    import type {Lot} from "../types/lot.ts";
    import {CalcMethod, LotState, LotType, OPTIMIZED_STATES, TooltipPlacements} from "../types/enums";
    import changeIcon from "@/images/ChangeIcon.svg";
    import StylableLotTypeIcon from "../molecules/StylableLotTypeIcon.svelte";
    import {type Readable, writable, type Writable} from "svelte/store";
    import type {DealPartner, DealPartnerConnect} from "../types/dealPartner";
    import type {Deal} from "../bid/types/deal";
    import {DefaultCompanyData} from "../types/companyData";
    import {DefaultContactData} from "../types/contactData";

    const dispatch = createEventDispatcher();
    const showKickback: Writable<boolean> = hasContext("showKickback") ? getContext("showKickback") : writable(false);
    const lot: Readable<Lot> = getContext("lot");
    const dealPartnerConnect: Writable<DealPartnerConnect> = getContext("dealPartnerConnect");
    const deal: Writable<Deal> = getContext("deal");

    export let showBackside = false;
    export let offererPage = false;
    export let isPlaceholder: boolean = false;

    let requestingPartner: DealPartner, optimizingPartner: DealPartner;

    let priceTtMsg: string;
    let sumTtMsg: string;

    let arePartnersConnected: boolean;
    let showPriceAndSales: boolean;
    let showContactData: boolean;
    let showFlipButton: boolean;

    let formattedPrice: string = "-"
    let formattedSum: string = '-'

    let hasPrice: boolean;
    let hasSum: boolean;
    let lotNumber: string = "-";

    let priceTooltip: Tooltip;
    let priceSumTooltip: Tooltip;

    let showKickbackTooltipForRequestOffer: boolean; // defines if we actually want to see the tooltip at this state

    async function sendFlipEvent() {
        dispatch('flip');
    }

    function createKickbackTt() {
        let {grossPricePerTon, kickbackPerTon, kickbackInPercent} = $lot;
        let formattedKickbackPerTon = formatNumber(kickbackPerTon);
        let formattedKickbackInPercent = formatNumber(kickbackInPercent);
        let msg;

        if (grossPricePerTon <= kickbackPerTon) {
            msg = $t('UI.excluding') + " " + $t('UI.price.infoCard.perTon', {perTon: formattedKickbackPerTon});
        } else {

            msg = $t('UI.including.short')
            if (kickbackInPercent) {
                msg += $t('UI.price.infoCard.percent', {inPercent: formattedKickbackInPercent});

                if (kickbackPerTon) {
                    msg += $t('UI.addKickback.connector') + $t('UI.price.infoCard.perTon', {perTon: formattedKickbackPerTon});
                }
            } else if (kickbackPerTon) {
                msg += $t('UI.price.infoCard.perTon', {perTon: formattedKickbackPerTon});
            }
        }
        return msg;
    }

    $: if ($lot) {
        arePartnersConnected = $lot?.state === LotState.HANDLING
            || $lot?.state === LotState.ACCOUNTING
            || $lot?.state === LotState.COMPLETED;
        showContactData = arePartnersConnected && !showBackside;
        showFlipButton = arePartnersConnected;
        showPriceAndSales = arePartnersConnected || $lot?.calcMethod === CalcMethod.NORMAL;
        // we don't show the kickback tooltip if we are in a request offer and before BIDDING
        showKickbackTooltipForRequestOffer = !($lot?.calcMethod === CalcMethod.REQUEST_OFFER && !OPTIMIZED_STATES.includes($lot?.state));
    }

    $: {
        formattedPrice = $lot?.grossPricePerTon ? formatNumber($lot?.grossPricePerTon) : $deal?.dealFacts?.priceValFormattedAsString || "-";
        let price = $lot?.grossPricePerTon || $deal?.dealFacts?.price || 0;
        let volume = $lot?.volume || $deal?.dealFacts.volume || 0;
        formattedSum = volume && price ? formatNumber(volume * price) : "-";
        hasPrice = formattedPrice !== "-";
        hasSum = formattedSum !== "-";
    }

    $: if (($lot?.kickbackPerTon || $lot?.kickbackInPercent) && $showKickback) {
        priceTtMsg = $t('UI.price.infoCard.base', {kickbackValues: createKickbackTt()});
        sumTtMsg = $t('UI.tooltip.sales', {kickbackValues: createKickbackTt()});
        if (priceTooltip && priceSumTooltip && !isPlaceholder) {
            priceTooltip.enable();
            priceSumTooltip.enable();
            priceTooltip.show();
            priceSumTooltip.show();
        }
    }

    $: lotNumber = ($lot?.correlationId ? ($lot?.correlationId + " / ") : "") + $lot?.code;
    $: if ($dealPartnerConnect) {
        requestingPartner = $dealPartnerConnect?.offerer ?? {
            company: DefaultCompanyData(),
            contact: DefaultContactData()
        };
        optimizingPartner = $dealPartnerConnect?.optimizer ?? {
            company: DefaultCompanyData(),
            contact: DefaultContactData()
        };

    }
</script>

<div class="material-information" style="{showBackside ? 'border: 1px solid #919191' : ''}">
    <div class="image-container" style="{showBackside ? 'filter: grayscale(100%) blur(2px)' : ''}">
        <StylableLotTypeIcon lotType={$lot?.type ?? LotType.DISPOSAL}/>
    </div>
    <div class="main-info-container" class:small-margin-first={showContactData}>
        <span class="headline">{$t('UI.LotType.' + $lot?.type)}</span>
        <span class="small">{$t('UI.LotType.' + $lot?.type + '.info')}</span>
    </div>
    <div class="material-info-container" class:small-margin-middle={showContactData}>
        <span class="{showBackside ? 'grey-text' : 'green-text'} headline"
              id="material-info-container-formatted-volume">{@html formatVolume($lot?.volume) || $deal?.dealFacts?.volumeFormattedAsString || "-"}
            &nbsp;{$t("UI.unit.ton")}</span>
        <div class="{showBackside ? 'grey-text' : 'green-text'} headline">
            <div style="margin-bottom: 5px;">
                <section class="headline-for-tooltip {showBackside ? 'grey-text' : 'green-text'}">
                    {@html $lot?.materialName || "-"}
                </section>
            </div>
        </div>
        <span></span>
        <!--remove the content of this span because it is included in the material properties but keep span for space -->
    </div>
    <div class="material-comment-container">
            <span class="small"
                  style="white-space: pre-wrap;margin-top: 5px">{$lot?.materialPropertiesWithoutOptional}</span>
    </div>
    {#if $lot?.calcMethod}
        {#if showPriceAndSales}
            <div class="cost-info-container" class:small-margin-last={showContactData}>
                <div>
                    <div class="small-for-tooltip price-info" id="cost-info-container-formatted-price">
                        <div><span>{@html formattedPrice}&nbsp;{$t("UI.unit.eurPerTon")}</span></div>
                        {#if $showKickback && hasPrice && showKickbackTooltipForRequestOffer}
                            <div class="tooltip-icon">
                                <Tooltip bind:this={priceTooltip} msg={priceTtMsg} isHtml/>
                            </div>
                        {/if}
                    </div>
                </div>
                <div>
                    <div class="small-for-tooltip price-info" id="cost-info-container-formatted-sum">
                        <div><span>{@html formattedSum}&nbsp;{$t("UI.unit.eur")}</span></div>
                        {#if $showKickback && hasSum && showKickbackTooltipForRequestOffer}
                            <div class="tooltip-icon">
                                <Tooltip bind:this={priceSumTooltip} msg={sumTtMsg}
                                         placementTtip={TooltipPlacements.BOTTOM_START} isHtml/>
                            </div>
                        {/if}
                    </div>
                </div>
            </div>
        {:else}
            <div class="cost-info-container" class:small-margin-last={showContactData}>
                <div class="small">
                    {$t("UI.CalcMethod.REQUEST_OFFER")}
                </div>
            </div>
        {/if}
    {/if}
    {#if showContactData}
        <div class="contact-info-container">
            <span class="headline">{$t('UI.Model.label.contactData')}</span>
            {#if offererPage}
                <div>
                    <div class="small-for-tooltip" style="margin-top: 11px;">
                        <div><span>{@html optimizingPartner.company.companyName || "-"}</span></div>
                        <div class="ttip-placement">
                            <Tooltip msg={(optimizingPartner.company.address.zip || '-') + ' ' + (optimizingPartner.company.address.city || '-') + '\n' +
                            (optimizingPartner.company.address.street || '-')}/>

                        </div>
                    </div>
                </div>
                <span class="small">{@html optimizingPartner.contact.fullName || "-"}</span>
                <span class="small">
                    {@html optimizingPartner.contact.phone || "-"}
                    {@html optimizingPartner.contact.mobile || "-"}
                </span>
                <span class="small">
                    {@html optimizingPartner.contact.eMail || "-"}
                </span>
            {:else}
                <div>
                    <div class="small-for-tooltip">
                        <div><span>{@html requestingPartner.company.companyName || "-"}</span></div>
                        <div class="tooltip-icon">
                            <Tooltip msg="{(requestingPartner.company.address.zip || '-') + ' ' + (requestingPartner.company.address.city || '-') + '\n' +
                            (requestingPartner.company.address.street || '-')}"/>
                        </div>
                    </div>
                </div>
                <span class="small">{requestingPartner.contact.fullName || "-"}</span>
                <span class="small">
                    {@html requestingPartner.contact.phone || "-"}
                    {@html requestingPartner.contact.mobile || "-"}
                </span>
                <span class="small">
                    {@html requestingPartner.contact.eMail || "-"}
                </span>
            {/if}
        </div>
    {/if}
    {#if showFlipButton}
        {#if showBackside}
            <div class="icon-container-backside" on:click={sendFlipEvent}>
                <img class="flip-icon-backside" src={changeIcon} alt="card flip">
            </div>
        {:else}
            <div class="icon-container" on:click={sendFlipEvent}>
                <svg class="flip-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <g id="a"/>
                    <g id="b">
                        <g id="c">
                            <g>
                                <rect class="d" width="20" height="20" fill="none"/>
                                <path class="e" fill="none"
                                      d="M4.69,8.52c.65-2.33,2.79-4.03,5.32-4.03s4.67,1.7,5.32,4.03"/>
                                <path class="e" fill="none"
                                      d="M15.34,11.49c-.65,2.34-2.79,4.05-5.33,4.05s-4.68-1.71-5.33-4.05"/>
                                <polyline class="e" fill="none" points="7.52 12.51 4.67 11.48 3.57 14.51"/>
                                <polyline class="e" fill="none" points="12.48 7.5 15.33 8.53 16.43 5.5"/>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        {/if}
    {/if}
    <span class="lot-number">{lotNumber}</span>
</div>

<style lang="scss">
  @import "../../styles/global";

  .material-comment-container {
    padding: 0 30px;
  }

  .material-information {
    display: flex;
    flex-direction: column;
    border: 1px solid $primaryColor;
    position: relative;
    border-radius: 6px;
    width: 100%;
    height: 450px;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & > .small-margin-first {
      margin-top: 4rem !important;
    }

    & > .small-margin-middle {
      margin-top: 0.9rem !important;
    }

    & > .small-margin-last {
      margin-top: 0.9rem !important;
    }

    & > .main-info-container {
      margin-top: 7.063rem;

      & > span:nth-of-type(2) {
        padding-top: 0.375rem;
      }
    }

    & > .material-info-container {
      margin-top: 1.875rem;

      & > div {
        position: relative;
        padding-top: 0.5rem;
      }

      & > span:not(:first-child) {
        padding-top: 0.5rem;
      }
    }

    & > .cost-info-container {
      margin-top: 1.875rem;

      & > div {
        position: relative;
      }

      & > div:not(:first-child) {
        padding-top: 0.5rem;
      }
    }

    & > .contact-info-container {
      margin-top: 2.25rem;
      margin-bottom: 4.375rem;

      & > div {
        padding-top: 0.75rem;
        position: relative;
      }

      & > span:not(:first-child) {
        padding-top: 0.375rem;
      }
    }
  }

  .green-text {
    color: $primaryColor !important;
  }

  .grey-text {
    color: #919191 !important;
  }

  .image-container {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    opacity: 20%;
    filter: blur(2px);
    top: 0;
    left: 0;
  }

  .image {
    position: absolute;
    height: 360px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .image-backfill {
    left: 52% !important;
    width: 575px;
  }

  .image-disposal {
    width: 480px;
  }

  .headline {
    @include roboto-font(20px, 500);
  }

  .small {
    @include roboto-font(14px, 400, 0.75rem);
  }

  .lot-number {
    @include roboto-font(14px, 300, 0.75rem);
    text-align: left;
    position: absolute;
    left: 1.875rem;
    bottom: 1.375rem;
  }

  .icon-container {
    position: absolute;
    border: 1px solid $primaryColor;
    border-radius: 4px;
    width: 2.2rem;
    height: 2.2rem;
    top: 1.25rem;
    right: 1.25rem;
  }

  .icon-container-backside {
    @extend .icon-container;
    background: $primaryColor;
  }

  .flip-icon {
    margin: 0.375em;
  }

  .flip-icon-backside {
    @extend .flip-icon;
    filter: brightness(100);
  }

  .info-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    right: 7.938rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("@/images/info-icon-filled-16px.svg");
  }

  .info-icon-downshift {
    @extend .info-icon;
    margin-top: 0.5rem;
  }

  .info-icon-contact {
    @extend .info-icon;
    margin-top: 0.6rem;
    right: 6.5rem;
    white-space: pre-line;
  }

  .headline-for-tooltip {
    @include roboto-font(20px, 500);
    @include flex-row();
    flex-wrap: wrap;
  }

  .small-for-tooltip {
    @include roboto-font(14px, 400, 0.75rem);
    @include flex-row(6px, $justify: space-between);
    flex-wrap: wrap;

    & > div:first-of-type {
      @include flex-row();
    }
  }

  .price-info {
    & span {
      @include roboto-font(20px, 500, 1rem);
    }
  }

  .ttip-placement {
    margin-top: -2px;
    margin-left: 10px;
    margin-right: -27px;
  }

  svg .e {
    stroke: $primaryColor;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

</style>