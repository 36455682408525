<script lang="ts">
    import {setContext} from "svelte";
    import {writable} from "svelte/store";
    import LotFileUpload from "./LotFileUpload.svelte";
    import LotComments from "./LotComments.svelte";
    import LotAnalyticalList from "./LotAnalyticalList.svelte";
    import {t} from "../../../../i18n/i18n";
    import LotStateChanger from "./LotStateChanger.svelte";
    import {LotState, LotType, OPTIMIZED_STATES} from "../../../types/enums";
    import QuoteAttachments from "./QuoteAttachments.svelte";
    import FakAccordionItem from "../FakAccordionItem.svelte";
    import FakAccordionItemHeader from "../FakAccordionItemHeader.svelte";
    import LotForm from "./LotForm.svelte";
    import NullableDataWrapper from "../../../utils/NullableDataWrapper.svelte";
    import {currentLot} from "../utils/fakStores";
    import LotMaterialForm from "./LotMaterialForm.svelte";
    import SaveNewLotItem from "./SaveNewLotItem.svelte";


    setContext('selectedItemId', writable(null));

    let analyticString;
    let hasAnalyticals;
    let hasAttachments;
    let showAddAttachments;
    let isNotNewLot = $currentLot.id !== "new";
    $: showAddAttachments = !hasAttachments;
    $: if ($currentLot) {
        if ($currentLot.type === LotType.DISPOSAL) {
            analyticString = $t('UI.map.analytic.disposal');
        } else if ($currentLot.type === LotType.MATERIAL_SALE) {
            analyticString = $t('UI.map.analytic.materialSale');
        } else {
            analyticString = $t('UI.map.analytic.materialNeed');
        }

        // This is necessary because of the delay caused by various animation durations and delays
        setTimeout(() => {
            isNotNewLot = $currentLot?.id !== "new";
        }, 601);
    }
</script>


<ul id="lot-details-accordion" class="position-details-accordion">
    <NullableDataWrapper bind:data={$currentLot} delay={500}>
        <li style="width: 100%; background: #FFFFFF; border-radius: 0.25rem; margin-bottom: 0.625rem;">
            <LotForm/>
        </li>

        <FakAccordionItem id={0} closeOnHeaderClick={true} openByDefault={true}>
            <div slot="header" style="height: 3.875rem; width: 100%;">
                <FakAccordionItemHeader label={$t('UI.map.card.lot.material')} id={0}/>
            </div>
            <div slot="body" style="width: 100%; height: 100%;">
                <LotMaterialForm/>
            </div>
        </FakAccordionItem>

        <!-- Positionsbemerkungen -->
        <FakAccordionItem id={1} closeOnHeaderClick={true}>
            <div slot="header" style="height: 3.875rem; width: 100%;">
                <FakAccordionItemHeader label={$t('UI.map.card.lot.remarks')} id={1}/>
            </div>
            <div slot="body" style="width: 100%; height: 100%;">
                <LotComments/>
            </div>
        </FakAccordionItem>

        {#if isNotNewLot}
            <!-- Positionsdateien -->
            <FakAccordionItem id={2} closeOnHeaderClick={true}>
                <div slot="header" style="height: 3.875rem; width: 100%;">
                    <FakAccordionItemHeader
                            label={hasAttachments ? $t('UI.map.card.lot.attachments') : $t('UI.map.card.lot.addAttachments')}
                            id={2}/>
                </div>
                <div slot="body" style="width: 100%; height: 100%;">
                    <LotFileUpload bind:hasAttachments/>
                </div>
            </FakAccordionItem>


            {#if $currentLot.state === LotState.BIDDING || OPTIMIZED_STATES.includes($currentLot.state)}
                <!-- Anfragedateien -->
                <FakAccordionItem id={3} closeOnHeaderClick={true}>
                    <div slot="header" style="height: 3.875rem; width: 100%;">
                        <FakAccordionItemHeader
                                label={$t('UI.map.card.quote.attachments')}
                                id={3}/>
                    </div>
                    <div slot="body" style="width: 100%; height: 100%;">
                        <QuoteAttachments/>
                    </div>
                </FakAccordionItem>
            {/if}

            <!-- Analytik -->
            <FakAccordionItem id={4} closeOnHeaderClick={true}>
                <div slot="header" style="height: 3.875rem; width: 100%;">
                    <FakAccordionItemHeader label={analyticString + (!hasAnalyticals ? " hinzufügen" : "")} id={4}/>
                </div>
                <div slot="body" style="width: 100%; display: flex; justify-content: center; align-items: center;">
                    <LotAnalyticalList bind:hasAnalyticals/>
                </div>
            </FakAccordionItem>


            <!-- Status -->
            <FakAccordionItem id={5} closeOnHeaderClick={true} openByDefault={true}>
                <div slot="header" style="height: 3.875rem; width: 100%;">
                    <FakAccordionItemHeader label={$t('UI.map.card.lot.status')} id={5}/>
                </div>
                <div slot="body" style="width: 100%; height: 100%;">
                    <LotStateChanger/>
                </div>
            </FakAccordionItem>

            <!--{#if $currentLot.state === LotState.BIDDING}
                &lt;!&ndash; Optimierer Management &ndash;&gt;
                <FakAccordionItem id={5} closeOnHeaderClick={true} openByDefault={true}>
                    <div slot="header" style="height: 3.875rem; width: 100%">
                        <FakAccordionItemHeader label={$t('UI.map.card.lot.optimizerManagement')} id={5}/>
                    </div>
                    <div slot="body" style="width: 100%; height: 100%;">
                        <LotOptimizerManagement/>
                    </div>
                </FakAccordionItem>
            {/if}-->
        {/if}
        {#if $currentLot && $currentLot.id === "new"}
            <SaveNewLotItem/>
        {/if}
    </NullableDataWrapper>
</ul>


<style lang="scss">

  .position-details-accordion {
    width: 450px;
    padding-left: 0;
    list-style: none;
  }

</style>