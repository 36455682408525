<script context="module" lang="ts">
    export enum ReactionButtons {
        POSITIVE = "positive",
        NEUTRAL = "neutral",
        NEGATIVE = "negative",
    }

</script>

<script lang="ts">
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import {createEventDispatcher} from "svelte";
    import Tooltip from "../atoms/Tooltip.svelte";
    import {TooltipPlacements} from "../types/enums";

    const dispatch = createEventDispatcher();

    export let label = "";
    export let reaction: ReactionButtons = ReactionButtons.POSITIVE;
    export let tooltipMsg = "";
    export let disabled = false;
    let color;
    $: color = getColorForReaction(reaction);

    function getColorForReaction(reaction) {
        if (reaction === ReactionButtons.POSITIVE) {
            return PrimaryBtnColors.GREEN;
        } else if (reaction === ReactionButtons.NEUTRAL) {
            return PrimaryBtnColors.ORANGE;
        } else if (reaction === ReactionButtons.NEGATIVE) {
            return PrimaryBtnColors.RED;
        }
    }

    function clickEvent() {
        if (disabled === false) {
            dispatch('reactionBtnClick');
        }
    }
</script>


<div class:positive-container={reaction === ReactionButtons.POSITIVE}
     class:negative-container={reaction === ReactionButtons.NEGATIVE}
     class:neutral-container={reaction === ReactionButtons.NEUTRAL} class:tt={tooltipMsg !== ""} class:disabled on:click|self={clickEvent}>
    {#if tooltipMsg !== ""}
        <div class="top-right">
            <Tooltip msg={tooltipMsg} placementTtip={TooltipPlacements.TOP_END}/>
        </div>
    {/if}
    <div class={"icon-" + reaction} on:click={clickEvent} class:disabled>
        <div></div>
    </div>
    <PrimaryButton {color} {label} {disabled} on:click={clickEvent}/>
</div>

<style lang="scss">
  @import "../../styles/global";
  @import "../../styles/icons";

  .positive-container, .negative-container, .neutral-container {
    border-radius: 4px;
    height: 188px;
    @include flex-col($justify: flex-start);
    padding: 2.188em 2.75rem;

    &:hover {
      cursor: pointer;
    }
  }

  .disabled {
    &:hover {
      cursor: default;
      filter: none !important;
      -webkit-filter: none !important;
    }
  }

  .tt {
    padding: 0.625rem 2.75rem;
  }

  .positive-container {
    background-color: $primaryGreenBackground;
  }

  .negative-container {
    background-color: $primaryRedBackground;
  }

  .neutral-container {
    background-color: $primaryOrangeBackground;
  }


  .top-right {
    align-self: flex-end;
    right: -2rem;
    position: relative;
    padding-bottom: 10px;
  }

  .icon-positive, .icon-neutral, .icon-negative {
    max-height: 56px;
    min-height: 56px;
    max-width: 56px;
    min-width: 56px;
    border-radius: 50%;
    @include flex-col();
    margin-bottom: 1.875rem;
    padding: 0.75rem;
    transition: filter 0.5s;

    &:hover {
      filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.75));
      -webkit-filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.75));
    }


    & > div {
      height: 32px;
      width: 32px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .icon-positive {
    background-color: $primaryGreen;

    & > div {
      //margin-top: 0.33rem;
      background-image: url("@/icons/icon_reaction_success.svg");
    }
  }

  .icon-neutral {
    background-color: $primaryOrange;

    & > div {
      background-image: url("@/icons/icon_edit.svg");
      filter: invert(100%); /* as the icon is black we need to invert the color */
    }
  }

  .icon-negative {
    background-color: $primaryRed;

    & > div {
      background-image: url("@/icons/icon_reaction_cancel.svg");
    }
  }

</style>