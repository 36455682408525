import {translate} from "../../i18n/i18n";
import {Salutation} from "./enums";
import {mixed, object, string} from "yup";
import {hashString} from "../utils/utils";

export type PersonData = {
    id: string,
    salutation: Salutation,
    title: string,
    firstName: string,
    lastName: string,
    email?: string,
    phone?: string,
    text: string,
}

export function DefaultPersonData() {
    let personData: PersonData = {
        id: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        salutation: Salutation.MADAM,
        title: "",
        text: ""
    };
    return personData;
}

export function copyPersonData(personData, regenerateId = false) {
    if (!personData) return null;
    let copy = DefaultPersonData();
    Object.assign(copy, personData);
    if (regenerateId) {
        copy.id = generatePersonDataId(copy);
    }
    return copy;
}

export function generatePersonDataId(personData: PersonData) {
    let str = personData.email + personData.firstName + personData.lastName + personData.phone + personData.salutation
        + personData.title;
    return hashString(str);
}


export function equalsPersonData(p1: PersonData, p2: PersonData) {
    return p1.salutation === p2.salutation
        && p1.title === p2.title
        && p1.firstName === p2.firstName
        && p1.lastName === p2.lastName
        && p1.email === p2.email
        && p1.phone === p2.phone
        && p1.text === p2.text;
}

export function getPersonDataAutocompleteText(personData: PersonData, withBrackets: boolean = true) {
    let s = `${personData.firstName} ${personData.lastName}`
    if (personData.salutation !== null) {
        let translatedSalutation = translate('de', 'Salutation.' + personData.salutation, []);
        s = `${translatedSalutation} ` + s;
    }
    if (withBrackets) {
        return '(' + s + ')';
    }
    return s;
}

export const personSchema = object({
    // id: string().notRequired(), not needed for server
    salutation: mixed().required(translate('de', 'UI.validation.person.salutation.required', []))
        .oneOf(Object.values(Salutation), ({value}) => translate('de', 'UI.validation.person.salutation.oneOf', [{value: value}])),
    title: string().notRequired(),
    firstName: string().notRequired(),
    lastName: string().required(translate('de', 'UI.validation.person.lastname.required', [])),
    email: string().required()
        .email(translate('de', 'UI.validation.personData.email.format', [])),
    phone: string().required()
        .matches(/(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/, translate('de', 'UI.validation.personData.phone.format', [])), // regex https://stackoverflow.com/a/53854183
    // text: string().notRequired() not needed for server
})
