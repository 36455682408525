<script lang="ts">
    import icon403 from "@/images/403.png";

    export let description: string = "";
</script>


<div class="blur-background-overlay">
    <div class="blur-background-white"></div>
    <div class="blur-background-overlay-content">
        <img alt="401 Error - No authorization" src={icon403}>
        <span class="error-hint">{@html description}</span>
    </div>
</div>


<style lang="scss">
  @import "../../styles/animations";
  .blur-background-overlay {
    z-index: 10000;
  }
</style>
