<script lang="ts">

    import FormattedHeaderValue, {inputTypes} from "../atoms/FormattedHeaderValue.svelte";
    import {t} from "../../i18n/i18n"
    import FormattedTimeframe from "../atoms/FormattedTimeframe.svelte";
    import {type AddressData, DefaultAddressData} from "../types/addressData";

    export let title: string = "";
    export let titleGreen: boolean = false;
    export let anonymizeAddress: boolean = true;
    export let address: AddressData = DefaultAddressData();
    export let startDate: string = "";
    export let endDate: string = "";
    export let vehicleCategories: string = "";
    export let dailyCapacity: string | number = "";
    let addressString: string = "-";
    $: {
        if (address) {
            if (!anonymizeAddress && address.street && address.street !== "-") {
                addressString = address.street + ", ";
                addressString += address.zip + " " + address.city;
            } else {
                addressString = address.zip + " " + address.city;
            }
        }
    }
</script>

<div class="project-information">
    <span class:titleGreen>{title}</span>
    <div class="project-information-grid">
        <FormattedHeaderValue title={$t("UI.lot.address.label")} value={addressString || "-"} type={inputTypes.OTHER}/>
        <FormattedTimeframe title={$t("UI.timePeriod.label")} {startDate} {endDate}/>
        <FormattedHeaderValue title={$t("UI.lot.vehicleCategories.label")}
                              value={vehicleCategories ? vehicleCategories:$t('UI.counterOffer.vehicleCategories.is.Unlimited') }
                              type={inputTypes.OTHER}/>
        <FormattedHeaderValue title={$t("UI.lot.dailyCapacity.label")} value={dailyCapacity} type={inputTypes.TONNAGE}
                              unit={$t("UI.unit.ton.short")}/>
    </div>
</div>

<style lang="scss">
  @import "../../styles/global";

  .project-information {
    @include flex-col(0.875rem, $alignment: flex-start);
    width: 100%;

    & > span {
      @include roboto-font(15px, 500, 1rem, black);
    }
  }

  .titleGreen {
    color: $primaryColor !important;
  }

  .project-information-grid {
    //width: max-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1.125rem;
    grid-column-gap: 5rem;
    grid-auto-rows: max-content;
    width: 100%;
  }

</style>