<script lang="ts">
    import Card from "../atoms/Card.svelte"
    import collapse from 'svelte-collapse'
    import Icon from "../atoms/Icon.svelte";
    import iconExpandable from "@/icons/icon_expandable_opened.svg";

    export let width = "100%";
    export let collapseHeader = false;
    export let openByDefault = false;

    export let open = openByDefault;

    $: open = params.open;


    let params = {
        open: openByDefault,
        duration: 0.2,
        easing: 'ease'
    }

    let headerParams = {
        open: !openByDefault || !collapseHeader,
        duration: 0.2,
        easing: 'ease'
    }

    export const handleHeaderClick = () => {
        params.open = !params.open;
        if (collapseHeader) {
            headerParams.open = !headerParams.open;
        }
    }

</script>

<Card {width}>
    <div style="min-height: 52px">
        <div class="arrow-handle" style="transform: rotate({params.open ? '-180deg' : '0'});"
             on:click={handleHeaderClick}>
            <Icon src={iconExpandable} size={32} clickable/>
        </div>

        <div class="header" use:collapse={headerParams} on:click={handleHeaderClick}>
            <slot name="header"/>
        </div>

        <div class="body" use:collapse={params}>
            <slot name="body"/>
        </div>

    </div>
</Card>

<style>
    .header {
        width: 100%;
        overflow: hidden;
        cursor: pointer;
    }

    .body {
        width: 100%;
    }

    .arrow-handle {
        position: absolute;
        top: 10px;
        right: 10px;
        width: fit-content;
        transition: transform .4s ease-out;
    }

</style>
