<script lang="ts">
    import {beforeUpdate} from "svelte";
    import ProgressBarIcon from "./ProgressBarIcon.svelte";

    // The list of states that we want to switch, e.g. WhitelistView or LotStates
    export let states: any[] = [];
    // The currently active state. Type is inferred from first element of the states list.
    export let actualState: typeof states[0];
    // Height of the bar in rem
    export let barHeight: number = 0.875;
    // Distance between icons in rem
    export let iconDistance: number = 3;
    // Icon width in rem
    export let iconWidth: number = 1.25;

    let maxState: number = states.length;
    let stateBefore: typeof actualState;
    $: isMaxState = states.indexOf(actualState) === maxState - 1;

    let duration: string = getDuration() + 's';

    beforeUpdate(() => {
        duration = getDuration() + 's';
        stateBefore = actualState;
        calcBarWidth();
    })

    // this function calculates the duration of the transition as a function of the ordinal size of the state before it
    function getDuration() {
        return 1.4 ** Math.abs((states.indexOf(actualState) - states.indexOf(stateBefore)) / maxState)
    }

    let barWidth; // calculated depending on the actual state and the length of the states (maxState)
    function calcBarWidth() {
        // the width of the bar, if the icons had 0 or 1 length
        let widthInPercent = (states.indexOf(actualState) / (maxState - 1)) * 100;
        // for the first icon, add the complete width, for the icon in the middle only add half of the icon width etc.
        let widthToCoverIcon = ((100 - widthInPercent) / 100) * iconWidth;
        barWidth = `calc(${widthInPercent}% + ${widthToCoverIcon}rem)`
    }

</script>

<div class="svelte-progress-bar">
    <div class="progress-line-inactive"
         style="--barHeight: {barHeight + 'rem'}; --borderRadius: {barHeight/2 + 'rem'}"/>
    <div class="progress-line-active"
         style="--barWidth: {barWidth};
                     --barHeight: {barHeight + 'rem'};
                     --marginRight: {'-' + barHeight + 'rem'};
                     --borderRadius: {barHeight/2 + 'rem'};
                     --duration: {duration}"
    />
    <div class="progress-bar-icons" style="--iconDistance: {iconDistance + 'rem'}">
        <!--This slot renders by default the ProgressBarIcons.
        However, this can be easily changed and other icons can be shown instead with this slot.-->
        <slot {iconWidth}>
            <!-- this way we can use the iconWidth with let from the main component when showing other icons
         and only need to set it once for the ProgressBar-->
            {#each {length: states.length} as _, i}
                {#if states.indexOf(actualState) === i}
                    <ProgressBarIcon actualPosition={states.indexOf(actualState)} ordinalValue={i}
                                     progressLineIconType={"selected"} {iconWidth}
                                     animate/>
                {/if}
                {#if i > states.indexOf(actualState)}
                    <ProgressBarIcon actualPosition={states.indexOf(actualState)} ordinalValue={i}
                                     progressLineIconType={"inactive"} {iconWidth}
                                     animate/>
                {/if}
                {#if i < states.indexOf(actualState)}
                    <ProgressBarIcon actualPosition={states.indexOf(actualState)} ordinalValue={i}
                                     progressLineIconType={"active"} {iconWidth}
                                     animate={false}/>
                {/if}
            {/each}
        </slot>
    </div>
</div>


<style lang="scss">
  @import "../../styles/global";

  .svelte-progress-bar {
    width: 100%;
    //min-width: 600px;

    // misuse grid to center align all elements in the div above each other in the same row and col
    // https://stackoverflow.com/a/51949049
    display: grid;
    align-items: center;

    & > * {
      grid-column: 1;
      grid-row: 1;
    }
  }

  // background grey line
  .progress-line-inactive {
    width: 100%;
    height: var(--barHeight);
    background-color: #E9E9E9;
    border-radius: var(--borderRadius);
    z-index: 0;
  }

  // actively moving line
  .progress-line-active {
    width: var(--barWidth); // add 40px such that the progress bar is overlapping the icon a little bit
    height: var(--barHeight);
    background-color: #4A609F;
    border-radius: var(--borderRadius);
    transition: var(--duration) ease-in-out;
    z-index: 1;
  }


  // container of the icons, icons need to be inserted with 'svelte:fragment' into the slot
  .progress-bar-icons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    column-gap: var(--iconDistance);
    z-index: 2;
  }
</style>
