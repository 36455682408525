<script lang="ts">

    import type {Catalog} from "./types/catalog";
    import {closeModal, openModal} from "svelte-modals";
    import MaterialCatalogModal from "./MaterialCatalogModal.svelte";
    import {createEventDispatcher, getContext} from "svelte";
    import {t} from "../../i18n/i18n";
    import ActionMenu from "../organisms/ActionMenu.svelte";
    import ActionMenuItem from "../molecules/ActionMenuItem.svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import type {Notification} from "../types/notification";
    import {DefaultNotification, NotificationType} from "../types/notification";
    import {addNotification} from "../stores";
    import Modal from "../organisms/Modal.svelte";
    import type {Filter} from "../utils/filter";
    import {applyFilters} from "../utils/filter";
    import type {Writable} from "svelte/store";
    import {DefaultCatalog} from "./types/catalog";
    import iconEditGreen from "@/icons/icon_edit_green.svg";
    import iconTrashRed from "@/icons/icon_trash_red.svg"

    const dispatch = createEventDispatcher();

    export let catalogs = [];
    export let updateCatalogs = () => {}; // fetchCatalogs method from MaterialCatalogPageManager
    let selectedCatalog: Writable<Catalog> = getContext('selectedCatalog');
    export let filters: Filter[] = [];

    function selectCatalog(catalog: Catalog) {
        $selectedCatalog = catalog;
        dispatch('catalogSelected'); // to change the view in the parent
    }

    function editCatalog(c: Catalog) {
        openModal(MaterialCatalogModal,
            {
                update: updateCatalogs,
                catalog: {...DefaultCatalog(), ...c}
            }
        )
    }

    function handleDeleteCatalog(catalog: Catalog) {
        openModal(Modal, {
            title: $t('UI.mmpv.delete.modal.title', {entity: catalog.name}),
            message: $t('UI.mmpv.delete.catalog.modal.message', {catalog: catalog.name}) ,
            denyText: $t('UI.delete'),
            onDeny: () =>{
                deleteCatalog(catalog);
                closeModal();
            }
        })
    }

    function deleteCatalog(c: Catalog) {
        fetchUtils.post(`/api/catalog/${c.id}/delete`, c)
            .then(_ => {
                let notification: Notification = DefaultNotification('Katalog erfolgreich entfernt.', NotificationType.SUCCESS, true);
                addNotification(notification);
                updateCatalogs;
                const idx = catalogs.indexOf(c);
                if (idx > -1) {
                    catalogs.splice(idx, 1);
                    catalogs = catalogs;
                }
            })
            .catch(fetchUtils.catchErrorAndShowNotification())
    }

</script>

<div class="catalogs-container">
    <div class="catalog-table">
        <div class="row-wrapper">
            <div></div>
            <div>{$t('UI.mmpv.catalogs.name')}</div>
            <!--<div>{$t('UI.mmpv.catalogs.category')}</div>-->
            <div>{$t('UI.mmpv.catalogs.comment')}</div>
            <div>{$t('UI.mmpv.catalogs.maxDepth')}</div>
            <div>{$t('UI.mmpv.catalogs.nodeCount')}</div>
            <div>{$t('UI.mmpv.catalogs.action')}</div>
            <div></div>
        </div>
        {#each catalogs.filter(c => applyFilters(filters, c)) as catalog (catalog.id)} <!--(id) means that they are keyed by id -->
            <div class="row-wrapper">
                <div></div>
                <div on:click={() => selectCatalog(catalog)}>{catalog.name || ''}</div>
                <!--<div>{catalog.category || ''}</div>-->
                <div on:click={() => selectCatalog(catalog)}>{catalog.comment || ''}</div>
                <div on:click={() => selectCatalog(catalog)}>{catalog.maxDepth || ''}</div>
                <div on:click={() => selectCatalog(catalog)}>{catalog.nodes.length || 0}</div>
                <div style="position: relative">
                    <ActionMenu titleAttr={$t('UI.mmpv.actionMenu.titleAttribute')}>
                        <svelte:fragment slot="content">
                            <ActionMenuItem label={$t('UI.edit')} iconSrc={iconEditGreen}
                                            on:click={() => editCatalog(catalog)}/>
                            <ActionMenuItem label={$t('UI.delete')} iconSrc={iconTrashRed}
                                            on:click={() => handleDeleteCatalog(catalog)}/>
                        </svelte:fragment>
                    </ActionMenu>
                </div>
                <div></div>
            </div>
        {/each}
    </div>
</div>


<style lang="scss">
  @import "../../styles/global";

  .catalogs-container {
    @include flex-col($justify: flex-start);
    width: 100%;
  }

  .catalog-table {
    display: grid;
    grid-template-columns: 68px 1fr 2fr repeat(3, auto) 68px; // Adjust this when category is used again.
    grid-template-rows: 1fr; /*remove for unequal rows */
    @include roboto-font(18px, 400, 0.75rem, black);
    width: 100%;

    & .row-wrapper {
      display: contents;

      & > div {
        text-align: start;
        border-bottom: 1px solid $grey-600;
        padding: 0.75rem 0.5rem;

        &:nth-last-child(2) {
          @include flex-row($justify: center);
        }

        &:hover {
          cursor: pointer;
        }
      }

      &:first-child > div {
        border-bottom: 1px solid $primaryGreen;
        @include roboto-font(18px, 400, 1rem, black)
      }
    }
  }

  .action-btn {
    background: url("@/icons/icon_3dot_menu.svg") no-repeat;
    width: 20px;
    height: 20px;
    background-size: contain;
  }

</style>
