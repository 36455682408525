<script lang="ts">
    import {jwt} from "../utils/jwt";
    import {t} from "../../i18n/i18n";

    export let iFrameUrl: string | null = null; // debug info for selenide tests/screenshots

    let actualYear = new Date().getFullYear();
</script>


<footer>
    <span>
        © {actualYear} Mineral Minds Deutschland GmbH
        &bull; <a href="tel:07151250100">07151/250100</a>
        &bull; <a href="mailto:info@mineral-minds.de">info@mineral-minds.de</a>
        &bull; <a href="https://mineral-minds.de/impressum">{$t('Imprint')}</a>
        &bull; <a href="/agb">{$t('TermsAndConditions')}</a>
        &bull; <a href="https://mineral-minds.de/datenschutzerklaerung">{$t('Privacy')}</a>
    </span>
    <span>
        {#if $jwt}
            {#if $jwt.spyuname && $jwt.tname}
                <span style="font-weight: 500;">{$jwt.spyuname}</span> ({$jwt.tname}) |
            {/if}
            {#if $jwt.productinfo && $jwt.nodename}
                <span title={$jwt.productinfo}>{$jwt.nodename}</span>
            {/if}
            {#if $jwt.isProductionVersion !== undefined && !$jwt.isProductionVersion}
                | <span>URL of iFrame: {iFrameUrl ? iFrameUrl : "No iFrame found"}</span>
            {/if}
        {/if}
    </span>
</footer>


<style lang="scss">
  @import "../../styles/global";

  footer {
    @include flex-row($justify: space-between);
    flex-wrap: wrap;
    width: 100%;
    height: 3.125rem;
    position: sticky;
    background: #FFF;
    bottom: 0;
    padding-left: 1.87rem;
    padding-right: 1.87rem;
    z-index: 2;

    & > span {
      @include roboto-font(normal, 400, 0.75rem);

      & > a {
        color: $primaryColor;
      }
    }
  }
</style>
