<script lang="ts">
    import {ButtonIcons, InputIcons} from "../types/enums";
    import SecondaryButton from "../atoms/SecondaryButton.svelte";
    import {inputFieldFormats} from "../utils/formatters";
    import type {FontProps} from "../types/fontProps";
    import {DefaultRobotoFontProps} from "../types/fontProps";
    import InputField from "../molecules/InputField.svelte";
    import {setContext, tick} from "svelte";
    import {createCol} from "../types/headlessTable";
    import type {Writable} from "svelte/store";
    import {writable} from "svelte/store";
    import {fetchUtils} from "../utils/fetchUtils";
    import type {IcData, InstallationCase} from "../types/installationCase";
    import {
        BaseMaterialResult,
        EmptyInstallationCase,
        InstallationCaseMaterials,
        InstallationCaseTechnical,
        InstallationCaseTrain,
        SurfaceLayer,
        SurfaceLayerMaterial,
        WaterProtection,
        WaterProtectionArea,
    } from "../types/installationCase";
    import type {AutocompleteFieldConfig} from "../types/autocompleteFieldConfig";
    import {AutocompleteConfig} from "../types/autocompleteFieldConfig";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import ChipList from "../organisms/ChipList.svelte";
    import {t} from "../../i18n/i18n";
    import HeadlessTable from "../organisms/HeadlessTable.svelte";
    import {createRender} from "svelte-headless-table";
    import IcCategorisationClassCell from "./IcCategorisationClassCell.svelte";
    import HeadlessTooltipCell from "../headlessTableCells/HeadlessTooltipCell.svelte";
    import iconParagraph from "@/icons/icon_paragraph.svg";
    import Basic from "../templates/Basic.svelte";

    setContext('useMaterialDesign', true);

    const generateInstallationCaseOptions = (source: any) => {
        return Object.values(source).map(value => ({label: $t(`InstallationCase.${value}`), value}));
    }

    const installationCaseTechnicalOptions = generateInstallationCaseOptions(InstallationCaseTechnical);
    const installationCaseTrainOptions = generateInstallationCaseOptions(InstallationCaseTrain);
    const installationCasesOptions = [
        {
            groupHeader: $t('InstallationCase.InstallationCaseTechnical.ph'),
            installationCases: installationCaseTechnicalOptions
        },
        {
            groupHeader: $t('InstallationCase.InstallationCaseTrain.ph'),
            installationCases: installationCaseTrainOptions
        }
    ]
    const waterProtectionAreaOptions = generateInstallationCaseOptions(WaterProtectionArea);
    const waterProtectionOptions = generateInstallationCaseOptions(WaterProtection);
    const surfaceLayerOptions = generateInstallationCaseOptions(SurfaceLayer);
    const surfaceLayerMaterialOptions = generateInstallationCaseOptions(SurfaceLayerMaterial);
    const installationCaseMaterialsOptions = generateInstallationCaseOptions(InstallationCaseMaterials);

    let installationCase: InstallationCase = EmptyInstallationCase();
    const filterFontProps: FontProps = DefaultRobotoFontProps('normal', '0.75rem', '#d9d9d9');
    let installationCasesConf: AutocompleteFieldConfig = AutocompleteConfig();
    installationCasesConf.groupLabelField = 'groupHeader';
    installationCasesConf.groupItemsField = 'installationCases';
    const waterProtectionAreaConf: AutocompleteFieldConfig = AutocompleteConfig();
    const waterProtectionConf: AutocompleteFieldConfig = AutocompleteConfig();
    const surfaceLayerConf: AutocompleteFieldConfig = AutocompleteConfig();
    const surfaceLayerMaterialConf: AutocompleteFieldConfig = AutocompleteConfig();
    let installationCaseMaterialsConf: AutocompleteFieldConfig = AutocompleteConfig('value', 'label', true);
    let isInstallationCaseTrain: boolean = false;

    const tableData: Writable<any[]> = setContext('table-data', writable([]));
    const colData: Writable<any[]> = setContext('table-cols', writable([]));
    const CategorisationCell = ({row}) => createRender(IcCategorisationClassCell, {
        classes: row.original.categorisationClasses
    })
    const FootnoteCell = ({row}) => createRender(HeadlessTooltipCell, {
        iconSrc: iconParagraph,
        iconSize: "24px",
        msg: row.original.footnote
    })
    $colData.push(createCol($t('InstallationCase.table.material.header'), 'materialName'));
    $colData.push(createCol($t('InstallationCase.table.categorisationClasses.header'), 'categorisationClasses', {
        plugins: {
            sort: {disable: true},
            filter: {exclude: true}
        },
        cell: CategorisationCell,
    }));
    $colData.push(createCol('', 'footnote', {
            plugins: {
                sort: {disable: true},
                filter: {exclude: true}
            },
            cell: FootnoteCell
        }
    ));


    const redirectToProjectsPage = () => {
        window.parent.location.href = `/projects`;
    }

    /**
     * This function builds a categorisation class string based on the given material key and categorisation class key.
     *
     * @param {string} materialKey - The key representing the material to which the categorisation class belongs.
     * @param {string|BaseMaterialResult} categorisationClassKey - The key representing the categorisation class.
     *
     * @return {string} The built categorisation class string.
     */
    function buildCategorisationClass(materialKey: string, categorisationClassKey: string | BaseMaterialResult) {
        switch (categorisationClassKey) {
            case BaseMaterialResult.NOT_APPLICABLE:
            case  BaseMaterialResult.NOT_SATISFIED:
            case BaseMaterialResult.NOT_RELEVANT:
                return $t('InstallationCase.BaseMaterialResult.' + categorisationClassKey)
            default:
                return $t('InstallationCase.' + materialKey + '.' + categorisationClassKey);
        }
    }

    /**
     * Processes the installation cases and returns an array of formatted data.
     * Material name is simply kept, categorisation classes are formatted (the first one is highlighted) and Footnotes are joined together in one text.
     *
     * @param {any[]} results - The array of installation case results.
     * @return {IcData[]} - The array of formatted installation case data.
     */
    function processInstallationCases(results: any[]): IcData[] {
        let tD: IcData[] = [...results];
        let tableData: any[] = [];
        tD.forEach(data => {
            const materialName: string = $t('InstallationCase.InstallationCaseMaterial.' + data.materialName);
            let ccHtml = data.resultList.map(res => buildCategorisationClass(data.materialName, res.categorisationClass));
            let footnoteHtml = data.resultList.filter(res => res.footnote && res.footnote !== "").map(res => `<b>${buildCategorisationClass(data.materialName, res.categorisationClass)}:</b><br/>${res.footnote}<br/><br/>`).join('').replace(/\n/g, "<br/>");
            tableData.push({materialName: materialName, categorisationClasses: ccHtml, footnote: footnoteHtml});
        });
        return tableData;
    }

    /**
     * Updates the table data by sending a POST request to the server API.
     *
     * @returns {Promise} A promise that resolves with the updated table data.
     */
    function updateTableData() {
        const UPDATE_URL = "/api/installationCases";
        let propertyList = [...Object.values(installationCase)]
            .filter(value => value !== null && value !== undefined)
            .filter(value => !Array.isArray(value) || Array.isArray(value) && value.length > 0);
        isInstallationCaseTrain = installationCase.installationCase?.includes('InstallationCaseTrain') ?? false; // if no installationCase is defined, default to false
        if (propertyList.length > 0) {
            let payload = {propertyList, filterList: selectedMaterialValues, isInstallationCaseTrain}
            fetchUtils.post(UPDATE_URL, payload)
                .then(processInstallationCases)
                .then(data => $tableData = data)
                .catch(fetchUtils.catchErrorAndShowNotification());
        } else {
            $tableData = [];
        }
    }


    let selectedMaterials = null;
    let selectedMaterialValues = null;
    let chipList = [];
    const updateChipList = () => {
        chipList = selectedMaterials !== null && Array.isArray(selectedMaterials) ? selectedMaterials.map(material => material.label) : [];
        updateTableData();
    }
    const updateSelectedMaterials = async () => {
        // we need to do that, because of the quirks of the autocomplete input field
        let rememberChipList = [...chipList];
        selectedMaterials = null;
        selectedMaterialValues = null;
        await tick();
        selectedMaterialValues = [...installationCaseMaterialsOptions.filter(material => rememberChipList.includes(material.label)).map(obj => obj.value)]
        await tick();
        updateTableData();
    }

    let searchTerm: string = "";
</script>

<Basic>
    <div class="installation-cases-main-container">
        <h1 class="site-header">EBV Einbauweisen</h1>
        <div class="search text-right">
            <InputField bind:value={searchTerm} placeholder={$t('UI.installationCases.search.ph')}
                        format={inputFieldFormats.FULL} containerWidth="calc(33.33333% - 0.703rem)"
                        fontProps={DefaultRobotoFontProps('0.875rem', '0.75rem')}
                        icon={InputIcons.SEARCH_LENS} useMaterialDesign={false}/>
        </div>
        <div class="filters-container">
            <div>
                <div>
                    <AutocompleteInputField bind:value={installationCase.installationCase} fontProps={filterFontProps}
                                            autocompleteConfig={installationCasesConf}
                                            label={$t('InstallationCase.InstallationCaseMaterials.ph')}
                                            options={installationCasesOptions} hideDropdownArrow={false}
                                            on:change={updateTableData}/>
                </div>
                <div>
                    <AutocompleteInputField bind:value={installationCase.waterProtectionArea}
                                            fontProps={filterFontProps} label={$t('InstallationCase.WaterProtectionArea.ph')}
                                            autocompleteConfig={waterProtectionAreaConf}
                                            options={waterProtectionAreaOptions} hideDropdownArrow={false}
                                            on:change={updateTableData}/>
                </div>
                <div>
                    <AutocompleteInputField bind:value={installationCase.surfaceLayer} fontProps={filterFontProps}
                                            autocompleteConfig={surfaceLayerConf}
                                            label={$t('InstallationCase.SurfaceLayer.ph')}
                                            options={surfaceLayerOptions} hideDropdownArrow={false}
                                            on:change={updateTableData}/>
                </div>
                <div>
                    <AutocompleteInputField bind:value={installationCase.surfaceLayerMaterial}
                                            fontProps={filterFontProps} label={$t('InstallationCase.SurfaceLayerMaterial.ph')}
                                            autocompleteConfig={surfaceLayerMaterialConf}
                                            options={surfaceLayerMaterialOptions} hideDropdownArrow={false}
                                            on:change={updateTableData}/>
                </div>
                <div>
                    <AutocompleteInputField bind:value={installationCase.waterProtection} fontProps={filterFontProps}
                                            autocompleteConfig={waterProtectionConf}
                                            label={$t('InstallationCase.WaterProtection.ph')}
                                            options={waterProtectionOptions} hideDropdownArrow={false}
                                            on:change={updateTableData}/>
                </div>
            </div>
            <div>
                <span>{$t("UI.filters")}</span>
                <div class="col-12 col-lg-4">
                    <AutocompleteInputField bind:value={selectedMaterialValues} bind:selectedObject={selectedMaterials}
                                            fontProps={filterFontProps} label={$t('InstallationCase.MEB.ph')}
                                            options={installationCaseMaterialsOptions}
                                            autocompleteConfig={installationCaseMaterialsConf} hideDropdownArrow={false}
                                            on:change={updateChipList}/>
                </div>
                {#if chipList.length > 0}
                    <ChipList bind:list={chipList} fontProps={filterFontProps}
                              chipFontProps={DefaultRobotoFontProps('0.625rem', '0.875rem', undefined, 'black')}
                              showInput={false}
                              on:chipRemoved={updateSelectedMaterials}/>
                {/if}
            </div>
        </div>
        <div style="grid-area: table; padding-top: 2.5rem;">
            <span class="table-title">Ergebnis</span>
            <div class="installation-cases-table" class:emptyTable={$tableData.length === 0}>
                <div>
                    <HeadlessTable {searchTerm} emptyMessage={$t('InstallationCase.table.emptyState')}>
                    </HeadlessTable>
                </div>
            </div>
        </div>
        <div class="buttons row">
            <div class="col-4">
                <SecondaryButton label={$t("UI.backToProjectList.button")} leftIcon={ButtonIcons.LEFT_ARROW}
                                 iconSize="24px" on:click={redirectToProjectsPage}/>
            </div>
        </div>
    </div>
</Basic>

<style lang="scss">
  @import "../../styles/global";

  .installation-cases-main-container {
    display: grid;
    grid-template-areas: "header"
                         "filter"
                         "table"
                         "buttons";
    row-gap: 1.5rem;

    @media screen and (min-width: 990px) {
      grid-template-areas: "header header"
                            "search search"
                         "filter filter"
                         "table table"
                         "buttons buttons";
    }
  }

  .site-header {
    grid-area: header;
  }

  .search {
    grid-area: search;
    @include flex-row($justify: flex-end);
  }

  .filters-container {
    grid-area: filter;
    @include flex-col(1.875rem, $alignment: flex-start);

    & > div:first-child {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr;
      grid-gap: 0.938rem 0.938rem;
      margin-top: 1rem;

      @media screen and (min-width: 990px) {
        grid-template-columns: repeat(3, minmax(calc((100% / 3) - 1.25rem), 1fr)) !important;
      }
      @media screen and (min-width: 720px) {
        grid-template-columns: 1fr 1fr;
        & > div {
          &:first-child {
            grid-column: span 2;
          }

          &:not(:first-child) {
            grid-column: span 1;
          }
        }
      }
    }

    & > div:not(:first-child) {
      @include flex-col(1rem, $alignment: flex-start);
      width: 100%;

      & > span {
        @include roboto-font(normal, 400, 1rem, black);
      }
    }
  }

  .table-title {
    justify-self: start;
    @include roboto-font(normal, 400, 1.25rem, black)
  }

  .installation-cases-table:not(.emptyTable) {
    padding-right: 1.5rem;
  }

  .installation-cases-table {
    max-height: 450px;
    overflow-y: auto;
    margin-top: 1.25rem;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #F0F2F8;
      border-radius: 4px;
      margin-top: 16px;
      margin-bottom: 16px;
      margin-right: 12px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #58C079;
      border-radius: 4px;
    }


  }

  .buttons {
    grid-area: buttons;
    margin-top: 2.25rem;
  }


  /* Table styles*/
  .installation-cases-table {
    & :global(.headless-table-header-cell-materialName), & :global(.headless-table-cell-materialName) {
      text-align: start;
      padding-left: 3rem;
      padding-right: 1rem;
      width: 300px;
      min-width: 250px;
    }

    & :global(.headless-table-header-cell-categorisationClasses) {
      text-align: start;
    }
  }

</style>
