<script lang="ts">
    import type {Offer} from "./types/offer";
    import {type RequestDto, RequestType} from "./types/requestDto";
    import Card from "../atoms/Card.svelte";
    import FormattedHeaderValue, {inputTypes} from "../atoms/FormattedHeaderValue.svelte";
    import {t} from "../../i18n/i18n";
    import {formatNumber} from "../utils/formatters.js";
    import {getContext, hasContext} from "svelte";
    import {writable, type Writable} from "svelte/store";
    import TextArea from "../atoms/TextArea.svelte";
    import {isEmptyOffer} from "./offerUtils.js";
    import type {Lot} from "../types/lot";

    const showKickback: Writable<boolean> = hasContext("showKickback") ? getContext("showKickback") : writable(false);
    const request: Writable<RequestDto> = getContext("request"); // todo qr hint: on the offerer page, request is not given at the moment, but we only need it to check the volume, which is also in the lot
    const lot: Writable<Lot> = getContext("lot");

    export let offer: Offer;
    export let requestType: RequestType;
    // we don't need to set it, if it can be shown depending on the type, but if we DONT want to show it (e.g. Saas) we can set it to false

    let priceExclKickback: number;
    let isOutdated: boolean = false;

    function computeKickbackForOffer() {
        let kickback = offer?.facts?.kickback || $request?.facts?.kickback || 0;
        let kickbackPercent = offer?.facts?.kickbackInPercent || $request?.facts?.kickbackInPercent || 0;
        if (offer?.facts?.price && kickback && kickbackPercent) {
            const kickbackOfActualPrice = Math.round(offer?.facts?.price * (kickbackPercent)) / 100;
            offer.facts.kickback = kickbackOfActualPrice < kickback ? kickback : kickbackOfActualPrice;
        }
        priceExclKickback = Math.round(((offer?.facts?.price ?? 0) - (offer?.facts?.kickback ?? 0)) * 100) / 100;
        priceExclKickback = priceExclKickback < 0 ? 0 : priceExclKickback;
    }

    $: if ($showKickback && offer?.facts) {
        computeKickbackForOffer();
    }
    $: isOutdated = (offer?.facts?.volume ?? 0) > ($request?.facts?.volume ?? $lot.volume ?? 0);
</script>

<div class="{requestType.toLowerCase()} offer-facts-info__data">
    <div class="offer-facts-info__facts">
        <Card borderRadius="0.625rem" boxShadow="" height="100%" padding="1.25rem">
            <div class="offer-info-card-data">
                <FormattedHeaderValue {isOutdated}
                                      title={$t('UI.lot.volume.label')}
                                      value={offer?.facts?.volume || "-"}
                                      unit={$t('UI.unit.ton.short.small')} type={inputTypes.TONNAGE}
                                      tooltipMessage={$t('UI.tooltip.optimizer.outdated.quote')}/>
                <FormattedHeaderValue title={$t('Offer.dailyVolume')}
                                      value={offer?.facts?.dailyCapacity || '-'}
                                      unit={$t('UI.unit.ton.short.small')} type={inputTypes.TONNAGE}/>
                <FormattedHeaderValue title={$t('UI.counterOffer.vehicleCategory.label')}
                                      value={!isEmptyOffer(offer, requestType) ? offer?.facts?.vehiclesAsString : "-"}/>
                <div class="offer__dates">
                    <FormattedHeaderValue title={$t('UI.startDate.label')}
                                          value={offer?.facts?.startAsFormattedDate || "-"}/>
                    <FormattedHeaderValue title={$t('UI.endDate.label')}
                                          value={offer?.facts?.endAsFormattedDate || "-"}/>
                </div>
            </div>
        </Card>
    </div>
    <div class="offer-facts-info__price">
        <Card borderRadius="0.625rem" boxShadow="" height="100%" padding="1.25rem">
            <div class="offer-info-card-data" class:hideKickback={!$showKickback}>
                {#if $showKickback}
                    <FormattedHeaderValue title={$t('UI.offer.priceExclKickback')}
                                          value={priceExclKickback ? formatNumber(priceExclKickback.toFixed(2)) : "-"}
                                          unit={$t("UI.unit.eurPerTon.short.small")}/>
                    <FormattedHeaderValue title={$t("Model.kickback")}
                                          value={offer?.facts?.kickbackFormattedAsString || "-"}
                                          unit={$t("UI.unit.eurPerTon.short.small")}/>
                    <div class="offer-price-container_separator">
                        <hr/>
                    </div>
                {/if}
                <FormattedHeaderValue
                        title={$showKickback ? $t("UI.offer.priceInclKickback") : $t('UI.lot.grossPricePerTon.Get.label')}
                        value={offer?.facts.priceValFormattedAsString || "-"} unit={$t("UI.unit.eurPerTon.short.small")}/>
            </div>
        </Card>
    </div>
    {#if requestType === RequestType.MATERIAL}
        <div class="offer-facts-info__place">
            <Card borderRadius="0.625rem" boxShadow="" padding="1.25rem">
                <div class="offer-info-card-data">
                    <FormattedHeaderValue title={$t('UI.counterOffer.acceptPlace.label')} value={offer.offerLocation}/>
                </div>
            </Card>
        </div>
    {/if}
    <div class="offer-facts-info__comment">
        <Card borderRadius="0.625rem" boxShadow="" padding="1.25rem">
            <div class="offer-info-card-data">
                <TextArea
                        id={requestType === RequestType.MATERIAL ? "furtherDetailsMaterial" : "furtherDetailsMaterialWithTransport"}
                        text={offer.furtherDetailsComment} title={$t('UI.Model.moreDetails')}
                        placeholder={$t('UI.remarks.noRemarks')} marginTop="0" readonly/>
            </div>
        </Card>
    </div>
</div>

<style lang="scss">
  @use "../../styles/global";

  .offer-info-card-data {
    @include global.flex-col(1.25rem, $alignment: flex-start, $justify: space-between);
    height: 100%;

    &.hideKickback {
      align-items: center;
      justify-content: center;

      :global(.container-header-value) {
        align-items: center;
        justify-content: center;
      }

      :global(.container-header-value span) {
        font-size: 1rem !important;
      }
    }

    .offer__dates {
      @include global.flex-row(1.5rem, $justify: flex-start, $alignment: center);
      width: 100%;
    }

  }

  .offer-facts-info__data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: subgrid;
    grid-column-gap: 2rem;
    grid-row-gap: 1.375rem;
    grid-row: 2/5; /* rows 2-5 of the parent grid */
  }

  .offer-price-container_separator {
    width: 100%;
    text-align: right;

    hr {
      color: global.$primaryGreen;
      height: 1px;
      width: 100%;
      margin: 1rem 0 0.7rem;
    }
  }

  /* order of the rows for desktop, not important for mobile since we have a one-row layout there */
  @media screen and (min-width: 992px) {
    .material {
      grid-column: 2/2;
    }

    .material_with_transport {
      grid-column: 1/1;
    }

    .offer-facts-info__price {
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .offer-facts-info__facts {
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .offer-facts-info__place {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .offer-facts-info__comment {
      grid-column-start: 1;
      grid-column-end: 3;
    }

  }

  .offer-facts-info__place {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .offer-facts-info__comment {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .offer-facts-info__place {
    grid-row: 2/2;
  }
  .offer-facts-info__comment {
    grid-row: 3/3;
  }


  /* Tablets*/
  @media screen and (max-width: 992px) {
    .offer-facts-info__data {
      grid-template-columns: 1fr 1fr;
    }

    .material {
      grid-column: 1/1;
    }

    .material.offer-facts-info__data {
      grid-row: 6/9;
    }
  }


  /*Phones*/
  @media screen and (max-width: 768px) {
    .offer-facts-info__data {
      grid-template-columns: 1fr;
    }
  }

</style>
