export type Notification = {
    message: string,
    messageHint?: string | undefined,
    dismissible?: boolean,
    timeout?: number,
    type: NotificationType,
    target?: string | null,
    id?: Symbol,
}

export function DefaultNotification(msg: string, type: NotificationType, dismissible: boolean = true, timeout: number = 3000, target: string | null = null): Notification {
    const id: Symbol = Symbol();
    return {message: msg, type, dismissible, timeout, target, id};
}

export enum NotificationType {
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
    INFO_INLINE = 'info-inline'
}
