import {LotMailNotificationType} from "./enums";

export type AccountingMailConfigData = {
    accountingMailData: AccountingMailData[],
    readOnly: boolean,
    isAccountingMailDispatchingDisabled:boolean,
    mailingList:string[],
    receiverList:string[],
    actualTargetComparisonFilled: boolean,
    holidayDays:string[];

}
export function DefaultAccountingMailConfigData() {
    let accountingMailConfigData: AccountingMailConfigData = {accountingMailData: [DefaultAccountingMailData(), DefaultAccountingMailData(),DefaultAccountingMailData(), DefaultAccountingMailData()],
        readOnly: true, isAccountingMailDispatchingDisabled: true, mailingList: [""], actualTargetComparisonFilled: false,
        receiverList: ["Case Owner: moritz.obexer@mineral-minds.de", "Optimierer: showcase-ag@mineral-minds.de", "Anbieter: bob.baumeister@mineral-minds.de"],
        holidayDays: ["2025-11-17", "2025-11-20"]};
    return accountingMailConfigData
}

export type AccountingMailData = {
    dispatchDateMail: string,
    mailType: LotMailNotificationType,
    subject:string
    state: string,
    htmlContent:string
    mailingList:string[]
}

export function DefaultAccountingMailData() {
    let mailData: AccountingMailData = {dispatchDateMail: null, mailType: LotMailNotificationType.FIRST_MAIL , subject: "Mail 1: Aufforderung Mengen und preise zurückzumelden" , state: "Versendet", htmlContent:"htmlContent", mailingList:[]};
    return mailData
}