<script lang="ts">

    import InputField from "../molecules/InputField.svelte";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import {
        DefaultMaterialProperty,
        DefaultMaterialPropertyListElement,
        dropdownTypes,
        type MaterialProperty,
        materialPropertyDropdownSchema,
        type MaterialPropertyListElement,
        materialPropertyListElementSchema,
        materialPropertySchema,
        MaterialPropertyType,
        PropertySystemFlag
    } from "./types/materialProperty";
    import type {FontProps} from "../types/fontProps";
    import {DefaultInputFieldProps, DefaultRobotoFontProps} from "../types/fontProps";
    import type {AutocompleteFieldConfig} from "../types/autocompleteFieldConfig";
    import {AutocompleteConfig} from "../types/autocompleteFieldConfig";
    import {t} from "../../i18n/i18n";
    import TextArea from "../atoms/TextArea.svelte";
    import {inputFieldFormats} from "../utils/formatters";
    import {createEventDispatcher, getContext} from "svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import type {Notification} from "../types/notification";
    import {DefaultNotification, NotificationType} from "../types/notification";
    import {addNotification, breadcrumbs} from "../stores";
    import type {Writable} from "svelte/store";
    import {getFormErrors} from "../utils/formValidation";
    import PrimaryButton from "../atoms/PrimaryButton.svelte";
    import Tooltip from "../atoms/Tooltip.svelte";
    import {ButtonIcons, TooltipPlacements} from "../types/enums";
    import StaticChipList from "../organisms/StaticChipList.svelte";
    import addIcon from "@/icons/icon_add_green.svg";

    const dispatch = createEventDispatcher();
    const fontProps: FontProps = DefaultInputFieldProps();
    const headerFontProps: FontProps = DefaultRobotoFontProps('15px', '0.875rem');
    const MATERIAL_PROPERTY_FORM_ERROR_PATH_PREFIX = 'materialProperty.'
    const MATERIAL_FORM_ERROR_PATH_PREFIX = 'material.';

    const materialTypeOptions = Object.values(MaterialPropertyType)
        .map(prop => ({text: $t('UI.mmpv.properties.types.' + prop), value: prop}));
    let typeAcConfig: AutocompleteFieldConfig = AutocompleteConfig('value', 'text');
    typeAcConfig.clearable = false;

    let systemFlagsOptions = Object.values(PropertySystemFlag).map(flag => ({label: $t(`PropertySystemFlag.${flag}`), value: flag}));

    // export let materialId: string; // fixme we probably need this to save the property to an existing material
    export let materialProperty: MaterialProperty = DefaultMaterialProperty();

    // we need to introduce this variable because otherwise the property would be set to null from the AC-component, and then we would get NPEs
    // we transfer the selected data from the acProperty to the property on:change
    let acProperty: MaterialProperty = DefaultMaterialProperty();
    let formErrors: Writable<{ path: string, message: string }[]> = getContext("formErrors");
    let properties: Writable<Map<string, MaterialProperty>> = getContext('materialProperties');
    let selectedMaterialPropertyListElement: MaterialPropertyListElement = DefaultMaterialPropertyListElement();

    function updateFlags(){
        if (materialProperty.type === MaterialPropertyType.MULTISELECT || materialProperty.type === MaterialPropertyType.SINGLESELECT) {
            systemFlagsOptions = Object.values(PropertySystemFlag).map(flag => ({label: $t(`PropertySystemFlag.${flag}`), value: flag}));
        } else {
            systemFlagsOptions = Object.values(PropertySystemFlag)
                .filter(flag => flag !== PropertySystemFlag.COMMENT_GENERATION_NEW_LINE_FOR_LIST_ELEMENT)
                .filter(flag => flag !== PropertySystemFlag.COMMENT_GENERATION_NEW_LINE_FOR_CHILDREN)
                .map(flag => ({label: $t(`PropertySystemFlag.${flag}`), value: flag}));
            materialProperty.flags = materialProperty.flags
                .filter(flag => flag !== PropertySystemFlag.COMMENT_GENERATION_NEW_LINE_FOR_LIST_ELEMENT)
                .filter(flag => flag !== PropertySystemFlag.COMMENT_GENERATION_NEW_LINE_FOR_CHILDREN)
                .map(flag => flag);
        }
    }

    /**
     * Asynchronously saves a material property.
     * It validates the property as per the relevant schema before saving it.
     * On successful save, a success notification is added and the property is placed in store.
     *
     * @param {MaterialProperty} property - The material property to be saved.
     * @param {string} target specifies the target of the notification, whether they should be displayed on the modal or not
     * @param {string} materialId if a materialId is given, we can show another notifcation
     * @returns {Promise<number>} The ID of the saved property, or void if validation failed.
     * @throws {Error} If there was an error saving the property.
     */
    export async function saveMaterialProperty(property: MaterialProperty, target: string, materialId: string = null) {
        try {
            $formErrors = [];
            let validatedProperty: MaterialProperty;
            if (dropdownTypes.includes(materialProperty.type)) {
                validatedProperty = await materialPropertyDropdownSchema.validate(property, {
                    stripUnknown: false,
                    abortEarly: false
                })
            } else {
                validatedProperty = await materialPropertySchema.validate(property, {
                    stripUnknown: false,
                    abortEarly: false
                })
            }
            if (validatedProperty.priority === null || !validatedProperty.priority) {
                validatedProperty.priority = 100; // set default priority
            }
            await fetchUtils.post(`/api/property/update/${property.id}`, validatedProperty)
                .then((data) => {
                    let wasNewProperty: boolean = property.id === "new";
                    Object.assign(validatedProperty, data)
                    let text: string = wasNewProperty ? $t('UI.mmpv.material.property.createdSuccessful.notification') : $t('UI.mmpv.material.property.updatedSuccessful.notification');
                    // if saved property was only/last object in breadcrumbs show notification in material properties page
                    if ($breadcrumbs.length == 1) {
                        target = null;
                        text = wasNewProperty && materialId ? $t('UI.mmpv.material.property.addedSuccessful.notification') : $t('UI.mmpv.material.property.updatedSuccessful.notification');
                    }
                    let notification: Notification = DefaultNotification(text, NotificationType.SUCCESS, true, 3000, target);
                    addNotification(notification);
                    $properties.set(validatedProperty.id, validatedProperty); // save new property to store
                }).catch((error) => {
                    let notification = DefaultNotification(error.message ?? error, NotificationType.ERROR, true, 0, target);
                    addNotification(notification);
                    validatedProperty.id = null;
                });
            $properties = $properties;
            return validatedProperty.id;
        } catch (error) {
            let err = getFormErrors(error, MATERIAL_PROPERTY_FORM_ERROR_PATH_PREFIX);
            console.log('ValidationError: ');
            console.dir(err);
            $formErrors = err;
        }
    }

    /**
     * Asynchronously creates a property list element.
     * It validates the property list element as per the relevant schema before creating it.
     * On successful creation, the property list element is pushed to the material property
     * and the selected material property list element is reset.
     *
     * @throws {Error} If validation failed.
     */
    async function createPropertyListElement() {
        try {
            $formErrors = [];
            let validatedPropertyListElement = await materialPropertyListElementSchema.validate(selectedMaterialPropertyListElement, {
                stripUnknown: false,
                abortEarly: false
            });
            if (validatedPropertyListElement.priority === null || !validatedPropertyListElement.priority) {
                validatedPropertyListElement.priority = 100; // set default priority
            }
            materialProperty.propertyListElements.push(validatedPropertyListElement);
            materialProperty = materialProperty; // reassign to trigger update after push
            selectedMaterialPropertyListElement = DefaultMaterialPropertyListElement(); // reset property list element
            selectedMaterialPropertyListElement.priority = null;
        } catch (error) {
            let err = getFormErrors(error, MATERIAL_FORM_ERROR_PATH_PREFIX);
            console.log('ValidationError: ');
            console.dir(err);
            $formErrors = err;
        }
    }

    /**
     * Removes a property list element from the property.
     * The function removes the element from both the propertyListElementIds and propertyListElements of the materialProperty.
     * @param {MaterialPropertyListElement} listElement - The list element to be removed.
     */
    function removePropertyListElement(listElement: MaterialPropertyListElement) {
        const idIndex = materialProperty.propertyListElementIds.indexOf(listElement.id);
        const elementIdx = materialProperty.propertyListElements.indexOf(listElement);
        if (idIndex > -1) {
            materialProperty.propertyListElementIds.splice(idIndex, 1);
            materialProperty = materialProperty;
        }
        if (elementIdx > -1) {
            materialProperty.propertyListElements.splice(elementIdx, 1);
            materialProperty = materialProperty;
        }
    }

    function pushMaterialProperty(listElement: MaterialPropertyListElement) { // we add the material property to the stack, because we navigate to the propertyListElement
        dispatch('pushMaterialProperty', {listElement: listElement}); // no need to hand over the object, we bind on it anyways
    }

    function pushListElementAndCreateNewProperty(listElement: MaterialPropertyListElement) {
        // we need to push the material Property AND the specific listElement, because we add a new property to the listElement
        dispatch('pushListElementAndCreateNewProperty', {listElement: listElement});
    }

    function handleKeydown(e) {
        if (e.key === 'Enter') {
            createPropertyListElement();
        }
    }

    /**
     * Constructs tooltip content for a list element that contains properties.
     * Content is built according to the number of properties in the list element.
     * The options are: one, two or more properties.
     * We ignore 0, because we do not show the tooltip anyways
     * @param {MaterialPropertyListElement} listElement - The list element for which the tooltip content is to be built.
     * @returns {string} The tooltip content string.
     */
    function buildTooltipContentForListElementWithProperties(listElement: MaterialPropertyListElement) {
        let res;
        switch (listElement.propertyChildIds.length) {
            case 1:
                res = $t('UI.mmpv.materialPropertyListElement.addedProperty.one.tt',
                    {name: $properties.get(listElement.propertyChildIds[0]).name});
                break;
            case 2:
                res = $t('UI.mmpv.materialPropertyListElement.addedProperty.two.tt', {
                    name: $properties.get(listElement.propertyChildIds[0]).name,
                    name2: $properties.get(listElement.propertyChildIds[1]).name
                });
                break;
            default:
                res = $t('UI.mmpv.materialPropertyListElement.addedProperty.default.tt', {
                    name: $properties.get(listElement.propertyChildIds[0]).name,
                    name2: $properties.get(listElement.propertyChildIds[1]).name
                });
        }
        return res;
    }

    $: materialProperty.type, updateFlags();

</script>

<div class="material-property-edit-container">
    <!--{#if allFields} &lt;!&ndash;e.g. for editing an existing property or to create a totally new property &ndash;&gt;-->
    <div style="grid-area: name">
        <InputField id={"input_property_name"} label={$t('UI.mmpv.properties.name.label')}
                    placeholder={$t('UI.mmpv.properties.name.ph')}
                    bind:value={materialProperty.name} format={inputFieldFormats.FULL} {fontProps} {headerFontProps}
                    errorPath={MATERIAL_PROPERTY_FORM_ERROR_PATH_PREFIX + 'name'}/>
    </div>
    <div style="grid-area: flags">
        <StaticChipList bind:activeChips={materialProperty.flags} list={systemFlagsOptions}
                        header="Annotationen" fontProps={headerFontProps} id="flags"
                        chipFontProps={DefaultRobotoFontProps('0.625rem', '0.875rem', undefined, 'white')}/>
    </div>
    <div style="grid-area: priority">
        <InputField id={"input_property_priority"} label={$t('UI.mmpv.properties.priority.label')}
                    bind:value={materialProperty.priority}
                    format={inputFieldFormats.FULL} {fontProps} {headerFontProps}
                    errorPath={MATERIAL_PROPERTY_FORM_ERROR_PATH_PREFIX + 'priority'} isNumeric/>
    </div>
    <div style="grid-area: displayName">
        <InputField id={"input_property_display_name"} label={$t('UI.mmpv.properties.displayName.label')}
                    placeholder={$t('UI.mmpv.properties.displayName.ph')}
                    bind:value={materialProperty.displayName} format={inputFieldFormats.FULL} {fontProps}
                    {headerFontProps}
                    errorPath={MATERIAL_PROPERTY_FORM_ERROR_PATH_PREFIX + 'displayName'}/>
    </div>
    <div style="grid-area: description">
        <TextArea id={"textarea_property_description"} title={$t('UI.mmpv.properties.description.label')}
                  placeholder={$t('UI.writeHere.ph')}
                  bind:text={materialProperty.description} inputFontProps={fontProps}
                  {headerFontProps} marginTop="0" labelGap="0.375rem"
                  errorPath={MATERIAL_PROPERTY_FORM_ERROR_PATH_PREFIX + 'description'}/>
    </div>
    <span class="modal-subheader">{$t('UI.mmpv.materialProperty.type.subheader')}</span>
    <div style="grid-area: type" id="autocomplete-property-type">
        <AutocompleteInputField bind:value={materialProperty.type} {fontProps} autocompleteConfig={typeAcConfig}
                                label={$t('UI.mmpv.properties.type.label')} options={materialTypeOptions}
                                errorPath={MATERIAL_PROPERTY_FORM_ERROR_PATH_PREFIX + 'type'} hideDropdownArrow={false}
                                on:change={() => materialProperty = materialProperty}/>
    </div>
    {#if dropdownTypes.includes(materialProperty.type) && $properties !== undefined}
        <!-- input field + list of values -->
        <div class="property-values-container">
            <span>{@html $t('UI.mmpv.properties.dropdown.values.hint')}</span>
            <div>
                <div class="property-add-list-element">
                    <InputField bind:value={selectedMaterialPropertyListElement.name}
                                label={$t('UI.mmpv.properties.dropdown.values.label')}
                                placeholder={$t('UI.mmpv.materialProperty.createListElement.ph')}
                                {fontProps} {headerFontProps} format={inputFieldFormats.FULL}
                                errorPath={MATERIAL_PROPERTY_FORM_ERROR_PATH_PREFIX + 'propertyListElements' + '||' + MATERIAL_FORM_ERROR_PATH_PREFIX + 'name'}
                                on:keydown={handleKeydown}/>
                    <InputField bind:value={selectedMaterialPropertyListElement.priority}
                                label={$t('UI.mmpv.materialProperty.createListElement.priority.label')}
                                placeholder={$t('UI.mmpv.materialProperty.createListElement.priority.ph')}
                                {fontProps} {headerFontProps} format={inputFieldFormats.FULL}
                                containerWidth="max-content" on:keydown={handleKeydown}/>
                    <PrimaryButton label={$t('UI.mmpv.properties.listElements.add')}
                                   on:click={createPropertyListElement}
                                   leftIcon={ButtonIcons.CHECKMARK} whiteIcon sizeAdaptingToText/>
                </div>
                {#each materialProperty.propertyListElements.sort((a, b) => a.priority - b.priority) as listElement}
                    <div class="material-property-list-element">
                        <div>
                            <span on:click={() => pushMaterialProperty(listElement)}>{listElement.name}</span>
                            {#if listElement.propertyChildIds !== undefined && listElement.propertyChildIds.length > 0}
                                <div class="info-icon">
                                    <Tooltip ttipIconHeight="20px" ttipIconWidth="20px" maxWidthTtip={370} placementTtip={TooltipPlacements.RIGHT}
                                             msg={"<div style='line-height: 20px;'> " + buildTooltipContentForListElementWithProperties(listElement)  + "</div>"}
                                             isHtml/>
                                </div>
                            {/if}
                        </div>
                        <div>
                            <div class="material-property-priority">
                                <span>{$t('UI.mmpv.propertyListElement.priority.info', {prio: listElement.priority})}</span>
                            </div>
                            <div class="element-add-child-btn"
                                 on:click={() => pushListElementAndCreateNewProperty(listElement)}>
                                <Tooltip placementTtip={TooltipPlacements.RIGHT} ttipIconImage={addIcon}
                                         msg={$t('UI.mmpv.propertyListElement.addProperty.tooltip')}/>
                            </div>
                            <div class="element-delete-btn"
                                 on:click={() => removePropertyListElement(listElement)}></div>
                        </div>
                    </div>
                {/each}
            </div>
        </div>
    {/if}


</div>


<style lang="scss">
  @import "../../styles/global";

  .material-property-edit-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1.25rem;
    grid-template-areas:
    "name name name name"
    "flags flags flags flags"
    "displayName displayName displayName priority"
    "description description description description"
    "subheader subheader subheader subheader"
    "type type type ."
    "listElements listElements listElements listElements"
    "btns btns btns btns";
    width: 100%;
    align-items: end;

    & > span:first-child {
      @include roboto-font(15px, 400, 0.75rem, $primaryGreen);
    }

    & > span:nth-of-type(2) {
      @include roboto-font(15px, 400, 0.75rem, black);
    }
  }

  span.modal-subheader {
    @include roboto-font(21px, 500, 0.875rem, $primaryBlue);
    grid-area: subheader;
  }

  .property-values-container {
    width: 100%;
    grid-area: listElements;
    margin-top: -0.5rem;

    & > span {
      @include roboto-font(15px, 400, 0.75rem, black);
    }
  }

  .buttons-row {
    width: 100%;
    @include flex-row(0.875rem, $justify: flex-end);
  }

  div.material-property-list-element {
    @include flex-row(0.875rem, $justify: flex-start, $alignment: center);
    width: 100%;
    border-bottom: 1px solid $grey-600;
    padding: 0.75rem 0;

    & > div {
      width: 100%;

      &:first-child {
        @include flex-row(0.375rem, $justify: flex-start);
        @include roboto-font(10px, 400, 0.75rem, $primaryGreen);
      }

      &:last-child {
        @include flex-row(0.5rem, $justify: flex-end);
        margin-right: 0.875rem;
      }

      & > span {
        cursor: pointer;
      }
    }

    & .element-delete-btn {
      background: url("@/icons/icon_trash_red.svg") no-repeat center;
    }

    & .material-property-priority {
      visibility: hidden;

      & > span {
        @include roboto-font(10px, 400, 0.75rem, $grey-800)
      }
    }

    & .element-add-child-btn,
    & .element-delete-btn {
      width: 24px;
      height: 24px;
      visibility: hidden;
      cursor: pointer;
    }

    &:hover {
      & .material-property-priority,
      & .element-add-child-btn,
      & .element-delete-btn {
        visibility: visible;
      }
    }
  }

  div.property-add-list-element {
    @include flex-row(0.875rem, $justify: flex-start, $alignment: flex-end);
    width: 100%;
    margin-top: 1.5rem;
  }

  .material-property-edit-container :global(.sv-control) {
    height: 32px; // override sv control to have the same height as the usual input fields
  }

  .material-property-edit-container :global(.checkbox-container) {
    justify-content: flex-start !important; // override the checkbox to be at the beginning of the row
  }


</style>
