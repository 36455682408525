<script lang="ts">
    import {getContext} from "svelte";
    import MapMatchActionBox from "../MapMatchActionBox.svelte";
    import Legacy from "./Legacy.svelte";
    import type {Writable} from "svelte/store";
    import MatchesTable from "../components/MatchesTable.svelte";
    import type {MatchMarkerDto} from "../types/matchMarkerDto";
    import Modals from "../../organisms/Modals.svelte";
    import MatchingDistanceLayout from "../components/MatchingDistanceLayout.svelte";

    export let position: google.maps.ControlPosition;
    export let refLot: string;
    export let startObject: string;
    export let match: string;
    export let minDistance: number;
    export let maxDistance: number;
    export let allowMapForm: boolean;

    let map: Writable<google.maps.Map> = getContext("map");
    let showMatches: boolean = false;
    let showPublishLotOnMM: boolean = false;
    let matchData: MatchMarkerDto[] = [];
    let markers: any[] = [];
    let referenceMarker: any;

    window.addEventListener('match-markers-created', initMatches);
    window.addEventListener('current-lot-changed', changeLot);
    window.addEventListener('lot-unselected', handleCurrentLotUnselected);
    window.addEventListener('edit-mini-lot', handleCurrentLotUnselected);

    function createControl(element: HTMLElement) {
        $map.controls[position].push(element);
    }

    function initMatches(event) {
        if (event.detail.markers?.length > 0) {
            markers = event.detail.markers.filter(marker => !marker.isReference && marker.lotId !== 'new');
            referenceMarker = event.detail.markers.filter(marker => (marker?.lotId === 'new' || marker.isReference) && marker.item.itemType !== 'PROJECT_LOCATION')[0];
            showMatches = true;
            // if saas handle low or no matches
            let FLAG_PUBLISH_ON_MM = window.mm.jwt.perms.includes('permission-prepare-lot') && window.mm.jwt.saas;
            if (FLAG_PUBLISH_ON_MM && markers.length <= 5 && referenceMarker.item.isPublishableOnMm && referenceMarker.item.lotId !== 'new') {
                showPublishLotOnMM = true;
            }
        }
    }

    function handleCurrentLotUnselected() {
        showMatches = false;
    }

    function changeLot(event) {
        if (!referenceMarker || event.detail.marker?.item?.lotId != referenceMarker?.item?.lotId) {
            referenceMarker = event.detail.marker;
        }
    }
</script>

<Modals/>

<div style="display: none">
    <div class="map-control-column" id="map-top-left-control" use:createControl>
        <div>
            <!--<RoutePlanerSearchBox/>-->
            {#if showMatches}
                <MatchesTable {markers} {referenceMarker} {showPublishLotOnMM} {match}/>
            {/if}
            { #if allowMapForm }
                <MapMatchActionBox/>
            {/if}
            <Legacy {refLot} {startObject} {match} {minDistance} {maxDistance}></Legacy>
            <MatchingDistanceLayout from={minDistance} to={maxDistance}/>
        </div>
    </div>
</div>

<style lang="scss">
  @import "../../../styles/global";

  .map-control-column {
    height: 100%;
    overflow: auto;
    pointer-events: none;
    padding: 1rem;

    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      @include flex-col(0.625rem, $alignment: flex-start, $justify: flex-start);
      width: 100%;
      pointer-events: all;
      padding-top: 1rem;
    }
  }

  .map-control-column > * {
    pointer-events: all;
  }

</style>
