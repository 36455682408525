<script lang="ts">
    let flipped = false;

    function flipHandler() {
        flipped = !flipped;
    }
</script>
<div class="flipper-card" class:flipped>
    <!-- placeholder is needed because position of front and back is absolute -->
    <!-- we also need to give the info if we are in the placeholder, as e.g. when we want to show tooltips
    from the beginning, they are rendered one time for EVERY slot, and since we don't want that,
    we give this boolean to check if we are in the placeholder slot -->
    <!-- we do not have to discuss that this solution is not beautiful, but I did not find another way -->
    <div class="placeholder">
        <slot isPlaceholder/>
    </div>
    <div class="flipper-slots" class:front={!flipped} class:back={flipped}>
        <slot {flipHandler} {flipped} isPlaceholder={false}/>
    </div>
</div>

<style lang="scss">
  .flipper-card {
    width: 100%;
    height: 100%;
    display: flex;
    perspective: 1000px;
    transform-style: preserve-3d;
    transition: all 0.75s ease;
  }

  .flipped {
    transform: rotateY(180deg);
  }

  .placeholder {
    position: relative;
    background: transparent;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }

  .flipper-slots {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    background: white;
  }

  .back {
    transform: rotateY(180deg);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }

</style>
