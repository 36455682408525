<script lang="ts">
    import {t} from "../../i18n/i18n";
    import {AnimatePresence, Motion} from "svelte-motion";

    export let progressLineIconType: string = 'inactive';
    export let ordinalValue: number;
    export let fontWeight: string = '400';
    export let lotStatus: string;
    export let lotStateDate: string = null;
    export let strokeLineType: string = "inactive";
    export let lotStateTextType: string = "empty";
    export let animate: boolean = false;
    export let barHeight: number;

    // +1 to set the position below or above the status bar
    let marginTopStrokeLine: string = (ordinalValue) % 2 === 0 ? '-50px' : '50px';
    let marginTopText: string = (ordinalValue) % 2 === 0 ? '-10px' : '7px';

    const variants = {
        enter: () => {
            return {
                scale: animate ? 0.2 : 1
            };
        },
        center: () => {
            return {
                scale: 1,
                transition: {duration: 0.7}
            }
        },
        exit: () => {
            return {
                scale: animate ? 0 : 1
            };
        },
    };

</script>

<AnimatePresence list={ [{ key: animate, animate }]} let:item={lotStateItem}>
    {#if lotStateItem.animate}
        <Motion layout let:motion={icons} {variants} exit="exit" animate="center" initial="enter">
            <div use:icons class="{'progress-line-icon ' + progressLineIconType}"
                 style="--position: {ordinalValue + 1};--barHeight: {barHeight + 'rem'}">
                <div class="{strokeLineType} + stroke-line" style="--marginTop: {marginTopStrokeLine};">
                    <div class="{lotStateTextType + ' lot-state-text'}"
                         style="--marginTop: {marginTopText}; --font-weight: {fontWeight}">
                        {$t('UI.LotState.' + lotStatus)}
                    </div>
                    <div class="lot-state-date">
                        {lotStateDate ?? "–"}
                    </div>
                </div>
            </div>
        </Motion>
    {:else}
        <Motion layout let:motion={noAnimation}>
            <div use:noAnimation class="{'progress-line-icon ' + progressLineIconType}"
                 style="--position: {ordinalValue + 1};--barHeight: {barHeight + 'rem'}">
                <div class="{strokeLineType} + stroke-line" style="--marginTop: {marginTopStrokeLine};">
                    <div class="{lotStateTextType + ' lot-state-text'}"
                         style="--marginTop: {marginTopText}; --font-weight: {fontWeight}">
                        {$t('UI.LotState.' + lotStatus)}
                    </div>
                    <div class="lot-state-date">
                        {lotStateDate ?? "–"}
                    </div>
                </div>
            </div>
        </Motion>
    {/if}
</AnimatePresence>

<style lang="scss">
  @import "../../styles/global";

  .progress-line-icon {
    grid-column: var(--position);
    grid-row: 1; /* first row */
    z-index: 2;
    width: var(--barHeight);
    height: var(--barHeight);
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
  }

  .progress-line-icon.active {
    background-image: url("@/icons/icon_progressBarActive.svg");
  }

  .progress-line-icon.inactive {
    background-image: url("@/icons/icon_progressBarInactive.svg")
  }

  .progress-line-icon.empty {
    background-image: url("@/icons/icon_progressBarEmpty.svg");
  }

  .progress-line-icon.inactive-discarded {
    background-image: url("@/icons/icon_progressBarInactiveDiscarded.svg")
  }

  .progress-line-icon.active-discarded {
    background-image: url("@/icons/icon_progressBarActiveDiscarded.svg");
  }

  .stroke-line {
    width: 1px;
    height: 20px;
    display: flex;
    flex-direction: column;
  }

  .stroke-line.active {
    background-color: #4A609F;
    margin-top: var(--marginTop);
  }

  .stroke-line.inactive {
    background-color: #D9D9D9;
    margin-top: var(--marginTop);
  }

  .stroke-line.discarded {
    background-color: #C05858;
    margin-top: -50px;
  }

  .lot-state-text {
    margin-left: 6px;
    width: 70px;
    display: flex;
  }

  .lot-state-text.active {
    margin-top: var(--marginTop);
    @include roboto-font(139.187%, 500, 0.625rem, #4A609F);
  }

  .lot-state-text.empty {
    margin-top: var(--marginTop);
    @include roboto-font(139.187%, var(--font-weight), 0.625rem, #9B9B9B);
  }

  .lot-state-text.inactive {
    @include roboto-font(139.187%, 400, 0.625rem, #000000);
    margin-top: var(--marginTop);
  }

  .lot-state-text.discarded {
    @include roboto-font(139.187%, var(--font-weight), 0.625rem, #C05858);
    margin-top: var(--marginTop);
  }

  .lot-state-date {
    @include roboto-font(139.187%, 400, 0.5rem, #9B9B9B);
    margin-left: 6px;
  }

</style>
