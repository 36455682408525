<script lang="ts" context="module">
    //https://stackoverflow.com/a/65450609, https://svelte.dev/docs#component-format-script-context-module

    export enum SecondaryBtnColors {
        GREEN = "green",
        GREY = "grey",
        RED = "red",
        BLUE = "blue",
    }
</script>

<script lang="ts">
    import {ButtonIcons} from "../types/enums";

    export let id: string = "";
    export let label = "";
    export let disabled = false;
    export let hide = false;
    export let leftIcon: ButtonIcons = ButtonIcons.NONE;
    export let rightIcon: ButtonIcons = ButtonIcons.NONE;

    /**
     * The size of the icon in the button. Defaults to 20px.
     */
    export let iconSize: string = '20px';
    export let color: SecondaryBtnColors = SecondaryBtnColors.GREEN;
    /**
     * Control the horizontal padding. This is one value for left AND right padding, as we always want the content centered.
     * Defaults to 0.75rem.
     */
    export let paddingHorizontal = '0.75rem';

    /**
     * Control the top padding. Defaults to 0.75rem
     */
    export let paddingTop = '0.75rem';

    /**
     * Control the bottom padding. Defaults to 0.75rem
     */
    export let paddingBottom: string = '0.75rem';
    /**
     * In case the button is in something like a flex container and should take the full width of his container.
     * Defaults to false.
     */
    export let fullWidth: boolean = false;

    /**
     * In case the button should not have a fixed width, set this to true. Then the button will only be as long as necessary for the text + padding.
     * Should be used alternating to fullWidth. Defaults to false.
     */
    export let sizeAdaptingToText: boolean = false;

    /**
     * In case we want to have the icon and the text not directly beneath each other, but rather flushed to the ends of the button.
     */
    export let flushTextAndIcon: boolean = false;
    export let width = '250px';
    export let fontSize = '0.75rem';
    export let borderRadius = '0.125rem';
    export let titleAttr: string = label; // adds a default browser tooltip with the label

    let buttonStyle: string = "";
    $: buttonStyle = `--icon-size: ${iconSize};
                      --padding-horizontal: ${paddingHorizontal};
                      --padding-top: ${paddingTop};
                      --padding-bottom: ${paddingBottom};
                      --font-size: ${fontSize};
                      --width: ${width};
                      --border-radius: ${borderRadius};
                      ${hide ? 'display: none;' : ''}`;
</script>

<button {id} class="secondary-button {color.toString()}" {disabled} on:click
        class:fullWidth class:sizeAdaptingToText class:flushTextAndIcon
        title={titleAttr} style={buttonStyle}>
    {#if leftIcon !== ButtonIcons.NONE}
        <div class="button-icon icon-{leftIcon.toString().toLowerCase().replace(/_/g, '-')}"
             class:icon-filter-disabled={disabled}></div>
    {/if}
    {label}
    {#if rightIcon !== ButtonIcons.NONE}
        <div class="button-icon icon-{rightIcon.toString().toLowerCase().replace(/_/g, '-')}"
             class:icon-filter-disabled={disabled}></div>
    {/if}
</button>


<style lang="scss">
  @import "../../styles/global";
  @import "../../styles/buttons";

  .secondary-button {
    background-color: white;
    color: white;
    @include roboto-font(16px, 400, var(--font-size), #000000);
    padding: var(--padding-top) var(--padding-horizontal) var(--padding-bottom);
    border-radius: var(--border-radius);
    width: var(--width);
    min-width: min-content;
    height: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    @include flex-row(var(--icon-gap));

    &:disabled, &:disabled:hover {
      border: 2px solid $bluish-grey-700;
      background-color: $grey-500;
      @include roboto-font(12px, 500, var(--font-size), $bluish-grey-700);
    }

    @media screen and (max-width: 992px) {
      width: 200px;
    }
    @media screen and (max-width: 768px) {
      width: 180px;
    }
  }


  .green {
    border: 2px solid $primaryColor;

    &:hover {
      border: 2px solid $secondaryColor;
    }
  }

  .grey {
    border: 1px solid $bluish-grey-700;
    color: #8D8D8D;

    &:hover {
        color: #000;
    }
  }

  .red {
    border: 2px solid $primaryRed;

    &:hover {
      border: 2px solid $primaryDarkRed;
    }
  }

  .blue {
    border: 2px solid $primaryBlue;

    &:hover {
      border: 2px solid $hoverStateBlue;
    }
  }

</style>
