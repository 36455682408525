<script lang="ts">

    import SimpleDropdownLinkItem from "../../dropdown/SimpleDropdownLinkItem.svelte";
    import DropdownMenuSection from "../../dropdown/DropdownMenuSection.svelte";
    import DropdownMenu from "../../dropdown/SimpleDropdownMenu.svelte";
    import MenuItem from "../../MenuItem.svelte";
    import {UserPermission} from "../../../types/enums";
    import {t} from "../../../../i18n/i18n";
</script>

<MenuItem title={$t("Project.plural")} url="/projects" permissions={UserPermission.VIEW_PROJECTS} let:active>
    <DropdownMenu {active}>
        <DropdownMenuSection>
            <SimpleDropdownLinkItem titleKey="Project.all" url="/projects"/>
            <SimpleDropdownLinkItem titleKey="Project.active" url="/projects/active"/>
            <SimpleDropdownLinkItem titleKey="Project.closed" url="/projects/closed"/>
            <SimpleDropdownLinkItem titleKey="Project.discarded" url="/projects/canceled"/>
        </DropdownMenuSection>
    </DropdownMenu>
</MenuItem>
