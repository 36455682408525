export type ContactData = {
    eMail: string,
    phone: string,
    mobile: string,
    fullName?: string
}

export function DefaultContactData() {
    let contactData: ContactData = {eMail: "", mobile: "", phone: "", fullName: ""};
    return contactData;
}
