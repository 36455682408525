<script lang="ts">
    import {inputFieldFormats} from "../utils/formatters";
    import type {FontProps} from "../types/fontProps";
    import {DefaultInputFieldProps, getFontPropsVarString} from "../types/fontProps";
    import MaterialInputField from "../molecules/MaterialInputField.svelte";
    import {getContext, hasContext, onMount} from "svelte";
    import {InputIcons} from "../types/enums";

    export let useMaterialDesign: boolean = hasContext('useMaterialDesign') ? getContext('useMaterialDesign') : false;

    export let id: string = "input-id" + Math.random().toString(16).slice(2);
    export let value: string | number;
    export let placeholder: string | number = "";
    export let format = inputFieldFormats.NORMAL;
    export let onInputFinished = () => {
    };
    export let borderless: boolean = false;
    export let fontProps: FontProps = DefaultInputFieldProps();

    export let readOnly: boolean = false;
    export let materialWidth: string;
    export let materialLabel: string;

    export let errorHighlight: boolean = false;

    export let icon: InputIcons = InputIcons.NONE;
    export let prependText: string = "";

</script>
{#if useMaterialDesign}
    <MaterialInputField bind:value {id} {placeholder} {onInputFinished} {fontProps} {borderless} {icon} {errorHighlight}
                        width={materialWidth} disabled={readOnly} label={materialLabel}
                        on:input on:focusout on:keydown on:change/>
{:else}
    {#if prependText}
        <span class="input-prepend-text" style="{getFontPropsVarString(fontProps)}">
            {prependText}
        </span>
    {/if}
    <input {id}  type="text"
           class="input-field-focus {icon !== InputIcons.NONE ? 'icon-' + icon.toString().toLowerCase().replace(/_/g, '-') : ''} {errorHighlight && !value ? 'input-field-error' : ''}"
           class:borderless style="width: {format.maxWidth}; max-width: {format.maxWidth}; {icon !== InputIcons.NONE ? 'padding-right: 2.25rem;' : ''} {getFontPropsVarString(fontProps)}"
           bind:value disabled={readOnly} placeholder={placeholder || null}
           on:blur={onInputFinished} on:focusout on:input on:keydown on:change>

{/if}

<style lang="scss">
  @import "../../styles/inputFields";
  @import "../../styles/icons.scss";

  input {
    height: 32px;
  }
</style>
