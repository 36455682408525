<script lang="ts">
    import {fetchUtils} from "../utils/fetchUtils";
    import {t} from "../../i18n/i18n";
    import {addNotification} from "../stores";
    import {NotificationType} from "../types/notification";
    import type {Lot} from "../types/lot";
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import DatePickerInput from "../atoms/DatePickerInput.svelte";
    import {getContext} from "svelte";
    import type {Writable} from "svelte/store";
    import type {Deal} from "../bid/types/deal";
    import type {Facts} from "../bid/types/facts";
    import type {PubPageParams} from "../bid/types/pubPageParams";

    const lot: Writable<Lot> = getContext("lot");
    const deal: Writable<Deal> = getContext("deal");
    const urlParams: PubPageParams = getContext("urlParams");

    export let readOnly: boolean = false;
    export let isOptimizer: boolean = false;

    let lotPublicId: string | undefined = urlParams?.lotPublicId;
    let targetFacts: Facts;
    let actualFacts: Facts;

    export function updatePositionDates() {
        let url;

        if (isOptimizer) {
            url = `/api/optimizer/public/deal/${$deal.publicId}/updateActualPositionDates`;
        } else {
            url = `/api/offerer/lot/${lotPublicId ?? $lot.publicId}/updateActualPositionDates`;
        }

        fetchUtils.post(url, {
            startDate: actualFacts.startAsIsoDate,
            endDate: actualFacts.endAsIsoDate,
        }).then(() => {
            let notification = {
                message: $t('UI.notification.saved'),
                type: NotificationType.SUCCESS,
                timeout: 4000,
                dismissible: false
            };
            addNotification(notification)
        })
            .catch(error => {
                let notification = {
                    message: $t("UI.error.save"),
                    type: NotificationType.ERROR,
                    timeout: 0,
                    dismissible: true
                };
                addNotification(notification);
                notification = {message: error, type: NotificationType.ERROR, timeout: 0, dismissible: true};
                addNotification(notification);
            });
    }

    // let changesStart = 0;
    // let changesEnd = 0;


    /*    function handleStartDateChanges() {
            // this is a workaround for a svelte bug that invalidates the expression in the condition though it didn't change
            // https://github.com/sveltejs/svelte/issues/7749
            if (changesStart < 3) {
                changesStart++;
                actualStart = positionDates.actualPositionDates?.startDate ? dayjs(positionDates.actualPositionDates.startDate, 'YYYY-MM-DD').unix() : null;
            }
        }

        function handleEndDateChanges() {
            // this is a workaround for a svelte bug that invalidates the expression in the condition though it didn't change
            // https://github.com/sveltejs/svelte/issues/7749
            if (changesEnd < 3) {
                changesEnd++;
                actualEnd = positionDates.actualPositionDates?.endDate ? dayjs(positionDates.actualPositionDates.endDate, 'YYYY-MM-DD').unix() : null;
            }
        }*/

    /** on changes to positionDates.actualPositionDates, we must trigger this reactive statement */
    // $: positionDates.actualPositionDates?.startDate, handleStartDateChanges();
    // $: positionDates.actualPositionDates?.endDate, handleEndDateChanges();


    $: $deal, targetFacts = $deal.dealFacts;
    $: $deal, actualFacts = isOptimizer ? $deal?.offerActualValues?.totalFacts : $deal?.requestActualValues?.totalFacts;
</script>

<div class="actual-comparison">
    <span>{$t('Project.timeframe')}</span>

    <div class="actual-comparison-grid">
        <div>{$t('Position')}</div>
        <div>{$t('UI.startDate.label')}</div>
        <div>{$t('UI.endDate.label')}</div>
    </div>
    <hr/>
    <div class="actual-comparison-grid even">
        <div>{$t('Position.should')}</div>
        {#if targetFacts?.start}
            <div>{targetFacts.startAsFormattedDate}</div>
        {:else}
            <div>&ndash;</div>
        {/if}
        {#if targetFacts?.end}
            <div>{targetFacts.endAsFormattedDate}</div>
        {:else}
            <div>&ndash;</div>
        {/if}
        {#if !targetFacts?.start && !targetFacts?.end}
            <div style="font-style: italic; width: max-content">{$t("UI.timeframe.noneGiven")}</div>
        {/if}
    </div>
    <div class="actual-comparison-grid">
        <div>{$t('Position.is')}</div>
        {#if readOnly}
            <div>{actualFacts.startAsFormattedDate}</div>
            <div>{actualFacts.endAsFormattedDate}</div>
        {:else}
            <DatePickerInput id="startDate" bind:date={actualFacts.start} label=""/>
            <DatePickerInput id="endDate" bind:date={actualFacts.end} label=""/>
        {/if}
    </div>
    {#if readOnly === false}
        <div style="float: right; margin: 20px auto">
            <PrimaryButton id="saveDatesBtn" label={$t('UI.button.save')} color={PrimaryBtnColors.CORPORATE_DESIGN}
                           on:click={updatePositionDates}/>
        </div>
    {/if}

</div>

<style lang="scss">
  @import "../../styles/global";

  .readOnly {
    display: none;
  }

  .actual-comparison {
    font-size: 14px;
    margin-top: 1.875rem;
    text-align: left;
    min-width: min-content;

    & > span {
      font-weight: bold;
      font-size: 16px;
    }

    .even {
      background-color: $bluish-grey-200;
    }

    & > hr {
      height: 2px;
      color: $primaryColor;
      opacity: 1;
      margin: 0 auto;
    }
  }

  .actual-comparison-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 80px) 100px repeat(2, 150px);
    grid-row-gap: 1.125rem;
    grid-column-gap: 3em;
    justify-content: center;
    align-items: center;
    margin: 0;

    & > div {
      width: max-content;
      margin: 12px;
    }

    .sum {
      color: $primaryColor;
    }

    & :global(.labeled-input-field) {
      margin: 12px 0;
    }
  }
</style>
