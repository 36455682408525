<script lang="ts">

    import {LotType} from "../types/enums";
    import {t} from "../../i18n/i18n";
    import type {Writable} from "svelte/store";
    import type {Lot} from "../optimizer/lot";
    import {getContext} from "svelte";
    const lot: Writable<Lot> = getContext("lot");

    export let name = "";
    // TODO qr potentially inline this, since we always use lot.zip + " " + lot.city
    export let address = "";
    export let showSaasData = false;
    export let saasOwnerName = "";
    export let tenantName = "";
    export let lotType: LotType | undefined = $lot?.type;

    let lotTypeText: string;
    // this distinction is just to differentiate between 'Ihr' and 'Ihre'
    const maleLotTypes = [LotType.MATERIAL_SALE, LotType.MATERIAL_NEED]
    $: lotType = $lot?.type
    $: lotType, lotTypeText = lotType ? ((maleLotTypes.includes(lotType)) ? 'Ihr ' : 'Ihre ') + `<b>${$t("LotType." + lotType)}</b>` : "Ihr Projekt";
</script>

<div class="salutation">
    {#if name !== ""}
        <h1>Hallo {name},</h1>
    {:else}
        <h1 class="salutation-large">Hallo,</h1>
    {/if}
    {#if showSaasData}
        <span class="salutation-small">hier eine Anfrage von {saasOwnerName} von {tenantName} in<span class="green salutation-small">&nbsp;{address}</span> für Sie:</span>
    {:else}
        <span class="salutation-small">hier {@html lotTypeText} in<span class="green salutation-small">&nbsp;{address}</span>:</span>
    {/if}
</div>

<style lang="scss">

  @import "../../styles/global";

  .salutation {
    @include flex-col($alignment: flex-start);
    padding-bottom: 1rem;
  }

  .salutation-small {
    @include roboto-font(1.875rem, 400, 1rem, black);
  }

  .green {
    @include roboto-font(1.875rem, 400, 1rem, $primaryColor);
  }

</style>