<script lang="ts">

    import {t} from "../../i18n/i18n";
    import Icon from "../atoms/Icon.svelte";
    import {mapFormControls} from "./fak/utils/fakStores";
    import expandableIcon from "@/icons/icon_expandable_closed.svg";

    const addProject = () => {
        $mapFormControls.level = 1;
    }
    const searchMatch = () => {
        //do something
        alert('Match suchen')
    }

    let actions = [
        {title: $t("UI.map.actions.createProject.title"), action: addProject}]
        // TODO when implementing the 'Match finden' component in svelte, then uncomment and use this
        // {title: $t("UI.map.actions.searchMatches.title"), description: longText, action: searchMatch}];

</script>

    <div class="content-container">
        {#each actions as action}
            <div class="action-container" on:click={action.action}>
                <span>{action.title}</span>
                <Icon src={expandableIcon} size="32"/>
            </div>
        {/each}
    </div>


<style lang="scss">
  @import "../../styles/global";

  .content-container {
    @include flex-col($alignment: flex-start);
    width: 100%;
    max-width: 420px;

    /* TODO uncomment when there are multiple actions involved
        & > div:first-child {
      padding: 1.625rem 7.25rem .75rem 2.125rem;
      border-radius: 10px 10px 0 0;
    }
    & > div:last-child {
      padding: .75rem 7.25rem 1.625rem 2.125rem;
      border-radius: 0 0 10px 10px;
    }*/
    & > div {
      border-radius: 10px;
      /* While using legacy components on map for "Match finden" etc. use this to fit the height*/
      padding: 1.281rem 1.313rem 1.281rem 2.125rem;
      height: 60px;
    }
  }

  .action-container {
    @include flex-row(0.125rem, $justify: space-between, $alignment: center);
    width: 100%;
    background-color: white;

    & > span:first-child {
      @include roboto-font(18.75px, 400, 1rem, black);
      text-align: left;
    }

    /*& > span:last-child {
      @include roboto-font(16px, 400, 0.75rem, $color: $grey-700);
      text-align: left;
    }*/

    &:hover {
      background-color: $grey-500;
      cursor: pointer;
    }
  }

</style>