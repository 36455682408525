<script lang="ts">
    import {localDateToUnixNumber, unixToLocalDate} from "../utils/formatters";

    export let title;
    export let startDate: string | number | undefined;
    export let endDate: string | number | undefined;
    let formattedStartDate: string = "";
    let formattedEndDate: string = "";

    $: if (startDate){
        formattedStartDate = typeof startDate === "number" ? unixToLocalDate(startDate) : unixToLocalDate(localDateToUnixNumber(startDate));
    }
    $: if (endDate) {
        formattedEndDate = typeof endDate === "number" ? unixToLocalDate(endDate) : unixToLocalDate(localDateToUnixNumber(endDate));
    }
</script>

<div class="container-header-value">
    <span>{title}</span>
    {#if formattedStartDate && formattedEndDate}
        <span>Von&nbsp;{formattedStartDate} bis&nbsp;{formattedEndDate}</span>
    {/if}
    {#if formattedStartDate && !formattedEndDate}
        <span>Ab&nbsp;{formattedStartDate}</span>
    {/if}
    {#if !formattedStartDate && formattedEndDate}
        <span>Bis&nbsp;{formattedEndDate}</span>
    {/if}
    {#if !formattedStartDate && !formattedEndDate }
        <span style="font-style: italic">Kein Zeitraum angegeben</span>
    {/if}
</div>

<style lang="scss">
  @import "../../styles/dataMapping";
</style>