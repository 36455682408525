<script lang="ts">
    import CardFlipper from "../utils/CardFlipper.svelte";
    import InfoCardSide from "./InfoCardSide.svelte";

    export let offererPage = false;
</script>

<CardFlipper let:flipped let:flipHandler let:isPlaceholder>
    <InfoCardSide {offererPage} {isPlaceholder} showBackside={flipped} on:flip={flipHandler}/>
</CardFlipper>
