<script lang="ts">
    /* https://svelte-modals.mattjennings.io/api */
    import {closeModal} from 'svelte-modals';
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import Icon from "../atoms/Icon.svelte";
    import {t} from "../../i18n/i18n";
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import {fly} from 'svelte/transition';
    import {ButtonIcons, RejectReason} from "../types/enums";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import type {AutocompleteFieldConfig} from "../types/autocompleteFieldConfig";
    import {AutocompleteConfig} from "../types/autocompleteFieldConfig";
    import {fetchUtils} from "../utils/fetchUtils";
    import {NotificationType} from "../types/notification";
    import {addNotification} from "../stores";
    import {onMount} from "svelte";
    import iconClose from "@/icons/icon_close.svg";


    const rejectReasonsOption = Object.values(RejectReason).map(function (x) {
        return {value: x, text: $t(("LotRejectReason." + x))};
    });
    const rejectReasonAcConfig: AutocompleteFieldConfig = AutocompleteConfig('value', 'text');

    // provided by <Modals/>
    export let isOpen: boolean;
    export let lotId: string;
    export let showCloseIcon: boolean = false;
    export let denyIcon: ButtonIcons = ButtonIcons.NONE;
    export let padding = "1.75rem 4.75rem";
    export let maxWidth = "100%";
    export let selectedObject: any;
    export let selectedValue: RejectReason;
    export let onDiscard;

    let url: string;
    let title: string;

    onMount(() => {
        title = $t("UI.lot.markRejected.modal.title");
        url = `/api/lot/${lotId}/markRejected`;
    });

    function confirm() {
        let request = {
            rejectReason: selectedValue,
        };
        fetchUtils.post(url, request)
            .then(data => {
                let notification = {
                    message: $t('UI.markRejected.notification.success'),
                    type: NotificationType.SUCCESS,
                    dismissible: true,
                    timeout: 5000
                };
                addNotification(notification);
                if (onDiscard !== undefined) {
                    onDiscard(data);
                }
                closeModal();
            })
            .catch(fetchUtils.catchErrorAndShowNotification());
    }
</script>

{#if isOpen}
    <div role="dialog" class="modal" in:fly="{{ y: -100, duration: 600 }}" out:fly={{y: -200, duration: 600}}>
        <div class="contents" style="--content-padding: {padding}; max-width: {maxWidth}">
            {#if showCloseIcon}
                <div class="cancel-btn" on:click={closeModal}>
                    <Icon src={iconClose} size="24"/>
                </div>
            {/if}
            <h1>{@html title}</h1>
            <div class="actual-content" style="row-gap: 0.938rem">
                <span>{@html $t("UI.markRejected.modal.message")}</span>
                <AutocompleteInputField bind:selectedObject bind:value={selectedValue}
                                        autocompleteConfig={rejectReasonAcConfig} options={rejectReasonsOption}
                                        label={$t("UI.rejectReason.autocomplete.ph")}/>
            </div>

            <div class="actions">
                <SecondaryButton label={$t('UI.button.cancel')} color={SecondaryBtnColors.RED} sizeAdaptingToText
                                 on:click={closeModal}/>
                <PrimaryButton label={$t("UI.lot.markRejected")} color={PrimaryBtnColors.RED} leftIcon={denyIcon}
                               disabled={selectedValue === null} sizeAdaptingToText
                               on:click={confirm}/>
            </div>
        </div>
    </div>
{/if}

<style lang="scss">
  @import "../../styles/modals"; // also includes global.scss
</style>
