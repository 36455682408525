import {object, string} from "yup";
import {translate} from "../../i18n/i18n";
import {hashString} from "../utils/utils";

export type AddressData = {
    city: string;
    zip: string;
    street: string;
    id?: string; // hash of the autocompletion suggestion
    text?: string;

}

export function initAddressData(): AddressData {
    const defaults = {
        city: "",
        zip: "",
        street: "",
        id: "",
        text: "",
    };

    return {
        ...defaults
    }
}

export function DefaultAddressData() {
    let address: AddressData = {city: "", street: "", zip: "", id: "", text: ""};
    return address;
}

export function copyAddressData(address: AddressData) {
    let copy: AddressData = DefaultAddressData();
    Object.assign(copy, address);
    return copy;
}

export function addressDataToString(addressData: AddressData) {
    return `${addressData.street}, ${addressData.zip} ${addressData.city}`
}

export function generateAddressDataId(address: AddressData) {
    let str = addressDataToString(address)
    return hashString(str);
}

export function getAddressDataAutocompleteText(addressData: AddressData) {
    return `${addressData.street} ${addressData.zip} ${addressData.city}`;
}

export const addressSchema = object({
    // id: string().notRequired(), not needed by server
    // text: string().notRequired().trim(), not needed by server
    city: string().required(translate('de', 'UI.validation.address.city.required', [])),
    zip: string().trim().required(translate('de', 'UI.validation.address.zip.required', []))
        .min(5, translate('de', 'UI.validation.address.zip.tooShort', []))
        .max(5, translate('de', 'UI.validation.address.zip.tooLong', []))
        .matches(/^\d{5}$/, translate('de', 'UI.validation.address.zip.onlyNumbers', [])),
    street: string().required(translate('de', 'UI.validation.address.street.required', []))
});