<script lang="ts">
    import Salutation from "../../atoms/Salutation.svelte";
    import InfoCard from "../../organisms/InfoCard.svelte";
    import ProjectInformationGrid from "../../project/ProjectInformationGrid.svelte";
    import TextArea from "../../atoms/TextArea.svelte";
    import {t} from "../../../i18n/i18n";
    import FileUpload from "../../organisms/FileUpload.svelte";
    import {NotificationType} from "../../types/notification";
    import {addNotification, userInfo, userIsLoggedIn} from "../../stores";
    import type {Lot} from "../lot";
    import {getContext, hasContext} from "svelte";
    import {LotState, OPTIMIZED_STATES_WITHOUT_DISCARDED} from "../../types/enums";
    import ActualTargetTimeFrame from "../../organisms/ActualTargetTimeFrame.svelte";
    import ActualTargetComparisonSaas from "../../organisms/ActualTargetComparisonSaas.svelte";
    import PublicPageHeader from "../../molecules/PublicPageHeader.svelte";
    import PublicPageFooter from "../../molecules/PublicPageFooter.svelte";
    import ProjectNavigationButtons from "../../molecules/ProjectNavigationButtons.svelte";
    import Basic from "../../templates/Basic.svelte";
    import {fetchRequestTenantLogo} from "../../corporateDesign/corporateDesignUtils";
    import type {UserInfo} from "../../types/userInfo";
    import type {PubPageParams} from "../../bid/types/pubPageParams";
    import {writable, type Writable} from "svelte/store";
    import {EmptyOffer, type Offer} from "../../bid/types/offer";
    import {RequestType} from "../../bid/types/requestDto";
    import {loadOfferAttachments} from "../../bid/offerUtils";
    import type {ContactData} from "../../types/contactData";
    import type {Deal} from "../../bid/types/deal";
    import {NextButtonWantedObject} from "../../bid/types/nextButtonDto";

    const urlParams: PubPageParams | null = getContext("urlParams") || null;
    const lot: Writable<Lot> = getContext("lot");
    const offerMaterial: Writable<Offer> = hasContext("offerMaterial")
        ? getContext("offerMaterial") : writable(EmptyOffer(RequestType.MATERIAL));
    const offerMaterialWithTransport: Writable<Offer> = hasContext("offerMaterialWithTransport")
        ? getContext("offerMaterialWithTransport") : writable(EmptyOffer(RequestType.MATERIAL_WITH_TRANSPORT));
    const deal: Writable<Deal> = getContext("deal");

    export let optimizerContactData: ContactData;

    let anonymizeAddress: boolean = true;
    let currentUserInfo: UserInfo;
    let isDifferentTenant: boolean = false;

    if ($lot.state === LotState.OPTIMIZED) {
        let notification = {
            message: $t('UI.Accepted.successful'), type: NotificationType.SUCCESS, timeout: 0, dismissible: false
        };
        addNotification(notification);
    }

    let attachmentsUploadUrl: string;
    let attachmentsOffer: any[] = [];
    let logo: string = "";

    async function fetchLogo() {
        logo = await fetchRequestTenantLogo(urlParams?.requestPublicId) ?? "";
    }

    userInfo.subscribe(value => currentUserInfo = value);

    $: {
        if ($lot) {
            anonymizeAddress = !($lot.state === LotState.HANDLING
                || $lot.state === LotState.ACCOUNTING
                || $lot.state === LotState.COMPLETED);
        }
        if ($offerMaterial.publicId || $offerMaterialWithTransport.publicId) {
            attachmentsUploadUrl = `/api/offer/${$offerMaterial?.publicId || $offerMaterialWithTransport?.publicId}/attachment/new`
            // at the moment, we only need to load attachments of one offer, since we sync them for both offers, will be adapted later
            loadOfferAttachments($offerMaterial?.publicId || $offerMaterialWithTransport?.publicId, urlParams)
                .then(data => {
                    attachmentsOffer = data;
                });
        }
    }

    $: if (currentUserInfo?.tenantName && $lot?.tenantName) {
        isDifferentTenant = currentUserInfo.tenantName !== $lot.tenantName;
    }

    $: if ($lot.isSaasInternalLot && urlParams?.requestPublicId) {
        fetchLogo();
    }
</script>

{#if !$userIsLoggedIn || isDifferentTenant}
    <PublicPageHeader requestPublicId={urlParams?.requestPublicId}/>
{/if}

<Basic showStandardHeaderFooter={$userIsLoggedIn && !isDifferentTenant}>
    {#if $lot.isSaasInternalLot && !isDifferentTenant && $userIsLoggedIn && logo}
        <div class="pp-logo-top">
            <img alt="Logo" src={logo}/>
        </div>
    {/if}
    <Salutation name={optimizerContactData.fullName} address={$lot.address.zip + " " + $lot.address.city}
                saasOwnerName={$lot.ownerName} tenantName={$lot.tenantName} showSaasData/>
    <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12">
            <InfoCard/>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12">
            <div class="container-project-information">
                <ProjectInformationGrid {anonymizeAddress} title={$t('UI.project.information')}
                                        {...$lot}/>
                <TextArea id="materialDetailsComment" title={$t("UI.materialDetailsComment.header")}
                          text={$lot.lotRemarks}
                          placeholder={$t('UI.remarks.noRemarks')} readonly/>
                {#if OPTIMIZED_STATES_WITHOUT_DISCARDED.includes($lot.state)}
                        <TextArea title={$t("UI.projectDetailsComment.header")} text={$lot.projectRemarks}
                                  placeholder={$t('UI.remarks.noRemarks')} readonly isHtml/>
                {:else}
                        <TextArea title={$t("UI.Model.moreDetails")} text={$offerMaterial.furtherDetailsComment}
                                  placeholder={$t('UI.remarks.noRemarks')} readonly/>
                {/if}
            </div>
        </div>
    </div>
    {#if OPTIMIZED_STATES_WITHOUT_DISCARDED.includes($lot.state)}
        <div class="row">
            <div class="col-lg-12 col-sm-12">
                    <TextArea title={$t("UI.Model.moreDetails")} text={$offerMaterial.furtherDetailsComment}
                              placeholder={$t('UI.remarks.noRemarks')} readonly/>
            </div>
        </div>
    {/if}
    <div class="row" style="margin-top: 1.875rem">
        <div class="col-lg-12 col-sm-12">
            <FileUpload uploadUrl={attachmentsUploadUrl}
                        filePrefix={$t('UI.attachments.optimizer.prefix') + currentUserInfo.tenantName + '_' + currentUserInfo.userLogin + '-'}
                        visibleToCustomer={0} visibleToPartner={1}
                        updateFun={() => loadOfferAttachments($offerMaterial.publicId || $offerMaterialWithTransport.publicId, urlParams).then(data => attachmentsOffer = data)}
                        attachments={attachmentsOffer}/>
        </div>
    </div>
    {#if $lot.state === LotState.ACCOUNTING || $lot.state === LotState.COMPLETED}
        <div class="row">
            <div class="col-lg-12 col-sm-12">
                <ActualTargetComparisonSaas readOnly={$lot.state !== LotState.ACCOUNTING} isOptimizer/>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-sm-12">
                <ActualTargetTimeFrame readOnly={$lot.state !== LotState.ACCOUNTING} isOptimizer/>
            </div>
        </div>
    {/if}
    {#if $userIsLoggedIn}
        <div class="row">
            <div class="col-lg-12 col-sm-12">
                <ProjectNavigationButtons currentId={$deal.publicId} wantedObject={NextButtonWantedObject.DEAL}
                                          returnUrl="/tenantlots/won" isSaas/>
            </div>
        </div>
    {/if}
</Basic>

{#if !$userIsLoggedIn || isDifferentTenant}
    <PublicPageFooter/>
{/if}

<style lang="scss">
  @import "../../../styles/pubPage";

  .columns {
    @include flex-col(1.875rem, $alignment: flex-end);
    width: 100%;
  }
</style>
