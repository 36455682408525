<script lang="ts">
    import {t} from "../../i18n/i18n";
    import {fly, scale} from 'svelte/transition';
    import Icon from "../atoms/Icon.svelte";
    import iconClose from "@/icons/icon_close.svg";
    import {inputFieldFormats} from "../utils/formatters";
    import {ButtonIcons, InputIcons} from "../types/enums";
    import InputField from "../molecules/InputField.svelte";
    import {debounce} from "../utils/utils";
    import SecondaryButton from "../atoms/SecondaryButton.svelte";
    import type {HelpEntry} from "./types/helpEntry";
    import MmLoadingAnimation from "../animations/MmLoadingAnimation.svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import HelpEntryCard from "./HelpEntryCard.svelte";
    import {jwt} from "../utils/jwt";
    import {onMount} from "svelte";

    export let open: boolean = false;
    let searchString: string = "";
    let allHelpEntries: HelpEntry[] = [];
    let filteredHelpEntries: HelpEntry[] = [];

    let userFirstname: string;
    let bottomCard: HTMLElement;
    let helpBubble: HTMLElement;

    let debounceTimer = 5000;
    let searchEntriesDebounced = debounce(searchEntries);
    let clickHandlerDebounced: () => void;
    $: clickHandlerDebounced = debounce(bubblePulse, debounceTimer);

    function searchEntries() {
        if (searchString.length > 0) {
            filteredHelpEntries = allHelpEntries.filter(e => {
                let search = searchString.trim().toLowerCase();
                return e.headline.toLowerCase().includes(search)
                    || e.description.toLowerCase().includes(search)
                    || e.searchStrings.some(t => t.toLowerCase().includes(search));
            });
        } else {
            filteredHelpEntries = allHelpEntries;
        }
    }

    async function fetchHelpEntries() {
        allHelpEntries = [];
        filteredHelpEntries = [];
        await fetchUtils.post("/api/helpEntries/", {origin: window.top?.location.pathname}, false)
            .then(data => {
                allHelpEntries = data;
                filteredHelpEntries = data;
            })
            .catch(e => {
                // Ignore, we just show the general (bottom) card
            })
    }

    function onSearchKeyPress(e: KeyboardEvent) {
        if (e.key === "Enter" && filteredHelpEntries.length === 0) {
            scrollToBottomCard();
        }
    }

    function scrollToBottomCard() {
        bottomCard.scrollIntoView({behavior: "smooth"});
        bottomCard.style.backgroundColor = "#D9D9D9";
        setTimeout(() => bottomCard.style.backgroundColor = "#FFF", 200);
    }

    async function bubblePulse() {
        debounceTimer += 5000;
        if (helpBubble && helpBubble.style) {
            for (let i = 0; i < 3; i++) {
                helpBubble.style.transform = "scale(1.2)";
                await new Promise(r => setTimeout(r, 200));
                helpBubble.style.transform = "scale(1)";
                await new Promise(r => setTimeout(r, 300));
                helpBubble.style.transform = "scale(1.2)";
                await new Promise(r => setTimeout(r, 200));
                helpBubble.style.transform = "scale(1)";
                await new Promise(r => setTimeout(r, 1500));
            }
        }
        clickHandlerDebounced();
    }

    jwt.subscribe((claims: any) => {
        if (claims && claims.ufirstname) {
            userFirstname = claims.ufirstname;
        }
    });

    onMount(() => {
        window.addEventListener("iframeActivity", clickHandlerDebounced);
        window.addEventListener("click", clickHandlerDebounced);
        window.addEventListener("touchstart", clickHandlerDebounced);
        window.addEventListener("keydown", clickHandlerDebounced);
        clickHandlerDebounced();
    });

</script>

<div class="help-modal-fixed">
    {#if !open}
        <button class="help-bubble" bind:this={helpBubble} on:click={() => open = true}
                in:scale={{ duration: 100, delay: 500 }}>
            <!-- HelpBubble icon -->
            <svg width="70" height="36" viewBox="0 0 70 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M67 1H3C1.89543 1 1 1.89543 1 3V25.338C1 26.4426 1.89543 27.338 3 27.338H43.5497L56.2748 35V27.338H67C68.1046 27.338 69 26.4426 69 25.338V3C69 1.89543 68.1046 1 67 1Z"
                      fill="white" stroke="#58C079"/>
            </svg>
            <span>{$t("UI.help.bubble")}</span>
        </button>
    {:else}
        <div class="help-modal open" transition:fly={{ duration: 500, x: 100 }}>
            <div class="help-modal-close">
                <Icon src={iconClose} size={24} clickable on:click={() => open = false}/>
            </div>
            <div class="help-modal-content">
                    <span class="help-modal-header">
                        {$t("UI.help.modal.header") + (userFirstname ? " " + userFirstname : "") + "!"}<br>
                        {$t("UI.help.modal.subheader")}
                    </span>
                <div class="help-modal-search-row">
                    <InputField bind:value={searchString} label="{$t('UI.help.search.label')}"
                                format={inputFieldFormats.FULL} icon={InputIcons.SEARCH_LENS}
                                containerWidth="24.5625rem" placeholder={$t('UI.placeholder.search')}
                                on:input={searchEntriesDebounced} on:keydown={onSearchKeyPress}/>
                    <SecondaryButton label={$t("UI.help.button.mineralMinds")}
                                     width="8.875rem" paddingHorizontal="0"
                                     on:click={() => window.open("https://mineral-minds.de", "_blank")}/>
                    <SecondaryButton leftIcon={ButtonIcons.PHONE} width="2rem" paddingHorizontal="0"
                                     on:click={scrollToBottomCard}/>
                </div>
                <div class="help-modal-entry-list">
                    {#await fetchHelpEntries()}
                        <MmLoadingAnimation/>
                    {:then {}}
                        {#each filteredHelpEntries.sort((a, b) => a.priority - b.priority) as helpEntry}
                            <HelpEntryCard bind:helpEntry/>
                        {/each}
                        <div bind:this={bottomCard} class="help-bottom-entry">
                            <span>{$t("UI.help.label.noSolutionFound")}</span>
                            <div class="help-bottom-entry-contact-info">
                                <span>{$t("UI.Model.phone")}: <a
                                        href="tel:{$t('UI.MineralMinds.internationalPhoneNr')}">{$t('UI.MineralMinds.phoneNr')}</a><br>
                                    {$t("UI.Model.email")}: <a
                                            href="mailto:{$t('MM.mail.email')}">{$t('MM.mail.email')}</a></span>
                                <button class="help-bottom-entry-social"
                                        on:click={() => window.open("https://linkedin.com/company/mineral-minds", "_blank")}>
                                    <i class='bx bxl-linkedin-square'></i>
                                </button>
                            </div>
                            <span class="help-bottom-entry-legal">
                                <a href="https://mineral-minds.de/impressum">{$t('Imprint')}</a>
                                &bull; <a href="/agb">{$t('TermsAndConditions')}</a>
                                &bull; <a href="https://mineral-minds.de/datenschutzerklaerung">{$t('Privacy')}</a>
                            </span>
                        </div>
                    {/await}
                </div>
            </div>
        </div>
    {/if}
</div>


<style lang="scss">
  @import "../../styles/global";
  @import "../../styles/svg";

  .help-modal-fixed {
    position: fixed;
    bottom: 4.5rem;
    right: 2.25rem;
    z-index: 1000003; // highest value of platform
  }

  .help-bubble {
    @include flex-row();
    position: absolute;
    bottom: 0;
    right: 0;
    width: 4.25rem;
    height: 2.125rem;
    background: none;
    border: none;
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: ease-out;
    cursor: pointer;
    filter: drop-shadow(0 0 0.313rem rgba(0, 0, 0, 0.25));
    -webkit-filter: drop-shadow(0 0 0.313rem rgba(0, 0, 0, 0.25));

    &:hover {
      transform: scale(1.3);
    }

    & > svg > path {
      stroke: $primaryColor;
    }

    & > span {
      @include roboto-font(0.75rem, 400, 0.75rem);
      display: block;
      margin-bottom: 10%;
      transition: font-size 100ms ease-out;
      position: absolute;
    }
  }

  .help-modal {
    max-width: 39.875rem;
    height: 32.25rem;
    border-radius: 0.125rem;
    background: #FFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  }

  .help-modal-close {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
  }

  .help-modal-content {
    @include flex-col($row-gap: 0.75rem, $alignment: normal, $justify: normal);
    width: 100%;
    height: 100%;
    padding-top: 1.5rem;
  }

  .help-modal-header {
    @include roboto-font(1.5rem, 500, 1.25rem, $primaryColor);
    padding-left: 1.31rem;
  }

  .help-modal-search-row {
    @include flex-row($col-gap: 0.37rem, $alignment: flex-end, $justify: normal);
    padding-left: 1.31rem;
    padding-right: 1.31rem;
  }

  .help-modal-entry-list {
    @include flex-col($row-gap: 0.38rem, $alignment: normal, $justify: normal);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0.5rem 0.81rem 0.5rem 1.31rem;
    margin-right: 0.317rem;
    margin-bottom: 1.563rem;

    &::-webkit-scrollbar {
      width: 20px;
      margin: 2px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px $bluish-grey-200;
      border: solid 8px transparent;
      border-radius: 20px;
      background-clip: padding-box;

    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px $primaryColor;
      border: solid 8px transparent;
      border-radius: 20px;
      background-clip: padding-box;
      min-height: 20px;
    }
  }

  .help-bottom-entry {
    @include flex-col($row-gap: 0.38rem, $alignment: normal, $justify: normal);
    background-color: #FFF;
    border: none;
    position: relative;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    max-width: 36.1875rem;
    padding: 1.37rem 2.31rem 1.37rem 1.31rem;
    transition: background-color ease-out 200ms;

    & > span:first-child {
      @include roboto-font(1.125rem, 400, 0.875rem, $primaryColor);
    }
  }

  .help-bottom-entry-contact-info {
    @include flex-row($alignment: flex-end, $justify: space-between);
    line-height: 1.125rem;

    & > span {
      @include roboto-font(1.125rem, 400, 0.625rem);

      & > a {
        @include roboto-font(1.125rem, 400, 0.625rem, $primaryColor);
      }
    }
  }

  .help-bottom-entry-legal {
    @include roboto-font(1.125rem, 400, 0.625rem);

    & > a {
      @include roboto-font(1.125rem, 400, 0.625rem, $primaryColor);
    }
  }

  @media (max-width: 39.875rem) {
    .help-modal-fixed {
      right: 1rem;
      left: 1rem;
    }

    .help-modal {
      width: 100vw;
      padding-right: 1.31rem;
    }

    .help-modal-close {
      right: 0;
    }
  }

  .help-bottom-entry-social {
    border: none;
    width: 20px;
    height: 20px;
    background: #fff;

    & > .bxl-linkedin-square {
      font-size: 1.25rem;
      color: $primaryColor;
    }
  }

</style>
