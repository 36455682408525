<script lang="ts">

    import {createEventDispatcher, getContext} from "svelte";
    import type {Writable} from "svelte/store";
    import type {MaterialProperty, MaterialPropertyListElement} from "./types/materialProperty";
    import {DefaultMaterialProperty} from "./types/materialProperty";
    import {t} from "../../i18n/i18n";
    import ActionMenu from "../organisms/ActionMenu.svelte";
    import ActionMenuItem from "../molecules/ActionMenuItem.svelte";
    import {closeModal, openModal} from "svelte-modals";
    import MaterialPropertyModal from "./MaterialPropertyModal.svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import {DefaultNotification, NotificationType} from "../types/notification";
    import {addNotification} from "../stores";
    import Modal from "../organisms/Modal.svelte";
    import type {Filter} from "../utils/filter";
    import {applyFilters} from "../utils/filter";
    import {changeUrl} from "../utils/misc";
    import iconTrashRed from "@/icons/icon_trash_red.svg";

    let properties: Writable<Map<string, MaterialProperty>> = getContext('materialProperties');
    let materialPropertyParents: Writable<Map<string, string[]>> = getContext('materialPropertyParents')
    const dispatch = createEventDispatcher();
    export let filters: Filter[] = [];


    function selectProperty(property: MaterialProperty) {
        openModal(MaterialPropertyModal, {
            materialProperty: {...DefaultMaterialProperty(), ...$properties.get(property.id)},
            materialId: undefined,
            isNew: false,
            parent: null,
        });
        changeUrl(`materialProperty/${property.id}`, window);
    }

    function handleDelete(property) {
        openModal(Modal, {
            title: $t('UI.mmpv.delete.modal.title', {entity: property.name}),
            message: $t('UI.mmpv.delete.property.modal.message', {property: property.name}),
            denyText: $t('UI.delete'),
            onDeny: () => {
                deleteProperty(property);
                closeModal();
            }
        })
    }

    async function deleteProperty(property: MaterialProperty) {
        fetchUtils.post(`/api/property/remove`, property)
            .then(_ => {
                // TODO remove property from parents list
                // first remove property in store
                $properties.delete(property.id);
                // also remove propertyIds in propertyChildIds of all list elements
                $properties.forEach(storeProperty => {
                    if (storeProperty.propertyListElements !== undefined) {
                        storeProperty.propertyListElements.forEach((propertyListElement: MaterialPropertyListElement) => {
                            if (propertyListElement.propertyChildIds !== undefined) {
                                let indexOfRemovedPropertyId = propertyListElement.propertyChildIds.indexOf(property.id)
                                if (indexOfRemovedPropertyId > -1) {
                                    propertyListElement.propertyChildIds.splice(indexOfRemovedPropertyId, 1)
                                }
                            }
                        })
                    }
                })
                $properties = $properties;
                // remove property in all nodes
                dispatch('updateProperties', {propertyId: property.id})
                let notification = DefaultNotification($t('UI.mmpv.materialProperty.deleted.notification.success'), NotificationType.SUCCESS)
                addNotification(notification);
            })
            .catch(fetchUtils.catchErrorAndShowNotification());
    }

    function getParentsNamesAsString(propertyId: string): string {
        return $materialPropertyParents.has(propertyId) ? $materialPropertyParents.get(propertyId).map(id => $properties.get(id).name).join(',') : "";
    }
</script>

<div class="properties-container">
    <div class="properties-table">
        <div class="row-wrapper">
            <div>{$t('UI.mmpv.properties.name')}</div>
            <div>{$t('UI.mmpv.properties.type')}</div>
            <div>{$t('UI.mmpv.properties.flags')}</div>
            <div>{$t('UI.mmpv.properties.links')}</div>
            <div>{$t('UI.mmpv.catalogs.action')}</div>
        </div>
        {#each [...$properties.values()].sort((a, b) => a.priority - b.priority).sort((a, b) => (a.type > b.type) ? 1 : ((b.type > a.type) ? -1 : 0)).filter(prop => applyFilters(filters, prop)) as property (property.id)}
            <!--sorting by priority and type -->
            {@const flags = property.flags.map(f => $t(`PropertySystemFlag.${f.toString()}`)).sort().join(', ')}
            {@const propertyParents = getParentsNamesAsString(property.id)}
            <div class="row-wrapper" on:click={() => selectProperty(property)}>
                <div title="{property.name || ''}">{property.name || ''}</div>
                <div>{$t('UI.mmpv.properties.types.' + property.type) || ''}</div>
                <div>{property.flags.length > 0 ? flags : ""}</div>
                <div>{propertyParents}</div>
                <div style="position: relative">
                    <ActionMenu titleAttr={$t('UI.mmpv.actionMenu.titleAttribute')}>
                        <svelte:fragment slot="content">
                            <ActionMenuItem label={$t('UI.delete')} iconSrc={iconTrashRed}
                                            on:click={() => handleDelete(property)}/>
                        </svelte:fragment>
                    </ActionMenu>
                </div>
            </div>
        {/each}
    </div>
</div>


<style lang="scss">
  @import "../../styles/global";

  .properties-container {
    @include flex-col($justify: flex-start);
    width: 100%;
  }

  .properties-table {
    display: grid;
    grid-template-columns: minmax(150px, 0.5fr) minmax(150px, 0.33fr) repeat(2, minmax(150px, 0.5fr)) minmax(60px, 0.15fr);
    grid-template-rows: 1fr; /*remove for unequal rows */
    @include roboto-font(18px, 400, 0.75rem, black);
    width: 100%;

    & .row-wrapper {
      display: contents;

      & > div {
        text-align: start;
        border-bottom: 1px solid $grey-600;
        padding: 0.75rem 0.75rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:last-child {
          @include flex-row($justify: center);
        }

        &:last-child {
          overflow: visible;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &:first-child > div {
        border-bottom: 1px solid $primaryGreen;
        @include roboto-font(18px, 400, 0.875rem, black);

      }
    }
  }
</style>
