<script lang="ts">
    import {lotStateUtils} from "../utils/lotStateUtils";
    import type {Lot} from "../../types/lot";
    import {DefaultLot} from "../../types/lot";
    import Icon from "../../../atoms/Icon.svelte";
    import {LotState} from "../../../types/enums";
    import {t} from "../../../../i18n/i18n";
    import {Motion} from "svelte-motion";
    import {addNotification} from "../../../stores";
    import {fetchUtils} from "../../../utils/fetchUtils";
    import {adjustMargin} from "../utils/fakUtils";
    import {currentLot, currentProject, localLotsStore} from "../utils/fakStores";
    import closeIcon from "@/icons/icon_expandable_closed.svg";
    import {closeModal, openModal} from "svelte-modals";
    import Modal from "../../../organisms/Modal.svelte";
    import {NotificationType} from "../../../types/notification";

    const headerMotionVariants = {
        visible: {
            opacity: 1,
            transition: {
                duration: .3,
            }
        },
        hidden: {
            opacity: 0,
        }
    }

    export let lot: Lot = DefaultLot();

    let hovering: boolean = false;
    let stateColor: string = "#000000";
    let hfBackground: string = "#D9D9D9";
    let stateText: string = "";

    function handleCancelMouseOver() {
        hovering = true;
        lot.state = lot.state;
    }

    function handleCancelMouseOut() {
        hovering = false;
        lot.state = lot.state;
    }

    function onLotSelected() {
        $currentLot = $currentLot?.id !== lot.id ? $localLotsStore.get(lot.id) : null;
        adjustMargin($currentLot, true);
    }

    function duplicateModal() {
        openModal(Modal, {
            title: $t("UI.map.lot.duplicate.modal.title"), message: $t("UI.map.lot.duplicate.modal.message"),
            acceptText: $t("UI.button.next"), onAccept: () => {
                closeModal();
                duplicateLot();
            }
        })
    }

    function duplicateLot() {
        fetchUtils.get(`/api/lot/${lot.id}/duplicate`)
            .then(data => {
                $localLotsStore.set(data.id, data);
                let notification = {
                    message: $t('UI.map.lot.duplicate.successful'),
                    type: NotificationType.SUCCESS,
                    dismissible: true,
                    timeout: 4500
                };
                addNotification(notification);
                $currentLot = $localLotsStore.get(data.id);
                $localLotsStore = $localLotsStore;
            })
            .catch(fetchUtils.catchErrorAndShowNotification());
    }

    function createStateInfo(): string {
        let text: string = $t("UI.map.lot.state") + " " + $t(("LotState." + lot.state)).toUpperCase();

        if (lot.state === LotState.BIDDING) {
            if (lot.optimizerMailSent) {
                text += " " + $t("Lot.optimizerMailSentInformation");
            } else if (lot.optimizerMailScheduled) {
                text += " " + $t("Lot.optimizerMailScheduled");
            }
        }
        return text;
    }

    $: stateColor = lot.state === LotState.HANDLING && (($currentLot && lot.id === $currentLot.id) || hovering) ? "#DADA00" : lotStateUtils.getStateColor(lot.state);
    $: if (!hovering && (!$currentLot || $currentLot.id !== lot.id)) {
        hfBackground = "#D9D9D9";
    } else {
        hfBackground = lotStateUtils.getStateBgColor(lot.state);
    }
    $: lot.state, stateText = createStateInfo();
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<li id="lot-{lot.id}" style="padding-bottom: 0.625rem;">
    <div class="lot-header-container" on:mouseover={handleCancelMouseOver} on:mouseout={handleCancelMouseOut}>
        <div class="lot-header" style="height: {$currentLot?.id === lot.id ? '8.0625' : '3.125'}rem"
             on:click={onLotSelected}>
            <div class="lot-header-title">
                <Icon src="{lotStateUtils.getStateIconPath(lot.state)}" size={28}/>
                <span class="lot-header-span">{lot.label}</span>
                <div style="transform: rotate({$currentLot?.id === lot.id ? '90deg' : '0'}); transition: transform .4s ease-out;">
                    <Icon src={closeIcon} size={28} clickable/>
                </div>
            </div>
            <div class="lot-header-comment"
                 style="opacity: {$currentLot?.id === lot.id ? '1' : '0'}; height: {$currentLot?.id === lot.id ? '4.94625rem' : '0'}">
                <span class="lot-header-comment-title">Positionskommentar</span>
                <span class="lot-header-comment-span"
                      style="{lot.remarks && lot.remarks.length > 0 ? '' : 'color: #A5A6A9;'}">{lot.remarks && lot.remarks.length > 0 ? lot.remarks : $t('UI.map.lot.comment.noComment')}</span>
            </div>
        </div>
        <div class="lot-header-footer" style="--hf-background:{hfBackground};">
            <span class="lot-header-state" style="color: {stateColor}">
                {@html stateText}
            </span>
            {#if !$currentProject.isSaasProject}
                <div style="position: relative;" on:click={duplicateModal}>
                    <Motion initial="visible"
                            animate={hovering ? 'hidden' : 'visible'}
                            variants={headerMotionVariants}
                            let:motion>
                        <span use:motion class="lot-header-code" style="position: absolute;">{lot.code}</span>
                    </Motion>
                    <Motion initial="hidden"
                            animate={hovering ? 'visible' : 'hidden'}
                            variants={headerMotionVariants}
                            let:motion>
                        <div use:motion class="copy-lot-container">
                            <!-- We want to change the color of the copy icon depending on the state.
                                 Therefore we need to include the svg directly instead of using an img tag. -->
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="icon_copy_24" width="12"
                                 height="12">
                                <defs>
                                    <style>.e {
                                        fill: none;
                                    }
                                    </style>
                                </defs>
                                <g id="a"/>
                                <g id="b">
                                    <g id="c">
                                        <g id="d">
                                            <rect class="e" width="24" height="24"/>
                                            <g>
                                                <path style="fill: {stateColor}"
                                                      d="M3,16c-.55,0-1-.45-1-1V3.01c0-.55,.45-1,1-1H12c.55,0,1,.45,1,1s-.45,1-1,1H4V15c0,.55-.45,1-1,1Z"/>
                                                <path style="fill: {stateColor}"
                                                      d="M20,8.01v12H10V8.01h10m0-2H10c-1.1,0-2,.9-2,2v12c0,1.1,.9,2,2,2h10c1.1,0,2-.9,2-2V8.01c0-1.1-.9-2-2-2h0Z"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span class="copy-lot-span" style="color:{stateColor}">Position duplizieren</span>
                        </div>
                    </Motion>
                </div>
            {:else}
                <div style="position: relative;">
                    <span class="lot-header-code">{lot.code}</span>
                </div>
            {/if}
        </div>
    </div>
</li>

<style lang="scss">
  @import "../../../../styles/global";

  .lot-header-container {
    border-radius: 0.25rem;
    background: #FFFFFF;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }
  }

  .lot-header {
    transition: height 0.3s ease-out;
    display: flex;
    flex-direction: column;
    padding-left: 1.44rem;
    padding-right: 0.75rem;
  }

  .lot-header-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.12rem;
  }

  .lot-header-span {
    @include roboto-font(0.875rem, 400, 0.625rem);
    max-width: 21.375rem;
    flex-grow: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .lot-header-comment {
    display: flex;
    flex-direction: column;
    row-gap: 0.63rem;
    transition-property: opacity, height;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  .lot-header-comment-title {
    @include roboto-font(normal, 400, 0.75rem);
  }

  .lot-header-comment-span {
    @include roboto-font(0.875rem, 400, 0.625rem);
    height: 2.375rem;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-right: 0.68rem;
  }

  .lot-header-footer {
    background: var(--hf-background); /*#D9D9D9;*/
    @include roboto-font(0.75rem, 500, 0.625rem);
    height: 1.375rem;
    transition-property: background-color;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 3.69rem;
    padding-right: 0.75rem;
  }

  .lot-header-state {
    @include roboto-font(normal, 500, 0.625rem);
  }

  .lot-header-code {
    @include roboto-font(normal, 500, 0.625rem, #9E9E9E);
  }

  .copy-lot-container {
    display: flex;

    & > svg {
      margin-right: 0.19rem;
    }
  }

  .copy-lot-span {
    @include roboto-font(normal, 500, 0.625rem);
  }
</style>