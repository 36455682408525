<script lang="ts">
    // either specify width and height or size
    import {onMount} from "svelte";

    export let id: string = "";
    export let size: number = 16;
    export let height: number = size;
    export let width: number = size;
    export let src: string;
    export let alt: string = "";
    export let clickable: boolean = false;
    export let allowTransition: boolean = true;
    export let titleAttr: string = ""; // adds a default browser tooltip with the label

    let icon: HTMLImageElement;
    let loaded = false;

    onMount(() => {
        if (icon) {
            icon.onload = () => {
                loaded = true;
            };
            icon.src = src;
        }
    });

    $: if (src) {
        loaded = false;
        if (icon) {
            icon.src = src;
        }
    }

    $:{
        if (size && !height) {
            height = size;
        }
        if (size && !width) {
            width = size;
        }
    }
</script>

<img bind:this={icon} class:loaded class:allowTransition {id} {alt} {width} {height} title={titleAttr} on:click
     style="cursor: {clickable ? 'pointer' : 'auto'}"/>

<style lang="scss">
  img.allowTransition {
    opacity: 0;
    transition: opacity 500ms ease-out;

    &.loaded {
      opacity: 1;
    }
  }
</style>
