<script lang="ts">
    import { fade } from 'svelte/transition';
    export let data: any;
    export let delay = 0;
</script>

{#if data}
        <div transition:fade={{ delay: delay, duration: 100 }} style="width: 100%;">
            <slot />
        </div>
{/if}