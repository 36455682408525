import {WhitelistView} from "./whitelist";
import {t} from "../../i18n/i18n";
import {LotType} from "../types/enums";
import {get} from "svelte/store";


export const whitelistModalUtils = {
    getLotTypeTTipMsg,
    getFirstActionButtonText,
    showSecondActionButton,
    showThirdActionButton,
    showFourthActionButton,
    getBreadcrumbTitleMaterialType,
    getPrefix,
    getLotTypeStringResource
};

function getLotTypeStringResource(selectedLotType: LotType): string {
    let resName = `UI.LotType.${selectedLotType.toString()}`;
    if (selectedLotType === LotType.RETURN_FREIGHT) {
        return get(t)(resName + '.new');
    }
    return get(t)(resName);
}

function getBreadcrumbTitleMaterialType(selectedLotType: LotType): string {
    let res = getLotTypeStringResource(selectedLotType);
    return get(t)('UI.whitelist.modal.breadcrumb.materialTypeTitle', {materialType: res});
}

function getLotTypeTTipMsg(lotType: LotType): string {
    switch (lotType) {
        case LotType.MATERIAL_SALE:
            return get(t)('UI.whitelist.modal.utils.ttipMsg.MS');
        case LotType.MATERIAL_NEED:
            return get(t)('UI.whitelist.modal.utils.ttipMsg.MN');
        case LotType.BACKFILL:
            return get(t)('UI.whitelist.modal.utils.ttipMsg.B');
        case LotType.DISPOSAL:
            return get(t)('UI.whitelist.modal.utils.ttipMsg.D');
        case LotType.RETURN_FREIGHT:
            return get(t)('UI.whitelist.modal.utils.ttipMsg.RF');
    }
}

function getFirstActionButtonText(view: WhitelistView): string {
    switch (view) {
        case WhitelistView.START_PAGE:
            return get(t)('UI.whitelist.modal.startpage.primaryButton');
        case WhitelistView.MATERIAL_TYPE:
        case WhitelistView.OVERVIEW:
            return get(t)('UI.button.next');
        case WhitelistView.FINISHED:
            return get(t)('UI.button.close');
    }
}

function showSecondActionButton(view: WhitelistView): boolean {
    switch (view) {
        case WhitelistView.START_PAGE:
            return true;
        case WhitelistView.MATERIAL_TYPE:
        case WhitelistView.OVERVIEW:
        case WhitelistView.FINISHED:
            return false;
    }
}

function showThirdActionButton(view: WhitelistView): boolean {
    switch (view) {
        case WhitelistView.START_PAGE:
        case WhitelistView.FINISHED:
            return false;
        case WhitelistView.MATERIAL_TYPE:
        case WhitelistView.OVERVIEW:
            return true;
    }
}

function showFourthActionButton(view: WhitelistView): boolean {
    switch (view) {
        case WhitelistView.OVERVIEW:
        case WhitelistView.START_PAGE:
        case WhitelistView.FINISHED:
            return false;
        case WhitelistView.MATERIAL_TYPE:
            return true;
    }
}

function getPrefix(lotType: string): string {
    switch (lotType) {
        case LotType.DISPOSAL:
        case LotType.RETURN_FREIGHT:
        case LotType.BACKFILL:
            return "einer"
        case LotType.MATERIAL_NEED:
        case LotType.MATERIAL_SALE:
            return "einem"
    }
}

class ViewHandler {
    view: WhitelistView;
    title: string;
    breadcrumbTitle: string;
    description: string;

    constructor(view) {
        this.view = view;
    }

    getViewVariables() {
        console.log('Should be overridden by subclass');
    }

    setViewVariables() {
        console.log('Should be overridden by subclass');
    }
}

export class WhitelistStartPageHandler extends ViewHandler {

    constructor() {
        super(WhitelistView.START_PAGE)
        this.setViewVariables();
        return this;
    }

    getViewVariables() {
        return {
            title: get(t)('UI.whitelist.modal.startpage.title'),
            breadcrumbTitle: get(t)('UI.whitelist.modal.startpage.breadcrumbTitle'),
            description: get(t)('UI.whitelist.modal.startpage.description'),

        }
    }

    setViewVariables() {
        const variables = this.getViewVariables()
        this.title = variables.title;
        this.breadcrumbTitle = variables.breadcrumbTitle;
        this.description = variables.description;
    }

}

export class WhitelistMaterialTypePageHandler extends ViewHandler {
    constructor() {
        super(WhitelistView.MATERIAL_TYPE)
        this.setViewVariables();
        return this;
    }

    getViewVariables() {
        return {
            title: get(t)('UI.whitelist.modal.materialType.title'),
            description: get(t)('UI.whitelist.modal.materialType.description'),
        }
    }

    setViewVariables() {
        const variables = this.getViewVariables()
        this.title = variables.title;
        this.description = variables.description;
    }
}

export class WhitelistOverviewHandler extends ViewHandler {
    constructor() {
        super(WhitelistView.OVERVIEW)
        this.setViewVariables();
        return this;
    }

    getViewVariables() {
        return {
            title: get(t)('UI.whitelist.modal.overview.title'),
            breadcrumbTitle: get(t)('UI.whitelist.modal.overview.breadcrumbTitle'),
            description: get(t)('UI.whitelist.modal.overview.description'),
        }
    }

    setViewVariables() {
        const variables = this.getViewVariables()
        this.title = variables.title;
        this.breadcrumbTitle = variables.breadcrumbTitle;
        this.description = variables.description;
    }
}

export class WhitelistFinishedHandler extends ViewHandler {
    constructor() {
        super(WhitelistView.FINISHED)
        this.setViewVariables();
        return this;
    }

    getViewVariables() {
        return {
            title: get(t)('UI.whitelist.modal.finished.title'),
            description: get(t)('UI.whitelist.modal.finished.description'),
        }
    }

    setViewVariables() {
        const variables = this.getViewVariables()
        this.title = variables.title;
        this.description = variables.description;
    }
}

