<script lang="ts">
    import {t} from "../../i18n/i18n";
    import AgbButton from "../organisms/AgbButton.svelte";
    import {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import RadioButton from "../atoms/RadioButton.svelte";
    import TextArea from "../atoms/TextArea.svelte";
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import {RejectionReason, TooltipPlacements} from "../types/enums";
    import {fetchUtils} from "../utils/fetchUtils";
    import {createEventDispatcher, getContext} from "svelte";
    import Tooltip from "../atoms/Tooltip.svelte";
    import type {PubPageParams} from "../bid/types/pubPageParams";

    const dispatch = createEventDispatcher();
    const rejectionReasons: string[] = Object.keys(RejectionReason).filter((rr) => isNaN(Number(rr)));
    const urlParams: PubPageParams = getContext("urlParams");
    const {requestPublicId, requestedEntityType, requestedEntityId, offerUserPublicId} = urlParams;

    export let isTooltipEnable = false;
    export let pub: boolean;

    let rejectReason: string;
    let rejectComment: string;
    let url: string;

    const offerChanged = () => dispatch("offerChanged");
    const cancel = () => dispatch("cancel");


    async function reject() {
        url = `/api/optimizer/${pub ? "public/" : ""}${requestPublicId}/requested/${requestedEntityType}/${requestedEntityId}/user/${offerUserPublicId}/reject`;
        let request = {
            reason: rejectReason,
            visibleComment: rejectComment,
        }
        await fetchUtils.post(url, request)
            .then(() => offerChanged())
            .catch(fetchUtils.catchErrorAndShowNotification())
    }
</script>

<div class="reject-offer-container">
    <div class="reject-offer-upper-text">
        <span class="block-title reject-offer-headline">{$t('UI.rejectOffer.headline')}</span>
        <span class="block-title reject-offer-sub-headline">{$t('UI.rejectOffer.headline.subtext')}</span>
    </div>
    <div style="display: flex">
        <div class="reject-offer-half-container">
            <div style="display: flex; margin: 1.875rem 0 1rem">
                <span class="reject-offer-headline">{$t('UI.rejectOffer.reason')}</span>
            </div>
            <div class="reject-offer-radio-grid-container">
                {#each rejectionReasons as reason}
                    <div class="reason-box" style="grid-area: {reason.toLowerCase().replaceAll('_', '-')}">
                        <RadioButton label={$t(`UI.optimizerPage.rejectReason.${reason}`)}
                                     value={reason} bind:selected={rejectReason}
                                     groupName="rejectReason"/>
                        {#if isTooltipEnable && reason === RejectionReason.CANNOT_PROCESS}
                            <div class="info-icon">
                                <Tooltip msg={$t('UI.rejectOffer.cannotProcess.tooltip')}
                                         placementTtip={TooltipPlacements.RIGHT}/>
                            </div>
                        {/if}
                    </div>
                {/each}
            </div>
        </div>
        <div class="reject-offer-half-container">
            <div style="margin-left: 5.5rem">
                <TextArea bind:text={rejectComment} title={$t('UI.rejectOffer.comment')}
                          placeholder={$t('UI.rejectOffer.comment.placeholder')} height="164px"/>
            </div>
        </div>
    </div>
    <div>
        <AgbButton label={$t('UI.rejectOffer.agbButton')} description={$t('UI.rejectOffer.agbButton.description')}
                   color={PrimaryBtnColors.RED} disabled={rejectReason == null} hideCheckbox
                   on:click={reject}/>
    </div>
    <div style="display: flex; justify-content: end;">
        <div class="mr-3">
            <SecondaryButton color={SecondaryBtnColors.GREY} label={$t("UI.button.cancel")} on:click={cancel}/>
        </div>
    </div>
</div>


<style lang="scss">
  @import "../../styles/global";

  .reject-offer-container {
    margin-top: 1.875rem;
  }

  .reject-offer-radio-grid-container {
    margin-bottom: 0.875rem;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-row-gap: 1.75rem;
    grid-template-areas:
            'not-interested not-enough-material'
            'price-mismatch exhausted'
            'too-far-away cannot-process'
            'too-much-material .';
  }

  .reject-offer-half-container {
    width: 100%;
  }

  .reject-offer-upper-text {
    display: flex;
    flex-direction: column;
  }

  .reason-box {
    display: flex;
  }

  .block-title {
    text-align: left;
  }

  .reject-offer-headline {
    @include roboto-font(15px, 500, 1em, black);
  }

  .reject-offer-sub-headline {
    padding-top: 1.0rem;
    @include roboto-font(15px, 400, 1em);
  }

  .info-icon {
    position: relative;
    margin-top: 0.125rem;
    margin-left: 0.375rem;
  }
</style>