<script lang="ts">
    /* https://svelte-modals.mattjennings.io/api */
    import {closeModal} from 'svelte-modals';
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import Icon from "../atoms/Icon.svelte";
    import {t} from "../../i18n/i18n";
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import {fly} from 'svelte/transition';
    import {ButtonIcons, DiscardedObjectType, DiscardReason} from "../types/enums";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import type {AutocompleteFieldConfig} from "../types/autocompleteFieldConfig";
    import {AutocompleteConfig} from "../types/autocompleteFieldConfig";
    import Checkbox from "../atoms/Checkbox.svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import {NotificationType} from "../types/notification";
    import {addNotification} from "../stores";
    import {onMount} from "svelte";
    import iconClose from "@/icons/icon_close.svg";


    const discardReasonsOption = Object.values(DiscardReason).map(function (x) {
        return {value: x, text: $t(("LotDiscardReason." + x))};
    });
    const discardReasonAcConfig: AutocompleteFieldConfig = AutocompleteConfig('value', 'text');

    // provided by <Modals/>
    export let isOpen;
    export let discardedObjectType: DiscardedObjectType;
    export let discardedObjectId;
    export let showCloseIcon = false;
    export let denyIcon: ButtonIcons = ButtonIcons.NONE;
    export let padding = "1.75rem 4.75rem";
    export let maxWidth = "100%";
    export let selectedObject: any;
    export let selectedValue: DiscardReason;
    export let shouldSendEmail: false;
    export let onDiscard;

    let url;
    let title: string;

    onMount(() => {
        switch (discardedObjectType) {
            case DiscardedObjectType.LOT:
                title = $t("UI.lot.markAborted.modal.title");
                url = `/api/lot/${discardedObjectId}/markAborted`;
                break;
            case DiscardedObjectType.PROJECT:
                title = $t("UI.project.markAborted.modal.title");
                url = `/api/project/${discardedObjectId}/markCanceled`
                break;
        }
    });

    function confirm() {
        let request = {
            discardReason: selectedValue,
            shouldSendEmail: shouldSendEmail
        };
        fetchUtils.post(url, request)
            .then(data => {
                let notification = {message: $t('UI.markAborted.notification.success'), type: NotificationType.SUCCESS, dismissible: true, timeout: 5000};
                addNotification(notification);
                if (onDiscard !== undefined) {
                    onDiscard(data);
                }
                closeModal();
            })
            .catch(fetchUtils.catchErrorAndShowNotification());
    }
</script>

{#if isOpen}
    <div role="dialog" class="modal" in:fly="{{ y: -100, duration: 600 }}" out:fly={{y: -200, duration: 600}}>
        <div class="contents" style="--content-padding: {padding}; max-width: {maxWidth}">
            {#if showCloseIcon}
                <div class="cancel-btn" on:click={closeModal}>
                    <Icon src={iconClose} size="24"/>
                </div>
            {/if}
            <h1>{@html title}</h1>
            <div class="actual-content">
                <p>{@html $t("UI.markAborted.modal.message")}</p>
                <AutocompleteInputField bind:selectedObject bind:value={selectedValue}
                                        autocompleteConfig={discardReasonAcConfig}
                                        label={$t("UI.discardReason.autocomplete.ph")} options={discardReasonsOption}/>
            </div>
            <div style="margin-top: 4px; display: flex;width: 100%">
                <Checkbox bind:checked={shouldSendEmail} label={$t('UI.additionally.send.discard.email')}/>
            </div>

            <div class="actions">
                <SecondaryButton label={$t('UI.button.cancel')} color={SecondaryBtnColors.RED} sizeAdaptingToText={true} on:click={closeModal}/>
                <PrimaryButton label={$t("UI.lot.markAborted")} color={PrimaryBtnColors.RED} sizeAdaptingToText={true} on:click={confirm} leftIcon={denyIcon} disabled={selectedValue === null}/>
            </div>
        </div>
    </div>
{/if}

<style lang="scss">
  @import "../../styles/modals"; // also includes global.scss
</style>
