<script lang="ts">
    import { fade } from 'svelte/transition';
    import Skeleton from "svelte-skeleton/Skeleton.svelte";

    export let repeat: number = 1;
</script>

<div class="loading-skeleton__container" out:fade={{duration: 300}}>
    <div class="navbar-big-screens">
        <div>
            <Skeleton height={900} width={300}>
                <rect width="300" height="900" x="0" y="0"/>
            </Skeleton>
        </div>
    </div>
    <div>
        {#each {length: repeat} as _,i}
            <div>
                <Skeleton width={'800px'}>
                    <rect width="296" height="72" x="0" y="0" rx="12" ry="12"/>
                    <rect width="460" height="10" x="308" y="19" rx="5" ry="5"/>
                    <rect width="350" height="10" x="308" y="43" rx="5" ry="5"/>

                    <rect width="296" height="72" x="0" y="96" rx="12" ry="12"/>
                    <rect width="460" height="10" x="308" y="115" rx="5" ry="5"/>
                    <rect width="350" height="10" x="308" y="139" rx="5" ry="5"/>
                </Skeleton>
            </div>
        {/each}
    </div>
</div>

<style lang="scss">
  @use "../../styles/global";

  .loading-skeleton__container {
    @include global.flex-row(2rem, $alignment: flex-start);
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 2rem;
    background: #fff;
    overflow: hidden;
    pointer-events: none;

    & > div:last-child > div {
      @include global.flex-col(2rem);
      margin: 1rem;

      & :global(svg) {
        display: block;
        margin: auto;
        max-width: 100%;
      }
    }
  }

  .navbar-big-screens {
    display: none;
  }

  @media screen and (min-width: 900px) {
    .navbar-big-screens {
      display: block;
    }
  }

</style>
