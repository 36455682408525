<script lang="ts">
    import type {FontProps} from "../types/fontProps";
    import {DefaultRobotoFontProps, getFontPropsVarString} from "../types/fontProps";
    import {createEventDispatcher} from "svelte";
    import {treeExpansionStates} from "../stores";
    import type {NodeData} from "../types/nodeData";

    const dispatch = createEventDispatcher();

    export let nodes; // this is basically AT LEAST a list of NodeData
    export let activeNode: NodeData;
    export let fontProps: FontProps = DefaultRobotoFontProps('18px', '0.75rem');
    export let vElementPadding: number = 1; // vertical padding applied to each element top AND bottom in REM
    export let indentation = '1.5rem';
    export let treeExpansionMapKey = 'mmpv';
    export let actualDepth: number = 0;

    export let navigationDisabled: boolean = false; //disables clicks in the tree to navigate

    function toggleExpansion(id, leaf: boolean) {
        if (!leaf && !navigationDisabled) {
            const index = $treeExpansionStates.get(treeExpansionMapKey).indexOf(id);
            if (index > -1) {
                // if existing then remove
                $treeExpansionStates.get(treeExpansionMapKey).splice(index, 1);
            } else {
                // if not existing then add
                $treeExpansionStates.get(treeExpansionMapKey).push(id);
            }
            $treeExpansionStates = $treeExpansionStates; // important to trigger the calculation of nodeExpanded in this case
        }
    }

    function isNodeExpanded(id) {
        return $treeExpansionStates.get(treeExpansionMapKey).includes(id) || false
    }

    function onLabelClick(item) {
        dispatch('labelClick', {selectedMaterial: item});
    }

    function findChildrenAndAllOfLevelsBelow(node: NodeData) {
        return nodes.filter(n => (node.childrenIds.includes(n.id) && n.parentId === node.id) || n.level > actualDepth + 1)
            .sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    }


    $: actualLevelNodes = nodes !== null && nodes.length !== 0 ? nodes.filter(n => n.level === actualDepth) : [];
    $: linePosition = parseFloat(indentation) / 2 + 0.2; // x-position of the lines
    $: lineHeight = parseFloat(fontProps.lineHeight); //line-height in rem extracted as number
    $: itemHeight = 2 * vElementPadding + (lineHeight/ 16) * 3;
    $: styleString = `
        --itemHeight:${itemHeight}rem;
        --horizontal-line-pos-y:${itemHeight / 2}rem;
        --vPadding:${vElementPadding}rem;
        --indentation: ${indentation};
         --line-indentation: -${linePosition}rem;
        ${getFontPropsVarString(fontProps)}`;
</script>

<ul class="tree" style={styleString}>
    {#each actualLevelNodes.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)) as node, childIdx}
        <slot> <!--this is the default content for the treeView: arrow, label and the other slots -->
            <li class="node" class:leaf={!node.childrenIds || node.childrenIds?.length === 0 && node.level > 0}
                class:root={node.level === 0}
                class:rootLeaf={(!node.childrenIds || node.childrenIds?.length === 0) && node.parentId === "root" && node.level === 0}
                style={styleString} open={$treeExpansionStates.get(treeExpansionMapKey).includes(node.id)}>
                <div class="node-details">
                    <div class="node-arrow"
                         class:nodeExpanded={$treeExpansionStates.get(treeExpansionMapKey).includes(node.id)}
                         on:click={() => toggleExpansion(node.id, node.childrenIds?.length === 0)}></div>
                    <span class="node-label" title="{node.label}"
                          class:active={activeNode && activeNode.id === node.id && activeNode.level === node.level}
                          on:click|preventDefault={() => onLabelClick(node)}>{node.label}
                    </span>
                    {#if $$slots.nodeAction}
                        <div class="nodeActionDiv">
                            <slot name="nodeAction" item={node}></slot>
                        </div>
                    {/if}
                    {#if $$slots.nodeData}
                        <!--                <div class="node-data">-->
                        <slot name="nodeData" item={node}/> <!-- todo possibly put into div -->
                        <!--                </div>-->
                    {/if}
                </div>
                {#if node.childrenIds && node.childrenIds.length > 0 && $treeExpansionStates.get(treeExpansionMapKey).includes(node.id)}
                    <svelte:self nodes={findChildrenAndAllOfLevelsBelow(node)} {navigationDisabled} {indentation}
                                 {fontProps} {vElementPadding} {treeExpansionMapKey} {activeNode}
                                 actualDepth={actualDepth + 1} on:labelClick>
                            <slot slot="nodeData" name="nodeData" let:item {item}/>
                            <slot slot="nodeAction" name="nodeAction" let:item {item}/>
                    </svelte:self>
                {/if}
            </li>
        </slot>
    {/each}
</ul>

<style lang="scss">
  @import "../../styles/global";

  ul.tree {
    margin-bottom: 0;
    list-style: none;
    padding-left: var(--indentation);
  }

  :focus-visible { /*hides strange outline when double-clicking on details or summary item and pressing buttons */
    outline: none !important;
  }

  summary {
    display: block;
    cursor: default;

    &::marker, &::-webkit-details-marker {
      display: none;
    }
  }

  li.root {
    &::after, &::before { /*hide the lines for the root elements */
      display: none;
    }

    &:first-of-type {
      border-top: none;
      padding-top: 0;
    }
  }

  span.node-label.active {
    color: $primaryGreen !important;
  }

  li.rootLeaf {
    .node-arrow {
      display: none; /* for leaves do not display the arrow */
    }

    .node-label {
      margin-left: 12px; /*for leaves we need margin because arrow is not displayed */
    }
  }

  li.leaf {
    summary {
      cursor: text;
    }

    .node-arrow {
      display: none; /* for leaves do not display the arrow */
    }

    .node-label {
      margin-left: 12px; /*for leaves we need margin because arrow is not displayed */
    }

    &:not(.root):first-of-type::after {
      display: block !important; // display the vertical line for leaves, but not for rootLeaves
    }

    &:last-of-type::after {
      bottom: unset;
      top: -10px;
      height: 60%; /* half of 120% */
    }
  }

  li.node {
    position: relative;
    border-top: 1px solid $grey-600;

    &::before { /*horizontal line and parts of the vertical line until the top of the element except for the root*/
      position: absolute;
      left: var(--line-indentation);
      top: 0;
      border-left: 2px solid $grey-750; //TODO switch this color once there is an active edit in this path
      border-bottom: 2px solid $grey-750;
      content: "";
      width: 10px;
      height: var(--horizontal-line-pos-y); /* TODO needs to be 50% of the height of one element at the end*/
    }

    &::after { /*connects the vertical lines by overlaying another vertical line except for the root*/
      position: absolute;
      left: var(--line-indentation);
      bottom: 0;
      top: 0;
      border-left: 2px solid $grey-750;
      content: "";
      width: 10px;
      height: 120%;
    }

    &:last-child::after {
      display: none;
    }

    & .node-details {
      @include flex-row(0.25rem, $justify: flex-start);
      padding: var(--vPadding) 0; /*element padding*/
      height: var(--itemHeight);

      &:hover > .nodeActionDiv {
        opacity: 1;
      }

      &.nodeExpanded {
        max-height: unset;
      }
    }

    & .node-label {
      @include roboto-font(var(--line-height), var(--font-weight), var(--font-size), var(--color));
      cursor: pointer;

      &:hover {
        color: $primaryGreen;
        font-weight: 500;
      }
    }

    & .node-arrow {
      min-width: 20px;
      height: 20px;
      background: url("@/icons/icon_arrow_down.svg") no-repeat center;
      cursor: pointer;
      transition: transform 0.25s ease-out;

      &:hover {
        background: url("@/icons/icon_arrow_down_green.svg") no-repeat center;
      }

      &.nodeExpanded {
        transition: transform 0.25s ease-out;
        transform: rotate(-180deg);
      }
    }
  }

  .node-label {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .nodeActionDiv {
    opacity: 0;
    display: block;
  }
</style>
