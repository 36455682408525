<script lang="ts">

    import {t} from "../../../../i18n/i18n";
    import TextArea, {textAreaHeights} from "../../../atoms/TextArea.svelte";
    import type {FontProps} from "../../../types/fontProps";
    import {DefaultRobotoFontProps} from "../../../types/fontProps";
    import {OPTIMIZED_STATES} from "../../../types/enums";
    import {currentLot} from "../utils/fakStores";
    import NullableDataWrapper from "../../../utils/NullableDataWrapper.svelte";
    import {validateAndSaveLot} from "../../types/lot";


    let commentsView: {
        canEditRemarks: boolean,
        canEditProjectRemarks: boolean,
        canEditPubRemarks: boolean,
        canEditFurtherDetailsComment: boolean,
        canEditOptimizerPublicComment: boolean,
        canEditCommentSales: boolean,
        canEditCommentBackoffice: boolean,
        canEditCommentAccounting: boolean
    };
    $: if ($currentLot) {
        commentsView = {
            canEditRemarks: $currentLot.lotViewFieldsChangeMap.canEditAfterApproval,
            canEditProjectRemarks: $currentLot.lotViewFieldsChangeMap.canEditAfterApproval,
            canEditPubRemarks: $currentLot.lotViewFieldsChangeMap.canEditPubRemark,
            canEditFurtherDetailsComment: $currentLot.lotViewFieldsChangeMap.canEditFurtherDetails,
            canEditOptimizerPublicComment: $currentLot.lotViewFieldsChangeMap.canEditCommentOptimizerPublic,
            canEditCommentBackoffice: $currentLot.lotViewFieldsChangeMap.canEditCommentBackoffice,
            canEditCommentSales: $currentLot.lotViewFieldsChangeMap.canEditCommentSales,
            canEditCommentAccounting: $currentLot.lotViewFieldsChangeMap.canEditCommentAccounting,
        };
    }

    function onCommentsChange() {
        if ($currentLot && $currentLot.id !== "new") {
            validateAndSaveLot();
        }
    }

    const headerFontProps: FontProps = DefaultRobotoFontProps('14px', '0.75rem');

</script>

<div style="padding-right: 0.656rem;">
    <div class="styled-scrollbar">
        <NullableDataWrapper bind:data={$currentLot}>
            <TextArea bind:text={$currentLot.remarks} title={$t('UI.map.comments.remarks')} height={textAreaHeights.MID}
                      placeholder={$t('UI.pubRemarks.placeholder')} labelGap="0.625rem"
                      {headerFontProps} marginTop="0.375rem" readonly={!commentsView.canEditRemarks}
                      on:change={onCommentsChange}/>
            <TextArea bind:text={$currentLot.projectRemarks} title={$t('UI.map.comments.projectRemarks')}
                      height={textAreaHeights.MID} isHtml
                      placeholder={$t('UI.pubRemarks.placeholder')} {headerFontProps} labelGap="0.625rem"
                      readonly={!commentsView.canEditProjectRemarks} on:change={onCommentsChange}/>
            <TextArea bind:text={$currentLot.pubRemarks} title={$t('Lot.PubRemarks')}
                      height={textAreaHeights.MID}
                      placeholder={$t('UI.remarks.noRemarks')} {headerFontProps} labelGap="0.625rem"
                      readonly={!commentsView.canEditPubRemarks} on:change={onCommentsChange}/>
            <TextArea bind:text={$currentLot.commentBackoffice} title={$t('UI.map.comments.backoffice')}
                      height={textAreaHeights.MID}
                      placeholder={$t('UI.remarks.noRemarks')} {headerFontProps} labelGap="0.625rem"
                      readonly={!commentsView.canEditCommentSales} on:change={onCommentsChange}/>
            <TextArea bind:text={$currentLot.commentSales} title={$t('UI.map.comments.sales')}
                      height={textAreaHeights.MID}
                      placeholder={$t('UI.remarks.noRemarks')} {headerFontProps} labelGap="0.625rem"
                      readonly={!commentsView.canEditCommentBackoffice} on:change={onCommentsChange}/>
            <TextArea bind:text={$currentLot.commentAccounting} title={$t('UI.map.comment.commentAccounting')}
                      height={textAreaHeights.MID}
                      placeholder={$t('UI.remarks.noRemarks')} {headerFontProps} labelGap="0.625rem"
                      readonly={!commentsView.canEditCommentAccounting} on:change={onCommentsChange}/>
            {#if OPTIMIZED_STATES.includes($currentLot.state)}
                <TextArea bind:text={$currentLot.furtherDetailsComment} title={$t("UI.map.comments.furtherDetails")}
                          height={textAreaHeights.MID}
                          placeholder={$t('UI.remarks.noRemarks')} {headerFontProps} labelGap="0.625rem"
                          readonly={!commentsView.canEditOptimizerPublicComment} on:change={onCommentsChange}/>
                <TextArea bind:text={$currentLot.optimizerPublicComment} title={$t("UI.map.comments.optimizerPublic")}
                          height={textAreaHeights.MID}
                          placeholder={$t('UI.remarks.noRemarks')} {headerFontProps} labelGap="0.625rem"
                          readonly={!commentsView.canEditOptimizerPublicComment} on:change={onCommentsChange}/>
            {/if}
        </NullableDataWrapper>
    </div>
</div>


<style lang="scss">
  @import "../../../../styles/global";


  .styled-scrollbar {
    padding-left: 1.313rem;
    padding-right: 0.656rem;
    padding-bottom: 0.656rem;
    scrollbar-track-color: transparent;
    scrollbar-color: $primaryGreen $bluish-grey-200;
    scroll-margin-right: 4px;

    /*TODO remove that if comments should not have max height */
    max-height: 382px;
    overflow-y: auto;
    /** remove until here */

    &::-webkit-scrollbar {
      width: 20px;
      margin: 2px
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px $bluish-grey-200;
      border: solid 8px transparent;
      border-radius: 20px;
      background-clip: padding-box;

    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px $primaryGreen;
      border: solid 8px transparent;
      border-radius: 20px;
      background-clip: padding-box;
    }
  }
</style>
