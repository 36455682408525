import type {CalcMethod, LotState} from "./enums";
import {LotType} from "./enums";
import type {AddressData} from "./addressData";
import {DefaultAddressData} from "./addressData";

export type Lot = {
    address: AddressData,
    backupGrossPricePerTon?: number,
    backupVolume?: number,
    calcMethod: CalcMethod | undefined,
    code: string,
    correlationId: string,
    companyName: string,
    customerComment?: string,
    dailyCapacity: number,
    declaration: string,
    dumpCategory: string,
    endDate: string,
    grossPricePerTon: number,
    id: string,
    kickbackInPercent?: number,
    kickbackPerTon?: number,
    label: string,
    lotRemarks: string,
    matchedQuoteId?: string
    materialName: string,
    personAddressableName?: string,
    projectRemarks: string,
    publicId: string,
    remarks?: string,
    startDate?: string,
    state: LotState | undefined,
    transportCost?: number,
    type: LotType | undefined,
    vehicleCategories?: string,
    volume: number,
    furtherDetailsComment?: string,
    materialPropertiesWithoutOptional: string,
    skippedApproval: boolean,
}

export function DefaultLot() {
    let address: AddressData = DefaultAddressData();
    let lot: Lot = {
        address: address,
        backupGrossPricePerTon: 0,
        backupVolume: 0,
        calcMethod: undefined,
        code: "",
        correlationId: "",
        companyName: "",
        customerComment: "",
        dailyCapacity: 0,
        declaration: "",
        dumpCategory: "",
        endDate: "",
        grossPricePerTon: 0,
        materialName: "",
        kickbackInPercent: 0,
        kickbackPerTon: 0,
        label: "",
        lotRemarks: "",
        matchedQuoteId: "",
        personAddressableName: "",
        projectRemarks: "",
        publicId: "",
        remarks: "",
        startDate: "",
        state: undefined,
        transportCost: 0,
        type: undefined,
        vehicleCategories: "",
        volume: 0,
        furtherDetailsComment: "",
        materialPropertiesWithoutOptional: "",
        skippedApproval: false,
    };
    return lot;
}