<script lang="ts">

    import {setContext} from "svelte";
    import {type Writable, writable} from "svelte/store";
    import mmLogo from "@/icons/mm_icon_green_black.svg";
    import Icon from "../atoms/Icon.svelte";

    let openDropdown: Writable<string> = setContext('openDropdown', writable(""));
    let hamburgerActive: boolean = false;

    function closeDropdown() {
        $openDropdown = "";
    }

    function toggleHamburger() {
        hamburgerActive = !hamburgerActive;
    }

    // Close dropdowns on outside click
    window.addEventListener("click", (e) => {
        if (e.target != null && e.target.href && e.target.href.endsWith("#")) {
            return;
        }
        closeDropdown();
    });
    window.addEventListener("iframeClick", closeDropdown);

</script>

<header id="nav-bar-top" aria-label="top-navigation">
    <div class="nav__container">
        <div class="nav__start">
            <a class="logo" href="/">
                <Icon src={mmLogo} size={35} alt="MM Logo" clickable/>
            </a>
            <nav class="menu__container" class:show={hamburgerActive}>
                <ul class="menu__bar">
                    <slot name="menu-start"/>
                </ul>
            </nav>
        </div>
        <div class="nav__end">
            <slot name="menu-end" {hamburgerActive}/>
            <!--<button id="hamburger" aria-label="hamburger" aria-haspopup="true" aria-expanded="false"
                    on:click={toggleHamburger}>-->
                <button id="hamburger" class:open={hamburgerActive} aria-label="hamburger" aria-haspopup="true"
                        aria-expanded="false" on:click={toggleHamburger}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            <!--</button>-->
        </div>
    </div>
</header>


<style lang="scss">
  @use "../../styles/menubar";

</style>

