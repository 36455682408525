<script context="module" lang="ts">
    export enum PrimaryBtnColors {
        RED = "red",
        ORANGE = "orange",
        GREEN = "green",
        BLUE = "blue",
        GREY = "grey",
        CORPORATE_DESIGN = "color-cd"
    }
</script>
<script lang="ts">
    import {ButtonIcons, TooltipPlacements} from "../types/enums";
    import Tooltip from "./Tooltip.svelte";

    export let id: string = '';
    export let label: string = "";
    export let disabled: boolean = false;
    /**
     * Defines a text that is displayed in a tooltip on hover of the button.
     */
    export let info: string | null = "";
    /**
     * Defines the placement of the tooltip in respect to the button. We can state the values, which tippy can interpret.
     * Defaults to 'top' to show the toolip above the button, if a text is specified..
     */
    export let infoPlacement: TooltipPlacements = TooltipPlacements.TOP_START; // see tippy tooltip placements
    export let color: PrimaryBtnColors = PrimaryBtnColors.GREEN;
    export let leftIcon: ButtonIcons = ButtonIcons.NONE;
    export let rightIcon: ButtonIcons = ButtonIcons.NONE;

    /**
     *If the icon should be displayed in white, set this to true. Defaults to false.
     */
    export let whiteIcon: boolean = false;

    /**
     * The size of the icon in the button. Defaults to 20px.
     */
    export let iconSize: string = '20px';

    /**
     * Control the horizontal padding. This is one value for left AND right padding, as we always want the content centered.
     * Defaults to 0.75rem.
     */
    export let paddingHorizontal: string = '0.75rem';

    /**
     * Control the top padding. Defaults to 0.875rem
     */
    export let paddingTop: string = '0.875rem';

    /**
     * Control the bottom padding. Defaults to 0.75rem
     */
    export let paddingBottom: string = '0.75rem';

    /**
     * In case the button is in something like a flex container and should take the full width of his container.
     * Defaults to false.
     */
    export let fullWidth: boolean = false;

    /**
     * In case the button should not have a fixed width, set this to true. Then the button will only be as long as necessary for the text + padding.
     * Should be used alternating to fullWidth. Defaults to false.
     */
    export let sizeAdaptingToText: boolean = false;

    /**
     * Give a fixed width to the button. Is overwritten by the other two variables, in case any of
     * them is set to true.
     * Defaults to 250px.
     */
    export let width: string = '250px';
    export let fontSize: string = '0.75rem';
    export let titleAttr: string = label; // adds a default browser tooltip with the label

    let buttonStyle: string;
    $: buttonStyle = "--icon-size: " + iconSize + "; --padding-horizontal: " + paddingHorizontal + "; --padding-top: " + paddingTop + "; --padding-bottom: " + paddingBottom + " ; --width: " + width + "; --font-size: " + fontSize;
</script>


{#if info}
    <div style="width: min-content">
        <Tooltip msg={info} placementTtip={infoPlacement} offsetTtip={[0,20]} isHtml>
            <button {id} class="primary-button btn-{color.toString().toLowerCase()}"
                    class:fullWidth class:sizeAdaptingToText {disabled} on:click
                    title={titleAttr} style={buttonStyle}>
                {#if leftIcon !== ButtonIcons.NONE}
                    <div class="button-icon icon-{leftIcon.toString().toLowerCase().replace(/_/g, '-')}"
                         class:icon-filter-disabled={disabled} class:whiteIcon></div>
                {/if}
                {label}
                {#if rightIcon !== ButtonIcons.NONE}
                    <div class="button-icon icon-{rightIcon.toString().toLowerCase().replace(/_/g, '-')}"
                         class:icon-filter-disabled={disabled} class:whiteIcon></div>
                {/if}
            </button>
        </Tooltip>
    </div>
{:else}
    <button {id} class="primary-button btn-{color.toString().toLowerCase()}"
            class:fullWidth class:sizeAdaptingToText {disabled} on:click
            title={titleAttr} style={buttonStyle}>
        {#if leftIcon !== ButtonIcons.NONE}
            <div class="button-icon icon-{leftIcon.toString().toLowerCase().replace(/_/g, '-')}"
                 class:icon-filter-disabled={disabled} class:whiteIcon></div>
        {/if}
        {label}
        {#if rightIcon !== ButtonIcons.NONE}
            <div class="button-icon icon-{rightIcon.toString().toLowerCase().replace(/_/g, '-')}"
                 class:icon-filter-disabled={disabled} class:whiteIcon></div>
        {/if}
    </button>
{/if}


<style lang="scss">
  @import "../../styles/global";
  @import "../../styles/buttons";


  .primary-button {
    border: none;
    color: white;
    @include roboto-font(16px, 400, var(--font-size), #ffffff);
    padding: var(--padding-top) var(--padding-horizontal) var(--padding-bottom);
    border-radius: 0.125rem;
    width: var(--width);
    min-width: min-content;
    height: 32px;
    min-height: 32px;
    @include flex-row(0.25rem);

    &:disabled,
    &:disabled:hover {
      background-color: $bluish-grey-700;
      cursor: not-allowed;
    }

    @media screen and (max-width: 992px) {
      width: 200px;
    }
    @media screen and (max-width: 768px) {
      width: 150px;
    }
    @media screen and (max-width: 660px) {
      width: 250px;
    }
  }

  .btn-red {
    background-color: $primaryRed;

    &:hover {
      background-color: $primaryDarkRed;
    }
  }

  .btn-orange {
    background-color: $primaryOrange;

    &:hover {
      background-color: $primaryDarkOrange;
    }
  }

  .btn-green {
    background-color: $primaryGreen;

    &:hover {
      background-color: $secondaryGreen;
    }
  }

  .btn-blue {
    background-color: $primaryBlue;

    &:hover {
      background-color: $secondaryDarkBlue;
    }
  }

  .btn-grey {
    background-color: #D0D5DD;

    &:hover {
      background-color: #D0D5DD;
    }
  }

  .btn-color-cd {
    background-color: $primaryColor;

    &:hover {
      background-color: $secondaryColor;
    }
  }

</style>
