<script lang="ts">
    import {copyLot, validateAndSaveLot, validateLot} from "../../types/lot";
    import InputField from "../../../molecules/InputField.svelte";
    import type {FontProps} from "../../../types/fontProps";
    import {DefaultRobotoFontProps} from "../../../types/fontProps";
    import {inputFieldFormats} from "../../../utils/formatters";
    import {CalcMethod, LotType} from "../../../types/enums";
    import type {AutocompleteFieldConfig} from "../../../types/autocompleteFieldConfig";
    import {AutocompleteConfig, getFormAcConfigFetch} from "../../../types/autocompleteFieldConfig";
    import {t} from "../../../../i18n/i18n";
    import AutocompleteInputField from "../../../autocomplete/AutocompleteInputField.svelte";
    import PersonAndCompanyDataAutocomplete from "../../../autocomplete/PersonAndCompanyDataAutocomplete.svelte";
    import DatePickerInput from "../../../atoms/DatePickerInput.svelte";
    import {tick} from "svelte";
    import dayjs from "dayjs";
    import {derived} from "svelte/store";
    import {copyAddressData} from "../../../types/addressData";
    import type {LotEditFieldsView} from "../../../types/lotEditFieldsView";
    import {DefaultLotEditFieldsView} from "../../../types/lotEditFieldsView";
    import NullableDataWrapper from "../../../utils/NullableDataWrapper.svelte";
    import {currentLot, currentProject, mapFormControls, positionAutocompleteVisible} from "../utils/fakStores";
    import type {PersonAndCompanyData} from "../../../types/personAndCompanyData";
    import {copyPersonAndCompanyData, DefaultPersonAndCompanyData} from "../../../types/personAndCompanyData";
    import AddressDataAutocomplete from "../../../autocomplete/AddressDataAutocomplete.svelte";
    import {hideAutosaveInfo} from "../../../utils/autosaveInfoUtils";
    import {jwt} from "../../../utils/jwt";
    import {copyUserAccountData, DefaultUserAccountData, type UserAccountData} from "../../../types/userAccountData.js";
    import {getUserAccountAcOptions} from "../utils/fakUtils";
    import {createTippy} from "svelte-tippy";

    const LOT_FORM_ERROR_PATH_PREFIX = 'lot.';

    let startDate: number;
    let endDate: number;

    let loginUser: UserAccountData = DefaultUserAccountData();
    if ($jwt) {
        jwt.subscribe((claims: any) => {
            loginUser.id = Number(claims.uid);
            loginUser.firstName = claims.ufirstname;
            loginUser.lastName = claims.ulastname;
        })
    }

    let lotEditFieldsView: LotEditFieldsView = $currentLot.lotViewFieldsChangeMap;
    $: lotEditFieldsView = $currentLot ? $currentLot.lotViewFieldsChangeMap : DefaultLotEditFieldsView();

    let canNotEditAfterApproval = !lotEditFieldsView.canEditAfterApproval;
    let canNotEditLot = !lotEditFieldsView.canEditLot;
    let canNotEditCaseManager = !lotEditFieldsView.canEditCaseManager;

    let contactPerson: PersonAndCompanyData | null = null;
    let personLink: string | null = null;
    let onboardingComment: string | null = null;

    $: if ($currentLot) {
        canNotEditAfterApproval = !lotEditFieldsView.canEditAfterApproval;
        canNotEditLot = !lotEditFieldsView.canEditLot;
        canNotEditCaseManager = !lotEditFieldsView.canEditCaseManager;
    }

    $: contactEditView = {
        canEditOthers: lotEditFieldsView.canEditLot,
        canEditPhone: lotEditFieldsView.canEditAfterApproval
    }

    const lotTypeOptions = Object.values(LotType).map(function (x) {
        return {value: x, text: $t(("LotType." + x))};
    });

    const calcMethodOptions = Object.values(CalcMethod).filter(x => x !== CalcMethod.COUNTER_OFFER).map(function (x) {
        return {value: x, text: $t(("CalcMethod." + x))};
    });

    const fontProps: FontProps = DefaultRobotoFontProps('0.875rem', '0.75rem');

    let typeAcConfig: AutocompleteFieldConfig = AutocompleteConfig('value', 'text', false, !lotEditFieldsView.canEditLot);
    let calcMethodAcConfig: AutocompleteFieldConfig = AutocompleteConfig('value', 'text', false, !lotEditFieldsView.canEditLot);

    let caseManagerAcConfig: AutocompleteFieldConfig = getFormAcConfigFetch("user-accounts", false, 'id', 'text',
        !lotEditFieldsView.canEditCaseManager);
    let caseManagerId: any = null;
    let caseManagerOptions: UserAccountData[] | UserAccountData;
    let rerenderTrigger = null;

    async function onStartDateChanged() {
        if (startDate) {
            await tick();
            $currentLot.startDate = dayjs.unix(startDate).add(12, 'h').toISOString().split("T")[0];
            triggerLotValidationAndSave();
        } else {
            startDate = $currentLot.startDate;
        }
    }

    async function onEndDateChanged() {
        if (endDate) {
            await tick();
            $currentLot.endDate = dayjs.unix(endDate).add(12, 'h').toISOString().split("T")[0];
            triggerLotValidationAndSave();
        } else {
            endDate = $currentLot.endDate;
        }
    }

    function triggerLotValidationAndSave() {
        if ($currentLot) {
            if (!contactPerson) {
                $currentLot.person = DefaultPersonAndCompanyData();
            } else {
                $currentLot.person = copyPersonAndCompanyData(contactPerson);
            }
            $currentLot.person.link = personLink;
            $currentLot.person.onboardingComment = onboardingComment;

            if ($currentLot.id !== "new") {
                validateAndSaveLot(copyLot($currentLot));
            }
        }
    }

    function initializeFieldsAndObjects() {
        if ($currentLot.id === "new") {
            $currentLot.project = $currentProject.id;
            $currentLot.address = copyAddressData($currentProject.address);
            $currentLot.person = copyPersonAndCompanyData($currentProject.contactPersonAndCompany);
            $currentLot.person.link = "";
            $currentLot.startDate = $currentProject.startDate;
            $currentLot.endDate = $currentProject.endDate;
            $currentLot.kickbackInPercent = 5;
            $currentLot.kickbackPerTon = 0.5;
            $currentLot.caseManager = copyUserAccountData(loginUser)
            fillProjectRemarkField();
        }
        startDate = $currentLot.startDate ? dayjs($currentLot.startDate, "YYYY-MM-DD").unix() : null;
        endDate = $currentLot.endDate ? dayjs($currentLot.endDate, "YYYY-MM-DD").unix() : null;
        caseManagerId = $currentLot.caseManager && $currentLot.caseManager.id ? $currentLot.caseManager.id : null;
        caseManagerOptions = getUserAccountAcOptions($currentLot.caseManager);
        rerenderTrigger = {}; // reassign the rerenderTrigger to completely rerender the caseOwner Autocomplete-Field
        contactPerson = $currentLot.person ? $currentLot.person : null;
        personLink = $currentLot.person && $currentLot.person.link ? $currentLot.person.link : null;
        onboardingComment = $currentLot.person && $currentLot.person.onboardingComment ? $currentLot.person.onboardingComment : null;
    }

    function onCalcMethodChange() {
        fillProjectRemarkField();
        if ($currentLot.calcMethod === CalcMethod.REQUEST_OFFER) {
            $currentLot.grossPricePerTon = null;
        }
    }

    function fillProjectRemarkField() {
        if ($currentLot.type === LotType.RETURN_FREIGHT) {
            $currentLot.projectRemarks = "";
            return;
        }
        let calcMethodStr = $currentLot.calcMethod === CalcMethod.NORMAL ? "normal" : "requestOffer";
        let typeStr;
        switch ($currentLot.type) {
            case LotType.MATERIAL_SALE:
                typeStr = "materialSale";
                break;
            case LotType.MATERIAL_NEED:
                typeStr = "materialNeed";
                break;
            default:
                typeStr = $currentLot.type.toLowerCase();
        }
        $currentLot.projectRemarks = $t('Model.generalProjectInfo.' + typeStr + '.' + calcMethodStr);
    }

    function saveAddressToClipboard() {
        navigator.clipboard.writeText($currentLot?.address?.city + " " + $currentLot?.address?.zip + " " + $currentLot?.address?.street);
    }

    const copyTippy = createTippy({
        animation: 'scale-extreme',
        content: $t('UI.addressData.copy'),
        delay: [100, 200],
        hideOnClick: true,
        interactive: true,
        maxWidth: 367,
        placement: 'left',
        theme: 'mmttip',
        appendTo: document.getElementsByClassName('fak-base-layout')[0],
    });

    /* The derived store makes sure, that reactivity for field and object initialization is triggered, iff the lot.id
    * has changed, i.e., a different lot is selected. */
    let currentLotId = derived(
        currentLot,
        $currentLot => $currentLot?.id
    );
    currentLotId.subscribe(() => {
        if ($currentLot) {
            initializeFieldsAndObjects();
            let lotToValidate = copyLot($currentLot);
            if (!$mapFormControls.saving) {
                hideAutosaveInfo();
            }
            if ($currentLot.id !== "new") {
                validateLot(lotToValidate);
            }
        }
    });

    initializeFieldsAndObjects();

</script>

<NullableDataWrapper bind:data={$currentLot}>
    <div class="position-form-header">
        <span>{$t('UI.map.lot.generalInfo')}</span>
        {#if $currentLot.id !== "new"}
            <a href="/lot/{$currentLot.id}" target="_blank">
                    <span class="mm-code-link">
                        {$currentLot.code}
                    </span>
            </a>
        {/if}
    </div>
    <div class="lot-form-container">
        <div class="position-main-data">
            <div>
                <div style="grid-area: label">
                    <InputField bind:value={$currentLot.correlationId} format={inputFieldFormats.FULL} id="lot-id"
                                placeholder={$t("UI.map.lot.correlationId.ph")} {fontProps}
                                readOnly={!lotEditFieldsView.canEditLot} on:focusout={triggerLotValidationAndSave}
                                errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'correlationId'}/>
                </div>
                <div style="grid-area: caseManager">
                    {#key rerenderTrigger}
                        <AutocompleteInputField bind:value={caseManagerId} bind:selectedObject={$currentLot.caseManager}
                                                bind:options={caseManagerOptions} bind:disabled={canNotEditCaseManager}
                                                id="lot-case-manager"
                                                {fontProps} autocompleteConfig={caseManagerAcConfig}
                                                label={$t("UI.caseManager.autocomplete.ph")}
                                                errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'caseManager.*'}
                                                on:change={triggerLotValidationAndSave}/>
                    {/key}
                    <!-- because case Manager is a nested type, we can show errors for all properties -->
                </div>
                <div style="grid-area: type">
                    <AutocompleteInputField bind:value={$currentLot.type} bind:disabled={canNotEditLot}
                                            id="lot-type" inputId="lot-type"
                                            {fontProps} autocompleteConfig={typeAcConfig} options={lotTypeOptions}
                                            label={$t("UI.type.autocomplete.ph")}
                                            errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'type'} required
                                            on:change={() => {fillProjectRemarkField(); triggerLotValidationAndSave()}}/>
                </div>
                <div style="grid-area: calcMethod">
                    <AutocompleteInputField bind:value={$currentLot.calcMethod} bind:disabled={canNotEditLot}
                                            id="lot-calc-method"
                                            {fontProps} autocompleteConfig={calcMethodAcConfig}
                                            label={$t("UI.calcMethod.autocomplete.ph")}
                                            options={calcMethodOptions}
                                            errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'calcMethod'}
                                            required
                                            on:change={() => {onCalcMethodChange(); triggerLotValidationAndSave()}}/>
                </div>
                <div style="grid-area: personContact">
                    <PersonAndCompanyDataAutocomplete bind:contactPerson bind:personLink bind:onboardingComment
                                                      bind:isEditable={lotEditFieldsView.canEditLot}
                                                      personInputId="lot-contact-person"
                                                      {fontProps} autocompleteVisibleStore={positionAutocompleteVisible}
                                                      editView={contactEditView} showCompany={false}
                                                      outerId={$currentLot.id} isNew={$currentLot.id === "new"}
                                                      errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'person'} required
                                                      on:change={triggerLotValidationAndSave}/>
                    <!-- no need to append '.*' to path here, is already done in component itself + we need the path for the separated subfields -->
                </div>
            </div>
        </div>

        <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start; justify-content: center;">
            <span>{$t('UI.map.timeframe')}</span>
            <div class="position-data-row">
                <div>
                    <!-- TODO Change permission naming-->
                    <DatePickerInput bind:date="{startDate}" bind:disabled={canNotEditCaseManager}
                                     id="lot-select-start-date" label="" {fontProps}
                                     format={inputFieldFormats.FULL} placeholder={$t('UI.startDate.label')}
                                     errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'startDate'}
                                     on:change={onStartDateChanged}/>
                    <DatePickerInput bind:date="{endDate}" bind:disabled={canNotEditCaseManager}
                                     id="lot-select-end-date" label="" {fontProps}
                                     format={inputFieldFormats.FULL} placeholder={$t('UI.endDate.label')}
                                     errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'endDate'} on:change={onEndDateChanged}/>
                </div>
            </div>
            <div class="execution-place-header">
                <span class="execution-place-header-text">{$t('UI.map.executionPlace')}</span>
                <span class="duplicate-address-icon" on:click={saveAddressToClipboard} use:copyTippy>
                </span>
            </div>
            <div class="position-data-row">
                <div>
                    <AddressDataAutocomplete bind:addressData={$currentLot.address}
                                             {fontProps} errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'addressData'}
                                             outerId={$currentLot.id} idPrefix="lot"
                                             isEditable={$currentLot.lotViewFieldsChangeMap.canEditLot} required
                                             on:change={triggerLotValidationAndSave}/>
                    <!-- no need to append '.*' to path here, is already done in component itself + we need the path for the separated subfields -->
                </div>
            </div>
        </div>
    </div>
</NullableDataWrapper>

<style lang="scss">
  @import "../../../../styles/global";

  .execution-place-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4px;
  }

  .execution-place-header-text {
    padding-top: 4px;
  }

  .lot-form-container {
    padding-left: 1.313rem;
    padding-right: 1.313rem;
    padding-bottom: 1.625rem;
    width: 100%;
    max-width: 450px;
    @include flex-col($justify: flex-start);

    & > span,
    & > div > span,
    & > div > div > span {
      @include roboto-font(1rem, 400, 0.875rem, black);
      align-self: start;
      padding-top: 1.25em;
      padding-bottom: 0.75em;
    }
  }

  .position-form-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1.625rem 1.31rem 1.25rem;

    & > span {
      @include roboto-font(normal);
    }
  }

  .position-data-row {
    width: 100%;
    @include flex-col(0.75rem, $alignment: flex-start);

    & > div,
    & > div > div {
      width: 100%;
      @include flex-row(0.625rem, $justify: flex-start);
    }
  }

  .position-main-data {
    width: 100%;
    @include flex-col(0.625rem, $alignment: flex-start);

    & > div {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));;
      grid-row-gap: 0.938rem;
      grid-column-gap: 0.625rem;
      grid-template-areas:
            "label caseManager"
            "type type"
            "calcMethod calcMethod"
            "personContact personContact";
    }
  }

  .mm-code-link {
    float: right;
    @include roboto-font(normal, 400, 0.75rem, $primaryGreen);

    &:hover {
      text-decoration: underline;
    }
  }

  .duplicate-address-icon {
    background-color: white;
    background-size: contain;
    position: relative;
    display: inline-block;
    text-align: center;
    width: 24px;
    height: 24px;
    margin-top: 12px;
    margin-right: 6px;

    &:hover {
      cursor: pointer;
    }
  }

</style>
