import type {Lot} from "../../types/lot";
import {defaultCatalogOptions, defaultMaterialOptions, formErrors, mapFormControls} from "./fakStores";
import {fetchUtils} from "../../../utils/fetchUtils";
import {getUserAccountAutocompleteText, type UserAccountData} from "../../../types/userAccountData";

export function adjustMargin(currentLot: Lot | null, scrollDetailsLayerToTop: boolean = false) {
    let projectFormContainer = document.getElementById("project-form-container");
    let projectLayer = document.getElementById("fak-project-layer");
    let projectAccordion = document.getElementById("project-accordion");
    if (projectFormContainer && projectLayer && projectAccordion) {
        let projectLayerMarginNumber = (Math.abs((projectLayer.offsetHeight - projectFormContainer.offsetHeight)) / 2);
        let projectLayerMargin = projectLayerMarginNumber + "px";
        projectAccordion.style.marginTop = projectLayerMargin;
        projectAccordion.style.marginBottom = projectLayerMargin;
        let lotsAccordionList = document.getElementById("lots-accordion-list");
        if (lotsAccordionList) {
            lotsAccordionList.style.marginTop = projectLayerMargin;

            setTimeout(() => {
                //scrollLotDetailsLayer = false;
                let lotsLayer = document.getElementById("fak-lots-accordion");
                let lotsLayerOffset = 0;
                if (currentLot && currentLot.id !== "new") {
                    let selectedLot = document.getElementById("lot-" + currentLot.id);
                    if (selectedLot) {
                        lotsLayerOffset = selectedLot.offsetTop - projectLayerMarginNumber;
                        lotsAccordionList.style.marginBottom = (window.innerHeight - projectLayerMarginNumber - 161) + "px";
                    }
                } else {
                    lotsAccordionList.style.marginBottom = projectLayerMargin;
                }
                if (lotsLayer) {
                    if (scrollDetailsLayerToTop) {
                        if (lotsLayerOffset === 0) {
                            let lotDetailsLayer = document.getElementById("fak-lot-details-layer");
                            if (lotsLayer && lotDetailsLayer) {
                                lotDetailsLayer.scrollTo({
                                    top: lotsLayer.scrollTop - lotsLayerOffset,
                                    behavior: "smooth"
                                });
                            }
                        } else {
                            lotsLayer.addEventListener("scrollend", () => {
                                let lotDetailsLayer = document.getElementById("fak-lot-details-layer");
                                if (lotsLayer && lotDetailsLayer) {
                                    lotDetailsLayer.scrollTo({
                                        top: lotsLayer.scrollTop - lotsLayerOffset,
                                        behavior: "smooth"
                                    });
                                }
                            }, {once: true});
                        }
                    }
                    lotsLayer.scrollTo({top: lotsLayerOffset, behavior: "smooth"});
                }
            }, 300);
        }
        let lotDetailsAccordion = document.getElementById("lot-details-accordion");
        if (lotDetailsAccordion) {
            lotDetailsAccordion.style.marginTop = projectLayerMargin;
            lotDetailsAccordion.style.marginBottom = projectLayerMargin;
        }
    }
}

export function clearFormErrors() {
    formErrors.set([{path: "", message: ""}]);
}

export function closeFAK() {
    setFAKLevel(0);
}

function setFAKLevel(newLevel: number) {
    mapFormControls.update(c => {
        c.level = newLevel;
        return c
    });
}

export function loadDefaultCatalogAndMaterialOptions() {
    fetchUtils.get("/api/catalogsandmaterials/svelte/defaults", false)
        .then(data => {
            defaultCatalogOptions.set(data.catalogDefaults);
            defaultMaterialOptions.set(data.materialDefaultsMap);
        })
        .catch(console.log);
}

export function getUserAccountAcOptions(key:UserAccountData) {
    let options: { text: string; id: number; firstName: string; lastName: string; }[];
    if (key && key.id) {
        options = [key].map(op => {
            return {
                ...op,
                text: getUserAccountAutocompleteText(op)
            }
        })
    } else {
        options = [];
    }
    return options;
}
