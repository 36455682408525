<script lang="ts">

    import {CalcMethod} from "../types/enums";
    import {t} from "../../i18n/i18n";
    import Checkbox from "../atoms/Checkbox.svelte";
    import {DefaultLabelFontProps} from "../types/fontProps";
    import {formatNumber} from "../utils/formatters";
    import {type RequestDto, RequestType} from "./types/requestDto";
    import {getContext, hasContext} from "svelte";
    import {writable, type Writable} from "svelte/store";

    const request: Writable<RequestDto> = getContext("request");
    const showKickback: Writable<boolean> = hasContext("showKickback") ? getContext("showKickback") : writable(false);

    export let checked: boolean = false;
    export let calcMethod: CalcMethod | undefined;
    export let requestType: RequestType;

    let label: string, infoText: string;

    function buildKickbackText() {
        if (!$showKickback) {
            return '';
        }
        let kickbackValues;
        kickbackValues = $request?.facts?.kickbackInPercent ? $t('UI.addKickback.percent', {inPercent: formatNumber($request?.facts?.kickbackInPercent)}) : '';
        kickbackValues = $request?.facts?.kickbackInPercent && $request?.facts?.kickback ? kickbackValues + $t('UI.addKickback.connector') : kickbackValues + '';
        kickbackValues = $request?.facts?.kickback ? kickbackValues + $t('UI.addKickback.perTon', {perTon: $request.facts.kickbackFormattedAsString}) : kickbackValues + '';
        return `${$t('UI.offer.info.addKickback', {kickbackValues: kickbackValues})}`;
    }

    $: label = $t(`RequestType.${requestType}`);
    $: {
        if (requestType) {
            let offerText = calcMethod === CalcMethod.COUNTER_OFFER ? $t("UI.optimizerPage.counterOffer.text") : $t("UI.optimizerPage.offer.text"); // Gegenangebot / Angebot
            infoText = requestType === RequestType.MATERIAL_WITH_TRANSPORT ? $t("UI.Offer.inclusiveTransp.desc", {offerText}) : $t("UI.Offer.exclusiveTransp.desc", {offerText});
        }
    }
</script>

<div class="offer-modality-checkbox">
    <Checkbox id={requestType} bind:checked {label} labelFontProps={DefaultLabelFontProps("normal", 500, "0.875rem")}
              on:checkboxChange/>
    <div>
        <span>{@html infoText + " " + buildKickbackText()}</span>
    </div>
</div>


<style lang="scss">
  @use "../../styles/global";

  .offer-modality-checkbox {
    @include global.flex-col(0.25rem, $alignment: flex-start);
  }
</style>
