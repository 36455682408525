<script lang="ts">
    import {fade} from "svelte/transition";
    import loadingAnimation from "@/animations/MmLogo.gif";
</script>
<div class="blur-background-overlay" in:fade={{duration: 400}} out:fade={{duration:400}}>
    <div class="blur-background-overlay-content">
        <div class="content-container">
            <!-- size to 100% of its original size instead of the complete parent -->
            <img alt="LoadingAnimation" src={loadingAnimation} width="100%"/>
        </div>
    </div>
</div>

<style lang="scss">
  @import "../../styles/animations";

  .blur-background-overlay {
    z-index: 10001; // increased to have the tooltips on the pub page that are showing directly below! the animation
  }
  .blur-background-overlay-content {
    z-index: 10002; // increased to have the tooltips on the pub page that are showing directly below! the animation
  }
</style>
