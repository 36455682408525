import {array, number, object, string} from "yup";
import {translate} from "../../../i18n/i18n";

export type HelpEntry = {
    id: string,
    pageIdentifier: string,
    visibility: string[],
    headline: string,
    description: string,
    link?: string,
    linkText?: string,
    searchStrings: string[],
    priority: number
}

export function EmptyHelpEntry() {
    return {
        id: "new",
        pageIdentifier: "/**",
        visibility: [],
        headline: "",
        description: "",
        searchStrings: [],
        priority: 100
    };
}

export function copyHelpEntry(entry: HelpEntry) {
    let copy = EmptyHelpEntry();
    Object.assign(copy, entry);
    if (entry.visibility) {
        // @ts-ignore
        copy.visibility = [...entry.visibility];
    }
    if (entry.searchStrings) {
        // @ts-ignore
        copy.searchStrings = [...entry.searchStrings];
    }
    return copy;
}

export const helpEntrySchema = object({
    id: string().nullable().notRequired(),
    pageIdentifier: string()
        // Used in the backend for pattern matching: https://docs.oracle.com/javase/7/docs/api/java/nio/file/FileSystem.html#getPathMatcher(java.lang.String)
        // Checks if the string starts with / and only contains valid characters
        .matches(/^\/([/\\?*\-_{},\w])*$/, translate('de', 'UI.validation.helpEntry.pageIdentifier.invalidCharacter', []))
        // Checks whether the number of { equals the number of }
        .test("bracketNumberEqualTest",
            translate('de', 'UI.validation.helpEntry.pageIdentifier.invalidNumberOfBrackets', []),
            (value) => {
                if (!value) return true;
                return (value.match(/{/g) || []).length === (value.match(/}/g) || []).length
            })
        .required(translate('de', 'UI.validation.helpEntry.pageIdentifier.required', [])),
    visibility: array()
        .of(string())
        .min(1, translate('de', 'UI.validation.helpEntry.visibility.required', []))
        .required(translate('de', 'UI.validation.helpEntry.visibility.required', [])),
    headline: string()
        .trim()
        .max(150, translate('de', 'UI.validation.helpEntry.headline.maxLength', []))
        .required(translate('de', 'UI.validation.helpEntry.headline.required', [])),
    description: string()
        .trim()
        .required(translate('de', 'UI.validation.helpEntry.description.required', [])),
    link: string()
        .url(translate('de', 'UI.validation.helpEntry.link.url', []))
        .notRequired(),
    linkText: string()
        .notRequired(),
    searchStrings: array()
        .of(string())
        .min(0, translate('de', 'UI.validation.helpEntry.searchStrings.required', []))
        .notRequired(),
    priority: number()
        .positive(translate('de', 'UI.validation.helpEntry.priority.positive', []))
        .required(translate('de', 'UI.validation.helpEntry.priority.required', []))
})
