<script lang="ts">

    import type {LotStatusButtonsView} from "../../../types/lotStatusButtonsView";
    import {getContext, onMount} from "svelte";
    import {t} from "../../../../i18n/i18n";
    import {DiscardedObjectType, LotState, TooltipPlacements} from "../../../types/enums";
    import {fetchUtils} from "../../../utils/fetchUtils";
    import {addNotification} from "../../../stores";
    import {closeModal, openModal} from "svelte-modals";
    import Modal from "../../../organisms/Modal.svelte";
    import DiscardReasonModal from "../../../organisms/DiscardReasonModal.svelte";
    import RejectReasonModal from "../../../organisms/RejectReasonModal.svelte";
    import ConnectPartnersModal from "../../../organisms/ConnectPartnersModal.svelte";
    import {derived, type Writable} from "svelte/store";
    import NullableDataWrapper from "../../../utils/NullableDataWrapper.svelte";
    import ProgressBarLotIcon from "../../../organisms/ProgressBarLotIcon.svelte";
    import {fade} from "svelte/transition";
    import PrimaryButton from "../../../atoms/PrimaryButton.svelte";
    import SecondaryButton, {SecondaryBtnColors} from "../../../atoms/SecondaryButton.svelte";
    import {lotStateUtils} from "../utils/lotStateUtils";
    import Tooltip from "../../../atoms/Tooltip.svelte";
    import {currentLot, currentProject, localLotsStore, mapFormControls} from "../utils/fakStores";
    import {copyProject, DefaultProject} from "../../../types/project";
    import {copyLot, DefaultLot, type Lot} from "../../types/lot";
    import ProgressBar from "../../../molecules/ProgressBar.svelte";
    import type {LotStateData} from "../../../types/lotStateData";
    import ActionMenu from "../../../organisms/ActionMenu.svelte";
    import ActionMenuItem from "../../../molecules/ActionMenuItem.svelte";
    import {closeFAK} from "../utils/fakUtils";
    import {NotificationType} from "../../../types/notification";

    let itemContext: Writable<any> = getContext("itemContext");

    let lotView: LotStatusButtonsView;
    let customerLink: string;

    let showBlur: boolean = false;
    let actualLotState: LotStateData;
    let isDiscarded: boolean = false;
    let mouseDown: boolean = false;
    let startX: number, scrollLeft: number;
    let slider: HTMLElement = null;
    let fakHTMLElement: Element = document.getElementsByClassName('fak-base-layout')[0];

    let currentLotStateData = derived(
        currentLot,
        $currentLot => $currentLot?.lotStateData
    );

    currentLotStateData.subscribe(() => {
        if ($currentLot) {
            updateVariablesForLotProgressBar();
            scrollToLotState();
        }
    });

    $: if ($itemContext) {
        showBlur = $itemContext.isOpen;
    }

    onMount(() => {
        if ($currentLot && slider) {
            setTimeout(() => {
                scrollToLotState();
            }, 1400);
            initializeVariablesForDraggingProgressBar();
        }
    })


    /** This function initializes variables required for dragging a progress bar. */
    function initializeVariablesForDraggingProgressBar() {
        /** This nested function will be executed when dragging starts. */
        const startDragging = (e) => {
            mouseDown = true;  // Assign true when mouse button is pressed.
            startX = e.pageX - slider.offsetLeft;  // Record the position of mouse when it starts dragging.
            scrollLeft = slider.scrollLeft;  // Get the current scroll position.
            slider.style.cursor = "grabbing";  // Change cursor style to 'grabbing'.
        }

        /** This nested function will be executed when dragging stops. */
        const stopDragging = (e) => {
            mouseDown = false;  // Assign false when the mouse button is released.
            slider.style.cursor = "grab";  // Change cursor style back to 'grab'.
        }

        /** This nested function will be executed when the mouse movements are captured. */
        const move = (e) => {
            e.preventDefault();  // Prevent any default action.
            if (!mouseDown) {
                return;  // If mouse is not pressed then return.
            }
            const x = e.pageX - slider.offsetLeft;  // Get the new position of mouse.
            const scroll = x - startX;  // Calculate the degree of scroll.
            slider.scrollLeft = scrollLeft - scroll;  // Set the new scroll position.
            slider.style.cursor = "grabbing";  // Keep the cursor style to 'grabbing'.
        }

        // Add the event listeners
        slider.addEventListener('mousemove', move, false);  // Add event listener for mouse move events.
        slider.addEventListener('mousedown', startDragging, false);  // Add event listener for mouse down events.
        slider.addEventListener('mouseup', stopDragging, false);  // Add event listener for mouse up events.
        slider.addEventListener('mouseleave', stopDragging, false);  // Add event listener for mouse leave events.
    }

    function scrollToLotState() {
        if (slider) {
            // Scroll to the required position smoothly at the start.
            slider.scrollTo({
                left: 80 + (150 * ($currentLot.lotStateData.indexOf(actualLotState) / ($currentLot.lotStateData.length - 1))),
                behavior: "smooth"
            })
        }
    }

    /**
     * This function is used to update the variables tied to the progress bar of the lot.
     */
    function updateVariablesForLotProgressBar() {

        // Update the lot view status.
        lotView = $currentLot.lotViewStatusChangeMap;

        // Initialize the highest value (max state) amongst the possible states of a lot progress bar.
        actualLotState = $currentLot.lotStateData.findLast(d => d.lotStateDate);

        // Determine whether the current lot has been discarded or not.
        // This is done by filtering the discarded lot states and checking the length of the result.
        isDiscarded = $currentLot.state === LotState.DISCARDED;

        // Build link to the customer associated with the current lot.
        customerLink = buildCustomerLink();
    }

    function handleFirstActionButtonClick() {
        switch ($currentLot.state) {
            case LotState.UNVERIFIED:
            case LotState.SUBMISSION:
            case LotState.PARKED:
                handleTriggerMatchingAndClosingFAK();
                break;
            case LotState.VERIFICATION:
                markApproval();
                break;
            case LotState.OPTIMIZED:
                markHandling(true);
                break;
            case LotState.ACCOUNTING:
                handleChangeLotState(LotState.COMPLETED);
                break;
            case LotState.DISCARDED:
                handleChangeLotState(LotState.UNVERIFIED, true);
                break;
        }
    }

    async function handleTriggerMatchingAndClosingFAK() {
        await changeLotState(LotState.VERIFICATION);
        // trigger the marker selected event to load matches and show on map
        if ($currentLot && $currentLot.state === LotState.VERIFICATION) {
            closeFAK();
            window.dispatchEvent(new CustomEvent('marker-selected', {
                detail: {
                    item: {
                        lotId: $currentLot.id
                    }
                }
            }));
            const url = new URL(window.top.location);
            url.searchParams.set("ref", 'LOT-' + $currentLot.id)
            window.top.history.pushState({}, "", url);
        }
    }

    async function reloadProjectAndLots() {
        await fetchUtils.get(`/api/projectAndLots/${$mapFormControls.projectId}`)
            .then(data => {
                $currentProject = DefaultProject();
                $currentProject = copyProject(data.project, true);
                $localLotsStore.clear();
                for (let loadedLot of data.lots) {
                    let lot = DefaultLot();
                    lot = copyLot(loadedLot, true);
                    $localLotsStore.set(lot.id, lot);
                }
            }).catch((e) => {

            })
    }

    function handleSecondActionButtonClick() {
        switch ($currentLot.state) {
            case LotState.UNVERIFIED:
                handleChangeLotState(LotState.PARKED)
                break;
            case LotState.SUBMISSION:
            case LotState.PARKED:
                changeLotState(LotState.UNVERIFIED);
                break;
            case LotState.VERIFICATION:
                markBidding(true);
                break;
            case LotState.OPTIMIZED:
                markHandling(false);
                break;
            case LotState.HANDLING:
                handleChangeLotState(LotState.ACCOUNTING);
                break;
            case LotState.DISCARDED:
                handleChangeLotState(LotState.UNVERIFIED);
                break;
        }
    }

    function handleThirdActionButtonClick() {
        switch ($currentLot.state) {
            case LotState.UNVERIFIED:
            case LotState.VERIFICATION:
            case LotState.SUBMISSION:
            case LotState.PARKED:
            case LotState.APPROVAL:
            case LotState.BIDDING:
                markAborted();
                break;
            case LotState.OPTIMIZED:
            case LotState.HANDLING:
            case LotState.ACCOUNTING:
            case LotState.COMPLETED:
                markBidding(false);
                break;
            case LotState.DISCARDED:
                deleteLot();
        }
    }

    function handleFourthActionButtonClick() {
        switch ($currentLot.state) {
            case LotState.UNVERIFIED:
            case LotState.VERIFICATION:
                markRejected();
                break;
            case LotState.SUBMISSION:
            case LotState.PARKED:
            case LotState.APPROVAL:
            case LotState.BIDDING:
            case LotState.OPTIMIZED:
            case LotState.HANDLING:
            case LotState.ACCOUNTING:
            case LotState.COMPLETED:
            case LotState.DISCARDED:
        }
    }

    function buildCustomerLink() {
        return `${window.location.origin}/position/${$currentLot.publicId}/`;
    }

    function buildMailConfLink() {
        return `${window.location.origin}/ui/lot/${$currentLot.id}/accountingMailConfiguration`;
    }

    function buildLotQuotesPageLink() {
        return `${window.location.origin}/lot/${$currentLot.id}/quotes`;
    }

    function copyCustomerLink() {
        navigator.clipboard
            .writeText(customerLink)
            .then(function () {
                addNotification({
                    message: $t("UI.lot.customerLink.copied", {customerLink: customerLink}),
                    type: NotificationType.INFO,
                    timeout: 4500,
                    dismissible: true
                });
            });
    }

    function updateStore(data: Lot) {
        $localLotsStore.set(data.id, data);
        $currentLot = $localLotsStore.get(data.id);
        $currentLot.id = $currentLot.id;
        $currentLot.state = $currentLot.state;
        lotView = lotView;
        $localLotsStore = $localLotsStore;
    }

    function handleChangeLotState(newState: LotState, reloadProject = false) {
        openModal(Modal, {
            title: $t('UI.lot.changeState', {state: $t('UI.LotState.' + newState)}),
            acceptText: 'Ja',
            message: $t("Confirmation.yesCancel"), onAccept: async () => {
                closeModal();
                await changeLotState(newState);
                if (reloadProject) {
                    await reloadProjectAndLots();
                }
            }
        });
    }

    async function changeLotState(newState: LotState, message: string = $t('UI.lot.stateChange.notification.success')) {
        await fetchUtils.post(`/api/lot/${$currentLot.id}/changeState`, {newState: newState}, false)
            .then(data => {
                let notification = {message: message, type: NotificationType.SUCCESS, dismissible: true, timeout: 4500};
                addNotification(notification);
                updateStore(data);
            })
            .catch(fetchUtils.catchErrorAndShowNotification());
    }

    function markAborted() {
        openModal(DiscardReasonModal, {
            discardedObjectType: DiscardedObjectType.LOT,
            discardedObjectId: $currentLot.id,
            onDiscard: updateStore,
        });
    }

    function markBidding(skipApproval: boolean) {
        openModal(Modal, {
            title: skipApproval ? $t("UI.map.lot.skipApproval.modal.title") : $t("UI.map.lot.markBidding.modal.title"),
            acceptText: skipApproval ? $t('UI.lot.skipApproval') : $t('UI.lot.markBidding'),
            message: skipApproval ? $t("UI.map.lot.skipApproval.modal.message") : $t("UI.map.lot.markBidding.modal.message"),
            onAccept: () => {
                closeModal();
                changeLotState(LotState.BIDDING);
            },
            update: updateStore,
        });
    }

    function markHandling(showModal: boolean) {
        if (showModal && $currentLot) {
            openModal(ConnectPartnersModal, {
                lotId: $currentLot.id,
                dealPartnerConnect: $currentLot.dealPartnerConnect,
                update: updateStore,
            })
        } else {
            openModal(Modal, {
                title: $t("MarkHandling.confirm.header"), acceptText: 'Ja',
                message: $t("Confirmation.yesCancel"), onAccept: () => {
                    closeModal();
                    changeLotState(LotState.HANDLING);
                },
                update: updateStore,
            });
        }

    }

    function markApproval() {
        openModal(Modal, {
            title: $t("UI.map.lot.markChecked.modal.title"), message: $t("UI.map.lot.markChecked.modal.message"),
            acceptText: $t("UI.button.next"), onAccept: () => {
                closeModal();
                changeLotState(LotState.APPROVAL);
            },
            update: updateStore
        })
    }

    function markRejected() {
        openModal(RejectReasonModal, {
            lotId: $currentLot.id,
            onDiscard: updateStore,
        });
    }

    function deleteLot() {
        let idToDelete = $currentLot.id;
        fetchUtils.get(`/api/lot/${$currentLot.id}/deleteTesting`)
            .then(data => {
                $currentLot = null
                $localLotsStore.delete(idToDelete)
                $localLotsStore = $localLotsStore

                let notification = {
                    message: $t('UI.map.lot.deleteTesting.successful'),
                    type: NotificationType.SUCCESS,
                    dismissible: true,
                    timeout: 4500
                };
                addNotification(notification);
            })
    }

    // this function only changes the BIDDING state to BIDDING_MAIL_SENT or BIDDING_MAIL_SCHEDULED for the progress bar
    function transformLotState(lotStateData: LotStateData) {
        if (lotStateData.lotStatus == LotState.BIDDING && $currentLot?.optimizerMailSent) {
            lotStateData.lotStatus = LotState.BIDDING_MAIL_SENT;
        } else if (lotStateData.lotStatus == LotState.BIDDING && $currentLot?.optimizerMailScheduled) {
            lotStateData.lotStatus = LotState.BIDDING_MAIL_SCHEDULED;
        }
        return lotStateData;
    }

    onMount(() => {
        lotView = $currentLot.lotViewStatusChangeMap;
        customerLink = buildCustomerLink();
    })

</script>

<div class="status-grid">
    <NullableDataWrapper bind:data={$currentLot}>
        <div class="lot-state-progress-bar-header">
            <span>{$t('UI.map.currentState')}</span>
            <div style="position: relative">
                <ActionMenu right="9rem" top="0">
                    <svelte:fragment slot="content">
                        <ActionMenuItem label={$t('UI.lot.copy.customerLink')}
                                        on:click={copyCustomerLink}/>
                        <ActionMenuItem label={$t('UI.lot.show.customerPage')}
                                        on:click={() => window.open(customerLink, '_blank')}/>
                        <ActionMenuItem label={$t('UI.mail.config.link')}
                                        on:click={() => window.open(buildMailConfLink(), '_blank')}/>
                        <ActionMenuItem label={$t('UI.lot.quotes.link.label')}
                                        on:click={() => window.open(buildLotQuotesPageLink(), '_blank')}/>
                        <ActionMenuItem label={$t('UI.lot.journalLink')}
                                        on:click={() => window.open($currentLot.journalLink, '_blank')}/>
                    </svelte:fragment>
                </ActionMenu>
            </div>
        </div>

        <div bind:this={slider} class="wrapper-lot-state-progress-bar">
            <div class="lot-state-progress-bar">
                <ProgressBar actualState={actualLotState} barHeight={0.875} iconWidth={0.875}
                             states={$currentLot.lotStateData.filter(d => d.lotStatus)} let:iconWidth>
                    {#each $currentLot.lotStateData as lotState , i}
                        <ProgressBarLotIcon lotState={transformLotState(lotState)} {isDiscarded} ordinalValue={i + 1}
                                            barHeight={iconWidth}
                                            actualLotState={$currentLot.lotStateData.indexOf(actualLotState) + 1}
                                            maxLotState={$currentLot.lotStateData.length}/>
                    {/each}
                </ProgressBar>
            </div>
            {#if showBlur}
                <div in:fade={{delay:200, duration: 400}} class="progress-bar-blur-right"></div>
                <div in:fade={{delay:200, duration: 400}} class="progress-bar-blur-left"></div>
            {/if}
        </div>
        <div class="lot-state-buttons-container">
            {#if lotStateUtils.showFirstActionButton($currentLot.state, lotView)}
                <div class="first-action-button-container">
                    <div class="status-text">
                        {lotStateUtils.getFirstActionButtonHeadline($currentLot.state)}
                    </div>
                    {#if lotStateUtils.isFirstActionButtonDisabled($currentLot.state, lotView)}
                        <Tooltip appendToObject={fakHTMLElement} msg={$t('ActualTargetComparison.tooltip')}
                                 placementTtip={TooltipPlacements.BOTTOM_START}>
                            <div class="primary-button-container-button">
                                <PrimaryButton label={lotStateUtils.getFirstActionButtonText($currentLot.state)}
                                               fontSize={"0.625rem"}
                                               disabled={lotStateUtils.isFirstActionButtonDisabled($currentLot.state, lotView)}
                                               on:click={handleFirstActionButtonClick} sizeAdaptingToText>
                                </PrimaryButton>
                            </div>
                        </Tooltip>
                    {:else}
                        <div class="primary-button-container-button">
                            <PrimaryButton label={lotStateUtils.getFirstActionButtonText($currentLot.state)}
                                           fontSize={"0.625rem"}
                                           disabled={lotStateUtils.isFirstActionButtonDisabled($currentLot.state, lotView)}
                                           on:click={handleFirstActionButtonClick} sizeAdaptingToText>
                            </PrimaryButton>
                        </div>
                    {/if}
                </div>
            {/if}
            {#if lotStateUtils.showSecondActionButton($currentLot.state, lotView)}
                <div class="secondary-action-button-container">
                    <div class="status-text">
                        {lotStateUtils.getSecondActionButtonHeadline($currentLot.state, lotView)}
                    </div>
                    {#if lotStateUtils.isSecondActionButtonDisabled($currentLot.state, lotView)}
                        <Tooltip appendToObject={fakHTMLElement} msg={$t('Lot.buttons.statusHandling.tooltip')}
                                 placementTtip={TooltipPlacements.BOTTOM_START}>
                            <div class="secondary-button-container-1-button">
                                <SecondaryButton color={lotStateUtils.getSecondActionButtonColor($currentLot.state)}
                                                 label={lotStateUtils.getSecondActionButtonText($currentLot.state, lotView)}
                                                 fontSize={"0.625rem"} on:click={handleSecondActionButtonClick}
                                                 disabled={lotStateUtils.isSecondActionButtonDisabled($currentLot.state, lotView)}
                                                 sizeAdaptingToText/>
                            </div>
                        </Tooltip>
                    {:else}
                        <div class="secondary-button-container-1-button">
                            <SecondaryButton color={lotStateUtils.getSecondActionButtonColor($currentLot.state)}
                                             label={lotStateUtils.getSecondActionButtonText($currentLot.state, lotView)}
                                             fontSize={"0.625rem"} on:click={handleSecondActionButtonClick}
                                             disabled={lotStateUtils.isSecondActionButtonDisabled($currentLot.state, lotView)}
                                             sizeAdaptingToText/>
                        </div>
                    {/if}
                </div>
            {/if}
            {#if lotView && (lotView.showMarkAborted || lotView.showCanDelete || lotView.showMarkBidding)}
                <div class="third-action-button-container">
                    <div class="status-text">
                        {lotStateUtils.getThirdActionButtonHeadline($currentLot.state)}
                    </div>
                    <div class="secondary-button-container-2-button">
                        <SecondaryButton color={SecondaryBtnColors.RED}
                                         label={lotStateUtils.getThirdActionButtonText($currentLot.state)}
                                         fontSize={"0.625rem"}
                                         on:click={handleThirdActionButtonClick} sizeAdaptingToText/>
                    </div>
                </div>
            {/if}
            {#if lotView && lotView.showMarkRejected}
                <div class="fourth-action-button-container">
                    <div class="status-text">
                        {lotStateUtils.getFourthActionButtonHeadline($currentLot.state)}
                    </div>
                    <div class="secondary-button-container-3-button">
                        <SecondaryButton color={SecondaryBtnColors.RED}
                                         label={lotStateUtils.getFourthActionButtonText($currentLot.state)}
                                         fontSize={"0.625rem"}
                                         on:click={handleFourthActionButtonClick} sizeAdaptingToText/>
                    </div>
                </div>
            {/if}
        </div>
    </NullableDataWrapper>
</div>

<style lang="scss">
  @import "../../../../styles/global";

  .status-grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-areas: "buttons ."
                            "buttons link";
    padding: 0 1.5625rem 1.5rem;
  }

  .copy-customer-link:hover {
    cursor: pointer;
  }

  .overlay-transition {
    grid-column: 1/1;
    background-color: white;
    width: 60px;
    height: 16px;
    margin-top: -15px;
    z-index: 2;

    border-radius: 14px;
    background-image: radial-gradient(circle at 54px 7px, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 8px, white 8px);
  }

  li.action-item {
    @include flex-row(0.625rem, $justify: flex-start);

    list-style: none;
    padding: 0.625rem;

    & span {
      @include roboto-font(normal, 400, 0.75rem, black);

      &:hover {
        font-weight: 500;
      }
    }
  }

  .action-link {
    @include roboto-font(normal, 400, 0.75rem, $primaryGreen);

    &:hover {
      font-weight: 500;
    }
  }

  .wrapper-lot-state-progress-bar {
    overflow-x: hidden;
    height: 7rem;
    width: 25rem;
    cursor: grab;
  }

  .lot-state-progress-bar {
    width: 25.125rem;
    margin: 3rem 10rem 2rem 3rem;
  }

  .progress-bar-blur-right {
    width: 4.1875rem;
    height: 7rem;
    background: linear-gradient(270deg, #FFF 69.82%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    top: 87px;
    right: 0;
    z-index: 10;
    float: right;
  }

  .progress-bar-blur-left {
    width: 2.1875rem;
    height: 7rem;
    background: linear-gradient(90deg, #FFF 69.82%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    top: 87px;
    left: 0;
    z-index: 1;
  }

  .lot-state-progress-bar-header {
    @include flex-row($justify: space-between, $alignment: baseline);
    padding-bottom: 0.2rem;

    & > span {
      @include roboto-font(normal, 400, 0.875rem, black);
    }

    & > div {
      display: flex;
    }
  }

  .lot-state-buttons-container {
    @include flex-row(0.38rem, $justify: flex-start);
    flex-wrap: wrap;
    row-gap: 0.4rem;
  }

  .first-action-button-container {
    flex-direction: column;
  }

  .secondary-action-button-container {
    flex-direction: column;
  }

  .third-action-button-container {
    flex-direction: column;
  }

  .fourth-action-button-container {
    flex-direction: column;
  }

  .status-text {
    @include roboto-font(normal, 500, 0.5rem, #9B9B9B);
    height: 0.8rem;
  }


</style>
