<script lang="ts">
    export let checked = false;
    export let disabled = false;
</script>

<style>
    .switch {
        position: relative;
        display: inline-block;
        width: 38px;
        height: 20px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 20px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    input:checked + .slider {
        background-color: #58C079;
    }

    input:checked + .slider {
        box-shadow: 0 0 1px #58C079;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }
</style>

<label class="switch">
    <input type="checkbox" bind:checked {disabled}/>
    <span class="slider"/>
</label>