<script lang="ts">
    import {vehicleCategories} from "./vehicleCategoriesStore.ts";
    import {onMount} from 'svelte';

    onMount(async () => {
        await fetch('/api/vehicleCategories')
            .then(response => response.json())
            .then(json => {
                vehicleCategories.set(json.data ?? [])
            }).catch(error => console.log(error));
    })
</script>