import type {Material} from "../types/material";
import type {Writable} from "svelte/store";
import type {Catalog} from "../types/catalog";

export function getParentProperties(childMaterial: Material, catalog: Writable<Catalog>) {
    let material = childMaterial;
    let propertyIds = [];
    while (material.parentId !== 'root') {
        const indexOfParentMaterial = catalog.nodes.map((node: Material) => node.id).indexOf(material.parentId)
        if (indexOfParentMaterial > -1) {
            catalog.nodes[indexOfParentMaterial].propertyIds.forEach((propertyId: string) => propertyIds.push(propertyId));
            material = catalog.nodes[indexOfParentMaterial];
        } else {
            break;
        }
    }
    return propertyIds;
}