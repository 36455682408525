<script lang="ts">


    import type {FontProps} from "../types/fontProps";
    import {DefaultInputFieldProps, getFontPropsVarString} from "../types/fontProps";
    import {InputIcons} from "../types/enums";

    export let id: string = "input-id" + Math.random().toString(16).slice(2);
    export let value: string | Number = "";
    export let label: string;
    export let placeholder: string = "";
    export let width: string = "100%";
    export let disabled: boolean = false;
    export let onInputFinished = () => {
    };
    export let borderless: boolean = false;
    export let fontProps: FontProps = DefaultInputFieldProps();
    export let icon: InputIcons = InputIcons.NONE;
    export let errorHighlight: boolean = false;


    $: fontPropsStyleString = getFontPropsVarString(fontProps);
    // if value is not falsy value, or if we have label AND placeholder, notEmpty is true
    $: notEmpty = (!!value) || (placeholder && label);
    let materialLabel: string;
    $: {
        if (label) {
            materialLabel = label;
        } else {
            materialLabel = placeholder;
            placeholder = "";
        }
    }
</script>


<div class="material-design" style="{fontPropsStyleString}; --input-width: {width}">
    <input {id} type="text" name={id} aria-labelledby="label-{id}" class:borderless class:notEmpty
           class="{icon !== InputIcons.NONE ? 'icon-' + icon.toString().toLowerCase().replace(/_/g, '-') : ''} {errorHighlight && !value ? 'material-design-error' : ''}"
           bind:value {disabled} {placeholder}
           on:blur={onInputFinished} on:input on:focusout on:keydown on:change/>
    <label class="material-label" for="{id}" id="label-{id}">
        <div class="material-text">{materialLabel}</div>
    </label>
</div>


<style lang="scss">
  @import "../../styles/global";
  @import "../../styles/inputFields";
  @import "../../styles/icons";
</style>
