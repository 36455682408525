<script lang="ts">

    import {CalcMethod} from "../types/enums";
    import {t} from "../../i18n/i18n";
    import {type Lot} from "../optimizer/lot";
    import OfferTypeCheckbox from "./OfferTypeCheckbox.svelte";
    import OfferFactsCard from "./OfferFactsCard.svelte";
    import OfferPriceCard from "./OfferPriceCard.svelte";
    import {EmptyOffer, type Offer, OfferState} from "./types/offer";
    import {type RequestDto, RequestType} from "./types/requestDto";
    import TextArea from "../atoms/TextArea.svelte";
    import {beforeUpdate, createEventDispatcher, getContext, hasContext, onDestroy, onMount} from "svelte";
    import AgbButton from "../organisms/AgbButton.svelte";
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import type {UpdateOfferRequest} from "./types/updateOfferRequest";
    import {fetchUtils} from "../utils/fetchUtils";
    import {DefaultNotification, NotificationType} from "../types/notification";
    import {addNotification} from "../stores";
    import OfferFactsPlace from "./OfferFactsPlace.svelte";
    import {writable, type Writable} from "svelte/store";
    import type {PubPageParams} from "./types/pubPageParams";
    import {isEmptyOffer} from "./offerUtils";

    const dispatch = createEventDispatcher();
    const request: Writable<RequestDto> = getContext("request");
    const urlParams: PubPageParams = getContext("urlParams") || null;
    const lot: Writable<Lot> = getContext("lot");
    const offerMaterial: Writable<Offer> = getContext("offerMaterial");
    const offerMaterialWithTransport: Writable<Offer> = getContext("offerMaterialWithTransport");
    const showKickback: Writable<boolean> = hasContext("showKickback") ? getContext("showKickback") : writable(false);

    export let hideAgbCheckbox: boolean = false;

    let materialWithTranspSelected: boolean = false
    let materialSelected: boolean = false;
    let agbAccepted: boolean = false;
    let {requestPublicId, offerUserPublicId, requestedEntityId, requestedEntityType} = urlParams;

    let counterOfferSent: boolean = false;

    const checkWithTransport = () => materialWithTranspSelected = true;
    const checkMaterial = () => materialSelected = true;

    let mounted: boolean = false;

    // only execute this once before the first mount, we must do it before the children are mounted
    beforeUpdate(() => {
        if (!mounted) {
            materialSelected = !!($offerMaterial && $offerMaterial.offerState != OfferState.DRAFT && !isEmptyOffer($offerMaterial, RequestType.MATERIAL)) || false;
            materialWithTranspSelected = !!($offerMaterialWithTransport && $offerMaterialWithTransport.offerState != OfferState.DRAFT && !isEmptyOffer($offerMaterialWithTransport, RequestType.MATERIAL_WITH_TRANSPORT)) || false;
        }
    })
    onMount(() => {
        // needs to be set such that we do not update the selected values before every update, before the first mount
        // this is necessary, because in the price we adapt the offer.facts.kickback, and then it does not match EmptyOffer
        mounted = true;
    })

    // when we sent a counteroffer, but one of the offers is not selected to be submitted to the server, we need to reset that offer
    // if we cancel the edit, we reload the data from the server, since the object can be edited and the checkbox can be (un)checked
    // by reloading the data from the server,we can omit a deep equal check and also saving states of the offers objects before and after edit
    onDestroy(() => {
        if (!counterOfferSent) {
            $offerMaterial = EmptyOffer(RequestType.MATERIAL);
            $offerMaterialWithTransport = EmptyOffer(RequestType.MATERIAL_WITH_TRANSPORT);
            dispatch('offerChanged');
            return;
        } else {
            if (!materialSelected) {
                $offerMaterial = EmptyOffer(RequestType.MATERIAL);
            }
            if (!materialWithTranspSelected) {
                $offerMaterialWithTransport = EmptyOffer(RequestType.MATERIAL_WITH_TRANSPORT);
            }
        }
    })

    function sendCounterOffer() {
        if (agbAccepted && $offerMaterial.offerState !== OfferState.ACCEPTED && $offerMaterialWithTransport.offerState !== OfferState.ACCEPTED) {
            const url = `/api/optimizer/request/${requestPublicId}/requested/${requestedEntityType}/${requestedEntityId}/user/${offerUserPublicId}/updateCounterOffer`;
            let offerMat = null;
            let offerMatWithTransport = null;
            if (materialSelected) {
                offerMat = {
                    facts: $offerMaterial.facts.toDto(),
                    furtherDetailsComment: $offerMaterial.furtherDetailsComment,
                    offerLocation: $offerMaterial.offerLocation,
                };
            }
            if (materialWithTranspSelected) {
                offerMatWithTransport = {
                    facts: $offerMaterialWithTransport.facts.toDto(),
                    furtherDetailsComment: $offerMaterialWithTransport.furtherDetailsComment,
                }
            }
            let payload: UpdateOfferRequest = {
                offerMaterial: offerMat,
                offerMaterialWithTransport: offerMatWithTransport
            };
            if (!$showKickback) { // for those cases we don't want the optimizer to pay the kickback, we need to subtract it
                if (payload?.offerMaterial) {
                    payload.offerMaterial.price = ($offerMaterial.facts.price ?? $request.facts.price);
                }
                if (payload?.offerMaterialWithTransport) {
                    payload.offerMaterialWithTransport.price = ($offerMaterialWithTransport.facts.price ?? $request.facts.price);
                }
            }
            fetchUtils.post(url, payload)
                .then(_ => {
                    counterOfferSent = true;
                    $offerMaterialWithTransport = $offerMaterialWithTransport;
                    $offerMaterial = $offerMaterial;

                    let message = $lot.calcMethod == CalcMethod.REQUEST_OFFER ?
                        $t('UI.optimizer.priceOffer.sent.confirmation') :
                        $t('UI.optimizer.counterOffer.sent.confirmation');

                    let notification = {
                        message: message, type: NotificationType.SUCCESS, timeout: 0, dismissible: true
                    };
                    addNotification(notification);
                    dispatch('linkAttachmentsByAccept');
                    dispatch('offerChanged');
                })
                .catch(fetchUtils.catchErrorAndShowNotification());

        } else {
            let notification = DefaultNotification($t('UI.TermsAndConditions.notAccepted.error'), NotificationType.ERROR, true, 0);
            addNotification(notification);
        }
    }


</script>

<div class="offer-facts-form__container">
    <div class="offer-facts-form__title">
        <h2>
            {#if $lot.calcMethod === CalcMethod.REQUEST_OFFER}
                {$t("UI.optimizerPage.priceOffer.editor.title")}
            {:else}
                {$t("UI.optimizerPage.counterOffer.editor.title")}
            {/if}
        </h2>
    </div>
    <div class="offer-facts-form__data">
        {#if $request && $request.types.includes(RequestType.MATERIAL_WITH_TRANSPORT)}
            <div class="offer-with-transport offer__modality">
                <OfferTypeCheckbox bind:checked={materialWithTranspSelected}
                                   requestType={RequestType.MATERIAL_WITH_TRANSPORT}
                                   calcMethod={$lot.calcMethod}/>
            </div>
            <div class="offer-with-transport offer-facts-form__data--offer">
                <OfferFactsCard bind:offer={$offerMaterialWithTransport}
                                on:editOffer={checkWithTransport}/>
                <OfferPriceCard bind:offer={$offerMaterialWithTransport} calcMethod={$lot.calcMethod}
                                on:editOffer={checkWithTransport}/>
            </div>
            <div class="offer-with-transport offer__comment">
                <TextArea id="furtherDetailsMaterialWithTransport" bind:text={$offerMaterialWithTransport.furtherDetailsComment}
                          title={$t('UI.Model.moreDetails')} placeholder={$t('UI.remarks.putInDetailsHere')}
                          marginTop="0"/>
            </div>
        {/if}
        {#if $request && $request.types.includes(RequestType.MATERIAL)}
            <div class="offer-material offer__modality">
                <OfferTypeCheckbox bind:checked={materialSelected} requestType={RequestType.MATERIAL}
                                   calcMethod={$lot.calcMethod}/>
            </div>
            <div class="offer-material offer-facts-form__data--offer">
                <OfferFactsCard bind:offer={$offerMaterial}
                                on:editOffer={checkMaterial}/>
                <OfferPriceCard bind:offer={$offerMaterial} calcMethod={$lot.calcMethod}
                                on:editOffer={checkMaterial}/>
            </div>
            <div class="offer-material offer__place">
                <OfferFactsPlace bind:offer={$offerMaterial} on:editOffer={checkMaterial}/>
            </div>
            <div class="offer-material offer__comment">
                <TextArea id="furtherDetailsMaterial" bind:text={$offerMaterial.furtherDetailsComment}
                          title={$t('UI.Model.moreDetails')} placeholder={$t('UI.remarks.putInDetailsHere')}
                          marginTop="0"/>
            </div>
        {/if}
    </div>
    <div class="offer-facts-form__agb">
        <AgbButton bind:agbAccepted hideCheckbox={hideAgbCheckbox}
                   label={$lot.calcMethod === CalcMethod.NORMAL ? $t('UI.optimizerPage.buttons.counteroffer.create') : $t('UI.optimizerPage.buttons.offer')}
                   color={$lot.calcMethod === CalcMethod.NORMAL ? PrimaryBtnColors.ORANGE : PrimaryBtnColors.GREEN}
                   description={$lot.calcMethod === CalcMethod.NORMAL ? $t('UI.counteroffer.optimizer.terms.description') : $t('UI.offer.optimizer.terms.description')}
                   disabledCheckbox={!materialWithTranspSelected && !materialSelected}
                   on:click={sendCounterOffer}/>
    </div>
    <div class="offer-facts-form__buttons">
        <div class="mr-3">
            <SecondaryButton color={SecondaryBtnColors.GREY} label={$t("UI.button.cancel")}
                             on:click={() => dispatch("cancelEdit")}/>
        </div>
    </div>
</div>


<style lang="scss">
  @use "../../styles/global";

  .offer-facts-form__container {
    @include global.flex-col();
    margin-top: 1.875rem;

    & > div {
      width: 100%;
    }
  }

  .offer-facts-form__title {
    margin: 0 0 1rem;
    text-align: left;
  }


  .offer-facts-form__data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 7rem;
    grid-row-gap: 1.375rem;
  }

  .offer-facts-form__data--offer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 1.375rem;
  }

  .offer-facts-form__agb {
    margin-top: 1.875rem;
  }

  .offer-facts-form__buttons {
    @include global.flex-row($justify: flex-end);
  }

  /* order of the rows for desktop, not important for mobile since we have a one-row layout there */
  @media screen and (min-width: 992px) {
    .offer-with-transport {
      grid-column-start: 1;
      grid-column-end: 2;
    }

    .offer-material {
      grid-column-start: 2;
      grid-column-end: 2;
    }

    .offer__modality {
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .offer-facts-form__data--offer {
      grid-row-start: 2;
      grid-row-end: 2;
    }
    .offer__place {
      grid-row-start: 3;
      grid-row-end: 3;
    }

    .offer__comment {
      grid-row-start: 4;
      grid-row-end: 4;
    }
  }


  /* Tablets*/
  @media screen and (max-width: 992px) {
    .offer-facts-form__data {
      grid-template-columns: 1fr;
    }

    .offer-facts-form__data--offer {
      grid-template-columns: 1fr 1fr;
    }

    .offer-with-transport.offer__modality {
      margin-top: 1.375rem;
    }
  }

  /*Phones*/
  @media screen and (max-width: 768px) {
    .offer-facts-form__data--offer {
      grid-template-columns: 1fr;
    }
  }

</style>
