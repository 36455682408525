<script lang="ts">
    import {onMount} from 'svelte';
    import {t} from "../../i18n/i18n.js";
    import {NotificationType} from "../types/notification";
    import {addNotification, hideAnimation, showAnimation} from "../stores.js";
    import {AttachmentParentType, ButtonIcons, LoadingAnimation, TooltipPlacements} from "../types/enums";
    import Attachment from "../molecules/Attachment.svelte";
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import Tooltip from "../atoms/Tooltip.svelte";
    import {closeModal, openModal} from "svelte-modals";
    import Modal from "./Modal.svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import {AttachmentVisibilityManagement} from "../types/attachment";
    import iconFileUpload from "@/icons/icon_file_upload.svg";

    export let readOnly: boolean = false;
    export let attachments = [];
    export let uploadUrl: string | null = null;
    export const eventByAccept = () => linkPopOrProfileAttachments();
    export let linkUrl: string = "";
    export let filePrefix: string = "";
    export let visibleToCustomer = 0;
    export let visibleToPartner = 0;
    export let updateFun = () => {
    };
    export let uploadCompleted: boolean = false;
    export let hideHints: boolean = false;
    export let parentPublicId: string | null = null;
    export let showVisibilityManagement: boolean = false;
    export let editVisibilityManagement: boolean = false;
    export let visibilityUrl: string = "";
    export let appendToObject:  Element | "parent" | ((ref: Element) => Element) | undefined = document.body
    export let canNotUpload:boolean = false;
    export let canNotDelete:boolean = false;
    export let id: string = "";

    let selectedAttachments: any[] = [];
    let noAttachments: boolean;
    let noSelectedAttachments: boolean;

    let gridWidth: string = "auto";
    let windowWidth;

    let fileInput: HTMLInputElement;
    let files;

    let dragCounter: number = 0;

    onMount(async () => {
        let dropChildren = document.querySelectorAll(".drop");

        for (let i = 0; i < dropChildren.length; i++) {
            dropChildren[i].addEventListener("dragenter", e => dragEnterEmptyAttachments(e));
            dropChildren[i].addEventListener("dragleave", e => dragLeaveEmptyAttachments(e));
        }
        updateFun();
    })

    window.addEventListener("fetchAttachments", updateFun);

    const downloadURI = (uri, name) => {
        const link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function addOrRemoveAttachment(event) {
        let attachmentDetails = {
            attachmentId: event.detail.attachmentId,
            canDelete: event.detail.canDelete,
            parentType: event.detail.parentType,
            entityId: event.detail.entityId
        }
        if (selectedAttachments.some(attachment => attachment.attachmentId === attachmentDetails.attachmentId)) {
            const index = selectedAttachments.findIndex((element, index) => {
                if (element.attachmentId === attachmentDetails.attachmentId) {
                    return true
                }
            })
            selectedAttachments.splice(index, 1)
        } else {
            selectedAttachments.push(attachmentDetails)
        }
        selectedAttachments = selectedAttachments;
    }

    function downloadSelectedAttachments() {
        attachments.forEach(attachment => {
            if (selectedAttachments.some(innerAttachment => innerAttachment.attachmentId === attachment.attachmentId)) {
                downloadURI(attachment.downloadUri, attachment.filename)
            }
        })
    }

    function askDeleteAttachments() {
        openModal(Modal, {
            title: $t("UI.attachment.deleteModal.title"), denyText: $t("UI.attachment.deleteModal.accept"),
            message: $t("UI.attachment.deleteModal.message"),
            onDeny: async () => {
                closeModal();
                await deleteAttachments()
            },
        });
    }

    async function deleteAttachments() {
        if (selectedAttachments.length === 0) {
            refreshAttachments();
            return;
        }

        let request = {
            parentPublicId,
            attachments: selectedAttachments,
        };

        await fetchUtils.post("/api/attachments/delete", request, false).then(() => {
            updateFun();
            let notification = {
                message: $t('UI.FileUploadDD.deleteSuccessful'),
                type: NotificationType.SUCCESS,
                timeout: 4000,
                dismissible: false
            };
            addNotification(notification);
        }).catch(() => {
            updateFun();
            let notification = {
                message: $t('UI.FileUploadDD.deleteError'),
                type: NotificationType.ERROR,
                timeout: 5000,
                dismissible: true
            };
            addNotification(notification);
        });
        updateFun();
        refreshAttachments();
    }

    function refreshAttachments() {
        attachments = attachments;
        uncheckAllAttachments();
    }

    function uncheckAllAttachments() {
        selectedAttachments = [];
        attachments.forEach(att => {
            let checkbox = document.getElementById("checkbox-" + att.attachmentId);
            if (checkbox) {
                checkbox.checked = false;
            }
        });
    }

    function checkAllAttachments() {
        if (selectedAttachments.length === attachments.length) {
            uncheckAllAttachments()
        } else {
            selectedAttachments = [];
            selectedAttachments.push(...attachments);
            attachments.forEach(att => {
                let checkbox = document.getElementById("checkbox-" + att.attachmentId);
                if (checkbox) {
                    checkbox.checked = true;
                }
            });
        }
    }

    function linkPopOrProfileAttachments() {
        let formData = new FormData();
        let attachmentEntityIds = [];
        let attachmentEntityParentTypes = [];
        attachments.forEach(attachment => {
            if (attachment.parentType === AttachmentParentType.PARENT_PROFILE ||
                attachment.parentType === AttachmentParentType.PARENT_POP) {
                attachmentEntityParentTypes.push(attachment.parentType.toString());
                attachmentEntityIds.push(attachment.entityId);
            }
        })
        if (attachmentEntityIds.length === 0) {
            return;
        }
        formData.append("attachmentEntityIds", attachmentEntityIds.join());
        formData.append("attachmentEntityParentTypes", attachmentEntityParentTypes.join());
        fetch(linkUrl, {
            method: "POST",
            body: formData,
        }).then(response => response.text())
            .then((responseParams) => {
                let response = JSON.parse(responseParams)
                if (response.success) {
                    let notification = {
                        message: $t('UI.FileUploadDD.linkSuccessful'),
                        type: NotificationType.SUCCESS,
                        timeout: 4000,
                        dismissible: false
                    };
                    addNotification(notification);
                } else {
                    updateFun();
                    let notification = {
                        message: $t('UI.FileUploadDD.linkError') + ' ' + response.errorMessage,
                        type: NotificationType.ERROR,
                        timeout: 0,
                        dismissible: true
                    };
                    addNotification(notification);
                }
            })
    }

    function fileUploadOnDragOver(ev) {
        ev.preventDefault(); // prevent file from being opened
        ev.stopPropagation();
        if (ev.dataTransfer) {
            try {
                ev.dataTransfer.dropEffect = "copy";
            } catch {
            } /* eslint-disable-line no-empty */
        }
    }

    function dragEnterEmptyAttachments() {
        dragCounter++;
    }

    function dragLeaveEmptyAttachments(ev) {
        if (ev) {
            dragCounter--;
        }
    }

    async function uploadFiles(ev) {
        showAnimation(LoadingAnimation.FileUpload)
        ev.preventDefault(); // prevent file from being opened

        let dTIs = ev.dataTransfer.items; // dataTransferItems
        if (dTIs) {
            for (let i = 0; i < dTIs.length; i++) {
                if (dTIs[i].kind === 'file') {
                    const file = dTIs[i].getAsFile();
                    await upload(file);
                }
            }
        } else {
            let dTFs = ev.dataTransfer.files; // dataTransferFiles
            for (let i = 0; i < dTFs.length; i++) {
                await upload(dTFs[i]);
            }
        }
        dragCounter = 0;
        setTimeout(() => {
            updateFun();
            hideAnimation();
        }, 600);
    }

    async function multiUpload(files) {
        for (const file of files) {
            await upload(file);
        }
    }

    async function upload(file) {
        let formData = new FormData();
        formData.append("fileName", filePrefix + file.name);
        formData.append("fileData", file);
        formData.append("visibleToCustomer", visibleToCustomer.toString());
        formData.append("visibleToPartner", visibleToPartner.toString());

        let response = await fetch(uploadUrl, {
            method: "POST",
            body: formData,
        });
        let responseBody = await response.json();
        if (responseBody.success) {
            updateFun();
            let notification = {
                message: $t('UI.FileUploadDD.uploadSuccessful'),
                type: NotificationType.SUCCESS,
                timeout: 4000,
                dismissible: false
            };
            addNotification(notification);
            refreshAttachments();
            uploadCompleted = true;
        } else {
            let notification = {
                message: $t('UI.FileUploadDD.uploadError'),
                type: NotificationType.ERROR,
                timeout: 0,
                dismissible: true
            };
            addNotification(notification);
        }
    }

    $: if (uploadUrl) refreshAttachments();
    $: selectAllButton = selectedAttachments.length === attachments.length ? $t('UI.FileUploadDD.selectNone') : $t('UI.FileUploadDD.selectAll');
    $: selectedAttachmentsAreNotDeletable = selectedAttachments.some(attachment => attachment.canDelete === false) || selectedAttachments.length === 0 || readOnly || canNotDelete;
    $: noSelectedAttachments = selectedAttachments.length === 0;
    $: if (attachments) {
        noAttachments = attachments.length === 0;
    }
    $: hideDeleteButton = selectedAttachmentsAreNotDeletable || noAttachments;
    $: if (!showVisibilityManagement && windowWidth > 1110 && attachments.length !== 0) {
        if (attachments.length === 1) {
            gridWidth = "25%";
        } else if (attachments.length === 2) {
            gridWidth = "50%";
        } else if (attachments.length === 3) {
            gridWidth = "75%";
        } else if (attachments.length > 3) {
            gridWidth = "97%";
        }
    } else {
        gridWidth = "auto";
    }
    $: if (files) {
        showAnimation(LoadingAnimation.FileUpload)
        multiUpload(files);
        setTimeout(() => {
            hideAnimation();
        }, 600);
    }
</script>

<svelte:window bind:innerWidth={windowWidth}/>

<div class="file-upload-container">
    {#if !hideHints}
        <span>{$t('UI.FileUpload.title')}</span>
    {/if}
    {#if !readOnly && !canNotUpload}
        {#if attachments === undefined || attachments.length === 0}
            {#if !hideHints}
                <span>{$t('UI.FileUpload.info')}</span>
            {/if}
            <div class="file-upload-drag-drop" id="fileUploadDrop" style="display: flex; justify-content: center;"
                 class:greenBackgroundHover={dragCounter > 0}
                 on:drop={(e) => uploadFiles(e)}
                 on:dragover={(e) => fileUploadOnDragOver(e)}
                 on:dragleave={(e) => dragLeaveEmptyAttachments(e)}
                 on:dragenter={dragEnterEmptyAttachments}>
                <div class="empty-file-upload">
                    <img src={iconFileUpload} alt={$t('UI.attachments.upload')}/>
                    <span>{@html $t('UI.FileUploadDD.info')}</span>
                    <div>{$t('UI.FileUploadDD.supportedFiles.info')}</div>
                </div>
            </div>
        {/if}
        {#if attachments && attachments.length > 0}
            <div class="file-upload-drag-drop-attachments"
                 on:drop="{(e) => uploadFiles(e)}"
                 on:dragover="{(e) => fileUploadOnDragOver(e)}"
                 on:dragleave="{(e) => dragLeaveEmptyAttachments(e)}"
                 on:dragenter={() => dragEnterEmptyAttachments()}
                 class:greenBackgroundHover={dragCounter > 0}>
                <div class="file-upload-drag-drop-attachments-grid" class:opacity-30={dragCounter > 0}
                     style="width: {gridWidth};">
                    {#each attachments as attachment}
                        {@const visibilityManagement = showVisibilityManagement ? AttachmentVisibilityManagement.SHOW : AttachmentVisibilityManagement.HIDE}
                        <Attachment bind:attachment {visibilityUrl} {visibilityManagement}
                                    on:attachmentSelected={addOrRemoveAttachment}/>
                    {:else}
                        <span class:invisible={dragCounter > 0}>{$t('UI.FileUploadDD.noFiles')}</span>
                    {/each}
                </div>
                <div class="drag-enter-state empty-file-upload" class:visible-flex={dragCounter > 0}>
                    <img src={iconFileUpload} alt="{$t('UI.attachments.upload')}"/>
                    <span>{@html $t('UI.FileUploadDD.info')}</span>
                    <div>{$t('UI.FileUploadDD.supportedFiles.info')}</div>
                </div>
            </div>
        {/if}
    {:else}
        <!-- if FileUpload is readOnly -->
        {#if attachments === undefined || attachments.length === 0}
            {#if !hideHints}
                <span>{$t('UI.FileUpload.info.project.complete')}</span>
            {/if}
        {/if}
        {#if attachments && attachments.length > 0}
            <div class="file-upload-drag-drop-attachments"
                 class:greenBackgroundHover={dragCounter > 0}>
                <div class="file-upload-drag-drop-attachments-grid" class:opacity-30={dragCounter > 0}
                     style="width: {gridWidth};">
                    {#each attachments as attachment}
                        {@const visibilityManagement = editVisibilityManagement ? AttachmentVisibilityManagement.SHOW : showVisibilityManagement ? AttachmentVisibilityManagement.READONLY : AttachmentVisibilityManagement.HIDE}
                        <Attachment bind:attachment {visibilityUrl} {visibilityManagement}
                                    on:attachmentSelected={addOrRemoveAttachment}/>
                    {:else}
                        <span class:invisible={dragCounter > 0}>{$t('UI.FileUploadDD.noFiles')}</span>
                    {/each}
                </div>
            </div>
        {/if}
    {/if}
    <div class="align-right" class:readOnly={readOnly && !showVisibilityManagement}>
        {#if selectedAttachmentsAreNotDeletable}
            <Tooltip msg={$t('UI.FileUploadDD.disableDownload')} placementTtip={TooltipPlacements.RIGHT}
                     ttipIconImage="" maxWidthTtip="250px" {appendToObject}>
                <SecondaryButton bind:disabled={selectedAttachmentsAreNotDeletable} hide={noSelectedAttachments}
                                 label={$t('UI.FileUploadDD.deleteAttachments')} color={SecondaryBtnColors.RED}
                                 leftIcon={ButtonIcons.TRASH}/>
            </Tooltip>
        {/if}
        <!--<ConfirmDeleteAttachments let:confirm="{confirmThis}">-->
        <SecondaryButton hide={hideDeleteButton} label={$t('UI.FileUploadDD.deleteAttachments')}
                         color={SecondaryBtnColors.RED} leftIcon={ButtonIcons.TRASH}
                         on:click={askDeleteAttachments}/>
        <!--</ConfirmDeleteAttachments>-->
        <div style="margin-left: 15px">
            <SecondaryButton hide={noSelectedAttachments} label={$t('UI.FileUploadDD.download')}
                             leftIcon={ButtonIcons.DOWNLOAD} on:click={downloadSelectedAttachments}/>
        </div>
        <div style="margin-left: 15px">
            <SecondaryButton bind:label={selectAllButton} hide={noAttachments} color={SecondaryBtnColors.BLUE}
                             on:click={checkAllAttachments}/>
        </div>
        {#if !readOnly && !canNotUpload}
            <div style="margin-left: 15px">
                <SecondaryButton label={$t('UI.FileUploadDD.upload')} leftIcon={ButtonIcons.PLUS}
                                 on:click={() => fileInput.click()}/>
            </div>
        {/if}
        {#if canNotUpload && attachments !== undefined && attachments.length !== 0}
            <div style="margin-left: 15px">
            <Tooltip msg={$t('UI.FileUploadDD.disableUpload')} placementTtip={TooltipPlacements.RIGHT}
                     ttipIconImage="" maxWidthTtip="250px">
                <SecondaryButton disabled label={$t('UI.FileUploadDD.upload')}
                                 leftIcon={ButtonIcons.PLUS}/>
            </Tooltip>
            </div>
        {/if}
        <input style="display:none" type="file" bind:this={fileInput} bind:files multiple
               on:click={(e) => {e.target.value = '';files = null;}}/>
    </div>
</div>


<style lang="scss">
  @import "../../styles/global";

  .file-upload-container {
    @include flex-col(1em, $alignment: flex-start);

    & > span:first-child {
      @include roboto-font(15px, 500, 1em, black);
    }
  }

  .file-upload-drag-drop {
    background: rgba(from $primaryColor r g b / 10%);
    //background: $primaryGreenBackground;
    border: 1px dashed $primaryColor;
    border-radius: 4px;
    padding: 2.375rem;
    height: 20.1875rem;
    width: 100%;

    &:hover {
      background: rgba(from $primaryColor r g b / 30%);
      //background: $primaryGreenBackgroundHover;
    }
  }

  .file-upload-drag-drop-on-error {
    background: $primaryRedBackgroundHover;
    border: 1px dashed $primaryRed;

    & > div {
      & > span {
        @include roboto-font(13px, 400, 0.875rem, $primaryRed);
      }
    }
  }


  .file-upload-drag-drop-attachments {
    @extend .file-upload-drag-drop;
    padding: 0.75rem;

    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .file-upload-drag-drop-attachments-grid {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(11.625rem, 1fr));
    grid-column-gap: 0.938rem;
    grid-row-gap: 0.938rem;
    justify-content: center;
    justify-items: center;

    & > span:last-child {
      grid-column-start: 1;
      grid-column-end: -1;
      margin-top: 101px;
      @include roboto-font(13px, 400, 0.875rem, $primaryColor);
    }
  }

  .opacity-30 {
    opacity: 0.3;
  }


  .greenBackgroundHover {
    background: rgba(from $primaryColor r g b / 30%); //$primaryGreenBackgroundHover;
  }

  .drag-enter-state {
    z-index: 5;
    opacity: 1;
    display: none !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .visible-flex {
    display: flex !important;
  }

  .invisible {
    display: none !important;
  }

  .empty-file-upload {
    @include flex-col();

    & > img {
      margin-bottom: 1rem;
      height: 63px;
      width: 65px;
    }

    & > span {
      @include roboto-font(13px, 400, 0.875rem, $primaryColor);
    }

    & > div:last-child {
      @include roboto-font(11px, 400, 0.75rem, $grey-700);
      background: #FFFFFF;
      border-radius: 2px;
      margin-top: 1rem;
      padding: 0.375rem 0.625rem 0.313rem 0.625rem;
    }
  }

  .align-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    flex-flow: row wrap;
    align-content: space-between;
    row-gap: 5px;
  }

  .readOnly {
    display: none;
  }

</style>
