<script lang="ts">
    import {t} from "../../../../i18n/i18n";
    import PrimaryButton, {PrimaryBtnColors} from "../../../atoms/PrimaryButton.svelte";
    import {openModal} from "svelte-modals";
    import DiscardReasonModal from "../../../organisms/DiscardReasonModal.svelte";
    import {DiscardedObjectType} from "../../../types/enums";
    import {copyProject} from "../../../types/project";
    import {copyLot, DefaultLot} from "../../types/lot";
    import {currentLot, currentProject, localLotsStore} from "../utils/fakStores";


    function cancelProject() {
        openModal(DiscardReasonModal, {
            discardedObjectType: DiscardedObjectType.PROJECT,
            discardedObjectId: $currentProject.id,
            onDiscard: updateProjectAndLots,
        });
    }

    function updateProjectAndLots(data) {
        $currentProject = copyProject(data.project);
        $localLotsStore.clear();
        for (let loadedLot of data.lots) {
            let lot = DefaultLot();
            lot = copyLot(loadedLot);
            $localLotsStore.set(lot.id, lot);
        }
        $localLotsStore = $localLotsStore;
        if ($currentLot) {
            $currentLot = $localLotsStore.get($currentLot.id);
        }
        window.dispatchEvent(new CustomEvent("closeProjectDetailsAccordion"));
    }

</script>

<li class="fak-acc-item">
    <div class="project-state-changer">
        <span class="project-state-label">{$t('UI.map.card.project.changeState')}</span>
        <div class="project-state-btn">
            <PrimaryButton id="button-cancel-project" on:click={cancelProject} label="Stornieren"
                           color={PrimaryBtnColors.RED}
                           width="6.188rem"
                           disabled={!$currentProject.canBeCanceled}/>
        </div>
    </div>
</li>

<style lang="scss">
  @import "../../../../styles/global";
  @import "../../../../styles/fak";

  .project-state-changer {
    background: #FFFFFF;
    border-radius: 0.25rem;
    user-select: none;
    height: 3.875rem;
  }

  .project-state-label {
    padding-left: 1.5625rem;
    padding-top: 1.5rem;
    float: left;
    @include roboto-font(19px, 400);
  }

  .project-state-btn {
    float: right;
    padding-right: 1.25rem;
    padding-top: 0.9375rem;
  }

</style>
