<script lang="ts">

    import {t} from "../../i18n/i18n";
    import PrimaryButton from "../atoms/PrimaryButton.svelte";
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import ChipList from "../organisms/ChipList.svelte";
    import {ButtonIcons, LoadingAnimation, LotMailNotificationType, TooltipPlacements} from "../types/enums";
    import {setContext} from "svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import type {AccountingMailConfigData} from "../types/accountingMailConfigData";
    import {DefaultAccountingMailConfigData} from "../types/accountingMailConfigData";
    import {closeModal, openModal} from "svelte-modals";
    import Modal from "../organisms/Modal.svelte";
    import {get} from "svelte/store";
    import {DefaultNotification, NotificationType} from "../types/notification";
    import {addNotification, hideAnimation, showAnimation} from "../stores";
    import MailConfigModal from "../organisms/MailConfigModal.svelte";
    import DatePickerInput from "../atoms/DatePickerInput.svelte";
    import dayjs from "dayjs";
    import Tooltip from "../atoms/Tooltip.svelte";
    import {DefaultRobotoFontProps} from "../types/fontProps";
    import Switch from "../atoms/Switch.svelte";
    import MmLoadingAnimation from "../animations/MmLoadingAnimation.svelte";
    import Basic from "../templates/Basic.svelte";

    export let lotId: string;
    let allMailsSent: boolean = false;
    let allDispatchingMailsSent: boolean = false;
    let readOnly: boolean = false;
    let disabledLetsGoMail: boolean = false;
    let shouldSendLetsGoMail: boolean = true;
    let isDataFetched: boolean = false;
    let stateOrdinal: number = 0;
    let showTimeline: boolean = false;
    let disableSendMailButton: boolean = false;
    let mailingListSize: number = 3;
    let isMailDispatchingDisabled: boolean = true;
    let mailingList: string[] = null;
    let defaultReceiverList: string[] = null;
    let accountingMailConfigData: AccountingMailConfigData = DefaultAccountingMailConfigData();
    let actualTargetComparisonFilled: boolean = false;
    let disableTriggerStartMailDispatchingButton: boolean = false;
    let labelTriggerMailDispatching: string;
    let btnColorTriggerMailDispatching: SecondaryBtnColors = SecondaryBtnColors.GREEN;
    let indexNextManualMail: number;
    let holidayDaysList: string[] = [];

    let firstLotMailDurationDate: number;
    let secondLotMailDurationDate: number;
    let thirdLotMailDurationDate: number;
    let fourthLotMailDurationDate: number;

    let savedFirstLotMailDurationDate: number;
    let savedSecondLotMailDurationDate: number;
    let savedThirdLotMailDurationDate: number;
    let savedFourthLotMailDurationDate: number;

    const disableDays = (date: Date) => {
        let formattedDate = date.getFullYear() + "-" + (date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-" + (date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate());
        const isBeforeDate = (dateA, dateB) => dateA < dateB;
        // return true to disable
        return (date.getDay() === 0 || date.getDay() === 6) || holidayDaysList.includes(formattedDate) || isBeforeDate(date, new Date());
    }

    setContext('useMaterialDesign', true);

    async function loadData() {
        await fetchStateData();
        await handleLetsGoMails();
        stateOrdinal >= 7 && handleAccountingMails(); // HANDLING
    }

    async function handleLetsGoMails() {
        await fetchLetsGoMailData();
        isDataFetched = true;
        if (stateOrdinal >= 5) { // BIDDING
            disabledLetsGoMail = true;
        }
    }

    function handleAccountingMails() {
        fetchData();
        disabledLetsGoMail = true;
        showTimeline = true;
    }

    function getTTipTriggerMailDispatching(): string {
        if (readOnly) {
            return $t('UI.mail.config.disable.button.ttip.message.readOnly');
        } else if (allMailsSent) {
            return $t('UI.mail.config.disable.button.ttip.message.internalMailsSent');
        } else if (actualTargetComparisonFilled) {
            return $t('UI.mail.config.disable.button.ttip.message.actFilled');
        } else if (allDispatchingMailsSent) {
            return $t('UI.mail.config.disable.button.ttip.message.externalMailsSent');
        }
    }

    function fetchData() {
        fetchUtils.get(`/api/lot/accountingMailData/${lotId}`)
            .then(data => {
                accountingMailConfigData = Object.assign(data);
                mailingList = accountingMailConfigData.mailingList;
                defaultReceiverList = accountingMailConfigData.receiverList;
                defaultReceiverList.forEach(receiver => mailingList.unshift(receiver));
                isMailDispatchingDisabled = accountingMailConfigData.isAccountingMailDispatchingDisabled;
                allMailsSent = accountingMailConfigData.accountingMailData.filter(data => data.dispatchDateMail).length === 5;
                allDispatchingMailsSent = accountingMailConfigData.accountingMailData.filter(data => data.mailType === LotMailNotificationType.FOURTH_MAIL).filter(data => data.dispatchDateMail).length === 1;
                actualTargetComparisonFilled = accountingMailConfigData.actualTargetComparisonFilled;
                readOnly = accountingMailConfigData.readOnly;
                indexNextManualMail = accountingMailConfigData.accountingMailData.filter(maiData => maiData.mailType !== LotMailNotificationType.FIFTH_MAIL).reduce((iMax, x, i) => x.dispatchDateMail && x.state === 'Versendet' ? i : iMax, -1) + 1;
                holidayDaysList = accountingMailConfigData.holidayDays;
                saveFetchedDates(accountingMailConfigData);
            })
            .catch(fetchUtils.catchErrorAndShowNotification());
    }

    async function fetchLetsGoMailData() {
        await fetchUtils.get(`/api/lot/${lotId}/shouldSendLetsGoMail`)
            .then(data => {
                shouldSendLetsGoMail = data;
            })
            .catch(fetchUtils.catchErrorAndShowNotification());
    }

    async function fetchStateData() {
        await fetchUtils.get(`/api/lot/${lotId}/stateOrdinal`)
            .then(data => {
                stateOrdinal = data;
            })
            .catch(fetchUtils.catchErrorAndShowNotification());
    }

    async function updateSendLetsGoMail() {
        if (isDataFetched && !disabledLetsGoMail) {
            const response = await fetchUtils.post(`/api/lot/${lotId}/updateSendLetsGoMail`)
                .then(response => {
                    let notification = {
                        message: get(t)('UI.notification.saved'),
                        type: NotificationType.SUCCESS,
                        timeout: 4000,
                        dismissible: false
                    };
                    addNotification(notification);
                })
        }
    }

    function saveFetchedDates(mailConfigData: AccountingMailConfigData) {
        mailConfigData.accountingMailData.forEach(mailConfigData => {
            if (onlyContainsNumbers(mailConfigData.state)) {
                let baseDate = new Date();
                baseDate.setMinutes(0);
                baseDate.setSeconds(0);
                baseDate.setMilliseconds(0);
                baseDate.setHours(0);
                if (mailConfigData.mailType === LotMailNotificationType.FIRST_MAIL) {
                    let state = parseInt(mailConfigData.state);
                    savedFirstLotMailDurationDate = dayjs(baseDate).add(state, 'days').unix();
                    firstLotMailDurationDate = savedFirstLotMailDurationDate
                } else if (mailConfigData.mailType === LotMailNotificationType.SECOND_MAIL) {
                    let state = parseInt(mailConfigData.state);
                    savedSecondLotMailDurationDate = dayjs(baseDate).add(state, 'days').unix();
                    secondLotMailDurationDate = savedSecondLotMailDurationDate
                } else if (mailConfigData.mailType === LotMailNotificationType.THIRD_MAIL) {
                    let state = parseInt(mailConfigData.state);
                    savedThirdLotMailDurationDate = dayjs(baseDate).add(state, 'days').unix();
                    thirdLotMailDurationDate = savedThirdLotMailDurationDate;
                } else if (mailConfigData.mailType === LotMailNotificationType.FOURTH_MAIL) {
                    let state = parseInt(mailConfigData.state);
                    savedFourthLotMailDurationDate = dayjs(baseDate).add(state, 'days').unix();
                    fourthLotMailDurationDate = savedFourthLotMailDurationDate;
                }
            }
        })
    }

    function updateMailDuration(mailType: LotMailNotificationType, newMailDuration: number) {

        if (!isValidTime(mailType, newMailDuration)) {
            return;
        }

        let request = {
            lotId: lotId,
            mailType: mailType,
            newMailDuration: dayjs.unix(newMailDuration).add(12, 'h').toISOString()
        };
        fetchUtils.post(`/api/lot/${lotId}/accountingMailData/updateDuration`, request)
            .then(_ => {
                let notification = {
                    message: get(t)('UI.notification.saved'),
                    type: NotificationType.SUCCESS,
                    timeout: 4000,
                    dismissible: false
                };
                addNotification(notification);
                fetchData();
            })
            .catch(() => {
                let notification = {
                    message: get(t)('UI.notification.error.INVALID_DAY'),
                    type: NotificationType.ERROR,
                    timeout: 3000,
                    dismissible: true
                };
                addNotification(notification);
            });
    }

    function isValidTime(mailType: LotMailNotificationType, newMailDuration: number): boolean {
        // this is needed because reactivity fires second date with diff of 9 hours
        if (isNaN(newMailDuration) || dayjs(dayjs.unix(newMailDuration).add(12, 'h').toISOString()).isBefore(dayjs(new Date()))) {
            return false;
        }
        if (mailType === LotMailNotificationType.FIRST_MAIL) {
            if (newMailDuration === savedFirstLotMailDurationDate) {
                return false;
            }
        } else if (mailType === LotMailNotificationType.SECOND_MAIL) {
            if (newMailDuration === savedSecondLotMailDurationDate) {
                return false;
            }
        } else if (mailType === LotMailNotificationType.THIRD_MAIL) {
            if (newMailDuration === savedThirdLotMailDurationDate) {
                return false;
            }
        } else if (mailType === LotMailNotificationType.FOURTH_MAIL) {
            if (newMailDuration === savedFourthLotMailDurationDate) {
                return false;
            }
        }
        return true;
    }

    async function updateMailingList() {
        let mailingListToSend: AccountingMailConfigData = DefaultAccountingMailConfigData();
        mailingListToSend.mailingList = mailingList.filter(entry => !defaultReceiverList.includes(entry));
        let request = {mailingList: mailingListToSend.mailingList};
        await fetchUtils.post(`/api/lot/accountingMailData/${lotId}/updateMailingList`, request)
            .then(data => {
                mailingList = Object.assign(data);
                defaultReceiverList.forEach(receiver => mailingList.unshift(receiver));
                mailingListSize = mailingList.length;
            }).catch((error) => {
                let notification = DefaultNotification(error.message ?? error, NotificationType.ERROR, true, 0);
                addNotification(notification);
                mailingList.pop();
                mailingList = mailingList;
            })
    }

    function handleTriggerMailDispatching() {
        if (isMailDispatchingDisabled) {
            openModal(Modal, {
                title: $t('UI.mail.config.modal.title.start.dispatching'),
                onAccept: () => {
                    triggerMailDispatching();
                    closeModal();
                },
                message: $t('UI.mail.config.modal.message.start.dispatching'),
                acceptText: $t("UI.mail.config.modal.button.start.dispatching"),
            })
        } else {
            openModal(Modal, {
                title: $t('UI.mail.config.modal.title.break.dispatching'),
                onDeny: () => {
                    triggerMailDispatching();
                    closeModal();
                },
                message: $t('UI.mail.config.modal.message.stop.dispatching'),
                denyText: $t("UI.mail.config.modal.button.break.dispatching"),
            })
        }

    }

    function triggerMailDispatching() {
        fetchUtils.get(`/api/lot/accountingMailData/${lotId}/triggerMailDispatching`)
            .then(_ => {
                isMailDispatchingDisabled = !isMailDispatchingDisabled;
                let notification;
                if (isMailDispatchingDisabled) {
                    notification = {
                        message: get(t)('UI.mail.config.success.break.dispatching.message'),
                        messageHint: get(t)('UI.mail.config.success.break.dispatching.messageHint'),
                        type: NotificationType.SUCCESS,
                        timeout: 4000,
                        dismissible: false
                    };
                } else {
                    notification = {
                        message: get(t)('UI.mail.config.success.start.dispatching.message'),
                        messageHint: get(t)('UI.mail.config.success.start.dispatching.messageHint'),
                        type: NotificationType.SUCCESS,
                        timeout: 4000,
                        dismissible: false
                    };
                }
                addNotification(notification);
            }).catch(fetchUtils.catchErrorAndShowNotification());
    }

    function handleSendMail() {
        const indexOfMail: number = accountingMailConfigData.accountingMailData.filter(mailData => mailData.dispatchDateMail).length;
        let mailTitle = accountingMailConfigData.accountingMailData[indexOfMail].subject;
        mailTitle.replace(" : ", ": ");
        openModal(Modal, {
            title: $t('UI.mail.config.modal.title.sentMail', {mail: mailTitle.replace(" : ", ":")}),
            message: $t('UI.mail.config.modal.message.sentMail'),
            onAccept: () => {
                sendMail(indexOfMail, mailTitle)
                closeModal();

            },
            acceptText: $t("UI.mail.config.modal.button.sendMail.title"),
        })
    }

    function showSendMail(index: number = 1, mailingList: string) {
        let content = accountingMailConfigData.accountingMailData[index].htmlContent;
        let header = "<p style='font-weight: 600;margin: auto;padding-bottom: 0.3rem'>Versendet an:</p>";
        let replacedList = mailingList.toString().replaceAll(",", "<br>").replaceAll(">", ">• ").replace("<br>", "");
        let ttContent = header + replacedList;

        openModal(MailConfigModal, {
            content: content,
            title: $t('UI.mail.config.modal.mailingList'),
            ttContent: ttContent
        })

        setTimeout(() => {
            let img = document.querySelector(".mcnImage");
            img.setAttribute('src', '/assets/images/mail_logo_new.png');
        }, 100)

    }

    function sendMail(mailIndex: number, mailTitle: string) {
        showAnimation(LoadingAnimation.MmLoader);
        fetchUtils.get(`/api/lot/accountingMailData/${lotId}/sendMail/${mailIndex}`)
            .then(_ => {
                let notification = {
                    message: get(t)('UI.mail.config.success.sendMail.title', {mail: mailTitle}),
                    type: NotificationType.SUCCESS,
                    timeout: 4000,
                    dismissible: false
                };
                addNotification(notification);
            })
            .catch(fetchUtils.catchErrorAndShowNotification())
            .finally(() => {
                // we need this timeout because the changes in elastic db needs one second to update
                setTimeout(() => {
                    fetchData();
                }, 1100);
                hideAnimation();
            });
    }

    const redirectToLotDetailsPage = () => {
        window.parent.location.assign(`/lot/${lotId}/basedata`);
    }

    let today = new Date();
    today.setHours(23);
    today.setMinutes(0);
    today.setSeconds(0);
    const onlyContainsNumbers = (str: string) => /^\d+$/.test(str);

    $: firstLotMailDurationDate, updateMailDuration(LotMailNotificationType.FIRST_MAIL, firstLotMailDurationDate);
    $: secondLotMailDurationDate, updateMailDuration(LotMailNotificationType.SECOND_MAIL, secondLotMailDurationDate);
    $: thirdLotMailDurationDate, updateMailDuration(LotMailNotificationType.THIRD_MAIL, thirdLotMailDurationDate);
    $: fourthLotMailDurationDate, updateMailDuration(LotMailNotificationType.FOURTH_MAIL, fourthLotMailDurationDate);

    $:shouldSendLetsGoMail, updateSendLetsGoMail();

    $: btnColorTriggerMailDispatching = isMailDispatchingDisabled ? SecondaryBtnColors.GREEN : SecondaryBtnColors.RED;
    $: labelTriggerMailDispatching = isMailDispatchingDisabled ? $t('UI.mail.config.button.restartMailing') : $t('UI.mail.config.button.stopMailing');

    $: if (!readOnly && mailingList && mailingListSize !== mailingList.length) {
        updateMailingList();
    }

    $: if (allMailsSent || !isMailDispatchingDisabled || isMailDispatchingDisabled || allDispatchingMailsSent || actualTargetComparisonFilled || readOnly) {
        disableSendMailButton = allMailsSent || isMailDispatchingDisabled || allDispatchingMailsSent || actualTargetComparisonFilled || readOnly;
    }

    $: if (allMailsSent || allDispatchingMailsSent || actualTargetComparisonFilled || readOnly) {
        disableTriggerStartMailDispatchingButton = true;
    }

</script>

<Basic>
    <div>
        <div class="lot-mail-config-container">
            <span>
                {$t('UI.mail.config.headline')}
            </span>
            {#await loadData()}
                <MmLoadingAnimation/>
            {:then {}}
                {#if showTimeline}
                    <div class="sub-headline">
                        <span>{$t('UI.mail.config.subHeadline')}</span>
                        {#if isMailDispatchingDisabled}
                            {#if actualTargetComparisonFilled}
                                <span style="color:#C05858">{$t('UI.mail.config.mailDispatchDate.completed')}</span>
                            {:else}
                                <span style="color:#C05858">{$t('UI.mail.config.mailDispatchDate.paused')}</span>
                            {/if}
                        {:else}
                            <span class="status-lot-accounting-mails-sent">{accountingMailConfigData.accountingMailData.filter(mail => mail.dispatchDateMail).length}
                                von 5 gesendet</span>
                        {/if}
                    </div>
                    <div class="mail-status-container">
                        <div class="row-wrapper">
                            <div>{$t('UI.mail.config.date')}</div>
                            <div>{$t('UI.mail.config.type')}</div>
                            <div>{$t('UI.mail.config.status')}</div>
                        </div>
                        {#each accountingMailConfigData.accountingMailData as mailData, i}
                            <div class="row-wrapper">
                                {#if mailData.dispatchDateMail}
                                    <div> {mailData.dispatchDateMail} </div>
                                    <div class="dispatch-date-mail-sent">
                                        {mailData.subject}
                                        <span class="hover-pointer"
                                              on:click={() => showSendMail(i, "<br>" + mailData.mailingList.toString().replace(",", "<br>"))}>{$t('UI.mail.config.linkTitle')}</span>
                                    </div>
                                {:else}
                                    {#if i === 4 || i < indexNextManualMail || (actualTargetComparisonFilled && mailData.state !== 'Versendet')}
                                        <div>-</div>
                                    {:else}
                                        {#if i === 0}
                                            <div>
                                                <DatePickerInput id="sendDate-{i}" label="" minDate={today}
                                                                 materialWidth={"50"}
                                                                 bind:date={firstLotMailDurationDate}
                                                                 maxDate={new Date(new Date().getFullYear() + 25, 11, 31)}
                                                                 placeholder={"Versanddatum"}
                                                                 disabled={readOnly || actualTargetComparisonFilled || i < indexNextManualMail || isMailDispatchingDisabled}
                                                                 disableDays={(date) => disableDays(date)}/>
                                            </div>
                                        {/if}
                                        {#if i === 1}
                                            <div>
                                                <DatePickerInput id="sendDate-{i}" label="" minDate={today}
                                                                 materialWidth={"50"}
                                                                 bind:date={secondLotMailDurationDate}
                                                                 maxDate={new Date(new Date().getFullYear() + 25, 11, 31)}
                                                                 placeholder={"Versanddatum"}
                                                                 disabled={readOnly || actualTargetComparisonFilled || i < indexNextManualMail || isMailDispatchingDisabled}
                                                                 disableDays={(date)=>disableDays(date)}/>
                                            </div>
                                        {/if}
                                        {#if i === 2}
                                            <div>
                                                <DatePickerInput id="sendDate-{i}" label="" minDate={today}
                                                                 materialWidth={"50"}
                                                                 bind:date={thirdLotMailDurationDate}
                                                                 maxDate={new Date(new Date().getFullYear() + 25, 11, 31)}
                                                                 placeholder={"Versanddatum"}
                                                                 disabled={readOnly || actualTargetComparisonFilled || i < indexNextManualMail || isMailDispatchingDisabled}
                                                                 disableDays={(date)=>disableDays(date)}/>
                                            </div>
                                        {/if}
                                        {#if i === 3}
                                            <div>
                                                <DatePickerInput id="sendDate-{i}" label="" minDate={today}
                                                                 materialWidth={"50"}
                                                                 bind:date={fourthLotMailDurationDate}
                                                                 maxDate={new Date(new Date().getFullYear() + 25, 11, 31)}
                                                                 placeholder={"Versanddatum"}
                                                                 disabled={readOnly || actualTargetComparisonFilled || i < indexNextManualMail || isMailDispatchingDisabled}
                                                                 disableDays={(date)=>disableDays(date)}/>
                                            </div>
                                        {/if}

                                    {/if}
                                    {#if i === indexNextManualMail && i !== 4 && !actualTargetComparisonFilled}
                                        <div class="dispatch-date-mail-next">
                                            {mailData.subject}
                                            <span>{$t('UI.mail.config.linkTitle')}</span>
                                        </div>
                                    {:else}
                                        <div class="dispatch-date-mail">
                                            {mailData.subject}
                                            <span>{$t('UI.mail.config.linkTitle')}</span>
                                        </div>
                                    {/if}

                                {/if}
                                {#if mailData.dispatchDateMail}
                                    <div class="sent-mail-identifier">{mailData.state}</div>
                                {:else}
                                    {#if isMailDispatchingDisabled}
                                        {#if actualTargetComparisonFilled}
                                            <div class="mail-dispatching-paused">{"Wurde nicht versendet"}</div>
                                        {:else}
                                            <div class="mail-dispatching-paused">{"Pausiert"}</div>
                                        {/if}
                                    {:else}
                                        <div class="mail-date">
                                            {#if i === 4}
                                                <span>Wird automatisch versendet bei Eingabe von</span>
                                                <span>Ist-Werten</span>
                                            {:else if parseInt(mailData.state) < 0 || (mailData.state === '0' && new Date().getHours() >= 8 || indexNextManualMail > i)  }
                                                <span class="red-color">Mail übersprungen</span>
                                            {:else if mailData.state === '0' && new Date().getHours() < 8}
                                                <span>Wird heute um 8 Uhr versendet</span>
                                            {:else}
                                                <span>Wird in</span>
                                                <span>{mailData.state} Tagen</span>
                                                <span>versendet</span>
                                            {/if}
                                        </div>
                                    {/if}

                                {/if}
                            </div>
                        {/each}
                    </div>
                    {#if disableTriggerStartMailDispatchingButton}
                        <div style="align-self: flex-end;">
                            <Tooltip msg={getTTipTriggerMailDispatching()}
                                     placementTtip={TooltipPlacements.BOTTOM_START}>
                                <div class="buttons">

                                    <SecondaryButton label={labelTriggerMailDispatching}
                                                     color={btnColorTriggerMailDispatching}
                                                     on:click={handleTriggerMailDispatching} disabled={true}/>
                                    <PrimaryButton label={$t('UI.mail.config.button.sendMail')}
                                                   bind:disabled={disableSendMailButton}
                                                   on:click={handleSendMail}/>

                                </div>
                            </Tooltip>
                        </div>
                    {:else}
                        <div class="buttons">
                            <SecondaryButton label={labelTriggerMailDispatching} color={btnColorTriggerMailDispatching}
                                             on:click={handleTriggerMailDispatching}/>
                            <PrimaryButton label={$t('UI.mail.config.button.sendMail')}
                                           bind:disabled={disableSendMailButton}
                                           on:click={handleSendMail}/>
                        </div>
                    {/if}

                    <div class="dispatch-data">
                        <div>
                            <span>{$t('UI.mail.config.mailDispatchData')}</span>
                            <span></span>
                        </div>
                        <div>
                            {#if mailingList}
                                <ChipList bind:list={mailingList}
                                          placeholder={$t('UI.mail.config.mailDispatchData.address')}
                                          chipFontProps={DefaultRobotoFontProps('0.625rem', '0.875rem', '#9d9d9d', 'black')}
                                          readOnly={readOnly || actualTargetComparisonFilled}
                                          bind:readOnlyEntries={defaultReceiverList}
                                          materialLabel={$t('UI.mail.config.mailDispatchData.address')}/>
                            {/if}
                        </div>
                    </div>
                {/if}
                <div class="manage-mail-container">
                    <div class="lets-go-mail">
                    <span>
                        {$t('UI.mail.config.manage.mail')}
                    </span>
                        <div class="context">
                            <div class="switch-container">
                                {#if disabledLetsGoMail}
                                    <Tooltip msg={$t('UI.mail.config.disabled.ttText')}
                                             placementTtip={TooltipPlacements.BOTTOM_START}>
                                        <Switch checked={shouldSendLetsGoMail} disabled={disabledLetsGoMail}/>
                                    </Tooltip>
                                {:else}
                                    <Switch bind:checked={shouldSendLetsGoMail}/>
                                {/if}
                            </div>
                            <div>
                                <p class="definition">{$t('UI.mail.config.letsGo.mail')}</p>
                                <p class="info-text">{$t('UI.mail.config.letsGo.mail.infoText')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="button">
                    <SecondaryButton leftIcon={ButtonIcons.LEFT_ARROW} on:click={redirectToLotDetailsPage}
                                     label={$t('UI.mail.config.button.backToLot')}></SecondaryButton>
                </div>
            {/await}
        </div>
    </div>
</Basic>

<style lang="scss">
  @import "../../styles/global";

  .manage-mail-container {
    width: 100%;
    margin-top: 2rem;
    @include flex-col($justify: flex-start, $alignment: flex-start);

    & .lets-go-mail {
      margin-top: 1.75rem;
      margin-bottom: 3rem;
      text-align: start;

      & span {
        @include roboto-font(normal, 400, 1.25rem, black);
      }

      & .context {
        @include flex-row($alignment: baseline, $justify: center);
        margin-top: 1.2rem;

        & .definition {
          @include roboto-font(1.125rem, 400, 0.875rem, black);
          margin-bottom: 0.375rem;
          flex: none;
        }

        & .info-text {
          @include roboto-font(1rem, 400, 0.875rem, black);
          flex: none;
          opacity: 0.5;
          max-width: 21.875rem;
        }

        & .switch-container {
          align-self: flex-start;
          margin-right: 2rem;
          width: auto;
        }
      }
    }
  }

  .button {
    grid-area: buttons;
    margin-top: 3rem;
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .lot-mail-config-container {
    @include flex-col($justify: flex-start);

    &:first-child {
      float: left;
      margin-top: 3.785rem
    }

    & span {
      align-self: flex-start;
      @include roboto-font(normal, 500, 1.25rem, black);
    }

    & .sub-headline {
      margin-top: 1.2rem;
      display: flex;
      width: 100%;
      justify-content: space-between;

      & span {
        @include roboto-font(normal, 400, 1.25rem, black);
        align-self: flex-start;
      }

      & span:last-child {
        @include roboto-font(normal, 500, 1rem, $primaryGreen);
        align-self: flex-end;
      }
    }

    & .buttons {
      align-self: flex-end;
      display: flex;
      margin-top: 1rem;
      column-gap: 0.6rem;
    }
  }

  .mail-status-container {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    grid-template-rows: 1fr; /*remove for unequal rows */
    @include roboto-font(18px, 400, 0.75rem, black);
    width: 100%;

    & .row-wrapper {
      display: contents;

      & div.mail-dispatching-paused {
        color: $primaryRed !important;
      }

      & > div {
        text-align: start;
        border-bottom: 1px solid $grey-600;
        padding: 0.75rem 0.5rem 0.64rem 0.5rem;
      }

      & > div:first-child {
        padding-left: 2rem;
        display: flex;
        align-items: center;
      }

      & div:last-child {
        @include roboto-font(1.125rem, 500, 0.875rem, $primaryGreen);
        display: flex;
        align-items: center;
      }

      & div:nth-child(2) {
        display: flex;
        flex-direction: column;
      }

      &:first-child > div {
        border-bottom: 1px solid $primaryGreen;
        @include roboto-font(18px, 400, 0.875rem, black)
      }
    }
  }

  .mail-date {
    display: flex;

    & span {
      @include roboto-font(1.125rem, 400, 0.875rem, black);
      align-self: center;
      display: contents;
    }

    & span:nth-child(2) {
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      align-self: center;
      display: contents;
      @include roboto-font(1.125rem, 500, 0.875rem, $primaryGreen);
    }

    & span.red-color {
      color: $primaryRed;
    }
  }

  .dispatch-data {
    width: 100%;
    @include flex-col($justify: flex-start);
    margin-top: 2rem;

    & div {
      display: flex;
      width: 100%;
      flex-direction: column;

      & span {
        align-self: flex-start;
        @include roboto-font(normal, 400, 1.25rem, black);
      }

      & span:last-child {
        margin-top: 2rem;
        @include roboto-font(normal, 500, 1rem, black);
      }

    }

    & div:last-child {
      margin-top: 3rem;
    }

    & div:nth-child(2) {
      margin-top: 1rem;
    }

  }

  .dispatch-date-mail {
    @include roboto-font(1.125rem, 400, 0.875rem, black);

    & span {
      @include roboto-font(1.5rem, 400, 0.875rem, $bluish-grey-700);
      color: $bluish-grey-700;
    }
  }

  .dispatch-date-mail-sent {
    @include roboto-font(1.125rem, 400, 0.875rem, rgba(0, 0, 0, 0.50));

    & span {
      @include roboto-font(1.5rem, 400, 0.875rem, #557DBA);
      color: #557DBA;
    }
  }

  .dispatch-date-mail-next {
    @include roboto-font(1.125, 500, 0.875rem, black);

    & span {
      @include roboto-font(1.5rem, 400, 0.875rem, $bluish-grey-700);
      color: $bluish-grey-700
    }
  }

  :global(.modal-mailing-list) {
    @include roboto-font(1.125, 400, 1rem, black);
  }


</style>