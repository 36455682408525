import {translate} from "../../i18n/i18n";

export function getFormErrors(error, pathPrefix: string): [any] {
    let result: any = [];
    if (error.inner.length !== 0) {
        error.inner.forEach(err =>
            result.push({path: pathPrefix + err.path, message: translate('de', err.message, [])})
        )
        return result;
    }
    result.push({path: error.path, message: translate('de', error.message, [])});
    return result;
}

export function isExactPathInErrorList(errors, fieldPath): boolean {
    return errors.some(error => error.path === fieldPath);
}

export function isPathInErrorList(errors, fieldPath: string): boolean {
    fieldPath = fieldPath.split('.*').join('');
    return errors.some(error => error.path.includes(fieldPath));
}

export function isAnyPathInErrorList(err, fieldPaths: string[]): boolean {
    return fieldPaths.some(fieldPath => {
        fieldPath = fieldPath.split('.*').join('');
        if (err.length !== 0) {
            return err.some(error => error.path.includes(fieldPath))
        }
    });
}

export function getExactPathErrorMessage(errors, fieldPath): string {
    return errors.find(error => error.path === fieldPath).message;
}

export function getPathErrorMessage(errors, fieldPath): string {
    fieldPath = fieldPath.split('.*').join('');
    return errors.find(error => error.path.includes(fieldPath)).message;
}
