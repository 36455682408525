<script lang="ts">

    import {onMount} from "svelte";
    import {t} from "../../i18n/i18n";
    import icon401 from "@/images/401.png";
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";

    export let description: string = "";

    let redirectUrl: string = '';
    onMount(() => {
        const url = new URL(window.location.href);
        redirectUrl = url.searchParams.get("ret") || "";
    });

    function redirectToLoginPage() {
        window.parent.location.href = `/login?redirect=${redirectUrl}`
    }
</script>


<div class="blur-background-overlay">
    <div class="blur-background-white"></div>
    <div class="blur-background-overlay-content">
        <img alt="401 Error - No access" src={icon401}>
        <span class="error-hint description-text">{@html description}</span>
        <PrimaryButton label={$t('UI.errorPage.401.login')} fontSize="0.875rem" on:click={redirectToLoginPage}
                       color={PrimaryBtnColors.GREEN}/>
    </div>
</div>


<style lang="scss">
  @import "../../styles/animations";
  .blur-background-overlay {
    z-index: 10000;
  }

  .description-text {
    margin-bottom: 1rem;
  }

</style>
