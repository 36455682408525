<script lang="ts">
    import {fly} from 'svelte/transition';
    import closeIcon from "@/icons/icon_close.svg";
    import infoIcon from "@/icons/icon_info_blue_outline_20px.svg";
    import Icon from "../../atoms/Icon.svelte";
    import {t} from "../../../i18n/i18n.ts";
    import {cubicOut} from "svelte/easing";

    let isSubstituteMatch: boolean = false;
    let isRecommendationMatch: boolean = false;
    let showModal: boolean = false;
    let underlyingAnalysis: string;
    let recommendation: string;
    let locationType: string;

    const cubicOutTrans = {y: -200, duration: 1000, easing: cubicOut};

    window.addEventListener('handle-info-window-modal', (e) => handleModal(e as CustomEvent));

    function handleModal(e: CustomEvent) {
        if (!e.detail) {
            closeModal();
            return;
        }

        showModal = true;
        if (e.detail.tooltip == "substitute") {
            isSubstituteMatch = !isSubstituteMatch;
        } else {
            isRecommendationMatch = !isRecommendationMatch
            underlyingAnalysis = e.detail.underlyingAnalysis;
            recommendation = e.detail.recommendation;
            locationType = e.detail.locationType;
        }
        if (!isSubstituteMatch && !isRecommendationMatch) {
            showModal = false
        }
    }

    function closeModal() {
        showModal = false;
        isSubstituteMatch = false;
        isRecommendationMatch = false;
    }

</script>

{#if showModal}
    <div class="conditional-match-container" in:fly={cubicOutTrans} out:fly={cubicOutTrans}>
        <div>
            {#if isSubstituteMatch}
                <span>{$t('UI.Map.substitute.tooltipHeader')}</span>
            {:else }
                <span>{$t('UI.Map.recommendation.tooltipHeader')}</span>
            {/if}
            <Icon src={closeIcon} size={20} on:click={closeModal} clickable></Icon>
        </div>
        <div>
            <Icon src={infoIcon} size={24}></Icon>
            {#if isSubstituteMatch}
                <div>{@html $t('UI.Map.substitute.tooltipText')}</div>
            {:else}
                <div>
                    {@html $t('UI.Map.recommendation.tooltipText', {underlyingAnalysis, locationType, recommendation})}
                </div>
            {/if}
        </div>
    </div>
{/if}

<style lang="scss">
  @import "../../../styles/global";

  .conditional-match-container :global(.green-conditional-text) {
    color: #58C079;
    font-weight: 500;
    font-size: 0.875rem;
  }

  div.conditional-match-container {
    border-radius: .5rem;
    padding: 12px 20px;
    position: fixed;
    background: white;
    top: 4.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    display: flex;
    flex-flow: column;

    & > div:first-child {
      display: flex;
      flex-flow: row;
      justify-content: space-between;

      & > span {
        @include roboto-font(15px, 500, 1rem, $primaryGreen);
      }
    }

    & > div:nth-child(2) {
      display: flex;
      flex-flow: row;
      padding-top: .5rem;

      & > div {
        padding-left: .5rem;
        @include roboto-font(18px, 350, 14px, rgb(94, 100, 104));
      }
    }
  }

</style>