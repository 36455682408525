<script lang="ts">
    import {createEventDispatcher, getContext, onMount, setContext} from "svelte";
    import {addNotification, hideAnimation, showAnimation, userIsLoggedIn} from "../stores.js";
    import {fade} from "svelte/transition";
    import {t} from "../../i18n/i18n";
    import {type Lot} from "../types/lot";
    import {ButtonIcons, CalcMethod, LoadingAnimation, OfferViewMode} from "../types/enums";
    import {savePublicComment, updateAttachmentsLot} from "../pages/OffererPubPage.svelte";
    import {NotificationType} from "../types/notification";
    import {fetchUtils} from "../utils/fetchUtils";
    import Salutation from "../atoms/Salutation.svelte";
    import InfoCard from "../organisms/InfoCard.svelte";
    import ProjectInformationGrid from "../project/ProjectInformationGrid.svelte";
    import TextArea from "../atoms/TextArea.svelte";
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import OfferReactionButton, {ReactionButtons} from "../bid/OfferReactionButton.svelte";
    import AgbButton from "../organisms/AgbButton.svelte";
    import FileUpload from "../organisms/FileUpload.svelte";
    import AcceptOfferComponent from "../bid/AcceptOfferComponent.svelte";
    import PublicPageFooter from "../molecules/PublicPageFooter.svelte";
    import Basic from "../templates/Basic.svelte";
    import PublicPageHeader from "../molecules/PublicPageHeader.svelte";
    import ContactBox from "../atoms/ContactBox.svelte";
    import type {PubPageParams} from "../bid/types/pubPageParams";
    import {RequestType} from "../bid/types/requestDto";
    import {writable, type Writable} from "svelte/store";
    import OfferInfo from "../bid/OfferInfo.svelte";
    import {EmptyOffer, type Offer, OfferState} from "../bid/types/offer";
    import {Facts} from "../bid/types/facts";
    import {isEmptyOffer} from "../bid/offerUtils";

    const dispatch = createEventDispatcher();
    const urlParams: PubPageParams = getContext("urlParams") || null;
    const lot: Writable<Lot> = getContext("lot");
    const offerMaterial: Writable<Offer> = setContext("offerMaterial", writable(EmptyOffer(RequestType.MATERIAL)));
    const offerMaterialWithTransport: Writable<Offer> = setContext("offerMaterialWithTransport", writable(EmptyOffer(RequestType.MATERIAL_WITH_TRANSPORT)));

    let lotPublicId: string | null | undefined;
    let requestPublicId: string | null | undefined;
    let offerUserPublicId: string | null | undefined;

    let isUserAndRequestProvided: boolean = false;
    let isAnyOfferRejected: boolean = false;
    let selectedRequestType: RequestType | null = null;
    let showFinalDenyButton: boolean = false; // double-opt on denying an offer

    let attachmentsLot: any[] = [];
    let attachmentsUploadUrl: string;
    let viewMode: OfferViewMode = OfferViewMode.VIEW_OFFER;

    onMount(async () => {
        ({lotPublicId, offerUserPublicId, requestPublicId} = urlParams);
        isUserAndRequestProvided = !!((offerUserPublicId && requestPublicId) || false);
        attachmentsLot = await updateAttachmentsLot(lotPublicId);
        if (!isUserAndRequestProvided) {
            let notification = {
                message: $t('UI.Approval.successful', {name: $lot.personAddressableName}) + ($lot.skippedApproval ? "<br>" + $t('UI.Approval.skipped') : ""),
                type: NotificationType.INFO,
                timeout: 0,
                dismissible: true
            };
            addNotification(notification);
        } else {
            await fetchCounterOfferData();
            if (isAnyOfferRejected) {
                let notification = {
                    message: $t('UI.quoteResult.COUNTER_OFFER_REJECTED.' + $lot.calcMethod),
                    type: NotificationType.SUCCESS,
                    timeout: 0,
                    dismissible: true
                };
                addNotification(notification);
            }
        }
    });

    async function fetchCounterOfferData() {
        await fetchUtils.get(`/api/offerer/offers/${requestPublicId}/${offerUserPublicId}`)
            .then(data => {
                if (data?.offerMaterialWithTransport) { // only overwrite if not null
                    $offerMaterialWithTransport = {
                        ...data?.offerMaterialWithTransport,
                        facts: new Facts(data.offerMaterialWithTransport.facts)
                    };
                    selectedRequestType = RequestType.MATERIAL_WITH_TRANSPORT;
                }
                if (data?.offerMaterial) {
                    $offerMaterial = {...data?.offerMaterial, facts: new Facts(data.offerMaterial.facts)};
                    selectedRequestType = RequestType.MATERIAL;
                }
                if (data.offerMaterialWithTransport) { // only overwrite if not null
                    $offerMaterialWithTransport = {
                        ...data?.offerMaterialWithTransport,
                        facts: new Facts(data.offerMaterialWithTransport.facts)
                    };
                    selectedRequestType = RequestType.MATERIAL_WITH_TRANSPORT;
                }
                isAnyOfferRejected = ($offerMaterial.offerState && ($offerMaterial.offerState === OfferState.REJECTED || $offerMaterial.offerState === OfferState.REJECTED_MM)) || ($offerMaterialWithTransport.offerState && ($offerMaterialWithTransport.offerState === OfferState.REJECTED || $offerMaterialWithTransport.offerState === OfferState.REJECTED_MM));
            }).catch(fetchUtils.catchErrorAndShowNotification());
    }

    function accept(): void {
        if (selectedRequestType) {
            let offerPublicId;
            if (selectedRequestType === RequestType.MATERIAL_WITH_TRANSPORT) {
                offerPublicId = $offerMaterialWithTransport.publicId;
            } else {
                offerPublicId = $offerMaterial.publicId;
            }

            showAnimation(LoadingAnimation.MmLoader);
            fetchUtils.get(`/api/offerer/offer/${offerPublicId}/accept`)
                .then(data => {
                    window.location.replace(window.location.origin + '/position/' + data.resLotPublicId); // in case we have a counter offer with shortage volume, we need to redirect to the other page
                    hideAnimation();
                })
                .catch(error => {
                    hideAnimation(0);
                    let notification = {message: error, type: NotificationType.ERROR, timeout: 0, dismissible: true};
                    addNotification(notification);
                })
        } else {
            let notification = {
                message: $t('UI.counterOffer.error.noModalityChosen'),
                type: NotificationType.ERROR,
                timeout: 0,
                dismissible: true
            };
            addNotification(notification);
        }
    }

    function deny(): void {
        showFinalDenyButton = true;
    }

    function sendDeny(): void {
        let promises: Promise<any>[] = [];
        if ($offerMaterial?.publicId) {
            promises.push(fetchUtils.get(`/api/offerer/offer/${$offerMaterial.publicId}/reject`))
        }
        if ($offerMaterialWithTransport?.publicId) {
            promises.push(fetchUtils.get(`/api/offerer/offer/${$offerMaterialWithTransport.publicId}/reject`))
        }
        const multi: boolean = promises.length > 1;
        Promise.all(promises)
            .then(() => {
                fetchCounterOfferData();
                let notification = {
                    message: $t(`UI.quoteResult.COUNTER_OFFER_REJECTED.${$lot.calcMethod}${multi ? '.multi' : ''}`),
                    type: NotificationType.SUCCESS,
                    timeout: 0,
                    dismissible: true
                };
                addNotification(notification);
            })
            .catch(fetchUtils.catchErrorAndShowNotification());
    }
    const viewOffer = () => viewMode = OfferViewMode.VIEW_OFFER;
    const viewAcceptOffer = () => viewMode = OfferViewMode.ACCEPT_OFFER;

    $: offerText = $lot.calcMethod === CalcMethod.REQUEST_OFFER ? $t("UI.optimizerPage.offer.text") : $t("UI.optimizerPage.counterOffer.text");
    $: isOfferProvided = isUserAndRequestProvided && !!($offerMaterial || $offerMaterialWithTransport);
    $: isOfferOutdated = ($offerMaterial?.offerState === OfferState.SENT && ($offerMaterial?.facts?.volume || 0) > $lot.volume)
        || ($offerMaterialWithTransport?.offerState === OfferState.SENT && ($offerMaterialWithTransport?.facts?.volume || 0) > $lot.volume);

    $: if (lotPublicId) {
        attachmentsUploadUrl = '/api/lot/pub/' + lotPublicId + '/attachment/new';
    }
</script>


{#if !$userIsLoggedIn}
    <PublicPageHeader/>
{/if}

<Basic showStandardHeaderFooter={$userIsLoggedIn}>
    <div class="container-salutation-contact">
        <Salutation name={$lot.personAddressableName} address={$lot.address.zip + " " + $lot.address.city}/>
        {#if $userIsLoggedIn}
            <ContactBox/>
        {/if}
    </div>
    <div class="row one-col-row-gap">
        <div class="col-lg-5 col-md-12 col-sm-12">
            <InfoCard offererPage/>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12">
            <div class="container-project-information">
                <ProjectInformationGrid title={$t('UI.project.information')} anonymizeAddress={false} {...$lot}/>
                <TextArea title={$t("UI.materialDetailsComment.header")} text={$lot.lotRemarks}
                          placeholder={$t('UI.remarks.noRemarks')} readonly/>
                <TextArea title={$t("UI.projectDetailsComment.header")} text={$lot.projectRemarks}
                          placeholder={$t('UI.remarks.noRemarks')} readonly isHtml/>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 1.875rem">
        <div class="col-12">
            <FileUpload uploadUrl={attachmentsUploadUrl}
                        filePrefix={$t('UI.attachments.offerer.prefix')}
                        visibleToCustomer={1} visibleToPartner={0}
                        updateFun={() => updateAttachmentsLot(lotPublicId || $lot.publicId).then(data => attachmentsLot = data)}
                        attachments={attachmentsLot}/>
        </div>
    </div>
    {#if isOfferProvided}
        <div class="row">
            <div class="col-12">
                <OfferInfo bind:selected={selectedRequestType} selectable={!isEmptyOffer($offerMaterial, RequestType.MATERIAL) && $offerMaterial.offerState !== OfferState.REJECTED && $offerMaterial.offerState !== OfferState.REJECTED_MM
                || !isEmptyOffer($offerMaterialWithTransport, RequestType.MATERIAL_WITH_TRANSPORT) && $offerMaterialWithTransport.offerState !== OfferState.REJECTED && $offerMaterialWithTransport.offerState !== OfferState.REJECTED_MM}/>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                {#if (isOfferProvided || isOfferOutdated) && !showFinalDenyButton && !isAnyOfferRejected}
                    <div class="container-offer-reaction" in:fade={{duration: 400}}>
                        {#if viewMode === OfferViewMode.ACCEPT_OFFER}
                            <span>{$t('UI.acceptOffer.terms.accept.hint')}</span>
                        {:else}
                            <span>{$t('UI.Decisions.select.request.customer')}</span>
                        {/if}

                        <div>
                            {#if viewMode === OfferViewMode.VIEW_OFFER}
                                <OfferReactionButton reaction={ReactionButtons.POSITIVE}
                                                     label={offerText + " " + $t('UI.accept.final')}
                                                     disabled={!selectedRequestType}
                                                     on:reactionBtnClick={viewAcceptOffer}/>


                                <OfferReactionButton reaction={ReactionButtons.NEGATIVE}
                                                     label={offerText + " " + $t('UI.CounterOffer.deny')}
                                                     on:reactionBtnClick={deny}/>
                            {/if}
                        </div>
                    </div>
                {/if}
                <!-- Show AGB dialog after accept (counter) offer -->
                {#if viewMode === OfferViewMode.ACCEPT_OFFER}
                    <AcceptOfferComponent isOfferer on:cancel={viewOffer} on:acceptedAgb={accept}/>
                {/if}
                {#if isOfferProvided && showFinalDenyButton && !isAnyOfferRejected}
                    <div in:fade={{duration: 400}} style="width: 100%;">
                        <div style="display: flex; flex-direction: row; margin-top: 1.875rem">
                            <h2>{offerText + ' ' + $t('UI.deny.final')}</h2>
                        </div>
                        <AgbButton label={offerText + ' ' + $t('UI.deny.final')} color={PrimaryBtnColors.RED}
                                   description={$t('UI.offerer.counterOffer.deny.description', {offerText})}
                                   hideCheckbox on:click={sendDeny}/>
                        <div class="align-right" style="margin-top: 1.125rem">
                            <SecondaryButton label={$t('UI.button.cancel')} color={SecondaryBtnColors.GREY}
                                             on:click={() => showFinalDenyButton = false}/>
                        </div>
                    </div>
                {/if}
            </div>
        </div>
    {/if}
    <div class="row">
        <div class="col-lg-12 col-xs-12">
                <TextArea id="offererPublicComment" bind:text={$lot.remarks} title={$t('UI.remarks.label')}
                          placeholder={$t("UI.pubRemarks.placeholder.detailedInfo")}/>
            <div class="align-right" style="margin-top: 1.125rem">
                <SecondaryButton label={$t("UI.saveRemark")} leftIcon={ButtonIcons.CHECKMARK}
                                 on:click={() => savePublicComment($lot.remarks, lotPublicId)}/>
            </div>
        </div>
    </div>

</Basic>

{#if !$userIsLoggedIn}
    <PublicPageFooter/>
{/if}

<style lang="scss">
  @import "../../styles/pubPage";


  .container-offer-reaction {
    @include flex-col(1rem, $alignment: flex-start);
    margin-top: 1.875rem;

    & > span:first-child {
      @include roboto-font(15px, 500, 1rem, black);
      text-align: left;
    }

    & > div:last-child {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1.438rem;
      grid-auto-rows: 1fr;
      width: 100%;

      @media screen and (max-width: 660px) {
        grid-template-columns: 1fr;
        grid-row-gap: 1.438rem;
      }
    }
  }


</style>
