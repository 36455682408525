import {fetchUtils} from "./fetchUtils";
import Hotjar from "@hotjar/browser";

/**
 * Loads Hotjar if user is in table hotjaruserassignment and loggedIn or is from pub page.
 * Hotjar Tracking Code defined by hotjar
 * see https://help.hotjar.com/hc/en-us/articles/115009336727-How-to-Install-Your-Hotjar-Tracking-Code
 *
 * It's important to not load hotjar multiple time in a session! Therefore, separate hotjar ids are set for the iframe
 * pages (map and analytics)
 *
 * @default false
 * @param isPubPage
 */
export async function loadHotjar(isPubPage: boolean = false) {
    fetchUtils.get(`/api/hotjar/${isPubPage}`)
        .then(data => {
            if (data === true) {
                // If user is in table hotjaruserassignment and loggedIn hotjar will be started
                // hotjarId same id like file page.html.pasta
                Hotjar.init(5009683, 6);
            }
        })
        .catch(fetchUtils.catchErrorAndShowNotification());
}
