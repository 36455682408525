<script lang="ts">

    import type {FontProps} from "../types/fontProps";
    import {DefaultRobotoFontProps, getFontPropsVarString} from "../types/fontProps";
    import {breadcrumbs} from "../stores";

    /*Breadcrumb needs to have the following data:
    {
        title: string, -> label that is shown
        data: object, -> the object and other data needed to handle navigating and to give back with the callback
        callback: function, -> function from the caller to execute when clicking on the breadcrumb
    }*/
    export let clickable: boolean = true;
    export let fontProps: FontProps = DefaultRobotoFontProps('21px', '14px');


    function navigate(breadcrumb, index) {
        if (clickable) {
            breadcrumb.callback(breadcrumb.data, index);
        }
        // TODO think about that, how we can allow to navigate and make error handling when unsaved changes etc.
    }

    $: fontPropsVarString = getFontPropsVarString(fontProps);
</script>


<div class="breadcrumbs" style="{fontPropsVarString}" class:clickable>
    {#each $breadcrumbs as breadcrumb, i}
        <a on:click={() => navigate(breadcrumb, i)}>{breadcrumb.title}</a>
        {#if i < $breadcrumbs.length - 1} <!-- for all except the last element -->
            <span>></span> <!--this > is on purpose ! -->
        {/if}
    {/each}
</div>

<!-- TODO breadcrumbs that are navigable or not depending on boolean -->
<!-- TODO this component could also include and manage a stack with the elements that are needed for every breadcrumb -->
<!-- TODO this stack could include the UI components and the according parameters and objects that are used on this component -->

<style lang="scss">
  @import "../../styles/global";

  div.breadcrumbs {
    --color: $grey-800;
    @include flex-row(0.25rem, $justify: flex-start, $alignment: center);
    @include roboto-font(var(--line-height), var(--font-weight), var(--font-size), var(--color));
    flex-wrap: wrap;
    width: 100%;

    & > a {
      color: var(--color);
      text-decoration: none;
      cursor: pointer;
    }

    & > a:last-of-type {
      color: $primaryGreen;
    }
  }

</style>
