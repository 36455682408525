<script lang="ts">
    import type {LotStateData} from "../types/lotStateData";
    import LotStateIcon from "../molecules/LotStateIcon.svelte";

    export let isDiscarded: boolean;
    export let actualLotState: number;
    export let lotState: LotStateData;
    export let ordinalValue: number;
    export let maxLotState: number;
    export let barHeight: number;


    let lotStateType = actualLotState === ordinalValue ? 'active' : 'inactive';

    let typeIsActual: boolean = false;
    let typeIsActualAndDiscardedAndLotIsDiscarded: boolean = false;
    let typeIsNotActualAndDiscardedAndLotIsNotDiscarded: boolean = false;
    let typeIsNotActualAndWasPresent: boolean = false;
    let typeIsNotActualAndWasNotPresent: boolean = false;
    let typeIsNotActualAndAfterActual: boolean = false;

    $: if (lotState) {
        lotStateType = actualLotState === ordinalValue ? 'active' : 'inactive';

        typeIsActual = lotState.lotStateDate && ordinalValue === actualLotState && ordinalValue !== maxLotState;
        typeIsActualAndDiscardedAndLotIsDiscarded = lotState.lotStateDate && ordinalValue === actualLotState && ordinalValue === maxLotState && isDiscarded;
        typeIsNotActualAndDiscardedAndLotIsNotDiscarded = !lotState.lotStateDate && ordinalValue === maxLotState && !isDiscarded;
        typeIsNotActualAndWasPresent = lotState.lotStateDate && ordinalValue < actualLotState;
        typeIsNotActualAndWasNotPresent = !lotState.lotStateDate && ordinalValue < actualLotState;
        typeIsNotActualAndAfterActual = !lotState.lotStateDate && ordinalValue > actualLotState;
    }

</script>

{#if typeIsActual}

    <LotStateIcon {ordinalValue} {barHeight} lotStateDate={lotState.lotStateDate} lotStatus={lotState.lotStatus}
                  strokeLineType={lotStateType} lotStateTextType={lotStateType} progressLineIconType={"active"} animate/>

{:else if typeIsActualAndDiscardedAndLotIsDiscarded}

    <LotStateIcon {ordinalValue} {barHeight} lotStateDate={lotState.lotStateDate} lotStatus={lotState.lotStatus}
                  progressLineIconType={"active-discarded"} strokeLineType={'discarded'} lotStateTextType={'discarded'}
                  fontWeight={"500"} animate/>

{:else if typeIsNotActualAndDiscardedAndLotIsNotDiscarded}

    <LotStateIcon {ordinalValue} {barHeight} progressLineIconType={"inactive-discarded"}
                  lotStateDate={lotState.lotStateDate} lotStatus={lotState.lotStatus}/>

{:else if typeIsNotActualAndWasPresent}

    <LotStateIcon {ordinalValue} {barHeight} lotStateDate={lotState.lotStateDate} strokeLineType={lotStateType}
                  lotStatus={lotState.lotStatus} lotStateTextType={lotStateType} progressLineIconType={"active"}/>

{:else if typeIsNotActualAndWasNotPresent}

    <LotStateIcon {ordinalValue} {barHeight} lotStateDate={lotState.lotStateDate} lotStatus={lotState.lotStatus}
                  progressLineIconType={"empty"}/>

{:else if typeIsNotActualAndAfterActual}

    <LotStateIcon {ordinalValue} {barHeight} lotStatus={lotState.lotStatus}/>

{/if}







