<script lang="ts">

    import CollapsibleCard from "../molecules/CollapsibleCard.svelte";
    import SecondaryButton from "../atoms/SecondaryButton.svelte";
    import InputField from "../molecules/InputField.svelte";
    import {inputFieldFormats} from "../utils/formatters";
    import {fetchUtils} from "../utils/fetchUtils";
    import {createEventDispatcher} from "svelte";
    import {addNotification} from "../stores";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import type {AutocompleteFieldConfig} from "../types/autocompleteFieldConfig";
    import {getFormAcConfigFetch} from "../types/autocompleteFieldConfig";
    import type {AnalyticalValue} from "./types/analyticalValue";
    import {DefaultAnalyticalValue} from "./types/analyticalValue";
    import {DefaultRobotoFontProps, type FontProps} from "../types/fontProps";
    import {DefaultNotification, NotificationType} from "../types/notification";
    import {t} from "../../i18n/i18n";
    import {analyticalMetadata} from "./stores";

    const dispatch = createEventDispatcher();
    const inputFieldFontProps: FontProps = DefaultRobotoFontProps('0.875rem', '0.75rem');


    export let paddingBottom: string = "2rem"; // this is necessary, such that on the sidebar we can scroll to the bottom

    let {analyticalType, analyticalId, parentId, parentType} = $analyticalMetadata;
    $: $analyticalMetadata, {analyticalType, analyticalId, parentId, parentType} = $analyticalMetadata;

    let name: string;
    let unit: string;
    let customValue: string;
    let autocompleteValue: string;
    let autocompleteUnit: string;

    $: autocompleteUnit = acValueObj?.unit ?? "/";


    const acConfig: AutocompleteFieldConfig = getFormAcConfigFetch(`${analyticalType?.toLowerCase()}`, false, 'id', 'text', false, true, `&analysisId=${analyticalId}&parentType=${parentType}`);
    let acValueObj: AnalyticalValue = DefaultAnalyticalValue(); // this is the complete object behind the AC-field

    const addCustomValue = () => {
        if (!name || !customValue) {
            let notification = DefaultNotification($t('Analytics.arbitraryValues.error.missingValues'), NotificationType.ERROR);
            addNotification(notification);
            return;
        }

        fetchUtils.post(`/api/${analyticalType?.toLowerCase()}/addValues`,
            {
                analyticalId,
                parentType,
                parentId,
                values: [
                    {name, unit, value: customValue, additional: true}
                ]
            }
        ).then(_ => {
            dispatch("valuesChanged")
            name = undefined;
            unit = undefined;
            customValue = undefined;
        })
    }

    const addAutocompleteValue = () => {
        if (!acValueObj.name || !autocompleteValue) {
            let notification = DefaultNotification($t('Analytics.arbitraryValues.error.missingValues'), NotificationType.ERROR);
            addNotification(notification);
            return;
        }

        fetchUtils.post(`/api/${analyticalType?.toLowerCase()}/addValues`,
            {
                analyticalId,
                parentType,
                parentId,
                values: [
                    {
                        ...acValueObj,
                        value: autocompleteValue
                    }
                ]
            }
        ).then(_ => {
            dispatch("valuesChanged")
            acValueObj = DefaultAnalyticalValue();
            autocompleteValue = undefined;
        })
    }
</script>

<div class="arbitrary-values-container" style="padding-bottom: {paddingBottom}">
    <h1>{$t('Analytics.arbitraryValues.add')}</h1>

    <CollapsibleCard>
        <div slot="header" class="addvalue-header">
            <h3>{$t('Analytics.arbitraryValues.addArbitrary')}</h3>
        </div>
        <div slot="body" class="addvalue">
            <div class="custom-value-layout">
                <div class="custom-value-name">
                    <InputField bind:value={name} placeholder={$t('Analytics.material')}
                                fontProps={inputFieldFontProps} format={inputFieldFormats.FULL}/>
                </div>
                <InputField bind:value={unit} placeholder={$t('Model.unit')}
                            fontProps={inputFieldFontProps} format={inputFieldFormats.FULL}/>
                <InputField bind:value={customValue} placeholder={$t('Model.value')}
                            fontProps={inputFieldFontProps} format={inputFieldFormats.FULL}
                            isNumeric/>
            </div>
            <div class="buttons">
                <SecondaryButton label={$t('Analytics.arbitraryValues.btn.add')} on:click={addCustomValue}/>
            </div>
        </div>
    </CollapsibleCard>
    <CollapsibleCard>
        <div slot="header" class="addvalue-header">
            <h3>{$t('Analytics.arbitraryValues.addKnown')}</h3>
        </div>
        <div slot="body" class="addvalue">
            <div class="custom-value-layout">
                <div class="custom-value-name">
                    <AutocompleteInputField bind:selectedObject={acValueObj} autocompleteConfig={acConfig}
                        label={$t("Analytics.material")}/>
                </div>
                <InputField bind:value={autocompleteUnit} placeholder={$t('Model.unit')}
                            fontProps={inputFieldFontProps} format={inputFieldFormats.FULL} readOnly/>
                <InputField bind:value={autocompleteValue} placeholder={$t('Model.value')}
                            fontProps={inputFieldFontProps} format={inputFieldFormats.FULL} isNumeric/>
            </div>


            <div class="buttons">
                <SecondaryButton label={$t('Analytics.arbitraryValues.btn.add')} on:click={addAutocompleteValue}/>
            </div>
        </div>
    </CollapsibleCard>
</div>


<style lang="scss">
  @import "../../styles/global";

  .arbitrary-values-container {
    @include flex-col($row-gap: 0.62rem, $alignment: normal, $justify: normal);
    width: 100%;
  }

  h1 {
    @include roboto-font(30px, 500, 20px, #1e1e1e);
  }

  .addvalue h3, .addvalue-header h3 {
    @include roboto-font(12px, 500, 10px, #999999);
    text-transform: uppercase;
  }

  .addvalue-header h3 {
    margin-bottom: 0;
  }

  .addvalue {
    width: 100%;
    padding: 26px 24px;
    margin-bottom: 10px;
  }

  .addvalue-header {
    width: 100%;
    padding: 26px 24px;
    display: grid;
    grid-template-columns: 60% 40%;
  }

  .addvalue :global(.secondary-button) {
    width: unset;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }

  .custom-value-layout {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 15px;
  }

  .custom-value-name {
    grid-column-end: span 2;
  }
</style>