<script lang="ts">

    import {replaceUrl} from "../utils/misc";
    import {onMount} from "svelte";
    import {getJwt, jwt} from "../utils/jwt";
    import LoadingSkeleton from "../animations/LoadingSkeleton.svelte";
    import Basic from "../templates/Basic.svelte";
    import {hideAnimation, manualOpenWhitelist, showAnimation} from "../stores";
    import {fetchUtils} from "../utils/fetchUtils";
    import LoadingAnimations from "../organisms/LoadingAnimations.svelte";
    import {LoadingAnimation} from "../types/enums";

    let frameSrc: string;
    let helpBoxOpen: boolean = false;

    let iFrameLoaded: boolean = false;

    let iFrameUrl: string = "";

    function setIFrameInfoAfterLoad() {
        iFrameLoaded = true
        let fr: HTMLIFrameElement = top?.document?.getElementById("mainFrame") as HTMLIFrameElement;
        iFrameUrl = fr.src;
    }

    function getHrefReplaced() {
        return window.location.href.replace('http://localhost:5000', 'http://localhost:9000');
    }

    onMount(async () => {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let requestId = urlParams.get('requestId')

        if (!requestId) {
            if (window.location.pathname === '/' || window.location.pathname === '/app') {
                const url = new URL('/admin', getHrefReplaced());
                url.search = window.location.search;
                frameSrc = url.toString();
            } else {
                const url = new URL((!window.location.pathname.startsWith('/app/') ? '/app' : '') + window.location.pathname, getHrefReplaced());
                url.search = window.location.search;
                frameSrc = url.toString();
            }
        }
        let fr: HTMLIFrameElement = top?.document?.getElementById("mainFrame") as HTMLIFrameElement;
        if (fr) {
            if (requestId) {
                await fetchCachedRequestAndSubmitForm(requestId);
            } else {
                fr.src = frameSrc.toString();
            }
        } else {
            let ifrm = top?.document?.createElement("iframe");
            if (ifrm) {
                ifrm.src = frameSrc.toString();
                ifrm.style.width = "100%";
                ifrm.style.height = "100%";
                ifrm.allowFullscreen = true;
                ifrm.id = "mainFrame";
                ifrm.name = "mainFrame";
                ifrm.title = "Mineral Minds";
                top?.document.getElementById("mainFrameContainer")?.appendChild(ifrm);
            }
        }
    })

    function handlePostMessages(e: MessageEvent) {
        if (e.origin !== window.location.origin || !e.data.type) { //FIXME is this secure ??
            return;
        }

        // noinspection FallThroughInSwitchStatementJS This is intentional
        switch (e.data.type) {
            case "iframeInfo":
                updateUrl(e.data.content);
                break;
            case "iframeClick":
                window.dispatchEvent(new CustomEvent("iframeClick"));
            case "iframeTouch":
            case "iframeKey":
                window.dispatchEvent(new CustomEvent("iframeActivity"));
                break;
            case "openWhitelist":
                $manualOpenWhitelist = true;
                break;
        }
    }

    function updateUrl(data: {
        path: string,
        search: string
    }) {
        let newPath = data.path.replace('/app/', '/');
        let search = data.search;
        if (newPath.endsWith('/')) {
            newPath = newPath.slice(0, -1);
        }

        checkIds();
        if (window.location.pathname !== newPath && top?.document.location.pathname !== newPath) {
            replaceUrl(newPath, window.top, new URLSearchParams(search));
        }
        helpBoxOpen = false; // Close help modal when url changes
    }

    function checkIds() {
        let fr: HTMLIFrameElement | null = top?.document.getElementById("mainFrame") as HTMLIFrameElement;
        if (fr) {
            let frDoc: Document | undefined = fr.contentDocument || fr.contentWindow?.document;
            if (frDoc) {
                let uid = (frDoc.getElementsByName("user-id")[0] as HTMLInputElement)?.value || "";
                let tid = (frDoc.getElementsByName("tenant-id")[0] as HTMLInputElement)?.value || "";
                let spyuid = (frDoc.getElementsByName("spy-user-id")[0] as HTMLInputElement)?.value || "";
                let spytid = (frDoc.getElementsByName("spy-tenant-id")[0] as HTMLInputElement)?.value || "";
                let ids = {uid, spyuid, tid, spytid};
                if (!isJWTOutdated(ids)) {
                    return;
                }
            }
        }
        getJwt();
    }

    function isJWTOutdated(ids: any) {
        for (const [key, value] of Object.entries(ids)) {
            if (value && $jwt[key] !== value) {
                return true;
            }
        }
        return false;
    }

    // this function fetches parameters of a post request which are cached. it's needed since we
    // lose our post parameters by redirecting from svelte to a sirius iFrame
    // more in SvelteController.java
    async function fetchCachedRequestAndSubmitForm(requestId: String) {
        await fetchUtils.get("/api/cachedRequest/" + requestId)
            .then(data => {
                let hiddenForm = top?.document?.createElement("form");
                if (hiddenForm) {
                    hiddenForm.id = "cachedRequestForm"
                    hiddenForm.name = "cachedRequestForm"
                    hiddenForm.method = "post";
                    hiddenForm.target = "mainFrame";
                    hiddenForm.action = "/app" + window.location.pathname;

                    Object.keys(data).forEach(key => {
                        let input = top?.document?.createElement("input");
                        if (input) {
                            input.name = key;
                            input.value = data[key];
                            input.type = "hidden";

                            hiddenForm.appendChild(input);
                        }
                    })

                    document.body.appendChild(hiddenForm);

                    hiddenForm.submit();
                }
            })
            .catch(fetchUtils.catchErrorAndShowNotification());
    }

    document.addEventListener("showLoadingAnimation", (e) => {
        showAnimation(LoadingAnimation.MmLoader);
    });

    document.addEventListener("hideLoadingAnimation", (e) => {
        hideAnimation(LoadingAnimation.MmLoader);
    });

</script>

<svelte:window on:message={handlePostMessages}/>
<LoadingAnimations showOnMount={false}/>
<Basic bind:helpBoxOpen bind:iFrameUrl showLoadingAnimation={false} fullscreen>
    <div class="one-row-grid">
        <div class="skeleton-holder" class:iFrameLoaded>
            <LoadingSkeleton repeat={6}/>
        </div>
        <iframe id="mainFrame" name="mainFrame" src={frameSrc} title="Mineral Minds" width="100%" height="100%"
                allowfullscreen on:load={() => setIFrameInfoAfterLoad()} data-hj-allow-iframe></iframe>
    </div>
</Basic>
<!-- Hint for selenide tests -->
{#if iFrameLoaded}
    <div id="iFrameLoaded"></div>
{/if}

<style lang="scss">
  @use "../../styles/global";

  iframe {
    // https://stackoverflow.com/questions/21025319/iframe-creates-extra-space-below
    display: block;
  }

  #mainFrame {
    grid-row: 1/1;
  }

  .one-row-grid {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: 100%;
  }

  .skeleton-holder {
    /* above sirius page and fade out once sirius page has loaded */
    grid-row: 1/1;
    z-index: 100;
    position: absolute;
    @include global.flex-col();
    width: 100%;
    height: 100%;

     &.iFrameLoaded {
       opacity: 0;
       height: 0;
       visibility: hidden;
       transition: opacity 0.5s ease-in-out 0.2s, visibility 0s linear 0.7s;
     }
  }
</style>
