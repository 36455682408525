<script lang="ts">
    import AutocompleteInputField from "./AutocompleteInputField.svelte";
    import type {AutocompleteFieldConfig} from "../types/autocompleteFieldConfig";
    import {getFormAcConfigFetch} from "../types/autocompleteFieldConfig";
    import {t} from "../../i18n/i18n";
    import type {FontProps} from "../types/fontProps";
    import InputField from "../molecules/InputField.svelte";
    import {inputFieldFormats} from "../utils/formatters";
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import {createEventDispatcher} from "svelte";
    import {createTippy} from "svelte-tippy";
    import type {CompanyData} from "../types/companyData";
    import type {PersonAndCompanyData} from "../types/personAndCompanyData";
    import {copyPersonAndCompanyData, DefaultPersonAndCompanyData,} from "../types/personAndCompanyData";

    export let contactPerson: PersonAndCompanyData = DefaultPersonAndCompanyData();
    export let personLink: string = "";
    export let onboardingComment: string = "";
    export let company: CompanyData = null;
    export let fontProps: FontProps;
    export let autocompleteVisibleStore;
    export let editView: { canEditOthers: boolean, canEditPhone: boolean } = {canEditOthers: true, canEditPhone: true};
    export let required: boolean = false;
    export let errorPath: string;
    export let isEditable: boolean = false;
    export let showCompany: boolean = true;
    export let outerId: string = ""; // used to detect if another lot or project is selected
    export let isNew: boolean = true;
    export let personInputId = "person-input-id" + Math.random().toString(16).slice(2);
    export let companyInputId = "company-input-id" + Math.random().toString(16).slice(2);

    const dispatch = createEventDispatcher();

    updateCompanyInPerson();
    let contactPersonId = contactPerson ? contactPerson.id : "";
    let options = contactPerson ? [copyPersonAndCompanyData(contactPerson)] : [];
    let companyString = getCompanyString();
    let isPersonLinkEditable = personLink == null || personLink === "";
    updateLinkEditable();

    $: outerId, updateLinkEditable()
    $: contactPerson, updateContactPerson()

    function updateLinkEditable() {
        isPersonLinkEditable = personLink == null || personLink === "";
    }

    function updateContactPerson() {
        if (contactPerson && contactPerson.lastName) {
            showAutocomplete = true;
            updateCompanyInPerson();
            contactPersonId = contactPerson.id;
            options = [copyPersonAndCompanyData(contactPerson)];
        } else {
            contactPerson = null;
            contactPersonId = null;
            options = [];
        }
    }

    const contactPersonAutocompleteConfig: AutocompleteFieldConfig = getFormAcConfigFetch('person-data', false, 'id', 'text', !isEditable);
    $: disabled = !isEditable;


    function getCompanyString() {
        if (contactPerson && contactPerson.companyName && contactPerson.address) {
            return contactPerson.companyName + ", " + contactPerson.address.zip + " " + contactPerson.address.street;
        }
        return "";
    }

    function updateCompanyInPerson() {
        if (company && contactPerson && contactPerson.text && company.companyName) {
            if (!contactPerson.text.includes(",")) {
                contactPerson.text = contactPerson.text + ", " + company.companyName;
            }
            contactPerson.companyName = company.companyName;
            contactPerson.address = company.address;
            contactPerson.showCompany = true;
        } else if (contactPerson) {
            contactPerson.showCompany = false;
        }
    }

    function autocompleteValueChanged() {
        if (!contactPerson) {
            contactPersonId = null;
        } else {
            contactPersonId = contactPerson.id;
        }

        if (contactPerson && contactPerson.companyName) {
            companyString = contactPerson.companyName + ", " + contactPerson.address.zip + " " + contactPerson.address.street;
        } else {
            companyString = "";
        }

        if (contactPersonId || personLink) {
            dispatch("change");
        }
    }

    let showAutocomplete = true;

    function toggle() {
        showAutocomplete = !showAutocomplete;
    }

    function togglePerson() {
        if (isEditable && !(contactPerson && contactPerson.lastName)) {
            toggle();
        }
    }


    // Use the appendTo to force placement: 'right', which would otherwise be overwritten by popper option 'flip'
    const disabledTippy = createTippy({
        animation: 'scale-extreme',
        content: $t("UI.map.contactPerson.disabled.tt"),
        delay: [100, 200],
        hideOnClick: false,
        interactive: true,
        maxWidth: 367,
        placement: 'left',
        theme: 'mmttip',
        appendTo: document.getElementsByClassName('fak-base-layout')[0],
    });
    const filledTippy = createTippy({
        animation: 'scale-extreme',
        content: $t("UI.map.contactPerson.filled.tt"),
        delay: [100, 200],
        hideOnClick: false,
        interactive: true,
        maxWidth: 367,
        placement: 'left',
        theme: 'mmttip',
        appendTo: document.getElementsByClassName('fak-base-layout')[0],
    });
    const addTippy = createTippy({
        animation: 'scale-extreme',
        content: $t("UI.map.contactPerson.create.tt"),
        delay: [100, 200],
        hideOnClick: false,
        interactive: true,
        maxWidth: 367,
        placement: 'left',
        theme: 'mmttip',
        appendTo: document.getElementsByClassName('fak-base-layout')[0],
    });
    const linkTippy = createTippy({
        animation: 'scale-extreme',
        content: $t("UI.map.contactPerson.link.tt"),
        delay: [100, 200],
        hideOnClick: 'toggle',
        interactive: true,
        maxWidth: 367,
        placement: 'right',
        theme: 'mmttip',
        appendTo: document.getElementsByClassName('fak-base-layout')[0],
    });
</script>

<div class="autocomplete-grid">
    {#if showCompany}
        <InputField bind:value={companyString} id={companyInputId} format={inputFieldFormats.FULL}
                    placeholder={$t('UI.Model.company')}
                    {fontProps} readOnly
                    errorPath={errorPath + '.*'}/>
    {/if}
    {#if showAutocomplete}
        <AutocompleteInputField bind:selectedObject={contactPerson}
                                bind:value={contactPersonId} bind:options bind:disabled id={personInputId}
                                {required} {fontProps} autocompleteConfig={contactPersonAutocompleteConfig}
                                label={$t('UI.map.project.contactPerson')} errorPath={errorPath + '.*'}
                                on:change={autocompleteValueChanged}>
            <!-- this should trigger an error for the subfields, too -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div class="control-end" slot="control-end" on:click={togglePerson}>
                {#if contactPerson}
                    <div class="separator"></div>
                {/if}
                {#if !isEditable}
                    <div class="disabled-icon" use:disabledTippy>
                        <div class="add-icon-20"></div>
                    </div>
                {:else if contactPerson && contactPerson.lastName}
                    <div class="disabled-icon" use:filledTippy>
                        <div class="add-icon-20"></div>
                    </div>
                {:else}
                    <div class="green-icon" use:addTippy>
                        <div class="add-icon-20"></div>
                    </div>
                {/if}
            </div>
        </AutocompleteInputField>
    {:else}
        <div class="person-link-field" use:linkTippy>
            <InputField bind:value={personLink} format={inputFieldFormats.FULL}
                        placeholder={$t('UI.contactPerson.link.ph')}
                        readOnly={!editView.canEditOthers || !isPersonLinkEditable}
                        errorPath={errorPath + '.personLink'} required
                        on:change/>
        </div>
        {#if isNew}
            <InputField bind:value={onboardingComment} format={inputFieldFormats.FULL}
                        placeholder={$t('Lot.onboardingComment')}
                        readOnly={!editView.canEditOthers || !isPersonLinkEditable} on:change/>
        {/if}
        <div class="discard-button-row">
            <SecondaryButton color={SecondaryBtnColors.RED} label={$t('UI.button.dismiss')} paddingHorizontal="1.375rem"
                             paddingTop="0.875rem" sizeAdaptingToText
                             on:click={toggle}/>
        </div>
    {/if}
</div>


<style lang="scss">
  @import "../../styles/global";
  @import "../../styles/autocomplete";

  .autocomplete-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 0.938rem;
  }

  .discard-button-row {
    @include flex-row(0.375rem, center, flex-end);
  }

  .control-end {
    @include flex-row(0.375rem);
    margin-right: 0.25rem;

    & .separator {
      width: 1px;
      background: $grey-700;
      display: block;
      height: 1.25rem;
    }
  }

</style>
