<script lang="ts">
    import {t} from "../../i18n/i18n";
    import {formatNumber} from "../utils/formatters"

    export let value: string | number;
    export let displayShortUnit: boolean = true;
    export let needsFormatting: boolean = false;
</script>

{#if value}
    {#if displayShortUnit}
        {needsFormatting ? formatNumber(value) : value}&nbsp;{displayShortUnit ? $t('UI.unit.ton.short.small') : $t('UI.unit.ton')}
    {/if}
{/if}
