<script lang="ts">

    import Svelecte, {config, highlightSearch} from "svelecte"; // https://mskocik.github.io/svelecte
    import Tooltip from "../atoms/Tooltip.svelte";
    import {t} from "../../i18n/i18n";
    import clearSvelecte from "@/icons/icon_clear_svelecte.svg";

    import type {FontProps} from "../types/fontProps";
    import {DefaultInputFieldProps, DefaultRobotoFontProps, getFontPropsStyleString, getFontPropsVarString} from "../types/fontProps";
    import type {AutocompleteFieldConfig} from "../types/autocompleteFieldConfig";
    import {DefaultAutocompleteConfig} from "../types/autocompleteFieldConfig";
    import Icon from "../atoms/Icon.svelte";
    import {getContext, hasContext, onMount} from "svelte";
    import {markFieldLabelRequired} from "../utils/formatters";
    import {onClassChange} from "../utils/mutationObserver";
    import {openCloseVariants} from "../utils/svelteMotionUtils";
    import {AnimatePresence, AnimateSharedLayout, Motion} from "svelte-motion";
    import {getExactPathErrorMessage, getPathErrorMessage, isExactPathInErrorList, isPathInErrorList} from "../utils/formValidation";
    import type {SearchProps} from "svelecte/dist/utils/list";
    import type {Writable} from "svelte/store";
    import type {FormError} from "../types/formError";

    config.i18n = {
        ...config.i18n,
        empty: $t('UI.map.svelecte.empty'),
        nomatch: $t('UI.map.svelecte.nomatch'),
        max: num => $t('UI.map.svelecte.max', {num: num}),
        fetchBefore: $t('UI.map.svelecte.fetchBefore'),
        fetchQuery: minQuery => `Tippe ${minQuery > 1 ? `mindestens ${minQuery} Zeichen ` : ''}, um zu suchen`,
        fetchEmpty: $t('UI.map.svelecte.fetchEmpty'),
        collapsedSelection: count => $t('UI.map.svelecte.collapsedSelection', {count: count}),
        createRowLabel: value => $t('UI.map.svelecte.createRowLabel', {value: value})
    }

    const useMaterialDesign: boolean = hasContext('useMaterialDesign') ? getContext('useMaterialDesign') : false;

    export let materialWidth: string = "100%";
    export let selectedObject: any = null; // required to get the selected object and not only the label string
    export let value: any = null; // in case we are only interested in the value that is showing in the input field, not the whole object
    // we give label and placeholder explicitly, this way we can also create a static field when using the material design
    export let label: string = ""; // label above the input field, which is animated
    export let placeholder: string | null = null; // when the placeholder is set, it is only displayed in the field
    export let ttText: string = ""; // tooltip beneath the input field
    export let borderRadius: string = '2px';
    export let fontProps: FontProps = DefaultInputFieldProps();
    export let headerFontProps: FontProps = DefaultRobotoFontProps("14px", "0.875rem");
    export let autocompleteConfig: AutocompleteFieldConfig = DefaultAutocompleteConfig();
    export let options: any[] = [];
    export let hideDropdownArrow: boolean = true;
    export let required: boolean = false;
    export let renderer: any = null;
    export let containerWidth = "100%";
    export let disabled: boolean = autocompleteConfig.disabled;
    export let disableCreateRowLabel: boolean = false;
    export let height = "2rem";
    export let id: string = "autocomplete-id" + Math.random().toString(16).slice(2);
    export let dropdownMaxHeight: string = "unset";
    $: inputId = id + "-input";

    let notEmpty: boolean;
    let isFocused: boolean; // is set to true when we focus in the input field -> value set via MutationObserver (class is-active of sv-control element)
    let changingField;
    let activeBorder = useMaterialDesign ? "2px solid var(--sv-border-color-active)" : "1px solid var(--sv-border-color-active)";
    let isValueSelected: boolean;

    const defaultSearchProps: Partial<SearchProps> = {
        skipSort: true,
        disabled: false,
        startOnly: false,
        wordsOnly: false
    };

    /* Handling of the error message indication with a context store that is set on the parent including the form */
    export let errorPath: string = '';
    let formErrors: Writable<FormError[]> | null = hasContext('formErrors') ? getContext('formErrors') : null;
    let error: string;
    $: if (errorPath && errorPath !== '' && $formErrors != null) {
        let paths = errorPath.includes('||') ? errorPath.split('||') : [errorPath];
        error = "";
        paths.forEach(path => {
            if (path.includes('.*')) {
                error += isPathInErrorList($formErrors, path) ? getPathErrorMessage($formErrors, path) : '';
            } else {
                error += isExactPathInErrorList($formErrors, path) ? getExactPathErrorMessage($formErrors, path) : '';
            }

        })
    }

    function checkNotEmpty(): boolean {
        return Array.isArray(value) ? value.length > 0 : !!value
    }

    function itemRenderer(item: any) {
        if (item) {
            if (renderer && typeof renderer === 'function') {
                return renderer(item);
            }
            if (autocompleteConfig.labelField) {
                return item[autocompleteConfig.labelField];
            }
            return item.label || item.text;
        }
        return '';
    }

    onMount(() => {
        if (required && label !== '') {
            label = markFieldLabelRequired(label);
        }
        isValueSelected = checkNotEmpty();
        notEmpty = !!(isValueSelected || (placeholder && label));
        const inputField = document.getElementById(inputId);
        // Sometimes, somehow, this component gets rebuild after getting destroyed (probably concurrently due to reactivity).
        // This can cause the inputField with "inputId" to not be present. Therefor, we check this here.
        if (inputField) {
            changingField = inputField?.closest(".svelecte-control"); // searches in the parent(s) for the selector
            onClassChange(changingField, (node) => {
                isFocused = node.classList.contains('is-focused');
            });
        }
    })

    $: hideSeparator = $$slots['control-end'] !== true;
    $: fontPropsStyleString = getFontPropsStyleString(fontProps);
    $: fontPropsVarString = getFontPropsVarString(fontProps);
    // if value is not falsy value, notEmpty is true or if we have label AND placeholder
    $: value, isValueSelected = checkNotEmpty();
    $: value, label, placeholder, notEmpty = !!(isValueSelected || (placeholder && label));


    $: if (disableCreateRowLabel) {
        // we need to set the whole object in order to overwrite a createRowLabel
        config.i18n = {
            ...config.i18n,
            empty: $t('UI.map.svelecte.empty'),
            nomatch: $t('UI.map.svelecte.nomatch'),
            max: num => $t('UI.map.svelecte.max', {num: num}),
            fetchBefore: $t('UI.map.svelecte.fetchBefore'),
            fetchQuery: minQuery => `Tippe ${minQuery > 1 ? `mindestens ${minQuery} Zeichen ` : ''}, um zu suchen`,
            fetchEmpty: $t('UI.map.svelecte.fetchEmpty'),
            collapsedSelection: count => $t('UI.map.svelecte.collapsedSelection', {count: count}),
            createRowLabel: value => `${value}`
        }
    }
</script>

<AnimateSharedLayout>
    <div {id} class="input-field-error-message-container" style="--container-width: {containerWidth}">
        <div class="autocomplete-container" class:hideDropdownArrow class:isValueSelected class:hideSeparator class:isFocused
             class:material-design={useMaterialDesign} class:noClear={!autocompleteConfig.clearable}
             style="width: 100%;
                --input-width: {materialWidth};
                --dropdown-max-height: {dropdownMaxHeight};
                --sv-border-radius:{borderRadius};
                --sv-border: 1px solid var(--sv-border-color);
                --sv-active-border: {activeBorder};
                --sv-border-color: #D9D9D9 !important;
                --sv-border-color-active: #58C079 !important;
                --sv-highlight-bg: rgba(88, 192, 121, 0.3) !important;
                --sv-placeholder-color: {fontProps.placeholderColor || '#9d9d9d'};
                --sv-bg: white;
                --sv-item-active-bg: #F0F2F8 !important;
                --sv-min-height: {height};
                {fontPropsStyleString || ''}">
            {#if label && label !== undefined && label !== "" && !useMaterialDesign}
                <div class="label-container">
                    <span class="input-label-variable" style="{getFontPropsVarString(headerFontProps)}">{label}</span>
                    {#if ttText !== undefined && ttText !== ""}
                        <div>
                            <Tooltip msg={ttText}/>
                        </div>
                    {/if}
                </div>
            {/if}
            <Svelecte bind:value bind:readSelection={selectedObject} bind:options {...autocompleteConfig} {inputId}
                      {renderer} {disabled} {placeholder}
                      searchProps={{...defaultSearchProps, ...autocompleteConfig.searchProps}}
                      collapseSelection={autocompleteConfig.multiple ? "always" : null}
                      keepSelectionInList highlightFirstItem={false}
                      on:change>
                <!-- see main.scss for the meaning, somehow the main.scss does not override the variables -->

                <slot slot="icon" name="icon"/>
                <!-- implement the checkboxes when allowing multiple selections -->
                <div class="multiselect-options" slot="option" let:item let:inputValue>
                    <div class="icon-checkbox" class:selected={item.$selected}
                         class:borderless={!autocompleteConfig.multiple}></div>
                    <div class="sv-item--content" title={itemRenderer(item)}>
                        <span>{@html highlightSearch(item, true, inputValue, itemRenderer, false)}</span>
                    </div>
                </div>
                <div class="close-icon" slot="clear-icon" let:selectedOptions>
                    {#if selectedOptions.length !== 0}
                        <Icon src={clearSvelecte} size={20} clickable/>
                    {/if}
                </div>
                <svelte:fragment slot="dropdown-toggle" let:isOpen>
                    {#if !hideDropdownArrow && !isValueSelected} <!-- only show that when no element is selected -->
                        <i class="bx bx-chevron-down sv-toggle" class:bx-rotate-180={isOpen}></i>
                    {/if}
                </svelte:fragment>
                <slot slot="control-end" name="control-end"/>

            </Svelecte>
            {#if useMaterialDesign}
                <label class="material-label" style={fontPropsVarString} for={inputId} id="label-{inputId}"
                       class:notEmpty class:isFocused class:material-label-disabled={disabled}>
                    <div class="material-text">{label}</div>
                </label>
            {/if}
        </div>
        <AnimatePresence list={error && error !== '' ? [{ key: 1 }] : []}>
            <Motion initial="collapsed" animate="open" exit="collapsed" variants={openCloseVariants}
                    let:motion={collapse}>
                <span class="form-field-error" use:collapse>{@html error}</span>
            </Motion>
        </AnimatePresence>
    </div>
</AnimateSharedLayout>

<style lang="scss">
  @import "../../styles/global";
  @import "../../styles/inputFields";
  @import "../../styles/autocomplete";

  .label-container {
    @include flex-row(0.25rem, $alignment: flex-end, $justify: flex-start);
    position: relative;
  }

  .autocomplete-container {
    width: 100%;
    @include flex-col(0.375rem, $justify: flex-start, $alignment: flex-start);

    & :global(.svelecte) {
      width: 100%;
    }

    &:hover {
      cursor: text !important;
    }
  }

  .autocomplete-container :global(.sv-control) {
    border-radius: var(--sv-border-radius) !important;
    height: var(--sv-height) !important;
    min-height: 2rem !important;
  }

  .autocomplete-container :global(.sv-content) {
    padding: 0.25rem 0.25rem 0 0.5rem;
  }

  .autocomplete-container :global(.sv-control.is-disabled),
  .autocomplete-container :global(.sv-control.is-disabled > .sv-content),
  .autocomplete-container :global(.sv-control.is-disabled > .sv-content > div),
  .autocomplete-container :global(.sv-control.is-disabled > .sv-content > div > .sv-item),
  .autocomplete-container :global(.sv-control.is-disabled > .sv-content .sv-item--content) {
    cursor: not-allowed;
    pointer-events: auto;
    color: $grey-800 !important;
  }


  .hideSeparator :global(.indicator-separator) { // hides the separator line
    display: none;
  }

  .autocomplete-container :global(div .indicator-container.close-icon) {
    padding: 0 8px;
  }

  .autocomplete-container:not(.noClear) :global(.indicator-container:first-of-type > svg) { // when clearable is not activated, the dropdownArrow is hidden
    display: none;
  }

  .hideDropdownArrow,
  .isValueSelected{
    :global(button.sv-btn-indicator[data-action="toggle"]) { // hides the dropdown arrow
      display: none;
    }
  }

  /* always hide the separator */
  :global(span.sv-btn-separator) {
    display: none;
  }

  // explicitly leave this here as information
  .autocomplete-container :global(.sv-item--content) {
    display: initial; // we must not use flex here, because this destroys the highlighting and removes spaces between words
  }


  .autocomplete-container :global(.sv-dd-item > .sv-item) { // makes padding only to the items in the dropdown list, not to the selected ones
    padding-left: 1rem !important; // eventually put this one in RoutePlanerSearchBox as well, if not applicable to all usages of AddressSearchbox
    line-height: 1.5 !important; // otherwise, the options look too small
  }

  .autocomplete-container :global(.sv-optgroup-header) {
    text-align: start;
    padding: 0.5rem 0.75rem;
    background: $grey-500;
  }

  :global(.sv_dropdown) {
    max-height: var(--dropdown-max-height);
  }

</style>
