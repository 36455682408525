import {number, object, string} from "yup";
import {translate} from "../../../i18n/i18n";

export type Catalog = {
    archived: boolean,
    category: string,
    comment: string,
    id: string,
    maxDepth: number,
    name: string,
    nodes: any,
    highlightedValue: string
}

export const catalogSchema = object({
    maxDepth: number()
        .required(translate('de', 'UI.mmpv.catalog.validation.maxDepth.required', []))
        .positive(translate('de', 'UI.mmpv.catalog.validation.maxDepth.notNegative', [])),
    name: string().trim().required(translate('de', 'UI.mmpv.catalog.validation.name.required', [])),
    id: string(),
}).required();

export function DefaultCatalog(): Catalog {
    return {
        archived: false,
        category: '',
        comment: '',
        id: "new",
        maxDepth: null,
        name: '',
        nodes: [],
        highlightedValue: "*"
    };
}
