<script lang="ts">
    /*https://svelte.dev/repl/5e140076757c4a9cab98628fff08d9cd?version=3.34.0*/
    import SidebarButton from "../atoms/SidebarButton.svelte";
    import {fade} from 'svelte/transition';
    import {userIsLoggedIn} from "../stores";

    const fadeIn = {
        delay: 400,
        duration: 300
    }

    const fadeOut = {
        delay: 0,
        duration: 100
    }

    export let open = false;
    export let position: 'right' | 'left' = 'right';

</script>

<section class="scrollable {position}" class:expanded={open} class:closed={!open}
        style="{$userIsLoggedIn ? 'margin-top:' : 'padding-top:'} var(--header-height)">
    <div class="sidebar-button-container" style="padding-top: {$userIsLoggedIn ? '61px' : '0'}">
        <SidebarButton {open} on:toggleSidebar={() => open = !open}/>
    </div>
    {#if open}
        <div id="sidebar-content" in:fade={fadeIn} out:fade={fadeOut} class="scrollable">
            <slot></slot>
            <!--this slot makes it possible to not have fixed content inside the sidebar, but to use it as a normal tag that includes code -->
        </div>
    {/if}
</section>


<style lang="scss">
  @import "../../styles/global";

  section {
    position: fixed;
    top: 0;
    margin-bottom: var(--footer-height);
    overflow-y: auto;
    overflow-x: hidden;
    width: 65px;
    height: calc(100% - var(--header-height));
    background-color: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    transition: width ease-out 400ms;
    -webkit-transition: width ease-out 400ms;
    z-index: 2;

    &::-webkit-scrollbar {
      display: none;
    }

    &.right {
      right: 0;
      clip-path: inset(2px 0 2px -25px); /*this allows to reduce the duplicate shadow on top and on the bottom, as there is also header and footer shadow */

      & .sidebar-button-container {
        margin-left: -1.125em;
      }
    }

    &.left {
      left: 0;
      clip-path: inset(2px -25px 2px 0); /*this allows to reduce the duplicate shadow on top and on the bottom, as there is also header and footer shadow */
      display: flex;
      justify-content: flex-end;

      & .sidebar-button-container {
        margin-right: -1.125em;
        transform: rotate(180deg);
      }
    }
  }

  section#sidebar-content {
    height: 100vh;
    @include flex-col(2.5rem, $alignment: center, $justify: flex-start);
  }

  .sidebar-button-container {
    position: fixed;
    margin-top: 6em;
    z-index: 2;
  }

  .expanded {
    width: 280px;
    transition: width ease-out 400ms;
    -webkit-transition: width ease-out 400ms;

    @media screen and (min-width: 1200px) {
      width: 513px;
    }
  }

</style>