<script lang="ts">
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import {t} from "../../i18n/i18n";
    import {AutocompleteConfig} from "../types/autocompleteFieldConfig";
    import type {AnalyticData, PossibleParameters} from "./types/analyticData";
    import {onMount} from "svelte";


    export let analyticData: AnalyticData;
    export let materialNeed: boolean = true;
    export let selectedResult: string | undefined;
    export let resultDescription: string;
    export let selectedParameters: Record<string, string> = {};
    let resultParameters: PossibleParameters;
    const satisfyingOptions = Object.values(analyticData.possibleSatisfyingResults).map(result => {
        return {value: result, text: analyticData.resultMap[result]?.name || result}
    });

    onMount(() => {
        // for those that have at least 1 satisfying result, select the first
        // when there is only one satisfying result (e.g. only SATISFIED), the selection won't be visible to the user
        if (analyticData.possibleSatisfyingResults.length >= 1) {
            selectedResult = analyticData.possibleSatisfyingResults[0];
            updateDescriptionAndParameters();
        }
    })


    function createOptions(data: string[], parameter: string) {
        return Object.values(data).map(value => {
            return {value, text: $t("Analytics.evaluationParameter." + parameter + "." + value)}
        })
    }

    function createConfig() {
        let a = AutocompleteConfig('value', 'text', false, false, true, false);
        a.searchProps = {skipSort: false};
        return a;
    }

    /* Necessary since Svelecte will otherwise show the option names with HTML encoding (like e.g. &lt;) */
    function renderer(item) {
        return `<span>${item.text}</span>`;
    }

    const updateDescriptionAndParameters = () => {
        if (selectedResult) {
            resultParameters = analyticData.resultParameters[selectedResult];
            resultDescription = analyticData.resultMap[selectedResult]?.name || "";
            selectedParameters = {}; // reset selected parameters
        }
    };

    $: selectedResult, updateDescriptionAndParameters();
</script>


<div class="requiredParameters">
    {#if materialNeed}
        {#if analyticData.possibleSatisfyingResults.length > 1}
            {@const autocompleteConfig = createConfig()}
            <AutocompleteInputField bind:value={selectedResult} {renderer} {autocompleteConfig}
                                    options={satisfyingOptions} label={$t("Analytics.desiredResult")}
                                    hideDropdownArrow={false}/>
        {/if}
        {#if selectedResult && resultParameters}
            <!-- whenever resultParameters changes, completely rebuild the autocomplete fields
             to have no buggy UI (duplicate placeholders) -->
            {#key resultParameters}
                {#each Object.keys(resultParameters) as resultParameter}
                    {#if resultParameter}
                        {@const options = createOptions(resultParameters[resultParameter], resultParameter)}
                        {@const autocompleteConfig = createConfig()}
                        <AutocompleteInputField bind:value={selectedParameters[resultParameter]}
                                                {renderer} {options} {autocompleteConfig}
                                                label={$t(`Analytics.evaluationParameter.${resultParameter}`)}
                                                hideDropdownArrow={false}/>
                    {/if}
                {/each}
            {/key}
        {/if}
    {:else}
        {#each analyticData.requiredParameters as requiredParameter, i (i)}
            {@const options = createOptions(analyticData.possibleParameterValues[requiredParameter], requiredParameter)}
            {@const autocompleteConfig = createConfig()}
            <AutocompleteInputField bind:value={selectedParameters[requiredParameter]} {renderer} {options}
                                    {autocompleteConfig} label={$t(`Analytics.evaluationParameter.${requiredParameter}`)}
                                    hideDropdownArrow={false}/>
        {/each}
    {/if}
</div>


<style lang="scss">
  @use "../../styles/global";

  .requiredParameters {
    width: 100%;
    @include global.flex-col(1rem);
  }

</style>
