<script lang="ts">

    import collapse from 'svelte-collapse'
    import {getContext, setContext} from "svelte";
    import type {Writable} from "svelte/store";
    import {writable} from "svelte/store";

    export let id = undefined;
    export let closeOnHeaderClick = false;
    export let borderRadius = "0.25rem";
    export let openByDefault = false;

    let selected: Writable<number> = getContext("selectedItemId");

    /* Serves as a communication channel between the header and footer */
    let itemContext = setContext("itemContext", writable({isOpen: false}));

    let params = {
        open: openByDefault,
        duration: 1,
        easing: 'ease',
    };

    $: $itemContext.isOpen = params.open;

    function handleHeaderClick() {
        if ((params.open && closeOnHeaderClick) || !params.open) {
            if (openByDefault) {
                params.open = !params.open;
            } else {
                $selected = !params.open ? id : null;
            }
        }
    }

    $: if (!openByDefault) {
        params.open = $selected === id;
    }

</script>

<li class="fak-acc-item" id="{'fak-acc-item-' + (id)}" aria-expanded={params.open}>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="{params.open ? 'fak-acc-item-header-opened' : 'fak-acc-item-header-closed'}"
         style="--border-radius:{borderRadius}"
         on:click={handleHeaderClick}>
        <slot name="header"/>
    </div>
    <div class="fak-acc-item-body" use:collapse={params} style="--border-radius:{borderRadius}">
        <slot name="body"/>
    </div>
</li>

<style lang="scss">
  @import "../../../styles/fak";
</style>
