<script lang="ts">
    import {createTippy} from "svelte-tippy";
    import type {Instance} from "tippy.js";
    import 'tippy.js/dist/tippy.css';
    import 'tippy.js/themes/light.css';
    import 'tippy.js/animations/shift-away-subtle.css';
    import 'tippy.js/animations/scale-extreme.css';
    import 'tippy.js/animations/shift-toward-extreme.css';
    import 'tippy.js/dist/svg-arrow.css';
    import Icon from "./Icon.svelte";
    import {onDestroy} from "svelte";
    import {TooltipPlacements} from "../types/enums";
    import infoIcon from '@/icons/icon_info_blue_outline_24px.svg';

    export let msg: string = '';// fix empty spaces
    export let ttipIconImage = infoIcon;
    export let ttipIconWidth: string = '24px';
    export let ttipIconHeight: string = '24px';
    export let delayTtip: [number, number] = [100, 200];
    export let placementTtip: TooltipPlacements = TooltipPlacements.RIGHT;
    export let themeTtip: string = 'mmttip';
    export let maxWidthTtip: number = 367;
    export let animationTTip: string | boolean = 'scale-extreme';
    export let offsetTtip: [number, number] | null = null;
    export let showOnCreate: boolean = false;
    export let isHtml: boolean = true;
    export let appendToObject: Element | "parent" | ((ref: Element) => Element) | undefined = document.body
    export let removeCloseIconAfterClose: boolean = false;

    export function show(withCloseButton = false) {
        if (tippyInstance) {
            showOnCreate = withCloseButton;
            tippyInstance.show();
        }
    }

    export function hide() {
        if (tippyInstance) {
            tippyInstance.hide();
        }
    }

    export function enable() {
        if (tippyInstance) {
            tippyInstance.enable();
        }
    }

    export function disable() {
        if (tippyInstance) {
            tippyInstance.disable();
        }
    }

    let contentMsg: string;

    $: {
        contentMsg = `<div>${msg}</div>`;
    }

    let tippyInstance: Instance;

    function getOffsetTtip(): [number, number] {
        // dividing by 4 shifts the arrow 10px, that's why this value is used here. If the standard icon size is kept
        // the offset remains the same. However, if the icon size changes, we must use a custom offset
        // To center the arrow when using right/left, try to use some offset like [0, x]
        if (placementTtip === TooltipPlacements.BOTTOM_START || placementTtip === TooltipPlacements.TOP_START) {
            return [-Number.parseInt(ttipIconWidth) / 4, 10];
        }

        if (placementTtip === TooltipPlacements.BOTTOM_END || placementTtip === TooltipPlacements.TOP_END) {
            return [Number.parseInt(ttipIconWidth) / 4, 10];
        }
        return [-Number.parseInt(ttipIconWidth) / 4, 10];
    }

    const tippy = createTippy({
        allowHTML: isHtml,
        interactive: true,
        appendTo: appendToObject,
        delay: delayTtip,
        placement: placementTtip,
        animation: animationTTip,
        theme: themeTtip,
        maxWidth: maxWidthTtip,
        offset: offsetTtip ? offsetTtip : getOffsetTtip(),
        showOnCreate: showOnCreate,
        onCreate(instance: Instance) {
            tippyInstance = instance;
        },
        onShown(instance: Instance) {
            if (showOnCreate) {
                const closeButton = document.getElementById("tt-close-button");
                if (closeButton) {
                    let parent = closeButton.parentElement;
                    if (parent) {
                        parent.style.flexDirection = "row !important";
                    }
                    closeButton.style.display = "block";
                    closeButton.addEventListener('click', () => {
                        instance.hide();
                    });
                    instance.popper.querySelector(".tippy-content")?.appendChild(closeButton);
                }
                instance.setProps({
                    hideOnClick: false,
                    trigger: 'manual',
                });
                return instance;
            }
        },
        onHide(instance: Instance) {
            if (showOnCreate && removeCloseIconAfterClose) {
                instance.popper.querySelector("#tt-close-button")?.remove();
            }
        },
        onHidden(instance: Instance) {
            if (showOnCreate) {
                instance.setContent(`<div>${msg}</div>`); //must be nested in div, because otherwise the content is displayed in rows
                instance.setProps({
                    allowHTML: isHtml,
                    delay: [100, 200],
                    hideOnClick: true,
                    trigger: 'mouseenter focus'
                });
                showOnCreate = false;
                return instance;
            }
        },
    });

    onDestroy(() => {
        if (tippyInstance) {
            tippyInstance.hide();
        }
    })

</script>


{#if $$slots.default}
    <div class="tooltip-container-slot" use:tippy={{content: contentMsg}}>
        <slot></slot>
    </div>
{:else}
    <div class="tooltip-info-icon-container" class:showOnCreate use:tippy={{content: contentMsg}}>
        <Icon width={ttipIconWidth} height={ttipIconHeight} src={ttipIconImage} allowTransition={false}/>
    </div>
{/if}

<div id="tt-close-button" class="close-button" style="display: none"></div>

<style lang="scss">
  @import "../../styles/global";

  .tooltip-info-icon-container {
    @include flex-row();
    width: auto;
    height: auto;
  }


  :global(.tippy-box[data-theme='mmttip']) {
    @include roboto-font(1.125rem, 400, 0.75rem, black);
    -webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
    -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=1, Color='rgba(0, 0, 0, 0.25)')";
    filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=1, Color='rgba(0, 0, 0, 0.25)')";
    background-color: #ffffff;
    border-radius: 2px;
    max-height: 47px;
    max-width: 260px;
    min-height: 35px;
    display: table-cell;
    vertical-align: middle;
  }

  :global(.tippy-box[data-theme~='mmttip'] > .tippy-content) {
    padding: 10px 12px 10px;
    display: flex;
    column-gap: 2px;
    line-height: 1.125rem;

  }

  :global(.tippy-box[data-theme~='mmttip'] > .tippy-content > div) {
    @extend .scrollable;
    max-height: 18.75rem;
    overflow-y: auto;
  }

  :global(.tippy-box[data-theme~='mmttip'] > .tippy-arrow::before) {
    color: #ffffff;
    border-radius: 4px;
  }

  .close-button {
    width: 16px;
    height: 20px;
    min-width: 16px;
    cursor: pointer;
    background-size: contain;
    background-image: url('@/icons/icon_cancel_tt.svg');
    background-repeat: no-repeat;
    margin-right: -6px;
    margin-top: -5px; // must be shifted upwards, as margin on top should be only 5px
  }

  .close-button:hover {
    filter: brightness(0%);
  }

  .close-button:not:hover {
    filter: invert(85%) sepia(0%) saturate(18%) hue-rotate(47deg) brightness(95%) contrast(89%);
  }

</style>
