<script lang="ts">
    import {onMount, setContext} from "svelte";
    import {fetchUtils} from "../../../utils/fetchUtils";
    import AnalyticalRow from "./AnalyticalRow.svelte";
    import PrimaryButton, {PrimaryBtnColors} from "../../../atoms/PrimaryButton.svelte";
    import {AnalyticalParentType, AnalyticalType, ButtonIcons, LotState, LotType} from "../../../types/enums";
    import type {Analytical} from "../../../analytics/types/analytical";
    import {openModal} from "svelte-modals";
    import {t} from "../../../../i18n/i18n";
    import NullableDataWrapper from "../../../utils/NullableDataWrapper.svelte";
    import {currentLot} from "../utils/fakStores";
    import Modal from "../../../organisms/Modal.svelte";
    import {derived} from "svelte/store";

    setContext("useMaterialDesign", false);

    export let hasAnalyticals: boolean;


    let lotAnalytics: Analytical[] = [];
    let analyticalType = $currentLot?.type === LotType.BACKFILL || $currentLot?.type === LotType.MATERIAL_NEED ? AnalyticalType.ANALYTIC : AnalyticalType.ANALYSIS;

    onMount(() => {
        fetchAnalytics();
    })


    let currentLotId = derived(
        currentLot,
        $currentLot => $currentLot?.id
    );

    currentLotId.subscribe(() => {
        if ($currentLot) {
            fetchAnalytics();
        }
    });

    function fetchAnalytics() {
        if ($currentLot && $currentLot.id !== "new") {
            fetchUtils.get(`/api/lot/${$currentLot.id}/analytics/`, false)
                .then(data => {
                    lotAnalytics = [...data];
                })
                .catch(fetchUtils.catchErrorAndShowNotification());
        }
    }

    function handleCreateAnalytical() {
        openModal(Modal, {
            maxWidth: '550px',
            padding: '2.5rem 2rem 1.75rem',
            onAccept: () => {
                let origin = encodeURIComponent(window.location.href);
                const url = `/${analyticalType.toLowerCase()}?parentId=${$currentLot?.id}&parentType=LOT&origin=${origin}`
                window.location.assign(url)
            },
            title: $t("UI.map.editAnalysis.modal.title"),
            message: $t(`UI.map.${analyticalType.toLowerCase()}Modal.message`),
            acceptText: $t(`UI.map.modal.create${analyticalType.toLowerCase()}`)
        })
    }

    $: hasAnalyticals = lotAnalytics?.length > 0 ?? false;
    $: analyticalType = ($currentLot?.type === LotType.BACKFILL || $currentLot?.type === LotType.MATERIAL_NEED) ? AnalyticalType.ANALYTIC : AnalyticalType.ANALYSIS;
</script>

<div class="analytic-list-container">
    <NullableDataWrapper bind:data={$currentLot}>
        <div class="analytic-list-container__content">
            {#key lotAnalytics}
                {#each lotAnalytics as analytical}
                    <AnalyticalRow {analytical} analyticalParentType={AnalyticalParentType.LOT}
                                   on:updateAnalytical={fetchAnalytics}/>
                {/each}
            {/key}
        </div>
        <div class="button-with-subcaption">
            <PrimaryButton label={$t(`UI.map.create${analyticalType.toLowerCase()}`)} color={PrimaryBtnColors.GREEN}
                           leftIcon={ButtonIcons.PLUS_BOLD} disabled={$currentLot?.state === LotState.DISCARDED}
                           sizeAdaptingToText whiteIcon
                           on:click={handleCreateAnalytical}/>
            <span>{$t('UI.map.analytic.redirectToAnalytic.buttonHint')}</span>
        </div>
    </NullableDataWrapper>
</div>


<style lang="scss">
  @import "../../../../styles/global";


  .analytic-list-container {
    width: 100%;
    @include flex-col(0.875rem, $alignment: flex-end);
    padding: 0 1.5625rem 1.5rem;
  }

  .analytic-list-container__content {
    width: 100%;
    @include flex-col(0.5rem, $alignment: flex-start, $justify: flex-start);
  }

  .button-with-subcaption {
    @include flex-col(0.25rem, $alignment: flex-end);
    padding-top: 0.75rem;

    & > span {
      @include roboto-font(11px, 400, 0.625rem, black);
    }
  }
</style>