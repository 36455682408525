import type {AddressData} from "./addressData";
import {addressDataToString, addressSchema, copyAddressData, DefaultAddressData,} from "./addressData";
import type {ObjectSchema} from "yup";
import {object, string} from "yup";
import {translate} from "../../i18n/i18n";
import {hashString} from "../utils/utils";

export type CompanyData = {
    id: string,
    companyName: string,
    address: AddressData,
    companyLink?: string,
    text: string,
}

export function DefaultCompanyData() {
    let companyData: CompanyData = {
        id: "",
        address: DefaultAddressData(),
        companyLink: "",
        companyName: "",
        text: "",
    };
    return companyData;
}

export function getCompanyDataAutocompleteText(companyData: CompanyData): string {
    return `${companyData.companyName || '-'},  ${companyData.address.zip} ${companyData.address.city}`;
}

export function copyCompanyData(company: CompanyData, regenerateId = false): CompanyData {
    if (!company) return null;
    let copy: CompanyData = DefaultCompanyData();
    Object.assign(copy, company);
    copy.address = copyAddressData(company.address);
    if (regenerateId) {
        copy.id = generateCompanyDataId(company);
    }
    return copy;
}

export function generateCompanyDataId(company: CompanyData) {
    let str = addressDataToString(company.address) + company.companyLink + company.companyLink;
    return hashString(str);
}

// @ts-ignore TODO check if that is okay
export const companyDataSchema: ObjectSchema<CompanyData> = object({
    // id: string().notRequired(), not needed for server
    companyName: string().required(translate('de', 'UI.validation.company.name.required', [])),
    companyLink: string().url(translate('de', 'UI.validation.company.link.invalid', [])).notRequired(),
    // text: string().notRequired(),
    address: addressSchema.required(translate('de', 'UI.validation.address.required', []))
})
