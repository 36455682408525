<script lang="ts">
    // TODO qr possibly collapse with OptimizerPage -> give saas argument from App.svelte -> less duplicate code
    import OptimizerPageOpenOfferSaas from "./OptimizerPageOpenOfferSaas.svelte";
    import OptimizerPageWithDealSaas from "./OptimizerPageWithDealSaas.svelte";
    import {type ComponentType, onMount, setContext} from "svelte";
    import {type Lot} from "../lot";
    import Themable from "../../corporateDesign/Themable.svelte";
    import {type Writable, writable} from "svelte/store";
    import {type RequestDto, RequestedEntityType, RequestType} from "../../bid/types/requestDto";
    import type {RequestCollecting} from "../../bid/types/requestCollecting";
    import {EmptyOffer, type Offer} from "../../bid/types/offer";
    import {addNotification, loadUserInfo} from "../../stores";
    import {fetchUtils} from "../../utils/fetchUtils";
    import {NotificationType} from "../../types/notification";
    import {Facts} from "../../bid/types/facts";
    import {type DealActualValues, DefaultActualValues} from "../../types/dealActualValues";
    import type {Deal} from "../../bid/types/deal";
    import type {DealPartnerConnect} from "../../types/dealPartner";
    import OptimizerPageRejectedSaas from "./OptimizerPageRejectedSaas.svelte";
    import {type ContactData, DefaultContactData} from "../../types/contactData";

    loadUserInfo();

    // TODO qr fix base url -> probably without public ?
    const baseUrl: string = '/api/optimizer/request';
    setContext("showKickback", writable(false)); // never show kickback on saas, TODO qr is that correct
    const request: Writable<RequestDto | null> = setContext("request", writable(null));
    const offerMaterial: Writable<Offer> = setContext("offerMaterial", writable(EmptyOffer(RequestType.MATERIAL)));
    const offerMaterialWithTransport: Writable<Offer> = setContext("offerMaterialWithTransport", writable(EmptyOffer(RequestType.MATERIAL_WITH_TRANSPORT)));
    const deal: Writable<Deal | null> = setContext("deal", writable(null));
    const dealPartnerConnect: Writable<DealPartnerConnect | null> = setContext("dealPartnerConnect", writable(null));
    const lot: Writable<Lot | null> = setContext("lot", writable(null));

    export let requestPublicId: string | undefined = undefined;
    export let offerUserPublicId: string | undefined = undefined;
    export let requestedEntityId: string | undefined = undefined;
    export let requestedEntityType: RequestedEntityType | undefined = undefined;
    export let lotId: string | undefined = undefined;

    setContext("urlParams", {
        lotPublicId: lotId,
        offerUserPublicId,
        requestPublicId,
        requestedEntityId,
        requestedEntityType
    }); // no writable store, we only want to read the values


    // Set to undefined in order to determine when a real value is loaded and set to the context store.
    // Used e.g. in PublicPageHeader component.
    let isSaasInternalLot: Writable<boolean | undefined> = setContext("isSaasInternalLot", writable(undefined));

    let pageComponent: ComponentType = OptimizerPageOpenOfferSaas;
    let contactData: ContactData = DefaultContactData();


    function getPageComponent(data: RequestCollecting) {
        if (data?.request?.rejectedByOptimizer) {
                return OptimizerPageRejectedSaas;
        }
        if (data?.deal) { // there exists a deal, i.e. the request/offers must have been accepted
            return OptimizerPageWithDealSaas;
        }
        return OptimizerPageOpenOfferSaas; // otherwise always return the opened quote page
    }


    onMount(async () => {
        await fetchRequestCollecting();
    })

    async function fetchRequestCollecting() {
        const url = `${baseUrl}/${requestPublicId}/${offerUserPublicId}`;
        await fetchUtils.get(url)
            .then((data: RequestCollecting) => {
                    $lot = {...data.lot}
                    $request = {...data.request, facts: new Facts(data.request.facts)};
                    if (data.offerMaterial) {
                        $offerMaterial = {...data?.offerMaterial, facts: new Facts(data.offerMaterial.facts)};
                    }
                    if (data.offerMaterialWithTransport) { // only overwrite if not null
                        $offerMaterialWithTransport = {
                            ...data?.offerMaterialWithTransport,
                            facts: new Facts(data.offerMaterialWithTransport.facts)
                        };
                    }
                    if (data.deal) {
                        let offererFacts: Facts = new Facts(data.deal?.offerActualValues?.totalFacts);
                        let requestFacts: Facts = new Facts(data.deal?.requestActualValues?.totalFacts);
                        let offerActualValues: DealActualValues = {
                            ...DefaultActualValues(),
                            ...data.deal?.offerActualValues,
                            totalFacts: offererFacts // must be set by hand to have a facts object
                        };
                        let requestActualValues: DealActualValues = {
                            ...DefaultActualValues(),
                            ...data.deal?.requestActualValues,
                            totalFacts: requestFacts // must be set by hand to have a facts object
                        }
                        $deal = {
                            ...data.deal,
                            dealFacts: new Facts(data.deal.dealFacts), // must be set by hand to have a facts object
                            offerActualValues,
                            requestActualValues
                        };
                        $dealPartnerConnect = {...data.connectData};
                    }
                    pageComponent = getPageComponent(data);
                    contactData = data.optimizerContactData;
                    $isSaasInternalLot = $lot.isSaasInternalLot;
                    // Trigger reactivity
                }
            ).catch((error) => {
                let notification = {
                    message: error.message,
                    type: NotificationType.ERROR,
                    timeout: 0,
                    dismissible: true
                };
                addNotification(notification);
            })
    }

</script>

<Themable {requestPublicId}>
    {#if $lot}
        <svelte:component this={pageComponent} optimizerContactData={contactData}
                          on:offerChanged={fetchRequestCollecting}/>
    {/if}
</Themable>
