<script lang="ts">
    import {AnimatePresence, Motion} from "svelte-motion";

    export let progressLineIconType: string = 'inactive';
    export let ordinalValue: number;
    export let actualPosition: number;
    export let animate: boolean = false;
    export let iconWidth: number;


    const variants = {
        enter: () => {
            return {
                scale: animate ? 0.2 : 1
            };
        },
        center: () => {
            return {
                scale: 1,
                transition: {duration: 0.7}
            }
        },
        exit: () => {
            return {
                scale: animate ? 0 : 1
            };
        },
    };

    function getIconValue() {
        return actualPosition > ordinalValue ? '&#10004;' : ordinalValue + 1;
    }

</script>

<AnimatePresence list={ [{ key: animate, animate }]} let:item={whiteListItem}>
    {#if whiteListItem.animate}
        <Motion layout let:motion={icons} {variants} exit="exit" animate="center" initial="enter">
            <div use:icons class="{'progress-line-icon ' + progressLineIconType}"
                 style="--position: {ordinalValue + 1 };--iconWidth: {iconWidth + 'rem'}">
                <div>
                    {@html getIconValue()}
                </div>
            </div>
        </Motion>
    {:else}
        <Motion layout let:motion={noAnimation}>
            <div use:noAnimation class="{'progress-line-icon ' + progressLineIconType}"
                 style="--position: {ordinalValue + 1 };--iconWidth: {iconWidth + 'rem'}">
                <div>
                    {@html getIconValue()}
                </div>
            </div>
        </Motion>
    {/if}
</AnimatePresence>

<style lang="scss">
  @import "../../styles/global";

  .progress-line-icon {
    z-index: 3;
    width: var(--iconWidth);
    height: var(--iconWidth);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;

    & > div {
      @include roboto-font(0.9375rem, 500, 0.625rem, #4A609F);
    }

    &.inactive {
      border: 0.2rem solid #E9E9E9;
      outline: 4px solid white;
    }

    &.selected {
      border: 0.2rem solid #4A609F;
    }

    &.active {
      border: 0.2rem solid #4A609F;

      & > div {
        color: #58C079;
      }
    }
  }


</style>