<script lang="ts">

    import {t} from "../../../../i18n/i18n";
    import ProjectFileUpload from "./ProjectFileUpload.svelte";
    import FakAccordionItem from "../FakAccordionItem.svelte";
    import FakAccordionItemHeader from "../FakAccordionItemHeader.svelte";
    import ProjectStateChanger from "./ProjectStateChanger.svelte";
    import ProjectComments from "./ProjectComments.svelte";
    import ProjectForm from "./ProjectForm.svelte";
    import {setContext} from "svelte";
    import {writable} from "svelte/store";
    import {currentProject} from "../utils/fakStores";

    export let resizeObserver;

    setContext("selectedItemId", writable(null));

</script>

<ul class="project-accordion" id="project-accordion">
    <li style="padding-bottom: 0.625rem;">
        <ProjectForm {resizeObserver}/>
    </li>

    {#if $currentProject && $currentProject.id !== "new"}
        <ProjectStateChanger/>

        <!-- Projektdateien -->
        <FakAccordionItem id={"project-attachments"} closeOnHeaderClick={true}>
            <div slot="header" style="height: 3.875rem; width: 100%;">
                <FakAccordionItemHeader label={"Projektdateien"} id={"project-attachments"}/>
            </div>
            <div slot="body" style="width: 100%; height: 100%;">
                <ProjectFileUpload />
            </div>
        </FakAccordionItem>

        <!-- Projektbemerkungen -->
        <FakAccordionItem id={"project-remarks"} closeOnHeaderClick={true}>
            <div slot="header" style="height: 3.875rem; width: 100%;">
                <FakAccordionItemHeader label={$t('UI.map.card.project.remarks')} id={"project-remarks"}/>
            </div>
            <div slot="body" style="width: 100%; height: 100%;">
                <ProjectComments/>
            </div>
        </FakAccordionItem>
    {/if}
</ul>

<style lang="scss">
  .project-accordion {
    width: 450px;
    padding-left: 0;
    list-style: none;
  }
</style>
