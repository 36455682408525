import {fetchUtils} from "../utils/fetchUtils";
import {get} from "svelte/store";
import {t} from "../../i18n/i18n";
import {NotificationType} from "../types/notification";
import {addNotification} from "../stores";
import type {PubPageParams} from "./types/pubPageParams";
import {EmptyOffer, type Offer, OfferState} from "./types/offer";
import {RequestType} from "./types/requestDto";

export async function loadOfferAttachments(offerPublicId: string | number, urlParams: PubPageParams | null) {
    let result: any[] = [];
    if (!offerPublicId || (typeof offerPublicId === 'number' && offerPublicId <= 0)) {
        if (urlParams == null) {
            return result;
        }
        const {requestPublicId, requestedEntityType, requestedEntityId, offerUserPublicId, lotPublicId} = urlParams;
        let url: string;
        if (lotPublicId && !requestPublicId && !requestedEntityType && !requestedEntityId && !offerUserPublicId) {
            url = `/api/optimizer/lot/${lotPublicId}/attachments`;
        } else {
            url = `/api/optimizer/request/${requestPublicId}/requested/${requestedEntityType}/${requestedEntityId}/user/${offerUserPublicId}/attachments`;
        }
        await fetchUtils.get(url)
            .then(data => {
                Object.assign(result, data);
            })
            .catch(error => console.log(error));
    } else {
        // at the moment, we have only one field to upload attachments, and as we duplicate the attachments to both
        // offers, we don't need to load them for both (at the moment!!)
        await fetchUtils.get(`/api/offer/${offerPublicId}/attachments`)
            .then(data => {
                Object.assign(result, data);
            })
            .catch(error => console.log(error));
    }

    return result;
}

export function savePublicOptimizerComment(optimizerPublicComment: string, offerMaterialId: string, offerMaterialWithTransportId: string): void {
    let request = {optimizerPublicComment};
    const ids = [offerMaterialId, offerMaterialWithTransportId];

    function saveOptimizerCommentFetch(id: string) {
        return fetchUtils.post(`/api/optimizer/offer/${id}/updateOptimizerPublicComment`, request);
    }

    Promise.all(
        ids.map(id => id ? saveOptimizerCommentFetch(id) : Promise.resolve())
    ).then(_ => {
        let notification = {
            message: get(t)('UI.notification.saved'),
            type: NotificationType.SUCCESS,
            timeout: 4000,
            dismissible: false
        };
        addNotification(notification)
    }).catch(error => {
        let notification = {
            message: get(t)("UI.error.save"),
            type: NotificationType.ERROR,
            timeout: 0,
            dismissible: true
        };
        addNotification(notification);
    });
}

export function isEmptyOffer(offer: Offer, requestType: RequestType) {
    return JSON.stringify(offer) === JSON.stringify(EmptyOffer(requestType));
}

export function isOfferProvided(offer: Offer, requestType: RequestType = RequestType.MATERIAL, otherOffer: Offer, selectable: boolean) {
    return (offer && !isEmptyOffer(offer, requestType)
        && ((selectable && ((offer.offerState === OfferState.READY && (!otherOffer.offerState || otherOffer.offerState === OfferState.READY)) || offer.offerState === OfferState.SENT)) || !selectable))|| false;
}

export function areBothRejectedOrEmpty(offerMaterial: Offer, offerMaterialWithTransport: Offer) {
    let isEmptyMaterial = isEmptyOffer(offerMaterial, RequestType.MATERIAL);
    let isEmptyMaterialWithTransport = isEmptyOffer(offerMaterialWithTransport, RequestType.MATERIAL_WITH_TRANSPORT);
    return (isEmptyMaterial || offerMaterial.offerState === OfferState.REJECTED || offerMaterial.offerState == OfferState.REJECTED_MM)
        && (isEmptyMaterialWithTransport || offerMaterialWithTransport.offerState === OfferState.REJECTED || offerMaterialWithTransport.offerState == OfferState.REJECTED_MM)
            && !(isEmptyMaterial && isEmptyMaterialWithTransport);
}

export function isOfferRejected(offer: Offer) {
    return !isEmptyOffer(offer, offer.requestType) && (offer.offerState === OfferState.REJECTED || offer.offerState === OfferState.REJECTED_MM);
}

export function isOfferRejectedByOptimizer(offer: Offer) {
    return !isEmptyOffer(offer, offer.requestType) && (offer.offerState == OfferState.REJECTED);
}
