export type NextButtonDto = {
    currentId: string,
    isSaas: boolean,
    originPage: NextButtonOriginPage, // Pop = Offer list per pop, bid = offer list for this user
    wantedObject: NextButtonWantedObject, // request = open requests, deal = requests with deal
    parentObjectId: string,
}

export enum NextButtonOriginPage {
    BID = 'BID',
    POP = 'POP',
    SAAS = 'SAAS'
}

export enum NextButtonWantedObject {
    REQUEST = 'REQUEST',
    DEAL = 'DEAL',
}
