<script lang="ts">
    import Notification from "../molecules/Notification.svelte";
    import {dismissNotification, notifications} from "../stores";
    import {fly, fade} from 'svelte/transition';

    export let id = '';
    export let position = 'sticky'; // for some cases, e.g. map, it could be necessary to use position 'absolute'
    export let notificationWidth = "100%";
    export let marginTop = '3.125rem';
    export let top = '2rem';
    export let target: string | null = null; // set default null to show general in main component
    export let padding: string = '0 2rem 0 2rem';
</script>

{#if $notifications && $notifications.filter(notification => notification.target === target).length > 0}
    <section in:fly="{{ x: -1000, duration: 400 }}" out:fade={{duration:1000}} {id}
             style="--ntfy-position: {position}; --ntfy-margin-top: {marginTop}; --ntfy-padding: {padding}; --ntfy-top: {top};">
        {#each $notifications.filter(notification => notification.target === target) as notification (notification.id)}
            <Notification
                    message={notification.message}
                    messageHint={notification.messageHint}
                    type={notification.type}
                    dismiss={notification.dismissible}
                    width={notificationWidth}
                    on:dismiss={() => dismissNotification(notification.id)}>
            </Notification>
        {/each}
    </section>
{/if}

<style lang="scss">
  @import "../../styles/global";

  section {
    @include flex-col(0.75rem);
    position: var(--ntfy-position);
    top: var(--ntfy-top);
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    margin-top: var(--ntfy-margin-top);
    padding: var(--ntfy-padding);
    transition: padding 400ms ease-out;
    -webkit-transition: padding 400ms ease-out;
    pointer-events: none;

    @media (max-width: 1420px) {
      section {
        --ntfy-padding: 0 2rem 0 2rem;
      }
    }
  }
</style>