<script lang="ts">

    import {Motion} from "svelte-motion";
    import {DefaultRobotoFontProps, type FontProps, getFontPropsVarString} from "../types/fontProps";

    export let active: boolean;
    export let disabled: boolean = false;
    export let label: string; // potentially html allowed
    export let fontProps: FontProps = DefaultRobotoFontProps('normal', '1.25rem', '#9d9d9d', 'black', 400);
    $: fontPropsStyleString = getFontPropsVarString(fontProps);
</script>

<li class:active class:disabled title={label} style={fontPropsStyleString} on:click>
    {@html label}
    {#if active}
        <Motion let:motion layoutId="underline">
            <div class:underline={active} use:motion></div>
        </Motion>
    {/if}
</li>


<style lang="scss">
  @import "../../styles/global";

  li {
    @include roboto-font(var(--line-height), var(--font-weight), var(--font-size), var(--color));
    @include flex-row($justify: space-between);
    margin: 0;
    padding: 10px 15px;
    border-radius: 5px 5px 0 0;
    width: 100%;
    position: relative;
    background: white;
    height: 100%;
    flex: 1;
    user-select: none;
    list-style: none;
    cursor: pointer;

    &.disabled {
      color: $grey-700;
      cursor: not-allowed;
    }

    &.active {
      color: $primaryGreen;
    }
  }

  div.underline {
    position: absolute;
    bottom: -2px;
    left: 9px;
    right: 9px;
    height: 3px;
    background: $primaryGreen;
    border-radius: 3px;
  }

</style>
