<script lang="ts">
    import {AnimatePresence, AnimateSharedLayout, Motion} from "svelte-motion";
    import {onDestroy, setContext} from "svelte";
    import {copyProject, DefaultProject} from "../../types/project";
    import {fetchUtils, SpecialHandledErrors} from "../../utils/fetchUtils";
    import MmLoadingAnimation from "../../animations/MmLoadingAnimation.svelte";
    import {copyLot, DefaultLot} from "../types/lot";
    import YupLocale from "../../atoms/YupLocale.svelte";
    import Modals from "../../organisms/Modals.svelte";
    import Notifications from "../../organisms/Notifications.svelte";
    import AutosaveInfo from "../../organisms/AutosaveInfo.svelte";
    import LotsAccordion from "./lotsLayer/LotsAccordion.svelte";
    import LotDetailsAccordion from "./lotDetailsLayer/LotDetailsAccordion.svelte";
    import ProjectAccordion from "./projectLayer/ProjectAccordion.svelte";
    import LegacyMaterialProperties from "./lotDetailsLayer/LegacyMaterialProperties.svelte";
    import {adjustMargin, closeFAK, loadDefaultCatalogAndMaterialOptions} from "./utils/fakUtils";
    import {
        currentLot,
        currentProject,
        defaultMaterialOptions,
        formErrors,
        localLotsStore,
        mapFormControls
    } from "./utils/fakStores";
    import {addPageError} from "../../stores";
    import {closeModal, openModal} from "svelte-modals";
    import Modal from "../../organisms/Modal.svelte";
    import {t} from "../../../i18n/i18n";
    import type {Material} from "../types/material";
    import iconClose from "@/icons/icon_close_white.svg";

    setContext("formErrors", formErrors);

    $: {
        const url = new URL(window.top?.location);
        url.searchParams.delete("ref");
        if ($currentProject) {
            url.searchParams.set("formProjectId", $currentProject.id);
            if ($currentLot) {
                url.searchParams.set("formLotId", $currentLot.id);
            } else {
                url.searchParams.delete("formLotId");
            }
        } else {
            url.searchParams.delete("formLotId");
        }
        window.top?.history.pushState({}, "", url);
    }

    $: {
        $mapFormControls.level = $currentLot ? 3 : $currentProject && $currentProject.id != "new" ? 2 : 1;
    }

    setContext('useMaterialDesign', true);
    loadDefaultCatalogAndMaterialOptions();


    async function fetchProjectAndLots() {
        $localLotsStore.clear();
        if ($mapFormControls.projectId && $mapFormControls.projectId !== "new") {
            await fetchUtils.get(`/api/projectAndLots/${$mapFormControls.projectId}`)
                .then(data => {
                    $currentProject = DefaultProject();
                    $currentProject = copyProject(data.project, true);
                    $localLotsStore.clear();
                    for (let loadedLot of data.lots) {
                        let lot = DefaultLot();
                        lot = copyLot(loadedLot, true);
                        $localLotsStore.set(lot.id, lot);
                    }
                    if ($mapFormControls.lotId) {
                        if ($mapFormControls.lotId === "new") {
                            $currentLot = DefaultLot();
                        } else if (Number($mapFormControls.lotId) > 0) {
                            if ($localLotsStore.has($mapFormControls.lotId.toString())) {
                                $currentLot = $localLotsStore.get($mapFormControls.lotId.toString()) || null;
                            }
                        }
                    }
                }).catch((e) => {
                    if (e.code in SpecialHandledErrors) {
                        closeFAK();
                        const error = e.message;
                        let currentError = {
                            code: e.code,
                            message: error
                        }
                        addPageError(currentError)
                    } else {
                        $currentProject = DefaultProject();
                    }
                });
        } else {
            $currentProject = DefaultProject();
        }
    }


    let legacyMaterialProperties: LegacyMaterialProperties;

    let mainLayoutTranslate = 0;
    let propertiesLayoutTranslate = 100;

    function openMaterialProperties() {
        if ($currentLot) {
            legacyMaterialProperties.init();
            mainLayoutTranslate = -100;
            propertiesLayoutTranslate = 0;
        }
    }

    function closeMaterialProperties() {
        mainLayoutTranslate = 0;
        propertiesLayoutTranslate = 100;
    }

    function onCloseClick() {
        if ($currentProject.id === "new" || ($currentLot && $currentLot.id === "new") || $mapFormControls.saving) {
            openModal(Modal, {
                title: $t("UI.mapForm.close.modal.title"),
                denyText: $t("UI.mapForm.close.modal.confirm"),
                message: $t("UI.mapForm.close.modal.desc"),
                onDeny: () => {
                    closeModal();
                    closeFAK();
                }
            });
        } else {
            closeFAK();
        }
    }

    addEventListener("openMaterialProperties", openMaterialProperties);
    addEventListener("closeMaterialProperties", closeMaterialProperties);

    let resizeObserver = new ResizeObserver(() => adjustMargin($currentLot));

    onDestroy(() => {
        resizeObserver.unobserve(document.getElementById('project-form-container'));
        $defaultMaterialOptions = new Map<string, Material[]>();
        $mapFormControls.projectId = null;
        $mapFormControls.lotId = null;
        $mapFormControls.saving = false;
        $currentLot = null;
        $currentProject = null;
        const url = new URL(window.parent.location);
        url.searchParams.delete("formProjectId");
        url.searchParams.delete("formLotId");
        window.parent.history.pushState({}, "", url);
        $localLotsStore.clear();
        if ($currentProject && $currentProject.id && $currentProject.id !== "") {
            window.dispatchEvent(new CustomEvent("mapForm-closed", {detail: {projectId: $currentProject.id}}));
        }
    });
</script>

<div class="fak-main-layout" style="transform: translateX({mainLayoutTranslate}%)">

    <!-- TODO: Add id for tippy appendTo -->
    <div class="fak-base-layout">
        {#await fetchProjectAndLots()}
            <MmLoadingAnimation/>
        {:then {}}
            <AnimateSharedLayout>
                <Motion layout let:motion={m1} transition={{ duration: .3 }}
                        onLayoutAnimationComplete={() => {setTimeout(() => adjustMargin($currentLot), 300)}}>
                    <div id="fak-project-layer" use:m1 class="fak-layout-item fak-accordion">
                        <ProjectAccordion {resizeObserver}/>
                    </div>
                </Motion>
                <AnimatePresence list={$mapFormControls.level > 1 ? [{key: 1}] : []}>
                    <Motion layout initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: .3 }} exit={{ opacity: 0}} let:motion={m2}
                            onLayoutAnimationComplete={() => {setTimeout(() => adjustMargin($currentLot), 300)}}>
                        <div use:m2 id="fak-lots-accordion"
                             class="fak-layout-item fak-accordion"
                             style="padding-right: 0.62rem; padding-left: 0.62rem">
                            <LotsAccordion/>
                        </div>
                    </Motion>
                </AnimatePresence>
                <AnimatePresence list={$mapFormControls.level > 2 ? [{key: 1}] : []}>
                    <Motion layout initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: .3 }} exit={{ opacity: 0 }} let:motion={m3}
                            onLayoutAnimationComplete={() => {setTimeout(() => adjustMargin($currentLot), 300)}}>
                        <div id="fak-lot-details-layer" use:m3 class="fak-layout-item fak-accordion">
                            <LotDetailsAccordion/>
                        </div>
                    </Motion>
                </AnimatePresence>
            </AnimateSharedLayout>
        {/await}
    </div>

    {#if $currentProject && $currentProject.id !== "new"}
        <img on:click={onCloseClick} src={iconClose} alt="Close icon" class="fak-close-icon"/>
    {/if}
</div>
<div class="fak-legacy-material-properties-layout" style="transform: translateX({propertiesLayoutTranslate}%)">
    {#if $currentLot && $currentLot.material}
        <LegacyMaterialProperties bind:this={legacyMaterialProperties}/>
    {/if}
</div>

<YupLocale/> <!-- include the yup locale settings and keys for the forms, could also be placed at app to work for all nested children -->
<Modals/> <!-- include modals on the map form -->
<Notifications position="absolute" notificationWidth="80%"
               marginTop="1rem"/> <!--include the notifications on the form -->
<AutosaveInfo/>

<style lang="scss">
  @import "../../../styles/fak";

  .fak-main-layout {
    transition: 800ms ease-in-out;
  }

  .fak-legacy-material-properties-layout {
    transition: 800ms ease-in-out;
    display: flex;
    justify-content: center;
    height: 100vh;
  }

  .fak-base-layout {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
  }

  .fak-layout-item {
    display: flex;
    align-items: center;
  }

  .fak-accordion {
    background: transparent;
    overflow: auto;
    width: fit-content;
    position: relative;
    display: inline-block;
    /* Hide Scrollbar */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    //noinspection CssInvalidPropertyValue
    -webkit-mask-image: linear-gradient(transparent, black 5%, black 95%, transparent);
    mask-image: linear-gradient(transparent, black 5%, black 95%, transparent);
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .fak-accordion::-webkit-scrollbar {
    display: none;
  }

  .fak-close-icon {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    cursor: pointer;
  }

</style>
