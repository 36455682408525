<script lang="ts" context="module">
    import {fetchUtils} from "../utils/fetchUtils";
    import {t} from "../../i18n/i18n";
    import {type Notification, NotificationType} from "../types/notification";
    import {addNotification} from "../stores";
    import {get} from "svelte/store";
    import {LotState} from "../types/enums";


    export function savePublicComment(remarks: string, lotPublicId: string): void {
        let request = {publicComment: remarks};
        updateLotComments(request, 'PublicComment', lotPublicId);
    }

    function updateLotComments(request, urlSuffix: string, lotPublicId: string) {
        fetchUtils.post('/api/offerer/lot/' + lotPublicId + '/update' + urlSuffix, request)
            .then(_ => {
                let notification: Notification = {
                    message: get(t)('UI.notification.saved'),
                    type: NotificationType.SUCCESS,
                    timeout: 4000,
                    dismissible: false
                };
                addNotification(notification)
            })
            .catch(error => {
                let notification: Notification = {
                    message: get(t)("UI.error.save") + ": " + error.statusText,
                    type: NotificationType.ERROR,
                    timeout: 5000,
                    dismissible: true
                };
                addNotification(notification);
            });
    }


    export async function updateAttachmentsLot(id: string | undefined) {
        let res;
        await fetchUtils.get('/api/lot/pub/' + id + '/attachments')
            .then(data => {
                res = data;
            })
            .catch(error => console.log(error));
        return res;
    }
</script>

<script lang="ts">
    import {onMount, setContext} from "svelte";
    import type {Lot} from "../types/lot";
    import OffererPubPageApproval from "../offerer/OffererPubPageApproval.svelte";
    import OffererPubPageBidding from "../offerer/OffererPubPageBidding.svelte";
    import OffererPubPageOptimized from "../offerer/OffererPubPageOptimized.svelte";
    import OffererPubPageHandling from "../offerer/OffererPubPageHandling.svelte";
    import OffererPubPageAccounting from "../offerer/OffererPubPageAccounting.svelte";
    import PubPageFailed from "./PubPageFailed.svelte";
    import PubPageCompleted from "./PubPageCompleted.svelte";
    import {caseOwnerContact} from "../stores";
    import {DefaultNotification} from "../types/notification";
    import {type Writable, writable} from "svelte/store";
    import {OFFERER_PAYS_KICKBACK} from "../types/enums";
    import {Facts} from "../bid/types/facts";
    import {type RequestDto} from "../bid/types/requestDto";


    export let requestPublicId: string | undefined = undefined;
    export let offerUserPublicId: string | undefined = undefined;
    export let lotPublicId: string | undefined = undefined;


    setContext("urlParams", {lotPublicId, offerUserPublicId, requestPublicId}); // no writable store, we only want to read the values
    setContext("isSaasInternalLot", writable(false));
    let showKickback: Writable<boolean> = setContext("showKickback", writable(false));
    const request: Writable<RequestDto | null> = setContext("request", writable(null));
    const lot: Writable<Lot | null> = setContext("lot", writable(null));

    const components = {
        [LotState.APPROVAL]: OffererPubPageApproval,
        [LotState.BIDDING]: OffererPubPageBidding,
        [LotState.OPTIMIZED]: OffererPubPageOptimized,
        [LotState.HANDLING]: OffererPubPageHandling,
        [LotState.ACCOUNTING]: OffererPubPageAccounting,
        [LotState.TARGET_ACTUAL_VERIFICATION]: OffererPubPageAccounting,
        [LotState.DISCARDED]: PubPageFailed,
        [LotState.COMPLETED]: PubPageCompleted
    }
    let component: any = OffererPubPageApproval;


    onMount(async () => {
        await fetchLotData(lotPublicId);
    })

    async function fetchLotData(id: string) {
        await fetchUtils.get('/api/offerer/lot/' + id ?? lotPublicId)
            .then(data => {
                    $lot = data ? {...data} : null;
                    //after accepting an offer or counter offer we get the lotPublicId of the resulting lot (can differ due to shortage)
                    // but as we have the id of the matching quote, we can still show the data
                    Object.assign($caseOwnerContact, data.contactData);
                    $caseOwnerContact = $caseOwnerContact;
                    component = components[$lot.state];
                }
            ).catch((error) => {
                console.log(error)
                let notification: Notification = DefaultNotification(error, NotificationType.ERROR, true, 0);
                addNotification(notification);
            })
    }

    $: $lot, $lot?.type, $showKickback = $lot?.type ? OFFERER_PAYS_KICKBACK.includes($lot.type) : false;
</script>

{#if $lot} <!-- TODO qr possibly remove this or introduce else case and show PubPageFailed? -->
    <svelte:component this={component} on:updateLot={(e) => fetchLotData(e.detail.lotPublicId)}/>
{/if}