<script lang="ts">

    import MaterialDetailsEditView from "./MaterialDetailsEditView.svelte";
    import type {Catalog} from "./types/catalog";
    import {DefaultCatalog} from "./types/catalog";
    import MaterialCatalogs from "./MaterialCatalogs.svelte";
    import {onMount, setContext} from "svelte";
    import type {Writable} from "svelte/store";
    import {writable} from "svelte/store";
    import {AnimatePresence, AnimateSharedLayout, Motion} from "svelte-motion";
    import {t} from "../../i18n/i18n";
    import {fetchUtils} from "../utils/fetchUtils";
    import {hideAnimation, showAnimation, treeExpansionStates} from "../stores";
    import PrimaryButton from "../atoms/PrimaryButton.svelte";
    import {ButtonIcons, InputIcons, LoadingAnimation} from "../types/enums";
    import {openModal} from "svelte-modals";
    import MaterialCatalogModal from "./MaterialCatalogModal.svelte";
    import type {ExtendedMaterial, Material} from "./types/material";
    import {EmptyMaterial} from "./types/material";
    import {MaterialView} from "./types/materialView";
    import type {MaterialProperty, MaterialPropertyListElement} from "./types/materialProperty";
    import {DefaultMaterialProperty} from "./types/materialProperty";
    import MaterialProperties from "./MaterialProperties.svelte";
    import MaterialPropertyModal from "./MaterialPropertyModal.svelte";
    import {changeUrl} from "../utils/misc"
    import {inputFieldFormats} from "../utils/formatters";
    import type {Filter} from "../utils/filter";
    import {ListFilter, OrFilter, StringContainsFilter, TranslatedEnumContainsFilter} from "../utils/filter";
    import SwitchableTab from "./SwitchableTab.svelte";
    import MaterialSubstitutes from "./MaterialSubstitutes.svelte";
    import Basic from "../templates/Basic.svelte";
    import InputField from "../molecules/InputField.svelte";
    import MaterialSubstituteModal from "./MaterialSubstituteModal.svelte";
    import type {SubstituteType} from "./types/substitutes";
    import {buildExtendedMaterials} from "./util/materialSubstituteUtil";

    // We must set the formErrors context here, even though it does not necessarily need to be assigned to a variable!
    setContext("formErrors", writable([{path: "", message: ""}]));

    // TODO use TABS and integrate filter

    export let catalogName: string = "";
    export let materialId: string = "";
    export let materialPropertyId: string = "";

    let selectedCatalog: Writable<Catalog> = setContext('selectedCatalog', writable(null));
    let selectedMaterial: Writable<Material> = setContext('selectedMaterial', writable(EmptyMaterial()));
    // used for substitutes, each material includes full code and catalog name
    let allExtendedMaterials: Writable<ExtendedMaterial[]> = setContext('extended-materials', writable([]));

    let buttonLabel: string = "";
    let materialProperties: Writable<Map<string, MaterialProperty>> = setContext('materialProperties', writable(new Map()));
    let materialPropertyParents: Writable<Map<string, string[]>> = setContext('materialPropertyParents', writable(new Map()));
    let materialEdit: MaterialDetailsEditView; // MaterialDetailsEditView component

    export let view: MaterialView = MaterialView.CATALOGS;
    let searchTerm: string = "";
    let selectedFilters: Filter[] = [];
    let showFilters: boolean = false;


    let catalogs: Catalog[] = [];
    let selectedSubstituteType: SubstituteType;

    onMount(async () => {
        await fetchData();
        if (materialId === "new") {
            navigateCatalogs(); // used to handle reload page with new unsaved material
        }
        if (catalogName && catalogName !== "") { // in case we visit via the url /catalog/
            let cat = catalogs.find(c => c.name === catalogName);
            $selectedCatalog = cat ? cat : catalogs[0];
            if (materialId && materialId !== "" && cat && materialId !== "new") {
                let nodes = cat.nodes;
                if (nodes !== null && nodes.length !== 0) {
                    $selectedMaterial = nodes.find(m => m.id === materialId);
                    let mat = $selectedMaterial;
                    $treeExpansionStates.get($selectedCatalog.name).push(mat.id);
                    let parId = mat.parentId;
                    while (parId && parId !== null && parId !== "root" && parId !== "") {
                        mat = nodes.find(m => m.id === parId);
                        parId = mat.parentId;
                        $treeExpansionStates.get($selectedCatalog.name).push(mat.id);
                    }
                    view = MaterialView.CATALOG_MATERIALS;
                }
            }
        } else if (materialPropertyId && materialPropertyId !== "") { // we need to open the modal with the given property
            openModal(MaterialPropertyModal, {
                materialProperty: {...DefaultMaterialProperty(), ...$materialProperties.get(materialPropertyId)},
                isNew: false,
                parent: null,
            })
        }
    });

    async function fetchData() {
        showAnimation(LoadingAnimation.MmLoader);
        await Promise.all([fetchCatalogs(), fetchProperties()])
            .catch(fetchUtils.catchErrorAndShowNotification)
            .finally(() => hideAnimation(800));
    }

    async function fetchCatalogs() {
        await fetchUtils.get('/api/catalogs/list').then(data => {
            catalogs = [];
            Object.assign(catalogs, data);
            catalogs = catalogs;
            catalogs.forEach(c => $treeExpansionStates.set(c.name, []));
            $selectedCatalog = catalogs[0];
            $allExtendedMaterials = buildExtendedMaterials(structuredClone(catalogs));
        }).catch(fetchUtils.catchErrorAndShowNotification())
    }

    async function updateCatalog(data: any) {
        let newCatalog = Object.assign(DefaultCatalog(), data)
        catalogs.push(newCatalog);
        catalogs = catalogs;
        $treeExpansionStates.set(newCatalog.name, []);
        $treeExpansionStates = $treeExpansionStates;
    }

    async function fetchProperties() {
        await fetchUtils.get('/api/material-properties').then(data => {
            let properties = [];
            Object.assign(properties, data);
            properties.forEach(prop => {
                $materialProperties.set(prop.id, prop);
                writePropertyParents(prop);
            });
            $materialProperties = $materialProperties;
            $materialPropertyParents = $materialPropertyParents;
        }).catch(fetchUtils.catchErrorAndShowNotification());
    }

    /**
     * Writes the property as parent into the store for all the subproperties in the contained listElements.
     * Goes through each property list element and each property child ID on those list elements,
     * checks if it exists in the material property parents then either adds it to the existing list or creates a new one.
     * @param {MaterialProperty} property - The material property for which parents need to be searched.
     */function writePropertyParents(property: MaterialProperty) {
        property.propertyListElements.forEach((listElement: MaterialPropertyListElement) => {
            listElement.propertyChildIds.forEach((propertyId: string) => {
                if ($materialPropertyParents.has(propertyId)) { // add the rest of the existing list
                    $materialPropertyParents.set(propertyId, ($materialPropertyParents.get(propertyId) || []).concat([property.id]))
                } else { // create a new list and set it
                    $materialPropertyParents.set(propertyId, [property.id]);
                }
            })
        })
    }

    function handleCreateCatalog() {
        openModal(MaterialCatalogModal, {
            update: (data: any) => updateCatalog(data)
        });
    }

    function handleCreateProperty() {
        openModal(MaterialPropertyModal, {
            materialProperty: DefaultMaterialProperty(),
            isNew: true,
            parent: null,
        })
    }

    function handleCreateSubstitute() {
        openModal(MaterialSubstituteModal, {
            substituteType: selectedSubstituteType,
            catalogs: structuredClone(catalogs)
        })
    }

    function createNewItem() {
        // resetSearchAndFilters();
        switch (view) {
            case MaterialView.CATALOG_MATERIALS:
                materialEdit.addRoot(); // function // in MaterialEditView
                break;
            case MaterialView.CATALOGS:
                handleCreateCatalog();
                break;
            case MaterialView.MATERIAL_PROPERTIES:
                handleCreateProperty();
                break;
            case MaterialView.MATERIAL_SUBSTITUTES:
                handleCreateSubstitute();
                break;
        }
    }

    function updateNodePropertiesByDeletingProperty(e) {
        // remove property or propertyId of deleted property in all nodes
        if (e.detail && e.detail.propertyId) {
            const removedPropertyId = e.detail.propertyId;
            catalogs.forEach(catalog => {
                catalog.nodes.forEach((node: Material) => {
                    if (node.properties !== undefined) {
                        const indexOfProperty = node.properties.map((node: Material) => node.id).indexOf(removedPropertyId)
                        if (indexOfProperty > -1) {
                            node.properties.splice(indexOfProperty, 1)
                        }
                        const indexOfPropertyId = node.propertyIds.indexOf(removedPropertyId)
                        if (indexOfPropertyId > -1) {
                            node.propertyIds.splice(indexOfPropertyId, 1)
                        }
                    }
                })
            })
        }
    }

    function getCreateNewButtonLabel() {
        switch (view) {
            case MaterialView.CATALOG_MATERIALS:
                buttonLabel = $t('UI.mmpv.createMaterial');
                break;
            case MaterialView.CATALOGS:
                buttonLabel = $t('UI.mmpv.createCatalog');
                break;
            case MaterialView.MATERIAL_PROPERTIES:
                buttonLabel = $t('UI.mmpv.createProperty');
                break;
            case MaterialView.MATERIAL_SUBSTITUTES:
                buttonLabel = $t('UI.mmpv.createSubstitute');
        }
    }

    function resetSearchAndFilters() {
        searchTerm = "";
        selectedFilters = [];
    }

    // TODO maybe also for tab navigation we should check whether we have unsaved data and show modal then?
    function navigateCatalogs() {
        view = MaterialView.CATALOGS;
        changeUrl('/catalogs', window);
        resetSearchAndFilters();
    }

    function navigateCatalogMaterials() {
        if ($selectedCatalog.nodes !== null) {
            handleNavigateCatalog();
        }
        view = MaterialView.CATALOG_MATERIALS;
        resetSearchAndFilters();
    }

    // in case we visit via /catalogs select
    function handleNavigateCatalog() {
        catalogName = $selectedCatalog.name;
        if ($selectedCatalog.nodes.length == 0) {
            $selectedMaterial = EmptyMaterial();
        } else {
            // select first material if last selected material doesn't match actual catalog
            if ($selectedMaterial.id == 'new' || $selectedCatalog.nodes.map(node => node.id).indexOf($selectedMaterial.id) === -1) {
                $selectedMaterial = $selectedCatalog.nodes.sort((a, b) => (a.parentCode + a.code > b.parentCode + b.code) ? 1 : ((b.parentCode + b.code > a.parentCode + a.code) ? -1 : 0))[0]
            }
        }
        $selectedMaterial = $selectedMaterial;
        changeUrl(`catalog/${catalogName}/material/${$selectedMaterial.id}`, window);
    }

    function navigateProperties() {
        view = MaterialView.MATERIAL_PROPERTIES;
        changeUrl('/materialProperties', window);
        resetSearchAndFilters();
    }

    function navigateSubstitutes() {
        view = MaterialView.MATERIAL_SUBSTITUTES;
        changeUrl('/materialSubstitutes', window);
        resetSearchAndFilters();
    }

    function searchCatalogs() {
        selectedFilters.push(new StringContainsFilter('name', searchTerm));
    }

    function searchMaterials() {
        selectedFilters.push(new OrFilter([
            new StringContainsFilter('name', searchTerm),
            new StringContainsFilter('code', searchTerm)
        ]));
    }

    function searchProperties() {
        selectedFilters.push(new OrFilter([
            new StringContainsFilter('name', searchTerm),
            new ListFilter(new TranslatedEnumContainsFilter('flags', searchTerm, 'PropertySystemFlag.'))
        ]));
    }

    function search() {
        // if (e.key === 'Enter') {
        selectedFilters = [];
        if (searchTerm !== '') {
            switch (view) {
                case MaterialView.CATALOGS:
                    searchCatalogs();
                    break;
                case MaterialView.MATERIAL_PROPERTIES:
                    searchProperties()
                    break;
                case MaterialView.CATALOG_MATERIALS:
                    searchMaterials()
            }
        }
        selectedFilters = selectedFilters; // reassign to trigger ui update
        // }
    }

    $: searchTerm, search();
    $: view, getCreateNewButtonLabel(); // rerun getCreateNewButtonLabel when view changes
</script>

<Basic>
    <div class="main-container">
        <section class="filters" style="height: 100%">
            <!-- filter list todo possibly own component with context store -->
            <span>{$t('UI.mmpv.materialCatalog')}</span>
            <div class="filter-row">
                <div>
                    <!-- TODO: Implement-->
                    <!--<SecondaryButton label={$t('UI.filters')}
                                     color={showFilters ? SecondaryBtnColors.GREEN : SecondaryBtnColors.GREY}
                                     rightIcon={showFilters ? ButtonIcons.UP_ARROW : ButtonIcons.DOWN_ARROW}
                                     on:click={() => showFilters = !showFilters} flushTextAndIcon/>
                    <SecondaryButton label={$t('UI.deleteAll')} color={SecondaryBtnColors.RED}
                                     on:click={() => selectedFilters = []} sizeAdaptingToText/>-->
                </div>
                <div>
                    <PrimaryButton label={buttonLabel} leftIcon={ButtonIcons.PLUS_BOLD} titleAttr={buttonLabel}
                                   on:click={createNewItem} whiteIcon sizeAdaptingToText/>
                    <InputField bind:value={searchTerm} placeholder={$t('UI.placeholder.search')} format={inputFieldFormats.SEARCH}
                                icon={InputIcons.SEARCH_LENS}/>
                </div>
            </div>
            {#if showFilters}
                <div class="filter-row">FILTER AUSWAHL TBD</div>
            {/if}
        </section>
        <nav> <!-- TABS for navigation -->
            <ul>
                <AnimateSharedLayout>
                    <SwitchableTab active={view===MaterialView.CATALOGS} label={$t('UI.mmpv.catalogsList.tab')}
                                   on:click={navigateCatalogs}/>
                    {#if catalogs.length !== 0 }
                        <SwitchableTab active={view===MaterialView.CATALOG_MATERIALS}
                                       label={$t('UI.mmpv.catalog.tab')} on:click={navigateCatalogMaterials}/>
                    {/if}
                    <SwitchableTab active={view===MaterialView.MATERIAL_PROPERTIES}
                                        label={$t('UI.mmpv.properties.tab')} on:click={navigateProperties}/>
                    <SwitchableTab active={view===MaterialView.MATERIAL_SUBSTITUTES}
                                        label={'Substitutsverwaltung'} on:click={navigateSubstitutes}/>
                </AnimateSharedLayout>
            </ul>
        </nav>
        <section class="main-content"> <!-- content with the views of catalogs list or material list for one catalog -->
            <AnimatePresence exitBeforeEnter list={[{key: view, view: view}]} let:item>
                <Motion let:motion initial={{ x: -10, opacity: 0 }} animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 10, opacity: 0 }}
                        transition={{ duration: 0.2 }}>
                    <div style="width: 100%; margin-top:3rem" use:motion>
                        {#if item.view === MaterialView.CATALOG_MATERIALS}
                            <MaterialDetailsEditView bind:this={materialEdit} on:backToCatalogs={navigateCatalogs}
                                                     filters={selectedFilters}/>
                        {:else if item.view === MaterialView.CATALOGS}
                            <!--no need to update material or catalog, is done via context store -->
                            <MaterialCatalogs {catalogs} on:catalogSelected={navigateCatalogMaterials}
                                              updateCatalogs={fetchCatalogs} filters={selectedFilters}/>
                        {:else if item.view === MaterialView.MATERIAL_PROPERTIES}
                            <MaterialProperties on:updateProperties={updateNodePropertiesByDeletingProperty}
                                                filters={selectedFilters}/>
                        {:else if item.view === MaterialView.MATERIAL_SUBSTITUTES}
                            <MaterialSubstitutes bind:substituteType={selectedSubstituteType}
                                                 catalogs={structuredClone(catalogs)}/>
                        {/if}
                    </div>
                </Motion>
            </AnimatePresence>
        </section>
    </div>
</Basic>

<style lang="scss">
  @import "../../styles/global";

  nav {
    background: white;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid $grey-700;
  }

  .main-content {
    @include flex-row();
    flex-grow: 1;
    user-select: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    @include roboto-font(normal, 400, 1.25rem, black);
  }

  ul {
    display: flex;
    width: max-content;
  }

  li {
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 100%;
    padding: 10px 15px;
    position: relative;
    background: white;
    cursor: pointer;
    @include flex-row($justify: space-between);
    flex: 1;
    user-select: none;
  }

  section.filters {
    @include flex-col(1.875rem, $alignment: flex-start, $justify: flex-start);
    margin-bottom: 2.5rem;

    & > span {
      @include roboto-font(normal, 500, 1.25rem, black);
    }

    & .filter-row {
      @include flex-row(0.875rem, $justify: space-between);
      width: 100%;

      & > div {
        @include flex-row(0.375rem);

        &:first-child {
          justify-content: flex-start;
        }
      }
    }

  }

</style>
