<script lang="ts">
    import {t} from "../../i18n/i18n.ts"
</script>

<div class="footer-contact-box-container">
    <div class="footer-contact-box-link">
        <span>{$t('UI.Model.phone')}:</span>
        <a href="tel:07151/250100">{$t('UI.MineralMinds.phoneNr')}</a>
    </div>
    <div class="footer-contact-box-link">
        <span>{$t('UI.Model.email')}:</span>
        <a href="mailto:info@mineral-minds.de">{$t('UI.MineralMinds.email.info')}</a>
    </div>
    <div class="footer-contact-box-data">
        <span>{$t('UI.lot.address.label')}</span>
        <a href="https://goo.gl/maps/Hdj4w1gHq3Xn4sYe9">Pragstraße&nbsp;120, 70376&nbsp;Stuttgart</a>
    </div>
</div>


<style lang="scss">
  @import "../../styles/global";

  .footer-contact-box-container {
    @include flex-col(0.875rem, flex-start);
  }

  .footer-contact-box-link {
    @include flex-col($alignment: flex-start);

    & > span {
      @include roboto-font(14px, 400, 0.625rem, black);
      text-decoration: none;
    }

    & > a {
      @include roboto-font(14px, 400, 0.625rem, $primaryGreen);
      text-decoration: none;
    }

  }

  .footer-contact-box-data {
    @include flex-col($alignment: flex-start);

    & > span, & > a {
      @include roboto-font(14px, 400, 0.625em, black);
      text-decoration: none;
    }
  }


</style>