export type LotStatusButtonsView = {
    showDuplicate: boolean, // duplicate lot
    showMarkUnverified: boolean, // -> UNVERIFIED (from SUBMISSION), Double-Opt
    showMarkSubmission: boolean, // -> SUBMISSION (from UNVERIFIED), only saas ?
    showMarkVerified: boolean, // -> VERIFICATION
    showMarkParked: boolean, // -> PARKED (from UNVERIFIED)
    showMarkChecked: boolean, // -> APPROVAL
    showMarkBidding: boolean, // -> BIDDING
    showMarkHandling: boolean, // -> HANDLING
    showMarkAccounting: boolean, // -> ACCOUNTING
    showMarkCompleted: boolean, // -> COMPLETED
    showMarkAborted: boolean, // -> DISCARDED
    showCanDelete: boolean; // -> delete when discarded as test
    showMarkRejected: boolean, // check only VERIFICATION state
}

export function DefaultLotStatusButtonsView(): LotStatusButtonsView {
    return {
        showDuplicate: false,
        showMarkUnverified: false,
        showMarkSubmission: false,
        showMarkVerified: false,
        showMarkParked: false,
        showMarkChecked: false,
        showMarkBidding: false,
        showMarkHandling: false,
        showMarkAccounting: false,
        showCanDelete: false,
        showMarkAborted: false,
        showMarkCompleted: false,
        showMarkRejected:false
    }
}
