<script lang="ts">
    import Basic from "../templates/Basic.svelte";
    import {onMount} from "svelte";
    import {deleteTenantLogo, fetchTenantLogo, uploadLogo} from "./corporateDesignUtils";
    import SecondaryButton from "../atoms/SecondaryButton.svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import Icon from "../atoms/Icon.svelte";
    import trashIcon from "@/icons/icon_trash_red.svg";
    import iconClose from "@/icons/icon_close.svg";
    import {DefaultNotification, NotificationType} from "../types/notification";
    import {addNotification} from "../stores.js";
    import {t} from "../../i18n/i18n";
    import {defaultTenantColors, type TenantColors} from "./tenantColors";
    import {closeModal, openModal} from "svelte-modals";
    import Modal from "../organisms/Modal.svelte";

    let currentLogo: any = null;
    let logoInput: HTMLInputElement;
    let colors: TenantColors = {...defaultTenantColors};

    async function updateLogo() {
        currentLogo = await fetchTenantLogo();
    }

    async function saveLogo() {
        if (logoInput.files?.length && logoInput.files.length > 0) {
            // Limit filesize to 2.5MB
            if (logoInput.files[0].size > 2621440) {
                addNotification(DefaultNotification($t('UI.corporateDesign.logo.filesize.tooBig'), NotificationType.ERROR, true, 5000));
                return;
            }
            await uploadLogo(logoInput.files[0])
                .then(async res => {
                    if (res) {
                        await updateLogo();
                        addNotification(DefaultNotification($t('UI.corporateDesign.logo.upload.success'), NotificationType.SUCCESS));
                    } else {
                        addNotification(DefaultNotification($t('UI.corporateDesign.logo.upload.error'), NotificationType.ERROR));
                    }
                });
        }
    }

    async function deleteLogo() {
        // @ts-ignore
        openModal(Modal, {
            title: $t("UI.corporateDesign.logo.delete"),
            denyText: $t("UI.delete"),
            message: $t("UI.corporateDesign.logo.delete.desc"),
            onDeny: async () => {
                closeModal();
                await deleteTenantLogo().then(res => {
                    if (res) {
                        currentLogo = null;
                        addNotification(DefaultNotification($t('UI.corporateDesign.logo.delete.success'), NotificationType.SUCCESS));
                    } else {
                        addNotification(DefaultNotification($t('UI.corporateDesign.logo.delete.error'), NotificationType.ERROR));
                    }
                });
            }
        });

    }

    async function fetchColors() {
        await fetchUtils.get("/api/cd/colors/config", false)
            .then((data: TenantColors) => {
                Object.assign(colors, data);
            })
            .catch(() => {
                // ignore
            })
            .finally(() => {
                colors = colors;
            })
    }

    async function saveColors() {
        let colorsToBeSaved: TenantColors = {...colors};
        if (colorsToBeSaved.primaryColor === defaultTenantColors.primaryColor) {
            delete colorsToBeSaved.primaryColor;
        }
        if (colorsToBeSaved.secondaryColor === defaultTenantColors.secondaryColor) {
            delete colorsToBeSaved.secondaryColor;
        }
        await fetchUtils.post("/api/cd/colors/update", colorsToBeSaved, false)
            .then(() => {
                addNotification(DefaultNotification($t('UI.corporateDesign.colors.upload.success'), NotificationType.SUCCESS));
            })
            .catch(async e => {
                addNotification(DefaultNotification($t('UI.corporateDesign.colors.upload.error'), NotificationType.ERROR));
                await fetchColors();
            });
    }

    onMount(() => {
        updateLogo();
        fetchColors();
    });

</script>


<Basic>
    <h1 style="padding-bottom: 1.88rem;">{$t('UI.corporateDesign.header')}</h1>
    <div class="theming-container">
        <div class="theming-col">
            <h2>{$t('UI.corporateDesign.logo.header')}</h2>
            {#if currentLogo}
                <div class="theming-logo-wrap">
                    <img class="theming-logo" src={currentLogo} alt="Logo"/>
                    <div class="theming-logo-delete">
                        <Icon src={trashIcon} clickable size={24} titleAttr={$t('UI.corporateDesign.logo.delete')}
                              on:click={deleteLogo}/>
                    </div>
                </div>
            {:else}
                <p>{$t('UI.corporateDesign.logo.noLogo')}</p>
            {/if}
            <input bind:this={logoInput} type="file" accept="image/*" style="display: none;" on:change={saveLogo}>
            <SecondaryButton label={$t('UI.corporateDesign.logo.upload.select')} on:click={() => logoInput.click()}/>
            <span class="theming-file-upload-hint">{$t('UI.corporateDesign.logo.filesize.hint')}</span>
        </div>

        <div class="theming-col">
            <h2>{$t('UI.corporateDesign.colors')}</h2>
            <div class="color-row">
                <span>{$t('UI.corporateDesign.colors.primaryColor')}:</span>
                <input id="primaryColor"
                       type="color"
                       bind:value={colors.primaryColor}
                       on:change={saveColors}/>
                <Icon src={iconClose} clickable
                      on:click={() => {
                          colors.primaryColor = defaultTenantColors.primaryColor
                          saveColors();
                      }}/>
            </div>
            <div class="color-row">
                <span>{$t('UI.corporateDesign.colors.secondaryColor')}:</span>
                <input id="secondaryColor"
                       type="color"
                       bind:value={colors.secondaryColor}
                       on:change={saveColors}/>
                <Icon src={iconClose} clickable
                      on:click={() => {
                          colors.secondaryColor = defaultTenantColors.secondaryColor
                          saveColors();
                      }}/>
            </div>
        </div>
    </div>
</Basic>

<style lang="scss">
  @import "../../styles/global";

  .theming-container {
    @include flex-col($row-gap: 1.88rem, $alignment: normal, $justify: normal);
  }

  .theming-col {
    @include flex-col($row-gap: 0.88rem, $alignment: normal);
  }

  .theming-logo-wrap {
    position: relative;
    width: fit-content;
  }

  .theming-logo {
    max-width: 450px;
    max-height: 350px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

    &:hover + .theming-logo-delete {
      opacity: 1;
    }
  }

  .theming-logo-delete {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    border: 2px $primaryRed solid;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    opacity: 0.5;
    transition: opacity 200ms ease-out;

    &:hover {
      opacity: 1;
    }
  }

  .theming-file-upload-hint {
    @include roboto-font(normal, 400, 0.75rem, #9E9E9E);
  }

  .color-row {
    @include flex-row($col-gap: 0.87rem, $justify: normal);

    & > input {
      cursor: pointer;
    }
  }

</style>
