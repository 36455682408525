<script lang="ts">
    import mmLogo from '@/images/logo_green.svg';

    const production = import.meta.env.MODE === 'production';
</script>

<main>
    {#if production}
        <h1>Hoppla, die Seite gibt es scheinbar nicht. In der Zwischenzeit, besuche doch unsere Website</h1>
    {/if}
    <div>
        <a href="https://mineral-minds.de" target="_blank" rel="noreferrer">
            <img src={mmLogo} class="logo" alt="MM Logo"/>
        </a>
    </div>
    {#if !production}
        <h1>Mineral Minds + Svelte + Vite</h1>
        <h3>Vite is running in {production ? "production" : "dev"}&nbsp;mode</h3>

        <div style="display: flex; justify-content: space-around">
            <div class="card-look">
                <button onclick="window.open('https://test.mineral-minds.net','_blank');">Mineral Minds Test Server</button>
            </div>
            <div class="card-look">
                <button onclick="window.open('https://github.com/Mineral-Minds-Deutschland/mineralminds/pulls','_blank');">
                    Pull Requests
                </button>
            </div>
            <div class="card-look">
                <button onclick="window.open('http://localhost:6006','_blank');">
                    Storybook
                </button>
            </div>
        </div>
    {/if}
    <p class="read-the-docs">
        Click on the MM logo to learn more
    </p>
</main>

<style lang="scss">

  main {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;

    color-scheme: light dark;
    color: rgba(255, 255, 255, 0.87);

    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 1280px;
    margin: auto auto;
    height: 100vh;
    padding: 6rem 2rem;
    text-align: center;
  }

  a {
    font-weight: 500;
    color: #58C079;
    text-decoration: inherit;
  }

  a:hover {
    color: #535bf2;
  }
  h3 {
    color: #888;
  }

  .logo {
    height: auto;
    width: auto;
    padding: 1.5em;
    will-change: filter;
    transition: filter 300ms;
  }

  .logo:hover {
    filter: drop-shadow(0 0 2em rgba(88, 192, 121, 0.5));
  }

  .read-the-docs {
    color: #888;
  }

  .card-look {
    padding: 2em;
    width: 100%;
  }

  button {
    border-radius: 8px;
    border: 2px solid transparent;
    padding: 0.6em 1.2em;
    font-size: 1em;
    font-weight: 500;
    font-family: inherit;
    background-color: #1a1a1a;
    cursor: pointer;
    transition: border-color 0.25s;
  }

  button:hover {
    border-color: #c0c0c0;
  }

  @media (prefers-color-scheme: light) {
    main {
      color: #58C079;
      background-color: #ffffff;
    }
    a:hover {
      color: #747bff;
    }
    button {
      background-color: #f9f9f9;
    }
  }
</style>
