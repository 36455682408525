import {array, mixed, object, string} from "yup";
import {translate} from "../../../i18n/i18n";

export type MaterialProperty = {
    archived: boolean,
    description: string,
    displayName: string,
    flags: string[],
    id: string,
    isEditable: boolean,
    name: string,
    priority: number | null,
    propertyListElementIds: number[], // defined values for dropdown
    propertyListElements: MaterialPropertyListElement[],
    type: MaterialPropertyType,
}

export function DefaultMaterialProperty(): MaterialProperty {

    return {
        archived: false,
        description: "",
        displayName: "",
        flags: [],
        id: "new",
        isEditable: true,
        name: "",
        priority: null,
        propertyListElementIds: [],
        propertyListElements: [],
        type: MaterialPropertyType.NUMBER,
    };
}

export enum MaterialPropertyType {
    RANGE = "RANGE",
    NUMBER = "NUMBER",
    STRING = "STRING",
    BOOLEAN = "BOOLEAN",
    SINGLESELECT = "SINGLESELECT",
    MULTISELECT = "MULTISELECT"
}
export const dropdownTypes = [MaterialPropertyType.SINGLESELECT, MaterialPropertyType.MULTISELECT];

export type MaterialPropertyListElement = {
    id: string,
    name: string,
    priority: number | null,
    archived: boolean,
    propertyId: string | null,
    propertyChildIds: string[],
}

export enum MaterialPropertyView {
    MATERIAL_PROPERTY,
    MATERIAL_PROPERTY_LIST_ELEMENT
}

export enum PropertySystemFlag {
    LABEL = "LABEL",
    HIERARCHY_MATCHING = "HIERARCHY_MATCHING",
    ANALYTIC = "ANALYTIC",
    REQUIRED = "REQUIRED",
    KEY_INFORMATION = "KEY_INFORMATION",
    MATCHING = "MATCHING",
    COMMENT_GENERATION_SHOW_NAME = "COMMENT_GENERATION_SHOW_NAME",
    COMMENT_GENERATION_NEW_LINE_FOR_LIST_ELEMENT = "COMMENT_GENERATION_NEW_LINE_FOR_LIST_ELEMENT",
    COMMENT_GENERATION_NEW_LINE_FOR_CHILDREN = "COMMENT_GENERATION_NEW_LINE_FOR_CHILDREN"
}

export function DefaultMaterialPropertyListElement(): MaterialPropertyListElement {

    return {
        id: "new",
        name: "",
        priority: null,
        archived: false,
        propertyId: null,
        propertyChildIds: []
    };
}

export const materialPropertySchema = object({
    name: string().trim().required(translate('de', 'UI.mmpv.materialProperty.validation.name.required', [])),
    type: mixed()
        .oneOf(Object.values(MaterialPropertyType), translate('de', 'UI.mmpv.materialProperty.validation.type.oneOf', []))
        .required(translate('de', 'UI.mmpv.materialProperty.validation.type.required', [])),
});

export const materialPropertyDropdownSchema = object({
    name: string().trim().required(translate('de', 'UI.mmpv.materialProperty.validation.name.required', [])),
    type: mixed()
        .oneOf([MaterialPropertyType.MULTISELECT, MaterialPropertyType.SINGLESELECT], translate('de', 'UI.mmpv.materialProperty.validation.type.oneOf', []))
        .required(translate('de', 'UI.mmpv.materialProperty.validation.type.required', [])),
    propertyListElements: array().required(translate('de', 'UI.mmpv.materialProperty.validation.values.required', [])).min(1, 'UI.mmpv.materialProperty.validation.values.min')
})

export const materialPropertyListElementSchema = object({
    name: string().trim().required(translate('de', 'UI.mmpv.propertyListElement.validation.name.required', []))
})

