import {type MaterialProperty, type MaterialPropertyListElement, MaterialPropertyView} from "../types/materialProperty";
import {translate} from "../../../i18n/i18n";

class ViewHandler {
    view: MaterialPropertyView;
    title: string;
    breadcrumbTitle: string;
    description: string;
    materialPropertyTooltip: string;


    constructor(view){
        this.view = view;
    }

    getViewVariables(){
        console.log('Should be overridden by subclass');
    }

    setViewVariables(){
        console.log('Should be overridden by subclass');
    }
}

export class MaterialPropertyHandler extends ViewHandler {
    materialProperty: MaterialProperty;
    isNew: boolean;
    #propertyParents: Map<string, string[]>;
    #properties: Map<string, MaterialProperty>;

    constructor(materialProperty: MaterialProperty, isNew: boolean, propertyParents: Map<string, string[]>, properties: Map<string, MaterialProperty>) {
        super(MaterialPropertyView.MATERIAL_PROPERTY)
        this.materialProperty = materialProperty;
        this.isNew = isNew;
        this.#properties = properties;
        this.#propertyParents = propertyParents;
        this.buildMaterialPropertyParentsInfoTooltip();
        this.setViewVariables();
        return this;
    }

    getViewVariables() {
        return {
            title: this.isNew ? translate('de', 'UI.mmpv.materialProperty.modal.title.new', []) : translate('de', 'UI.mmpv.materialProperty.modal.title.existing', {materialProperty: this.materialProperty.name}),
            breadcrumbTitle: this.isNew ? translate('de', 'UI.mmpv.materialProperty.breadcrumb.new', []) : this.materialProperty.name,
            description: this.isNew ? translate('de', 'UI.mmpv.materialProperty.modal.description.new', []) : translate('de', 'UI.mmpv.materialProperty.modal.description.existing', []),
        };
    }

    setViewVariables() {
        const variables = this.getViewVariables();
        this.title = variables.title;
        this.breadcrumbTitle = variables.breadcrumbTitle;
        this.description = variables.description;
    }

    /**
     * Generates tooltip information for material property parents if available.
     *
     * The function checks for existence of the given material property in the property parents collection (i.e., $propertyParents)
     * and builds an HTML list of parent properties. If the given material property does not exist, an empty string
     * is returned instead.
     *
     * @function buildMaterialPropertyParentsInfoTooltip
     * @returns {string} The tooltip information as an HTML string, or an empty string if the given material property does not have parents.
     */
    buildMaterialPropertyParentsInfoTooltip(): void {
        if (this.#propertyParents.has(this.materialProperty.id)) {
            let list: string = this.#propertyParents.get(this.materialProperty.id)
                .filter((parentId: string) => this.#properties.get(parentId) !== undefined)
                .map((parentId:string) =>
                    `<li onclick="document.dispatchEvent(new CustomEvent('tooltipRedirect', {detail: '${parentId}'}))" style="cursor: pointer; margin-top: 0.5rem">
                    ${this.#properties.get(parentId).name}
                </li>`
                ).join('');
            this.materialPropertyTooltip = translate('de', 'UI.mmpv.materialProperty.parents.tt', {
                materialProperty: this.materialProperty.name,
                list: list
            })
        } else {
            this.materialPropertyTooltip = "";
        }
    }

}

export class MaterialPropertyListElementHandler extends ViewHandler {
    propertyElement: MaterialPropertyListElement;
    materialPropertyTooltip: string;
    constructor(propertyElement: MaterialPropertyListElement) {
        super(MaterialPropertyView.MATERIAL_PROPERTY_LIST_ELEMENT)
        this.propertyElement = propertyElement;
        this.setViewVariables();
        return this;
    }
    getViewVariables() {
        return {
            title: translate('de', 'UI.mmpv.listElement.modal.title', {listElement: this.propertyElement.name}),
            breadcrumbTitle: this.propertyElement.name,
            description: translate('de', 'UI.mmpv.listElement.modal.description', []),
            materialPropertyTooltip: "",
        }
    }

    setViewVariables() {
        const variables = this.getViewVariables()
        this.title = variables.title;
        this.breadcrumbTitle = variables.breadcrumbTitle;
        this.description = variables.description;
        this.materialPropertyTooltip = variables.materialPropertyTooltip;
    }

    buildMaterialPropertyParentsInfoTooltip(): void {
        this.materialPropertyTooltip = "";
    }
}