<script lang="ts">

    import {fetchUtils} from "../utils/fetchUtils";
    import {analyticalMetadata} from "./stores";


    export let valueKey: string;
    export let editable: boolean = true;
    export let onValuesRemoved;

    let {analyticalType, analyticalId, parentId, parentType} = $analyticalMetadata;
    $: $analyticalMetadata, {analyticalType, analyticalId, parentId, parentType} = $analyticalMetadata;

    const deleteValue = () => {
        fetchUtils.post(`/api/${analyticalType?.toLowerCase()}/removeValues`,
            {
                parentId,
                analyticalId,
                parentType,
                values: [
                    {
                        key: valueKey,
                    }
                ]
            }
        ).then(_ => {
            onValuesRemoved();
        })
    }

</script>

<div>
    {#if editable}
        <div class="icon-trash-red size-24" on:click={deleteValue}></div>
    {:else}
        <div class="icon-trash-grey size-24" class:notAllowed={!editable}></div>
    {/if}
</div>

<style lang="scss">
  @import "../../styles/global";
  @import "../../styles/buttons";

  .size-24 {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.notAllowed {
      cursor: not-allowed;
    }
  }
</style>
