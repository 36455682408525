import App from './App.svelte';
import {requireI18n} from './i18n/i18n';

let app;
requireI18n(() => {
	app = new App({
		target: document.getElementById('svelte-app'),
	});
});

export default app;
