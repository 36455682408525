import {translate} from "../../i18n/i18n";

export interface Filter {
    match(dataObj: any): boolean;
}

abstract class SimpleFilter implements Filter {
    prop: string;
    value: string;

    constructor(p: string, v: string) {
        this.prop = p;
        this.value = v;
    }

    abstract match(dataObj: any): boolean;
}

export class StringContainsFilter extends SimpleFilter {
    match(dataObj: any): boolean {
        if (typeof dataObj === 'object' && dataObj !== null) {
            if (this.prop === 'code') {
                return (dataObj.parentCode + dataObj.code).includes(this.value);
            }
            if (typeof dataObj[this.prop] === 'string') {
                return dataObj[this.prop].toLowerCase().includes(this.value.toLowerCase());
            }
            return dataObj[this.prop].includes(this.value);
        } else if (typeof dataObj === 'string') {
            return dataObj.toLowerCase().includes(this.value.toLowerCase());
        }
    }
}

export class TranslatedEnumContainsFilter extends SimpleFilter {
    translationPrefix: string

    constructor(prop: string, value: string, translationPrefix: string) {
        super(prop, value);
        this.translationPrefix = translationPrefix;
    }

    match(dataObj: object | string): boolean {
        if (typeof dataObj === 'object' && dataObj !== null) {
            const enumVal = dataObj[this.prop];
            return translate('de', `${this.translationPrefix}${enumVal}`, []).includes(this.value);
        } else if (typeof dataObj === 'string') {
            return translate('de', `${this.translationPrefix}${dataObj}`, []).includes(this.value);
        }
    }
}

export class LessThanFilter extends SimpleFilter {

    match(dataObj: any): boolean {
        return dataObj[this.prop] < this.value;
    }
}

export class LessThanOrEqualToFilter extends SimpleFilter {

    match(dataObj: any): boolean {
        return dataObj[this.prop] <= this.value;
    }
}

export class GreaterThanFilter extends SimpleFilter {

    match(dataObj: any): boolean {
        return dataObj[this.prop] > this.value;
    }
}

export class GreaterThanOrEqualToFilter extends SimpleFilter {

    match(dataObj: any): boolean {
        return dataObj[this.prop] >= this.value;
    }
}

export class EqualToFilter extends SimpleFilter {

    match(dataObj: any): boolean {
        return dataObj[this.prop] === this.value;
    }
}

export class NotEqualToFilter extends SimpleFilter {

    match(dataObj: any): boolean {
        return dataObj[this.prop] !== this.value;
    }
}

export class OrFilter implements Filter {
    filters: Filter[];

    constructor(filters: Filter[]) {
        this.filters = filters;
    }

    match(dataObj: any): boolean {
        return this.filters.some(filter => filter.match(dataObj));
    }
}

export class ListFilter implements Filter {
    filter: SimpleFilter;

    constructor(filter: SimpleFilter) {
        this.filter = filter;
    }

    match(dataObj: any): boolean {
        let data = dataObj[this.filter.prop];
        if (Array.isArray(data) && data.length > 0) {
            return data.some(val => this.filter.match(val));
        }
        return false;
    }
}


/**
 * Applies a set of filters to an object and returns whether the object satisfies all the filters.
 *
 * @param {Filter[]} filters - An array of Filter objects specifying the criteria to be applied. Each Filter object contains a type, a prop and a value. The type specifies the type of filter (e.g., STRING_CONTAINS, LT, GT, etc.), the prop specifies the property of the object against which to filter, and the value specifies the minimum/maximum/equal value or the substring to be matched, depending on the filter type.
 * @param dataObj - The object to which the filters need to be applied.
 *
 * @returns {boolean} Returns true if the object satisfies all the filters, otherwise returns false.
 *
 * @example
 * let filters = [{type: FilterType.GT, prop: 'age', value: 24}, {type: FilterType.STRING_CONTAINS, prop: 'name', value: 'John'}];
 * let dataObj = {name: 'John Doe', age: 25};
 * let result = applyFilters(filters, dataObj); // returns true
 */
export function applyFilters(filters: Filter[], dataObj: any): boolean {
    if (filters.length === 0) {
        return true;
    }
    return filters.every(filter => filter.match(dataObj));
}


