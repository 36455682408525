import {createRender} from "svelte-headless-table";
import Tooltip from "../atoms/Tooltip.svelte";
import InputFieldTableCell from "../analytics/InputFieldTableCell.svelte";
import {inputFieldFormats} from "../utils/formatters";

export function renderTooltip(msg: string, iconSrc: string, placement: string = "right", iconSize: string = "16px", isHtml: boolean = true) {
    return createRender(Tooltip, {
        msg: msg,
        ttipIconImage: iconSrc,
        ttipIconWidth: iconSize,
        ttipIconHeight: iconSize,
        placementTtip: placement,
        isHtml: isHtml
    })
}

export function createCol(header: string, accessor: string, options: any = null) {
    return {header: header, accessor: accessor, ...options};
}

export const createEditableCell /*: DataLabel<Sample>*/ = (updateFunc, editable, compact, isNumeric) => ({column, row, value}) =>
    createRender(InputFieldTableCell, {
        row,
        column,
        value,
        onUpdateValue: updateFunc,
        isNumeric: isNumeric,
        placeholder: "Wert",
        editable: editable,
        format: compact ? inputFieldFormats.COMPACT : inputFieldFormats.NORMAL
    })

