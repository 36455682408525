<script lang="ts">
    import Tooltip from "../atoms/Tooltip.svelte";

    export let msg: string;
    export let iconSize: string;
    export let iconSrc: string;
    export let align: string = "start";
    export let prefix: string = "<span style='color: #58C079; font-weight: 500'>Fußnote:<br><br></span>"


    let internalMsg: string;
    $: msg, internalMsg = (!msg.trimStart().startsWith("<span>") ? "<span>" : "") + msg + (!msg.trimStart().endsWith("</span>") ? "</span>" : "")
</script>
{#if msg && msg !== ""}
    <div style="position: relative" class="cell cell-align-{align}">
        <Tooltip msg={prefix + (internalMsg ?? "")} ttipIconImage={iconSrc} ttipIconHeight={iconSize} ttipIconWidth={iconSize}
                 isHtml/>
    </div>
{/if}

<style lang="scss">
  @import "../../styles/headlessTable";

  .cell :global(.tippy-content) {
    flex-direction: column !important;// we want a column to render data below each other
  }
</style>
