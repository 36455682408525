<script lang="ts">

    import DropdownMenu from "../../dropdown/SimpleDropdownMenu.svelte";
    import SimpleDropdownLinkItem from "../../dropdown/SimpleDropdownLinkItem.svelte";
    import DropdownMenuSection from "../../dropdown/DropdownMenuSection.svelte";
    import MenuItem from "../../MenuItem.svelte";
    import {UserPermission} from "../../../types/enums";
    import {t} from "../../../../i18n/i18n";
    import {hasPermissions} from "../../Permission.svelte";
    import {jwt} from "../../../utils/jwt";

    const LOTS_MENU_PERMISSIONS = `${UserPermission.EDIT_LOT}|`
        + `${UserPermission.VIEW_LOT}|`
        + `${UserPermission.BID_LOT}|`
        + `${UserPermission.VIEW_SAAS_LOT}|`
        + `${UserPermission.MANAGE_SAAS_LOT}`;

    const LOT_ACTIVE_PERMISSIONS = `${UserPermission.EDIT_LOT} | ${UserPermission.VIEW_SAAS_LOT}`;
    const TENANTLOTS_BID_PERMISSIONS = `${UserPermission.VIEW_SAAS_LOT} & (${UserPermission.BID_LOT} | ${UserPermission.VIEW_LOT})`;
    const NO_SAAS_BID_PERMISSIONS = `(!${UserPermission.VIEW_SAAS_LOT}) & (${UserPermission.BID_LOT} | ${UserPermission.VIEW_LOT})`;
    const NO_SAAS_OWN_PERMISSIONS = `(!${UserPermission.VIEW_SAAS_LOT}) & ${UserPermission.VIEW_LOT}`;

    const url = hasPermissions(LOT_ACTIVE_PERMISSIONS, $jwt.perms) ? "/lots/active" :
                hasPermissions(TENANTLOTS_BID_PERMISSIONS, $jwt.perms) ? "/tenantlots/bid" :
                hasPermissions(NO_SAAS_BID_PERMISSIONS, $jwt.perms) ? "/lots/bid" :
                hasPermissions(NO_SAAS_OWN_PERMISSIONS, $jwt.perms) ? "/lots/own" : "";
</script>

<MenuItem title={$t("Lot.plural")} {url} permissions={LOTS_MENU_PERMISSIONS} let:active>
    <DropdownMenu {active}>
        <DropdownMenuSection permissions={LOT_ACTIVE_PERMISSIONS}>
            <SimpleDropdownLinkItem titleKey="Lot.active" url="/lots/active"/>
            <SimpleDropdownLinkItem titleKey="Lot.discarded" url="/lots?state=DISCARDED"/>
            <SimpleDropdownLinkItem titleKey="Lot.completed" url="/lots/completed"/>
        </DropdownMenuSection>
        <DropdownMenuSection permissions={TENANTLOTS_BID_PERMISSIONS}>
            <SimpleDropdownLinkItem titleKey="Lot.bid" url="/tenantlots/bid" permissions={UserPermission.BID_LOT}/>
            <SimpleDropdownLinkItem titleKey="Lot.won" url="/tenantlots/won" permissions={UserPermission.VIEW_LOT}/>
        </DropdownMenuSection>
        <DropdownMenuSection permissions={TENANTLOTS_BID_PERMISSIONS}>
            <SimpleDropdownLinkItem titleKey="Lot.bidMM" url="/lots/bid" permissions={UserPermission.BID_LOT}/>
            <SimpleDropdownLinkItem titleKey="Lot.wonMM" url="/lots/won" permissions={UserPermission.VIEW_LOT}/>
            <SimpleDropdownLinkItem titleKey="Lot.ownMM" url="/lots/own" permissions={UserPermission.VIEW_LOT}/>
        </DropdownMenuSection>
        <DropdownMenuSection permissions={NO_SAAS_BID_PERMISSIONS}>
            <SimpleDropdownLinkItem titleKey="Lot.bid" url="/lots/bid" permissions={UserPermission.BID_LOT}/>
            <SimpleDropdownLinkItem titleKey="Lot.won" url="/lots/won" permissions={UserPermission.VIEW_LOT}/>
        </DropdownMenuSection>
        <DropdownMenuSection permissions={NO_SAAS_OWN_PERMISSIONS}>
            <SimpleDropdownLinkItem titleKey="Lot.own" url="/lots/own" permissions={UserPermission.VIEW_LOT}/>
        </DropdownMenuSection>
    </DropdownMenu>
</MenuItem>
