import {fetchUtils} from "../utils/fetchUtils";

export function termsCheckboxChanged(lotPublicId: string, checked: boolean, msg: string) {
    let request = {
        lotPublicId,
        checkboxState: checked,
        message: msg,
        // TODO csrfToken: CSRF_TOKEN
    };
    fetchUtils.post("/api/checkbox",request).catch(fetchUtils.catchErrorAndShowNotification);
}