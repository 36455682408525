<script lang="ts">
import {t} from "../../../../i18n/i18n";
import PrimaryButton from "../../../atoms/PrimaryButton.svelte";
import {PrimaryBtnColors} from "../../../atoms/PrimaryButton.svelte";
import SecondaryButton from "../../../atoms/SecondaryButton.svelte";
import {DefaultLot, validateAndSaveLot} from "../../types/lot";
import {currentLot} from "../utils/fakStores";
import {adjustMargin} from "../utils/fakUtils";


function addNewLot() {
    $currentLot = DefaultLot();
    adjustMargin($currentLot, true);
}

function createLot() {
    validateAndSaveLot();
}
</script>

<li class="fak-acc-item">
    <div class="add-lot-btns">
            <SecondaryButton on:click={() => $currentLot = null} label={$t('Button.cancel')} width="8.875rem"/>
            <PrimaryButton on:click={createLot} label={$t('Basedata.create')} color={PrimaryBtnColors.GREEN} width="15.9375rem"/>
    </div>
</li>

<style lang="scss">
  @import "../../../../styles/global";
  @import "../../../../styles/fak";

  .add-lot-btns {
    background: #FFFFFF;
    border-radius: 0.25rem;
    user-select: none;
    height: 3.875rem;
    display: flex;
    align-items: center;
    gap: 0.375rem;
    justify-content: center;
  }

</style>