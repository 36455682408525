<script lang="ts">
    import {t} from "../../i18n/i18n";
    import type {Lot} from "../types/lot";

    import Salutation from "../atoms/Salutation.svelte";
    import ProjectInformationGrid from "../project/ProjectInformationGrid.svelte";
    import InfoCard from "../organisms/InfoCard.svelte";
    import TextArea, {textAreaHeights} from "../atoms/TextArea.svelte";
    import FileUpload from "../organisms/FileUpload.svelte";
    import ActualTargetComparison from "../organisms/ActualTargetComparison.svelte";
    import {updateAttachmentsLot} from "../pages/OffererPubPage.svelte";
    import {getContext, onMount, setContext} from "svelte";
    import iconCompleted from "@/icons/icon_pubpage_project_completed.png";
    import Basic from "../templates/Basic.svelte";
    import PublicPageHeader from "../molecules/PublicPageHeader.svelte";
    import {userIsLoggedIn} from "../stores";
    import PublicPageFooter from "../molecules/PublicPageFooter.svelte";
    import type {PubPageParams} from "../bid/types/pubPageParams";
    import {writable, type Writable} from "svelte/store";
    import type {Deal} from "../bid/types/deal";
    import type {DealPartnerConnect} from "../types/dealPartner";
    import {fetchUtils} from "../utils/fetchUtils";
    import {Facts} from "../bid/types/facts";
    import type {DealActualValues} from "../types/dealActualValues";
    import LoadingSkeleton from "../animations/LoadingSkeleton.svelte";
    import {fade} from "svelte/transition";


    const urlParams: PubPageParams = getContext("urlParams") || null;
    const lot: Writable<Lot> = getContext("lot");
    const deal: Writable<Deal | null> = setContext("deal", writable(null));
    const dealPartnerConnect: Writable<DealPartnerConnect | null> = setContext("dealPartnerConnect", writable(null));
    const {lotPublicId} = urlParams;
    export let showBlur: boolean = true;


    // let lotPublicId: string = urlParams?.lotPublicId || $lot.publicId;

    let attachmentsLot: any[] = [];

    onMount(async () => {
        attachmentsLot = await updateAttachmentsLot(lotPublicId);
    });


    function closeBlur() {
        showBlur = !showBlur;
    }

    // we get the deal and actual data via the lot public id, since we now have exactly one specific partner which is
    // retrievable via the lot id
    async function fetchDealData() {
        await fetchUtils.get(`/api/offerer/lot/${lotPublicId}/dealAndActual`).then((data) => {
            console.log("data", data);
            if (data.deal) {
                let offererFacts: Facts = new Facts(data.deal?.offerActualValues?.totalFacts);
                let requestFacts: Facts = new Facts(data.deal?.requestActualValues?.totalFacts);
                let offerActualValues: DealActualValues = {
                    ...data.deal.offerActualValues,
                    totalFacts: offererFacts
                };
                let requestActualValues: DealActualValues = {
                    ...data.deal.requestActualValues,
                    totalFacts: requestFacts
                }
                $deal = {
                    ...data.deal,
                    dealFacts: new Facts(data.deal?.dealFacts),
                    offerActualValues,
                    requestActualValues
                }
                $dealPartnerConnect = {...data.contactData}
            }

        })
    }

    $: console.log($lot);
</script>

{#if !$userIsLoggedIn}
    <PublicPageHeader/>
{/if}


<Basic showStandardHeaderFooter={$userIsLoggedIn}>
    <div class="blur-background-overlay" class:hide={!showBlur}>
        <div class="blur-background-white"></div>
        <div class="blur-background-overlay-content align-start">
            <span class="completed-title">{$t("UI.optimizerPage.project.completed.title")}</span>
            <img class="completed-img" alt={$t("UI.optimizerPage.project.completed.title")}
                 src={iconCompleted}>
            <div style="margin-top: 15px; max-width: 35%">
                <span class="completed-hint">{$t("UI.optimizerPage.project.completed.hint")} </span>
            </div>
            <div style="margin-top: 15px">
                <span class="show-project-details" on:click={closeBlur}>{$t("Project.editProjectData")}</span>
            </div>
        </div>
    </div>
    {#await fetchDealData()}
        <div out:fade={{duration: 300}}>
            <LoadingSkeleton repeat={6}/>
        </div>
    {:then _}
        <Salutation name={$lot?.personAddressableName} address={$lot.address.zip + " " + $lot.address.city}/>
        <div class="row">
            <div class="col-lg-5 col-md-12 col-sm-12">
                <InfoCard offererPage/>
            </div>
            <div class="col-lg-7 col-md-12 col-sm-12">
                <div class="container-project-information" style="row-gap: 0.95rem">
                    <ProjectInformationGrid title={$t('UI.project.information.updated')} anonymizeAddress={false}
                                            {...$lot} titleGreen/>
                    <TextArea title={$t("UI.materialDetailsComment.header")} text={$lot.lotRemarks}
                              placeholder={$t('UI.remarks.noRemarks')} height={textAreaHeights.SMALL} readonly/>
                    <TextArea title={$t("UI.projectDetailsComment.header")} text={$lot.projectRemarks}
                              placeholder={$t('UI.remarks.noRemarks')} readonly isHtml/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-sm-12">
                <TextArea title={$t("UI.Model.moreDetails")} text={$lot.furtherDetailsComment}
                          placeholder={$t('UI.remarks.noRemarks')} height={textAreaHeights.SMALL} readonly/>
            </div>
        </div>
        <div class="row" style="margin-top: 1.875rem">
            <div class="col-lg-12 col-sm-12">
                <FileUpload visibleToCustomer={1} visibleToPartner={0} attachments={attachmentsLot} canNotDelete canNotUpload/>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-sm-12">
                <ActualTargetComparison isOfferer readOnly/>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-sm-12">
                <TextArea text={$lot.remarks} title={$t('UI.remarks.label')}
                          placeholder={$t("UI.pubRemarks.placeholder.project.complete")} readonly/>
            </div>
        </div>
    {/await}
</Basic>

{#if !$userIsLoggedIn}
    <PublicPageFooter/>
{/if}

<style lang="scss">
  @import "../../styles/animations";
  @import "../../styles/pubPage";

  .completed-title {
    @include roboto-font(28px, 500, 24px, black);
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: bold;
  }

  .completed-hint {
    @include roboto-font(18px, 400, 0.875rem, black);
    margin-bottom: 8rem;
  }

  .completed-img {
    margin-left: 25px;
    @media screen and (max-width: 768px) {
      width: 33%;
      max-width: 200px;
    }
  }

  .align-start {
    @include flex-col($justify: flex-start);
    margin-top: 40vh;
  }

  .hide {
    z-index: -10;
    display: none;
  }

  .show-project-details {
    @include roboto-font(15px, 500, 1rem, $primaryGreen);
    cursor: pointer;
  }

</style>
