/**
 * Corporate design colors for a tenant. If undefined, default MM colors are used.
 */
export type TenantColors = {
    primaryColor?: string,
    secondaryColor?: string,
    // add new colors here...
}

/**
 * Represents our default MM colors. See colors.scss for reference.
 */
export const defaultTenantColors: TenantColors = {
    primaryColor: "#58C079", // primaryGreen
    secondaryColor: "#469B61", // secondaryGreen
    // add new default colors here...
}
