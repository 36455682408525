<script lang="ts">
    /**
     * Represents a text variable.
     *
     * @param {string} text - The string value of the text variable.
     * @constructor
     */
    export let text: string;
    export let active: boolean;
    export let align: 'start' | 'end' | 'center' = "start";
</script>

<div style="position: relative" class="cell-align-{align}">
    <span class="cell-text" class:active>{@html text}</span>
</div>

<style lang="scss">
  @use "../../styles/headlessTable";
  @use "../../styles/global";

  .cell-text {
    color: global.$bluish-grey-700;

    &.active {
      color: global.$primaryGreen;
    }
  }
</style>
