<script lang="ts">
    import {t} from "../../i18n/i18n";
    import SecondaryButton from "../atoms/SecondaryButton.svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import {ButtonIcons} from "../types/enums";
    import {onMount} from "svelte";
    import {type NextButtonDto, NextButtonOriginPage, NextButtonWantedObject} from "../bid/types/nextButtonDto";

    export let currentId: string | undefined = undefined;
    export let wantedObject: NextButtonWantedObject = NextButtonWantedObject.REQUEST;
    export let isSaas: boolean = false;
    export let returnUrl: string = '/lots/bid';

    let nextUrl: string | null = null;
    let fromPage: string | null = null;
    let nextObjectParentId: string | number | undefined;
    let nextObjectOriginPage: NextButtonOriginPage = NextButtonOriginPage.BID;

    onMount(() => {
        // Return base page button url
        const params = new URLSearchParams(window.location.search);

        // we can now reach the PubPages not only via the /lots/bid pages or the mails, but also via a /pop/$id/bid
        // or /pop/$id/won url, since we can only catch the first case via the code, we need to be able to redirect
        // to the page in the 'from' url attribute as well as use that url as a base url to get the next project of that list
        fromPage = params.get("from");
        let fromId = params.get("fromId");

        if (fromPage && fromPage.startsWith(NextButtonOriginPage.POP.toLowerCase()) && fromId) {
            nextObjectParentId = fromId;
            nextObjectOriginPage = NextButtonOriginPage.POP;
            switch (fromPage) {
                case 'popBid':
                    returnUrl = `/pop/${fromId}/bid`;
                    break;
                case 'popWon':
                    returnUrl = `/pop/${fromId}/won`;
                    break;
            }
        } else if (fromPage && fromPage.startsWith(NextButtonOriginPage.BID.toLowerCase())) {
            nextObjectOriginPage = NextButtonOriginPage.BID;
            switch (fromPage) {
                case 'bid':
                    returnUrl = '/lots/bid';
                    break;
                case 'bidWon':
                    returnUrl = '/lots/won';
                    break;
            }
        } else if (fromPage && fromPage.startsWith(NextButtonOriginPage.SAAS.toLowerCase())) {
            nextObjectOriginPage = NextButtonOriginPage.SAAS;
            switch (fromPage) {
                case 'saasBid':
                    returnUrl = '/tenantlots/won';
                    break;
                case 'saasWon':
                    returnUrl = '/tenantlots/won';
                    break;
            }
        }
        loadNextId(currentId);
    });

    async function loadNextId(currentId: string | number | undefined) {
        if (!currentId) return;
        if (typeof currentId === "number" && currentId <= 0) return;
        const payload : NextButtonDto = {
            originPage: nextObjectOriginPage,
            parentObjectId: nextObjectParentId,
            isSaas,
            currentId,
            wantedObject
        }
        await fetchUtils.post("/api/optimizer/nextObjectUrl", payload)
            .then(data => {
                nextUrl = data;
            }).catch(error => console.log(error));
    }
</script>


<div class="navigation-buttons-container">
    <div>
        <a href={returnUrl}><!-- back to requests list -->
            <SecondaryButton leftIcon={ButtonIcons.LEFT_ARROW} label={$t('UI.backToPage.button')}/>
        </a>
    </div>
    <div>
        <a href={nextUrl}> <!-- show next request/deal in list -->
            <SecondaryButton rightIcon={ButtonIcons.RIGHT_ARROW} label={$t('UI.nextProject.button')}
                             disabled={nextUrl == null}/>
        </a>
    </div>
</div>

<style lang="scss">
  @import "../../styles/inputFields";

  .navigation-buttons-container {
    width: 100%;
    /*     add space in case we are on mobile, then this is necessary.
         Otherwise the space-between deals with the space, but with the 0.25 we give a minimum*/
    @include flex-row(0.25rem, $justify: space-between);
    margin-top: 3.125rem;
  }

  a {
    text-decoration: none;
  }

</style>
