<script lang="ts" context="module">
    export const hasPermissions = (requiredPermissions: string, userPermissions: string[]) => {
        return LEP.parse(requiredPermissions.replaceAll(/\s/g, ""), t => userPermissions.indexOf(t) > -1);
    }
</script>
<script lang="ts">
    import LEP from "logical-expression-parser";
    import {onMount} from "svelte";
    import {jwt} from "../utils/jwt";

    /**
     * For the required permissions, we expect a string including the operators
     * | OR
     * & AND
     * ! Not
     * () Parentheses -> AND is stronger than OR
     * CANNOT HANDLE WHITESPACES, THEREFORE WE REPLACE ALL WHITESPACES BELOW
     * https://github.com/NimitzDEV/logical-expression-parser
     */
    export let requiredPermissions: string | null;

    let userPermissions: string[] = [];
    let permissionsMatch: boolean;

    onMount(() => {
        permissionsMatch = checkPermissions();
    })

    function checkPermissions() {
        if (requiredPermissions) {
            requiredPermissions = requiredPermissions.replaceAll(/\s/g, ""); // replace whitespaces, parser can't handle them
            if (!userPermissions) {
                return false;
            }
            return LEP.parse(requiredPermissions, t => userPermissions.indexOf(t) > -1);
        }
        return true;
    }

    $: userPermissions = $jwt.perms;
    $: userPermissions, permissionsMatch = checkPermissions();
</script>

{#if permissionsMatch}
    <slot/>
{/if}
