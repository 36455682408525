<script lang="ts">

    import {onMount} from "svelte";
    import {LoadingAnimation} from "../types/enums";
    import {displayLoadingAnimation, manualAnimationPending} from "../stores";
    import MmLoadingAnimation from "../animations/MmLoadingAnimation.svelte";
    import FileUploadAnimation from "../animations/FileUploadAnimation.svelte";

    export let showOnMount: boolean = true;
    let component: AnimationComponents;
    let show = false;

    type AnimationComponents = typeof FileUploadAnimation | typeof MmLoadingAnimation;

    const animations: { animation: LoadingAnimation, component: AnimationComponents }[] = [
        {
            animation: LoadingAnimation.FileUpload,
            component: FileUploadAnimation
        },
        {
            animation: LoadingAnimation.MmLoader,
            component: MmLoadingAnimation
        },
    ];

    function getComponentForType(type: LoadingAnimation): AnimationComponents {
        return animations.find(animation => animation.animation === type)?.component || MmLoadingAnimation;
    }

    /*this shows the MmLoadingAnimation on start of every refresh of every Page, were LoadingAnimations is included */
    onMount(() => {
        component = MmLoadingAnimation;
        if (showOnMount) {
            show = true;
            setTimeout(() => {
                if (!$manualAnimationPending) { // only end the animation, when there is no condition we are waiting for
                    show = false;
                }
            }, 2300);
        }
    })

    displayLoadingAnimation.subscribe(async (animation) => {
        if (animation.display === true) {
            show = true;
            component = getComponentForType(animation.animation);
        } else {
            show = false;
        }
    });


</script>

{#if show}
    <svelte:component this={component}/>
{/if}
