<script lang="ts">
    /* https://svelte-modals.mattjennings.io/api */
    import {closeModal} from 'svelte-modals';
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import Icon from "../atoms/Icon.svelte";
    import {t} from "../../i18n/i18n";
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import {fly} from 'svelte/transition';
    import {ButtonIcons} from "../types/enums";
    import InputField from "../molecules/InputField.svelte";
    import type {Catalog} from "./types/catalog";
    import {catalogSchema, DefaultCatalog} from "./types/catalog";
    import {fetchUtils} from "../utils/fetchUtils";
    import type {Notification} from "../types/notification";
    import {DefaultNotification, NotificationType} from "../types/notification";
    import {addNotification} from "../stores";
    import {inputFieldFormats} from "../utils/formatters";
    import type {FontProps} from "../types/fontProps";
    import {DefaultInputFieldProps} from "../types/fontProps";
    import {getFormErrors} from "../utils/formValidation";
    import type {Writable} from "svelte/store";
    import {getContext, onMount, setContext} from "svelte";
    import TextArea from "../atoms/TextArea.svelte";
    import {checkUnedited} from "../utils/misc";
    import iconClose from "@/icons/icon_close.svg";

    const CATALOG_FORM_ERROR_PATH_PREFIX = "catalog.";
    const fontProps: FontProps = DefaultInputFieldProps();
    const headerFontProps: FontProps = DefaultInputFieldProps('13px', 500);

    // provided by <Modals/>
    export let isOpen: boolean;
    export let showCloseIcon: boolean = false;
    // this is basically a callback which allows to trigger an update of created or changed catalog
    export let update = (data: any) => {
        return data
    };
    export let padding = "1.75rem 4.75rem";
    export let maxWidth = "100%";

    export let catalog: Catalog = DefaultCatalog(); // to enable editing, we can give it as argument

    setContext("useMaterialDesign", true);

    let catalogCopy: Catalog;
    let formErrors: Writable<{ path: string, message: string }[]> = getContext("formErrors");

    let showOverlay: boolean = false;
    let overlayTitle: string = $t('UI.mmpv.materialPropertyModal.unsavedChanges.title');
    let overlayMessage: string = $t('UI.mmpv.catalogModal.unsavedChanges.message');
    let overlayButtonLabel: string = $t('UI.mmpv.materialPropertyModal.unsavedChanges.button.quit')
    let overlayAction = () => {
    }
    let clickCount = 0;


    onMount(() => {
        catalogCopy = {...catalog};
    })

    async function updateCatalog() {

        try {
            let validatedCatalog = await catalogSchema.validate(catalog, {
                stripUnknown: false,
                abortEarly: false
            });
            if (validatedCatalog.maxDepth === null) {
                validatedCatalog.maxDepth = 3;
            }
            clickCount++; // we do not want this to trigger multiple times, when the validation was correct
            if (clickCount <= 1) {
                fetchUtils.post(`/api/catalog/${catalog.id}`, validatedCatalog)
                    .then(catalogData => {
                        let notification: Notification = DefaultNotification($t('UI.mmpv.createCatalog.notification.success'), NotificationType.SUCCESS, false, 3500);
                        addNotification(notification);
                        update(catalogData); // add catalog to catalogs
                        closeModal();

                    })
                    .catch(fetchUtils.catchErrorAndShowNotification())
            }
        } catch (error) {
            clickCount = 0;
            let err = getFormErrors(error, CATALOG_FORM_ERROR_PATH_PREFIX);
            console.log('ValidationError: ');
            console.dir(err);
            $formErrors = err;
        }
    }

    function handleAbort() {
        let isUnedited: boolean = checkUnedited(catalog, catalogCopy);
        if (isUnedited) {
            closeModal();
        } else {
            showOverlay = true;
            overlayAction = () => {
                catalog = {...catalogCopy}; // reset the catalog such that the changes are not saved with the session
                closeModal();
            }
        }
    }

</script>

{#if isOpen}
    <div role="dialog" class="modal" in:fly="{{ y: -1000, duration: 400 }}" out:fly={{y: -1000, duration: 400}}>
        <div class="contents" style="--content-padding: {padding}; max-width: {maxWidth}">
            {#if showCloseIcon}
                <div class="cancel-btn" on:click={handleAbort}>
                    <Icon src={iconClose} size={24}/>
                </div>
            {/if}
            {#if showOverlay}
                <div class="modal-overlay-content">
                    <div>
                        <span>{@html overlayTitle}</span>
                        <p>{@html overlayMessage}</p>
                    </div>
                    <div>
                        <SecondaryButton label={overlayButtonLabel} color={SecondaryBtnColors.RED}
                                         on:click={overlayAction} sizeAdaptingToText/>

                        <PrimaryButton label={$t('UI.mmpv.materialPropertyModal.button.continue')}
                                       color={PrimaryBtnColors.RED}
                                       on:click={() => {showOverlay = false}} sizeAdaptingToText/>
                    </div>
                </div>
            {:else}
                <div class="actual-content">
                    <h1>{@html $t('UI.mmpv.createCatalog')}</h1>
                    <InputField bind:value={catalog.name} id="input-catalog-name"
                                placeholder={$t('UI.mmpv.catalog.name')} {fontProps}
                                {headerFontProps} format={inputFieldFormats.FULL}
                                errorPath={CATALOG_FORM_ERROR_PATH_PREFIX + 'name'} required/>
                    <div class="depth-and-highlighted">
                        <InputField bind:value={catalog.maxDepth} id="input-catalog-depth"
                                    placeholder={$t('UI.mmpv.catalog.maxDepth')} {fontProps} {headerFontProps}
                                    errorPath={CATALOG_FORM_ERROR_PATH_PREFIX + 'maxDepth'}
                                    format={inputFieldFormats.FULL} isNumeric required/>
                        <InputField bind:value={catalog.highlightedValue} id="input-catalog-indicator"
                                    placeholder={$t('UI.mmpv.catalog.highlighted.indicator')} {fontProps}
                                    {headerFontProps} errorPath={CATALOG_FORM_ERROR_PATH_PREFIX + 'maxhighlightedValue'}
                                    format={inputFieldFormats.FULL} required/>
                    </div>

                    <TextArea bind:text={catalog.comment} id="textarea-catalog-comment"
                              title={$t('UI.mmpv.catalog.comment')} placeholder={$t('UI.mmpv.catalog.comment.ph')}
                              inputFontProps={fontProps} {headerFontProps} labelGap="0.375rem" marginTop="0"/>
                </div>
                <div class="actions">
                    <SecondaryButton label={$t('UI.button.cancel')} color={SecondaryBtnColors.GREEN}
                                     sizeAdaptingToText on:click={handleAbort}/>
                    <PrimaryButton id="btn-catalog-modal-add" label={$t('UI.mmpv.createCatalog.modal.button')}
                                   color={PrimaryBtnColors.GREEN} rightIcon={ButtonIcons.CHECKMARK} sizeAdaptingToText
                                   whiteIcon on:click={updateCatalog}/>
                </div>
            {/if}
        </div>
    </div>
{/if}

<style lang="scss">
  @use "../../styles/modals"; // also includes global.scss

  p {
    @include modals.roboto-font(14px, 400, 0.75rem);
    text-align: center;
    margin-top: 1rem;
    padding: 1rem 1rem 0;
  }

  .actual-content {
    width: 100%;
    padding: 0 1rem;
    @include modals.flex-col(0.75rem);
  }

  .depth-and-highlighted {
    width: 100%;
    @include modals.flex-row(0.938rem, $justify: space-between);
  }

  .actions {
    @include modals.flex-row(0.375rem, $justify: flex-end);
    width: 100%;
    margin-top: 1.125rem;
    padding: 0.5rem 1rem;
    border-radius: 0 0 6px 6px;
  }

</style>
