<script lang="ts">

    import type {Material} from "./types/material";
    import {DefaultMaterial} from "./types/material";
    import MaterialDetails from "./MaterialDetails.svelte";
    import TreeView from "../organisms/TreeView.svelte";
    import Tooltip from "../atoms/Tooltip.svelte";
    import type {Catalog} from "./types/catalog";
    import {closeModal, openModal} from "svelte-modals";
    import Modal from "../organisms/Modal.svelte";
    import {addNotification, treeExpansionStates} from "../stores";
    import type {Notification} from "../types/notification";
    import {DefaultNotification, NotificationType} from "../types/notification";
    import {getContext, onMount} from "svelte";
    import {t} from "../../i18n/i18n";
    import {changeUrl} from "../utils/misc";
    import type {Filter} from "../utils/filter";
    import {applyFilters} from "../utils/filter";
    import {deepEqual} from "../utils/utils";
    import type {Writable} from "svelte/store";
    import type {MaterialProperty} from "./types/materialProperty";
    import {TooltipPlacements} from "../types/enums";
    import {getParentProperties} from "./util/materialUtil";

    export let filters: Filter[] = [];

    let catalog: Writable<Catalog> = getContext('selectedCatalog');
    let material: Writable<Material> = getContext('selectedMaterial');
    let properties: Writable<Map<string, MaterialProperty>> = getContext('materialProperties');
    let materialCopy: Material; // this is needed to check for differences -> if different, we need to save changes
    let isNew = false;

    onMount(() => {
        if ($catalog.nodes.length === 0 || !$catalog.hasOwnProperty('nodes')) {
            addRoot();
        }
        // insert labels and trigger update
        $catalog.nodes.forEach((node: Material) => node.label = (node?.parentCode ?? '') + node.code + (node.highlighted ? $catalog.highlightedValue : "") + ' ' + node.name);
        $catalog = $catalog;
        materialCopy = structuredClone($material);
        filterNodesAndSetSelectedMaterial();
    })

    function changeMaterial(selectedMaterial: Material) {
        if (!selectedMaterial) {
            selectedMaterial = DefaultMaterial('root', null, 0);
        }
        addPropertiesToSelectedMaterial(selectedMaterial);
        $material = structuredClone(selectedMaterial);
        materialCopy = structuredClone(selectedMaterial);
        showEditProperty = false; // we do not want to see the property edit window
        changeUrl(`catalog/${$catalog.name}/material/${$material.id}`, window);
    }

    function addPropertiesToSelectedMaterial(selectedMaterial: Material) {
        selectedMaterial.properties = []; // needed => doesn't exist if empty from server
        if (selectedMaterial.propertyIds && selectedMaterial.propertyIds.length > 0) {
            selectedMaterial.propertyIds.forEach((propertyId: string) => selectedMaterial.properties.push($properties.get(propertyId)));
            selectedMaterial.properties.forEach((property: MaterialProperty) => property.isEditable = true);
        }
        let hereditaryPropertyIds = getParentProperties(selectedMaterial, $catalog);
        let hereditaryProperties = [];
        hereditaryPropertyIds.forEach(propertyId => hereditaryProperties.push($properties.get(propertyId)));
        hereditaryProperties.forEach(property => property.isEditable = false);
        hereditaryProperties.forEach(property => selectedMaterial.properties.push(property));
    }


    function addNode(node: Material) { // not yet sent to backend
        let existingNewNode: boolean = $catalog.nodes.some(n => n.id === 'new');
        if (!existingNewNode) { // if there is not already a new node wih this parent
            changeMaterial(DefaultMaterial(node.id, node.parentCode ?? '' + node.code ?? '', node.level + 1));
            isNew = true;
            // if children property does not exist, we create it
            if (node.hasOwnProperty('childrenIds') && Array.isArray(node.childrenIds)) {
                node.childrenIds.push($material.id);
            } else {
                node.childrenIds = [$material.id];
            }
            $treeExpansionStates.get($catalog.name).push(node.id); // by default expand the parent
            $catalog.nodes.push($material); // push the new material into the nodes
            $catalog = $catalog;
            filterNodesAndSetSelectedMaterial(true);
        } else {
            let notification: Notification = DefaultNotification('Es existiert bereits ein neuer Knoten. Schließe erst das Erstellen dieses Knotens ab.', NotificationType.INFO, false, 5000);
            addNotification(notification);
        }
    }

    // export to be able to call it from parent
    export function addRoot() { // not yet sent to backend
        let existingNewNode: boolean = $catalog.nodes.some((node: Material) => node.id === 'new'); // is there already a new node?
        if (!existingNewNode) { // If there is not already a new root node
            // we give it to the parent id 'root' to go through validation correctly (null is not allowed)
            changeMaterial(DefaultMaterial('root', null, 0))// has no parent and thus no parentCode
            isNew = true;
            $catalog.nodes.push($material);
            $catalog.nodes = $catalog.nodes;
            filterNodesAndSetSelectedMaterial(true); // give the material such that we do not change away from the new material
        } else {
            let notification: Notification = DefaultNotification('Es existiert bereits ein neuer Knoten. Schließe erst das Erstellen dieses Knotens ab.', NotificationType.INFO, false, 5000);
            addNotification(notification);
            changeMaterial(DefaultMaterial('root', null, 0))// has no parent and thus no parentCode
        }
    }


    function navigateMaterial(selectedMaterial: Material) {
        changeMaterial(selectedMaterial);
        isNew = $material.id === 'new';
    }

    function handleSelectMaterial(e) {
        materialCopy?.properties?.sort((a, b) => a.priority - b.priority).sort((a, b) => a.name - b.name);
        let isMaterialUnedited: boolean = deepEqual($material, materialCopy);
        if (isMaterialUnedited) {
            navigateMaterial(e.detail.selectedMaterial);
        } else {
            openModal(Modal, {
                title: $t('UI.modal.unsavedChanges.header'),
                message: $t('UI.mmpv.modal.material.unsavedChanges.message'),
                denyText: $t('UI.saveAndDiscardChanges'),
                onDeny: () => {
                    navigateMaterial(e.detail.selectedMaterial);
                    closeModal();
                }
            })
        }
    }

    function onRemoveNode(e) {
        const idx = $catalog.nodes.map((node: Material) => node.id).indexOf(e.detail.material.id);
        if (idx > -1) {
            $catalog.nodes.splice(idx, 1);
            $catalog.nodes = $catalog.nodes;
            filterNodesAndSetSelectedMaterial();
            isNew = false; // as we change to existing material, we do not have a new node anymore
        }
    }

    function filterNodesAndSetSelectedMaterial(keepMaterial: boolean = false) {
        filteredNodes = [];
        let nodes = $catalog.nodes.filter((node: Material) => applyFilters(filters, node) || node.id === 'new'); // FIXME also show parents until root of filtered nodes
        nodes.forEach((node: Material) => {
            if (!nodeInArray(node, filteredNodes)) {
                filteredNodes.push(node);
            }
            addParentsToCollection(node, filteredNodes)
        });
        if (filters.length > 0) { // when we reset the filters by creating a new node, we don't want to change material
            if (!keepMaterial) {
                changeMaterial(filteredNodes[0]);
            }
            $treeExpansionStates.set($catalog.name, []); // set empty to only show filtered nodes
            filteredNodes.forEach((node: Material) => $treeExpansionStates.get($catalog.name).push(node.id));
        }
    }

    function addParentsToCollection(node: Material, collection) {
        let parent = findParent(node);
        if (parent && !nodeInArray(parent, collection)) {
            collection.push(parent);
            addParentsToCollection(parent, collection);
        }
    }

    function findParent(node: Material) {
        return $catalog.nodes.find((parentNode: Material) => parentNode.id === node.parentId);
    }

    function nodeInArray(node: Material, nodeArray: Material[]) {
        return nodeArray.some((x: Material) => x.id === node.id)
    }


    let addGreen: boolean = false;
    let showEditProperty: boolean = false;
    let filteredNodes;

    $: filters, filterNodesAndSetSelectedMaterial();
</script>

<div class="material-tree-edit-view">
    <div>
        <TreeView nodes={filteredNodes} treeExpansionMapKey={$catalog.name} activeNode={$material}
                  on:labelClick={handleSelectMaterial}>
            <div slot="nodeAction" let:item style="position: relative" on:mouseenter={() => addGreen = true}
                 on:mouseleave={() => addGreen = false}>
                <!-- if we are hovering, we do not want the action for a leaf -->
                {#if item.level < $catalog.maxDepth - 1}
                    <Tooltip msg={$t('UI.mmpv.addChild')} placementTtip={TooltipPlacements.RIGHT}>
                        <div class="add-icon" class:addGreen on:click={() => addNode(item)}></div>
                    </Tooltip>
                {/if}
            </div>
        </TreeView>
    </div>
    <div>
        <MaterialDetails {isNew} bind:showEditProperty bind:materialCopy on:removeNode={onRemoveNode} on:backToCatalogs
                         on:addRoot={addRoot}
                         on:changeMaterial={(event)=>{ changeMaterial(event.detail.material); filterNodesAndSetSelectedMaterial(true); isNew=false} }/>
        <!-- compare changes with materialCopy before navigation -->
    </div>
</div>

<style lang="scss">
  @import "../../styles/global";

  .material-tree-edit-view {
    display: grid;
    grid-template-columns: 17.188rem 1fr;
    grid-column-gap: 5rem;

    & > div:last-child {
      justify-self: center;
      width: 100%;
    }
  }

  .add-icon {
    width: 20px;
    height: 20px;
    background: url("@/icons/icon_plus_grey_20.svg") no-repeat center;
    background-size: contain;
    animation: incOpacity 0.3s ease-out;
    -webkit-animation: incOpacity 0.3s ease-out;
    -moz-animation: incOpacity 0.3s ease-out;
    -o-animation: incOpacity 0.3s ease-out;

    &:hover {
      cursor: pointer;
    }

    @-webkit-keyframes incOpacity {
      0% {
        display: none;
        opacity: 0;
      }
      1% {
        opacity: 0;
      }
      100% {
        opacity: 100%;
      }
    }
    @-moz-keyframes incOpacity {
      0% {
        display: none;
        opacity: 0;
      }
      1% {
        opacity: 0;
      }
      100% {
        opacity: 100%;
      }
    }
    @keyframes incOpacity {
      0% {
        display: none;
        opacity: 0;
      }
      1% {
        opacity: 0;
      }
      100% {
        opacity: 100%;
      }
    }
    @-o-keyframes incOpacity {
      0% {
        display: none;
        opacity: 0;
      }
      1% {
        opacity: 0;
      }
      100% {
        opacity: 100%;
      }
    }
  }

  .addGreen {
    background: url("@/icons/icon_plus_green_20.svg") no-repeat center !important;
  }

</style>