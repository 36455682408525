export type InstallationCase = {
    waterProtectionArea: WaterProtectionArea,
    waterProtection: WaterProtection,
    installationCase: InstallationCaseTechnical | InstallationCaseTrain,
    surfaceLayer: SurfaceLayer,
    surfaceLayerMaterial: SurfaceLayerMaterial,
}

export function EmptyInstallationCase(): InstallationCase {
    return {surfaceLayer: null, surfaceLayerMaterial: null, installationCase: null, waterProtection: null, waterProtectionArea: null}
}

export enum InstallationCaseTechnical {
    INSTALLATION_CASE_01 = "InstallationCaseTechnical.INSTALLATION_CASE_01",
    INSTALLATION_CASE_02 = "InstallationCaseTechnical.INSTALLATION_CASE_02",
    INSTALLATION_CASE_03 = "InstallationCaseTechnical.INSTALLATION_CASE_03",
    INSTALLATION_CASE_04 = "InstallationCaseTechnical.INSTALLATION_CASE_04",
    INSTALLATION_CASE_05 = "InstallationCaseTechnical.INSTALLATION_CASE_05",
    INSTALLATION_CASE_06 = "InstallationCaseTechnical.INSTALLATION_CASE_06",
    INSTALLATION_CASE_07 = "InstallationCaseTechnical.INSTALLATION_CASE_07",
    INSTALLATION_CASE_08 = "InstallationCaseTechnical.INSTALLATION_CASE_08",
    INSTALLATION_CASE_09 = "InstallationCaseTechnical.INSTALLATION_CASE_09",
    INSTALLATION_CASE_10 = "InstallationCaseTechnical.INSTALLATION_CASE_10",
    INSTALLATION_CASE_11 = "InstallationCaseTechnical.INSTALLATION_CASE_11",
    INSTALLATION_CASE_12 = "InstallationCaseTechnical.INSTALLATION_CASE_12",
    INSTALLATION_CASE_13 = "InstallationCaseTechnical.INSTALLATION_CASE_13",
    INSTALLATION_CASE_14 = "InstallationCaseTechnical.INSTALLATION_CASE_14",
    INSTALLATION_CASE_15 = "InstallationCaseTechnical.INSTALLATION_CASE_15",
    INSTALLATION_CASE_16 = "InstallationCaseTechnical.INSTALLATION_CASE_16",
    INSTALLATION_CASE_17 = "InstallationCaseTechnical.INSTALLATION_CASE_17"
}

export enum InstallationCaseTrain {
    INSTALLATION_CASE_B01 = "InstallationCaseTrain.INSTALLATION_CASE_B01",
    INSTALLATION_CASE_B02 = "InstallationCaseTrain.INSTALLATION_CASE_B02",
    INSTALLATION_CASE_B03 = "InstallationCaseTrain.INSTALLATION_CASE_B03",
    INSTALLATION_CASE_B04 = "InstallationCaseTrain.INSTALLATION_CASE_B04",
    INSTALLATION_CASE_B05 = "InstallationCaseTrain.INSTALLATION_CASE_B05",
    INSTALLATION_CASE_B06 = "InstallationCaseTrain.INSTALLATION_CASE_B06",
    INSTALLATION_CASE_B07 = "InstallationCaseTrain.INSTALLATION_CASE_B07",
    INSTALLATION_CASE_B08 = "InstallationCaseTrain.INSTALLATION_CASE_B08",
    INSTALLATION_CASE_B09 = "InstallationCaseTrain.INSTALLATION_CASE_B09",
    INSTALLATION_CASE_B10 = "InstallationCaseTrain.INSTALLATION_CASE_B10",
    INSTALLATION_CASE_B11 = "InstallationCaseTrain.INSTALLATION_CASE_B11",
    INSTALLATION_CASE_B12 = "InstallationCaseTrain.INSTALLATION_CASE_B12",
    INSTALLATION_CASE_B13 = "InstallationCaseTrain.INSTALLATION_CASE_B13",
    INSTALLATION_CASE_B14 = "InstallationCaseTrain.INSTALLATION_CASE_B14",
    INSTALLATION_CASE_B15 = "InstallationCaseTrain.INSTALLATION_CASE_B15",
    INSTALLATION_CASE_B16 = "InstallationCaseTrain.INSTALLATION_CASE_B16",
    INSTALLATION_CASE_B17 = "InstallationCaseTrain.INSTALLATION_CASE_B17",
    INSTALLATION_CASE_B18 = "InstallationCaseTrain.INSTALLATION_CASE_B18",
    INSTALLATION_CASE_B19 = "InstallationCaseTrain.INSTALLATION_CASE_B19",
    INSTALLATION_CASE_B20 = "InstallationCaseTrain.INSTALLATION_CASE_B20",
    INSTALLATION_CASE_B21 = "InstallationCaseTrain.INSTALLATION_CASE_B21",
    INSTALLATION_CASE_B22 = "InstallationCaseTrain.INSTALLATION_CASE_B22",
    INSTALLATION_CASE_B23 = "InstallationCaseTrain.INSTALLATION_CASE_B23",
    INSTALLATION_CASE_B24 = "InstallationCaseTrain.INSTALLATION_CASE_B24",
    INSTALLATION_CASE_B25 = "InstallationCaseTrain.INSTALLATION_CASE_B25",
    INSTALLATION_CASE_B26 = "InstallationCaseTrain.INSTALLATION_CASE_B26"
}

export enum WaterProtectionArea {
    INSIDE = "WaterProtectionArea.INSIDE",
    OUTSIDE = "WaterProtectionArea.OUTSIDE",
}

export enum WaterProtection {
    NONE = "WaterProtection.NONE",
    WSGIIIA_HSGIII = "WaterProtection.WSGIIIA_HSGIII",
    WSGIIIB_HSGIV = "WaterProtection.WSGIIIB_HSGIV",
    WATER_PRIORITY_AREA = "WaterProtection.WATER_PRIORITY_AREA"
}

export enum SurfaceLayer {
    FAVOURABLE = "SurfaceLayer.FAVOURABLE",
    UNFAVOURABLE = "SurfaceLayer.UNFAVOURABLE"
}

export enum SurfaceLayerMaterial {
    EMPTY = "SurfaceLayerMaterial.EMPTY",
    SAND = "SurfaceLayerMaterial.SAND",
    LOAM_SILT_CLAY = "SurfaceLayerMaterial.LOAM_SILT_CLAY"
}

export enum InstallationCaseMaterials {
   SOIL_MATERIAL = "InstallationCaseMaterial.SOIL_MATERIAL", // Bodenmaterial (BM)
   DREDGED_MATERIAL = "InstallationCaseMaterial.DREDGED_MATERIAL", // Baggergut (BG)
   RAIL_BALLAST = "InstallationCaseMaterial.RAIL_BALLAST", // Gleisschotter (GS)
   RECYCLING_MATERIAL = "InstallationCaseMaterial.RECYCLING_MATERIAL", // Recyclingmaterial (RC)
   BRICK_MATERIAL = "InstallationCaseMaterial.BRICK_MATERIAL", // Ziegelmaterial (ZM)
   BLAST_FURNACE_SLAG = "InstallationCaseMaterial.BLAST_FURNACE_SLAG", // Hochofenstückschlacke (HOS)
   STEEL_MILL_SAND = "InstallationCaseMaterial.STEEL_MILL_SAND", // Hüttensand (HS)
   STEEL_MILL_SLAG = "InstallationCaseMaterial.STEEL_MILL_SLAG", // Stahlwerksschlacke (SWS)
   FOUNDRY_CUPOLA_FURNACE_SLAG = "InstallationCaseMaterial.FOUNDRY_CUPOLA_FURNACE_SLAG", // Gießerei-Kupolofenschlacke (GKOS)
   COPPER_SMELTER_MATERIAL = "InstallationCaseMaterial.COPPER_SMELTER_MATERIAL", // Kupferhüttenmaterial (CUM)
   FOUNDRY_SAND = "InstallationCaseMaterial.FOUNDRY_SAND", // Gießereirestsand (GRS)
   HARD_COAL_BOILER_ASH = "InstallationCaseMaterial.HARD_COAL_BOILER_ASH", // Steinkohlenkesselasche (SKA)
   MELTING_CHAMBER_GRANULATE_HARD_COAL = "InstallationCaseMaterial.MELTING_CHAMBER_GRANULATE_HARD_COAL", // Schmelzkammergranulat aus der Schmelzkammerfeuerung von Steinkohle (SKG)
   HARD_COAL_FLY_ASH = "InstallationCaseMaterial.HARD_COAL_FLY_ASH", // Steinkohlenflugasche (SFA)
   BROWN_COAL_FLY_ASH = "InstallationCaseMaterial.BROWN_COAL_FLY_ASH", // Braunkohlenflugasche (BFA)
   HOUSEHOLD_WASTE_INCINERATOR_ASH = "InstallationCaseMaterial.HOUSEHOLD_WASTE_INCINERATOR_ASH",// Hausmüllverbrennungsasche (HMVA)
}

export enum BaseMaterialResult {
    NOT_APPLICABLE= "NOT_APPLICABLE",
    NOT_RELEVANT="NOT_RELEVANT",
    NOT_SATISFIED="NOT_SATISFIED"
}

export type IcResult = {
    categorisationClass: string,
    footnote: string
}

export type IcData = {
    resultList: IcResult[],
    materialName: string,
}
