<script lang="ts">

    import type {Writable} from "svelte/store";
    import {getContext} from "svelte";
    import Permission from "./Permission.svelte";

    const openDropdown: Writable<string> = getContext('openDropdown');

    // this is the title of the menu item
    // it is also used as an identifier in the context of the store to decide which menu is currently open
    export let title: string = "";
    export let url: string = "";
    export let permissions: string | null = null;
    export let openInNewTab: boolean = false;

    // determines if the dropdown menu is opened, if a dropdown menu exists
    let active: boolean = false;
    $:$openDropdown, active = $openDropdown === title;

    function handleDropdownTouch() {
        if (!active) {
            $openDropdown = title;
        } else {
            $openDropdown = "";
            active = false;
        }
    }

    function onMenuItemMouseEnter() {
        $openDropdown = title;
    }

    function onMenuItemMouseLeave() {
        $openDropdown = "";
        active = false;
    }

</script>

<Permission requiredPermissions={permissions}>
    {#if $$slots.default}
        <li role="menuitem" on:mouseenter={onMenuItemMouseEnter} on:mouseleave={onMenuItemMouseLeave}>
            <!-- To allow opening sub-menus on touch-devices, we prevent the click on the top-item and use a toggle logic to
             open/close submenus on touch input events -->
            <a class="nav__link dropdown__btn" class:active aria-haspopup="true"
               aria-expanded={active? "true" : "false"} aria-label={title} target="{openInNewTab ? '_blank' : '_self'}"
               href={url} on:touchstart|preventDefault on:touchend|preventDefault={handleDropdownTouch}>
                {title}
            </a>
            <slot {active}/>
        </li>
    {:else}
        <li>
            <a class="nav__link" href={url} on:click target="{openInNewTab ? '_blank' : '_self'}">{title}</a>
        </li>
    {/if}
</Permission>

<style lang="scss">
  @use "../../styles/menubar";

</style>

