<script lang="ts">
    import {t} from "../../i18n/i18n";
    import Basic from "../templates/Basic.svelte";
    import {DefaultLot, type Lot} from "../types/lot";
    import iconProjectCanceled from "@/icons/icon_pubpage_project_canceled.png";
    import iconQuoteTaken from "@/icons/icon_pubpage_quote_already_taken.png";

    import Salutation from "../atoms/Salutation.svelte";
    import ProjectInformationGrid from "../project/ProjectInformationGrid.svelte";
    import InfoCard from "../organisms/InfoCard.svelte";
    import TextArea, {textAreaHeights} from "../atoms/TextArea.svelte";
    import FileUpload from "../organisms/FileUpload.svelte";
    import SecondaryButton from "../atoms/SecondaryButton.svelte";
    import {ButtonIcons} from "../types/enums";
    import ProjectNavigationButtons from "../molecules/ProjectNavigationButtons.svelte";
    import {userIsLoggedIn} from "../stores";
    import PublicPageHeader from "../molecules/PublicPageHeader.svelte";
    import PublicPageFooter from "../molecules/PublicPageFooter.svelte";
    import {type Writable, writable} from "svelte/store";
    import {getContext} from "svelte";
    import type {RequestDto} from "../bid/types/requestDto";

    const lot: Writable<Lot> = getContext("lot") ?? writable(DefaultLot());
    const request: Writable<RequestDto> = getContext("request");

    export let isCanceled: boolean = false;
    export let isAlreadyTaken: boolean = false;
    export let requestId: string | undefined = $request?.publicId;

</script>


{#if !$userIsLoggedIn}
    <PublicPageHeader/>
{/if}

<Basic showStandardHeaderFooter={$userIsLoggedIn}>
    <div>
        <div class="blur-background-white"></div>
        <div class="blur-background-overlay scrollable">
            <div class="blur-background-overlay-content align-start">
                {#if isAlreadyTaken}
                    <span class="failed-title">{@html $t('UI.PubPageFailed.alreadyTaken.title')}</span>
                    <img class="failed-img" alt="Projekt bereits vergeben" src={iconQuoteTaken}>
                    <span class="failed-hint">{@html $t('UI.PubPageFailed.alreadyTaken.info')}</span>
                {/if}
                {#if isCanceled}
                    <span class="failed-title">{@html $t('UI.PubPageFailed.canceled.title')}</span>
                    <img class="failed-img" alt="Projekt storniert" src={iconProjectCanceled}>
                    <span class="failed-hint">{@html $t('UI.PubPageFailed.canceled.info')}</span>
                {/if}
                {#if requestId}
                    <ProjectNavigationButtons currentId={requestId}/>
                {/if}
            </div>
        </div>
        <Salutation name={$lot.personAddressableName} address={$lot.address.zip + " " + $lot.address.city}/>
        <div class="row">
            <div class="col-lg-5 col-md-12 col-sm-12">
                <InfoCard offererPage/>
            </div>
            <div class="col-lg-7 col-md-12 col-sm-12">
                <div class="container-project-information">
                    <ProjectInformationGrid title={$t('UI.project.information')} {...$lot}/>
                    <TextArea title={$t("UI.materialDetailsComment.header")} placeholder={$t('UI.remarks.noRemarks')}
                              height={textAreaHeights.SMALL} readonly/>
                    <TextArea title={$t("UI.projectDetailsComment.header")} placeholder={$t('UI.remarks.noRemarks')}
                              height={textAreaHeights.SMALL} readonly isHtml/>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 1.875rem">
            <div class="col-lg-12 col-sm-12">
                <FileUpload/>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-sm-12">
                <TextArea title={$t('UI.remarks.label')} text={$lot.remarks}
                          placeholder={$t("UI.pubRemarks.placeholder")}/>
                <div class="align-right" style="margin-top: 1.125rem">
                    <SecondaryButton label={$t("UI.saveRemark")} leftIcon={ButtonIcons.CHECKMARK}/>
                </div>
            </div>
        </div>
    </div>
</Basic>

{#if !$userIsLoggedIn}
    <PublicPageFooter/>
{/if}

<style lang="scss">
  @import "../../styles/animations";

  .blur-background-overlay {
    top: 100px;
    height: 100%;
    overflow-y: auto;
    z-index: 10000;
  }

  .failed-title {
    @include roboto-font(28px, 500, 1.5rem, black);
  }

  .failed-hint {
    @include roboto-font(18px, 400, 0.875rem, black);
    margin-bottom: 8rem;
    max-width: 550px;
    @media screen and (max-width: 768px) {
      max-width: 90%;
    }
  }

  .failed-img {
    @media screen and (max-width: 768px) {
      width: 33%;
      max-width: 200px;
    }
    @media screen and (max-height: 680px) {
      width: 33%;
      max-width: 200px;
    }
    margin-bottom: 1.75rem;
    margin-top: 1rem;
  }

  .align-start {
    @include flex-col($justify: flex-start);
    margin-top: 25vh;
  }

</style>