<script lang="ts">

    import type {MatchMarkerDto} from "../types/matchMarkerDto";
    import {MatchType} from "../../mmpv/types/substitutes";
    import {t} from "../../../i18n/i18n";
    import {createEventDispatcher} from "svelte";
    import iconKM from "@/images/kmIcon.svg";
    import iconTransporter from "@/images/transporterIcon.svg";
    import iconAttention from "@/images/attentionIcon.svg";
    import Tooltip from "../../atoms/Tooltip.svelte";

    const dispatch = createEventDispatcher()
    export let matchData: MatchMarkerDto;

    let matchType: MatchType = MatchType.MATCH;
    let backgroundColor: string = "white";

    function onMatchDataChange() {
        setMatchType();
        setBackgroundColor();
    }

    function setBackgroundColor() {
        switch (matchType) {
            case MatchType.RECOMMENDATION:
            case MatchType.SUBSTITUTE:
                backgroundColor = "#FFF6EE";
                break;
            case  MatchType.MATCH:
                backgroundColor = "white";
        }
    }

    function setMatchType() {
        if (matchData.isSubstituteMatch) {
            matchType = MatchType.SUBSTITUTE;
        } else if (matchData.isConditionalMatch) {
            matchType = MatchType.RECOMMENDATION;
        } else {
            matchType = MatchType.MATCH;
        }
    }

    function triggerRouteCalculating(objectId) {
        dispatch('triggerRouting', {endMarkerId: objectId});
    }

    $: matchData, onMatchDataChange();

</script>

<tr class="match-row" style="--backgroundColor: {backgroundColor}">
    <td colspan="4">
        <div>
            {#if matchType === MatchType.SUBSTITUTE || matchType === MatchType.RECOMMENDATION}
                <span class="match-row-header">
                    {$t(`UI.Map.MatchingTableRow.Header.${MatchType[matchType]}`)}
                </span>
            {/if}
            <a on:click={()=> triggerRouteCalculating(matchData.objectId)}>{matchData.label}</a>
        </div>
    </td>
</tr>
<tr class="sub-match-row" style="--backgroundColor: {backgroundColor}">
    <td>
        {#if matchData.price}
            <div style="text-align: left">
                {@html matchData.price}
            </div>
        {:else }
            <div style="overflow: overlay;">{$t('ProfilePrice.Price.isEmpty')}</div>
        {/if}
    </td>
    <td>
        <div class="match-data-padding-default" style="max-height: 15px">
            {@html matchData.transportCostDaily ? matchData.transportCostDaily : matchData.transportCost}
        </div>
        <Tooltip ttipIconImage={matchData.transportCostDaily ? iconTransporter : iconKM} ttipIconHeight={'18'} ttipIconWidth={'18'}
                 msg={matchData.transportCostDaily ? matchData.transportCostInfo : $t("Matching.alternateTransportCost.tooltip")} isHtml>
        </Tooltip>
    </td>
    <td>
        <div>
            {@html matchData.totalCostPerTon}
        </div>
    </td>
    <td>
        {#if matchData.economy}
            <div>
                {@html matchData.economy}
            </div>
        {:else}
            <div class:match-data-padding={matchData.price} style="padding-top: .3rem;padding-right: 1.9rem">
                {'—'}
            </div>
            {#if matchData.price}
                <div class="match-data-padding-default">
                    <Tooltip msg={$t("Matching.saving.tooltip") + "<br><br>" + matchData.calculationPath} isHtml
                             ttipIconImage={iconAttention} ttipIconHeight={'18'} ttipIconWidth={'18'}>
                    </Tooltip>
                </div>
            {/if}
        {/if}
    </td>
</tr>

<style lang="scss">
  @import "../../../styles/global";

  .match-row {
    background-color: var(--backgroundColor);

    & > td > div {
      @include flex-col(0.2rem, flex-start);
      padding: 0.2rem 0 0.5rem 0.3rem;
    }

    .match-row-header {
      padding-top: .2rem;
      @include roboto-font(0.875rem, 400, 0.725rem, #FDA62A);
    }

  }

  .sub-match-row {
    background-color: var(--backgroundColor);

    & > td {
      padding-bottom: 0.2rem;

      & > div {
        text-align: right;

      }
    }

    & > td:first-child {
      & > div {
        text-align: left;
      }
    }

    & > td:nth-child(2) {
      display: flex;
      float: right;
    }

    & > td:last-child {
      display: flex;
      float: right;
      padding-bottom: 0.45rem;

      & > div {
        padding-right: .5rem;
      }
    }

    & > td > div {
      @include roboto-font(0.875rem, 500, 0.725rem, black);
      padding-left: 0.3rem;
    }

    .match-data-padding {
      padding-right: .2rem !important;
    }

    .match-data-padding-default {
      padding-right: .3rem !important;
    }

  }

  th, td, tr {
    border: none;
    padding: 0 0.5rem 0 0.5rem;
    align-items: center;
  }

  a {
    @include roboto-font(0.875rem, 500, 0.7625rem, #557EBA);
    cursor: pointer;
    padding-top: 0.3rem;
  }

</style>

