<script lang="ts">
    import type {MaterialProperty, MaterialPropertyListElement} from "./types/materialProperty";
    import {
        DefaultMaterialProperty,
        DefaultMaterialPropertyListElement,
        MaterialPropertyView
    } from "./types/materialProperty";
    import MaterialPropertyListElementForm from "./MaterialPropertyListElementForm.svelte";
    import Breadcrumbs from "../organisms/Breadcrumbs.svelte";
    import MaterialPropertyForm from "./MaterialPropertyForm.svelte";
    import {t} from "../../i18n/i18n";
    import {ButtonIcons, TooltipPlacements} from "../types/enums";
    import {type Breadcrumb, breadcrumbs, closeModalOnOutsideClick, notifications} from "../stores";
    import {getContext, onMount, setContext} from "svelte";
    import {closeModal, onBeforeClose} from "svelte-modals";
    import {fly} from 'svelte/transition';
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import Icon from "../atoms/Icon.svelte";
    import Notifications from "../organisms/Notifications.svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import type {Writable} from "svelte/store";
    import Tooltip from "../atoms/Tooltip.svelte";
    import {changeUrl} from "../utils/misc";
    import type {Material} from "./types/material";
    import {MaterialPropertyHandler, MaterialPropertyListElementHandler} from "./util/materialPropertyModalUtil";
    import closeIcon from "@/icons/icon_close.svg";
    import {deepEqual} from "../utils/utils";

    const BREADCRUMBS_SIMPLE_POP_LENGTH = 3;
    setContext("useMaterialDesign", true);
    let formErrors: Writable<{ path: string, message: string }[]> = getContext("formErrors");

    let propertyParents: Writable<Map<string, string[]>> = getContext('materialPropertyParents');
    let properties: Writable<Map<string, MaterialProperty>> = getContext('materialProperties');

    export let view: MaterialPropertyView = MaterialPropertyView.MATERIAL_PROPERTY;
    export let parent: MaterialPropertyListElement | MaterialProperty | Material; // because we can step further and add breadcrumbs, we have the parent to directly add ids to the parent's list
    export let materialProperty: MaterialProperty;
    let materialPropertyCopy: MaterialProperty;
    export let materialId = undefined; // TODO check if it works to register top-level-property in material
    export let updateMaterial = undefined;

    let propertyElement: MaterialPropertyListElement;
    let propertyElementCopy: MaterialPropertyListElement;
    export let isNew: boolean = true;
    export let isOpen: boolean;
    let viewHandler: MaterialPropertyHandler | MaterialPropertyListElementHandler;
    let materialPropertyForm: MaterialPropertyForm, propertyElementForm: MaterialPropertyListElementForm; // components

    export let showCloseIcon = false;

    // these are functions, that need to be defined when calling openModal(ModalXYComponent, {title: ..., ...})
    export let acceptDisabled = false;

    let showOverlay: boolean = false;
    let overlayTitle: string = $t('UI.mmpv.materialPropertyModal.unsavedChanges.title');
    let overlayMessage: string = "Nachricht, die wir anzeigen wollen";
    let overlayButtonLabel: string = $t('UI.mmpv.materialPropertyModal.unsavedChanges.button.quit')
    let overlayAction = () => {
    }

    onBeforeClose(() => {
        $breadcrumbs = [];
        $formErrors = [];
        // by closing modal remove existing notifications
        $notifications = $notifications.filter(notification => notification.target === null)
    })

    onMount(() => {
        $closeModalOnOutsideClick = false;
        materialPropertyCopy = {...materialProperty};
        propertyElementCopy = {...propertyElement};
        updateViewVariables();
        $breadcrumbs.push({
            title: viewHandler.breadcrumbTitle,
            data: {
                view: MaterialPropertyView.MATERIAL_PROPERTY,
                materialProperty: {...materialProperty},
                materialPropertyCopy: {...materialPropertyCopy},
                parent: parent,
            },
            callback: (data, index: number) => changeToMaterialProperty(data.materialProperty, data.materialPropertyCopy, data.parent, true, index)
        })
        $breadcrumbs = $breadcrumbs; //reassign to update
        document.addEventListener('tooltipRedirect', checkChangesAndRedirectToProperty)
    });

    function updateViewVariables() {
        switch (view) {
            case MaterialPropertyView.MATERIAL_PROPERTY:
                viewHandler = new MaterialPropertyHandler(materialProperty, isNew, $propertyParents, $properties);
                break;
            case MaterialPropertyView.MATERIAL_PROPERTY_LIST_ELEMENT:
                viewHandler = new MaterialPropertyListElementHandler(propertyElement);
                break;
        }
    }

    function save() {
        switch (view) {
            case MaterialPropertyView.MATERIAL_PROPERTY:
                saveMaterialProperty();
                break;
            case MaterialPropertyView.MATERIAL_PROPERTY_LIST_ELEMENT:
                savePropertyListElement();
                break;
        }
    }

    /**
     * Saves a property list element.
     * Removes any unnecessary list item and updates the material property.
     */
    async function savePropertyListElement() {
        let validatedPropertyListElement: MaterialPropertyListElement = await propertyElementForm.saveMaterialPropertyListElement(propertyElement);
        if (validatedPropertyListElement !== null) {
            $breadcrumbs.pop(); // pop the list item, which we do not need anymore
            const topElement = $breadcrumbs.pop(); //pop the property to get the data
            $breadcrumbs.push(topElement); // re-add the property to have the correct breadcrumbs
            const data = topElement.data; // get the data of the topElement, so the materialProperty
            data.materialProperty.propertyListElements = data.materialProperty.propertyListElements.filter(
                propertyListElement => propertyListElement.id !== validatedPropertyListElement.id); // remove old PropertyListElement
            data.materialProperty.propertyListElements.push(validatedPropertyListElement); // replace it with the new PropertyListElement
            changeToMaterialProperty(data.materialProperty, data.materialPropertyCopy, data.parent, false);
            $breadcrumbs = $breadcrumbs; // reassign to update
        }
    }

    /**
     * Saves a material property.
     * If the property was saved with material context, it adds the property to the material.
     * Handles the breadcrumb for different conditions.
     */
    async function saveMaterialProperty() {
        const identifier = "modal";
        let id = await materialPropertyForm.saveMaterialProperty(materialProperty, identifier, materialId);
        if (id !== undefined && id !== null) {
            // do breadcrumbs things or add property to material to be able to save it
            if ($breadcrumbs.length >= BREADCRUMBS_SIMPLE_POP_LENGTH) { // we are at least in prop A > listItem > prop B
                $breadcrumbs.pop(); // we pop this property, because we saved it already
                // restore the old data
                let topElement: Breadcrumb = $breadcrumbs.pop();
                let prevProperty: Breadcrumb = $breadcrumbs.pop(); // we need the data of the older property to be able to add the list element to it
                // we do not want to remove the breadcrumb completely, just get the data
                $breadcrumbs.push(prevProperty);
                $breadcrumbs.push(topElement);
                $breadcrumbs = $breadcrumbs;
                const data = topElement.data;
                if (parent?.propertyChildIds) {// the parent is a listElement
                    if (!parent.propertyChildIds.includes(id)) {
                        parent.propertyChildIds.push(id)
                    }
                    if (!$propertyParents.has(id)) { // in case the property does not yet exist in the parents store, add it with an empty list
                        $propertyParents.set(id, [parent.propertyId]);
                    } else {
                        $propertyParents.set(id, $propertyParents.get(id).concat([parent.propertyId]));
                    }
                }
                changeToMaterialPropertyListElement(data.listElement, data.listElementCopy, data.parent, false);
            } else { // we are at last prop and thus can close the model, but need to check for material, i.e. if we need to directly register property in material
                // if property was saved with material context, add property to material
                if (materialId !== undefined && updateMaterial !== undefined) {
                    updateMaterial(id);
                } else {
                    changeUrl('/materialProperties', window)
                }
                closeModal();
            }
        } else {
            // reload property in store from server and refresh breadcrumbs and actual property with copy
            const refreshingPropertyId = $breadcrumbs[$breadcrumbs.length - 1].data.materialProperty.id;
            if (refreshingPropertyId != 'new') {
                await reloadProperty(refreshingPropertyId);
                $breadcrumbs[$breadcrumbs.length - 1].data.materialProperty = $properties.get(refreshingPropertyId);
                $breadcrumbs[$breadcrumbs.length - 1].data.materialPropertyCopy = $properties.get(refreshingPropertyId);
            }
            $breadcrumbs = $breadcrumbs;
        }
    }

    async function reloadProperty(propertyId: string) {
        await fetchUtils.get(`/api/property/get/${propertyId}`)
            .then((data) => {
                let propertyToUpdate = DefaultMaterialProperty();
                Object.assign(propertyToUpdate, data);
                $properties.set(propertyToUpdate.id, propertyToUpdate); // save new property to store
                materialProperty = {...propertyToUpdate};
                materialPropertyCopy = {...propertyToUpdate};
            }).catch(fetchUtils.catchErrorAndShowNotification());
        $properties = $properties;
    }

    /**
     * Pushes the material property breadcrumb.
     * Updates the stack top if necessary.
     * @param {boolean} updateStackTop - Indicate whether to update the stack top.
     * @param {Object} itemParent - Parent item.
     * @param {string} breadcrumbTitle - Title for the breadcrumb.
     */
    function pushMaterialPropertyBreadcrumb(updateStackTop: boolean, itemParent = parent, breadcrumbTitle: string = materialProperty.name) {
        // when we push the first item, we already have something about this item in the store, so we need to pop and push again with updated data
        // but we still have to keep the copy from when we first opened the element and not with the changes already
        let copy = {...materialPropertyCopy};
        if (updateStackTop) {
            // we must pop once to be able to push the updated data
            let top = $breadcrumbs.pop();
            copy = top.data.materialPropertyCopy;
        }
        let breadcrumb = {
            title: breadcrumbTitle,
            data: {
                view: MaterialPropertyView.MATERIAL_PROPERTY,
                materialProperty: {...materialProperty},
                materialPropertyCopy: copy,
                parent: {...itemParent},
            },
            callback: (data, index) => changeToMaterialProperty(data.materialProperty, data.materialPropertyCopy, data.parent, true, index)
        }
        $breadcrumbs.push(breadcrumb);
        $breadcrumbs = $breadcrumbs;
    }

    /**
     * Pushes the material property list element breadcrumb.
     * Updates the stack top if necessary.
     * @param {boolean} updateStackTop - Indicate whether to update the stack top.
     * @param itemParent - Parent item.
     */
    function pushMaterialPropertyListElementBreadcrumb(updateStackTop: boolean, itemParent = materialProperty): void {
        // when we push the first item, we already have something about this item in the store, so we need to pop and push again with updated data
        let copy = {...propertyElementCopy};
        if (updateStackTop) {
            // we must pop once to be able to push the updated data
            let top = $breadcrumbs.pop();
            copy = top.data.listElementCopy;
        }
        let breadcrumb = {
            title: propertyElement.name, // for list element, we always have the name
            data: {
                view: MaterialPropertyView.MATERIAL_PROPERTY_LIST_ELEMENT,
                listElement: {...propertyElement},
                listElementCopy: copy,
                parent: {...itemParent}
            },
            callback: (data, index) => changeToMaterialPropertyListElement(data.listElement, data.listElementCopy, data.parent, true, index)
        }
        $breadcrumbs.push(breadcrumb);
        $breadcrumbs = $breadcrumbs;
    }

    function pushPropertyAndGoToListElement(e) {
        pushMaterialPropertyBreadcrumb(true);
        isNew = false;
        propertyElement = {...DefaultMaterialPropertyListElement(), ...e.detail.listElement};
        propertyElementCopy = {...DefaultMaterialPropertyListElement(), ...e.detail.listElement};
        view = MaterialPropertyView.MATERIAL_PROPERTY_LIST_ELEMENT;
        updateViewVariables();
        pushMaterialPropertyListElementBreadcrumb(false);
    }

    /**
     * Pushes and navigates to the property.
     * @param {MaterialProperty} prop - The property object.
     * @param {boolean} isNewElement - Indicate whether it is a new element.
     */
    function pushAndGoToProperty(prop: MaterialProperty, isNewElement: boolean = false) {
        pushMaterialPropertyListElementBreadcrumb(true);
        changeToMaterialProperty(prop, prop, propertyElement, false);
        isNew = isNewElement;
        updateViewVariables()
        pushMaterialPropertyBreadcrumb(false, propertyElement, viewHandler.breadcrumbTitle)
    }

    /**
     * Changes to the material property, either by click on breadcrumb, saving or moving forward/registering new property.
     * @param {MaterialProperty} prop - The property object.
     * @param {MaterialProperty} propCopy - Copy of the property object.
     * @param parentData - Parent data.
     * @param {boolean} checkChanges - Indicate whether to check changes.
     * @param {number} breadcrumbIndex - Breadcrumb index.
     */
    function changeToMaterialProperty(prop: MaterialProperty, propCopy: MaterialProperty, parentData, checkChanges: boolean = false, breadcrumbIndex: number = -1) {
        if (checkChanges) {
            let noChangesDetected = handleClose(() => changeToMaterialProperty(prop, propCopy, parentData, false, breadcrumbIndex),
                breadcrumbIndex,
                $t('UI.mmpv.materialPropertyModal.unsavedChanges.action.breadcrumb'),
                $t('UI.mmpv.materialPropertyModal.unsavedChanges.button.navigate'))
            if (!noChangesDetected) {
                return;
            }
            isNew = false; // if we go backwards, we do not have a new item
        }
        if (breadcrumbIndex > -1) { // when we have a breadcrumb clicked and do not check changes, we need to pop the other breadcrumbs
            while ($breadcrumbs.length > breadcrumbIndex + 1) {
                $breadcrumbs.pop();
            }
            $breadcrumbs = $breadcrumbs;
        }
        view = MaterialPropertyView.MATERIAL_PROPERTY;
        materialProperty = {...prop};
        materialPropertyCopy = {...propCopy};
        parent = parentData; //FIXME

    }

    /**
     * Changes to the material property list element.
     * @param {MaterialPropertyListElement} listElement - The list element object.
     * @param {MaterialPropertyListElement} listElementCopy - Copy of the list element object.
     * @param {Object} parentData - Parent data.
     * @param {boolean} checkChanges - Indicate whether to check changes.
     * @param {number} breadcrumbIndex - Breadcrumb index.
     */
    function changeToMaterialPropertyListElement(listElement: MaterialPropertyListElement, listElementCopy: MaterialPropertyListElement, parentData, checkChanges: boolean = false, breadcrumbIndex: number = -1) {
        if (checkChanges) {
            let noChangesDetected = handleClose(() => changeToMaterialPropertyListElement(listElement, listElementCopy, parentData, false, breadcrumbIndex),
                breadcrumbIndex,
                $t('UI.mmpv.materialPropertyModal.unsavedChanges.action.breadcrumb'),
                $t('UI.mmpv.materialPropertyModal.unsavedChanges.button.navigate'));
            if (!noChangesDetected) {
                return;
            }
            isNew = false; // if we go backwards, we do not have a new item
        }
        if (breadcrumbIndex > -1) { // when we have a breadcrumb clicked and do not check changes, we need to pop the other breadcrumbs
            while ($breadcrumbs.length > breadcrumbIndex + 1) {
                $breadcrumbs.pop();
            }
            $breadcrumbs = $breadcrumbs;
        }
        view = MaterialPropertyView.MATERIAL_PROPERTY_LIST_ELEMENT;
        propertyElement = {...listElement};
        propertyElementCopy = {...listElementCopy};
        parent = parentData; //FIXME
    }


    /**
     * Process breadcrumb data based on the view
     * @param {*} breadcrumb
     * @return {Array} - list of element and its copy
     */
    function processBreadcrumbData(breadcrumb) {
        if (breadcrumb.data.view === MaterialPropertyView.MATERIAL_PROPERTY) {
            return [breadcrumb.data.materialProperty, breadcrumb.data.materialPropertyCopy]
        } else if (breadcrumb.data.view === MaterialPropertyView.MATERIAL_PROPERTY_LIST_ELEMENT) {
            return [breadcrumb.data.listElement, breadcrumb.data.listElementCopy]
        }
    }

    /**
     * Handle close action. Gives the overlayFun to the overlay in case there are changes made such that we have the
     * double-opt to really abort.
     * Return false if there are any changes made.
     * @param {Function} overlayFun - Function to execute.
     * @param {number} checkIndex - Index of breadcrumb to which we would navigate, -1 to close all.
     * @param {string} actionString - Action string.
     * @param {string} buttonString - Button label string.
     * @return {boolean} No changes made.
     */
    function handleClose(overlayFun, checkIndex: number, actionString: string, buttonString: string = $t('UI.mmpv.materialPropertyModal.unsavedChanges.button.quit')) {
        let editedItems = [];
        $breadcrumbs.forEach(breadcrumb => {
            if ($breadcrumbs.indexOf(breadcrumb) > checkIndex) {
                const [el, elCopy] = processBreadcrumbData(breadcrumb);

                let isUnedited = deepEqual(el, elCopy);
                if (!isUnedited) {
                    editedItems.push(el);
                }
            }
        });
        if (view === MaterialPropertyView.MATERIAL_PROPERTY) {
            const isPropertyUnedited = deepEqual(materialProperty, materialPropertyCopy);
            if (!isPropertyUnedited) {
                editedItems.push(materialProperty);
            }
        } else if (view === MaterialPropertyView.MATERIAL_PROPERTY_LIST_ELEMENT) {
            const isListElementUnedited = deepEqual(propertyElement, propertyElementCopy);
            if (!isListElementUnedited) {
                editedItems.push(propertyElement.name !== "" ? propertyElement : propertyElementCopy);
            }
        }
        // display overlay if there are unsaved changes
        if (editedItems.length > 0) {
            showOverlay = true;
            let editedItemNames: string[] = [];
            editedItems.forEach(item => {
                editedItemNames.push(item.name ? item.name : $t('UI.mmpv.materialPropertyModal.unsavedChanges.newItemWithoutName'));
            });

            overlayMessage = $t('UI.mmpv.materialPropertyModal.unsavedChanges.message', {
                list: [...new Set(editedItemNames.map(name => "<li style='margin-top: 8px;'>" + name + "</li>"))].join(''),
                userActionText: actionString
            });
            overlayAction = () => {
                overlayFun();
                showOverlay = false;
            };
            overlayButtonLabel = buttonString;
            return false;
        } else {
            return true;
        }
    }

    /**
     * Checks for any changes and redirects to the property details page based on the passed in event detail. For now,
     * this method is called from the tooltip with the info about the parents.
     *
     * This function first extracts the property id from the event detail. It then checks
     * for any changes using handleClose() and if there are changes
     * or handleClose() returns true, the modal is closed and the user is redirected to the property
     * page of the specified property id on the same host and protocol.
     *
     * @function checkChangesAndRedirectToProperty
     * @param {Event} e - The event object which contains the property id in its detail attribute.
     */
    function switchToNewPropertyOfListElement(e) {
        if (e.detail !== undefined) {
            pushMaterialPropertyBreadcrumb(true); // push the actual material property
            propertyElement = e.detail.listElement;
            pushMaterialPropertyListElementBreadcrumb(false, materialProperty);
            changeToMaterialProperty(DefaultMaterialProperty(), DefaultMaterialProperty(), propertyElement, false);
            isNew = true;
            updateViewVariables(); // to build the correct title, update the view variables
            pushMaterialPropertyBreadcrumb(false, propertyElement, viewHandler.breadcrumbTitle); // push the new property
        }
    }


    function checkChangesAndRedirectToProperty(e) {
        let id = e.detail;
        if (handleClose(() => {
            closeModal();
            window.location.href = window.location.protocol + "//" + window.location.host + "/materialProperty/" + id;
        }, -1, "Abbrechen & Zu Eigenschaft navigieren")) {
            closeModal();
            window.location.href = window.location.protocol + "//" + window.location.host + "/materialProperty/" + id
        }
    }


    function closeAndChangeUrlToProperties() {
        // reset the last two visited entities to before the changes
        materialProperty = {...materialPropertyCopy};
        propertyElement = {...propertyElementCopy};
        closeModal();
        if (materialId === undefined || updateMaterial === undefined) {
            changeUrl('/materialProperties', window)
        }
    }

    function closeMaterialPropertyModal() {
        if (handleClose(closeAndChangeUrlToProperties, -1, $t('UI.mmpv.materialPropertyModal.unsavedChanges.action.close'))) {
            closeAndChangeUrlToProperties();
        }
    }

    $: materialProperty, viewHandler?.buildMaterialPropertyParentsInfoTooltip();
    $: view, isNew, materialProperty.name, updateViewVariables(); // on changes to view execute updateViewVariables

</script>

{#if isOpen}
    <div role="dialog" class="modal" in:fly="{{ y: -1000, duration: 400 }}" out:fly={{y: -1000, duration: 400}}>
        <div class="contents" style="--content-padding: 3.13rem 3.5rem; max-width: 920px; width: 920px">
            <Notifications target={"modal"}/>
            {#if showCloseIcon}
                <div class="cancel-btn" on:click={closeMaterialPropertyModal}>
                    <Icon src={closeIcon} size={24}/>
                </div>
            {/if}
            {#if showOverlay}
                <div class="modal-overlay-content">
                    <div>
                        <span>{@html overlayTitle}</span>
                        <p>{@html overlayMessage}</p>
                    </div>
                    <div>
                        <SecondaryButton label={overlayButtonLabel} color={SecondaryBtnColors.RED}
                                         on:click={overlayAction} sizeAdaptingToText/>

                        <PrimaryButton label={$t('UI.mmpv.materialPropertyModal.button.continue')}
                                       color={PrimaryBtnColors.RED}
                                       on:click={() => {showOverlay = false}} sizeAdaptingToText/>
                    </div>
                </div>
            {:else}
                <Breadcrumbs/>
                <div class="sub-content-row">
                    <div class="material-property-modal-title">
                        <h2>{@html viewHandler.title}</h2>
                        {#if viewHandler.materialPropertyTooltip !== ""}
                            <Tooltip placementTtip={TooltipPlacements.RIGHT} msg={viewHandler.materialPropertyTooltip}
                                     isHtml showOnCreate removeCloseIconAfterClose appendToObject="parent"/>
                        {/if}
                    </div>
                    <span>{@html viewHandler.description}</span>
                </div>
                {#if view === MaterialPropertyView.MATERIAL_PROPERTY} <!-- create a new property -->
                    <MaterialPropertyForm bind:materialProperty bind:this={materialPropertyForm}
                                          on:pushListElementAndCreateNewProperty={switchToNewPropertyOfListElement}
                                          on:pushMaterialProperty={pushPropertyAndGoToListElement}/>
                {:else if view === MaterialPropertyView.MATERIAL_PROPERTY_LIST_ELEMENT}
                    <!-- change sub-properties of material property list element -->
                    <MaterialPropertyListElementForm bind:propertyElement bind:this={propertyElementForm}
                                                     selectedProperty={materialProperty}
                                                     on:addCreatePropertyBreadcrumb={() => pushAndGoToProperty(DefaultMaterialProperty(), true)}
                                                     on:editMaterialProperty={(e) => pushAndGoToProperty(e.detail.materialProperty)}/>
                {/if}
                <div class="actions">
                    <SecondaryButton label={$t('UI.button.cancel')} color={SecondaryBtnColors.GREY}
                                     on:click={closeMaterialPropertyModal}
                                     sizeAdaptingToText/>
                    <PrimaryButton label={$t('UI.button.save')} color={PrimaryBtnColors.GREEN} on:click={save}
                                   leftIcon={ButtonIcons.CHECKMARK} disabled={acceptDisabled}
                                   sizeAdaptingToText whiteIcon/>
                </div>
            {/if}
        </div>
    </div>
{/if}

<style lang="scss">
  @import "../../styles/modals"; // also includes global.scss

  h2 {
    width: auto;
    margin: 0;
  }

  div.material-property-modal-title {
    @include flex-row(0.5rem, $justify: flex-start);
    width: 100%;

    & li {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: $primaryBlue;
      }
    }
  }

  .actions {
    @include flex-row(0.375rem, $justify: flex-end);
    width: 100%;
    border-radius: 0 0 6px 6px;
    margin: 0;
  }

</style>

