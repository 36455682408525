<script lang="ts">
    /**
     * This component includes the actual analysis/analytic, its values and recommendations.
     * It receives the data from AnalyticalPage, which manages the reception of the data from the server and creates
     * necessary analytics.
     * The actual presentation of this data as well as saving that data is done here.
     */

    import {t} from "../../i18n/i18n";
    import {
        analyticalMetadata,
        analyticNames,
        getFormattedParameters,
        hideOptionalValues,
        loadRecommendations,
        parameterStore,
        values
    } from './stores'
    import {addRenderer} from 'svelecte';
    import {AnalyticalParentType, AnalyticalType, ButtonIcons, InputIcons} from "../types/enums";
    import {createEventDispatcher, onDestroy, onMount, setContext} from "svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import InputField from "../molecules/InputField.svelte";
    import Switch from "../atoms/Switch.svelte";
    import {inputFieldFormats} from "../utils/formatters";
    import PrimaryButton from "../atoms/PrimaryButton.svelte";
    import SecondaryButton from "../atoms/SecondaryButton.svelte";
    import EvaluationCard from "./EvaluationCard.svelte";
    import AnalysisTable from "./AnalysisTable.svelte";
    import {addNotification} from "../stores";
    import Recommendations from "./Recommendations.svelte";
    import ArbitraryValuesForm from "./ArbitraryValuesForm.svelte";
    import {debounce} from "../utils/utils";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import {AutocompleteConfig} from "../types/autocompleteFieldConfig";
    import {DefaultRobotoFontProps} from "../types/fontProps";
    import BasicWithSidebar from "../templates/BasicWithSidebar.svelte";
    import {type Unsubscriber, writable} from "svelte/store";
    import type {AnalyticDto} from "./types/analyticDto";
    import type {AnalyticalEvaluation, EvaluationResult, ValueResults} from "./types/analyticalEvaluation";
    import type {EvaluationParameter} from "./types/analyticalResponse";
    import {NotificationType} from "../types/notification";

    const dispatch = createEventDispatcher();
    setContext('useMaterialDesign', true);
    setContext('stickyHeightInformation', writable("30px"));

    export let editable = false;
    export let title: string = "-";
    export let analytic: AnalyticDto;
    export let description: string = "";
    export let expectedSatisfyingResult: string | undefined;
    export let parameters: EvaluationParameter[] = [];

    let unsubscribeParameters: Unsubscriber;

    let enableSave: boolean = false; // by default, we show no save button, we only show a save button when we have either a new or concrete analysis to save
    let isAnalysis: boolean = false; // isAnalysis -> true, if analysis; false, if analytic
    let searchTerm: string = '';

    let evaluationResult: Record<string, EvaluationResult>;
    let valueMetadata: any;
    let valueResults: ValueResults;
    let valueFootnotes: any;
    let {parentType, analyticalId, analyticalType, parentId} = $analyticalMetadata; // get all the analytical metadata from the store

    onMount(async () => {
        unsubscribeParameters = parameterStore.subscribe((_) => updateEvaluation())
    });

    onDestroy(() => {
        unsubscribeParameters();
    });

    const deactivateOptionalHidingAndReload = async () => {
        $hideOptionalValues = false;
        reload();
    }

    function reload() {
        dispatch("reload");
    }

    const updateEvaluation = debounce(() => {
        if (!analytic) {
            return;
        }
        // store values in the session storage only for analysis check
        if (parentType === AnalyticalParentType.NONE || parentId === "-1") {
            window.sessionStorage.setItem("analyticalValues", JSON.stringify($values));
        }
        let parameters = [...getFormattedParameters()];
        fetchUtils.post(`/api/analytics/evaluateAnalysis`, {
            analyticTechnicalName: analytic.technicalName,
            parameters: parameters,
            values: $values
        }).then((data: AnalyticalEvaluation) => {
            evaluationResult = data.combinedResult;
            $analyticNames = data.analyticNames;
            valueMetadata = data.valueMetadata;
            valueResults = data.valueResults;
            valueFootnotes = data.valueFootnotes;
        })
        loadRecommendations();
    })


    /**
     * Saves values to the server, if save is enabled. Disabled e.g. for the analytical check.
     */
    const saveValues = () => {
        if (enableSave) {
            const parameters = [...getFormattedParameters()];
            const url = `/api/${analyticalType?.toLowerCase()}/${analyticalId}/update`
            let payload = {
                description,
                parameters,
                values: $values,
                parentType
            };
            fetchUtils.post(url, payload)
                .then(() => {
                    addNotification({
                        message: $t("Analytics.tool.saved.success"),
                        type: NotificationType.SUCCESS
                    })
                })
        }
    }

    /**
     * Redirects the user back to the previous page or to the '/projects' page if no origin is specified.
     */
    const redirectBack = () => {
        // in case we come from another page, e.g the FAK, we have a referrer/origin specified
        const urlParams = new URLSearchParams(window.parent.location.search);
        if (urlParams.has("origin")) {
            // we should always give an origin, even when creating an analysis or analytic
            window.parent.location.href = decodeURIComponent(urlParams.get("origin") as string);
        } else {
            window.parent.location.href = `/projects`;
        }
    }

    const addParameterRenderer = (parameter: string) => {
        function renderer(item, _: boolean) {
            // noinspection TypeScriptValidateTypes
            return $t(`Analytics.evaluationParameter.${parameter}.${item.text}`);
        }

        addRenderer(parameter + '-render', renderer);
        return renderer;
    }

    function isSaveEnabled() {
        // !! makes sure it is a boolean
        // saveEnabled, if parent data valid and not empty OR no invalid analytical id
        // AND must be editable
        return !!((parentId && parentId !== "-1"
                    && parentType && parentType !== AnalyticalParentType.NONE)
                || (analyticalId && analyticalId !== "-1"))
            && editable;
    }

    $: if (valueResults) {
        for (let i in $values) {
            let value = $values[i];
            value.evaluation = valueResults[value.key];
            value.footnote = valueFootnotes[value.key];
        }
        $values = $values;
    }
    $: $analyticalMetadata, enableSave = isSaveEnabled();
    $: $analyticalMetadata, {parentType, analyticalId, analyticalType, parentId} = $analyticalMetadata; // whenever the store changes, update the variables
    $: isAnalysis = analyticalType === AnalyticalType.ANALYSIS;

</script>

<BasicWithSidebar sidebarOpened={isAnalysis}>
    <div class="analytic-container" slot="main-content">
        <div class="analytic-col">
            <h1 id="analytic-header" style="padding-bottom: 0.37rem;">{title}</h1>
            <h2>{$t(isAnalysis ? 'Analytics.evaluation' : 'Analytics.testMethod')}: {analytic?.name}</h2>
            <!-- Input field to give a name to the analysis -->
            {#if isAnalysis && parentType !== AnalyticalParentType.NONE && analyticalId && analyticalId !== "-1"}
                <InputField bind:value={description} readOnly={!editable} format={inputFieldFormats.FULL}
                            fontProps={DefaultRobotoFontProps('0.875rem', '0.75rem')}
                            label={$t('Analytics.description')}/>
            {/if}

            {#if analytic}
                <!-- for analysis, we make the parameters selectable, therefore we show autocomplete fields -->
                {#if isAnalysis}
                    {#each Object.entries(analytic?.requiredParameters) as [parameter, parameterValues], i}
                        <!-- had to do it this way, as we are in an each loop -->
                        <AutocompleteInputField bind:value={$parameterStore.parameters[parameter]}
                                                label={$t(`Analytics.evaluationParameter.${parameter}`)}
                                                autocompleteConfig={AutocompleteConfig(null,null,false,!editable)}
                                                options={parameterValues}
                                                containerWidth={inputFieldFormats.SEARCH.maxWidth}
                                                renderer={addParameterRenderer(parameter)}
                                                on:change={loadRecommendations}/>
                    {/each}
                {:else}
                    <!-- for analytics, we only show the selected expected result and the selected parameters as text -->
                    {#if expectedSatisfyingResult && expectedSatisfyingResult.toLowerCase() !== "eingehalten"}
                        <div>
                            <b>{$t("Analytics.desiredResult")}:</b><br/>
                            {expectedSatisfyingResult}
                        </div>
                    {/if}
                    {#each parameters.filter(param => param.value) as param}
                        <div>
                            <b>{$t(`Analytics.evaluationParameter.${param.name}`)}:</b><br/>
                            {$t(`Analytics.evaluationParameter.${param.name}.${param.value}`) ?? "-"}
                        </div>
                    {/each}
                {/if}
            {/if}
        </div>

        <!-- top row including switch to hide optional values, change the procedure for analysis check and search for
        values in the list of values -->
        <div class="analytic-col">
            <h2>{$t('UI.filters')}</h2>
            <div class="analytic-row">
                <div class="filter-box-optional">
                    <span>{$t('Analytics.switch.hideOptional')}</span>
                    <Switch bind:checked={$hideOptionalValues}/>
                </div>
                <div>
                    {#if parentType === AnalyticalParentType.NONE && parentId && parentId === "-1" }
                        <PrimaryButton label={$t("UI.analytic.changeProcedure.btn")}
                                       titleAttr={$t("UI.analytic.changeProcedure.btn.titleAttr")}
                                       sizeAdaptingToText
                                       on:click={() => dispatch('changeProcedure')}/>
                    {/if}
                    <InputField bind:value={searchTerm} placeholder={$t('UI.placeholder.search')}
                                materialWidth={inputFieldFormats.SEARCH.maxWidth} icon={InputIcons.SEARCH_LENS}
                                containerWidth="auto"/>
                </div>
            </div>
        </div>

        {#if isAnalysis}
            <div class="sticky-top">
                <EvaluationCard {evaluationResult} {valueMetadata}/>
            </div>
        {/if}

        <div class:noAnalysis={!isAnalysis}>
            <AnalysisTable {analytic} {searchTerm} {editable}
                           on:valuesRemoved={reload} on:updateValue={updateEvaluation}/>
        </div>

        <!-- bottom row including back button and save button, if save enabled -->
        <div class="analytic-row">
            <SecondaryButton label={$t('UI.button.back')} leftIcon={ButtonIcons.LEFT_ARROW} iconSize="24px"
                             on:click={redirectBack}/>
            {#if enableSave}
                <PrimaryButton id="button_save_analysis" label={$t('UI.button.save')} disabled={!editable}
                               leftIcon={ButtonIcons.CHECKMARK_BOLD} whiteIcon
                               on:click={saveValues}/>
            {/if}
        </div>
    </div>


    <div slot="sidebar-content" class="analytical-sidebar-content">
        {#if isAnalysis}
            <Recommendations on:valuesChanged={deactivateOptionalHidingAndReload} {editable}/>
        {/if}
        <!--        only show the values if we are not in the tool-->
        {#if editable && parentType !== AnalyticalParentType.NONE && analyticalId && analyticalId !== "-1"}
            <ArbitraryValuesForm on:valuesChanged={deactivateOptionalHidingAndReload}/>
        {/if}
    </div>
</BasicWithSidebar>

<style lang="scss">
  @import "../../styles/global";

  .analytic-container {
    @include flex-col($row-gap: 1.88rem, $alignment: normal, $justify: normal);
    padding-bottom: 2rem;
    width: 100%;
  }

  .analytic-col {
    @include flex-col($row-gap: 0.88rem, $alignment: normal, $justify: normal);
  }

  .analytic-row {
    @include flex-row($justify: space-between);
    flex-wrap: wrap;
    row-gap: 0.88rem;
  }

  .filter-box-optional {
    @include flex-row($justify: space-between);
    @include roboto-font(normal, 400, 0.75rem);
    width: 15.9375rem;
    height: 2rem;
    border: 1px solid #D9D9D9;
    border-radius: 0.125rem;
    padding-left: 0.75rem;
    padding-right: 0.87rem;

    & ~ div {
      @include flex-row(0.88rem);
    }
  }

  .sticky-top {
    position: sticky;
    /* Height of header */
    top: 3.813rem;
    /* Add padding to hide content "behind" card */
    padding-top: 1.88rem;
    padding-bottom: 1.88rem;
    /* Negate the padding with this margin so we don't double the row-gap value */
    margin-top: -1.88rem;
    margin-bottom: -1.88rem;
    z-index: 1;
    background-color: #fff;
  }

  div.noAnalysis :global(tr.analysis-table-header) {
    --sticky-header-top: 10px; // top space for sticky
    --sticky-header-top-padding: 61px; // height of menubar
  }

  .analytical-sidebar-content {
    @include flex-col($row-gap: 1.88rem, $alignment: normal, $justify: normal);
    padding-bottom: 2rem;
  }
</style>
