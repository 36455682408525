<script lang="ts">
    import {t} from "../../i18n/i18n";
    import type {Lot} from "../types/lot";
    import {DefaultLot} from "../types/lot";
    import Salutation from "../atoms/Salutation.svelte";
    import ProjectInformationGrid from "../project/ProjectInformationGrid.svelte";
    import InfoCard from "../organisms/InfoCard.svelte";
    import TextArea, {textAreaHeights} from "../atoms/TextArea.svelte";
    import FileUpload from "../organisms/FileUpload.svelte";
    import SecondaryButton from "../atoms/SecondaryButton.svelte";
    import {ButtonIcons} from "../types/enums";
    import UnderConstruction404 from "../organisms/UnderConstruction404.svelte";
    import {setContext} from "svelte";
    import {writable} from "svelte/store";
    import Basic from "../templates/Basic.svelte";


    const lot: Lot = DefaultLot();
    setContext("lot", writable(lot));
    export let description: string = $t('UI.Page404.description');

</script>

<Basic>
    <UnderConstruction404 {description}/>
    <Salutation name={lot.personAddressableName} address={lot.address.zip + " " + lot.address.city}
                lotType={lot.type}/>
    <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12">
            <InfoCard offererPage/>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12">
            <div class="container-project-information">
                <ProjectInformationGrid title={$t('UI.project.information')} {...lot}/>
                <TextArea title={$t("UI.materialDetailsComment.header")} placeholder={$t('UI.remarks.noRemarks')}
                          text="" height={textAreaHeights.SMALL} readonly/>
                <TextArea title={$t("UI.projectDetailsComment.header")} placeholder={$t('UI.remarks.noRemarks')}
                          text="" height={textAreaHeights.SMALL} readonly/>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 1.875rem">
        <div class="col-lg-12 col-sm-12">
            <FileUpload/>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-sm-12">
                <TextArea title={$t('UI.remarks.label')} text={lot.remarks}
                          placeholder={$t("UI.pubRemarks.placeholder")}/>
            <div class="align-right" style="margin-top: 1.125rem">
                <SecondaryButton label={$t("UI.saveRemark")} leftIcon={ButtonIcons.CHECKMARK}/>
            </div>
        </div>
    </div>
</Basic>

<style lang="scss">
  @import "../../styles/pubPage";


</style>