<script lang="ts">
    import LotHeader from "./LotHeader.svelte";
    import AddLotItem from "./AddLotItem.svelte";
    import {currentProject, localLotsStore} from "../utils/fakStores";
</script>

<ul class="lots-accordion" id="lots-accordion-list">
    {#if !$currentProject.isSaasProject}
        <AddLotItem/>
    {/if}

        {#each Array.from($localLotsStore, (data) => {return {id: data[0], lot: data[1]}}) as lotData}
            <LotHeader bind:lot={lotData.lot}/>
        {/each}
</ul>

<style lang="scss">

  .lots-accordion {
    width: 450px;
    padding-left: 0;
    list-style: none;
    /* TODO: Still needed? Default values used when form opened on level > 1 before correct calculation is triggert */
    margin-top: 365px;
    margin-bottom: 365px;
    transition-property: margin-bottom, margin-top;
    transition-duration: 500ms;
    transition-timing-function: ease-out;
  }

</style>