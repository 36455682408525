<script lang="ts">
    import ContactBox from "../atoms/ContactBox.svelte";
    import mmLogo from '@/images/mm_logo_text.png';
    import {fetchRequestTenantLogo} from "../corporateDesign/corporateDesignUtils";
    import type {Writable} from "svelte/store";
    import {getContext} from "svelte";

    export let elevated: boolean = false;
    export let requestPublicId: string = "";

    let isSaasInternalLot: Writable<boolean | undefined> = getContext("isSaasInternalLot");
    let logo: string = "";

    // This handling is necessary, since the context store is updated later than the component is build, so we might
    // see the mmLogo accidentally for a split second on load. The store is undefined until the lot is loaded (except
    // for offerer pages, where the value is always false).
    async function updateLogo() {
        if ($isSaasInternalLot === undefined) {
            logo = "";
        } else if ($isSaasInternalLot === true && requestPublicId) {
            logo = await fetchRequestTenantLogo(requestPublicId) ?? "";
        } else {
            logo = mmLogo;
        }
    }

    $: if (requestPublicId && $isSaasInternalLot === true || $isSaasInternalLot === false) {
        updateLogo();
    }
</script>


<div class="pp-header" class:elevated>
    <div></div>
    <div class="pp-header-logo">
        {#if logo}
            <img alt="Logo" src={logo}>
        {/if}
    </div>
    <div class="pp-header-info">
        {#if $isSaasInternalLot === false}
            <ContactBox/>
        {/if}
    </div>
</div>


<style lang="scss">
  @import "../../styles/global";

  .pp-header {
    position: sticky;
    top: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 100px;
    grid-column-gap: 2rem;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    width: 100%;
    z-index: 3;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      & > div:first-child, & > div:last-child {
        display: none;
      }
    }
  }

  .pp-header-logo {
    max-width: 100%;

    & > img {
      max-height: 57px;
      max-width: 90%;
    }

    @include flex-row();
  }

  .pp-header-info {
    @include flex-col();
    align-items: flex-end;
    margin-right: 0.5rem;
  }

  .elevated {
    z-index: 1002;
    position: fixed;
    bottom: 0;
  }

</style>