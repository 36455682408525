export enum MatchOutcome {
    MATCH = "MATCH",
    CONDITIONAL_MATCH = "CONDITIONAL_MATCH",
    NO_MATCH = "NO_MATCH",
    IGNORE = "IGNORE",
}

export namespace MatchOutcome {
    export function isAtLeastConditionalMatch(matchOutcome: MatchOutcome) {
        switch (matchOutcome) {
            case MatchOutcome.MATCH,
                MatchOutcome.CONDITIONAL_MATCH,
                MatchOutcome.IGNORE:
                return true;
            default:
                return false
        }
    }
}
