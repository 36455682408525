<script lang="ts">
    import InputField from "../molecules/InputField.svelte";
    import {inputFieldFormats} from "../utils/formatters";
    import type {FontProps} from "../types/fontProps";
    import {DefaultRobotoFontProps} from "../types/fontProps";

    // type Item = $$Generic;

    export let addonText: string = '';
    export let label: string = '';
    export let ttText: string = '';
    export let format = inputFieldFormats.NORMAL;
    export let placeholder: string = "";
    export let isNumeric: boolean = false;
    export let unit: string = "";
    export let forcedDecimalPlaces: number = 0;

    export let editable: boolean = true;

    export let row; /*: BodyRow<Item>*/
    export let column; /*: DataColumn<Item>*/
    export let value; /*: unknown;*/
    export let onUpdateValue; /*: (rowDataId: string, columnId: string, newValue: unknown) => void*/

    export let errorHighlight = false;

    let editingValue;

    $: editingValue = value;

    let fontProps: FontProps = DefaultRobotoFontProps('0.875rem', '0.75rem');

    const handleSubmit = () => {
        if (row.isData()) {
            onUpdateValue(row.dataId, column.id, editingValue);
        }
    };
</script>

<div>
    <InputField bind:value={editingValue} {label} {addonText} {ttText} {isNumeric} {format} {placeholder} {unit}
                {fontProps} {errorHighlight}
                readOnly={!editable} {forcedDecimalPlaces} on:input={handleSubmit}></InputField>
</div>

<style>
</style>