<script lang="ts">
    import DropdownMenuSectionTitle from "./DropdownMenuSectionTitle.svelte";
    import Permission from "../Permission.svelte";

    // title for this section, given to a DropdownMenuSectionTitle
    export let title: string = "";
    export let permissions: string | null = null;
</script>

<Permission requiredPermissions={permissions}>
    <ul class="dropdown__menu-section scrollable" role="menu">
        {#if title && title !== ""}
            <DropdownMenuSectionTitle {title}/>
        {/if}
        <slot></slot>
    </ul>
</Permission>

<style lang="scss">
  @use "../../../styles/menubar";
</style>
