<script lang="ts">
    import {AnimateSharedLayout, Motion, MotionConfig} from "svelte-motion";
    import type {Project} from "../../../types/project";
    import {copyProject, projectSchema} from "../../../types/project";
    import type {AutocompleteFieldConfig} from "../../../types/autocompleteFieldConfig";
    import {getFormAcConfigFetch} from "../../../types/autocompleteFieldConfig";
    import {t, translate} from "../../../../i18n/i18n";
    import ProjectStatus from "../../../molecules/ProjectStatus.svelte";
    import InputField from "../../../molecules/InputField.svelte";
    import {inputFieldFormats} from "../../../utils/formatters";
    import type {FontProps} from "../../../types/fontProps";
    import {DefaultInputFieldProps} from "../../../types/fontProps";
    import PrimaryButton from "../../../atoms/PrimaryButton.svelte";
    import SecondaryButton from "../../../atoms/SecondaryButton.svelte";
    import {ButtonIcons} from "../../../types/enums";
    import DatePickerInput from "../../../atoms/DatePickerInput.svelte";
    import {getContext, onMount} from "svelte";
    import dayjs from "dayjs";
    import PersonAndCompanyDataAutocomplete from "../../../autocomplete/PersonAndCompanyDataAutocomplete.svelte";
    import {addNotification} from "../../../stores";
    import AutocompleteInputField from "../../../autocomplete/AutocompleteInputField.svelte";
    import type {Writable} from "svelte/store";
    import {getFormErrors} from "../../../utils/formValidation";
    import {closeModal, openModal} from "svelte-modals";
    import Modal from "../../../organisms/Modal.svelte";
    import {fetchUtils} from "../../../utils/fetchUtils";
    import AddressDataAutocomplete from "../../../autocomplete/AddressDataAutocomplete.svelte";
    import {currentLot, currentProject, mapFormControls, projectAutocompleteVisible} from "../utils/fakStores";
    import type {PersonAndCompanyData} from "../../../types/personAndCompanyData";
    import {copyPersonAndCompanyData, DefaultPersonAndCompanyData} from "../../../types/personAndCompanyData";
    import {closeFAK, getUserAccountAcOptions} from "../utils/fakUtils";
    import {DefaultLot} from "../../types/lot";
    import {
        hideAutosaveInfo,
        showAutosaveCheckmark,
        showAutosaveCrossmark,
        showAutosaveLoading
    } from "../../../utils/autosaveInfoUtils.js";
    import {copyUserAccountData, DefaultUserAccountData, type UserAccountData} from "../../../types/userAccountData";
    import {jwt} from "../../../utils/jwt";
    import {createTippy} from "svelte-tippy";
    import {NotificationType} from "../../../types/notification";

    export let resizeObserver;

    /*font properties for all the input fields */
    const fontProps: FontProps = DefaultInputFieldProps();
    const autocompleteVisibleStore = projectAutocompleteVisible;
    const PROJECT_FORM_ERROR_PATH_PREFIX = 'project.';
    const PROJECT_AUTOSAVE_TIMEOUT = 2000;

    let formErrors: Writable<Array<any>> = getContext("formErrors");

    let loginUser: UserAccountData = DefaultUserAccountData();
    if ($jwt) {
        jwt.subscribe((claims: any) => {
            loginUser.id = Number(claims.uid);
            loginUser.firstName = claims.ufirstname;
            loginUser.lastName = claims.ulastname;
        })
    }


    let isNew = $currentProject.id === "new";
    $: isNew = $currentProject.id === "new";

    let startDate: number;
    let endDate: number;

    const caseOwnerAutocompleteConfig: AutocompleteFieldConfig = getFormAcConfigFetch('user-accounts', false, 'id', 'text', !$currentProject.isEditable);
    let caseOwnerId: any = null;
    let caseOwnerOptions;
    let rerenderTrigger = null;

    let contactPerson: PersonAndCompanyData = null;
    let personLink: string = null;
    let onboardingComment: string | null = null;


    initializeFieldsAndObjects();

    function initializeFieldsAndObjects() {
        if (isNew) {
            $currentProject.caseOwner = copyUserAccountData(loginUser);
        }
        startDate = $currentProject.startDate ? dayjs($currentProject.startDate, "YYYY-MM-DD").unix() : null;
        endDate = $currentProject.endDate ? dayjs($currentProject.endDate, "YYYY-MM-DD").unix() : null;
        caseOwnerId = $currentProject.caseOwner?.id && $currentProject.caseOwner.id !== null ? $currentProject.caseOwner.id : null;
        // if we have a user hash from autocompletion, then select it
        caseOwnerOptions = getUserAccountAcOptions($currentProject.caseOwner);
        rerenderTrigger = {};
        contactPerson = $currentProject.contactPersonAndCompany ? $currentProject.contactPersonAndCompany : null;
        personLink = $currentProject.contactPersonAndCompany && $currentProject.contactPersonAndCompany.link ? $currentProject.contactPersonAndCompany.link : null;
        onboardingComment = $currentProject.contactPersonAndCompany && $currentProject.contactPersonAndCompany.onboardingComment ? $currentProject.contactPersonAndCompany.onboardingComment : null;
    }

    addEventListener("saveProject", autosaveProjectHandler);

    let timeout: number;

    /* Starts the autosave process where the actual save function is called only after a specified timeout when no more
    changes are made. */
    function autosaveProjectHandler() {
        if (!contactPerson) {
            $currentProject.contactPersonAndCompany = DefaultPersonAndCompanyData();
        } else {
            $currentProject.contactPersonAndCompany = copyPersonAndCompanyData(contactPerson);
        }
        $currentProject.contactPersonAndCompany.link = personLink;
        $currentProject.contactPersonAndCompany.onboardingComment = onboardingComment;

        if ($currentProject && $currentProject.id !== "new") {
            $mapFormControls.saving = true;
            showAutosaveLoading();
            clearTimeout(timeout);
            timeout = setTimeout(() => validateAndSaveProject(copyProject($currentProject)), PROJECT_AUTOSAVE_TIMEOUT);
        }
    }

    async function validateAndSaveProject(projectToSave: Project) {
        projectToSave.startDate = startDate ? dayjs.unix(startDate).add(12, 'h').toISOString() : null;
        projectToSave.endDate = endDate ? dayjs.unix(endDate).add(12, 'h').toISOString() : null;

        $formErrors = [{path: '', message: ''}];

        await projectSchema.validate(projectToSave, {
            stripUnknown: true,
            abortEarly: false
        }) // stripUnknown strips fields that are not stated in the validator
            .then(async validatedProject => {
                await fetchUtils.post(`/api/project/${validatedProject.id}/update`, validatedProject, false)
                    .then(data => {
                        $currentProject = copyProject(data);
                        initializeFieldsAndObjects();
                        if (validatedProject.id === "new") {
                            let notification = {
                                message: translate('de', 'UI.map.project.new.success', []),
                                type: NotificationType.SUCCESS,
                                dismissible: true,
                                timeout: 5000
                            };
                            addNotification(notification);
                            $currentLot = DefaultLot();
                        } else {
                            showAutosaveCheckmark("(Projekt " + $currentProject.code + ")");
                        }
                    })
                    .catch(e => {
                        fetchUtils.catchErrorAndShowNotification()(e);
                        hideAutosaveInfo();
                        if (e.data) {
                            $currentProject = copyProject(e.data);
                            initializeFieldsAndObjects();
                        }
                    })
            })
            .catch(error => {
                $formErrors = getFormErrors(error, PROJECT_FORM_ERROR_PATH_PREFIX);
                showAutosaveCrossmark("Projekt " + $currentProject.code);
            });
        $mapFormControls.saving = false;
    }

    function cancel() {
        openModal(Modal, {
            title: $t("UI.mapForm.close.modal.title"),
            denyText: $t("UI.mapForm.close.modal.confirm"),
            message: $t("UI.mapForm.close.modal.desc"),
            onDeny: () => {
                closeModal();
                closeFAK();
            }
        });
    }


    function saveAddressToClipboard() {
        navigator.clipboard.writeText($currentProject?.address?.city + " " + $currentProject?.address?.zip + " " +
            $currentProject?.address?.street);
    }

    const copyTippy = createTippy({
        animation: 'scale-extreme',
        content: $t("UI.addressData.copy"),
        delay: [100, 200],
        hideOnClick: true,
        interactive: true,
        maxWidth: 367,
        placement: 'left',
        theme: 'mmttip',
        appendTo: document.getElementsByClassName('fak-base-layout')[0],
    });

    onMount(() => {
        if (resizeObserver) {
            resizeObserver.observe(document.getElementById('project-form-container'));
        }
    })

</script>

<MotionConfig transition={{duration: 0.6, delay: 0.1, type: 'spring', stiffness: 80, damping: 9, mass: 0.55}}> <!--type: tween is also very smooth and does
not have the bouncing at the end for the address search box-->
    <AnimateSharedLayout>
        <Motion let:motion>
            <div class="project-form-container" id="project-form-container" use:motion>
                <div class="project-main-data">
                    {#if isNew}
                        <span>{$t("UI.map.project.create.header")}</span>
                    {:else }
                        <ProjectStatus state={$currentProject.state}
                                       label={$currentProject.label}
                                       projectId={$currentProject.id}
                                       code={$currentProject.code}
                        />
                    {/if}
                    <div>
                        <div style="grid-area: projectName">
                            <InputField bind:value={$currentProject.projectName} id="project-name"
                                        format={inputFieldFormats.FULL}
                                        placeholder={$t('UI.map.project.name.ph')}
                                        {fontProps} readOnly={!$currentProject.isEditable}
                                        errorPath={PROJECT_FORM_ERROR_PATH_PREFIX + 'projectName'}
                                        required on:focusout={autosaveProjectHandler}/>
                        </div>
                        <div style="grid-area: caseOwner">
                            {#key rerenderTrigger}
                                <AutocompleteInputField bind:value={caseOwnerId} bind:options={caseOwnerOptions}
                                                        bind:selectedObject={$currentProject.caseOwner}
                                                        id="project-case-owner"
                                                        {fontProps} autocompleteConfig={caseOwnerAutocompleteConfig}
                                                        label={$t('UI.map.project.caseOwner.ph')}
                                                        errorPath={PROJECT_FORM_ERROR_PATH_PREFIX + 'caseOwner.*'}
                                                        required on:change={autosaveProjectHandler}/>
                            {/key}
                        </div>
                        <div style="grid-area: contactPerson">
                            <PersonAndCompanyDataAutocomplete bind:contactPerson bind:onboardingComment
                                                              bind:personLink isNew={$currentProject.id === "new"}
                                                              personInputId="project-contact-person"
                                                              {fontProps} {autocompleteVisibleStore}
                                                              company={$currentProject.contactPersonAndCompany}
                                                              outerId={$currentProject.id}
                                                              errorPath={PROJECT_FORM_ERROR_PATH_PREFIX + 'contactPerson'}
                                                              isEditable={$currentProject.isEditable}
                                                              required
                                                              on:change={autosaveProjectHandler}/>
                            <!-- no need to append '.*' to path here, is already done in component itself + we need the path for the separated subfields -->
                        </div>
                    </div>
                </div>
                <div class="project-data-row">
                    <span>{$t('UI.map.timeframe')}</span>
                    <div>
                        <DatePickerInput bind:date={startDate} id="project-select-start-date" {fontProps} label=""
                                         format={inputFieldFormats.FULL}
                                         placeholder={$t('UI.startDate.label')} disabled={!$currentProject.isEditable}
                                         errorPath={PROJECT_FORM_ERROR_PATH_PREFIX + 'startDate'}
                                         on:change={autosaveProjectHandler}/>
                        <DatePickerInput bind:date={endDate} id="project-select-end-date" {fontProps} label=""
                                         format={inputFieldFormats.FULL}
                                         placeholder={$t('UI.endDate.label')} disabled={!$currentProject.isEditable}
                                         errorPath={PROJECT_FORM_ERROR_PATH_PREFIX + 'endDate'}
                                         on:change={autosaveProjectHandler}/>
                    </div>
                </div>
                <div class="project-data-row" style="row-gap: 0.25rem">
                    <span class="execution-place-header">
                        <span class="execution-place-header-text">{$t('UI.map.executionPlace')}</span>
                        <span class="duplicate-address-icon" on:click={saveAddressToClipboard} use:copyTippy>
                        </span>
                    </span>
                    <div>
                        <AddressDataAutocomplete bind:addressData={$currentProject.address} {fontProps}
                                                 outerId={$currentProject.id} isEditable={$currentProject.isEditable}
                                                 errorPath={PROJECT_FORM_ERROR_PATH_PREFIX + 'addressData'}
                                                 idPrefix="project" required
                                                 on:change={autosaveProjectHandler}/>
                        <!-- no need to append '.*' to path here, is already done in component itself + we need the path for the separated subfields -->
                    </div>
                </div>
                {#if isNew}
                    <div class="new-project-buttons">
                        <SecondaryButton label={$t('UI.button.cancel')} paddingHorizontal="1.375rem"
                                         paddingTop="0.875rem" sizeAdaptingToText
                                         on:click={cancel}/>

                        <PrimaryButton id="create-project-button" label={$t('UI.map.project.form.createProject')}
                                       leftIcon={ButtonIcons.PLUS_BOLD} iconSize="24px"
                                       paddingHorizontal="1.375rem" paddingTop="0.875rem"
                                       sizeAdaptingToText whiteIcon
                                       on:click={() => {
                                               showAutosaveLoading();
                                               validateAndSaveProject($currentProject);
                                           }}/>
                    </div>
                {/if}
                <!--<div style="width: 100%;" class:invisible={!$currentProject.code || $currentProject.code === ""}>

                </div>-->
            </div>
        </Motion>
    </AnimateSharedLayout>
</MotionConfig>


<style lang="scss">
  @import "../../../../styles/global";
  @import "../../../../styles/autocomplete";

  .execution-place-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4px;
  }

  .execution-place-header-text {
    padding-top: 4px;
  }

  .project-form-container {
    background-color: white;
    border-radius: 0.25rem;
    padding: 1.625rem 1.25rem 1.625rem;
    width: 450px;
    max-width: 450px;
    @include flex-col(1.25rem, $justify: flex-start);
    position: relative; /*necessary for the close button */
  }

  .project-main-data {
    width: 100%;
    @include flex-col(1.25rem, $alignment: flex-start);

    & > span:first-child {
      @include roboto-font(19px, 400, 1rem, black);
    }

    & > div:not(:first-child) {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
              "projectName caseOwner"
              "contactPerson contactPerson";
      grid-column-gap: 0.625rem;
      grid-row-gap: 0.938rem;
    }
  }

  .project-data-row {
    width: 100%;
    @include flex-col(0.75rem, $alignment: flex-start);

    & > span,
    & > span > span {
      @include roboto-font(1rem, 400, 0.875rem, black);
    }

    & > div,
    & > div > div {
      width: 100%;
      @include flex-row(0.625rem, $justify: flex-start);
    }
  }


  .cancel-btn {
    background-color: $grey-700;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    position: absolute;
    top: -16px; /*half of the element above top of container */
    right: -16px; /*half of the element to the right of container */
    @include flex-col();

    &:focus {
      cursor: pointer;
    }
  }

  .invisible {
    opacity: 0;
  }

  .new-project-buttons {
    @include flex-row(0.375rem, center, flex-end);
    width: 100%;
    margin-top: -0.375rem;
  }


  .duplicate-address-icon {
    background-color: white;
    background-size: contain;
    position: relative;
    display: inline-block;
    text-align: center;
    width: 24px;
    height: 24px;
    margin-right: 6px;

    &:hover {
      cursor: pointer;
    }
  }

</style>
