<script lang="ts">

    import {t} from "../../i18n/i18n";
    import {getContext, setContext} from "svelte";
    import {type Writable} from "svelte/store";
    import {fly} from 'svelte/transition';
    import Notifications from "../organisms/Notifications.svelte";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import {AutocompleteConfig, type AutocompleteFieldConfig,} from "../types/autocompleteFieldConfig";
    import {DefaultRobotoFontProps, type FontProps} from "../types/fontProps";
    import {type Material} from "./types/material";
    import TextArea, {textAreaHeights} from "../atoms/TextArea.svelte";
    import Icon from "../atoms/Icon.svelte";
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import plusIcon from "@/icons/buttonicons/icon_plus.svg";
    import {type MaterialProperty} from "./types/materialProperty";
    import {closeModal, onBeforeClose} from "svelte-modals";
    import {SubstituteMaterialMapping, type SubstituteScriptImportData, type SubstituteType} from "./types/substitutes";
    import MaterialSubstituteImport from "./MaterialSubstituteImport.svelte";
    import CollapsibleCard from "../molecules/CollapsibleCard.svelte";
    import {getParentProperties} from "./util/materialUtil";
    import type {Catalog} from "./types/catalog";
    import {fetchUtils} from "../utils/fetchUtils";
    import {addNotification, notifications} from "../stores";
    import {NotificationType} from "../types/notification";
    import type {ExtendedMaterial} from "./types/material.js";

    export let isOpen: boolean;
    export let substituteType: SubstituteType;
    export let catalogs: Catalog[] = [];

    const fontProps: FontProps = DefaultRobotoFontProps('0.875rem', '0.75rem');
    const LOT_FORM_ERROR_PATH_PREFIX = 'lot.';
    setContext("useMaterialDesign", true);

    let materialAcRefConfig: AutocompleteFieldConfig = AutocompleteConfig('id', 'fullName');
    materialAcRefConfig.disableSifter = false; // needed to allow search
    let materialAcCanConfig: AutocompleteFieldConfig = AutocompleteConfig('id', 'fullName');
    materialAcCanConfig.disableSifter = false;// needed to allow search

    // material data variables
    let properties: Writable<Map<string, MaterialProperty>> = getContext('materialProperties');
    let materialReferenceId: string;
    let materialReferenceBackupId: string;
    let materialReference: Material;
    let materialCandidateId: string;
    let materialCandidateBackupId: string;
    let materialCandidate: Material;
    let allExtendedMaterials: Writable<ExtendedMaterial[]> = getContext('extended-materials');
    let materialOptions: ExtendedMaterial[] = [...$allExtendedMaterials.values()];
    let substituteScriptImportData: SubstituteScriptImportData[] = []

    // unsaved changes overlay variables
    let allowSwitch: boolean = true;
    let showOverlay: boolean = false;
    let overlayTitle: string = $t('UI.mmpv.modal.substitute.overlay.title');
    let overlayMessage: string = $t('UI.mmpv.modal.substitute.overlay.message');

    // component variables
    let substituteHeaderComment: string = '';
    let scriptManualText: string = '';
    let scriptFullText: string = '';
    let previewMode: boolean = false;
    let disablePreview: boolean = true;

    // overlay actions of handling of unsaved changes

    /**
     * Checks whether the actual substitute material has no imports.
     *
     * This function evaluates if the substitute material script import data is empty and if the changed material type
     * has a substituteImportData value
     *
     * @return {boolean} - Returns true if there are no relevant material imports; otherwise, returns false.
     */
    function actualSubstituteMaterialHasNoImports() {
        if (substituteScriptImportData.length === 0) {
            return true;
        }
        if (materialReferenceBackupId !== materialReferenceId) {
            return !substituteScriptImportData.some(data => data.materialType === SubstituteMaterialMapping.REFERENCE_MATERIAL && data.defaultPropertyName);
        } else {
            return !substituteScriptImportData.some(data => data.materialType === SubstituteMaterialMapping.CANDIDATE_MATERIAL && data.defaultPropertyName);
        }
    }

    /**
     * Decides whether the material is changed or not. If not, the material ids are reset to the selected material, as
     * they have already been changed by the autocomplete component
     *
     * @param {boolean} allow - Indicates whether changes are allowed.
     */
    function allowChanges(allow: boolean) {
        if (allow) {
            allowSwitch = true;
            handleSwitch();
        } else {
            allowSwitch = false;
            materialReferenceId = materialReferenceBackupId;
            materialCandidateId = materialCandidateBackupId;
            substituteScriptImportData = substituteScriptImportData;
        }
        showOverlay = false;

    }

    /**
     * Handles the switch operation by cloning material options based on their IDs,
     * updating substitute script data, and resetting switch-related flag allowSwitch and backups ids.
     *
     */
    function handleSwitch() {
        materialReference = structuredClone(materialOptions.find(material => material.id === materialReferenceId));
        materialCandidate = structuredClone(materialOptions.find(material => material.id === materialCandidateId));
        updateSubstituteScriptImportData();
        materialReferenceBackupId = materialReferenceId;
        materialCandidateBackupId = materialCandidateId;
        allowSwitch = false
    }

    onBeforeClose(() => {
        // by closing modal remove existing notifications
        $notifications = $notifications.filter(notification => notification.target === null)
    })

    function onMaterialChanged() {
        // switch material if boolean is true or if there are no imports else show overlay action
        if (allowSwitch || actualSubstituteMaterialHasNoImports()) {
            handleSwitch()
        } else {
            showOverlay = true;
        }
    }

    /**
     * Updates the `substituteScriptImportData` array based on the conditions related to `materialReferenceId`,
     * `materialCandidateId`, `materialReferenceBackupId`, and `materialCandidateBackupId`. It filters the array
     * to contain only the necessary items based on changes to reference and candidate IDs, and then applies
     * the `updatePropertiesOfSubstituteScriptImport` function to each item in the updated array.
     *
     */
    function updateSubstituteScriptImportData() {
        if (!materialReferenceId && !materialCandidateId) {
            substituteScriptImportData = [];
        } else if (materialReferenceBackupId !== materialReferenceId) {
            substituteScriptImportData = substituteScriptImportData.filter(data =>
                data.materialType === SubstituteMaterialMapping.CANDIDATE_MATERIAL
            );
        } else if (materialCandidateBackupId !== materialCandidateId) {
            substituteScriptImportData = substituteScriptImportData.filter(data =>
                data.materialType === SubstituteMaterialMapping.REFERENCE_MATERIAL
            );
        }
        substituteScriptImportData.forEach(data => updatePropertiesOfSubstituteScriptImport(data));
    }

    function updatePropertiesOfSubstituteScriptImport(data: SubstituteScriptImportData) {
        let propertyIds: string[] = [];

        if (materialReference?.propertyIds) {
            addAllProperties(materialReference, propertyIds);

        }
        if (materialCandidate?.propertyIds) {
            addAllProperties(materialCandidate, propertyIds);
        }

        data.properties = [...$properties.values()].filter(property => propertyIds.includes(property.id));
    }

    function addAllProperties(material: Material, propertyIds: string[]) {
        if (material?.propertyIds) {
            let materialCatalog;
            for (const catalog of catalogs.values()) {
                if (catalog.nodes.some((node) => node.id === material.id)) {
                    materialCatalog = catalog;
                }
            }

            getParentProperties(material, materialCatalog)?.forEach(propId => material.propertyIds.push(propId))
            material.propertyIds?.forEach(propId => {
                if (!propertyIds.includes(propId)) {
                    propertyIds.push(propId)
                }
            })
        }
    }

    function handleRemoveImport(e: CustomEvent) {
        const indexOfImport = substituteScriptImportData.map(data => data.identifier).indexOf(e.detail.identifier);

        if (indexOfImport > -1) {
            substituteScriptImportData.splice(indexOfImport, 1);
        }

        substituteScriptImportData = substituteScriptImportData;
    }

    function pushSubstituteImportData() {
        let data: SubstituteScriptImportData = {
            defaultPropertyName: '',
            ownPropertyName: '',
            properties: [],
            identifier: substituteScriptImportData.length + 1,
            text: '',
            materialType: SubstituteMaterialMapping.REFERENCE_MATERIAL
        }
        // add all properties to data obj of selected material reference and candidate
        updatePropertiesOfSubstituteScriptImport(data);
        // add the new import to the start of the array to show as first object in ui
        substituteScriptImportData.unshift(data);

        substituteScriptImportData = substituteScriptImportData;
    }

    /**
     * Constructs the complete script text by concatenating following objects: 1. header comments 2. imports and
     * declares as variable substituteScriptImportData 3. script text.
     */
    function buildScriptText() {
        let scriptText = "";

        if (substituteHeaderComment) {
            scriptText = scriptText.concat(substituteHeaderComment).concat("\n\n");
        }

        substituteScriptImportData.forEach(data => {
            if (data.defaultPropertyName) {
                scriptText = scriptText.concat(data.text).concat("\n");
            }
        });

        if (scriptManualText) {
            scriptText = scriptText.concat("\n").concat(scriptManualText);
        }
        scriptFullText = scriptText;
    }

    function saveScript() {
        buildScriptText();
        fetchUtils.post('/api/materialData/substitutes/mapping/save/new', {
            substituteTypePublicId: substituteType.publicId,
            refMatPublicId: materialReferenceId,
            canMatPublicId: materialCandidateId,
            dirrtyScript: scriptFullText
        })
            .then(data => {
                console.log(data)
                addNotification({
                    message: "Das Script wurde erfolgreich gespeichert",
                    type: NotificationType.SUCCESS,
                    target: "modal"
                })
            })
            .catch(fetchUtils.catchErrorAndShowNotification("modal"));
    }

    /**
     * Handles changes to the reacted objects by checking if there are no imports filled and no text in header comment
     * and script text. If true, the preview view mode is disabled.
     */
    function handleDataChange() {
        const noImportFilled = substituteScriptImportData.length === 0 || substituteScriptImportData.filter(data => data.defaultPropertyName).length === 0;
        disablePreview = noImportFilled && substituteHeaderComment?.length === 0 && scriptManualText?.length === 0;
    }

    $: previewMode, buildScriptText();
    $: substituteHeaderComment, scriptManualText, substituteScriptImportData, handleDataChange();

</script>
{#if isOpen}
    <div role="dialog" class="modal" in:fly="{{ y: -1000, duration: 400 }}" out:fly={{y: -1000, duration: 400}}>
        <div class="contents" class:show-overlay={showOverlay}
             style="--content-padding: 3.13rem 3.5rem; max-width: 920px; width: 920px">
            <Notifications target={"modal"}/>
            {#if showOverlay}
                <div class="modal-overlay-content overlay-alignment">
                    <div>
                        <span>{@html overlayTitle}</span>
                        <p>{@html overlayMessage}</p>
                    </div>
                    <div>
                        <SecondaryButton label={$t('Button.cancel')} color={SecondaryBtnColors.RED}
                                         sizeAdaptingToText on:click={()=> allowChanges(false)}/>

                        <PrimaryButton label={$t('UI.mmpv.modal.substitute.overlay.button.confirm')}
                                       color={PrimaryBtnColors.RED} sizeAdaptingToText
                                       on:click={()=> allowChanges(true)}/>
                    </div>
                </div>
            {/if}
            <div class="substitute-container">
                <div class:hidden={previewMode}>
                    <span>{$t('UI.mmpv.modal.substitute.text.general.header')}</span>
                    <span>{@html $t('UI.mmpv.modal.substitute.text.general.subheader')}</span>
                    <div class="materials-autocomplete">
                        <div>
                            <AutocompleteInputField bind:value={materialReferenceId}
                                                    label={$t('UI.mmpv.modal.substitute.autocomplete.label.reference')}
                                                    autocompleteConfig={materialAcRefConfig} options={materialOptions}
                                                    errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'material'} {fontProps}
                                                    required fullWidth on:change={onMaterialChanged}/>
                        </div>
                        <div>
                            <AutocompleteInputField bind:value={materialCandidateId}
                                                    label={$t('UI.mmpv.modal.substitute.autocomplete.label.candidate')}
                                                    autocompleteConfig={materialAcCanConfig} options={materialOptions}
                                                    errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'material'} {fontProps}
                                                    required fullWidth on:change={onMaterialChanged}/>
                        </div>
                    </div>
                    <TextArea bind:text={substituteHeaderComment}
                              title={$t('UI.mmpv.modal.substitute.script.header.title')}
                              placeholder={$t('UI.mmpv.modal.substitute.script.header.placeholder')} titleInline/>
                    <div class="imports-and-declares">
                        <div>
                            <span>{$t('UI.mmpv.modal.substitute.text.importsAndDeclares.header')}</span>
                            {#if materialReferenceId || materialCandidateId}
                                <div>
                                    <Icon src={plusIcon} allowTransition={false} height={32} width={32} clickable
                                          on:click={pushSubstituteImportData}/>
                                </div>
                            {:else }
                                <div class="disabled">
                                    <Icon src={plusIcon} allowTransition={false} height={32} width={32}/>
                                </div>
                            {/if}
                        </div>
                        {#each substituteScriptImportData as data (data.identifier)}
                            <div style="padding: .5rem 0;">
                                <CollapsibleCard openByDefault>
                                    <div slot="header" class="imports-and-declares-header">
                                        <h3>
                                            {$t('UI.mmpv.modal.substitute.import.card.header')} {data.ownPropertyName?.length > 0 ? data.ownPropertyName : data.defaultPropertyName ? data.defaultPropertyName : '' }
                                        </h3>
                                    </div>
                                    <div slot="body">
                                        <MaterialSubstituteImport bind:data {materialReference} {materialCandidate}
                                                                  on:removeImport={handleRemoveImport}/>
                                    </div>
                                </CollapsibleCard>
                            </div>
                        {/each}
                    </div>
                    <div class="script-text">
                        <TextArea bind:text={scriptManualText} title={$t('UI.mmpv.modal.substitute.script.text.title')}
                                  marginTop={substituteScriptImportData?.length === 0 ? ".3rem" : "1rem"}
                                  placeholder={$t('UI.mmpv.modal.substitute.script.text.placeholder')}
                                  height={textAreaHeights.LARGE} titleInline/>
                    </div>
                </div>
                <div class:hidden={!previewMode}>
                    <span>{$t('UI.mmpv.modal.substitute.script.preview.title')}</span>
                    <TextArea bind:text={scriptFullText} height={"38rem"}
                              title={$t('UI.mmpv.modal.substitute.script.preview.inlineTitle')}
                              placeholder={$t('UI.mmpv.modal.substitute.script.text.placeholder')}
                              titleInline readonly/>
                </div>
                <div class="substitute-modal-footer">
                    <div>
                        <SecondaryButton color={SecondaryBtnColors.GREEN} disabled={disablePreview}
                                         label={$t('UI.mmpv.modal.substitute.button.preview')} width={"5.25rem"}
                                         on:click={()=> previewMode = !previewMode}/>
                    </div>
                    <div>
                        <SecondaryButton color={SecondaryBtnColors.GREEN} label={$t('Button.cancel')} width={"6.375rem"}
                                         on:click={closeModal}/>
                        <PrimaryButton color={PrimaryBtnColors.GREEN} label={$t('UI.button.save')} width={"6.175rem"}
                                       on:click={saveScript}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
{/if}

<style lang="scss">
  @import "../../styles/modals"; // also includes global.scss

  h2 {
    width: auto;
    margin: 0;
  }

  .hidden {
    display: none !important;

  }

  div.overlay-alignment {
    width: 100%;
    height: 93%;
    background: white;
    position: absolute;
    z-index: 99999999;
  }

  .contents {
    max-height: 90%;
  }

  .substitute-container {
    @include flex-col($justify: normal, $alignment: normal);
    height: 45rem;
    width: 52rem;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    padding: 0 2px;

    & > div {
      display: flex;
      flex-flow: column;
    }

    ::-webkit-scrollbar {
      display: none;
    }

    & :global(.green-text) {
      color: $primaryGreen;
    }

    & > div > span:first-child {
      @include roboto-font(1.5rem, 500, 1rem, black);
      padding-bottom: .5rem;
    }

    & > div > span:nth-child(2) {
      @include roboto-font(1.25rem, 400, .75rem, #1E1E1E);
      padding-bottom: 1rem;
    }

    & > div > div.materials-autocomplete {
      @include flex-row($justify: space-between, $col-gap: .875rem);

      & > div {
        width: 400px;
        max-width: 49%;
      }
    }

    div.imports-and-declares {
      padding-top: .5rem;

      & > div:first-child {
        @include flex-row($justify: space-between);
        position: sticky;
        top: -.1rem;
        padding-bottom: .5rem;
        padding-top: .3rem;
        margin-left: -2px;
        margin-right: -2px;
        padding-right: 2px;
        min-height: 3rem;
        z-index: 1;
        background-color: white;

        & > span {
          @include roboto-font(1.125rem, 500, .875rem, #5888C0);
          align-self: center;
        }

        & > div {
          outline: 2px solid $primaryGreen;
          border-radius: 2px;
          width: 32px;
          height: 32px;
        }

        & > div.disabled {
          outline: 2px solid $bluish-grey-700;
        }
      }

      .imports-and-declares-header {
        @include roboto-font(20px, 500, 12px, #1e1e1e);
        padding-top: 1rem;
        padding-left: 1rem;
      }
    }

    div.script-text {
      padding-bottom: 2rem
    }

    & > div.substitute-modal-footer {
      @include flex-row($justify: space-between,);
      position: absolute;
      background: white;
      bottom: 0;
      height: 5rem;
      border: none;
      width: inherit;
      margin: 0 -2px;

      & > div:nth-child(2) {
        @include flex-row($justify: space-between, $col-gap: 1rem);
      }
    }
  }

</style>