<script lang="ts">

import DropdownMenu from "../../dropdown/SimpleDropdownMenu.svelte";
import SimpleDropdownLinkItem from "../../dropdown/SimpleDropdownLinkItem.svelte";
import DropdownMenuSection from "../../dropdown/DropdownMenuSection.svelte";
import MenuItem from "../../MenuItem.svelte";
import {UserPermission} from "../../../types/enums";
import {t} from "../../../../i18n/i18n";

const TARGET_PRICE_PERMISSIONS = `${UserPermission.FLAG_TARGET_PRICE}&(${UserPermission.VIEW_TARGET_PRICE}|${UserPermission.EDIT_TARGET_PRICE})`;
</script>

<MenuItem title={$t("TargetPrice.title")} url="/targetPrice" permissions={TARGET_PRICE_PERMISSIONS} let:active>
    <DropdownMenu {active}>
        <DropdownMenuSection>
            <SimpleDropdownLinkItem titleKey="TargetPrice.dashboard" url="/targetPrice"
                                    permissions={UserPermission.VIEW_TARGET_PRICE}/>
            <SimpleDropdownLinkItem titleKey="TargetPrice.recording" url="/targetPrices"
                                    permissions={UserPermission.EDIT_TARGET_PRICE}/>
        </DropdownMenuSection>
    </DropdownMenu>
</MenuItem>
