<div class="whitelist-view-finished">
</div>

<style lang="scss">
  .whitelist-view-finished {
    background-image: url("@/images/whitelist_finished_background.svg");
    animation: scale 900ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
    animation-iteration-count: 1;
    height: 23rem;
    width: 30rem;
    align-self: center;
    margin: 3rem 7rem 2rem 0;
  }

  @keyframes scale {
    from {
      transform: scale(0.9) translateY(70px);
    }
    to {
      transform: scale(1) translateY(0);
    }
  }
</style>