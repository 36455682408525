import type {VehicleCategory} from "../../optimizer/vehicleCategory";
import {formatNumber, localDateToUnixNumber, unixToIsoDate, unixToLocalDate} from "../../utils/formatters";
import {get} from "svelte/store";
import {t} from "../../../i18n/i18n";
import {type Volume, VolumeUnit} from "../../types/volume";
import type {Price} from "../../types/price";

// these are all the fields that Facts have, they can be used to create a facts object with any child object of them,
// i.e. that the not given fields are nulled in the class instance
interface FactsParams {
    vehicles?: VehicleCategory[];
    volume?: Volume;
    start?: string;
    end?: string;
    dailyCapacity?: Volume;
    price?: number | Price;
    kickback?: number;
    kickbackInPercent?: number;
    materialName?: string,
}

export const DEFAULT_KICKBACK_PERCENT: number = 5;

export class Facts {
    vehicles: VehicleCategory[];
    private _volume: Volume | null;
    private _start: number | null; // Stored as unix
    private _end: number | null; // Stored as unix
    private _dailyCapacity: Volume | null;
    price: number | null;
    kickback: number | null;
    kickbackInPercent: number | null;
    materialName: string | null

    constructor(params: Partial<FactsParams> = {}) {
        const {
            vehicles = [],
            volume = null,
            start = null,
            end = null,
            dailyCapacity = null,
            price = null,
            kickback = null,
            kickbackInPercent = null,
            materialName = null
        } = params;

        this.vehicles = vehicles;
        this._volume = volume;
        this._start = localDateToUnixNumber(start);
        this._end = localDateToUnixNumber(end);
        this._dailyCapacity = dailyCapacity;
        this.price = typeof price == "number" ? price : price?.number ?? null;
        this.kickback = kickback;
        this.kickbackInPercent = kickbackInPercent;
        this.materialName = materialName;
    }

    get start(): number | null {
        return this._start;
    }

    get startAsFormattedDate(): string {
        return unixToLocalDate(this._start);
    }

    get startAsIsoDate(): string | null {
        return unixToIsoDate(this._start) || null;
    }

    set start(date: string | number) {
        this._start = typeof date === 'string' ? localDateToUnixNumber(date) : date;
    }

    get end(): number | null {
        return this._end;
    }

    get endAsFormattedDate(): string {
        return unixToLocalDate(this._end);
    }

    get endAsIsoDate(): string | null {
        return unixToIsoDate(this._end) || null;
    }

    set end(date: string | number) {
        this._end = typeof date === 'string' ? localDateToUnixNumber(date) : date;
    }

    get volume(): number | undefined {
        return this?._volume?.volume ?? undefined; // return null such that the placeholder is showing when empty
    }

    set volume(vol: number | Volume) {
        if (vol) {
            if (typeof vol === 'number') {
                this._volume = {volume: vol, unit: VolumeUnit.TONNE};
            } else {
                this._volume = {...vol};
            }
        } else {
            this._volume = null;
        }
    }

    get volumeUnit(): VolumeUnit {
        return this._volume ? this._volume.unit : VolumeUnit.TONNE;
    }

    get dailyCapacity() : number | undefined {
        return this?._dailyCapacity?.volume ?? undefined; // return null such that the placeholder is showing when empty
    }

    set dailyCapacity(dailyCap: number | Volume){
        if (dailyCap) {
            if (typeof dailyCap === 'number') {
                this._dailyCapacity = {volume: dailyCap, unit: VolumeUnit.TONNE};
            } else {
                this._dailyCapacity = {...dailyCap};
            }
        } else {
            this._dailyCapacity = null;
        }
    }

    get dailyCapacityUnit(): VolumeUnit {
        return this._dailyCapacity ? this._dailyCapacity.unit : VolumeUnit.TONNE;
    }

    get volumeFormattedAsString() {
        return this._volume ? formatNumber(this._volume.volume.toFixed(0).toString()) : "-";
    }

    get dailyCapacityFormattedAsString() {
        return this._dailyCapacity ? formatNumber(this._dailyCapacity.volume.toFixed(0).toString()) : "-";
    }

    get kickbackFormattedAsString() {
        return this.kickback ? formatNumber(this.kickback.toFixed(2)).toString() : "-";
    }

    get priceValFormattedAsString() {
        return this.price ? formatNumber(this.price.toFixed(2)).toString() : "-";
    }

    get vehiclesAsString(): string {
        return ((this.vehicles?.length ?? 0) > 0)
            ? this.vehicles.map(vehicle => vehicle.label).join(', ')
            : get(t)('UI.counterOffer.vehicleCategories.is.Unlimited');
    }

    toDto() {
        return {
            vehicles: this.vehicles,
            volume: this._volume,
            start: this.startAsIsoDate,
            end: this.endAsIsoDate,
            dailyCapacity: this._dailyCapacity,
            price: this.price,
            kickback: this.kickback,
            kickbackInPercent: this.kickbackInPercent,
            materialName: this.materialName
        }
    }


    // return a symbol iterator for this class that iterates over the fields
    * [Symbol.iterator]() {
        const properties = Object.getOwnPropertyNames(this);
        let index = 0;

        return {
            next: () => {
                if (index < properties.length) {
                    const key = properties[index];
                    const value = this[key];
                    index++;
                    return {
                        value: {key, value}, done: false
                    }
                }
            }
        }
    }

}
