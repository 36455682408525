import {CalcMethod, LotState, LotType} from "../../types/enums";
import {type AddressData, addressSchema} from "../../types/addressData";
import {DefaultUserAccountData, type UserAccountData, userAccountDataSchema} from "../../types/userAccountData";
import type {VehicleCategory} from "../../optimizer/vehicleCategory";
import type {LotStatusButtonsView} from "../../types/lotStatusButtonsView";
import {DefaultLotStatusButtonsView} from "../../types/lotStatusButtonsView";
import type {DealPartnerConnect} from "../../types/dealPartnerConnect";
import type {LotEditFieldsView} from "../../types/lotEditFieldsView";
import {DefaultLotEditFieldsView} from "../../types/lotEditFieldsView";
import {array, mixed, number, object, string} from "yup";
import {translate} from "../../../i18n/i18n";
import {type Material, materialSchema} from "./material";
import type {LotStateData} from "../../types/lotStateData";
import {type Catalog, catalogSchema} from "./catalog";
import {fetchUtils} from "../../utils/fetchUtils";
import {
    currentLot,
    formErrors,
    localLotsStore,
    lotSavingTimeout,
    mapFormControls,
    positionAutocompleteVisible
} from "../fak/utils/fakStores";
import {clearFormErrors} from "../fak/utils/fakUtils";
import {addNotification} from "../../stores";
import {get} from "svelte/store";
import {getFormErrors} from "../../utils/formValidation";
import type {PersonAndCompanyData} from "../../types/personAndCompanyData";
import {
    copyPersonAndCompanyData,
    DefaultPersonAndCompanyData,
    personAndCompanySchema
} from "../../types/personAndCompanyData";
import {
    hideAutosaveInfo,
    showAutosaveCheckmark,
    showAutosaveCrossmark,
    showAutosaveLoading
} from "../../utils/autosaveInfoUtils";
import {NotificationType} from "../../types/notification";

export type Lot = {
    address: AddressData,
    calcMethod: CalcMethod,
    caseManager: UserAccountData,
    catalog: Catalog,
    code: string,
    correlationId: string,
    customerComment?: string,
    commentAccounting: string,
    dailyCapacity?: number,
    endDate: string,
    grossPricePerTon?: number,
    id: string,
    material: Material,
    materialProperties: string,
    materialPropertiesComment: string,
    materialPropertiesCommentWithoutOptional: string,
    kickbackInPercent?: number,
    kickbackPerTon?: number,
    label: string,
    remarks: string,
    person: PersonAndCompanyData,
    project: string,
    projectRemarks: string,
    publicId: string,
    pubRemarks?: string,
    startDate?: string,
    state: LotState,
    type: LotType,
    vehicleCategories: Array<VehicleCategory>,
    volume: number,
    furtherDetailsComment?: string,
    optimizerPublicComment?: string,
    commentSales?: string,
    commentBackoffice?: string,
    lotViewStatusChangeMap: LotStatusButtonsView,
    lotViewFieldsChangeMap: LotEditFieldsView,
    dealPartnerConnect: DealPartnerConnect,
    lotStateData: LotStateData[],
    optimizerMailSent: boolean,
    optimizerMailScheduled: boolean,
    journalLink: string,
}

export function DefaultLot() {
    let lot: Lot = {
        address: null,
        calcMethod: CalcMethod.REQUEST_OFFER,
        caseManager: DefaultUserAccountData(),
        catalog: null,
        code: "",
        commentBackoffice: "",
        commentSales: "",
        correlationId: "",
        customerComment: "",
        commentAccounting: "",
        dailyCapacity: null,
        endDate: "",
        grossPricePerTon: null,
        id: "new",
        material: null,
        materialProperties: "",
        materialPropertiesComment: "",
        materialPropertiesCommentWithoutOptional: "",
        kickbackInPercent: null,
        kickbackPerTon: null,
        label: "",
        remarks: "",
        person: DefaultPersonAndCompanyData(),
        project: "",
        projectRemarks: "",
        publicId: "",
        pubRemarks: "",
        startDate: "",
        state: LotState.UNVERIFIED,
        type: LotType.DISPOSAL,
        vehicleCategories: [],
        volume: null,
        furtherDetailsComment: "",
        optimizerPublicComment: "",
        lotViewStatusChangeMap: DefaultLotStatusButtonsView(),
        lotViewFieldsChangeMap: DefaultLotEditFieldsView(),
        dealPartnerConnect: null,
        lotStateData: [],
        optimizerMailSent: false,
        optimizerMailScheduled: false,
        journalLink: "",
    };
    return lot;
}

export function copyLot(lot: Lot, regenerateIds = false) {
    let copy: Lot = DefaultLot();
    Object.assign(copy, lot);
    // @ts-ignore
    copy.person = copyPersonAndCompanyData(lot.person, regenerateIds);
    if (lot.address) {
        copy.address = {...lot.address};
    }
    if (lot.caseManager) {
        Object.assign(copy.caseManager, lot.caseManager);
    }
    if (lot.material) {
        copy.material = {...lot.material};
    }
    if (lot.catalog) {
        copy.catalog = {...lot.catalog};
    }
    if (lot.dealPartnerConnect) {
        Object.assign(copy.dealPartnerConnect, lot.dealPartnerConnect);
    }
    if (lot.lotStateData) {
        Object.assign(copy.lotStateData, lot.lotStateData);
    }

    return copy;
}

// Keep in mind that once we change anything here or in the typescript type, it could be that we need to adapt the path for the error messages as well
export const mapLotSchema = object().shape({
    address: addressSchema.required(translate('de', 'UI.validation.address.required', [])),
    calcMethod: mixed()
        .oneOf(Object.values(CalcMethod), translate('de', 'UI.validation.calcMethod.oneOf', []))
        .required(translate('de', 'UI.validation.calcMethod.required', [])),
    caseManager: userAccountDataSchema.notRequired(),
    catalog: catalogSchema.required(translate('de', 'UI.validation.catalog.id.required', [])),
    commentBackoffice: string().notRequired(),
    commentSales: string().notRequired(),
    correlationId: string().notRequired(),
    customerComment: string().notRequired(),
    commentAccounting: string().notRequired(),
    dailyCapacity: number()
        .positive(translate('de', 'UI.validation.dailyCapacity.positive', []))
        .notRequired(),
    endDate: string().notRequired(),
    grossPricePerTon: number().min(0, translate('de', 'UI.validation.grossPricePerTon.min', [])).notRequired(),
    id: string().required(translate('de', 'UI.validation.lot.id.required', [])),
    material: materialSchema.required(translate('de', 'UI.validation.material.id.required', [])),
    materialProperties: string().notRequired(),
    materialPropertiesComment: string().notRequired(),
    materialPropertiesCommentWithoutOptional: string().notRequired(),
    kickbackInPercent: number().min(0, translate('de', 'UI.validation.kickbackPercent.notNegative', []))
        .when('kickbackPerTon', {
            is: (val: number) => val !== undefined && val !== null,
            then: (schema) => schema.notRequired(),
            otherwise: (schema) => schema.required(translate('de', 'Lot.kickbackFields.validationMessage', [])),
        }),
    kickbackPerTon: number().min(0, translate('de', 'UI.validation.kickbackPerTon.required', []))
        .when('kickbackInPercent', {
            is: (val: number) => val !== undefined && val !== null,
            then: (schema) => schema.notRequired(),
            otherwise: (schema) => schema.required(translate('de', 'Lot.kickbackFields.validationMessage', [])),
        }),
    remarks: string().notRequired(),
    person: personAndCompanySchema.required(translate('de', 'UI.validation.person.required', [])),
    project: string().required(),
    projectRemarks: string().notRequired(),
    pubRemarks: string().notRequired(),
    startDate: string().notRequired(),
    type: mixed().oneOf(Object.values(LotType)).required(translate('de', 'UI.validation.LotType.required', [])),
    vehicleCategories: array().notRequired(),
    volume: number().typeError(translate('de', 'UI.validation.volume.typeError', []))
        .required(translate('de', 'UI.validation.volume.required', []))
        .positive(translate('de', 'UI.validation.volume.positive', []))
        .min(1, translate('de', 'UI.validation.volume.min', [])), // must be larger 0
    furtherDetailsComment: string().notRequired(),
    optimizerPublicComment: string().notRequired(),
}, [['kickbackInPercent', 'kickbackPerTon']]).required();


// When validation fails, throws an error
// @ts-ignore
export function validateLot(lotToValidate: Lot): Lot | never {
    clearFormErrors();
    if (lotToValidate.grossPricePerTon === null) {
        lotToValidate.grossPricePerTon = 0;
    }
    if (lotToValidate.kickbackPerTon === null) {
        lotToValidate.kickbackPerTon = 0;
    }
    // to be able to validate the dailyCapacity correctly, we need to remove the field when it is 0 or null
    // otherwise, we will get th error, that it is NAN and the validation will fail even though we did not specify it
    if (!lotToValidate.dailyCapacity) {
        delete lotToValidate.dailyCapacity;
    }
    try {
        // @ts-ignore
        return mapLotSchema.validateSync(lotToValidate, {stripUnknown: true, abortEarly: false});
    } catch (validationError) {
        showAutosaveCrossmark(lotToValidate.id !== "new" ? "(" + lotToValidate.code + ")" : "");
        formErrors.set(getFormErrors(validationError, 'lot.'));
        let cLot = get(currentLot);
        if (!cLot || cLot.id !== lotToValidate.id) {
            currentLot.set(get(localLotsStore).get(lotToValidate.id) ?? null);
        }
    }
}

// Not exported to enforce calling validation function before saving
async function saveLot(lotToSave: Lot) {
    await fetchUtils.post(`/api/lot/update/${lotToSave.id}`, lotToSave)
        .then((data: Lot) => {
            localLotsStore.update(localLots => {
                localLots.set(data.id, copyLot(data));
                return localLots;
            });
            let cLot = get(currentLot);
            if (cLot && cLot.id === data.id) {
                currentLot.set(get(localLotsStore).get(data.id) ?? null);
            }
            if (lotToSave.id === "new") {
                addNotification({
                    message: translate('de', 'UI.map.notification.newPosition.success', []),
                    type: NotificationType.INFO,
                    timeout: 5000,
                    dismissible: true
                });
                currentLot.set(get(localLotsStore).get(data.id) ?? null);
                hideAutosaveInfo();
            } else {
                showAutosaveCheckmark("(" + data.code + ")");
            }
        }).catch((e) => {
            fetchUtils.catchErrorAndShowNotification()(e);
            hideAutosaveInfo();
            // Replace with currently save lot data from server
            if (e.data) {
                localLotsStore.update(localLots => {
                    localLots.set(e.data.id, copyLot(e.data));
                    return localLots;
                });
                currentLot.set(get(localLotsStore).get(e.data.id) ?? null);
            }
        })
}

export async function validateAndSaveLot(lotToSave: Lot | null = get(currentLot)) {
    if (lotToSave) {
        mapFormControls.update(controls => {
            controls.saving = true;
            return controls;
        });
        positionAutocompleteVisible.set({company: true, contactPerson: true, addressData: true});
        let timeoutDelay = lotToSave.id !== "new" ? 1000 : 0;
        showAutosaveLoading();
        lotSavingTimeout.update(timeout => {
            clearTimeout(timeout);
            return setTimeout(async () => {
                let validatedLot = validateLot(lotToSave);
                if (validatedLot) {
                    await saveLot(validatedLot);
                }

                mapFormControls.update(controls => {
                    controls.saving = false;
                    return controls;
                });
            }, timeoutDelay);
        });
    }
}


export async function updateMaterialPropertyComments() {
    let lot = get(currentLot);
    if (lot) {
        let materialPropertiesComment = "";
        let materialPropertiesCommentWithoutOptional = "";
        if (lot.materialProperties && lot.material) {
            await fetchUtils.post("/api/materialProperties/jsonToCommentString", JSON.parse(lot.materialProperties), false)
                .then((data) => {
                    materialPropertiesComment = data;
                })
                .catch(() => {
                    // ignore
                });
            await fetchUtils.post("/api/materialProperties/jsonToCommentString/requiredOnly", JSON.parse(lot.materialProperties), false)
                .then((data) => {
                    materialPropertiesCommentWithoutOptional = data;
                })
                .catch(() => {
                    // ignore
                });
        }

        currentLot.update(l => {
            // @ts-ignore
            l.materialPropertiesComment = materialPropertiesComment;
            // @ts-ignore
            l.materialPropertiesCommentWithoutOptional = materialPropertiesCommentWithoutOptional;
            return l;
        });
    }
}
