import {get, type Writable, writable} from "svelte/store";
import {AnalyticalParentType, AnalyticalType} from "../types/enums";
import {fetchUtils} from "../utils/fetchUtils";
import {tick} from "svelte";
import type {Recommendation} from "./types/recommendation";
import type {AnalyticalMetadata} from "./types/analyticalMetadata";
import type {AnalyticalValue} from "./types/analyticalValue";

let sessionValues = window.sessionStorage.getItem("analyticalValues");
export const values: Writable<AnalyticalValue[]> = writable(  sessionValues ? JSON.parse(sessionValues) : []);
export const parameterStore: Writable<{ parameters: Record<string, string> }> = writable({parameters: {}});
export const recommendationParameters: Writable<Record<string, Record<string, string>>> = writable({});
export const recommendationValues: Writable<Record<string, Writable<AnalyticalValue[]>>> = writable({});
export const recommendations: Writable<Recommendation[]> = writable([]);
export const selectedRecommendation = writable<string | null>();
export const hideOptionalValues: Writable<boolean> = writable(true);

export const analyticalMetadata: Writable<AnalyticalMetadata> = writable({
    parentType: AnalyticalParentType.LOT,
    parentId: null,
    analyticalId: null,
    analyticalType: AnalyticalType.ANALYSIS,
    analyticalParentType: AnalyticalParentType.LOT,
    technicalName: null,
});

export const analyticNames: Writable<Record<string, string>> = writable({});
export const analyticsToolParams: Writable<Record<string, string>> = writable();

export const findValue = (key: string) => {
    let valuesArr = get(values);
    for (let i = 0; i < valuesArr.length; i++) {
        let value = valuesArr[i];
        if (value.key === key) {
            return value;
        }
    }

    return null;
}

export const getFormattedParameters = () => {
    let res = [];
    const parameters = get(parameterStore).parameters;
    for (let parametersKey in parameters) {
        res.push({
            name: parametersKey,
            value: parameters[parametersKey]
        })
    }
    return res;
}

export function loadRecommendations() {
    const {analyticalId, analyticalType, parentType, technicalName} = get(analyticalMetadata);
    const baseUrl = window.location.href;
    if (analyticalType === AnalyticalType.ANALYTIC) { // for analytics do nothing
        return;
    }
    const url = new URL(`/api/${analyticalType?.toLowerCase()}/recommendations`, baseUrl);
    if (!parentType || parentType === AnalyticalParentType.NONE) {
        let parameters = getCurrentAnalyticalParameters();
        let payloadForTool = {
            parameters,
            technicalName,
            values: get(values),
        }
        fetchUtils.post(url, payloadForTool).then(setRecommendations)
    } else {
        url.searchParams.append("parentType", parentType || "LOT");
        url.searchParams.append("analyticalId", analyticalId || "");
        fetchUtils.get(url)
            .then(setRecommendations)
    }
}

const setRecommendations = async (data: { recommendations: Recommendation[] }) => {
        recommendations.set(data.recommendations);
        await tick();
        initializeRecommendationParameterStore(data.recommendations);
}

const getCurrentAnalyticalParameters = () => {
    const paramsStore = get(parameterStore).parameters;
    return Object.keys(paramsStore).map((parametersKey) => ({
        name: parametersKey,
        value: paramsStore[parametersKey]
    }));
};

const initializeRecommendationParameterStore = (recommendations: Recommendation[]) => {
    // initialize/update recommendation parameter store
    const paramsStore = get(parameterStore).parameters;
    for (let i = 0; i < recommendations.length; i++) {
        let recommendation = recommendations[i];
        let recommendationParams = get(recommendationParameters);
        if (!recommendationParams[recommendation.analytic]) {
            // only create the object if not existing to be able to set the parameters in that object
            recommendationParams[recommendation.analytic] = {};
        }
        // always update all the values according to the parameterStore
        for (const [key, _] of Object.entries(recommendation.requiredParameters)) {
            recommendationParams[recommendation.analytic][key] = paramsStore[key];
        }
    }
}
