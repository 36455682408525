import type {NodeData} from "../../types/nodeData";
import {object, string} from "yup";
import {translate} from "../../../i18n/i18n";
import type {MaterialProperty} from "./materialProperty";

export type Material = NodeData & { //extends NodeData
    archived: boolean,
    code: string,
    description: string,
    comment: string,
    name: string,
    parentCode: string,
    properties: MaterialProperty[], //actually a list of [MaterialProperty] but maybe not set as all properties are only set once -> use the Ids for a reliable list
    propertyIds: string[], //actually a list of [MaterialPropertyID (string)]
}

export type ExtendedMaterial = Material & {
    fullCode: string;
    fullName: string;
}

export const materialSchema = object({
    code: string()
        .trim()
        .required(translate('de', 'UI.mmpv.material.validation.code.required', []))
        .length(2, translate('de', 'UI.mmpv.material.validation.code.invalidLength', [])),
    description: string(),
    comment: string(),
    name: string().trim().required(translate('de', 'UI.mmpv.material.validation.name.required', [])),
    parentId: string().required(translate('de', 'UI.mmpv.material.validation.parent.required', [])),
    id: string().required(),
}).required();


export function EmptyMaterial(): Material {
    return {
        archived: false,
        highlighted: false,
        childrenIds: [],
        code: "01",
        level: 1,
        description: "",
        id: "new",
        comment: "",
        label: "",
        name: "",
        parentCode: "",
        parentId: "",
        propertyIds: [],
        properties: [],
    };
}

export function DefaultMaterial(parentId: string, parentCode: string, level: number): Material {
    return {
        archived: false,
        highlighted: false,
        childrenIds: [],
        code: "01",
        level: level,
        description: "",
        id: "new",
        comment: "",
        label: "Neuer Knoten",
        name: "",
        parentCode: parentCode,
        parentId: parentId,
        propertyIds: [],
        properties: [],
    };
}
