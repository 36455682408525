<script lang="ts">
    import type {FontProps} from "../types/fontProps";
    import {DefaultInputFieldProps, getFontPropsVarString} from "../types/fontProps";
    import {createEventDispatcher, onMount} from "svelte";
    import Flatpickr from 'svelte-flatpickr'
    import {openCloseVariants} from "../utils/svelteMotionUtils";
    import {AnimatePresence, AnimateSharedLayout, Motion} from "svelte-motion";

    export let id = "";
    export let value = "";
    export let label: string;
    export let placeholder: string = ""; // one CAN specify a placeholder, but the transition does not look that good
    export let width = "100%";
    export let disabled: boolean = false;
    export let fontProps: FontProps = DefaultInputFieldProps();
    export let flatpickrOptions;
    export let error: string = '';
    export let containerWidth = "100%";


    let notEmpty;
    let materialLabel;
    let dateId: string = "date-id" + Math.random().toString(16).slice(2);
    let open = false; // toggles the border when the calendar is opened
    const dispatch = createEventDispatcher();

    function onOpen(event) {
        open = true;
        dispatch("open", event);
    }

    function onClose(event) {
        open = false;
        dispatch("close", event);
    }

    $: fontPropsStyleString = getFontPropsVarString(fontProps);
    // if value is not falsy value, notEmpty is true or if we have label AND placeholder
    $: notEmpty = (!!value) || (placeholder && label);
    $: {
        if (label) {
            materialLabel = label;
        } else {
            materialLabel = placeholder;
            placeholder = "";
        }
    }
</script>

<AnimateSharedLayout>
    <div class="input-field-error-message-container" style="--container-width: {containerWidth}">
        <div class="material-design" style="{fontPropsStyleString}; --input-width: {width}">
            <div class="input-field-focus date-time-field input"
                 style="width: {width}; max-width: {width};{getFontPropsVarString(fontProps)}"
                 class:open class:notEmpty class:material-disabled={disabled}>
                <Flatpickr {id} bind:value options={flatpickrOptions} {placeholder}
                           on:change
                           on:open={onOpen}
                           on:close={onClose}/>
            </div>
            <label class="material-label" for="{dateId}" id="label-{dateId}" class:material-label-disabled={disabled}>
                <div class="material-text">{materialLabel}</div>
            </label>
        </div>
        <AnimatePresence list={error && error !== '' ? [{ key: 1 }] : []}>
            <Motion let:motion={collapse} initial="collapsed" animate="open" exit="collapsed" variants={openCloseVariants}>
                <span class="form-field-error" use:collapse>{@html error}</span>
            </Motion>
        </AnimatePresence>
    </div>
</AnimateSharedLayout>


<style lang="scss">
  @import "../../styles/global";
  @import "../../styles/inputFields";

  .material-design > .material-label-disabled > .material-text {
    background-color: white !important;
  }

  .date-time-field .input {
    height: 2rem !important;
    background-color: white !important;

    & > .material-label-disabled,
    & > .material-disabled > input {
      background-color: $grey-500;
      cursor: not-allowed;
    }

    & > .material-disabled > input,
    & > .material-disabled > :global(input.flatpickr-input) {
      color: $grey-800;
      background-color: $grey-500;
      cursor: not-allowed;
    }
  }

  .open {
    border: 2px solid $primaryGreen;
    outline: none !important;
  }

  div :global(.flatpickr-input) {
    border: none;
    box-shadow: none;
    height: 20px;
    padding: 0;
    width: var(--input-width);

    &:disabled, &:read-only, &[readonly], &[disabled] {
      background-color: white;
    }

    &::placeholder {
      color: var(--placeholder-color);
    }
  }

  div.open + .material-label .material-text {
    font-size: 0.75rem;
    font-weight: 400;
    transform: translate(0, -140%);
    background-color: white;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    color: black;
  }

  :global(.flatpickr-day.selected) {
    background: $primaryGreen !important;
    border-color: $primaryGreen !important;
  }
</style>
