<script lang="ts">
    import {vehicleCategories} from "../atoms/vehicleCategoriesStore";
    import Card from "../atoms/Card.svelte";
    import {AutocompleteConfig, type AutocompleteFieldConfig} from "../types/autocompleteFieldConfig";
    import {beforeUpdate, createEventDispatcher, getContext, setContext, tick} from "svelte";
    import InputField from "../molecules/InputField.svelte";
    import {t} from "../../i18n/i18n";
    import DatePickerInput from "../atoms/DatePickerInput.svelte";
    import {inputFieldFormats} from "../utils/formatters";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import {type Offer} from "./types/offer";
    import type {RequestDto} from "./types/requestDto";
    import type {VehicleCategory} from "../optimizer/vehicleCategory";
    import type {Writable} from "svelte/store";
    import {DefaultInputFieldProps, type FontProps} from "../types/fontProps";

    const dispatch = createEventDispatcher();
    const request: Writable<RequestDto> = getContext("request");
    setContext("useMaterialDesign", true);
    const fontPropsWithBlackPlaceholder: FontProps = DefaultInputFieldProps("13px", 400, "0.75rem", "black", "black");


    export let offer: Offer;

    let vehicleCategoriesOptions: VehicleCategory[] | VehicleCategory;
    let vehiclesPlaceholder: string;
    let vehicleAutocompleteConfig: AutocompleteFieldConfig = AutocompleteConfig('id', 'label', true);
    let startDatePlaceholder: string;
    let endDatePlaceholder: string;

    // to keep the initialized vehicle categories from an existing offer, we need to use these temporary variables
    let offerVehiclesTemp: VehicleCategory[] | null = [];
    let offerVehiclesIdsPrefill: number[] | null = [];

    vehicleCategories.subscribe(vehicleCategories => {
        if (vehicleCategories) {
            buildVehicleCategoriesConfig(vehicleCategories);
        }
    });

    // to keep the vehicle categories that were coming from either the server or an offer that was just created, we need
    // to use the temporary variable and initialize the field with the prefill
    beforeUpdate(async () => {
        await tick();
        if (offer?.facts?.vehicles.length === 0 && offerVehiclesTemp && offerVehiclesTemp?.length > 0) {
            offerVehiclesIdsPrefill = [...offerVehiclesTemp.map(v => v.id)];
            offerVehiclesTemp = null;
        }
    });

    const editedOffer = () => dispatch("editOffer"); // we just notify the parent that any field has been changed

    function buildVehicleCategoriesConfig(value: VehicleCategory[]) {
        // The includes() method may not work correctly for reference type objects.
        // Instead, it's better to compare based on a specific property of the objects.
        // using some() method to iterate through vehicles array and compare with each value
        let filteredOptions = value.filter(val => $request?.facts.vehicles.some(vehicle =>
            vehicle.label === val.label
        ));

        vehiclesPlaceholder = filteredOptions && filteredOptions.length > 0 ? filteredOptions.map(val => val.label).join(", ") : $t('UI.counterOffer.vehicleCategories.is.Unlimited');
        vehicleAutocompleteConfig = vehicleAutocompleteConfig;
        vehicleCategoriesOptions = filteredOptions.length > 0 ? filteredOptions : value;
    }

    $: $request?.facts?.start, startDatePlaceholder = $request?.facts.startAsFormattedDate ?? "";
    $: $request?.facts?.end, endDatePlaceholder = $request?.facts.endAsFormattedDate ?? "";

    // to not have the offer vehicle categories overwritten, we need to save them in a temporary variable and then rewrite it to the offer
    $: if (offer?.facts.vehicles?.length > 0 && offerVehiclesTemp) {
        offerVehiclesTemp = [...offer?.facts?.vehicles]
    }

</script>

<Card borderRadius="0.625rem" boxShadow=""> <!-- to unset, boxShadow needs to be empty -->
    <div class="offer-facts-container">
        <InputField id={`inputVolume-${offer?.requestType.toLowerCase()}`}
                    bind:value={offer.facts.volume} label={$t('UI.lot.volume.label')}
                    fontProps={fontPropsWithBlackPlaceholder}
                    placeholder={$request?.facts?.volume || ""} addonText={$t('UI.unit.ton.short.small')} isNumeric
                    on:input={editedOffer}/>
        <InputField id={`dailyCapacity-${offer?.requestType.toLowerCase()}`}
                    bind:value={offer.facts.dailyCapacity} label={$t('Offer.dailyVolume')}
                    fontProps={fontPropsWithBlackPlaceholder}
                    placeholder={$request?.facts.dailyCapacity || ""} addonText={$t('UI.unit.ton.short.small')}
                    isNumeric
                    on:input={editedOffer}/>
        <AutocompleteInputField id={`offerVehicleCategories-${offer?.requestType.toLowerCase()}`}
                                bind:value={offerVehiclesIdsPrefill}
                                bind:selectedObject={offer.facts.vehicles}
                                label={$t('UI.counterOffer.vehicleCategory.label')}
                                fontProps={fontPropsWithBlackPlaceholder}
                                placeholder={vehiclesPlaceholder} options={vehicleCategoriesOptions}
                                autocompleteConfig={vehicleAutocompleteConfig} hideDropdownArrow={false}
                                on:change={editedOffer}/>
        <div class="offer-facts-container__dates">
            <DatePickerInput id={`startDate-${offer?.requestType.toLowerCase()}`}
                             bind:date={offer.facts.start}
                             label={$t('UI.startDate.label')} placeholder={startDatePlaceholder}
                             fontProps={fontPropsWithBlackPlaceholder}
                             format={inputFieldFormats.COMPACT}
                             on:change={editedOffer}/>
            <DatePickerInput id={`endDate-${offer?.requestType.toLowerCase()}`}
                             bind:date={offer.facts.end}
                             label={$t('UI.endDate.label')} placeholder={endDatePlaceholder}
                             fontProps={fontPropsWithBlackPlaceholder}
                             format={inputFieldFormats.COMPACT}
                             on:change={editedOffer}/>
        </div>
    </div>
</Card>

<style lang="scss">
  @import "../../styles/global";

  :global(.card) {
    --card-width: 14.625rem;

    @media screen and (max-width: 992px) {
      --card-width: 100%;
    }
  }

  .offer-facts-container {
    @include flex-col(1.25rem, $alignment: flex-start);
    margin: 1.25rem;

    .offer-facts-container__dates {
      @include flex-row(1.5rem, $justify: flex-end, $alignment: center);
      width: 100%;
    }
  }

</style>
