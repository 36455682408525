<!--suppress JSValidateTypes -->
<script lang="ts">
    import Map from "./Map.svelte";
    import {setContext} from "svelte";
    import {jwt} from "../utils/jwt";
    import RouteAndMatchActionControl from "./controls/RouteAndMatchActionControl.svelte";
    import FakControl from "./controls/FakControl.svelte";
    import {mapFormControls} from "./fak/utils/fakStores";
    import {writable, type Writable} from "svelte/store";
    import Basic from "../templates/Basic.svelte";


    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);

    let ref: string = urlParams.get("ref") || "";
    let refLot: string = "";
    let startObject: string = "";
    let match: string = urlParams.get("match") || "";
    if (ref) {
        if (ref.startsWith("LOT-")) {
            refLot = ref.substring(4);
        } else if (ref.startsWith("PROJECT-")) {
            startObject = ref;
        }
    }
    let formProjectId: string | null = urlParams.get("formProjectId");
    let formLotId: string | null = urlParams.get("formLotId");

    let minDistance: number = Number(urlParams.get("minDistance"));
    let maxDistance: number = Number(urlParams.get("maxDistance")) || 150;

    // Block map form for saas
    let allowMapForm = false;
    $: if (allowMapForm) {
        if (formProjectId) {
            $mapFormControls.projectId = formProjectId;
            if (formLotId) {
                $mapFormControls.lotId = formLotId;
            }
            $mapFormControls.level = 1;
        }
    }

    let streetViewPermission: boolean = false;
    let mapReady: boolean = false;
    let jwtReady: boolean = false;
    let container: HTMLElement;
    let map: Writable<google.maps.Map> = writable();
    let center: google.maps.LatLngLiteral = {lat: 48.8079392, lng: 9.19921149};

    window.initMap = function initMap() {
        $map = new google.maps.Map(container, {
            zoom: 12,
            center: center,
            gestureHandling: 'greedy',
            zoomControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT,
            },
            streetViewControl: streetViewPermission,
            streetViewControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            }
            // styles: mapStyles // optional
        });
        mapReady = true;
    }

    jwt.subscribe(claims => {
        if (claims) {
            if (claims.tloc) {
                center = {lat: Number(claims.tloc[0]), lng: Number(claims.tloc[1])};
            }
            allowMapForm = claims.perms && !claims.saas;
            streetViewPermission = claims.perms && claims.perms.includes('permission-street-view');
            jwtReady = !!claims.perms;
        }
    })

    setContext("map", map);

</script>

<svelte:head>
    <script src="/assets/js/tippy/popper.js" type="text/javascript"></script>
    <script src="/assets/js/tippy/tippy.js" type="text/javascript"></script>
    { #if jwtReady }
        <script defer async
                src="https://maps.googleapis.com/maps/api/js?key=AIzaSyB7sb3Nblnurt744h-KxBWRlLbdzu97Lx0&callback=initMap&libraries=places&v=weekly">
        </script>
    { /if  }
</svelte:head>

<Basic fullscreen>
    <Map bind:container={container}>
        <base target="_parent">
        { #if mapReady }
            { #if allowMapForm }
                <FakControl position={google.maps.ControlPosition.LEFT_TOP}/>
            {/if}
            <RouteAndMatchActionControl position={google.maps.ControlPosition.LEFT_TOP} {refLot}
                                        {startObject} {match} {allowMapForm}
                                        {minDistance} {maxDistance}/>
            <!--        TODO uncomment for seeing the usage examples -->
            <!--        <Marker></Marker>-->
            <!--        <Control position={google.maps.ControlPosition.LEFT_TOP}></Control>-->
        { /if   }
    </Map>
</Basic>

<style>
    :global(body) {
        padding: 0;
    }
</style>
