<script lang="ts">

    import Notifications from "../organisms/Notifications.svelte";
    import LoadingAnimations from "../organisms/LoadingAnimations.svelte";
    import MmResponsiveMenuBar from "../menubar/mm/MmResponsiveMenuBar.svelte";
    import FooterMM from "../molecules/FooterMM.svelte";
    import {manualOpenWhitelist, userIsLoggedIn} from "../stores";
    import Modals from "../organisms/Modals.svelte";
    import HelpBox from "../help/HelpBox.svelte";
    import {jwt} from "../utils/jwt";
    import WhitelistModal from "../whitelist/WhitelistModal.svelte";
    import {mapFormControls} from "../map/fak/utils/fakStores";
    import MediaQuery from "./MediaQuery.svelte";


    export let showLoadingAnimation: boolean = true;
    /**
     * Used when the content is the fullscreen (sirius iframe / map). Overwrites maxWidth
     */
    export let fullscreen: boolean = false;
    export let padding: string = '4.375rem 8.31rem 5.125rem 8.31rem';
    export let showHelpBox: boolean = true; // default, we don't want to show it on pub pages
    export let helpBoxOpen: boolean = false; // boolean to control if the help box is opened, needed especially for the sirius pages nested in the iFrame, we need to close it when navigating
    export let iFrameUrl: string = ""; // debug info for selenide tests/screenshots
    /**
     False for offerer page (not logged-in) and optimizer pages saas (not logged-in or different tenant).
     */
    export let showStandardHeaderFooter: boolean = true;

    /**
     * This is the default for all pages!
     */
    let maxWidth: string = '1110px';

    $: contentPadding = fullscreen ? '0' : padding;
    $: contentMobilePadding = fullscreen ? '0' : '2rem';
    $: contentMaxWidth = fullscreen ? '100%' : maxWidth;
    $: contentHeight = fullscreen ? 'calc(100vh - 111px)' : '100%';

    $: style = `
        --content-padding: ${contentPadding};
        --content-mobile-padding: ${contentMobilePadding};
        --content-max-width: ${contentMaxWidth};
        --content-height: ${contentHeight};
    `;

    $: showWhitelist = $userIsLoggedIn && (($jwt && $jwt.showwhitelist) || $manualOpenWhitelist);

</script>

{#if showStandardHeaderFooter}
    <MmResponsiveMenuBar/>
{/if}
<!-- FAK notifications need to be handled separately, so we show this Notifications component iff the FAK is closed (level === 0). -->
{#if $mapFormControls.level === 0}
    <Notifications id="main-notification-container" top="{showStandardHeaderFooter ? '6.938' : '9.376'}rem"
                   position={fullscreen ? "fixed" : "sticky"}/>
{/if}
<main {style}>
    <div class="main-content">
        <Modals/>
        {#if showHelpBox}
            <HelpBox bind:open={helpBoxOpen}/>
        {/if}
        <slot/>
    </div>
</main>
{#if showStandardHeaderFooter}
    <FooterMM bind:iFrameUrl/>
{/if}
{#if showLoadingAnimation}
    <LoadingAnimations/>
{/if}
{#if showWhitelist}
    <MediaQuery query="(min-width: 57rem)">
        <WhitelistModal bind:isOpen={showWhitelist}/>
    </MediaQuery>
{/if}

<style lang="scss">
  main {
    height: var(--content-height);
    // In case, fullscreen is false, we need to make sure, the height of the content is at least 100vh-111px, otherwise
    // the footer will not stick to the bottom.
    min-height: calc(100vh - 111px);
    padding: var(--content-padding);
    overflow: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .main-content {
    margin: 0 auto 0;
    max-width: var(--content-max-width);
    height: 100%;
  }

  @media (max-width: 1110px) {
    main {
      padding: var(--content-mobile-padding);
    }
  }
</style>
