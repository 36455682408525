<script lang="ts">

    import ExpandableSidebar from "../organisms/ExpandableSidebar.svelte";
    import Notifications from "../organisms/Notifications.svelte";
    import LoadingAnimation from "../organisms/LoadingAnimations.svelte";
    import Modals from "../organisms/Modals.svelte";
    import MmResponsiveMenuBar from "../menubar/mm/MmResponsiveMenuBar.svelte";
    import FooterMM from "../molecules/FooterMM.svelte";
    import {manualOpenWhitelist, userIsLoggedIn} from "../stores";
    import HelpBox from "../help/HelpBox.svelte";
    import {jwt} from "../utils/jwt";
    import WhitelistModal from "../whitelist/WhitelistModal.svelte";
    import MediaQuery from "./MediaQuery.svelte";

    export let sidebarOpened: boolean = false;
    export let sidebarPosition: 'right' | 'left' = 'right';
    export let contentMaxWidth: string = '1110px';
    export let showLoadingAnimation: boolean = true;
    export let showAnimationOnMount: boolean = true;
    export let alwaysShowHeaderFooter: boolean = false;

    $: headerHeight = $userIsLoggedIn || alwaysShowHeaderFooter ? 61 : 100;
    $: footerHeight = $userIsLoggedIn || alwaysShowHeaderFooter ? 50 : 0;
    $: contentPadding = sidebarOpened ? '4.375rem 38.31rem 5.125rem 8.31rem' : '4.375rem 8.31rem 5.125rem 8.31rem';

    $: style = `
        --header-height: ${headerHeight}px;
        --footer-height: ${footerHeight}px;
        --content-padding: ${contentPadding};
    `;

    $: showWhitelist = $userIsLoggedIn && (($jwt && $jwt.showwhitelist) || $manualOpenWhitelist);

</script>

{#if showLoadingAnimation}
    <LoadingAnimation showOnMount={showAnimationOnMount}/>
{/if}
{#if $userIsLoggedIn || alwaysShowHeaderFooter}
    <MmResponsiveMenuBar/>
    <div style="height: 61px; width: 100%"></div>
{/if}
<Modals/> <!-- must be included here already, otherwise the sidebar will not be hidden by the backdrop -->
<HelpBox/>
<Notifications  id="main-notification-container" top={$userIsLoggedIn || alwaysShowHeaderFooter ? '5.813rem' : '2rem'}
               padding={sidebarOpened ? '0 30rem 0 0' : '0'}/>
<main {style} class:isPubPage={!$userIsLoggedIn}>
    <section class="main-content {sidebarPosition}" class:sidebarOpened
             style="--content-max-width: {contentMaxWidth};">
        <slot name="main-content">
            <!--default content for empty slot -->
        </slot>
    </section>
    <ExpandableSidebar bind:open={sidebarOpened} position={sidebarPosition}>
        <div class="sidebar-content-padded">
            <slot name="sidebar-content">
                <!-- default content for empty slot-->
            </slot>
        </div>
    </ExpandableSidebar>
    <div id="selenide-bottom-anchor"></div><!--selenide anchor to scroll to bottom -->
</main>
{#if showWhitelist}
    <MediaQuery query="(min-width: 57rem)">
        <WhitelistModal bind:isOpen={showWhitelist}/>
    </MediaQuery>
{/if}
{#if $userIsLoggedIn || alwaysShowHeaderFooter}
    <FooterMM/>
{/if}

<style lang="scss">
  @import "../../styles/global";

  main {
    height: 100%;
    padding: var(--content-padding);
    transition: padding 400ms ease-out;
    -webkit-transition: padding 400ms ease-out;
    scroll-behavior: smooth;
    // Allows pub page footer to stay below content, but always at bottom of the page
    @include flex-col($alignment: normal, $justify: normal);
    min-height: calc(100vh - var(--header-height) - var(--footer-height));

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: 1420px) {
    main {
      padding: 2rem 6rem 2rem 2rem;
    }
  }

  .main-content {
    margin: 0 auto 0;
    height: 100%;
    width: 100%;
    max-width: var(--content-max-width);
  }

  .sidebarOpened {
    transition: width ease-out 600ms;
    -webkit-transition: width ease-out 600ms;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .sidebar-content-padded {
    padding: 2em 1.75em;
    overflow-y: auto;
    @extend .hide-scrollbar;
  }

  #selenide-bottom-anchor {
    visibility: hidden;
  }
</style>
