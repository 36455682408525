<script lang="ts">
    import {createEventDispatcher} from "svelte";
    import {t} from "../../i18n/i18n";
    import {fade} from "svelte/transition";
    import {CalcMethod} from "../types/enums";
    import OfferReactionButton, {ReactionButtons} from "./OfferReactionButton.svelte";

    const dispatch = createEventDispatcher();

    export let calcMethod: CalcMethod;

    const sendAccept = () => dispatch("acceptOffer");
    const sendDecline = () => dispatch("declineOffer");
    const sendEdit = () => dispatch("editOffer");


</script>

<div class="row" style="margin-top: 1.875rem">
    <div class="col-lg-12 col-sm-12">
        <div class="container-offer-reaction" in:fade={{duration: 400}}>
            <span>{$t('UI.Decisions.select.request.customer')}</span>
            {#if calcMethod === CalcMethod.NORMAL}
                <div class="counter-offer">
                    <OfferReactionButton label={$t('UI.optimizerPage.buttons.accept')} reaction={ReactionButtons.POSITIVE}
                                         tooltipMsg={$t('UI.optimizerPage.buttons.accept.info')}
                                         on:reactionBtnClick={sendAccept}/>
                    <OfferReactionButton label={$t('UI.optimizerPage.buttons.counteroffer')} reaction={ReactionButtons.NEUTRAL}
                                         tooltipMsg={$t('UI.optimizerPage.buttons.counteroffer.info')}
                                         on:reactionBtnClick={sendEdit}/>
                    <OfferReactionButton label={$t('UI.optimizerPage.buttons.decline')} reaction={ReactionButtons.NEGATIVE}
                                         tooltipMsg={$t('UI.optimizerPage.buttons.decline.info')}
                                         on:reactionBtnClick={sendDecline}/>
                </div>
            {/if}
            {#if calcMethod === CalcMethod.REQUEST_OFFER}
                <div class="request-offer">
                    <OfferReactionButton label={$t('UI.optimizerPage.buttons.offer')} reaction={ReactionButtons.POSITIVE}
                                         on:reactionBtnClick={sendEdit}/>
                    <OfferReactionButton label={$t('UI.optimizerPage.buttons.decline')} reaction={ReactionButtons.NEGATIVE}
                                         on:reactionBtnClick={sendDecline}/>
                </div>
            {/if}
        </div>
    </div>
</div>

<style lang="scss">
  @import "../../styles/global";

  .container-offer-reaction {
    @include flex-col(1rem, $alignment: flex-start);

    & > span:first-child {
      @include roboto-font(15px, 500, 1rem, black);
      text-align: left;
    }

    & > div.request-offer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1.438rem;
      grid-auto-rows: 1fr;
      width: 100%;
      @media screen and (max-width: 660px) {
        grid-template-columns: 1fr;
        grid-row-gap: 1.438rem;
      }
    }

    & > div.counter-offer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1rem;
      grid-auto-rows: 1fr;
      width: 100%;
      @media screen and (max-width: 992px) {
        grid-template-columns: 1fr;
        grid-row-gap: 1.438rem;
      }
    }
  }

</style>