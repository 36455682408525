import {translate} from "../../i18n/i18n";

export enum CalcMethod {
    NORMAL = "NORMAL",
    COUNTER_OFFER = "COUNTER_OFFER",
    REQUEST_OFFER = "REQUEST_OFFER"
}

export enum LotState {
    UNVERIFIED = "UNVERIFIED",
    VERIFICATION = "VERIFICATION",
    SUBMISSION = "SUBMISSION",
    PARKED = "PARKED",
    APPROVAL = "APPROVAL",
    BIDDING = "BIDDING",
    BIDDING_MAIL_SCHEDULED = "BIDDING.MAIL_SCHEDULED", // this is a dummy state, only for representing visually that optimizer mails were scheduled
    BIDDING_MAIL_SENT = "BIDDING.MAIL_SENT", // this is a dummy state, only for representing visually that optimizer mails were sent
    OPTIMIZED = "OPTIMIZED",
    HANDLING = "HANDLING",
    ACCOUNTING = "ACCOUNTING",
    TARGET_ACTUAL_VERIFICATION = "TARGET_ACTUAL_VERIFICATION",
    COMPLETED = "COMPLETED",
    DISCARDED = "DISCARDED"
}

export const OPTIMIZED_STATES = [
    LotState.OPTIMIZED,
    LotState.HANDLING,
    LotState.ACCOUNTING,
    LotState.TARGET_ACTUAL_VERIFICATION,
    LotState.COMPLETED,
    LotState.DISCARDED]

export const OPTIMIZED_STATES_WITHOUT_DISCARDED = [
    LotState.OPTIMIZED,
    LotState.HANDLING,
    LotState.ACCOUNTING,
    LotState.TARGET_ACTUAL_VERIFICATION,
    LotState.COMPLETED,
]

export enum LotType {
    DISPOSAL = "DISPOSAL",
    BACKFILL = "BACKFILL",
    MATERIAL_SALE = "MATERIAL_SALE",
    MATERIAL_NEED = "MATERIAL_NEED",
    RETURN_FREIGHT = "RETURN_FREIGHT"
}

export enum LotAttributes {
    GRAVEL = "gravel",
    SAND = "sand",
    SLIT = "slit",
    CLAY = "clay",
    REUSABLE = "reusable"
}

export const OFFERER_PAYS_KICKBACK = [LotType.BACKFILL, LotType.MATERIAL_SALE, LotType.RETURN_FREIGHT];// Materialannahme, Produktverkauf, Transportleistung
export const OPTIMIZER_PAYS_KICKBACK = [LotType.DISPOSAL, LotType.MATERIAL_NEED];//Entsorgung, Materialbedarf

export enum OfferViewMode {
    VIEW_OFFER,
    EDIT_OFFER,
    ACCEPT_OFFER,
    REJECT_OFFER,
}

export enum RejectionReason {
    NOT_INTERESTED = "NOT_INTERESTED",
    PRICE_MISMATCH = "PRICE_MISMATCH",
    TOO_FAR_AWAY = "TOO_FAR_AWAY",
    TOO_MUCH_MATERIAL = "TOO_MUCH_MATERIAL",
    NOT_ENOUGH_MATERIAL = "NOT_ENOUGH_MATERIAL",
    EXHAUSTED = "EXHAUSTED",
    CANNOT_PROCESS = "CANNOT_PROCESS"
}

export enum LoadingAnimation {
    MmLoader,
    FileUpload
}

export enum ButtonIcons {
    NONE = "NONE",
    PLUS = "PLUS",
    PLUS_BOLD = "PLUS_BOLD",
    LEFT_ARROW = "LEFT_ARROW",
    RIGHT_ARROW = "RIGHT_ARROW",
    CHECKMARK = "CHECKMARK",
    CHECKMARK_BOLD = "CHECKMARK_BOLD",
    TRASH = "TRASH",
    DOWNLOAD = "DOWNLOAD",
    UP_ARROW = "UP_ARROW",
    DOWN_ARROW = "DOWN_ARROW",
    PHONE = "PHONE"
}

export enum StaticChipListColor {
    LIGHT_BLUE = "LIGHT_BLUE",
    DARK_BLUE = "DARK_BLUE"
}

export enum InputIcons {
    NONE = "NONE",
    SEARCH_LENS = "SEARCH_LENS"
}

export enum ProjectState {
    OPEN = "OPEN",
    CLOSED = "CLOSED",
    CANCELED = "CANCELED"
}

export enum Salutation {
    SIR = "SIR",
    MADAM = "MADAM",
    MX = "MX",
}

export function getSalutationOptions() {
    return [
        {value: Salutation.MADAM, text: translate('de', 'Salutation.' + Salutation.MADAM.toString(), [])},
        {value: Salutation.SIR, text: translate('de', 'Salutation.' + Salutation.SIR.toString(), [])},
    ]
}

export enum DiscardReason {
    INTERN = "INTERN",
    TESTING = "TESTING",
    NO_ANSWER = "NO_ANSWER",
    NOT_OPTIMIZABLE = "NOT_OPTIMIZABLE",
    CANCELLED= "CANCELLED",
    DOUBLE_POSITION = "DOUBLE_POSITION"
}

export enum RejectReason {
    REJECTED = "REJECTED",
    INCORRECT_DATA = "INCORRECT_DATA",
    INCOMPLETE_INFORMATION = "INCOMPLETE_INFORMATION",
    DOCUMENTS_PROOFS_MISSING = "DOCUMENTS_PROOFS_MISSING",
    QUALITY_ASSURANCE = "QUALITY_ASSURANCE"
}

export enum DiscardReasonOnlySaas {
    PRICE = "PRICE",
    SERVICE_SPECTRUM = "SERVICE_SPECTRUM",
    NOT_MATERIALIZED = "NOT_MATERIALIZED",
    MISC = "OTHER"
}

export enum DiscardedObjectType {
    LOT,
    PROJECT
}

export enum AttachmentParentType {
    PARENT_LOT = "PARENT_LOT",
    PARENT_PROJECT = "PARENT_PROJECT",
    PARENT_OFFER = "PARENT_OFFER",
    PARENT_POP = "PARENT_POP",
    PARENT_PROFILE = "PARENT_PROFILE"
}

export enum AnalyticalType {
    ANALYSIS = "ANALYSIS",
    ANALYTIC = "ANALYTIC"
}

export enum AnalyticalParentType {
    LOT = "LOT",
    POP = "POP",
    NONE = "NONE"
}

export enum LotMailNotificationType {
    FIRST_MAIL = "FIRST_MAIL",
    SECOND_MAIL = "SECOND_MAIL",
    THIRD_MAIL = "THIRD_MAIL",
    FOURTH_MAIL = "FOURTH_MAIL",
    FIFTH_MAIL = "FIFTH_MAIL"
}

export enum TooltipPlacements {
    RIGHT = "right-start",
    LEFT = "left-start",
    TOP_START = "top-start",
    TOP_END = "top-end",
    BOTTOM_START = "bottom-start",
    BOTTOM_END = "bottom-end"
}

export enum HelpVisibility {
    NETWORK = "NETWORK",
    SAAS = "SAAS",
    INTERN = "INTERN"
}

export enum UserPermission {
    FLAG_LOGGED_IN = "flag-logged-in",
    MAP = "permission-map",
    VIEW_PROJECTS = "permission-view-projects",
    EDIT_LOT = "permission-edit-lot",
    VIEW_LOT = "permission-view-lot",
    BID_LOT = "permission-bid-lot",
    VIEW_SAAS_LOT = "permission-view-saas-lot",
    MANAGE_SAAS_LOT = "permission-manage-saas-lot",
    DASHBOARD = "permission-view-dashboard",
    FLAG_TARGET_PRICE = "flag-target-price",
    VIEW_TARGET_PRICE = "permission-view-targetPrice",
    EDIT_TARGET_PRICE = "permission-edit-targetPrice",
    FLAG_EBV_EVALUATION = "flag-ebv-evaluation",
    MANAGE_POPS = "permission-manage-pops",
    MANAGE_TENANTS = "permission-manage-tenants",
    MANAGE_SYSTEM_USER = "permission-manage-system-users",
    FLAG_SYSTEM_TENANT = "flag-system-tenant",
    FLAG_SYSTEM_TENANT_MEMBER = "flag-system-tenant-member",
    MANAGE_USER_ACCOUNTS = "permission-manage-user-accounts",
    MATERIAL_PRIORITIES_EDITOR = "permission-material-priorities-editor",
    MANAGE_BASEDATA = "permission-manage-basedata",
    MANAGE_MASTERDATA = "permission-manage-masterdata",
    MANAGE_FILES = "permission-manage-files",
    EXECUTE_JOBS = "permission-execute-jobs",
    MANAGE_PROCESSES = "permission-manage-processes",
    MANAGE_CODE_LISTS = "permission-manage-code-lists",
    SYSTEM_PROTOCOLS = "permission-system-protocols",
    SYSTEM_JOURNAL = "permission-system-journal",
    SYSTEM_TAGS = "permission-system-tags",
    CONTROL_DISASTER_MODE = "permission-control-disaster-mode",
    SYSTEM_CONSOLE = "permission-system-console",
    SYSTEM_TIMING = "permission-system-timing",
    FLAG_SYSTEM_ADMINISTRATOR = "flag-system-administrator",
    SYSTEM_CLUSTER = "permission-system-cluster",
    SYSTEM_SCRIPTING = "permission-sytem-scripting", // Yes, this typo exists in sirius-biz...
    SYSTEM_BACKUPS = "permission-system-backups",
    SELECT_TENANT = "permission-select-tenant",
    SELECT_USER_ACCOUNT = "permission-select-user-account",
    EDIT_HELP_ENTRIES = "permission-edit-help-entries",
    FLAG_ANALYSIS_CHECK = "flag-analysis-check",
    ANALYSIS_CHECK = "permission-analysis-check",
    CUSTOMIZE_PUBPAGES= "permission-customize-pubpages",
}
