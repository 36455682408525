<script lang="ts">

    import ResponsiveMenuBar from "../ResponsiveMenuBar.svelte";
    import TargetPriceMenuItem from "./menuitems/TargetPriceMenuItem.svelte";
    import LotMenuItem from "./menuitems/LotMenuItem.svelte";
    import ProjectsMenuItem from "./menuitems/ProjectsMenuItem.svelte";
    import RegistryMenuItem from "./menuitems/RegistryMenuItem.svelte";
    import MenuItem from "../MenuItem.svelte";
    import ProfileMenuItem from "./menuitems/ProfileMenuItem.svelte";
    import AdminMenuItem from "./menuitems/AdminMenuItem.svelte";
    import Permission from "../Permission.svelte";
    import {UserPermission} from "../../types/enums";
    import {t} from "../../../i18n/i18n";
</script>

<ResponsiveMenuBar>
    <svelte:fragment slot="menu-start">
        <Permission requiredPermissions={UserPermission.FLAG_LOGGED_IN}>
            <!-- Karte -->
            <MenuItem title={$t("Map")} url="/map" permissions={UserPermission.MAP}/>
            <!-- Projekte -->
            <ProjectsMenuItem/>
            <!-- Positionen -->
            <LotMenuItem/>
            <!-- Dashboard -->
            <MenuItem title={$t("Dashboard.title")} url="/dashboard" permissions={UserPermission.DASHBOARD}/>
            <!-- Richtpreis -->
            <TargetPriceMenuItem/>
            <!-- Einbauweisen -->
            <MenuItem title={$t("InstallationCases.title")} url="/installationCases"
                      permissions={UserPermission.FLAG_EBV_EVALUATION}/>
            <!-- Analyse Check -->
            <MenuItem title={$t("AnalysisCheck.menu.title")} url="/analysis"
                      permissions={UserPermission.ANALYSIS_CHECK}/>
            <!-- Verwalten -->
            <RegistryMenuItem/>
        </Permission>
        <MenuItem title={$t("Button.faq")} url="https://mineral-minds.de/so-funktionierts-netzwerk/" openInNewTab/>
    </svelte:fragment>
    <svelte:fragment slot="menu-end" let:hamburgerActive>
        <Permission requiredPermissions={UserPermission.FLAG_LOGGED_IN}>
            <AdminMenuItem {hamburgerActive}/>
            <ProfileMenuItem {hamburgerActive}/>
        </Permission>
    </svelte:fragment>
</ResponsiveMenuBar>
