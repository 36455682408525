const CHIP_ID: string = "autosave-info-chip";
const LOADER_ID: string = "autosave-info-loader";
const CHECKMARK_ID: string = "autosave-info-checkmark";
const CROSSMARK_ID: string = "autosave-info-crossmark";
const AUTOSAVE_TEXT_ID: string = "autosave-info-text";

export function hideAutosaveInfo() {
    const chip = document.getElementById(CHIP_ID);
    if (chip) chip.style.opacity = "0";
}

export function showAutosaveLoading() {
    hideAll();
    const loader = document.getElementById(LOADER_ID);
    const chip = document.getElementById(CHIP_ID);
    const checkmark = document.getElementById(CHECKMARK_ID);
    const autosaveText = document.getElementById(AUTOSAVE_TEXT_ID);
    if (autosaveText) autosaveText.textContent = "Speichern...";
    if (loader) {
        loader.style.display = "block";
        loader.classList.remove("fak-load-complete");
    }
    if (checkmark) checkmark.style.display = "none";
    if (chip) {
        chip.style.display = "flex";
        chip.style.opacity = "1";
    }
}

export function showAutosaveCheckmark(info = "") {
    hideAll();
    const loader = document.getElementById(LOADER_ID);
    const checkmark = document.getElementById(CHECKMARK_ID);
    const chip = document.getElementById(CHIP_ID);
    const autosaveText = document.getElementById(AUTOSAVE_TEXT_ID);
    if (autosaveText) autosaveText.textContent = "Alle Änderungen gespeichert! " + info;
    if (loader) {
        loader.style.display = "block";
        loader.classList.add("fak-load-complete");
    }
    if (checkmark) checkmark.style.display = "block";
    if (chip) {
        chip.style.opacity = "1";
        setTimeout(() => chip.style.opacity = ".9", 2000);
    }
}

export function showAutosaveCrossmark(info = "") {
    hideAll();
    const chip = document.getElementById(CHIP_ID);
    const crossmark = document.getElementById(CROSSMARK_ID);
    const autosaveText = document.getElementById(AUTOSAVE_TEXT_ID);
    if (autosaveText) autosaveText.textContent = "Fehler bei Speichern! " + info;
    if (crossmark) crossmark.style.display = "block";
    const xMarkClasses = document.getElementsByClassName("addClass");
    for (let i = 0; i < xMarkClasses.length; ++i) {
        xMarkClasses[i].classList.add("animateElement");
    }
    setTimeout(() => {
        if (chip) chip.style.opacity = ".9"
    }, 2000);
}

function hideAll() {
    const loader = document.getElementById(LOADER_ID);
    const checkmark = document.getElementById(CHECKMARK_ID);
    const crossmark = document.getElementById(CROSSMARK_ID);
    if (loader) loader.style.display = "none";
    if (checkmark) checkmark.style.display = "none";
    if (crossmark) crossmark.style.display = "none";
    const xMarkClasses = document.getElementsByClassName("addClass");
    for (let i = 0; i < xMarkClasses.length; ++i) {
        xMarkClasses[i].classList.remove("animateElement");
    }
}


