<script lang="ts">
    import {createRender, createTable, Render, Subscribe} from "svelte-headless-table";
    import Tooltip from "../atoms/Tooltip.svelte";
    import Empty from "../atoms/Empty.svelte";
    import InputFieldTableCell from "./InputFieldTableCell.svelte";
    import AnalyticEvaluationTableCell from "./AnalyticEvaluationTableCell.svelte";
    import FootnoteIconCell from "./FootnoteIconCell.svelte";
    import {createEventDispatcher, getContext, setContext} from "svelte";
    import {AnalyticalType} from "../types/enums";
    import RemoveAnalysisValueCell from "./RemoveAnalysisValueCell.svelte";
    import {inputFieldFormats} from "../utils/formatters";
    import ttImg from '@/icons/icon_info_blue_outline.svg';
    import type {Writable} from "svelte/store";
    import {analyticalMetadata, hideOptionalValues, values} from "./stores";
    import type {AnalyticDto} from "./types/analyticDto";
    import type {AnalyticalValue} from "./types/analyticalValue";

    setContext('useMaterialDesign', false);
    const dispatch = createEventDispatcher()
    const stickyHeaderTop: Writable<string> = getContext("stickyHeightInformation");

    // For recommendations, we need to pass the analysis values manually, otherwise, the standard store is used.
    export let analysisValues: Writable<AnalyticalValue[]> = values;
    export let analytic: AnalyticDto | null = null;
    export let searchTerm: string = '';
    export let editable: boolean = true;
    export let compact: boolean = false;

    let type: AnalyticalType | null = $analyticalMetadata.analyticalType;


    const matchesSearch = (item, searchTerm) => {
        let isOptional = analytic ? analytic.optionalValues.includes(item.key) : false;
        let isMatchingSearch = searchTerm ?
            stringMatch(item.name, searchTerm)
            || stringMatch(item.extractionMethod, searchTerm)
            || stringMatch(item.title, searchTerm)
            || stringMatch(item.unit, searchTerm)
            : true; // TODO better search matching

        return (!$hideOptionalValues || $hideOptionalValues && !isOptional && !item.additional) && isMatchingSearch;
    }

    const stringMatch = (text, searchTerm) => {
        let matches = false;
        if (text) {
            matches = text.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return matches;
    }

    const showTitle = (rows, idx, item, searchTerm) => {
        if (compact) {
            return false;
        }

        if (idx === 0) {
            return true;
        }

        let lastShownItem = item;
        let searchIdx = idx - 1;
        while (searchIdx >= 0) {
            if (matchesSearch(rows[searchIdx].original, searchTerm)) {
                lastShownItem = rows[searchIdx].original;
                return lastShownItem.title !== item.title;
            }

            searchIdx--;
        }

        // return whether the last showing item is the item
        return lastShownItem == item;
    }

    // noinspection TypeScriptValidateTypes
    const EditableCell /*: DataLabel<Sample>*/ = ({column, row, value}) =>
        createRender(InputFieldTableCell, {
            row,
            column,
            value,
            onUpdateValue: updateData,
            isNumeric: false,
            placeholder: "Wert",
            editable,
            errorHighlight: row.original.valueError ? row.original.valueError != "NONE" : false,
            format: compact ? inputFieldFormats.COMPACT : inputFieldFormats.NORMAL
        })

    const updateData = (rowDataId, columnId, newValue) => {
        // In this case, the dataId of each item is its index in $data.
        // You can also handle any server-synchronization necessary here.
        const idx = parseInt(rowDataId);
        const currentItem = $analysisValues[idx];
        const key = columnId; // Cast as `keyof YourDataItem`
        const newItem = {...currentItem, [key]: newValue};
        $analysisValues[idx] = newItem;
        $analysisValues = $analysisValues;
        dispatch("updateValue")
    }

    // noinspection TypeScriptValidateTypes
    const EvaluationCell = ({_, __, value}) =>
        createRender(AnalyticEvaluationTableCell, {
            evaluation: value
        })

    // TODO extractionMethod as tooltip if compact
    const valuesTable = createTable(analysisValues);
    let cols = [];

    cols.push(
        valuesTable.column({
            header: 'Stoff',
            accessor: 'name'
        })
    )
    cols.push(
        valuesTable.column({
            header: '',
            accessor: 'title',
            cell: ({row}) => {
                if (row.original.title) {
                    // noinspection TypeScriptValidateTypes
                    return createRender(Tooltip, {
                        msg: row.original.title,
                        ttipIconImage: ttImg,
                    })
                } else {
                    // noinspection TypeScriptValidateTypes
                    return createRender(Empty, {})
                }
            }
        }),
    )
    cols.push(
        valuesTable.column({
            header: 'Einheit',
            accessor: (item) => item.unit ? item.unit : ""
        }),
    )
    if (compact) {
        cols.push(
            valuesTable.column({
                header: '',
                cell: ({row}) => {
                    if (row.original.extractionMethod) {
                        // noinspection TypeScriptValidateTypes
                        return createRender(Tooltip, {
                            msg: row.original.extractionMethod,
                            ttipIconImage: ttImg,
                        })
                    } else {
                        // noinspection TypeScriptValidateTypes
                        return createRender(Empty, {})
                    }
                }
            })
        )
    } else {
        cols.push(
            valuesTable.column({
                header: 'Methode',
                accessor: (item) => item.extractionMethod ? item.extractionMethod : ""
            }),
        )
    }
    cols.push(
        valuesTable.column({
            header: type === AnalyticalType.ANALYTIC ? 'Grenzwert' : 'Wert',
            accessor: 'value',
            cell: EditableCell
        })
    )
    if (type === AnalyticalType.ANALYSIS) {
        cols.push(
            valuesTable.column({
                header: 'Auswertung',
                accessor: 'evaluation',
                cell: EvaluationCell
            })
        )
    }
    if (!compact) {
        cols.push(
            valuesTable.column({
                header: '',
                accessor: 'footnote',
                cell: ({row}) => {
                    if (row.original.additional) {
                        // noinspection TypeScriptValidateTypes
                        return createRender(RemoveAnalysisValueCell, {
                            valueKey: row.original.key,
                            editable,
                            onValuesRemoved: () => dispatch("valuesRemoved")
                        })
                    } else {
                        // noinspection TypeScriptValidateTypes
                        return createRender(FootnoteIconCell, {
                            footnote: row.original.footnote
                        })
                    }
                }
            })
        )
    }

    let valuesColumns = valuesTable.createColumns(cols)

    const {
        headerRows,
        rows,
        tableAttrs,
        tableBodyAttrs,
    } = valuesTable.createViewModel(valuesColumns);

    $: $analyticalMetadata.analyticalType, type = $analyticalMetadata.analyticalType;

</script>

<table {...$tableAttrs} class="analysis-table" class:compact={compact}>
    <thead>
    {#each $headerRows as headerRow (headerRow.id)}
        <Subscribe rowAttrs={headerRow.attrs()} let:rowAttrs>
            <tr {...rowAttrs} class="analysis-table-header" style="--sticky-header-top: {$stickyHeaderTop}">
                {#each headerRow.cells as cell (cell.id)}
                    <Subscribe attrs={cell.attrs()} let:attrs>
                        <th {...attrs}
                            class="analysis-table-header-cell analysis-table-header-cell-{cell.id}">
                            <Render of={cell.render()}/>
                        </th>
                    </Subscribe>
                {/each}
            </tr>
        </Subscribe>
    {/each}
    </thead>
    <tbody {...$tableBodyAttrs}>

    {#each $rows as row, i (row.id)}
        {#if (matchesSearch(row.original, searchTerm))}
            <Subscribe rowAttrs={row.attrs()} let:rowAttrs>
                {#if showTitle($rows, i, row.original, searchTerm)}
                    <tr class="analysis-table-section">
                        <td colspan="7">{row.original.title ? row.original.title : "ohne Kategorie"}</td>
                    </tr>
                {/if}
                <tr {...rowAttrs} class="analysis-table-row" id={row.original.key}>
                    {#each row.cells as cell (cell.id)}
                        <Subscribe attrs={cell.attrs()} let:attrs>
                            <td {...attrs} class="analysis-table-cell analysis-table-cell-{cell.id}">
                                <Render of={cell.render()}/>
                            </td>
                        </Subscribe>
                    {/each}
                </tr>
            </Subscribe>
        {/if}
    {/each}
    </tbody>
</table>

<style lang="scss">
  @import "../../styles/global";

  .analysis-table {
    width: 100%;
  }

  .analysis-table-header {
    @include roboto-font(1.125rem, 400, 1rem);
    height: 24px;
    box-shadow: var(--table-shadow-bottom, inset 0 -1px 0 $primaryGreen); // we use this with position sticky to have a border at the bottom
    position: sticky;
    z-index: 1;
    background-color: white;
    // Two times padding of 1.88rem (~30px) + 61px header
    top: calc(var(--sticky-header-top, 30px) + var(--sticky-header-top-padding, 121px));
  }

  .analysis-table-header-cell {
    padding: 10px 13px;
  }

  .compact .analysis-table-header-cell {
    @include roboto-font(18px, 400, 10px, #000000);
    padding: 10px 13px;
  }

  .analysis-table-header-cell-name {
    padding-left: 24px;
  }

  .analysis-table-section {
    background: linear-gradient(90deg, rgba(192, 192, 192, 0.2) 99.99%, rgba(217, 217, 217, 0) 100%);
    border-bottom: 1px solid #e9e9e9;
    height: 33px;
  }

  .analysis-table-section > td {
    padding-left: 24px;
  }

  .analysis-table-row {
    border-bottom: 1px solid #E9E9E9;
  }

  .compact.analysis-table > tbody > tr:last-child {
    border-bottom: none;
  }

  .analysis-table-cell {
    padding: 13px;
  }

  .compact .analysis-table-cell {
    @include roboto-font(18px, 400, 10px, #000000);
  }

  .analysis-table-cell-name {
    padding-left: 24px;
  }

  .compact .analysis-table-cell-name {
    padding-left: 13px;
  }

  .analysis-table-cell-title {
    padding-right: 30px;
  }
</style>