<script lang="ts">
    import {getContext, onMount, setContext} from "svelte";
    import {t} from "../../i18n/i18n";
    import {type Lot} from "../types/lot";
    import Salutation from "../atoms/Salutation.svelte";
    import InfoCard from "../organisms/InfoCard.svelte";
    import ProjectInformationGrid from "../project/ProjectInformationGrid.svelte";
    import TextArea, {textAreaHeights} from "../atoms/TextArea.svelte";
    import SecondaryButton from "../atoms/SecondaryButton.svelte";
    import {ButtonIcons} from "../types/enums";
    import FileUpload from "../organisms/FileUpload.svelte";
    import {savePublicComment, updateAttachmentsLot} from "../pages/OffererPubPage.svelte";
    import ActualTargetComparison from "../organisms/ActualTargetComparison.svelte";
    import PublicPageHeader from "../molecules/PublicPageHeader.svelte";
    import PublicPageFooter from "../molecules/PublicPageFooter.svelte";
    import {userIsLoggedIn} from "../stores";
    import Basic from "../templates/Basic.svelte";
    import ContactBox from "../atoms/ContactBox.svelte";
    import type {PubPageParams} from "../bid/types/pubPageParams";
    import {writable, type Writable} from "svelte/store";
    import {fetchUtils} from "../utils/fetchUtils";
    import type {Deal} from "../bid/types/deal";
    import type {DealPartnerConnect} from "../types/dealPartner";
    import {Facts} from "../bid/types/facts";
    import type {DealActualValues} from "../types/dealActualValues";
    import {fade} from "svelte/transition";
    import LoadingSkeleton from "../animations/LoadingSkeleton.svelte";

    const urlParams: PubPageParams = getContext("urlParams");
    const lot: Writable<Lot> = getContext("lot");
    const deal: Writable<Deal | null> = setContext("deal", writable(null));
    const dealPartnerConnect: Writable<DealPartnerConnect | null> = setContext("dealPartnerConnect", writable(null));
    const {lotPublicId} = urlParams;


    let attachmentsLot: any[] = [];

    onMount(async () => {
        await fetchDealData();
        attachmentsLot = await updateAttachmentsLot(lotPublicId ?? $lot.publicId);
    });


    let attachmentsUploadUrl: string;

    // we get the deal and actual data via the lot public id, since we now have exactly one specific partner which is
    // retrievable via the lot id
    async function fetchDealData() {
        await fetchUtils.get(`/api/offerer/lot/${lotPublicId}/dealAndActual`).then((data) => {
            if (data.deal) {
                let offererFacts: Facts = new Facts(data.deal?.offerActualValues?.totalFacts);
                let requestFacts: Facts = new Facts(data.deal?.requestActualValues?.totalFacts);
                let offerActualValues: DealActualValues = {
                    ...data.deal.offerActualValues,
                    totalFacts: offererFacts
                };
                let requestActualValues: DealActualValues = {
                    ...data.deal.requestActualValues,
                    totalFacts: requestFacts
                }
                $deal = {
                    ...data.deal,
                    dealFacts: new Facts(data.deal?.dealFacts),
                    offerActualValues,
                    requestActualValues
                }
                $dealPartnerConnect = {...data.contactData}
            }

        })
    }

    $: {
        if (lotPublicId) {
            attachmentsUploadUrl = '/api/lot/pub/' + lotPublicId + '/attachment/new';
        }
    }
</script>

{#if !$userIsLoggedIn}
    <PublicPageHeader/>
{/if}

<Basic showStandardHeaderFooter={$userIsLoggedIn}>
    {#await fetchDealData()}
        <div out:fade={{duration: 300}}>
            <LoadingSkeleton repeat={6}/>
        </div>
    {:then _}
        <div in:fade={{delay: 50}}>
            <div class="container-salutation-contact">
                <Salutation name={$lot.personAddressableName} address={$lot.address.zip + " " + $lot.address.city}/>
                {#if $userIsLoggedIn}
                    <ContactBox/>
                {/if}
            </div>
            <div class="row">
                <div class="col-lg-5 col-md-12 col-sm-12">
                    <InfoCard offererPage/>
                </div>
                <div class="col-lg-7 col-md-12 col-sm-12">
                    <div class="container-project-information" style="row-gap: 0.55rem">
                        <ProjectInformationGrid title={$t('UI.project.information.updated')} anonymizeAddress={false}
                                                {...$lot} titleGreen/>
                        <TextArea title={$t("UI.materialDetailsComment.header")} text={$lot.lotRemarks}
                                  placeholder={$t('UI.remarks.noRemarks')} height={textAreaHeights.SMALL} readonly/>
                        <TextArea title={$t("UI.projectDetailsComment.header")} text={$lot.projectRemarks}
                                  placeholder={$t('UI.remarks.noRemarks')} readonly isHtml/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xs-12">
                <TextArea title={$t("UI.Model.moreDetails")} text={$lot.furtherDetailsComment}
                          placeholder={$t('UI.remarks.noRemarks')} height={textAreaHeights.SMALL} readonly/>
                </div>
            </div>
            <div class="row" style="margin-top: 1.875rem">
                <div class="col-lg-12 col-sm-12">
                    <FileUpload uploadUrl={attachmentsUploadUrl}
                                filePrefix={$t('UI.attachments.offerer.prefix')}
                                visibleToCustomer={1} visibleToPartner={0}
                                updateFun={() => updateAttachmentsLot(lotPublicId || $lot.publicId).then(data => attachmentsLot = data)}
                                attachments={attachmentsLot}/>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-sm-12">
                    <ActualTargetComparison isOfferer readOnly/>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-sm-12">
                <TextArea title={$t('UI.remarks.label')} bind:text={$lot.remarks}
                          placeholder={$t("UI.pubRemarks.placeholder.detailedInfo")}/>
                    <div class="align-right" style="margin-top: 1.125rem">
                        <SecondaryButton label={$t("UI.saveRemark")} leftIcon={ButtonIcons.CHECKMARK}
                                         on:click={() => savePublicComment($lot.remarks, lotPublicId)}/>
                    </div>
                </div>
            </div>
        </div>
    {/await}
</Basic>

{#if !$userIsLoggedIn}
    <PublicPageFooter/>
{/if}


<style lang="scss">
  @import "../../styles/pubPage";

  :global(.loading-skeleton__container) {
    position: relative !important;
  }

</style>
