<script lang="ts">
    import {setLocale} from "yup";

    /** They can later be used like this:
     * try {
     *   await schema.validate({ name: 'jimmy', age: 11 });
     * } catch (err) {
     *   messages = err.errors.map((err) => $t('UI.foo.bar.' + err.key, {value: err.values}));
     * }
     *
     * what allows to use i18n values without the need to specify every error message explicitly in the validation schema
     */

    setLocale({
        mixed: {
            oneOf: (params) => ({key: 'oneOf', value: {params}}),
        },
        number: {
            min: ({min}) => ({key: 'tooShort', value: min}),
            max: ({max}) => ({key: 'tooLong', value: max}),
        },
    })
</script>