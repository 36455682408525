import {translate} from "../../i18n/i18n";
import {Salutation} from "./enums";
import {boolean, mixed, object, string} from "yup";
import {deepEqual, hashString} from "../utils/utils";
import type {AddressData} from "./addressData";
import {addressSchema, copyAddressData, DefaultAddressData} from "./addressData";

export type PersonAndCompanyData = {
    id: string,
    salutation: Salutation,
    title: string,
    firstName: string,
    lastName: string,
    email?: string,
    phone?: string,
    companyName: string,
    address: AddressData,
    link?: string,
    onboardingComment?: string,
    text: string,
    showCompany: boolean
}

export function DefaultPersonAndCompanyData() {
    let personAndCompanyData: PersonAndCompanyData = {
        id: "",
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
        salutation: null,
        title: null,
        companyName: null,
        address: DefaultAddressData(),
        link: null,
        text: "",
        showCompany: false
    };
    return personAndCompanyData;
}

export function copyPersonAndCompanyData(personAndCompanyData, regenerateId = false) {
    if (!personAndCompanyData) return null;
    let copy = DefaultPersonAndCompanyData();
    Object.assign(copy, personAndCompanyData);
    copy.address = copyAddressData(personAndCompanyData.address);
    if (regenerateId && !copy.id) {
        copy.id = generatePersonAndCompanyDataId(copy);
    }
    return copy;
}

export function generatePersonAndCompanyDataId(personAndCompanyData: PersonAndCompanyData) {
    let str =  personAndCompanyData.salutation + personAndCompanyData.title + personAndCompanyData.firstName + personAndCompanyData.lastName + personAndCompanyData.email +
        personAndCompanyData.phone + personAndCompanyData.companyName + personAndCompanyData.address.street + personAndCompanyData.address.zip + personAndCompanyData.address.city;
    return hashString(str);
}

export function equalsPersonAndCompanyData(p1: PersonAndCompanyData, p2: PersonAndCompanyData) {
    return deepEqual(p1, p2);
}

export function getPersonAndCompanyDataAutocompleteText(personAndCompanyData: PersonAndCompanyData, withBrackets: boolean = true) {
    let s = `${personAndCompanyData.firstName} ${personAndCompanyData.lastName}`
    if (personAndCompanyData.salutation !== null) {
        let translatedSalutation = translate('de', 'Salutation.' + personAndCompanyData.salutation, []);
        s = `${translatedSalutation} ` + s;
    }
    if (withBrackets) {
        return '(' + s + ')';
    }
    return s;
}

export const personAndCompanySchema = object({
    id: string().notRequired(),
    salutation: mixed().when('lastName', {
        is: lastName => lastName && lastName.length > 0,
        then: schema => schema.required(translate('de', 'UI.validation.person.salutation.required', []))
            .oneOf(Object.values(Salutation), ({value}) => translate('de', 'UI.validation.person.salutation.oneOf', [{value: value}])),
        otherwise: schema => schema.notRequired()
    }),
    title: string().notRequired(),
    firstName: string().notRequired(),
    companyName: string().notRequired(),
    address: object().when('companyName', {
        is: companyName => companyName && companyName.length > 0,
        then: schema => addressSchema.required(translate('de', 'UI.validation.address.required', [])),
        otherwise: schema => schema.notRequired()
    }),
    lastName: string().notRequired(),
    email: string().when('companyName', {
        is: companyName => companyName && companyName.length > 0,
        then: schema => schema.required(translate('de', 'UI.validation.personData.email.required', []))
            .email(translate('de', 'UI.validation.personData.email.format', [])),
        otherwise: schema => schema.notRequired()
    }),
    phone: string().notRequired(),
    link: string().notRequired(),
    onboardingComment: string().notRequired(),
    showCompany: boolean().required(),
    // text: string().notRequired() not needed for server
}).test('person-or-link', translate('de', 'UI.validation.personOrLink.required', []), (test) => {
    return !((!test.lastName || !test.email) && !test.link);
}).test('company-or-link', translate('de', 'UI.validation.personOrLink.required', []), (test) => {
    return !test.showCompany || !((!test.companyName || !Object.keys(test.address).length) && !test.link);
})
