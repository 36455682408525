<script lang="ts">

    import FileUpload from "../../../organisms/FileUpload.svelte";
    import {fetchUtils} from "../../../utils/fetchUtils";
    import {addNotification} from "../../../stores";
    import {DefaultAttachmentView} from "../../../types/attachment";
    import {derived} from "svelte/store";
    import {currentLot} from "../utils/fakStores";
    import {DefaultNotification, type Notification, NotificationType} from "../../../types/notification";
    import {tick} from "svelte";


    let attachments;
    let fakHTMLElement: Element = document.getElementsByClassName('fak-base-layout')[0];
    const filePrefix = ""; // TODO make file prefix
    const hideHints = false;
    const visibilityUrl = "/api/quote/attachment/";

    async function fetchAttachments() {
        if ($currentLot && $currentLot.id !== "new") {
            await fetchUtils.get(`/api/lot/${$currentLot.id}/quoteAttachments`, false)
                .then(data => {
                    attachments = [];
                    for (let i = 0; i < data.length; i++) {
                        let a = DefaultAttachmentView();
                        Object.assign(a, data[i]);
                        attachments.push(a);
                    }
                })
                .catch(error => {
                    let notification: Notification = DefaultNotification(error.message, NotificationType.ERROR);
                    addNotification(notification);
                });
        }
    }

    let currentLotId = derived(
        currentLot,
        $currentLot => $currentLot?.id
    );

    currentLotId.subscribe(async () => {
        await tick();
        if ($currentLot) {
            await fetchAttachments();
        }
    });

</script>

<div style="padding: 0 1.5625rem 1.5rem;">
    <FileUpload bind:attachments {filePrefix} {hideHints} {visibilityUrl} updateFun={fetchAttachments} appendToObject={fakHTMLElement}
                showVisibilityManagement editVisibilityManagement readOnly/>
</div>
