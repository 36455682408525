<script lang="ts">

    import {t} from "../../i18n/i18n";
    import StaticChipList from "../organisms/StaticChipList.svelte";
    import {type Writable} from "svelte/store";
    import {LotType} from "../types/enums";
    import {getContext} from "svelte";
    import {whitelistModalUtils} from "./whitelistModalUtil";

    export let lotTypes: LotType[] = [];
    // context stores
    let selectedLotTypes: Writable<(string | LotType)[]> = getContext('selectedLotTypes');

    let lotTypesChipOptions: {
        label: string,
        value: LotType,
        msg: string
    }[];


    function initializeLotTypes() {
        lotTypesChipOptions = lotTypes.map(flag => ({
            label: whitelistModalUtils.getLotTypeStringResource(flag as LotType),
            value: flag as LotType,
            msg: whitelistModalUtils.getLotTypeTTipMsg(flag as LotType)
        }));
    }

    $: lotTypes, initializeLotTypes(); // lotTypes are given from config data -> all LotTypes for which materials exist

</script>

<div class="whitelist-view-header">
    <span>{$t('UI.whitelist.modal.startpage.chiplist.header')}</span>
</div>
<StaticChipList bind:activeChips={$selectedLotTypes} list={lotTypesChipOptions} showSelectAll/>


<style lang="scss">

  @use "../../styles/global";

  div.whitelist-view-header {
    padding-bottom: 0.7rem;

    & > span {
      @include global.roboto-font(1rem, 500, 0.75rem, black);
    }
  }
</style>