<script context="module" lang="ts">
    export const textAreaHeights = {
        SMALL: "97px",
        MID: "131px",
        LARGE: "340px"
    }
</script>
<script lang="ts">
    import type {FontProps} from "../types/fontProps";
    import {DefaultRobotoFontProps, DefaultLabelFontProps, getFontPropsVarString} from "../types/fontProps";
    import {markFieldLabelRequired} from "../utils/formatters";
    import {getContext, hasContext} from "svelte";
    import {
        getExactPathErrorMessage,
        getPathErrorMessage,
        isExactPathInErrorList,
        isPathInErrorList
    } from "../utils/formValidation";
    import type {Writable} from "svelte/store";
    import {openCloseVariants} from "../utils/svelteMotionUtils";
    import {AnimatePresence, AnimateSharedLayout, Motion} from "svelte-motion";
    import type {FormError} from "../types/formError";

    export let id: string = "";
    export let title: string = "";
    export let text: string;
    export let readonly: boolean = false;
    export let placeholder: string = "";
    export let height: string = textAreaHeights.SMALL;
    export let headerFontProps: FontProps = DefaultLabelFontProps();
    export let inputFontProps: FontProps = DefaultRobotoFontProps('18px', '0.75rem');
    export let marginTop = '1.875rem';
    export let labelGap = '0.88rem'; // gap between label and textarea itself
    export let required: boolean = false;
    export let isHtml: boolean = false;
    export let maxLength: number | undefined = undefined; // 524288 is the default value
    export let titleInline: boolean = false;

    /* Handling of the error message indication with a context store that is set on the parent including the form */
    export let errorPath: string = '';
    let formErrors: Writable<FormError[]> | null = hasContext('formErrors') ? getContext('formErrors') : null;
    let error: string;
    $: if (errorPath && errorPath !== '' && $formErrors !== null) {
        let paths = errorPath.includes('||') ? errorPath.split('||') : [errorPath];
        error = "";
        paths.forEach(path => {
            if (path.includes('.*')) {
                error += isPathInErrorList($formErrors, path) ? getPathErrorMessage($formErrors, path) : '';
            } else {
                error += isExactPathInErrorList($formErrors, path) ? getExactPathErrorMessage($formErrors, path) : '';
            }

        })
    }

    $: if (required) {
        title = markFieldLabelRequired(title);
    }

    $: styleString = getFontPropsVarString(headerFontProps) + '--margin-top:' + marginTop + ';--label-gap:' + labelGap;

</script>

<AnimateSharedLayout>
    <div class="textarea-container" style={styleString}>
        {#if !titleInline}
            <span>{@html title}</span>
        {/if}
        {#if isHtml}
            {#if readonly}
                <div class="not-editable-container scrollable"
                     style={getFontPropsVarString(inputFontProps) + "height:" + height + ";background-color: #F0F2F8;text-align: start;"}
                     contenteditable="false" bind:innerHTML={text}></div>
                <!-- This field is only used in the print version of the page, to be able to show the text in full height -->
                <span class="printable" style={getFontPropsVarString(inputFontProps)}>{@html text}</span>
            {:else}
                <!-- TODO: This is unsafe, since users can inject their own js code which will be persisted and executed!
                     TODO: Needs to be addressed at latest when this becomes publicly accessible! -->
                <div class="editable-html-container scrollable"
                     style={getFontPropsVarString(inputFontProps) + "height:" + height + ";text-align: start;"}
                     contenteditable="true" bind:innerHTML={text} on:focusout></div>
                <!-- This field is only used in the print version of the page, to be able to show the text in full height -->
                <span class="printable" style={getFontPropsVarString(inputFontProps)}>{@html text}</span>
            {/if}
        {:else }
            {#if titleInline}
                <div class="headline-inline-text">
                    <span style="position: absolute;top: 0;left: 2px;"
                          class:text-disabled={readonly}>{@html title}</span>
                </div>
                <textarea {id} {readonly} {placeholder} maxlength={maxLength} class="scrollable title-inline"
                          rows="3" style={getFontPropsVarString(inputFontProps) + "height:" + height} bind:value={text}
                          on:change on:focusout></textarea>
            {:else}
            <textarea {id} maxlength={maxLength} {readonly} {placeholder}
                      class="scrollable" rows="3" style={getFontPropsVarString(inputFontProps) + "height:" + height}
                      bind:value={text} on:change on:focusout></textarea>
            {/if}
            <!-- This field is only used in the print version of the page, to be able to show the text in full height -->
            <span class="printable" style={getFontPropsVarString(inputFontProps)}>{text}</span>
        {/if}
    </div>
    <AnimatePresence list={error && error !== '' ? [{ key: 1 }] : []}>
        <Motion initial="collapsed" animate="open" exit="collapsed" variants={openCloseVariants} let:motion={collapse}>
            <span class="form-field-error" use:collapse>{@html error}</span>
        </Motion>
    </AnimatePresence>
</AnimateSharedLayout>


<style lang="scss">
  @import "../../styles/global";


  .textarea-container {
    @include flex-col(var(--label-gap), $alignment: flex-start);
    width: 100%;
    margin-top: var(--margin-top);
    position: relative;

    & > span {
      @include roboto-font(var(--line-height), var(--font-weight), var(--font-size), var(--color));
    }
  }

  textarea[readonly], textarea[disabled], div.not-editable-container, .printable {
    border: 1px solid $grey-700;
    background-color: $bluish-grey-200;
    @include roboto-font(var(--line-height), var(--font-weight), var(--font-size), var(--color));
    cursor: not-allowed;

    &:focus {
      border: 1px solid $grey-700;
      outline: none; // we don't want to see a border/outline when clicking in the textarea if it is disabled/readonly
    }

    &::placeholder {
      @include roboto-font(var(--line-height), var(--font-weight), var(--font-size), var(--color));
    }
  }

  textarea, div.not-editable-container, div.editable-html-container, .printable {
    @include roboto-font(var(--line-height), var(--font-weight), var(--font-size), var(--color));
    width: 100%;
    height: 100%;
    background-color: white;
    border: 1px solid $grey-700;
    border-radius: 0.125rem;
    overflow-y: auto;
    padding: 0.75em 2.375em 1em 1.125em;
    resize: none;

    &::placeholder {
      height: 13px;
      text-align: left;
      @include roboto-font(var(--line-height), var(--font-weight), var(--font-size), var(--placeholder-color));
    }

    &:focus {
      /** we now use outline instead of border, this doesn't let the component 'jump' around */
      outline: 2px solid $primaryColor;
      border-color: transparent;
    }
  }

  .scrollable::-webkit-scrollbar-track {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .title-inline {
    padding-top: 13px;
  }

  .title-inline::placeholder {
    line-height: 20px;
  }

  div.headline-inline-text {
    position: absolute;
    top: 0;
    left: .75rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    pointer-events: none;
    font-weight: 400 !important;

    & > span {
      font-size: 12px;
      transform: translateY(-6px);
      background-color: #fff;
      color: #000;
      padding-left: .25rem;
      padding-right: .25rem;
      position: absolute;
      top: 0;
    }
  }

  .text-disabled {
    background-color: #F0F2F8 !important;
  }

</style>
