<script lang="ts">

    import PubPageFailed from "./PubPageFailed.svelte";
    import {onMount, setContext} from "svelte";
    import type {Lot} from "../types/lot";
    import {DefaultLot} from "../types/lot";
    import {writable, type Writable} from "svelte/store";

    export let currentError;


    // when we explicitly set the component here already, we will see e.g. the finished layer ABOVE the failed layer, therefore this remains unset
    let props: { isAlreadyTaken?: boolean; isCanceled?: boolean } = {
        isAlreadyTaken: true,
        isCanceled: false,
    };
    let requestId: string | undefined = undefined;

    enum FinishedCode {
        CANCELED = "CANCELED",
        COMPLETED = "COMPLETED",
        ALREADY_TAKEN = "ALREADY_TAKEN"
    }

    const components = [
        {
            status: FinishedCode.ALREADY_TAKEN,
            update: () => {
                props = {isAlreadyTaken: true}
            }
        },
        {
            status: FinishedCode.CANCELED,
            update: () => {
                props = {isCanceled: true}
            }
        }
    ];

    onMount(() => {
        let status = currentError.data.code;
        requestId = currentError.data.requestId;
        updateProps(status);
    })


    function updateProps(code: FinishedCode) {
        components.find(component => component.status == code)?.update();
    }
</script>

<PubPageFailed {...props} {requestId}/>
