<script lang="ts">

    export let label: string = ""; // the label of the RadioButton
    export let groupName: string; // the RadioButton group to which this one belongs, e.g. "priceModality"
    export let value: any; // the value that is written in selected when selecting another RadioButton in the group
    export let selected: any;
    export let disabled: boolean = false;
    export let id: string | Number = Math.floor(Math.random() * 1000);
</script>


<div class="radio-btn-container">
    <!-- id must not be value, otherwise on clicking the label we select the first of the group and not the clicked -->
    <input id={`${groupName}-${id}`} type="radio" class="radio-btn" bind:group={selected}
           name="{groupName}" {value} {disabled}/>
    <label class="radio-btn-label" for={`${groupName}-${id}`}>{label}</label>
</div>


<style lang="scss">
  @import "../../styles/global.scss";

  .radio-btn-container {
    @include flex-row(0.5rem);
  }

  .radio-btn-label {
    @include roboto-font(15px, 400, 1rem, black);
    min-height: 15px;
    height: fit-content;
    max-height: max-content;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: $grey-700;
    margin: 0;
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    display: grid;
    place-content: center;
    opacity: 0.5;

    &::before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1rem 1rem $primaryGreen;
    }

    &:not([disabled]):hover {
      background-color: $grey-775;
    }

    &:checked {
      opacity: 1;

      &::before {
        transform: scale(1);
      }
    }
  }


</style>