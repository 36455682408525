import {LotState} from "../../../types/enums";
import {t} from "../../../../i18n/i18n"
import {get} from "svelte/store";
// @ts-ignore
import {PrimaryBtnColors} from "../../../atoms/PrimaryButton.svelte";
// @ts-ignore
import {SecondaryBtnColors} from "../../../atoms/SecondaryButton.svelte";
import type {LotStatusButtonsView} from "../../../types/lotStatusButtonsView";


export const lotStateUtils = {
    getStateColor,
    getStateIconPath,
    showFirstActionButton,
    isFirstActionButtonDisabled,
    getFirstActionButtonHeadline,
    getFirstActionButtonText,
    showSecondActionButton,
    isSecondActionButtonDisabled,
    getSecondActionButtonColor,
    getSecondActionButtonHeadline,
    getSecondActionButtonText,
    getThirdActionButtonText,
    getThirdActionButtonHeadline,
    getFourthActionButtonText,
    getFourthActionButtonHeadline,
    getStateBgColor,
};

function getStateColor(state: LotState) {
    switch (state) {
        case LotState.UNVERIFIED:
            return "#4068F4";
        case LotState.VERIFICATION:
            return "#5EC6D8";
        case LotState.SUBMISSION: // TODO: Difference between submission and parked?
        case LotState.PARKED:
            return "#FF9797";
        case LotState.APPROVAL:
            return "#75CDB3";
        case LotState.BIDDING:
            return "#000000";
        case LotState.OPTIMIZED:
            return "#BE1DD9";
        case LotState.HANDLING:
            return "#F3F336";
        case LotState.ACCOUNTING:
            return "#FFA900";
        case LotState.COMPLETED:
            return "#43D936";
        case LotState.DISCARDED:
            return "#F24822";
        default:
            return "#000000"
    }
}

function showFirstActionButton(state: LotState, lotView: LotStatusButtonsView) {
    switch (state) {
        case LotState.UNVERIFIED:
            return lotView.showMarkVerified;
        case LotState.SUBMISSION:
        case LotState.PARKED:
        case LotState.DISCARDED:
            return lotView.showMarkUnverified;
        case LotState.VERIFICATION:
            return lotView.showMarkChecked;
        case LotState.OPTIMIZED:
            return lotView.showMarkHandling
        case LotState.ACCOUNTING:
            return true;
        default:
            return false
    }
}

function isFirstActionButtonDisabled(state: LotState, lotView: LotStatusButtonsView) {
    return state === LotState.ACCOUNTING && !lotView.showMarkCompleted;
}

function getFirstActionButtonHeadline(state: LotState) {
    switch (state) {
        case LotState.UNVERIFIED:
            return get(t)('UI.lot.nextStatus', {state: get(t)('UI.LotState.' + LotState.VERIFICATION)})
        case LotState.VERIFICATION:
            return get(t)('UI.lot.nextStatus', {state: get(t)('UI.LotState.' + LotState.APPROVAL)})
        case LotState.PARKED:
        case LotState.SUBMISSION:
            return get(t)('UI.lot.nextStatus', {state: get(t)('UI.LotState.' + LotState.VERIFICATION)})
        case LotState.OPTIMIZED:
            return get(t)('UI.lot.nextStatus', {state: get(t)('UI.LotState.' + LotState.HANDLING)})
        case LotState.ACCOUNTING:
            return get(t)('UI.lot.nextStatus', {state: get(t)('UI.LotState.' + LotState.COMPLETED)})
        case LotState.COMPLETED:
            return get(t)('UI.lot.nextStatus', {state: get(t)('UI.LotState.' + LotState.BIDDING)})
        case LotState.DISCARDED:
            return get(t)('UI.lot.nextStatus', {state: get(t)('UI.LotState.' + LotState.UNVERIFIED)})
        default:
            return "";
    }
}

function getFirstActionButtonText(state: LotState) {
    switch (state) {
        case LotState.UNVERIFIED:
        case LotState.PARKED:
        case LotState.SUBMISSION:
            return get(t)('UI.lot.markVerified')
        case LotState.VERIFICATION:
            return get(t)('UI.lot.markApproval')
        case LotState.APPROVAL:
        case LotState.OPTIMIZED:
            return get(t)('Basedata.connectPartners')
        case LotState.ACCOUNTING:
            return get(t)('UI.lot.markCompleted')
        case LotState.DISCARDED:
            return get(t)('UI.map.lot.reopen')
        default:
            return null;
    }
}

function showSecondActionButton(state: LotState, lotView: LotStatusButtonsView) {
    switch (state) {
        case LotState.UNVERIFIED:
        case LotState.SUBMISSION:
        case LotState.PARKED:
        case LotState.OPTIMIZED:
        case LotState.HANDLING:
        case LotState.VERIFICATION:
            return true
        case LotState.APPROVAL:
        default:
            return false
    }
}

function isSecondActionButtonDisabled(state: LotState, lotView: LotStatusButtonsView) {
    return state === LotState.HANDLING && !lotView.showMarkAccounting;
}

function getSecondActionButtonColor(state: LotState) {
    return state === LotState.APPROVAL ? SecondaryBtnColors.GREEN : SecondaryBtnColors.BLUE;
}

function getSecondActionButtonHeadline(state: LotState, lotView: LotStatusButtonsView) {
    let returnState: string;
    switch (state) {
        case LotState.UNVERIFIED:
            returnState = LotState.SUBMISSION;
            break;
        case LotState.PARKED:
        case LotState.SUBMISSION:
            returnState = LotState.UNVERIFIED;
            break;
        case LotState.APPROVAL:
        case LotState.OPTIMIZED:
            returnState = LotState.HANDLING;
            break;
        case LotState.HANDLING:
            returnState = LotState.ACCOUNTING;
            break;
        case LotState.ACCOUNTING:
            returnState = LotState.COMPLETED;
            break;
        case LotState.COMPLETED:
        case LotState.VERIFICATION:
            returnState = LotState.BIDDING;
            break;
        default:
            return "";
    }
    return get(t)('UI.lot.nextStatus', {state: get(t)('UI.LotState.' + returnState)});
}

function getSecondActionButtonText(state: LotState, lotView: LotStatusButtonsView) {
    switch (state) {
        case LotState.UNVERIFIED:
        case LotState.PARKED:
        case LotState.SUBMISSION:
        case LotState.OPTIMIZED:
        case LotState.HANDLING:
        case LotState.VERIFICATION:
            return get(t)('UI.map.lot.changeStatus');
        case LotState.APPROVAL:
        default:
            return null;
    }
}

function getThirdActionButtonText(state: LotState) {
    switch (state) {
        case LotState.UNVERIFIED:
        case LotState.VERIFICATION:
        case LotState.PARKED:
        case LotState.SUBMISSION:
        case LotState.APPROVAL:
        case LotState.BIDDING:
            return get(t)('UI.lot.markAborted')
        case LotState.OPTIMIZED:
        case LotState.HANDLING:
        case LotState.ACCOUNTING:
        case LotState.COMPLETED:
            return get(t)('UI.lot.markBidding')
        case LotState.DISCARDED:
            return get(t)('UI.lot.deleteLot')
        default:
            return null;
    }
}

function getThirdActionButtonHeadline(state: LotState) {
    switch (state) {
        case LotState.UNVERIFIED:
        case LotState.VERIFICATION:
        case LotState.PARKED:
        case LotState.SUBMISSION:
        case LotState.APPROVAL:
        case LotState.BIDDING:
            return get(t)('UI.lot.nextStatus', {state: get(t)('UI.LotState.' + LotState.DISCARDED)})
        case LotState.OPTIMIZED:
        case LotState.HANDLING:
        case LotState.ACCOUNTING:
        case LotState.COMPLETED:
            return get(t)('UI.lot.nextStatus', {state: get(t)('UI.LotState.' + LotState.BIDDING)})
        default:
            return "";
    }
}

function getFourthActionButtonHeadline(state: LotState) {
    switch (state) {
        case LotState.UNVERIFIED:
        case LotState.VERIFICATION:
            return get(t)('UI.lot.nextStatus', {state: get(t)('UI.LotState.' + LotState.UNVERIFIED)})
        case LotState.PARKED:
        case LotState.SUBMISSION:
        case LotState.APPROVAL:
        case LotState.BIDDING:
        case LotState.OPTIMIZED:
        case LotState.HANDLING:
        case LotState.ACCOUNTING:
        case LotState.COMPLETED:
        default:
            return "";
    }
}

function getFourthActionButtonText(state: LotState) {
    switch (state) {
        case LotState.UNVERIFIED:
        case LotState.VERIFICATION:
            return get(t)('UI.lot.markRejected')
        case LotState.PARKED:
        case LotState.SUBMISSION:
        case LotState.APPROVAL:
        case LotState.BIDDING:
        case LotState.OPTIMIZED:
        case LotState.HANDLING:
        case LotState.ACCOUNTING:
        case LotState.COMPLETED:
        case LotState.DISCARDED:
        default:
            return null;
    }
}

function getStateBgColor(state: LotState) {
    switch (state) {
        case LotState.UNVERIFIED:
            return "#C6D2FC";
        case LotState.VERIFICATION:
            return "#CEEDFF";
        case LotState.SUBMISSION:
            return "#FCD9C6";
        case LotState.APPROVAL:
            return "#D5FFDC";
        case LotState.BIDDING:
            return "#8D8D8D";
        case LotState.OPTIMIZED:
            return "#FFCFF7";
        case LotState.HANDLING:
            return "#FFFDC3";
        case LotState.ACCOUNTING:
            return "#FFE0C3";
        case LotState.COMPLETED:
            return "#D3FFC3";
        case LotState.DISCARDED:
            return "#FFC3C3";
        default:
            return "#D9D9D9"
    }
}

function getStateIconPath(state: LotState) {
    return "/assets/icons/lotstates/icon_lot_" + state.toLowerCase() + ".svg";
}
