<script lang="ts">

    import Card from "../atoms/Card.svelte";
    import InputField from "../molecules/InputField.svelte";
    import {CalcMethod} from "../types/enums";
    import {t} from "../../i18n/i18n";
    import {createEventDispatcher, getContext, hasContext, onMount, setContext} from "svelte";
    import type {RequestDto} from "./types/requestDto.js";
    import {type Offer} from "./types/offer";
    import {writable, type Writable} from "svelte/store";
    import type {FormError} from "../types/formError";
    import {DefaultInputFieldProps, type FontProps} from "../types/fontProps";

    const dispatch = createEventDispatcher();
    const request: Writable<RequestDto> = getContext("request");
    const OFFER_PRICE_ERROR_PREFIX = "offer."
    const formErrors: Writable<FormError[]> = setContext("formErrors", writable([{path: "", message: ""}]));
    setContext("useMaterialDesign", true);
    const showKickback: Writable<boolean> = hasContext("showKickback") ? getContext("showKickback") : writable(false);
    const fontPropsWithBlackPlaceholder: FontProps = DefaultInputFieldProps("13px", 400, "0.75rem", "black", "black");

    export let offer: Offer;
    export let calcMethod: CalcMethod | undefined;

    let priceExclKickback: number;
    // if a price is specified in the request, then we show this value as placeholder
    let priceExclKickbackPlaceholder: number;

    onMount(() => {
        if (offer?.facts?.price) {
            adaptPriceWithoutKickbackAndKickback();
        } else if ($request?.facts?.price) {
            computePlaceholderValues();
        } else if ($showKickback && $request?.facts?.kickback) {
            offer.facts.kickback = $request.facts.kickback;
        }
    })


    function computePlaceholderValues() {
        const requestPrice: number | undefined = $request?.facts?.price ?? undefined;
        if ($showKickback && typeof (requestPrice) === 'number' && !isNaN(requestPrice) && requestPrice >= 0 && !offer?.facts?.kickback) {
            const kickbackOfActualPrice = Math.round(requestPrice * ($request?.facts?.kickbackInPercent)) / 100;
            // if the kickback is 0, we must set it to null such that the comparison with EmptyOffer does not return a false positive
            offer.facts.kickback = (kickbackOfActualPrice < $request?.facts.kickback ? $request?.facts?.kickback : kickbackOfActualPrice) || null;
            priceExclKickbackPlaceholder = Math.round((requestPrice - (offer?.facts.kickback || 0)) * 100) / 100;
        }
    }

    function adaptPriceWithoutKickbackAndKickback() {
        if (offer?.facts?.price && offer.facts.price >= 0) {
            if ($showKickback) {
                $formErrors = [];
                let requestKickback = $request?.facts?.kickback ?? 0;
                if (requestKickback > (offer.facts.price ?? -1)) { // in case the final price is lower than our minimum kickback
                    const error = {
                        path: OFFER_PRICE_ERROR_PREFIX + offer?.requestType + ".pricePerTon",
                        message: $t("UI.validation.offerPrice.mustNotBeLTKickback")
                    };
                    $formErrors = [error];
                    return;
                }
                // round to two decimals
                const kickbackOfActualPrice = Math.round(offer?.facts?.price * ($request?.facts?.kickbackInPercent)) / 100;
                offer.facts.kickback = kickbackOfActualPrice < requestKickback ? requestKickback : kickbackOfActualPrice;
                priceExclKickback = Math.round((offer?.facts?.price - offer?.facts.kickback) * 100) / 100;
            }
            dispatch("editOffer"); // we just notify the parent that any field has been changed
        }
    }

    function adaptGrossPriceAndKickback() {
        if (priceExclKickback || priceExclKickback >= 0) {
            if ($showKickback) {
                const requestKickback = $request?.facts?.kickback ?? 0;
                const kickbackPercentDecimal = (100 - $request?.facts.kickbackInPercent) / 100;
                let priceWithPercentKickback = (priceExclKickback / kickbackPercentDecimal);
                let kickbackWithPercent = Math.round((priceWithPercentKickback - priceExclKickback) * 100) / 100;
                if (kickbackWithPercent < requestKickback) {
                    offer.facts.kickback = $request.facts.kickback;
                    offer.facts.price = Math.round((priceExclKickback + requestKickback) * 100) / 100;
                } else {
                    offer.facts.kickback = kickbackWithPercent;
                    offer.facts.price = Math.round(priceWithPercentKickback * 100) / 100;
                }
            }
            dispatch("editOffer"); // we just notify the parent that any field has been changed
        }
    }

    $: offer, offer?.facts?.price, adaptPriceWithoutKickbackAndKickback();
</script>

<Card borderRadius="0.625rem" boxShadow="" padding="1.25rem"> <!-- to unset, boxShadow needs to be empty -->
    <div class="offer-price-container">
        {#if $showKickback}
            <InputField id={`priceExclKickback-${offer?.requestType.toLowerCase()}`}
                        bind:value={priceExclKickback} forcedDecimalPlaces={2}
                        label={$t("UI.offer.priceExclKickback")} fontProps={fontPropsWithBlackPlaceholder}
                        addonText={$t('UI.unit.eurPerTon.short.small')}
                        placeholder={calcMethod === CalcMethod.REQUEST_OFFER ? "-" : priceExclKickbackPlaceholder}
                        errorPath={OFFER_PRICE_ERROR_PREFIX + offer?.requestType + '.priceExclKickback'} isNumeric
                        on:focusout={adaptGrossPriceAndKickback}/>
            <InputField id={`kickbackPerTon-${offer?.requestType.toLowerCase()}`}
                        value={offer.facts.kickback} forcedDecimalPlaces={2}
                        fontProps={fontPropsWithBlackPlaceholder}
                        label={$t("Model.kickback")} addonText={$t('UI.unit.eurPerTon.short.small')}
                        errorPath={OFFER_PRICE_ERROR_PREFIX + offer?.requestType + '.kickbackPerTon'}
                        readOnly isNumeric/>
            <div class="offer-price-container_separator">
                <hr/>
            </div>
        {/if}
        <InputField id={"inputGrossPricePerTon-" + offer?.requestType?.toLowerCase()}
                    bind:value={offer.facts.price} forcedDecimalPlaces={2}
                    label={$showKickback ? $t("UI.offer.priceInclKickback") : $t("UI.lot.grossPricePerTon.Get.label")}
                    fontProps={fontPropsWithBlackPlaceholder}
                    placeholder={calcMethod === CalcMethod.REQUEST_OFFER ? "-" : $request?.facts?.price}
                    addonText={$t('UI.unit.eurPerTon.short.small')}
                    errorPath={OFFER_PRICE_ERROR_PREFIX + offer?.requestType + '.pricePerTon'} isNumeric
                    on:focusout={adaptPriceWithoutKickbackAndKickback}/>

    </div>
</Card>

<style lang="scss">
  @use "../../styles/global";

  :global(.card) {
    --card-width: 14.625rem;
    @media screen and (max-width: 992px) {
      --card-width: 100%;
    }
  }

  .offer-price-container {
    @include global.flex-col(1.25rem, $alignment: flex-start);
  }

  .offer-price-container_header {
    @include global.flex-row(0.375rem, $justify: flex-start);

    & h3 {
      margin-bottom: 0;
    }
  }

  .offer-price-container_separator {
    width: 100%;
    text-align: right;

    hr {
      color: global.$primaryGreen;
      height: 1px;
      width: 100%;
      margin: 1rem 0;
    }
  }
</style>
