<script lang="ts">
    import MmLoadingAnimation from "../../animations/MmLoadingAnimation.svelte";
    import {EmptyHelpEntry, type HelpEntry} from "../types/helpEntry";
    import {fetchUtils} from "../../utils/fetchUtils";
    import {closeModal, openModal} from "svelte-modals";
    import HelpConfigurationModal from "./HelpConfigurationModal.svelte";
    import PrimaryButton from "../../atoms/PrimaryButton.svelte";
    import HelpEntryCard from "../HelpEntryCard.svelte";
    import Masonry from 'svelte-bricks';
    import {t} from "../../../i18n/i18n";
    import AutocompleteInputField from "../../autocomplete/AutocompleteInputField.svelte";
    import {AutocompleteConfig, type AutocompleteFieldConfig} from "../../types/autocompleteFieldConfig";
    import {setContext} from "svelte";
    import {ButtonIcons, HelpVisibility, InputIcons} from "../../types/enums";
    import InputField from "../../molecules/InputField.svelte";
    import {debounce} from "../../utils/utils";
    import SecondaryButton from "../../atoms/SecondaryButton.svelte";
    import {addNotification} from "../../stores";
    import {NotificationType} from "../../types/notification";
    import Modal from "../../organisms/Modal.svelte";
    import Basic from "../../templates/Basic.svelte";


    setContext("useMaterialDesign", true);


    let entries: HelpEntry[] = [];
    let filteredEntries: HelpEntry[] = [];
    let limitedEntries: HelpEntry[] = [];

    let entriesShown = 0;

    let visibilityFilterConfig: AutocompleteFieldConfig = AutocompleteConfig('value', 'label', true);
    visibilityFilterConfig.searchProps = {skipSort: false}; // Allow filtering
    let selectedVisibilityFilter: { lable: string, value: string }[] = [];
    const visibilityOptions = Object.keys(HelpVisibility).map((o: string) => {
        return {label: $t("UI.help.visibility." + o.toLowerCase()), value: o}
    });

    let tagFilterConfig: AutocompleteFieldConfig = AutocompleteConfig('value', 'label', true);
    let selectedTagFilter: string[] = [];
    let tagOptions: { label: string, value: string }[] = [];

    let search = "";

    function setTagFilterOptions() {
        let allTagsSet: Set<string> = new Set();
        entries.forEach(e => {
            e.searchStrings.forEach(s => allTagsSet.add(s));
        });
        tagOptions = Array.from(allTagsSet).map(s => {
            return {label: s, value: s}
        });
    }


    let filterEntriesDebounced = debounce(filterEntries);

    function filterEntries() {

        filteredEntries = entries.slice();
        // Filter visibility
        let visibilityFilter = selectedVisibilityFilter.map(f => f.value);
        if (visibilityFilter.length > 0) {
            filteredEntries = filteredEntries.filter(e => visibilityFilter.every(f => e.visibility.includes(f)));
        }
        // Filter tags (searchStrings)
        if (selectedTagFilter.length > 0) {
            filteredEntries = filteredEntries.filter(e => selectedTagFilter.every(f => e.searchStrings.includes(f)));
        }

        filterSearch();
    }

    function filterSearch() {
        let searchString = search.trim().toLowerCase();
        if (searchString.length > 0) {
            filteredEntries = filteredEntries.filter(e => {
                return e.headline.toLowerCase().includes(searchString)
                    || e.description.toLowerCase().includes(searchString)
                    || e.searchStrings.some(t => t.toLowerCase().includes(searchString));
            });
        } else {
            filteredEntries = filteredEntries;
        }
    }

    async function fetchHelpEntries() {
        await fetchUtils.get("/api/helpConfig")
            .then(data => {
                // noinspection TypeScriptUnresolvedReference
                entries = data.sort((a: HelpEntry, b: HelpEntry) => a.priority - b.priority);
                entriesShown = Math.min(entries.length, Math.max(20, entriesShown));
                setTagFilterOptions();
                filterEntries();
            })
            .catch(e => {
                let notification = {
                    message: e.message, type: NotificationType.ERROR, timeout: 0,
                    dismissible: true
                };
                addNotification(notification);
            });
    }

    function openHelpEntryModal(entry: HelpEntry = EmptyHelpEntry()) {
        openModal(HelpConfigurationModal, {helpEntry: entry, onSave: fetchHelpEntries});
    }

    async function deleteEntry(entryId: string) {
        openModal(Modal, {
            title: $t("UI.help.delete.title"),
            denyText: $t("UI.delete"),
            message: $t("UI.help.delete.desc"),
            onDeny: () => {
                closeModal();
                fetchUtils.get("/api/deleteHelpEntry/" + entryId, false)
                    .then(() => {
                        let notification = {
                            message: $t("UI.help.notification.delete.success"),
                            type: NotificationType.SUCCESS,
                            timeout: 3000,
                            dismissible: true
                        };
                        addNotification(notification);
                        fetchHelpEntries();
                    })
                    .catch(e => {
                        let notification = {
                            message: e.message, type: NotificationType.ERROR, timeout: 0,
                            dismissible: true
                        };
                        addNotification(notification);
                    });
            }
        });
    }

    $: limitedEntries = filteredEntries.slice(0, entriesShown);

</script>

<Basic>
    <div class="help-config-container">
        <h1>{$t("UI.help.helpSystem.title")}</h1>
        <div style="width: 100%; text-align: left;">
            <h2 style="padding-bottom: 0.88rem;">{$t("UI.filters")}</h2>
            <div class="help-config-filter">
                <div>
                    <AutocompleteInputField bind:selectedObject={selectedVisibilityFilter}
                                            autocompleteConfig={visibilityFilterConfig} containerWidth="16rem"
                                            label={$t("UI.help.filterByVisibility.label")} options={visibilityOptions}
                                            hideDropdownArrow={false}
                                            on:change={filterEntriesDebounced}/>
                    <AutocompleteInputField bind:value={selectedTagFilter} bind:options={tagOptions}
                                            autocompleteConfig={tagFilterConfig} containerWidth="16rem"
                                            label={$t("UI.help.filterByKeywords.label")} hideDropdownArrow={false}
                                            on:change={filterEntriesDebounced}/>
                </div>
                <div>
                    <PrimaryButton id="btn-new-help-entry" label={$t('UI.help.newEntry.title')}
                                   leftIcon={ButtonIcons.PLUS_BOLD} whiteIcon sizeAdaptingToText
                                   on:click={() => openHelpEntryModal()}/>
                    <InputField bind:value={search} label="{$t('UI.placeholder.search')}"
                                materialWidth="255px" icon={InputIcons.SEARCH_LENS} containerWidth="auto"
                                on:input={filterEntriesDebounced}/>
                </div>
            </div>
        </div>
        <div style="width: 100%; text-align: left;">
            <h2 style="padding-bottom: 1rem;">{$t("UI.help.questionAnswers.title")}</h2>
            {#await fetchHelpEntries()}
                <MmLoadingAnimation/>
            {:then {}}
                <div style="width: 100%">
                    {#if limitedEntries.length > 0}
                        <Masonry bind:items={limitedEntries}
                                 minColWidth={240} maxColWidth={336} gap={16} masonryWidth={1110}
                                 let:item>
                            <div class="help-entry-card">
                                <HelpEntryCard helpEntry={item} previewMode
                                               onEditClick={() => openHelpEntryModal(item)}
                                               onDeleteClick={() => deleteEntry(item.id)}/>
                            </div>
                        </Masonry>
                    {:else}
                        <div class="help-config-no-entries">
                            <span>{$t("UI.help.noEntriesFound")}</span>
                        </div>
                    {/if}

                    {#if entriesShown <= filteredEntries.length - 1}
                        <div style="float: right; padding-top: 3rem;">
                            <SecondaryButton label={$t("UI.help.showMore")}
                                             on:click={() => entriesShown = entriesShown + 20}/>
                        </div>
                    {/if}
                </div>
            {/await}
        </div>
    </div>
</Basic>


<style lang="scss">
  @import "../../../styles/global";

  .help-config-container {
    @include flex-col($row-gap: 1.88rem, $alignment: flex-start);
  }

  .help-config-filter {
    @include flex-row(0.875rem, $justify: space-between);
    row-gap: 0.938rem;
    width: 100%;
    flex-wrap: wrap;


    & > div {
      @include flex-row($col-gap: 0.375rem, $alignment: flex-start, $justify: flex-start);
      flex-wrap: wrap;
      row-gap: 0.938rem;

      & :global(.sv-content) {
        padding: 0.25rem 0.25rem 0.25rem 0.5rem !important;

        & > :global(div > .sv-item) {
          background-color: #fff !important;
          border: 1px solid $primaryGreen;
          color: #000;
          border-radius: 1.25rem !important;
          padding-left: 0.625rem;

          & > :global(.sv-item--content) {
            padding: 3px 3px 3px 0;
          }
        }
      }

      :global(.sv-item-btn) {
        background-color: #fff !important;
        border-radius: 1.25rem !important;

        & > :global(svg) {
          fill: #9D9D9D !important;
        }
      }

      :global(.indicator-container) {
        cursor: pointer;
        padding: 0 0.75rem 0 0;
      }
    }
  }

  .help-entry-card {
    background: white;
    transition: transform 0.3s ease-out;

    &:hover {
      transform: translateY(-5px) scale(1.001) translateZ(0);
    }
  }

  :global(div.masonry) {
    justify-content: flex-start;
  }

  .help-config-no-entries {
    @include flex-row();
    padding-top: 3rem;
  }

</style>