<script lang="ts">
    import OptimizerPageOpenOffer from "./OptimizerPageOpenedOffer.svelte";
    import {onMount, setContext} from "svelte";
    import {OPTIMIZER_PAYS_KICKBACK} from "../types/enums";
    import type {Lot} from "./lot";
    import OptimizerPageWithDeal from "./OptimizerPageWithDeal.svelte";
    import OptimizerPageRejected from "./OptimizerPageRejected.svelte";
    import PubPage404 from "../pages/PubPage404.svelte";
    import {addNotification, caseOwnerContact, loadUserInfo} from "../stores";
    import {type RequestDto, RequestedEntityType, RequestType} from "../bid/types/requestDto";
    import {writable, type Writable} from "svelte/store";
    import type {RequestCollecting} from "../bid/types/requestCollecting";
    import {EmptyOffer, type Offer} from "../bid/types/offer";
    import type {ContactData} from "../types/contactData";
    import {Facts} from "../bid/types/facts";
    import {fetchUtils} from "../utils/fetchUtils";
    import {NotificationType} from "../types/notification";
    import type {Deal} from "../bid/types/deal";
    import type {DealActualValues} from "../types/dealActualValues";
    import type {DealPartnerConnect} from "../types/dealPartner";

    loadUserInfo();

    const baseUrl: string = '/api/optimizer';
    const baseUrlRequest: string = baseUrl + "/request"
    const baseUrlLot: string = baseUrl + '/lot';

    const request: Writable<RequestDto | null> = setContext("request", writable(null));
    const offerMaterial: Writable<Offer> = setContext("offerMaterial", writable(EmptyOffer(RequestType.MATERIAL)));
    const offerMaterialWithTransport: Writable<Offer> = setContext("offerMaterialWithTransport", writable(EmptyOffer(RequestType.MATERIAL_WITH_TRANSPORT)));
    const deal: Writable<Deal | null> = setContext("deal", writable(null));
    const dealPartnerConnect: Writable<DealPartnerConnect | null> = setContext("dealPartnerConnect", writable(null));
    const showKickback: Writable<boolean> = setContext("showKickback", writable(false));
    const lot: Writable<Lot | null> = setContext("lot", writable(null));
    // Set to undefined in order to determine when a real value is loaded and set to the context store.
    // Used e.g. in PublicPageHeader component.
    let isSaasInternalLot: Writable<boolean | undefined> = setContext("isSaasInternalLot", writable(undefined));


    export let requestPublicId: string | undefined = undefined;
    export let offerUserPublicId: string | undefined = undefined;
    export let requestedEntityId: string | undefined = undefined;
    export let requestedEntityType: RequestedEntityType | undefined = undefined;
    export let lotPublicId: string | undefined = undefined;


    setContext("urlParams", {
        lotPublicId,
        offerUserPublicId,
        requestPublicId,
        requestedEntityId,
        requestedEntityType
    }); // no writable store, we only want to read the values

    let pageComponent: typeof OptimizerPageOpenOffer | typeof OptimizerPageRejected | typeof OptimizerPageWithDeal | typeof PubPage404;
    let optimizerContactData: ContactData;

    function getPageComponent(data: RequestCollecting) {
        if (data?.request?.rejectedByOptimizer) {
            return OptimizerPageRejected;
        }
        if (data.deal) { // there exists a deal, i.e. the request/offers must have been accepted
            return OptimizerPageWithDeal;
        }
        return OptimizerPageOpenOffer; // otherwise always return the opened quote page
    }


    onMount(async () => {
        await fetchRequestCollecting();
    })

    async function fetchRequestCollecting() {
        let url = '';
        if (lotPublicId) {
            url = `${baseUrlLot}/${lotPublicId}`;
        } else if (requestPublicId && offerUserPublicId) {
            if (offerUserPublicId.length > 0) {
                url = `${baseUrlRequest}/${requestPublicId}/${offerUserPublicId}`;
            } else {
                url = `${baseUrlRequest}/${requestPublicId}`;
            }
        }
        if (url.length > 0) {
            await fetchUtils.get(url)
                .then((data: RequestCollecting) => {
                        $lot = {...data.lot}
                        $caseOwnerContact = {...data.caseOwnerContactData};
                        $showKickback = $lot?.type ? OPTIMIZER_PAYS_KICKBACK.includes($lot.type) : false;
                        optimizerContactData = {...data.optimizerContactData};
                        $request = {...data.request, facts: new Facts(data.request.facts)};
                        if (data.offerMaterial) {
                            $offerMaterial = {...data?.offerMaterial, facts: new Facts(data.offerMaterial.facts)};
                        }
                        if (data.offerMaterialWithTransport) { // only overwrite if not null
                            $offerMaterialWithTransport = {
                                ...data?.offerMaterialWithTransport,
                                facts: new Facts(data.offerMaterialWithTransport.facts)
                            };
                        }
                        if (data.deal) {
                            let offererFacts: Facts = new Facts(data.deal?.offerActualValues?.totalFacts);
                            let requestFacts: Facts = new Facts(data.deal?.requestActualValues?.totalFacts);
                            let offerActualValues: DealActualValues = {
                                ...data.deal.offerActualValues,
                                totalFacts: offererFacts
                            };
                            let requestActualValues: DealActualValues = {
                                ...data.deal.requestActualValues,
                                totalFacts: requestFacts
                            }
                            $deal = {
                                ...data.deal,
                                dealFacts: new Facts(data.deal?.dealFacts),
                                offerActualValues,
                                requestActualValues
                            }
                            $dealPartnerConnect = {...data.connectData}
                        }
                        pageComponent = getPageComponent(data);
                        $isSaasInternalLot = $lot.isSaasInternalLot;
                    }
                ).catch((error) => {
                    console.log(error);
                    let notification = {
                        message: error.message,
                        type: NotificationType.ERROR,
                        timeout: 0,
                        dismissible: true
                    };
                    addNotification(notification);
                })
        }
    }

    $: $lot, $lot?.type, $showKickback = $lot?.type ? OPTIMIZER_PAYS_KICKBACK.includes($lot.type) : false;
</script>

<svelte:component this={pageComponent} {optimizerContactData} on:offerChanged={fetchRequestCollecting}/>
