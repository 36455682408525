<script lang="ts">
    import {t} from "../../i18n/i18n"
    import type {Lot} from "./lot";
    import {createEventDispatcher, getContext, hasContext} from "svelte";
    import {CalcMethod, OfferViewMode, TooltipPlacements} from "../types/enums";
    import OptimizerDecisionButtons from "../bid/OptimizerDecisionButtons.svelte";
    import OfferInfo from "../bid/OfferInfo.svelte";
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import AcceptOfferComponent from "../bid/AcceptOfferComponent.svelte";
    import RejectOfferComponent from "./RejectOfferComponent.svelte";
    import OfferFactsForm from "../bid/OfferFactsForm.svelte";
    import {EmptyOffer, type Offer, OfferState} from "../bid/types/offer";
    import {writable, type Writable} from "svelte/store";
    import {RequestType} from "../bid/types/requestDto";
    import OptimizerPagesTemplate from "./OptimizerPagesTemplate.svelte";
    import type {ContactData} from "../types/contactData";
    import {isEmptyOffer} from "../bid/offerUtils";

    const dispatch = createEventDispatcher();

    let lot: Writable<Lot> = getContext("lot");
    let offerMaterial: Writable<Offer> = hasContext("offerMaterial")
        ? getContext("offerMaterial") : writable(EmptyOffer(RequestType.MATERIAL));
    let offerMaterialWithTransport: Writable<Offer> = hasContext("offerMaterialWithTransport")
        ? getContext("offerMaterialWithTransport") : writable(EmptyOffer(RequestType.MATERIAL_WITH_TRANSPORT));

    export let optimizerContactData: ContactData;

    let offerViewMode: OfferViewMode = OfferViewMode.VIEW_OFFER;

    let emptyOffers: boolean;
    let editOfferButtonText: string;
    let offerAlreadyAccepted: boolean;

    const viewOffer = () => offerViewMode = OfferViewMode.VIEW_OFFER;
    const viewEditOffer = () => offerViewMode = OfferViewMode.EDIT_OFFER;
    const viewAcceptOffer = () => offerViewMode = OfferViewMode.ACCEPT_OFFER;
    const viewRejectOffer = () => offerViewMode = OfferViewMode.REJECT_OFFER;
    const offerChanged = () => dispatch("offerChanged");



    $: $offerMaterial, $offerMaterialWithTransport, emptyOffers = (!$offerMaterial && !$offerMaterialWithTransport)
        || !!(isEmptyOffer($offerMaterial, RequestType.MATERIAL) && isEmptyOffer($offerMaterialWithTransport, RequestType.MATERIAL_WITH_TRANSPORT))
        || $offerMaterial.offerState === OfferState.DRAFT;

    $: editOfferButtonText = $lot.calcMethod === CalcMethod.REQUEST_OFFER ?
        $t("UI.optimizerPage.priceOffer.editor.title.edit") : $t("UI.optimizerPage.counterOffer.editor.title.edit");
    $: offerAlreadyAccepted = $offerMaterial.offerState === OfferState.ACCEPTED
        || $offerMaterialWithTransport.offerState === OfferState.ACCEPTED
</script>

<OptimizerPagesTemplate recipientData={optimizerContactData} let:linkAttachments={linkAttachmentsByAccept}
                        on:offerChanged={offerChanged}>
    {#if offerViewMode === OfferViewMode.VIEW_OFFER}
        {#if !emptyOffers}
            <div class="col-lg-12 col-sm-12 columns" style="margin-top: 1.875rem">
                <OfferInfo onOptimizerPage/>
                <!-- only show the button to edit the offer when we don't already have accepted offers -->
                <div class="align-right">
                    <PrimaryButton label={editOfferButtonText} color={PrimaryBtnColors.ORANGE}
                                   disabled={offerAlreadyAccepted}
                                   info={offerAlreadyAccepted ? "Angebot wurde bereits angenommen" : null}
                                   infoPlacement={TooltipPlacements.LEFT}
                                   on:click={viewEditOffer}/>
                </div>
            </div>
        {:else}
            <OptimizerDecisionButtons calcMethod={$lot?.calcMethod}
                                      on:acceptOffer={viewAcceptOffer}
                                      on:declineOffer={viewRejectOffer}
                                      on:editOffer={viewEditOffer}/>
        {/if}
    {/if}

    {#if offerViewMode === OfferViewMode.ACCEPT_OFFER}
        <AcceptOfferComponent on:cancel={viewOffer}
                              on:offerChanged={offerChanged}
                              on:linkAttachmentsByAccept={linkAttachmentsByAccept}/>
    {/if}

    {#if offerViewMode === OfferViewMode.EDIT_OFFER}
        <OfferFactsForm on:cancelEdit={viewOffer}
                        on:linkAttachmentsByAccept={linkAttachmentsByAccept}
                        on:offerChanged={() => {offerChanged(); viewOffer();}}/>
    {/if}

    {#if offerViewMode === OfferViewMode.REJECT_OFFER}
        <RejectOfferComponent isTooltipEnable on:cancel={viewOffer} on:offerChanged={offerChanged}/>
    {/if}

</OptimizerPagesTemplate>

<style lang="scss">
  @import "../../styles/pubPage";
</style>
