<script lang="ts">
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    export let open = false;
    export let position: string = 'right';

    function toggleSidebar() {
        open = !open;
        dispatch('toggleSidebar'); // this is necessary to send the button click / toggle to the parent class, it did not work with bind:open somehow
    }
</script>

<button class="round-button {position}" class:open on:click={toggleSidebar}>
    <i class="bx bx-chevron-left bx-sm" class:bx-flip-horizontal={open}></i>
</button>

<style lang="scss">
  @import "../../styles/global";

  .round-button {
    width: 32px;
    height: 32px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    background-color: $primaryColor;
    @include flex-col();
    border: none;

    &:hover {
      cursor: pointer;
      background-color: $secondaryColor;
    }

    & > i {
      color: #fff;
    }
  }

  .open {
    background-color: white !important;

    &:hover {
      cursor: pointer;
      background-color: $grey-200 !important;
    }

    & > i {
      color: $primaryColor;

      &:hover {
        color: $secondaryColor;
      }
    }
  }
</style>