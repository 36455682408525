import type {AttachmentParentType} from "./enums";

export type AttachmentView = {
    filename: string,
    downloadUri: string,
    changeDate: string,
    canDelete: boolean,
    attachmentId: string,
    entityId: string,
    parentType: AttachmentParentType,
    visibility: AttachmentVisibility
};

export function DefaultAttachmentView() {
    let attachment: AttachmentView = {
        changeDate: "",
        downloadUri: "",
        filename: "",
        attachmentId: "",
        canDelete: false,
        entityId: "",
        parentType: null,
        visibility: AttachmentVisibility.NONE
    };
    return attachment;
}

export enum AttachmentVisibility {
    OPTIMIZER = "optimizer",
    OFFERER = "offerer",
    ALL = "all",
    NONE = "none"
}

export enum AttachmentVisibilityManagement {
    SHOW = "show",
    READONLY = "readonly",
    HIDE = "hide"
}
