<script lang="ts">
    import {currentLot, defaultCatalogOptions, defaultMaterialOptions} from "../utils/fakStores";
    import NullableDataWrapper from "../../../utils/NullableDataWrapper.svelte";
    import {inputFieldFormats} from "../../../utils/formatters";
    import {t} from "../../../../i18n/i18n";
    import {CalcMethod} from "../../../types/enums";
    import AutocompleteInputField from "../../../autocomplete/AutocompleteInputField.svelte";
    import InputField from "../../../molecules/InputField.svelte";
    import {vehicleCategories} from "../../../atoms/vehicleCategoriesStore";
    import {AutocompleteConfig, type AutocompleteFieldConfig, getFormAcConfigFetch} from "../../../types/autocompleteFieldConfig";
    import {copyLot, updateMaterialPropertyComments, validateAndSaveLot} from "../../types/lot";
    import type {LotEditFieldsView} from "../../../types/lotEditFieldsView";
    import {DefaultLotEditFieldsView} from "../../../types/lotEditFieldsView";
    import type {FontProps} from "../../../types/fontProps";
    import {DefaultRobotoFontProps} from "../../../types/fontProps";
    import {derived} from "svelte/store";
    import PrimaryButton from "../../../atoms/PrimaryButton.svelte";
    import TextArea, {textAreaHeights} from "../../../atoms/TextArea.svelte";
    import Icon from "../../../atoms/Icon.svelte";
    import type {Material} from "../../types/material";
    import type {Catalog} from "../../types/catalog";
    import {tick} from "svelte";
    import iconClose from "@/icons/icon_expandable_closed.svg";


    const fontProps: FontProps = DefaultRobotoFontProps('0.875rem', '0.75rem');
    const LOT_FORM_ERROR_PATH_PREFIX = 'lot.';

    let lotEditFieldsView: LotEditFieldsView = $currentLot.lotViewFieldsChangeMap;
    $: lotEditFieldsView = $currentLot ? $currentLot.lotViewFieldsChangeMap : DefaultLotEditFieldsView();

    let canNotEditLot = !lotEditFieldsView.canEditLot;
    let catalogDisabled = canNotEditLot || !$currentLot || !$currentLot.catalog;
    $: materialEmpty = !$currentLot || !$currentLot.material;

    $: if ($currentLot) {
        canNotEditLot = !lotEditFieldsView.canEditLot;
        catalogDisabled = canNotEditLot || !$currentLot.catalog;
    }
    // [{"id":"1","label":"Option 1"},{"id":"2","label":"Option 2"},{"id":"3","label":"Option 3"},{"id":"4","label":"Option 4"},{"id":"5","label":"Option 5"},{"id":"6","label":"Option 6"},{"id":"7","label":"Option 7"},{"id":"8","label":"Option 8"},{"id":"9","label":"Option 9"},{"id":"10","label":"Option 10"},{"id":"11","label":"Option 11"},{"id":"12","label":"Option 12"},{"id":"13","label":"Option 13"},{"id":"14","label":"Option 14"},{"id":"15","label":"Option 15"}];

    let vehicleCategoryAcConfig: AutocompleteFieldConfig = AutocompleteConfig("id", "label", true, !lotEditFieldsView.canEditLot);
    $: vehicleCategoriesValue = $currentLot?.vehicleCategories.map(c => c.id);

    let materialAcConfig: AutocompleteFieldConfig = getFormAcConfigFetch( "material", false, 'id', 'text',
        !lotEditFieldsView.canEditLot, false);
    materialAcConfig.fetch = "/api/material/autocomplete/svelte?catalogId=" + $currentLot?.catalog?.id + "&qry=[query]";
    let materialId: any = null;
    let materialOptions: Material[];

    let catalogAcConfig: AutocompleteFieldConfig = getFormAcConfigFetch("catalog", false, 'id', 'text',
        !lotEditFieldsView.canEditLot, false);
    let catalogId: any = null;
    let catalogOptions: Catalog[];

    let materialCommentsOpen = true;
    let materialCommentsRequiredOpen = true;

    function onCatalogChanged() {
        if ($currentLot) {
            $currentLot.material = null;
            materialId = null;
            if (!$currentLot.catalog) {
                materialOptions = []; // Needed because Svelecte crashes otherwise. Why? Nobody knows...
                materialAcConfig.fetch = "/api/material/autocomplete/svelte?qry=[query]";
                materialCommentsOpen = true;
                materialCommentsRequiredOpen = true;
            } else {
                materialOptions = []; // Needed because Svelecte crashes otherwise. Why? Nobody knows...
                materialAcConfig.fetch = "/api/material/autocomplete/svelte?catalogId=" + $currentLot.catalog.id + "&qry=[query]";
                tick().then(() => {
                    materialOptions = $currentLot.catalog ? $defaultMaterialOptions[$currentLot.catalog.id] ?? [] : [];
                });
            }
        }
        updateMaterialPropertyComments();
        triggerLotValidationAndSave();
    }

    function onMaterialChanged() {
        if ($currentLot && $currentLot.materialProperties && !$currentLot.material) {
            $currentLot.materialProperties = null;
            materialCommentsOpen = true;
            materialCommentsRequiredOpen = true;
        }
        updateMaterialPropertyComments();
        triggerLotValidationAndSave();
    }

    function triggerLotValidationAndSave() {
        if ($currentLot && $currentLot.id !== "new") {
            validateAndSaveLot(copyLot($currentLot));
        }
    }

    /* The derived store makes sure, that reactivity for field and object initialization is triggered, iff the lot.id
    * has changed, i.e., a different lot is selected. */
    let currentLotId = derived(
        currentLot,
        $currentLot => $currentLot?.id
    );
    currentLotId.subscribe(() => {
        if ($currentLot) {
            initializeMaterialFields();
        }
    });

    function setCatalogForNewLot() {
        if (catalogId === null && catalogOptions.length > 0) { //new lot
            catalogId = catalogOptions[0].id;
            $currentLot.catalog = catalogOptions[0];
            onCatalogChanged(); // update material options
        }
    }

    function setMaterialForNewLot() {
        if (materialId === null && materialOptions.length > 0) { //new lot
            materialId = materialOptions[0].id;
            $currentLot.material = materialOptions[0];
            onMaterialChanged(); // update material properties
        }
    }

    function initializeMaterialFields() {
        // first the catalog must be initialized
        catalogOptions = $defaultCatalogOptions;
        catalogId = $currentLot.catalog && $currentLot.catalog.id ? $currentLot.catalog.id : null;

        setCatalogForNewLot();
        // then the material must be initialized
        let materialOptionsBuilder = $currentLot.catalog ? $defaultMaterialOptions[$currentLot.catalog.id] : [];
        if ($currentLot.material && !materialOptionsBuilder.some(m => m.id === $currentLot.material.id)) {
            materialOptionsBuilder.push($currentLot.material);
        }
        materialOptions = materialOptionsBuilder;
        materialId = $currentLot.material && $currentLot.material.id ? $currentLot.material.id : null;

        setMaterialForNewLot();

        vehicleCategoriesValue = $currentLot.vehicleCategories.map(c => c.id);
    }

    const gridTemplateAreas = '"catalog material"\
    "materialProperties materialProperties"\
    "materialPropertiesComments materialPropertiesComments"\
    "materialPropertiesCommentsRequired materialPropertiesCommentsRequired"\
    "volume dailyCapacity"\
    "vehicleCategories vehicleCategories"\
    "pricePerTon pricePerTon"\
    "kickbackInPercent kickbackPerTon"';

    const gridTemplateAreasNoMaterial = '"catalog material"\
    "materialProperties materialProperties"\
    "volume dailyCapacity"\
    "vehicleCategories vehicleCategories"\
    "pricePerTon pricePerTon"\
    "kickbackInPercent kickbackPerTon"';
</script>

<NullableDataWrapper bind:data={$currentLot}>
    <div class="lot-form-container">
        <div class="lot-material-data">
            <div style="grid-template-areas: {$currentLot.material ? gridTemplateAreas : gridTemplateAreasNoMaterial}">
                <div style="grid-area: catalog">
                    <AutocompleteInputField id="lotCatalog" bind:value={catalogId} bind:selectedObject={$currentLot.catalog}
                                            bind:options={catalogOptions} bind:disabled={canNotEditLot}
                                            {fontProps} autocompleteConfig={catalogAcConfig}
                                            label={$t("UI.catalog.autocomplete.ph")}
                                            errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'catalog'} required
                                            on:change={onCatalogChanged}/>
                </div>
                <div style="grid-area: material">
                    <AutocompleteInputField id="lotMaterial" bind:value={materialId} bind:selectedObject={$currentLot.material}
                                            bind:options={materialOptions} bind:disabled={catalogDisabled}
                                            {fontProps} autocompleteConfig={materialAcConfig}
                                            label={$t("UI.material.autocomplete.ph")}
                                            errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'material'} required
                                            on:change={onMaterialChanged}/>
                </div>
                <div style="grid-area: materialProperties">
                    <PrimaryButton on:click={() => dispatchEvent(new CustomEvent("openMaterialProperties"))}
                                   label={$t(canNotEditLot ? 'UI.map.lot.button.materialProperties.readOnly' : 'UI.map.lot.button.materialProperties')}
                                   fullWidth bind:disabled={materialEmpty}/>
                </div>
                {#if $currentLot.material}
                    <div style="grid-area: materialPropertiesComments; overflow: hidden;">
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <div on:click={() => materialCommentsOpen = !materialCommentsOpen}
                             class="material-comments-container hover-pointer">
                            <span>{$t('Lot.materialPropertiesComments')}</span>
                            <div style="transform: rotate({materialCommentsOpen ? '90deg' : '0'}); transition: transform .4s ease-out;">
                                <Icon src={iconClose} size={20} clickable/>
                            </div>
                        </div>
                        <div class="divider" style="background: {materialCommentsOpen ? '#fff' : '#d9d9d9'};"/>
                        <div style="height: {materialCommentsOpen ? '8.1875' : '0'}rem; transition: height .5s ease-out;">
                            <TextArea bind:text={$currentLot.materialPropertiesComment}
                                      marginTop="0"
                                      labelGap="0" readonly={!lotEditFieldsView.canEditLot}
                                      height={textAreaHeights.MID}
                                      on:focusout={triggerLotValidationAndSave}/>
                        </div>
                    </div>
                    <div style="grid-area: materialPropertiesCommentsRequired; overflow: hidden;">
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <div on:click={() => materialCommentsRequiredOpen = !materialCommentsRequiredOpen}
                             class="material-comments-container hover-pointer">
                            <span>{$t('Lot.materialPropertiesCommentsWithoutMandatory')}</span>
                            <div style="transform: rotate({materialCommentsRequiredOpen ? '90deg' : '0'}); transition: transform .4s ease-out;">
                                <Icon src={iconClose} size={20} clickable/>
                            </div>
                        </div>
                        <div class="divider" style="background: {materialCommentsRequiredOpen ? '#fff' : '#d9d9d9'};"/>
                        <div style="height: {materialCommentsRequiredOpen ? '8.1875' : '0'}rem; transition: height .5s ease-out;">
                            <TextArea bind:text={$currentLot.materialPropertiesCommentWithoutOptional}
                                      marginTop="0" labelGap="0"
                                      readonly={!lotEditFieldsView.canEditLot}
                                      height={textAreaHeights.MID}
                                      on:focusout={triggerLotValidationAndSave}/>
                        </div>
                    </div>
                {/if}
                <div style="grid-area: volume">
                    <InputField bind:value={$currentLot.volume} {fontProps} unit="T" id="lotVolume"
                                format={inputFieldFormats.FULL} placeholder={$t("UI.map.lot.volume.ph")}
                                readOnly={!lotEditFieldsView.canEditLot} errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'volume'}
                                on:focusout={triggerLotValidationAndSave}
                                isNumeric required/>
                </div>
                <div style="grid-area: dailyCapacity">
                    <InputField bind:value={$currentLot.dailyCapacity} {fontProps} unit="T" format={inputFieldFormats.FULL}
                                placeholder={$t("UI.map.lot.dailyCapacity.ph")} readOnly={!lotEditFieldsView.canEditLot}
                                errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'dailyCapacity'} id="lotDailyCapacity"
                                on:focusout={triggerLotValidationAndSave}
                                isNumeric/>
                </div>
                <div class="vehicle-categories-container">
                    <AutocompleteInputField bind:value={vehicleCategoriesValue}
                                            bind:selectedObject={$currentLot.vehicleCategories}
                                            bind:disabled={canNotEditLot} options={$vehicleCategories}
                                            dropdownMaxHeight="6.25rem"
                                            {fontProps} autocompleteConfig={vehicleCategoryAcConfig}
                                            label={$t("UI.lot.vehicleCategories.label")}
                                            errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'vehicleCategories'} height="100%"
                                            on:change={triggerLotValidationAndSave}/>
                </div>
                <div style="grid-area: pricePerTon">
                    <InputField bind:value={$currentLot.grossPricePerTon} {fontProps} unit="€" forcedDecimalPlaces={2}
                                format={inputFieldFormats.FULL} placeholder={$t("UI.map.lot.pricePerTon.ph")}
                                readOnly={$currentLot.calcMethod === CalcMethod.REQUEST_OFFER || !lotEditFieldsView.canEditLot}
                                errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'grossPricePerTon'}
                                on:focusout={triggerLotValidationAndSave}
                                isNumeric/>
                </div>
                <div style="grid-area: kickbackInPercent">
                    <InputField bind:value={$currentLot.kickbackInPercent} {fontProps} unit="%" format={inputFieldFormats.FULL}
                                placeholder={$t("UI.map.lot.kickbackInPercent.ph")} readOnly={!lotEditFieldsView.canEditLot}
                                errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'kickbackInPercent'}
                                on:focusout={triggerLotValidationAndSave}
                                isNumeric/>
                </div>
                <div style="grid-area: kickbackPerTon">
                    <InputField bind:value={$currentLot.kickbackPerTon} {fontProps}
                                unit="€" forcedDecimalPlaces={2} format={inputFieldFormats.FULL}
                                readOnly={!lotEditFieldsView.canEditLot} placeholder={$t("UI.map.lot.kickbackPerTon.ph")}
                                errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'kickbackPerTon'}
                                on:focusout={triggerLotValidationAndSave}
                                isNumeric/>
                </div>
            </div>
        </div>
    </div>
</NullableDataWrapper>

<style lang="scss">
  @import "../../../../styles/global";
  @import "../../../../styles/fak";

  .lot-form-container {
    padding-left: 1.313rem;
    padding-right: 1.313rem;
    padding-bottom: 1.5rem;
    width: 100%;
    max-width: 450px;
    @include flex-col($justify: flex-start);

    & > span, & > div > span {
      @include roboto-font(1rem, 400, 0.875rem, black);
      align-self: start;
      padding-top: 1em;
      padding-bottom: 0.875em;
    }
  }

  .lot-material-data {
    width: 100%;
    padding-top: 0.4rem;
    @include flex-col(0.625rem, $alignment: flex-start);

    & > div {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));;
      grid-row-gap: 0.938rem;
      grid-column-gap: 0.625rem;
    }
  }

  .material-comments-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span {
      @include roboto-font(normal, 400, 0.875rem);
    }
  }

  .vehicle-categories-container {
    grid-area: vehicleCategories;

    & :global(.sv-dropdown) {
      position: static;
    }
  }

  .divider {
    height: 1px;
    margin-top: .5rem;
    border-radius: 50%;
    transition: background .3s ease-out;
  }
</style>
