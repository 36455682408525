<script lang="ts">
    import {Route, Router} from "svelte-routing";
    import {pageError} from "./lib/stores";
    import {t} from "./i18n/i18n";
    import {MaterialView} from "./lib/mmpv/types/materialView";
    import VehicleCategoriesLoader from "./lib/atoms/VehicleCategoriesLoader.svelte";

    import OptimizerPage from "./lib/optimizer/OptimizerPage.svelte";
    import OffererPubPage from "./lib/pages/OffererPubPage.svelte";
    import PubPage404 from "./lib/pages/PubPage404.svelte";
    import PubPage40x from "./lib/pages/PubPage40x.svelte";
    import OptimizerPageSaas from "./lib/optimizer/saas/OptimizerPageSaas.svelte";
    import OptimizerPubPageFinished from "./lib/pages/OptimizerPubPageFinished.svelte";
    import InstallationCasesPage from "./lib/ic/InstallationCasesPage.svelte";
    import MaterialCatalogPageManager from "./lib/mmpv/MaterialCatalogPageManager.svelte";
    import LotAccountingMailConfigurationPage from "./lib/pages/LotAccountingMailConfigurationPage.svelte";
    import MapView from "./lib/map/MapView.svelte";
    import DefaultLandingPage from "./lib/utils/DefaultLandingPage.svelte";
    import NestedSiriusIFramePage from "./lib/pages/NestedSiriusIFramePage.svelte";
    import HelpConfiguration from "./lib/help/configuration/HelpConfiguration.svelte";
    import HelpBox from "./lib/help/HelpBox.svelte";
    import FooterMM from "./lib/molecules/FooterMM.svelte";
    import MatchCheck from "./lib/pages/MatchCheck.svelte";
    import {onMount} from "svelte";
    import {loadHotjar} from "./lib/utils/hotjar";
    import AnalyticalPage from "./lib/analytics/AnalyticalPage.svelte";
    import CorporateDesignPage from "./lib/corporateDesign/CorporateDesignPage.svelte";

    // Used for SSR. A falsy value is ignored by the Router.
    // "SSR" means server side rendering, for everyone, like me, who didn't know yet
    export let url: string = "";

    let currentError: {
        code: number,
        message: string
    };
    pageError.subscribe(value => currentError = value)

    const production = import.meta.env.MODE === 'production';

    onMount(async () => {
        const PUB_PAGES_URLS_FOR_HOTJAR = ["/position/"];
        await loadHotjar(PUB_PAGES_URLS_FOR_HOTJAR.some(s => window.location.href.indexOf(s) > -1));
    });

</script>

<svelte:head>
    <style lang="scss">
      /* Global CSS via SASS */
      @import 'styles/main.scss';
      @import "styles/global.scss";
    </style>
    <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet'>
    <script src="https://unpkg.com/boxicons@2.1.4/dist/boxicons.js"></script>
</svelte:head>

{#if currentError}
    {#if currentError.code === 401}
        <PubPage40x description={currentError.message}/>
    {:else if currentError.code === 403}
        <PubPage40x code="403" description={currentError.message}/>
    {:else if currentError.code === 410}
        <OptimizerPubPageFinished {currentError}/>
    {:else}
        <PubPage404 description={currentError.message}/>
    {/if}
{:else}

    <VehicleCategoriesLoader/>
    <Router {url}>
        {#if !production}
            <Route path="/dev">
                <DefaultLandingPage/>
                <FooterMM/>
            </Route>
        {/if}
        <Route> <!-- route all urls that are not handled below to an iframe and show sirius UI -->
            <NestedSiriusIFramePage/>
        </Route>
        <Route path="/404" let:params>
            <PubPage404 description={$t('UI.404.desc')}/>
        </Route>
        <Route path="/401" let:params>
            <PubPage40x description={$t('UI.401.desc')}/>
        </Route>
        <Route path="/analysis">
            <AnalyticalPage/>
        </Route>
        <Route path="/analytic">
            <AnalyticalPage/>
        </Route>
        <Route path="/catalogs">
            <MaterialCatalogPageManager/>
        </Route>
        <Route path="/catalog/:catalogName/material/:materialId" let:params>
            <MaterialCatalogPageManager {...params}/>
        </Route>
        <Route path="/corporateDesign">
            <CorporateDesignPage/>
        </Route>
        <Route path="/hlpConfiguration">
            <HelpConfiguration/>
        </Route>
        <Route path="/hlp">
            <HelpBox/>
        </Route>
        <Route path="/installationCases">
            <InstallationCasesPage/>
        </Route>
        <Route path="/map">
            <MapView/>
        </Route>
        <Route path="/materialProperties">
            <MaterialCatalogPageManager view={MaterialView.MATERIAL_PROPERTIES}/>
        </Route>
        <Route path="/materialProperty/:materialPropertyId" let:params>
            <MaterialCatalogPageManager view={MaterialView.MATERIAL_PROPERTIES} {...params}/>
        </Route>
        <Route path="/materialSubstitutes">
            <MaterialCatalogPageManager view={MaterialView.MATERIAL_SUBSTITUTES}/>
        </Route>
        <Route path="/system/matching">
            <MatchCheck/>
        </Route>
        <Route path="/ui/lot/:lotId/accountingMailConfiguration" let:params>
            <LotAccountingMailConfigurationPage lotId={params.lotId}/>
        </Route>
        <Route path="/angebot/:requestPublicId/requested/:requestedEntityType/:requestedEntityId/user/:offerUserPublicId"
               let:params>
            <OptimizerPageSaas requestPublicId={params.requestPublicId} requestedEntityId={params.requestedEntityId}
                               requestedEntityType={params.requestedEntityType}
                               offerUserPublicId={params.offerUserPublicId}/>
        </Route>
        <!-- TODO qr /angebot/quotePublicId deleted -> equivalent necessary ?? -->
        <Route path="/lots/bid/:requestPublicId/requested/:requestedEntityType/:requestedEntityId/user/:offerUserPublicId"
               let:params>
            <OptimizerPage requestPublicId={params.requestPublicId} requestedEntityId={params.requestedEntityId}
                           requestedEntityType={params.requestedEntityType}
                           offerUserPublicId={params.offerUserPublicId}/>
        </Route>
        <Route path="/lots/won/:lotId" let:params>
            <OptimizerPage lotPublicId={params.lotId}/>
        </Route>
        <Route path="/position/:lotPublicId" let:params>
            <OffererPubPage lotPublicId={params.lotPublicId}/>
        </Route>
        <Route path="/position/:lotPublicId/gegenangebot/:reqPublicId/user/:offerUserPublicId" let:params>
            <OffererPubPage lotPublicId={params.lotPublicId} requestPublicId={params.reqPublicId}
                            offerUserPublicId={params.offerUserPublicId}/>
        </Route>
    </Router>
{/if}
