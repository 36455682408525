<script lang="ts">
    import {createEventDispatcher, getContext, onMount} from "svelte";
    import {t} from "../../i18n/i18n";
    import {addNotification, userIsLoggedIn} from "../stores.js";
    import {termsCheckboxChanged} from "./termsAndConditions";
    import {fetchUtils} from "../utils/fetchUtils";
    import {type Lot} from "../types/lot";
    import {NotificationType} from "../types/notification";
    import {savePublicComment, updateAttachmentsLot} from "../pages/OffererPubPage.svelte";
    import Salutation from "../atoms/Salutation.svelte";
    import InfoCard from "../organisms/InfoCard.svelte";
    import ProjectInformationGrid from "../project/ProjectInformationGrid.svelte";
    import TextArea, {textAreaHeights} from "../atoms/TextArea.svelte";
    import SecondaryButton from "../atoms/SecondaryButton.svelte";
    import {ButtonIcons} from "../types/enums";
    import {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import AgbButton from "../organisms/AgbButton.svelte";
    import FileUpload from "../organisms/FileUpload.svelte";
    import PublicPageHeader from "../molecules/PublicPageHeader.svelte";
    import PublicPageFooter from "../molecules/PublicPageFooter.svelte";
    import Basic from "../templates/Basic.svelte";
    import ContactBox from "../atoms/ContactBox.svelte";
    import type {PubPageParams} from "../bid/types/pubPageParams";
    import type {Writable} from "svelte/store";

    const urlParams: PubPageParams = getContext("urlParams") || null;
    const dispatch = createEventDispatcher();

    const lot: Writable<Lot> = getContext("lot");

    let lotPublicId: string | undefined;
    let agbAccepted = false;
    let attachmentsLot: any[] = [];
    let attachmentsUploadUrl: string;


    onMount(async () => {
        lotPublicId = urlParams?.lotPublicId;
        attachmentsLot = await updateAttachmentsLot(lotPublicId);

    })

    function approveLot(): void {
        if (agbAccepted === true) {
            let request = {isAgbAccepted: agbAccepted};
            fetchUtils.post('/api/offerer/lot/' + lotPublicId + '/approve', request)
                .then(() => {
                    createUpdateLotEvent(lotPublicId);
                })
                .catch(fetchUtils.catchErrorAndShowNotification());

        } else {
            let notification = {
                message: $t('UI.TermsAndConditions.notAccepted.error'), type: NotificationType.ERROR, timeout: 0,
                dismissible: true
            };
            addNotification(notification);
        }
    }

    function createUpdateLotEvent(id: string | undefined) {
        dispatch('updateLot', {lotPublicId: id ?? lotPublicId});
    }

    function agbClicked() {
        termsCheckboxChanged(
            lotPublicId,
            agbAccepted,
            $t('UI.termsConditions.approval.clicked')
        );
    }

    $: {
        if (lotPublicId) {
            attachmentsUploadUrl = '/api/lot/pub/' + lotPublicId + '/attachment/new';
        }
    }

</script>

{#if !$userIsLoggedIn}
    <PublicPageHeader/>
{/if}

<Basic showStandardHeaderFooter={$userIsLoggedIn}>
    <div class="container-salutation-contact">
        <Salutation name={$lot?.personAddressableName} address={$lot?.address?.zip + " " + $lot?.address?.city}/>
        {#if $userIsLoggedIn}
            <ContactBox/>
        {/if}
    </div>
    <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12">
            <InfoCard offererPage/>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12">
            <div class="container-project-information">
                <ProjectInformationGrid title={$t('UI.project.information')} anonymizeAddress={false} {...$lot}/>
                <TextArea title={$t("UI.materialDetailsComment.header")} text={$lot?.lotRemarks}
                          placeholder={$t('UI.remarks.noRemarks')}
                          height={textAreaHeights.SMALL} readonly/>
                <TextArea title={$t("UI.projectDetailsComment.header")} text={$lot?.projectRemarks}
                          placeholder={$t('UI.remarks.noRemarks')}
                          height={textAreaHeights.SMALL} isHtml readonly/>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 1.875rem">
        <div class="col-lg-12 col-sm-12">
            <FileUpload parentPublicId={lotPublicId} uploadUrl={attachmentsUploadUrl}
                        filePrefix={$t('UI.attachments.offerer.prefix')}
                        visibleToCustomer={1} visibleToPartner={0}
                        updateFun={() => updateAttachmentsLot(lotPublicId || $lot?.publicId).then(data => attachmentsLot = data)}
                        attachments={attachmentsLot}/>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-sm-12">
                <TextArea id="offererPublicComment" bind:text={$lot.remarks} title={$t('UI.remarks.label')}
                          placeholder={$t("UI.pubRemarks.placeholder.detailedInfo")}/>
            <div class="align-right" style="margin-top: 1.125rem">
                <SecondaryButton label={$t("UI.saveRemark")} leftIcon={ButtonIcons.CHECKMARK}
                                 on:click={() => savePublicComment($lot?.remarks, lotPublicId)}/>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 1.875rem">
        <div class="col-lg-12 col-sm-12">
            <AgbButton bind:agbAccepted label={$t('UI.publicPage.approveProject')}
                       color={PrimaryBtnColors.GREEN} description={$t('UI.approval.terms.description')}
                       on:checkboxChange={agbClicked} on:click={approveLot}/>
        </div>
    </div>

</Basic>

{#if !$userIsLoggedIn}
    <PublicPageFooter/>
{/if}


<style lang="scss">
  @import "../../styles/pubPage";


</style>
