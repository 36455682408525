<script lang="ts">
    import {t} from "../../i18n/i18n";
    import {caseOwnerContact} from "../stores";
    import Tooltip from "./Tooltip.svelte";
    import {afterUpdate} from "svelte";

    let showTooltip: boolean = false;
    let caseOwnerMailContainer: HTMLElement;
    const onlyContainsNumbers = (str: string) => /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/.test(str); //see https://stackoverflow.com/questions/41538589/regexp-for-german-phone-number-format/53854183#53854183

    afterUpdate(() => {
        caseOwnerMailContainer = document.querySelectorAll('.case-owner-mail')[0] as HTMLElement;
        if (caseOwnerMailContainer && isEllipsisActive(caseOwnerMailContainer)) {
            showTooltip = true;
        }
    })

    function isEllipsisActive(element: HTMLElement) {
        return element.offsetWidth < element.scrollWidth;
    }

    $: caseOwnerMobileLength = ($caseOwnerContact?.mobile?.length ?? 0)
    $: caseOwnerPhoneLength = ($caseOwnerContact?.phone?.length ?? 0)
    $: caseOwnerMailLength = ($caseOwnerContact?.eMail?.length ?? 0)
    $: caseOwnerNameLength = ($caseOwnerContact?.fullName?.length ?? 0)

</script>


<div class="contact-box-optimizer">
    {#if caseOwnerNameLength > 0}
        <div class="contact-box-contact">
            <span class="contact-box-headline">{$t('UI.MineralMinds.contactPerson')}</span>
            <span class="case-owner-full-name">{$caseOwnerContact.fullName}</span>
            {#if caseOwnerMobileLength > 0 && onlyContainsNumbers($caseOwnerContact.mobile) || caseOwnerPhoneLength > 0 && onlyContainsNumbers($caseOwnerContact.phone)}
                {#if caseOwnerMobileLength > 0 && onlyContainsNumbers($caseOwnerContact.mobile)}
                    <div>
                        <span>Mobil:</span>
                        <a class="case-owner-mobile"
                           href="tel:{$caseOwnerContact.mobile}">{$caseOwnerContact.mobile}</a>
                    </div>
                {/if}
                {#if caseOwnerPhoneLength > 0 && onlyContainsNumbers($caseOwnerContact.phone)}
                    <div>
                        <span>Telefon: </span>
                        <a class="case-owner-phone" href="tel:{$caseOwnerContact.phone}">{$caseOwnerContact.phone}</a>
                    </div>
                {/if}
            {:else}
                <div>
                    <span>Telefon:</span>
                    <a class="case-owner-phone" href="tel:{$t('UI.MineralMinds.phoneNr')}">{$t('UI.MineralMinds.phoneNr')}</a>
                </div>
            {/if}
            {#if caseOwnerMailLength > 0}
                <div>
                    <span>E-Mail:</span>
                    {#if showTooltip}
                        <div>
                            <Tooltip msg={$caseOwnerContact.eMail}>
                                <div class="case-owner-mail-wrapper">
                                    <a class="case-owner-mail"
                                       href="mailto:{$caseOwnerContact.eMail}">{$caseOwnerContact.eMail}</a>
                                </div>
                            </Tooltip>
                        </div>
                    {:else }
                        <a class="case-owner-mail" href="mailto:{$caseOwnerContact.eMail}">{$caseOwnerContact.eMail}</a>
                    {/if}
                </div>
            {/if}
        </div>
    {:else}
        <div class="contact-box-contact contact-box-mm-contact">
            <span class="contact-box-headline">{$t('UI.MineralMinds.thereForYou')}</span>
            <div>
                <span>Telefon: </span>
                <a id="contact-box-default-phone" href="tel:{$t('UI.MineralMinds.phoneNr')}">{$t('UI.MineralMinds.phoneNr')}</a>
            </div>
        </div>
    {/if}
</div>


<style lang="scss">
  @import "../../styles/global";

  .contact-box-default-phone-icon {
    width: 12px;
    height: 12px;
    background-size: contain;
    background: url("@/icons/icon_phone.svg") no-repeat;
    margin-top: 1px;
  }

  span {
    height: 11px;
    @include roboto-font(11px, 500, 0.75rem, black);
    text-align: left;
  }

  .contact-box-optimizer {
    width: 16.125rem;
    height: 5.5rem;
    background-color: white;
    border-radius: 0.25rem;
    border: 2px solid $primaryGreen;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1rem;
  }

  .contact-box-contact {
    display: flex;
    flex-direction: column;
    row-gap: 0.16rem;
    max-width: 15rem;

    & > span.contact-box-headline {
      @include roboto-font(0.875rem, 700, 0.6875rem, $primaryGreen);
      text-transform: uppercase;
    }

    & > span {
      @include roboto-font(0.875rem, 400, 0.625rem, black);
    }

    & > div {
      display: flex;
      flex-direction: row;

      & > span {
        @include roboto-font(0.875rem, 400, 0.625rem, black);
        padding-right: 0.2rem;
        align-self: baseline;
      }

      & a {
        @include roboto-font(0.875rem, 400, 0.625rem, $primaryGreen);
        align-self: baseline;
      }

      & a.case-owner-mail {
        @include roboto-font(0.875rem, 400, 0.625rem, $primaryGreen);
        align-self: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 12rem;
        height: 11px;
      }
    }
  }

  .case-owner-mail-wrapper {
    text-overflow: ellipsis;
    display: flex;
    height: 11px;
  }

  .contact-box-mm-contact {
    row-gap: 0.5rem;
  }

</style>