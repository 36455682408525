<script lang="ts">
    import type {MatchResults} from "../types/matching/matchResults";
    import InputField from "../molecules/InputField.svelte";
    import PrimaryButton from "../atoms/PrimaryButton.svelte";
    import {MatchOutcome} from "../types/matching/matchOutcome";
    import {fetchUtils} from "../utils/fetchUtils";
    import Basic from "../templates/Basic.svelte";
    import {inputFieldFormats} from "../utils/formatters";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import {type FontProps} from "../types/fontProps";
    import {DefaultLabelFontProps} from "../types/fontProps.js";
    import {t} from "../../i18n/i18n"

    const headerFontProps: FontProps = DefaultLabelFontProps();
    // setContext("useMaterialDesign", true);

    let referenceId: string;
    let referenceType: string;
    let targetId: string;
    let targetType: string;
    let matchResults: MatchResults;

    let collapsed: any = {};

    const matchTypeOptions = [
        {label: "Standort", value: "POINTOFPRESENCE"},
        {label: "Position", value: "LOT"},
        {label: "Profil", value: "PROFILE"},
    ]

    function matchCheck() {
        fetchUtils.get(`/api/matchCheck?reference=${referenceType}-${referenceId}&candidate=${targetType}-${targetId}`)
            .then(data => {
                matchResults = data;
            })
    }

    function toggle(idx: number) {
        if (collapsed[idx]) {
            collapsed[idx] = false;
        } else {
            collapsed[idx] = true;
        }
    }


</script>

<Basic>
    <div class="content">
        <h5>{$t("UI.system")}</h5>
        <h1>{$t("UI.matchcheck.title")}</h1>
        <div class="matchingcheck__input">
            <AutocompleteInputField bind:value={referenceType} {headerFontProps} options={matchTypeOptions}
                                    label={$t("UI.matchcheck.ref.type")}/>
            <InputField bind:value={referenceId} {headerFontProps} format={inputFieldFormats.FULL}
                        label={$t("UI.matchcheck.ref.id")}/>
            <AutocompleteInputField bind:value={targetType} {headerFontProps} options={matchTypeOptions}
                                    label={$t("UI.matchcheck.candidate.type")}/>
            <InputField bind:value={targetId} {headerFontProps} format={inputFieldFormats.FULL}
                        label={$t("UI.matchcheck.candidate.id")}/>
        <PrimaryButton label={$t("UI.matchcheck.check")} on:click={matchCheck}/>
        </div>

        {#if matchResults}
            <div class="card">
                <span style="font-weight: 500">{$t("UI.matchcheck.ref")}: {matchResults.reference.label}</span>
            </div>
            {#each matchResults.results as matchResult, i (i)}
                <div class="card">
                    <div class="matchresult__row" class:collapsed={collapsed[i]} on:click={() => toggle(i)}>
                        <span>{$t("UI.matchcheck.candidate")}: {matchResult.matchPartner.label}</span>
                        <i class="bx bx-chevron-up" class:bx-rotate-180={collapsed[i]}></i>
                    </div>
                    {#each matchResult.ruleResults as ruleResult}
                        <div class="matchingcheck__row" class:collapsed={collapsed[i]}>
                            <div>
                                <span>{ruleResult.matchingRule}:</span>
                            </div>
                            <div>
                                {#if ruleResult.outcome === MatchOutcome.MATCH}
                                    <span class="matchingcheck__table--match">{$t("UI.matchcheck.match")}</span>
                                {:else if ruleResult.outcome === MatchOutcome.CONDITIONAL_MATCH}
                                    <span class="matchingcheck__table--conditional-match">{$t("UI.matchcheck.conditionalMatch")} {ruleResult.matchCondition.description || ''}</span>
                                {:else if ruleResult.outcome === MatchOutcome.IGNORE}
                                    <span class="matchingcheck__table--ignored">{$t("UI.matchcheck.ignored")} {ruleResult.noMatchReason || ''}</span>
                                {:else}
                                    <span class="matchingcheck__table--no-match">{$t("UI.matchcheck.noMatch")} {ruleResult.noMatchReason || ''}</span>
                                {/if}
                                (Duration: {ruleResult.duration || 0}ms)
                            </div>
                        </div>
                    {/each}
                </div>
            {/each}
        {/if}
    </div>
</Basic>

<style lang="scss">
  @use "../../styles/global";

  h5 {
    color: global.$grey-800;
    font-size: 1rem;
  }
  h1 {
    @include global.roboto-font(normal, 500, 1.75rem, black);
    margin-bottom: 2.5rem;
  }


  .content {
    margin: auto;
    height: 100%;
    width: 100%;
    max-width: 1110px;
    padding: 1rem 2rem;
    overflow-y: auto;
  }

  div {
    text-align: start;
  }

  .card {
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding: 0.75rem;
  }

  .matchingcheck__input {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1.375rem;
    margin-bottom: 1rem;
    align-items: end;
    @media screen and (min-width: 720px) {
      grid-gap: 2.5rem 0.875rem;
      grid-template-columns: 1fr 3fr;
    }
  }

  .matchresult__row {
    @include global.flex-row($justify: space-between);
    margin-bottom: 1rem;
    font-weight: 500;
    cursor: pointer;
    padding: 0 1.5rem 0 0;

    &.collapsed {
      margin-bottom: unset;
    }

    &:hover {
      color: global.$primaryBlue;
    }
  }

  .matchingcheck__row {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 20px;
    margin-bottom: 0.5rem;

    &.collapsed {
      padding: 0 18px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      display: none;
    }

    & .matchingcheck__table--match {
      color: global.$primaryGreen;
    }

    & .matchingcheck__table--conditional-match {
      color: #d39e00;
    }

    & .matchingcheck__table--ignored {
      color: global.$primaryDarkOrange;
    }

    & .matchingcheck__table--no-match {
      color: global.$primaryRed;
    }
  }

</style>
