<script lang="ts">

    import CollapsibleCard from "../../molecules/CollapsibleCard.svelte";
    import InputField from "../../molecules/InputField.svelte";
    import PrimaryButton from "../../atoms/PrimaryButton.svelte";
    import SecondaryButton from "../../atoms/SecondaryButton.svelte";
    import DoubleRangeSlider from "../../atoms/DoubleRangeSlider.svelte";
    import {setContext} from "svelte";
    import {inputFieldFormats} from "../../utils/formatters";
    import {t} from "../../../i18n/i18n";
    import iconDashPrimary from "@/icons/dash-primary.png";

    setContext('useMaterialDesign', true);

    export let from = 0;
    export let to = 500;
    let max = 1000;

    let originalFrom = from;
    let originalTo = to;

    let cardOpen = false;
    let showCard = false;

    let closeCard: () => void;

    window.addEventListener("current-lot-changed", (e) => {
        showCard = true;
    });

    window.addEventListener("lot-unselected", () => showCard = false);

    function acceptValues() {
        originalFrom = from;
        originalTo = to;
        window.dispatchEvent(new CustomEvent<any>('matching-distance-changed', {detail: {from: from, to: to}}));
        if (closeCard) {
            closeCard();
        }
    }

    function rejectValueChange() {
        from = originalFrom;
        to = originalTo;
        if (closeCard) {
            closeCard();
        }
    }
</script>

{#if showCard}
    <div>
        <CollapsibleCard bind:open={cardOpen} collapseHeader={false} bind:handleHeaderClick={closeCard}>
            <div slot="header" class="distance-header" style="padding: 23px 50px 19px 34px">
                <span>{$t("Matching.setDistance.title")}</span>
                {#if !cardOpen}
                    &nbsp;<span class="distance">{originalFrom} - {originalTo} km</span>
                {/if}
            </div>
            <div slot="body" class="distance-body">
                <div style="margin-bottom: 23px; font-size: 12px; line-height: 150%">
                    {$t("Matching.setDistance.help")}
                </div>
                <div style="display: flex; justify-content: center; column-gap: 22px;">
                    <InputField isNumeric={true} unit="km" containerWidth="65px" format={inputFieldFormats.COMPACT}
                                bind:value={from} label="Min.">

                    </InputField>
                    <div style="margin: auto 20px">
                        <img src={iconDashPrimary}/>
                    </div>
                    <InputField isNumeric={true} unit="km" containerWidth="65px" format={inputFieldFormats.COMPACT}
                                bind:value={to} label="Max.">

                    </InputField>
                </div>
                <div>
                    <DoubleRangeSlider bind:left={from} bind:right={to} max={max}></DoubleRangeSlider>
                </div>
                <div style="display: flex; justify-content: end; column-gap: 4px">
                    <SecondaryButton disabled={originalTo === to && originalFrom === from} sizeAdaptingToText={true}
                                     label="Abbrechen" on:click={rejectValueChange}/>
                    <PrimaryButton disabled={originalTo === to && originalFrom === from} sizeAdaptingToText={true}
                                   label="Festlegen" on:click={acceptValues}/>
                </div>
            </div>
        </CollapsibleCard>
    </div>
{/if}

<style lang="scss">
  @import "../../../styles/global";
  @import "../../../styles/colors";

  .distance-header {
    @include roboto-font(18.75px, 400, 1rem, black);
  }

  .distance-body {
    @include roboto-font(15px, 400, 10PX, black);
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 24px 20px;
    row-gap: 10px;
    max-width: 420px;
    color: #1e1e1e;
  }

  .distance {
    color: $primaryGreen;
  }

  // override some global styles for map
  div > :global(.card) {
    border: none !important;
    border-radius: 10px !important;
    box-shadow: none !important;
  }

  div > :global(.card .arrow-handle) {
    top: 16px !important;
    right: 21px !important;
  }


</style>
