<script lang="ts">

    import FileUpload from "../../../organisms/FileUpload.svelte";
    import {fetchUtils} from "../../../utils/fetchUtils";
    import {DefaultAttachmentView} from "../../../types/attachment";
    import {derived} from 'svelte/store';
    import {currentLot} from "../utils/fakStores";
    import {LotState} from "../../../types/enums";

    export let hasAttachments = false;

    let attachments;
    let uploadUrl: string = "";
    const filePrefix = ""; // TODO make file prefix
    const hideHints = true;
    const visibilityUrl = "/api/lot/attachment/";

    async function fetchAttachments() {
        if ($currentLot && $currentLot.id !== "new") {
            await fetchUtils.get(`/api/lot/${$currentLot.id}/attachments`, false)
                .then(data => {
                    attachments = [];
                    for (let i = 0; i < data.length; i++) {
                        let a = DefaultAttachmentView();
                        Object.assign(a, data[i]);
                        attachments.push(a);
                    }
                    hasAttachments = attachments.length > 0;
                })
                .catch(fetchUtils.catchErrorAndShowNotification());
        }
    }

    let currentLotId = derived(
        currentLot,
        $currentLot => $currentLot?.id
    );

    currentLotId.subscribe(() => {
        if ($currentLot) {
            fetchAttachments();
            uploadUrl = `/api/lot/${$currentLot.id}/attachment/new`;
        }
    });
</script>

<div style="padding: 0 1.5625rem 1.5rem;">
    <FileUpload bind:attachments
                {uploadUrl}
                {filePrefix}
                visibleToCustomer={$currentLot?.state === LotState.UNVERIFIED ? 1 : 0}
                {hideHints}
                updateFun={fetchAttachments}
                showVisibilityManagement={true}
                {visibilityUrl}/>
</div>
