<script lang="ts">

    import type {DealPartnerConnectData} from "../types/dealPartnerConnect";
    import {DefaultDealPartnerConnectData} from "../types/dealPartnerConnect";
    import InputField from "../molecules/InputField.svelte";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import {DefaultAutocompleteConfig} from "../types/autocompleteFieldConfig";
    import {getSalutationOptions} from "../types/enums";
    import {DefaultInputFieldProps, DefaultRobotoFontProps, type FontProps} from "../types/fontProps";
    import ChipList from "./ChipList.svelte";
    import {t} from "../../i18n/i18n";
    import {inputFieldFormats} from "../utils/formatters";

    const fontProps: FontProps = DefaultInputFieldProps();

    export let data: DealPartnerConnectData = DefaultDealPartnerConnectData();
    export let title;

    let salutationConfig = DefaultAutocompleteConfig();
    salutationConfig.clearable = false;
    let salutationOptions = getSalutationOptions();

</script>

<div class="qpc-container">
    <span>{title}</span>
    <div class="qpc-grid">
        <div class="qpc-titleSalutation" style="grid-area: titleSalutation">
            <AutocompleteInputField bind:value={data.person.salutation} {fontProps} options={salutationOptions}
                                    autocompleteConfig={salutationConfig} label={$t('UI.contactPerson.salutation.ph')}/>
            <InputField bind:value={data.person.title} placeholder={$t('UI.contactPerson.title.ph')} format={inputFieldFormats.FULL}/>
        </div>
        <div style="grid-area: phone">
            <InputField bind:value={data.contactPhone} placeholder={$t('UI.contactPerson.phone.ph')} format={inputFieldFormats.FULL}/>
        </div>
        <div style="grid-area: firstName">
            <InputField bind:value={data.person.firstName} placeholder={$t('UI.contactPerson.firstName.ph')} format={inputFieldFormats.FULL}/>
        </div>
        <div style="grid-area: lastName">
            <InputField bind:value={data.person.lastName} placeholder={$t('UI.contactPerson.lastName.ph')} format={inputFieldFormats.FULL}/>
        </div>
        <div style="grid-area: companyName">
            <InputField bind:value={data.companyName} placeholder={$t('UI.company.name.ph')} format={inputFieldFormats.FULL}/>
        </div>
        <div style="grid-area: zip">
            <InputField bind:value={data.address.zip} placeholder={$t('UI.map.zip.ph')} format={inputFieldFormats.FULL}/>
        </div>
        <div style="grid-area: city">
            <InputField bind:value={data.address.city} placeholder={$t('UI.map.place.ph')} format={inputFieldFormats.FULL}/>
        </div>
        <div style="grid-area: street">
            <InputField bind:value={data.address.street} placeholder={$t('UI.map.street.ph')} format={inputFieldFormats.FULL}/>
        </div>
        <div style="grid-area: mails">
            <ChipList bind:list={data.emails} placeholder={$t('UI.modal.connectPartners.recipients.ph')}
                      chipFontProps={DefaultRobotoFontProps('0.75rem', '0.625rem', '#9d9d9d', 'black')}/>
        </div>
    </div>
</div>

<style lang="scss">
  @import "../../styles/global";

  .qpc-container {
    @include flex-col(0.875rem, $justify: flex-start, $alignment: flex-start);
    width: 100%;

    & > span {
      @include roboto-font(18px, 400, 0.875rem, black);
    }
  }

  .qpc-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
              "titleSalutation phone"
              "firstName lastName"
              "companyName companyName"
              "street street"
              "zip city"
              "mails mails";
    grid-column-gap: 0.625rem;
    grid-row-gap: 1.125rem;
    width: 100%;

    & > .qpc-titleSalutation {
      display: grid;
      grid-template-columns: 2fr 3fr;
      grid-column-gap: 0.625rem;
    }

  }
</style>
