<script lang="ts">
    import {onMount} from "svelte";

    export let query: string = ""; // syntax: (min-width: 768px) and (max-width: 1200px)
    let matches: boolean = false; // result of query

    const checkMediaQuery = () => {
        matches = window.matchMedia(query).matches;
    };

    onMount(() => {
        checkMediaQuery();
        window.addEventListener("resize", checkMediaQuery);

        return () => {
            window.removeEventListener("resize", checkMediaQuery);
        };
    });
</script>

{#if matches}
    <slot/>
{/if}
