/* in case we have the same component with several different font styles, we can use this type and these methods to get the style */
export type FontProps = {
    fontSize: string,
    fontWeight: number,
    fontStyle: string,
    lineHeight: string,
    color: string,
    placeholderColor: string,
    fontFamily: string,
}

function DefaultFontProps(placeholderColor: string, color: string, fontSize: string, fontStyle: string, fontWeight: number, lineHeight: string, fontFamily: string) {
    let inputFieldProps: FontProps = {
        placeholderColor: placeholderColor,
        color: color,
        fontSize: fontSize,
        fontStyle: fontStyle,
        fontWeight: fontWeight,
        lineHeight: lineHeight,
        fontFamily: fontFamily,
    };
    return inputFieldProps;
}

export function DefaultRobotoFontProps(lineHeight: string, fontSize: string, placeholderColor: string = '#9d9d9d', color: string = 'black', fontWeight: number = 400) {
    let inputFieldProps: FontProps = {
        placeholderColor: placeholderColor,
        color: color,
        fontSize: fontSize,
        fontStyle: 'normal',
        fontWeight: fontWeight,
        lineHeight: lineHeight,
        fontFamily: 'Roboto',
    };
    return inputFieldProps;
}

export function DefaultInputFieldProps(lineHeight = "13px", fontWeight = 400, fontSize = "0.75rem", color = "black", placeholderColor = "#9d9d9d", fontStyle = "normal", fontFamily = 'Roboto'): FontProps {
    return DefaultFontProps(placeholderColor, color, fontSize, fontStyle, fontWeight, lineHeight, fontFamily);
}

export function DefaultLabelFontProps(lineHeight = 'normal', fontWeight = 500, fontSize = '1rem', color = 'black') {
    return DefaultRobotoFontProps(lineHeight, fontSize, '#9d9d9d', color, fontWeight);
}


export function getFontPropsVarString(props: FontProps, prefix: string = ""): string {
    if (!props) return "";
    return `--${prefix !== "" ? prefix + "-" : ""}line-height: ${props.lineHeight};
            --${prefix !== "" ? prefix + "-" : ""}font-size: ${props.fontSize};
            --${prefix !== "" ? prefix + "-" : ""}font-weight: ${props.fontWeight};
            --${prefix !== "" ? prefix + "-" : ""}font-style: ${props.fontStyle};
            --${prefix !== "" ? prefix + "-" : ""}color: ${props.color};
            --${prefix !== "" ? prefix + "-" : ""}placeholder-color: ${props.placeholderColor}; 
            --${prefix !== "" ? prefix + "-" : ""}font-family: ${props.fontFamily};`
}

export function getFontPropsStyleString(props: FontProps): string {
    return getFontPropsVarString(props).replace(/--/gi, '');
}
