<script lang="ts">
    export let order: "asc" | "desc" | undefined = undefined;
</script>

<svg width="18px" height="18px" viewBox="0 0 24 24"
     xmlns="http://www.w3.org/2000/svg">
    <g id="a">
        <g class:green-stroke={order === "asc"}>
            <line class="sort-arrow-line" x1="12" x2="7" y1="19.5" y2="14.3"
                  stroke-width="2"/>
            <line class="sort-arrow-line" x1="12" x2="17" y1="19.5"
                  y2="14.3"
                  stroke-width="2"/>
        </g>
        <g class:green-stroke={order === "desc"}>
            <line class="sort-arrow-line" x1="12" x2="7" y1="4.5" y2="9.7"
                  stroke-width="2"/>
            <line class="sort-arrow-line" x1="12" x2="17" y1="4.5" y2="9.7"
                  stroke-width="2"/>
        </g>
    </g>
</svg>

<style lang="scss">
  @import "../../styles/colors";

  .sort-arrow-line {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: black;
  }

  .green-stroke > line {
    stroke: $primaryGreen;
  }
</style>
