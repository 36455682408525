<script lang="ts">
    import {t} from "../../../../i18n/i18n";
    import PrimaryButton, {PrimaryBtnColors} from "../../../atoms/PrimaryButton.svelte";
    import SecondaryButton from "../../../atoms/SecondaryButton.svelte";
    import {DefaultLot, validateAndSaveLot} from "../../types/lot";
    import {currentLot} from "../utils/fakStores";
    import {adjustMargin} from "../utils/fakUtils";


    $: newLotOpen = $currentLot && $currentLot.id === "new";

    function addNewLot() {
        $currentLot = DefaultLot();
        adjustMargin($currentLot, true);
    }

    function createLot() {
        validateAndSaveLot();
    }
</script>

<li class="fak-acc-item">
    <div class="add-lot-container">
        <span class="add-lot-label">{$t('UI.map.card.lots')}</span>
        <div style="padding-right: 0.75rem">
            {#if newLotOpen}
                <div class="add-lot-btns">
                    <SecondaryButton on:click={() => $currentLot = null} id="btn-cancel-create-new-lot" label={$t('Button.cancel')} sizeAdaptingToText/>
                    <PrimaryButton on:click={createLot} id="btn-create-lot" label={$t('Basedata.create')} color={PrimaryBtnColors.GREEN} width="4.875rem"/>
                </div>
            {:else}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div id="buttonAddLot" class="green-icon-36" on:click={addNewLot}>
                    <div class="add-icon-24"></div>
                </div>
            {/if}
        </div>
    </div>
</li>

<style lang="scss">
  @import "../../../../styles/global";
  @import "../../../../styles/fak";
  @import "../../../../styles/icons";

  .add-lot-container {
    background: #FFFFFF;
    border-radius: 0.25rem;
    user-select: none;
    height: 3.875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .add-lot-label {
    padding-left: 1.5625rem;
    padding-top: 0.313rem;
    @include roboto-font(normal, 400);
  }

  .add-lot-btns {
    display: flex;
    gap: 0.25rem;
  }

  .green-icon-36 {
    background-color: $primaryGreen;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    @include flex-col();
    cursor: pointer;

    &:hover {
      background-color: $secondaryGreen;
    }
  }

  .add-icon-24 {
    @extend .icon-24;
    @extend .icon-info;
    background-image: url("@/icons/icon_add_autocomplete_entry.svg");
  }
</style>
