<script context="module" lang="ts">
    import {NotificationType} from "../types/notification";

    export function getColorForNotificationType(type: NotificationType): string {
        switch (type) {
            case NotificationType.ERROR:
                return '#C05858';
            case NotificationType.INFO:
                return '#5888C0';
            case NotificationType.INFO_INLINE:
                return '#d9d9d9';
            case NotificationType.SUCCESS:
                return '#58C079';
        }
    }

    export const notificationTypesList = [
        {id: "error", color: '#C05858'}, // primaryRed
        {id: "info", color: '#5888C0'},// primaryBlue
        {id: "info-inline", color: '#ffffff'},// white
        {id: "success", color: '#58C079'} // primaryGreen
    ]; /*only necessary for the test page as far as I can see*/

</script>
<script lang="ts">
    import {createEventDispatcher} from "svelte";
    import {fly, fade} from 'svelte/transition';
    import DismissNotificationIcon from "../atoms/DismissNotificationIcon.svelte";

    const dispatch = createEventDispatcher();
    export let message = "";
    export let type: NotificationType = NotificationType.INFO;
    export let dismiss = false;
    export let width = '100%';
    export let messageHint: string | null = null;
    let notificationColor: string;
    $: notificationColor = getColorForNotificationType(type);

    function dismissNotification() {
        dispatch("dismiss");
    }
</script>
<div class="notification container-{type.toString().replaceAll('_', '-')}" in:fly="{{ x: -1000, duration: 400 }}"
     out:fade={{duration:1000}}
     style="--ntfy-width: {width}">
    <div>
        <div class="notification-stroke primary-{type}"></div>
        <div class="notification-icon-{type}">
            <div></div>
        </div>
        {#if messageHint}
            <div class="container-message-hint">
                <h6>{@html message}</h6>
                <p>{@html messageHint}</p>
            </div>
        {:else}
            <h6>{@html message}</h6>
        {/if}
    </div>
    {#if dismiss}
        <div class="dismiss-icon">
            <DismissNotificationIcon color={notificationColor} on:click={dismissNotification}/>
        </div>
    {/if}
</div>

<style lang="scss">
  @import "../../styles/global";

  .notification {
    width: var(--ntfy-width);
    @include flex-row($col-gap: 3rem, $justify: space-between);
    min-height: 68px;
    padding: 0 3rem 0 1rem;
    border-radius: 6px;
    max-width: 1110px;
    pointer-events: all;

    & > div:first-child {
      @include flex-row();

      & > span {
        overflow: hidden;
      }
    }
  }

  h6 {
    line-height: 1.25rem;
    margin: 0;
    padding: 0;
  }

  p {
    line-height: 1.25rem;
    margin: 0;
    padding: 0;
  }

  .container-message-hint {
    display: grid;
  }

  .container-error {
    background-color: $secondaryRed;

    & h6, p {
      color: $primaryRed;
    }
  }

  .container-info {
    background-color: $secondaryBlue;

    & h6, p {
      color: $primaryBlue;
    }
  }

  .container-info-inline {
    background-color: white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 0 1rem 0 0.75rem !important;

    & > div:first-child {
      position: relative;
      margin: 0.6rem 0 0.6rem 0.75rem;
      column-gap: 2rem;
    }

    & > div.dismiss-icon {
      align-self: flex-start;
      margin-top: 1rem;
      margin-left: 1.5rem;
    }

    & h6, p {
      color: #353535;
    }
  }

  .container-success {
    background-color: $primaryLightGreen;

    & h6, p {
      color: $primaryGreen;
    }

  }

  .primary-error {
    background-color: $primaryRed;
  }

  .primary-info {
    background-color: $primaryBlue;
  }

  .primary-info-inline {
    border-left: 4px solid #5888C0;
    position: absolute;
    height: 100% !important;
    align-self: stretch;
    margin-right: 2px;
    left: -1rem;
  }

  .primary-success {
    background-color: $primaryGreen;
  }

  .notification-stroke {
    height: 48px;
    width: 4px;
    border-radius: 6px;
  }

  .notification-icon-info, .notification-icon-error, .notification-icon-success {
    margin-left: 1.313rem;
    margin-right: 3.25rem;
    border-radius: 50%;
  }

  .notification-icon-info-inline, .notification-icon-info, .notification-icon-error, .notification-icon-success {
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    @include flex-col();

    & > div {
      min-width: 16px;
      max-width: 16px;
      min-height: 16px;
      max-height: 16px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .notification-icon-info {
    background-color: $primaryBlue;

    & > div {
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
      margin-bottom: 2px;
      background: url("@/icons/icon_info.svg") no-repeat center;
    }
  }

  .notification-icon-info-inline {
    align-self: flex-start;

    & > div {
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
      background: url("@/icons/icon_info_blue_outline.svg") no-repeat center;
      background-size: contain;
      margin-bottom: 2px;
    }
  }

  .notification-icon-error {
    background-color: $primaryRed;

    & > div {
      background-image: url("@/icons/icon_reaction_cancel.svg");
    }
  }

  .notification-icon-success {
    background-color: $primaryGreen;

    & > div {
      background-image: url("@/icons/icon_reaction_success.svg");
    }
  }

  span {
    text-align: left;
  }

  .notification-dismiss-error, .notification-dismiss-info, .notification-dismiss-info-inline, .notification-dismiss-success {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  @media (max-width: 576px) {
    .notification {
      column-gap: 0.5rem;
      padding: 0.5rem;
    }

    .notification-icon-info {
      margin-left: .5rem;
      margin-right: 1rem;
    }
  }

</style>