<script lang="ts">
    import Salutation from "../../atoms/Salutation.svelte";
    import InfoCard from "../../organisms/InfoCard.svelte";
    import ProjectInformationGrid from "../../project/ProjectInformationGrid.svelte";
    import TextArea from "../../atoms/TextArea.svelte";
    import {t} from "../../../i18n/i18n";
    import FileUpload from "../../organisms/FileUpload.svelte";
    import {NotificationType} from "../../types/notification";
    import {addNotification, dismissNotification, userInfo, userIsLoggedIn} from "../../stores";
    import type {Lot} from "../lot";
    import {fetchUtils} from "../../utils/fetchUtils";
    import PublicPageHeader from "../../molecules/PublicPageHeader.svelte";
    import PublicPageFooter from "../../molecules/PublicPageFooter.svelte";
    import AgbButton from "../../organisms/AgbButton.svelte";
    import {PrimaryBtnColors} from "../../atoms/PrimaryButton.svelte";
    import {createEventDispatcher, getContext, hasContext} from "svelte";
    import Basic from "../../templates/Basic.svelte";
    import {fetchRequestTenantLogo} from "../../corporateDesign/corporateDesignUtils";
    import {writable, type Writable} from "svelte/store";
    import type {ContactData} from "../../types/contactData";
    import {EmptyOffer, type Offer} from "../../bid/types/offer";
    import {RequestType} from "../../bid/types/requestDto";
    import {loadOfferAttachments} from "../../bid/offerUtils";
    import type {UserInfo} from "../../types/userInfo";
    import type {PubPageParams} from "../../bid/types/pubPageParams";

    const dispatch = createEventDispatcher();

    const lot: Writable<Lot> = getContext("lot");
    const urlParams: PubPageParams = getContext("urlParams");
    const {requestPublicId, requestedEntityId, requestedEntityType, offerUserPublicId} = urlParams;
    const offerMaterial: Writable<Offer> = hasContext("offerMaterial")
        ? getContext("offerMaterial") : writable(EmptyOffer(RequestType.MATERIAL));
    const offerMaterialWithTransport: Writable<Offer> = hasContext("offerMaterialWithTransport")
        ? getContext("offerMaterialWithTransport") : writable(EmptyOffer(RequestType.MATERIAL_WITH_TRANSPORT));

    export let optimizerContactData: ContactData;

    let notification = {
        id: $lot.matchedQuoteId,
        message: $t('UI.Rejected.successful'),
        type: NotificationType.SUCCESS,
        timeout: 0,
        dismissible: false
    };
    let notificationId: Symbol = addNotification(notification);

    let attachmentsUploadUrl: string;
    let attachmentsOffer: any[] = [];
    let currentUserInfo: UserInfo;
    let isDifferentTenant: boolean = false;
    let logo: string = "";


    const offerChanged = () => dispatch("offerChanged");

    async function reopen() {
        await fetchUtils.get(`/api/optimizer/public/${requestPublicId}/requested/${requestedEntityType}/${requestedEntityId}/user/${offerUserPublicId}/reopen`, false)
            .then(response => {

                dismissNotification(notificationId)
                offerChanged();

                let reopenedNotification = {
                    message: $t('UI.Reopened.successful'),
                    type: NotificationType.SUCCESS,
                    dismissible: true,
                    timeout: 6000
                };
                notificationId = addNotification(reopenedNotification);
            })
            .catch(fetchUtils.catchErrorAndShowNotification())
    }

    async function fetchLogo() {
        logo = await fetchRequestTenantLogo(requestPublicId) ?? "";
    }

    userInfo.subscribe(value => currentUserInfo = value);

    $: if ($offerMaterial.publicId || $offerMaterialWithTransport.publicId) {
        attachmentsUploadUrl = `/api/offer/${$offerMaterial?.publicId || $offerMaterialWithTransport?.publicId}/attachment/new`
        // at the moment, we only need to load attachments of one offer, since we sync them for both offers, will be adapted later
        loadOfferAttachments($offerMaterial?.publicId || $offerMaterialWithTransport?.publicId, urlParams)
            .then(data => {
                attachmentsOffer = data;
            });
    }

    $: if (currentUserInfo?.tenantName && $lot?.tenantName) {
        isDifferentTenant = currentUserInfo.tenantName !== $lot.tenantName;
    }

    $: if ($lot.isSaasInternalLot && requestPublicId) {
        fetchLogo();
    }
</script>

{#if !$userIsLoggedIn}
    <PublicPageHeader {requestPublicId}/>
{/if}

<Basic showStandardHeaderFooter={$userIsLoggedIn && !isDifferentTenant}>
    {#if $lot.isSaasInternalLot && !isDifferentTenant && $userIsLoggedIn && logo}
        <div class="pp-logo-top">
            <img alt="Logo" src={logo}/>
        </div>
    {/if}
    <Salutation name={optimizerContactData.fullName} address={$lot.address.zip + " " + $lot.address.city}
                saasOwnerName={$lot.ownerName} tenantName={$lot.tenantName} showSaasData/>
    <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12">
            <InfoCard/>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12">
            <div class="container-project-information">
                <ProjectInformationGrid title={$t('UI.project.information')} {...$lot}
                                        anonymizeAddress/>
                <TextArea title={$t("UI.materialDetailsComment.header")} text={$lot.lotRemarks}
                          placeholder={$t('UI.remarks.noRemarks')} readonly/>
                <TextArea title={$t("UI.projectDetailsComment.header")} text={$lot.projectRemarks}
                          placeholder={$t('UI.remarks.noRemarks')} readonly isHtml/>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 1.875rem">
        <div class="col-lg-12 col-sm-12">
            <FileUpload uploadUrl={attachmentsUploadUrl}
                        filePrefix={$t('UI.attachments.optimizer.prefix') + currentUserInfo.tenantName + '_' + currentUserInfo.userLogin + '-'}
                        visibleToCustomer={0} visibleToPartner={1}
                        updateFun={() => loadOfferAttachments($offerMaterial?.publicId ?? $offerMaterialWithTransport?.publicId, urlParams).then(data => attachmentsOffer = data)}
                        attachments={attachmentsOffer} readOnly/>
        </div>
    </div>
    <div class="row" style="margin-top: 1.875rem">
        <div class="col-lg-12 col-sm-12">
            <AgbButton color={PrimaryBtnColors.BLUE} label={$t('UI.rejectedOffer.undoButton')}
                       description={$t('UI.rejectedOffer.undoButton.description')} hideCheckbox
                       on:click={reopen}/>
        </div>
    </div>
</Basic>

{#if !$userIsLoggedIn}
    <PublicPageFooter/>
{/if}


<style lang="scss">
  @import "../../../styles/pubPage";

  .columns {
    @include flex-col(1.875rem, $alignment: flex-end);
    width: 100%;
  }
</style>
