<script lang="ts">

    import type {SvelteComponent} from "svelte";
    import DropdownMenuSection from "./DropdownMenuSection.svelte";


    // if the menu is active, it is opened
    export let active: boolean = false;

    // we can use the list of items and insert components the JS way,
    // but we can also use the slot and put the items the html way
    export let items: {
        section: string,
        entries: {
            component: SvelteComponent,
            data: any
        }[]
    }[] = [];


</script>


<div class="dropdown__menu" class:active>
    {#if $$slots.default}
        <slot/>
    {:else if items.length === 1}
        <DropdownMenuSection title={items[0].section}>
            {#each items[0].entries as item}
                <svelte:component this={item.component} {...item.data}/>
            {/each}
        </DropdownMenuSection>
    {:else if items.length > 1}
        {#each items as {section, entries}}
            <DropdownMenuSection title={section}>
                {#each entries as item}
                    <svelte:component this={item.component} {...item.data}/>
                {/each}
            </DropdownMenuSection>
        {/each}
    {/if}
</div>


<style lang="scss">
  @use "../../../styles/menubar";

</style>
