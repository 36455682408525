<script lang="ts">
    import "@/js/materialPropertiesModal.js";
    import "@/map/new/multiselect.js";
    import "@/js/tokenfield.js";
    import "@/js/autocomplete.js";
    import {onMount, tick} from "svelte";
    import {t} from "../../../../i18n/i18n";
    import {currentLot} from "../utils/fakStores";
    import {updateMaterialPropertyComments, validateAndSaveLot} from "../../types/lot";
    import mmLogoAnimation from "@/animations/MmLogo.gif";

    let mPropertiesModal = null;

    export async function init() {
        await tick();
        try {
            mPropertiesModal = new MaterialPropertiesModal("/api/lot/" + $currentLot.id, !$currentLot.lotViewFieldsChangeMap.canEditLot, $currentLot.material.id, $currentLot.material.text);
            mPropertiesModal.resetContent();
            await mPropertiesModal.init();
        } catch (e) {
            console.warn("Could not initialize properties modal. Trying again in 5s...");
            setTimeout(init, 5000);
        }
    }

    addEventListener("materialPropertiesSave", async e => {
        if ($currentLot && $currentLot.lotViewFieldsChangeMap.canEditLot && $currentLot.material.id === e.detail.materialId) {
            $currentLot.materialProperties = JSON.stringify(e.detail);
            await updateMaterialPropertyComments();
            if ($currentLot.id !== "new") {
                await validateAndSaveLot();
            }
        }
        close();
    });

    function close() {
        dispatchEvent(new CustomEvent("closeMaterialProperties"));
    }

    onMount(() => {
        init();
    });

</script>

<div class="fak-material-properties-modal" id="materialPropertiesModal"
     style="transform: translateY(-100%); overflow: scroll;">
    <!-- For some reason whatsoever, we have to include the translateY to see the modal -->
    <div class="mp-container container" role="document">
        <div class="modal-header" style="display: block">
            <h4 id="mp-header-text" class="modal-title"></h4>
            <span>Definieren und bearbeiten Sie hier die Materialeigenschaften des ausgewählten Materials anhand folgender Felder.</span>
        </div>
        <img id="mp-loading-animation" alt="LoadingAnimation" src={mmLogoAnimation}/>

        <div id="mp-content" class="container" style="margin-left: -15px;">

        </div>

        <div class="form-actions">
            <a id="mp-btn-save" class="btn btn-primary btn-elevated"
               on:click={() => mPropertiesModal?.checkPropsAndDispatchEvent()}>
                <i class="fa fa-check"></i>
                {$t("UI.button.done")}
            </a>
            <a on:click={close} class="btn btn-secondary btn-elevated">{$t("NLS.cancel")}</a>
        </div>
    </div>
</div>
<input id="material-properties-input" type="hidden" name="materialProperties"
       bind:value={$currentLot.materialProperties}/>

<style lang="scss">

  .fak-material-properties-modal {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Chrome, Safari, Opera */
  .fak-material-properties-modal::-webkit-scrollbar {
    display: none;
  }
</style>