<script lang="ts">
    // import mapStyles from './map-styles'; // optional


    export let container: HTMLElement;

</script>


<div class="full-screen" bind:this={container}></div>
<slot/>


<style lang="scss">
  .full-screen {
    width: 100%;
    height: 100%;

    & :global(.gm-style > iframe + div) {
        border: none !important;
    }
  }

</style>
