<script lang="ts">
    import Checkbox from "../atoms/Checkbox.svelte";
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import {createEventDispatcher} from 'svelte';


    export let agbAccepted: boolean = false;
    export let label: string = "";
    export let description: string = "";
    export let color: PrimaryBtnColors = PrimaryBtnColors.GREEN;
    export let hideCheckbox: boolean = false;
    export let disabled: boolean = false;
    export let disabledCheckbox: boolean = false;

    $: agbAccepted = hideCheckbox === true;

    $: if (!hideCheckbox && disabledCheckbox) {
        agbAccepted = false;
    }

</script>


<div class="final-button-container"
     class:container-red={color === PrimaryBtnColors.RED}
     class:container-green={color === PrimaryBtnColors.GREEN}
     class:container-orange={color === PrimaryBtnColors.ORANGE}
     class:container-blue={color === PrimaryBtnColors.BLUE}>
    <div>
        {#if !hideCheckbox}
            <div class="agb-accept-container">
                <Checkbox bind:checked={agbAccepted} id="agb-checkbox" on:checkboxChange disabled={disabledCheckbox}/>
                <span>&nbsp;&nbsp;<b><a href="/agb" style="color: black ">AGB</a> </b> akzeptieren</span>
            </div>
        {/if}
        <div>
            <PrimaryButton {label} {color} disabled={!agbAccepted || disabled || (agbAccepted && disabledCheckbox )}
                           fullWidth on:click/>
        </div>
        <span class="final-button-description">{@html description}</span>
    </div>
</div>


<style lang="scss">
  @import "../../styles/global";


  .span {
    @include roboto-font(30px, 400, 0.875rem, black);
    text-align: left;
    text-decoration: none;
  }

  .final-button-container {
    min-height: 259px;
    border-radius: 4px;
    @include flex-col();
    padding-top: 3.375rem;
    padding-bottom: 3.375rem;
    margin-bottom: 1.125rem;

    & > div {
      @include flex-col();
      width: 40%;
      min-width: min-content;

      @media screen and (max-width: 768px) {
        width: 75%;
      }

      & > div {
        min-width: fit-content;

        &:first-child {
          align-self: flex-start;
        }

        &:last-of-type {
          width: 100%;
          margin: 0.375rem 0 0.625rem;
        }
      }
    }
  }

  .agb-accept-container {
    flex-direction: row;
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: center;
  }

  .final-button-description {
    @include roboto-font(20px, 400, 0.875rem, black);
    text-align: center;
  }

  .container-green {
    background-color: $primaryGreenBackground;
  }

  .container-red {
    background-color: $primaryRedBackground;
  }

  .container-orange {
    background-color: $primaryOrangeBackground;
  }

  .container-blue {
    background-color: $secondaryBlue;
  }

</style>
