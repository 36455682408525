<script lang="ts">
    import Icon from "../../atoms/Icon.svelte";
    import {getContext} from "svelte";
    import {createTippy} from "svelte-tippy";
    import type {Writable} from "svelte/store";
    import expandableIcon from "@/icons/icon_expandable_closed.svg";

    export let label = "";
    export let id = null;
    export let onIconClick = () => {
    };
    export let showAddIcon = false;
    export let addIconTTMessage = "";
    export let addButtonDisabled = false;

    let itemContext: Writable<any> = getContext("itemContext");
    let isOpen = false;

    $: if ($itemContext !== undefined) {
        isOpen = $itemContext.isOpen;
    }

    let tippy = createTippy({
        interactive: true,
        placement: 'left',
        maxWidth: 182,
        theme: 'mmttip',
        content: addIconTTMessage,
        hideOnClick: false,
        delay: [0, 2000]
    });

</script>

<span {id} class="add-item-header-label">{label}</span>
<div style="padding-right: 0.875rem; padding-top: 0.8125rem; float: right;">
    {#if showAddIcon && addIconTTMessage !== ""}
        <div class="green-icon-36 {isOpen || addButtonDisabled ? 'icon-disabled' : ''}" on:mousedown={onIconClick}
             use:tippy>
            <div class="add-icon-24"></div>
        </div>
    {:else if showAddIcon}
        <div class="green-icon-36 {isOpen || addButtonDisabled ? 'icon-disabled' : ''}" on:mousedown={onIconClick}>
            <div class="add-icon-24"></div>
        </div>
    {:else}
        <div style="transform: rotate({isOpen ? '90deg' : '0'}); transition: transform .4s ease-out;">
            <Icon src={expandableIcon} size={32} clickable/>
        </div>
    {/if}
</div>

<style lang="scss">
  @import "../../../styles/global";
  @import "../../../styles/icons";

  .add-item-header-label {
    padding-left: 1.5625rem;
    padding-top: 1.5rem;
    float: left;
    @include roboto-font(19px, 400);
  }

  .green-icon-36 {
    background-color: $primaryGreen;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    @include flex-col();
    cursor: pointer;

    &:hover {
      background-color: $secondaryGreen;
    }
  }

  .add-icon-24 {
    @extend .icon-24;
    @extend .icon-info;
    background-image: url("@/icons/icon_add_autocomplete_entry.svg");
  }

  .icon-disabled {
    background-color: $grey-700;
    cursor: not-allowed;
  }
</style>
