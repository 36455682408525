export function areAllFilledPropertiesEqual(a, b) {
    let biggerObj = Object.keys(a).length >= Object.keys(b).length ? a : b;
    let smallerObj = Object.keys(a).length >= Object.keys(b).length ? b : a;
    let testedKeys = [];
    for (const [key, value] of Object.entries(biggerObj)) {
        testedKeys.push(key);
        if (key in smallerObj) {
            // if the values are not equal
            if (value !== smallerObj[key]) {
                return false;
            }
        } else {
            // there is a specified value that is not in the other object
            if (value !== "" && value !== null && value !== undefined) {
                return false;
            }
        }
    }
    // there could still be a key in the smallerObj that we did not check yet and that could be not empty;
    return !Object.keys(smallerObj).some(k => !testedKeys.includes(k) && smallerObj[k] !== "" && smallerObj[k] !== null && smallerObj[k] !== undefined);
}

export function hashString(str: String) {
    // https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
    let hash = 0, i, chr;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
}

// https://dmitripavlutin.com/how-to-compare-objects-in-javascript/#4-deep-equality
export function deepEqual(object1, object2, ignoreProperties: Set<String> = new Set()) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        if (!ignoreProperties.has(key)) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = isObject(val1) && isObject(val2);
            if (areObjects && !deepEqual(val1, val2) ||
                !areObjects && val1 !== val2) {
                return false;
            }
        }
    }
    return true;
}
function isObject(object) {
    return object != null && typeof object === 'object';
}

export function debounce(func, timeout = 350) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    }
}
