<script lang="ts">

    import {t} from "../../i18n/i18n";
    import {type Notification, NotificationType} from "../types/notification";
    import {addNotification, dismissNotification} from "../stores";
    import AgbButton from "../organisms/AgbButton.svelte";
    import {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import {createEventDispatcher, getContext} from "svelte";
    import type {ContactData} from "../types/contactData";
    import OptimizerPagesTemplate from "./OptimizerPagesTemplate.svelte";
    import type {PubPageParams} from "../bid/types/pubPageParams";

    const dispatch = createEventDispatcher();
    const urlParams: PubPageParams = getContext("urlParams");
    const {requestPublicId, requestedEntityId, requestedEntityType, offerUserPublicId} = urlParams;

    export let optimizerContactData: ContactData;

    let notification: Partial<Notification> = {
        message: $t('UI.Rejected.successful'),
        type: NotificationType.SUCCESS,
        timeout: 0,
        dismissible: false
    };
    let notificationId = addNotification(notification);


    const offerChanged = () => dispatch("offerChanged");

    async function reopen() {
        await fetchUtils.get(`/api/optimizer/${requestPublicId}/requested/${requestedEntityType}/${requestedEntityId}/user/${offerUserPublicId}/reopen`)
            .then(() => {
                dismissNotification(notificationId);
                offerChanged();

                let reopenedNotification = {
                    message: $t('UI.Reopened.successful'),
                    type: NotificationType.SUCCESS,
                    dismissible: true,
                    timeout: 6000
                };
                notificationId = addNotification(reopenedNotification);
            })
            .catch(fetchUtils.catchErrorAndShowNotification())

    }

</script>

<OptimizerPagesTemplate recipientData={optimizerContactData} attachmentsReadonly>
    <div class="row" style="margin-top: 1.875rem">
        <div class="col-lg-12 col-sm-12">
            <AgbButton color={PrimaryBtnColors.BLUE} label={$t('UI.rejectedOffer.undoButton')}
                       description={$t('UI.rejectedOffer.undoButton.description')} hideCheckbox
                       on:click={reopen}/>
        </div>
    </div>
</OptimizerPagesTemplate>
