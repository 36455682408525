<script lang="ts">
    // taken from https://svelte.dev/repl/75d34e46cbe64bb68b7c2ac2c61931ce?version=4.2.2
    import {clamp} from '../utils/misc';

    let start = 0;
    let end = 1;

    export let left = 0;
    export let right = 50;
    export let max = 100

    $: updateLeft(start, max);
    $: updateRight(end, max);
    $: updateStart(left, max);
    $: updateEnd(right, max);

    function updateStart(l, m) {
        start = l / m;
    }

    function updateEnd(r, m) {
        end = r / m;
    }

    function updateLeft(s, m) {
        left = Math.round(s * m);
    }

    function updateRight(e, m) {
        right = Math.round(e * m);
    }


    let leftHandle;
    let body;
    let slider;

    function draggable(node) {
        let x;
        let y;

        function handleMousedown(event) {
            if (event.type === 'touchstart') {
                event = event.touches[0];
            }
            x = event.clientX;
            y = event.clientY;
            node.dispatchEvent(new CustomEvent('dragstart', {
                detail: {x, y}
            }));
            window.addEventListener('mousemove', handleMousemove);
            window.addEventListener('mouseup', handleMouseup);
            window.addEventListener('touchmove', handleMousemove);
            window.addEventListener('touchend', handleMouseup);
        }

        function handleMousemove(event) {
            if (event.type === 'touchmove') {
                event = event.changedTouches[0];
            }
            const dx = event.clientX - x;
            const dy = event.clientY - y;
            x = event.clientX;
            y = event.clientY;
            node.dispatchEvent(new CustomEvent('dragmove', {
                detail: {x, y, dx, dy}
            }));
        }

        function handleMouseup(event) {
            x = event.clientX;
            y = event.clientY;
            node.dispatchEvent(new CustomEvent('dragend', {
                detail: {x, y}
            }));
            window.removeEventListener('mousemove', handleMousemove);
            window.removeEventListener('mouseup', handleMouseup);
            window.removeEventListener('touchmove', handleMousemove);
            window.removeEventListener('touchend', handleMouseup);
        }

        node.addEventListener('mousedown', handleMousedown);
        node.addEventListener('touchstart', handleMousedown);
        return {
            destroy() {
                node.removeEventListener('mousedown', handleMousedown);
                node.removeEventListener('touchstart', handleMousedown);
            }
        };
    }

    function setHandlePosition(which) {
        return function (evt) {
            const {left, right} = slider.getBoundingClientRect();
            const parentWidth = right - left;
            const p = Math.min(Math.max((evt.detail.x - left) / parentWidth, 0), 1);
            if (which === 'start') {
                start = p;
                end = Math.max(end, p);
            } else {
                start = Math.min(p, start);
                end = p;
            }
        }
    }

    function setHandlesFromBody(evt) {
        const {width} = body.getBoundingClientRect();
        const {left, right} = slider.getBoundingClientRect();
        const parentWidth = right - left;
        const leftHandleLeft = leftHandle.getBoundingClientRect().left;
        const pxStart = clamp((leftHandleLeft + event.detail.dx) - left, 0, parentWidth - width);
        const pxEnd = clamp(pxStart + width, width, parentWidth);
        const pStart = pxStart / parentWidth;
        const pEnd = pxEnd / parentWidth;
        start = pStart;
        end = pEnd;
    }
</script>

<div class="double-range-container">
    <div class="double-range-slider" bind:this={slider}>
        <div
                class="double-range-body"
                bind:this={body}
                use:draggable
                on:dragmove|preventDefault|stopPropagation="{setHandlesFromBody}"
                style="
				left: {100 * start}%;
				right: {100 * (1 - end)}%;
			"
        ></div>
        <div
                class="double-range-handle left"
                bind:this={leftHandle}
                data-which="start"
                use:draggable
                on:dragmove|preventDefault|stopPropagation="{setHandlePosition('start')}"
                style="
				left: {100 * start}%
			"
        ></div>
        <div
                class="double-range-handle right"
                data-which="end"
                use:draggable
                on:dragmove|preventDefault|stopPropagation="{setHandlePosition('end')}"
                style="
				left: {100 * end}%
			"
        ></div>
    </div>
</div>

<style lang="scss">
  @import "../../styles/colors";
  @import "../../styles/global";

  .double-range-container {
    width: 100%;
    height: 20px;
    user-select: none;
    box-sizing: border-box;
    white-space: nowrap
  }

  .double-range-slider {
    position: relative;
    width: 100%;
    height: 4px;
    top: 50%;
    transform: translate(0, -50%);
    background-color: $grey-600;
    border-radius: 2px;
  }

  .double-range-handle {
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
  }

  .double-range-handle:after {
    content: ' ';
    box-sizing: border-box;
    position: absolute;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: white;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    transform: translate(-50%, -50%)
  }

  .double-range-handle.left:after {
    background-image: url("@/icons/icon_left_primary.svg");
    background-position-x: 40%;
    background-position-y: center;
  }

  .double-range-handle.right:after {
    background-image: url("@/icons/icon_right_primary.svg");
    background-position-x: 60%;
    background-position-y: center;
  }

  /* .handle[data-which="end"]:after{
      transform: translate(-100%, -50%);
  } */
  .double-range-handle:active:after {
    background-color: #ddd;
    z-index: 9;
  }

  .double-range-body {
    top: 0;
    position: absolute;
    background-color: $primaryGreen;
    bottom: 0;
  }
</style>