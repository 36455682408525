<script lang="ts">

    import {t} from "../../i18n/i18n";
    import {inputFieldFormats} from "../utils/formatters";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import InputField from "../molecules/InputField.svelte";
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import {DefaultRobotoFontProps, type FontProps} from "../types/fontProps";
    import type {Material} from "./types/material";
    import {type Declares, SubstituteMaterialMapping, type SubstituteScriptImportData} from "./types/substitutes";
    import {AutocompleteConfig, type AutocompleteFieldConfig} from "../types/autocompleteFieldConfig";
    import {type MaterialProperty} from "./types/materialProperty";
    import type {Writable} from "svelte/store";
    import {createEventDispatcher, getContext, onMount} from "svelte";
    import {
        concatProperty,
        getStringNameOfPropertyType,
        getSuffix,
        replaceSpecialCharacters
    } from "./util/materialSubstituteUtil";

    const LOT_FORM_ERROR_PATH_PREFIX = 'lot.';
    const fontProps: FontProps = DefaultRobotoFontProps('0.875rem', '0.75rem');
    const dispatch = createEventDispatcher();

    export let materialReference: Material;
    export let materialCandidate: Material;
    export let data: SubstituteScriptImportData;

    let materialSubstituteConfig: AutocompleteFieldConfig = AutocompleteConfig('id', 'translation');
    materialSubstituteConfig.disableSifter = false;
    let materialPropertyConfig: AutocompleteFieldConfig = AutocompleteConfig('concattedId', 'deepName');
    materialPropertyConfig.disableSifter = false;

    let allProperties: Writable<Map<string, MaterialProperty>> = getContext('materialProperties');
    let allMappedProperties: MaterialProperty[] = [];
    let materialPropertyOptions: MaterialProperty[] = [];
    let declares: Declares[] | undefined;
    let substitutePropertyId: string | undefined;
    /**
     * we save the concatenated property id in the autocomplete component, an example value is like this:
     * plQIMLQzo97JDdvmEVzsnv8s3WA.YZFNmSXFJEa93UvgsZ6QODX0hwE.NcE9pYEMr1BDyJlK4O5yEt0G-dE.wQCOuUq8J-FXEOf2_qdjyAnHT_g
     * A property can exist several times, depending on which parent properties are attached to the material.
     * The properties are therefore concatenated with the ids of the parent properties
     */
    let concatenateSubstitutePropertyId: string | undefined;

    let materialSubstituteOptions: {
        translation: string;
        id: string
    }[] = Object.keys(SubstituteMaterialMapping).map(mapping => {
            return {
                id: mapping,
                translation: SubstituteMaterialMapping.REFERENCE_MATERIAL.toString() === mapping ? 'Reference' : 'Candidate'
            }
        }
    );

    onMount(() => {
        if (!materialReference) {
            data.materialType = SubstituteMaterialMapping.CANDIDATE_MATERIAL;
        }
        handleMaterialSubstituteChanged();
    })

    /**
     * Removes an import card based on the provided identifier.
     *
     * Triggers the 'removeImport' action of MaterialSubstituteModal with the necessary identifier data.
     * @see {@link handleRemoveImport}
     */
    function removeImport() {
        dispatch('removeImport', {
            identifier: data.identifier
        });
    }

    /**
     * Handles the event when a material substitute property is changed. This method
     * resets the `concatenateSubstitutePropertyId`, updates the material property options,
     * and clears the `defaultPropertyName` and `ownPropertyName` from the `data` object.
     */
    function handleMaterialSubstituteChanged() {
        concatenateSubstitutePropertyId = undefined;
        updateMaterialPropertyOptions();
        data.defaultPropertyName = undefined;
        data.ownPropertyName = undefined;
    }

    /**
     * Updates the materialPropertyOptions Autocomplete component based on the selected material type.
     * Filters properties depending on whether the material type is REFERENCE_MATERIAL or Candidate_MATERIAL.
     * Uses structuredClone to create a deep copy of the filtered properties.
     * Calls the flatProperties function after updating materialPropertyOptions.
     */
    function updateMaterialPropertyOptions() {
        materialPropertyOptions = []
        if (data.materialType === SubstituteMaterialMapping.REFERENCE_MATERIAL) {
            materialPropertyOptions = structuredClone(data.properties.filter(prop => materialReference?.propertyIds.some(id => id === prop.id)));
        } else {
            materialPropertyOptions = structuredClone(data.properties.filter(prop => materialCandidate?.propertyIds.some(id => id === prop.id)));
        }
        flatProperties();
    }

    /**
     * Handles the event when a substitute property changes. It updates the default property name and set the own
     * property name as default. The default property name is used as fallback if the own property name is empty (user input).
     * The new property names are concatenated with a suffix (_REF or _CAN and a unique digit number) and special
     * characters are replaced.
     */
    function onSubstitutePropertyChanged() {
        declares = [];
        if (concatenateSubstitutePropertyId) {
            substitutePropertyId = allMappedProperties.find(prop => prop.concattedId === concatenateSubstitutePropertyId)?.id;
            data.defaultPropertyName = allMappedProperties.find(prop => prop.id === substitutePropertyId)?.displayName + getSuffix(data.materialType);
            data.defaultPropertyName = replaceSpecialCharacters(data.defaultPropertyName)
            data.ownPropertyName = data.defaultPropertyName;
        } else {
            data.ownPropertyName = undefined;
            data.defaultPropertyName = undefined;
        }
    }

    /**
     * Flattens the nested properties within the materialPropertyOptions array by
     * concatenating them into a single list of properties and assigns the result
     * back to materialPropertyOptions.
     * As an example, we have a nested property that looks like this:
     * Fremdbestandteile | Ja | Fremdbestandteile Anteil in % | &lt;5% | Beschreibung Fremdbestandteile &lt;5%
     */
    function flatProperties() {
        allMappedProperties = [];
        materialPropertyOptions.forEach(materialProperty => {
            concatProperty(materialProperty, allMappedProperties, $allProperties)
        });
        materialPropertyOptions = structuredClone(allMappedProperties);
    }

    /**
     * Populates the declares array with objects representing property list elements.
     * Each object contains an id, a defaultName, and an ownName.
     * default name is used as fallback if ownName (user input) is empty
     */
    function addDeclares() {
        $allProperties.get(substitutePropertyId)?.propertyListElements.forEach(listElement => {
            declares?.push({
                id: listElement.id,
                defaultName: "Eigenschaftswert_" + replaceSpecialCharacters(listElement.name),
                ownName: "Eigenschaftswert_" + replaceSpecialCharacters(listElement.name)
            })
        })
        declares = declares;
    }

    function handleSubstitutePropertyChange() {
        declares = [];
    }

    /**
     * Constructs a property script by determining the material type and property details, then formatting it properly
     * according to the data and declarations provided. Updates the script text in the `data.text` property.
     * The script text of this property is used in parent component
     * @see {@link buildScriptText}
     */
    function buildPropertyScript() {

        let materialType;

        if (data.materialType === SubstituteMaterialMapping.REFERENCE_MATERIAL) {
            materialType = $t('UI.mmpv.modal.substitute.import.reference.material');
        } else {
            materialType = $t('UI.mmpv.modal.substitute.import.candidate.material');
        }

        let propertyName = data.ownPropertyName ?? replaceSpecialCharacters(data.defaultPropertyName);
        let propertyTypeName = getStringNameOfPropertyType($allProperties.get(substitutePropertyId)?.type)

        // build script text of material property
        data.text = `import ${concatenateSubstitutePropertyId} from ${materialType} as ${propertyTypeName} ${propertyName};\n`;

        // build script text of list elements
        if (declares?.length && declares?.length > 0) {
            declares.forEach(declare => {
                const declareName = declare.ownName ? declare.ownName : replaceSpecialCharacters(declare.defaultName);
                const declareText = `declare ${declare.id} as ${declareName};\n`
                data.text = data.text.concat(declareText);
            });

        } else {
            data.text.concat("\n")
        }
    }

    /**
     * Handles the text change event for a property input element.
     * The event containing the user input string of the property name.
     * If the user clears all characters the propertyName is set to fallback defaultPropertyName.
     */
    function handlePropertyTextChange(e: Event) {
        const target = e.target as HTMLInputElement;
        const namedProp = replaceSpecialCharacters(target.value);
        if (!namedProp || namedProp === '') {
            data.ownPropertyName = data.defaultPropertyName;
        } else if (namedProp) {
            data.ownPropertyName = namedProp;
        }
        data = data;
    }

    /**
     * Handles the text change event for a list property input element.
     * The event containing the user input string of the property name.
     * If the user clears all characters the propertyName is set to fallback defaultPropertyName.
     */
    function handlePropertyListElementTextChange(e: Event, declareObj: Declares) {
        const target = e.target as HTMLInputElement;
        const namedPropListEle = replaceSpecialCharacters(target.value);
        if (!namedPropListEle || namedPropListEle === '') {
            declareObj.ownName = declareObj.defaultName;
        } else if (namedPropListEle) {
            declareObj.ownName = namedPropListEle;
        }
        declares = declares;
    }

    $: concatenateSubstitutePropertyId, handleSubstitutePropertyChange();
    $: data, declares, buildPropertyScript();
    $: enableDeclares = concatenateSubstitutePropertyId && $allProperties.get(substitutePropertyId)?.propertyListElements.length > 0 && declares?.length === 0;
    $: materialCandidate, materialReference, updateMaterialPropertyOptions();

</script>

<div class="material-substitute-import">
    <span>{$t('UI.mmpv.modal.substitute.import.header')}</span>
    <div>
        <AutocompleteInputField bind:value={data.materialType} autocompleteConfig={materialSubstituteConfig}
                                label={$t('UI.material.autocomplete.ph')} options={materialSubstituteOptions}
                                errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'material'}
                                {fontProps} required fullWidth on:change={handleMaterialSubstituteChanged}/>
        <AutocompleteInputField bind:value={concatenateSubstitutePropertyId} autocompleteConfig={materialPropertyConfig}
                                label={$t('UI.material.property.autocomplete.ph')} options={materialPropertyOptions}
                                errorPath={LOT_FORM_ERROR_PATH_PREFIX + 'material'} {fontProps} required fullWidth
                                on:change={onSubstitutePropertyChanged}/>
    </div>
    <InputField bind:value={data.ownPropertyName} placeholder={$t('UI.mmpv.modal.substitute.import.property.naming.ph')}
                format={inputFieldFormats.FULL} on:change={handlePropertyTextChange}/>
    {#if declares?.length && declares.length > 0 && concatenateSubstitutePropertyId}
        <div class="declares">
            <span>{$t('UI.mmpv.modal.substitute.declare.header')}</span>
            {#each declares as declare (declare.id)}
                <div>
                    <div>
                        <InputField bind:value={declare.defaultName} format={inputFieldFormats.FULL} readOnly/>
                        <InputField bind:value={declare.ownName} format={inputFieldFormats.FULL}
                                    placeholder={$t('UI.mmpv.modal.substitute.import.declare.naming.ph')}
                                    on:change={(e)=> handlePropertyListElementTextChange(e, declare)}/>
                    </div>
                </div>
            {/each}
        </div>
    {/if}
    <div class="import-and-declare-buttons">
        <SecondaryButton color={SecondaryBtnColors.RED} label={$t('UI.mmpv.modal.substitute.button.addImport')}
                         sizeAdaptingToText on:click={removeImport}/>
        <PrimaryButton color={PrimaryBtnColors.GREEN} label={$t('UI.mmpv.modal.substitute.button.addDeclare')}
                       disabled={!enableDeclares} sizeAdaptingToText on:click={addDeclares}/>
    </div>
</div>

<style lang="scss">
  @import "../../styles/modals"; // also includes global.scss

  div.material-substitute-import {
    padding: 1rem 1rem 0 1rem;

    & > div {
      @include flex-col($row-gap: 0.875rem, $alignment: normal);
      padding-top: 0.6rem;
      padding-bottom: 1rem;
    }

    & div:last-child {
      @include flex-row($col-gap: 1rem, $alignment: normal);
    }

    div.import-and-declare-buttons {
      justify-content: flex-end;
    }

    & > div.declares {
      @include flex-col($alignment: normal);

      & > div {
        border: 1px solid #D9D9D9;
        margin-top: .7rem;
        padding-bottom: .7rem;
      }

      & > div > div {
        @include flex-row($alignment: normal, $col-gap: 1rem);
        padding-top: .5rem;
        width: 99%;
        padding-left: 1%;
      }

      & > span {
        @include roboto-font(.625rem, 500, .75rem, #000);
      }
    }

    & > span:first-child {
      @include roboto-font(.625rem, 500, .75rem, #000);
    }

    :global(.sv-dd-item > .sv-item) {
      overflow-x: auto !important;
      scrollbar-width: none !important;
    }

    :global(.sv-dd-item > .sv-item::-webkit-scrollbar) {
      display: none; /* for Chrome, Safari and Opera */
    }

    :global(.sv-item-content ) {
      overflow: visible !important;
    }
  }

</style>