<div id="autosave-info-chip" class="fak-autosave-state">
    <span id="autosave-info-text" class="fak-autosave-text"></span>
    <div id="autosave-info-loader" class="fak-circle-loader fak-load-complete">
        <div id="autosave-info-checkmark" class="fak-checkmark draw"></div>
    </div>
    <div id="autosave-info-crossmark">
        <svg class="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="crossmark_circle" cx="26" cy="26" r="25" fill="none"/>
            <path class="crossmark_check" fill="none" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"/>
        </svg>
    </div>
</div>

<style lang="scss">
  @import "../../styles/global";

  .fak-autosave-state {
    position: absolute;
    background: #fff;
    opacity: 0;
    transition-property: opacity;
    transition-timing-function: ease-out;
    transition-duration: 0.5s;
    bottom: 1.25rem;
    right: 1.25rem;
    height: 3rem;
    display: none;
    align-items: center;
    border-radius: 1.5rem;
    z-index: 1001;

    &:hover {
      opacity: 1 !important;
    }
  }

  .fak-autosave-text {
    @include roboto-font(0.75rem, 400, 0.875rem, black);
    padding-left: 0.75rem;
    padding-right: 0.5rem;
    white-space: nowrap;
    user-select: none;
    margin-top: 0.125rem;
  }

  $brand-success: $primaryGreen;
  $loader-size: 2rem;
  $check-height: calc($loader-size / 2);
  $check-width: calc($check-height / 2);
  $check-left: calc(($loader-size / 8 + $loader-size / 16));
  $check-thickness: 3px;
  $check-color: $brand-success;

  // https://codepen.io/scottloway/pen/zqoLyQ
  .fak-circle-loader {
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-left-color: $check-color;
    animation: fak-loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
    margin-right: .5rem;
  }

  .fak-load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: $check-color;
    transition: border 500ms ease-out;
  }

  .fak-checkmark {
    display: none;

    &.draw:after {
      animation-duration: 800ms;
      animation-timing-function: ease;
      animation-name: fak-checkmark;
      transform: scaleX(-1) rotate(135deg);
    }

    &:after {
      opacity: 1;
      height: $check-height;
      width: $check-width;
      transform-origin: left top;
      border-right: $check-thickness solid $check-color;
      border-top: $check-thickness solid $check-color;
      content: '';
      left: $check-left;
      top: $check-height;
      position: absolute;
    }
  }

  @keyframes fak-loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes fak-checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: $check-width;
      opacity: 1;
    }
    40% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
    100% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
  }

  // Crossmark https://bbbootstrap.com/snippets/animated-checkmark-cross-icon-51064654
  .crossmark {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    box-shadow: inset 0 0 0 $primaryRed;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    margin-right: 0.5rem;
  }

  .crossmark_circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: $primaryRed;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .crossmark_check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0 0 0 30px $primaryRed;
    }
  }

</style>