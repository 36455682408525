<script lang="ts">

    export let text: string;
    export let align: 'start' | 'end' | 'center' = "start";
</script>

<div style="position: relative" class="cell-align-{align}">
    <span>{@html text}</span>
</div>

<style lang="scss">
  @use "../../styles/headlessTable";
</style>
