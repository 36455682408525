<script lang="ts">
    /* This works like the Notifications component: To be able to show any modal, we need this component in the page somewhere,
    as it renders the modals with the given store
    https://svelte-modals.mattjennings.io/api*/

    import {closeModal, modals, Modals} from "svelte-modals";
    import {closeModalOnOutsideClick} from "../stores";

    // Prevent scrolling content behind modals.
    $: if ($modals.length > 0) {
        document.body.style.overflowY = "hidden";
    } else {
        document.body.style.overflowY = "initial";
    }
</script>

<Modals>
    <div slot="backdrop"
         class="backdrop"
         on:click={() => {if ($closeModalOnOutsideClick) closeModal()}}></div>
</Modals>


<style lang="scss">
    @keyframes fadeBackdrop {
    from {
      background: rgba(0, 0, 0, 0);
    }
    to {
      background: rgba(0, 0, 0, 0.50);
    }
  }

    .backdrop {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      animation: .6s ease-out 0s 1 fadeBackdrop;
      animation-fill-mode: forwards;
      z-index: 1000; /* important, as otherwise there are maybe crazy things going on in the ui -> some components are not blurred/darkened by the filter */
    }
</style>
