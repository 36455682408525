<script lang="ts">
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import InputField from "../molecules/InputField.svelte";
    import PriceTableCell from "../atoms/PriceTableCell.svelte";
    import VolumeTableCell from "../atoms/VolumeTableCell.svelte";
    import type {Lot} from "../types/lot";
    import {checkNumber, inputFieldFormats} from "../utils/formatters";
    import {fetchUtils} from "../utils/fetchUtils";
    import {t} from "../../i18n/i18n";
    import {NotificationType} from "../types/notification";
    import {addNotification} from "../stores";
    import type {Writable} from "svelte/store";
    import {getContext} from "svelte";
    import type {Deal} from "../bid/types/deal";
    import type {PubPageParams} from "../bid/types/pubPageParams";
    import type {Facts} from "../bid/types/facts";
    import type {DealActualValues} from "../types/dealActualValues";
    import type {ActualPosition} from "../types/actualPosition";

    const lot: Writable<Lot> = getContext("lot");
    const deal: Writable<Deal> = getContext("deal");
    const urlParams: PubPageParams = getContext("urlParams");
    const zeroPriceFormat = '0,00';

    export let readOnly = false;
    export let isOptimizer = false;

    let lotPublicId: string | undefined = urlParams?.lotPublicId;
    let targetFacts: Facts;
    let actualFacts: DealActualValues;
    let targetSum: number, actualSum: number, difference: number;

    function updatePosition() {
        let url;
        if (isOptimizer) {
            url = `/api/optimizer/public/deal/${$deal.publicId}/updateActualPosition`;
        } else {
            url = `/api/offerer/lot/${lotPublicId ?? $lot.publicId}/updateActualPosition`;
        }
        const payload: ActualPosition = {
            volume: actualFacts?.totalFacts?.volume,
            grossPrice: actualFacts?.totalFacts?.price,
            materialPrice: actualFacts?.materialPrice.number,
            transportCost: actualFacts?.transportPrice.number
        }
        fetchUtils.post(url, payload).then(() => {
            let notification = {
                message: $t('UI.notification.saved'),
                type: NotificationType.SUCCESS,
                timeout: 4000,
                dismissible: false
            };
            addNotification(notification)
            updateSumAndDifference();
        })
            .catch(error => {
                let notification = {
                    message: $t("UI.error.save"),
                    type: NotificationType.ERROR,
                    timeout: 0,
                    dismissible: true
                };
                addNotification(notification);
                notification = {message: error.message, type: NotificationType.ERROR, timeout: 0, dismissible: true};
                addNotification(notification);
            });
    }


    function updateSumAndDifference() {
        if (actualFacts?.totalFacts?.price || actualFacts?.totalFacts?.volume) {
            actualSum = checkNumber((actualFacts?.totalFacts?.volume || targetFacts.volume) * (actualFacts?.totalFacts?.price || targetFacts.price)) ?? 0;
            difference = checkNumber(actualSum - targetSum) ?? 0;
        }
    }


    function updateGrossPrice() {
        let transportCost = actualFacts?.transportPrice?.number ?? 0;
        let materialPrice = actualFacts?.materialPrice?.number ?? 0;
        actualFacts.totalFacts.price = transportCost + materialPrice;
        updateSumAndDifference();
    }

    function updateGrossPriceField() {
        actualFacts.transportPrice.number = undefined;
        actualFacts.materialPrice.number = undefined;
        updateSumAndDifference();
    }

    function triggerSumUpdate() {
        targetSum = checkNumber((targetFacts?.volume ?? 0) * (targetFacts?.price ?? 0)) ?? 0;
        updateSumAndDifference();
    }

    $: targetFacts, actualFacts, triggerSumUpdate();
    $: $deal, targetFacts = $deal?.dealFacts;
    $: $deal, actualFacts = isOptimizer ? $deal?.offerActualValues : $deal?.requestActualValues;
    $: if ($deal) {
        targetFacts = $deal.dealFacts;
    }

</script>

<div class="actual-comparison">

    <span>{$t('UI.Model.should.is.comparison')}</span>

    <div class="actual-comparison-grid">
        <div>{$t('Position')}</div>
        <div>{$t('Position.material')}</div>
        <div>{$t('UI.lot.volume.label')}</div>
        <div>{$t('Model.transportCost')}</div>
        <div>{$t('Model.accept.drop.price')}</div>
        <div>{$t('UI.Model.InclTransp.price')}</div>
    </div>
    <hr/>
    <div class="actual-comparison-grid odd">
        <div>{$t('Position.should')}</div>
        <div>{targetFacts.materialName}</div>
        <div>
            <VolumeTableCell value={targetFacts?.volumeFormattedAsString}/>
        </div>
        <div></div>
        <div></div>
        <div>
            <PriceTableCell value={targetFacts?.priceValFormattedAsString || zeroPriceFormat}/>
        </div>
    </div>

    <div class="actual-comparison-grid">

        <div>{$t('Position.is')}</div>
        <div>{targetFacts.materialName}</div>
        {#if readOnly}
            <div>
                <VolumeTableCell value={actualFacts?.totalFacts?.volumeFormattedAsString} needsFormatting/>
            </div>
            <div>
                <PriceTableCell value={actualFacts?.transportPrice.number} needsFormatting/>
            </div>
            <div>
                <VolumeTableCell value={actualFacts?.materialPrice.number} needsFormatting/>
            </div>
            <div>
                <PriceTableCell value={actualFacts?.totalFacts?.price} needsFormatting/>
            </div>
        {:else}
            <div>
                <InputField id="volume" bind:value={actualFacts.totalFacts.volume} unit={$t("UI.unit.ton.short.small")}
                            placeholder={targetFacts?.volumeFormattedAsString} forcedDecimalPlaces={2}
                            format={inputFieldFormats.EXPANDED} isNumeric
                            on:focusout={updateSumAndDifference}/>
            </div>
            <div>
                <InputField id="transportCost" bind:value={actualFacts.transportPrice.number} unit={$t("UI.unit.eur.short")}
                            placeholder={$lot.transportCost} forcedDecimalPlaces={2} isNumeric
                            on:focusout={updateGrossPrice}/>
            </div>
            <div>
                <InputField id="materialPrice" bind:value={actualFacts.materialPrice.number} unit={$t("UI.unit.eur.short")}
                            placeholder="0" forcedDecimalPlaces={2} isNumeric
                            on:focusout={updateGrossPrice}/>
            </div>
            <div>
                <InputField id="grossPrice" bind:value={actualFacts.totalFacts.price} unit={$t("UI.unit.eur.short")}
                            placeholder={targetFacts.priceValFormattedAsString} forcedDecimalPlaces={2} isNumeric
                            on:focusout={updateGrossPriceField}/>
            </div>
        {/if}
    </div>

    <hr/>
    <div class="actual-comparison-grid">
        <div class="sum" style="margin-top:-30px">{$t('UI.Mode.sum')}</div>
        <div></div>
        <div></div>
        <div></div>
        <div>
            <div>{$t('UI.Model.shouldVolumes')}</div>
            <div>{$t('UI.Model.isVolumes')}</div>
            <div>{$t('UI.Model.difference')}</div>
        </div>
        <div class="sum">
            <div>
                <PriceTableCell value={targetSum || zeroPriceFormat} needsFormatting/>
            </div>
            <div>
                <PriceTableCell value={actualSum || zeroPriceFormat} needsFormatting/>
            </div>
            <div>
                <PriceTableCell value={difference || zeroPriceFormat} needsFormatting/>
            </div>
        </div>
    </div>
    {#if readOnly === false}
        <div style="float: right;">
            <PrimaryButton id="saveComparisonBtn" label={$t('UI.button.save')} color={PrimaryBtnColors.CORPORATE_DESIGN}
                           on:click={updatePosition}/>
        </div>
    {/if}
</div>

<style lang="scss">
  @import "../../styles/global";

  .readOnly {
    display: none;
  }

  .actual-comparison {
    font-size: 14px;
    margin-top: 1.875rem;
    text-align: left;
    min-width: min-content;

    & > span {
      font-weight: bold;
    }

    .odd {
      background-color: #F0F2F8;
    }

    & > hr {
      height: 2px;
      color: $primaryColor;
      opacity: 1;
      margin: 0 auto;
    }

    .actual-comparison-grid {

      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 80px) 100px repeat(2, 150px);
      grid-row-gap: 1.125rem;
      grid-column-gap: 3em;
      justify-content: center;
      align-items: center;
      margin: 0;

      & > div {
        width: fit-content;
        margin: 12px;
      }

      .sum {
        color: $primaryColor;
      }

      .actual-comparison-grid :global(.container-label-info-input) {
        margin: 12px;
      }
    }
  }
</style>
