<script lang="ts">

    import {t} from "../../i18n/i18n";
    import type {AutocompleteFieldConfig} from "../types/autocompleteFieldConfig";
    import {AutocompleteConfig} from "../types/autocompleteFieldConfig";
    import type {MaterialProperty, MaterialPropertyListElement} from "./types/materialProperty";
    import {DefaultMaterialProperty, dropdownTypes} from "./types/materialProperty";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import {getContext} from "svelte";
    import type {FontProps} from "../types/fontProps";
    import {DefaultInputFieldProps} from "../types/fontProps";
    import {breadcrumbs} from "../stores";
    import type {Writable} from "svelte/store";

    export let materialProperty: MaterialProperty = DefaultMaterialProperty();
    export let propertyListElement: MaterialPropertyListElement | undefined;
    export let alreadyMappedProperties: MaterialProperty[] = [];

    // we need to introduce this variable because otherwise the property would be set to null from the AC-component, and then we would get NPEs
    // we transfer the selected data from the acProperty to the property on:change
    let acProperty: MaterialProperty = DefaultMaterialProperty();

    let propertyAcConfig: AutocompleteFieldConfig = AutocompleteConfig('id', 'name');
    propertyAcConfig.searchProps = {skipSort: false};
    let properties: Writable<Map<string, MaterialProperty>> = getContext('materialProperties');
    const fontProps: FontProps = DefaultInputFieldProps();
    let propertyOptions = [...$properties.values()];

    $: if (alreadyMappedProperties.length >= 0) {
        propertyOptions = propertyOptions.filter(property => !alreadyMappedProperties.some(mappedProperty => mappedProperty.id === property.id))
    }
    // filter properties by circular dependency if parent Component is from property modal
    if (propertyListElement !== undefined) {
        // filter with store properties
        propertyOptions = propertyOptions.filter(property => !isCyclic({...property}));
        // filter with not saved properties in breadcrumb
        let breadcrumbProperties = [];
        $breadcrumbs.forEach(breadcrumb => {
            if (breadcrumb.data.materialProperty !== undefined) {
                breadcrumbProperties.push(breadcrumb.data.materialProperty.id)
            }
        })
        // filter dropdown value properties which are mapped in breadcrumbs
        propertyOptions = propertyOptions.filter(property => !breadcrumbProperties.includes(property.id));
    }

    function writeSelectedToProperty() {
        materialProperty = {...acProperty}
    }

    function isCyclicUtil(property: MaterialProperty, visited, rec) {
        let returnHelper = false;
        if (rec.has(property.id)) {
            return true;
        }
        if (visited.has(property.id)) {
            return false;
        }

        visited.set(property.id, property);
        rec.set(property.id, property);
        let childProperties = [];
        property.propertyListElements.forEach((propertyListElement: MaterialPropertyListElement) =>
            propertyListElement.propertyChildIds.forEach((propChildId: string) => {
                const prop = {...$properties.get(propChildId)}
                if (dropdownTypes.includes(prop.type)) {
                    childProperties.push({...prop})
                }
            }))

        childProperties.forEach(prop => {
            if (isCyclicUtil({...prop}, visited, rec)) {
                returnHelper = true;
                return true;
            }
        })
        rec.delete(property.id);
        return returnHelper;

    }

    function isCyclic(property: MaterialProperty) {
        let visited = new Map();
        let stack = new Map();
        let parentPropWithChild = structuredClone(materialProperty); // use this function to prevent referencing deeper objects
        let indexOfPropertyListElement = parentPropWithChild.propertyListElements.map((pLE: MaterialPropertyListElement) => pLE.id).indexOf(propertyListElement.id);
        // add property id to copy of parent property list element children to validate circular dependency
        if (indexOfPropertyListElement > -1) {
            parentPropWithChild.propertyListElements[indexOfPropertyListElement].propertyChildIds.push(property.id);
        }

        return isCyclicUtil(parentPropWithChild, visited, stack);
    }

</script>

<!-- todo maybe this component can also include an 'Edit' button to edit the property and also the sub properties -> MaterialPropertyModal -->

<div id="autocomplete-qry-properties" style="width: 100%;">
    <AutocompleteInputField bind:selectedObject={acProperty} bind:options={propertyOptions} {fontProps}
                            autocompleteConfig={propertyAcConfig} label={$t('UI.mmpv.properties.name.label')}
                            hideDropdownArrow={false}
                            on:change={writeSelectedToProperty}/>
</div>

<style lang="scss">
  @import "../../styles/global";
  /* TODO make style */


</style>


