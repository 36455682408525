<script lang="ts">
    import {t} from "../../i18n/i18n"
    import type {Lot} from "./lot";
    import {createEventDispatcher, getContext, hasContext} from "svelte";

    import Salutation from "../atoms/Salutation.svelte";
    import InfoCard from "../organisms/InfoCard.svelte";
    import ProjectInformationGrid from "../project/ProjectInformationGrid.svelte";
    import TextArea from "../atoms/TextArea.svelte";
    import FileUpload from "../organisms/FileUpload.svelte";
    import ProjectNavigationButtons from "../molecules/ProjectNavigationButtons.svelte";
    import {userInfo, userIsLoggedIn} from "../stores";
    import PublicPageHeader from "../molecules/PublicPageHeader.svelte";
    import PublicPageFooter from "../molecules/PublicPageFooter.svelte";
    import Basic from "../templates/Basic.svelte";
    import ContactBox from "../atoms/ContactBox.svelte";
    import {EmptyOffer, type Offer, OfferState} from "../bid/types/offer";
    import {writable, type Writable} from "svelte/store";
    import {type RequestDto, RequestType} from "../bid/types/requestDto";
    import type {ContactData} from "../types/contactData";
    import {loadOfferAttachments} from "../bid/offerUtils";
    import {AttachmentParentType, LotState, OPTIMIZED_STATES_WITHOUT_DISCARDED} from "../types/enums";
    import iconProjectCompleted from "@/icons/icon_pubpage_project_completed.png";
    import type {PubPageParams} from "../bid/types/pubPageParams";
    import {NextButtonWantedObject} from "../bid/types/nextButtonDto";


    const lot: Writable<Lot> = getContext("lot");
    const offerMaterial: Writable<Offer> = hasContext("offerMaterial")
        ? getContext("offerMaterial") : writable(EmptyOffer(RequestType.MATERIAL));
    const offerMaterialWithTransport: Writable<Offer> = hasContext("offerMaterialWithTransport")
        ? getContext("offerMaterialWithTransport") : writable(EmptyOffer(RequestType.MATERIAL_WITH_TRANSPORT));
    const request: Writable<RequestDto> = getContext("request");
    const urlParams: PubPageParams = getContext("urlParams");


    export let recipientData: ContactData;
    export let anonymizeAddress: boolean = true;
    export let projectNavigationCurrentId: string = $request.publicId;
    export let projectNavigationReturnUrl: string | undefined = undefined;
    export let projectNavigationWantedObject: NextButtonWantedObject = NextButtonWantedObject.REQUEST;
    export let attachmentsReadonly: boolean = false;


    let attachmentsOffer: any[] = [];
    let attachmentsUploadUrl: string | string[];
    let attachmentLinkUrl: string;
    let linkAttachments;
    let uploadCompleted: boolean = false;

    let currentUserInfo: { userLogin: string, tenantName: string };
    userInfo.subscribe(value => currentUserInfo = value);

    let completed: boolean = $lot.state === LotState.COMPLETED;
    let showBlur: boolean = completed;
    const toggleBlur = () => showBlur = !showBlur;
    const dispatch = createEventDispatcher();

    function reloadAttachments(data: any) {
        if ((data?.length || -1) > 0) {
            attachmentsOffer = data;
            const offerMaterials = data.filter((file) => file.parentType === AttachmentParentType.PARENT_OFFER);

            // only reload, if there are attachments attached to the offers, but the offers are not yet updated
            // -> this essentially means that there were attachments added by the optimizer and offers created for that
            if (offerMaterials.length > 0 && !$offerMaterial.publicId && !$offerMaterialWithTransport.publicId) {
                // after first upload of an offer attachment we have to fetch the request data again to get the created draft offer
                dispatch("offerChanged");
            }
        }
    }

    function handleUploadOfferAttachmentWithoutOffer() {
        // reload page if an attachment was uploaded without an offer
        if (!$offerMaterial.publicId && !$offerMaterialWithTransport.publicId && uploadCompleted) {
            window.location.reload();
        }
    }

    $: {
        attachmentsUploadUrl = `/api/request/${urlParams.requestPublicId}/requested/${urlParams.requestedEntityType}/${urlParams.requestedEntityId}/user/${urlParams.offerUserPublicId}/attachment/new`;
        if ($offerMaterial.publicId || $offerMaterialWithTransport.publicId) {
            attachmentsUploadUrl = `/api/offer/${$offerMaterial?.publicId || $offerMaterialWithTransport?.publicId}/attachment/new`
            // at the moment, we only need to load attachments of one offer, since we sync them for both offers, will be adapted later
            loadOfferAttachments($offerMaterial?.publicId ?? $offerMaterialWithTransport?.publicId, urlParams)
                .then(data => {
                    attachmentsOffer = data;
                });
        }
    }


    $: uploadCompleted, handleUploadOfferAttachmentWithoutOffer()
    $: $lot.state, completed = $lot.state === LotState.COMPLETED;
    $: completed, showBlur = completed;

</script>

{#if completed}
    <div class="blur-background-overlay" class:hide={!showBlur}>
        <div class="blur-background-white"></div>
        <div class="blur-background-overlay-content align-start">
            <span class="completed-title">{$t("UI.optimizerPage.project.completed.title")}</span>
            <img class="completed-img" alt={$t("UI.optimizerPage.project.completed.title")}
                 src={iconProjectCompleted}>
            <div style="margin-top: 15px; max-width: 35%">
                <span class="completed-hint">{$t("UI.optimizerPage.project.completed.hint")} </span>
            </div>
            <div style="margin-top: 15px">
                <span class="show-project-details" on:click={toggleBlur}>{$t("Project.editProjectData")}</span>
            </div>
        </div>
    </div>
{/if}

{#if !$userIsLoggedIn}
    <PublicPageHeader/>
{/if}

<Basic showStandardHeaderFooter={$userIsLoggedIn}>
    <div class="container-salutation-contact">
        <Salutation name={recipientData.fullName} address={$lot.address.zip + " " + $lot.address.city}/>
        {#if $userIsLoggedIn}
            <ContactBox/>
        {/if}
    </div>
    <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12">
            <InfoCard/>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12">
            <div class="container-project-information">
                <ProjectInformationGrid title={$t('UI.project.information')} {...$lot} {anonymizeAddress}/>
                <TextArea title={$t('UI.materialDetailsComment.header')} text={$lot.lotRemarks}
                          placeholder={$t('UI.remarks.noRemarks')} readonly/>
                {#if $lot.state !== LotState.DISCARDED}
                <TextArea title={$t("UI.projectDetailsComment.header")} text={$lot.projectRemarks}
                          placeholder={$t('UI.remarks.noRemarks')} readonly isHtml/>
                {:else}
                    <TextArea title={$t("UI.Model.moreDetails")} text={$offerMaterial.furtherDetailsComment}
                              placeholder={$t('UI.remarks.noRemarks')} readonly/>
                {/if}
            </div>
        </div>
    </div>
    {#if OPTIMIZED_STATES_WITHOUT_DISCARDED.includes($lot.state)}
        <div class="row">
            <div class="col-lg-12 col-sm-12">
                    <TextArea id="optimizerFurtherDetailsComment" title={$t("UI.Model.moreDetails")}
                              text={$offerMaterial.furtherDetailsComment} placeholder={$t('UI.remarks.noRemarks')}
                              readonly/>
            </div>
        </div>
    {/if}
    <div class="row" style="margin-top: 1.875rem">
        <div class="col-lg-12 col-sm-12">
            <FileUpload bind:eventByAccept={linkAttachments} bind:uploadCompleted uploadUrl={attachmentsUploadUrl}
                        filePrefix={$t('UI.attachments.optimizer.prefix') + currentUserInfo.tenantName + '_' + currentUserInfo.userLogin + '-'}
                        visibleToCustomer={0} visibleToPartner={1} linkUrl={attachmentLinkUrl}
                        updateFun={() => loadOfferAttachments($offerMaterial?.publicId || $offerMaterialWithTransport?.publicId, urlParams).then(data => reloadAttachments(data))}
                        attachments={attachmentsOffer} parentPublicId={$offerMaterial.publicId}
                        readOnly={attachmentsReadonly}
                        canNotDelete={completed || $offerMaterial?.offerState !== OfferState.DRAFT || $offerMaterialWithTransport?.offerState !== OfferState.DRAFT }
                        canNotUpload={completed}/>
        </div>
    </div>

    <slot {linkAttachments}/>

    <div class="row">
        <div class="col-lg-12 col-sm-12">
            <ProjectNavigationButtons currentId={projectNavigationCurrentId} returnUrl={projectNavigationReturnUrl}
                                      wantedObject={projectNavigationWantedObject} isSaas={false}/>
        </div>
    </div>

</Basic>

{#if !$userIsLoggedIn}
    <PublicPageFooter/>
{/if}

<style lang="scss">
  @import "../../styles/animations";
  @import "../../styles/pubPage";

  .columns {
    @include flex-col(1.875rem, $alignment: flex-end);
    width: 100%;
  }

  .completed-title {
    @include roboto-font(28px, 500, 24px, black);
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: bold;
  }

  .completed-hint {
    @include roboto-font(18px, 400, 0.875rem, black);
    margin-bottom: 8rem;
  }

  .completed-img {
    margin-left: 25px;
    @media screen and (max-width: 768px) {
      width: 33%;
      max-width: 200px;
    }
  }

  .hide {
    z-index: -10;
    display: none;
  }

  .show-project-details {
    @include roboto-font(15px, 500, 1rem, $primaryGreen);
    cursor: pointer;
  }


</style>
