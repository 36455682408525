<script lang="ts">

    import {t} from "../../i18n/i18n";
    import {whitelistModalUtils} from "./whitelistModalUtil";
    import {DefaultRobotoFontProps, type FontProps} from "../types/fontProps";
    import {AnimateSharedLayout} from "svelte-motion";
    import SwitchableTab from "../mmpv/SwitchableTab.svelte";
    import Checkbox from "../atoms/Checkbox.svelte";
    import StaticChipList from "../organisms/StaticChipList.svelte";
    import {
        AllWhitelistData,
        type WhitelistCatalog,
        type WhitelistMaterial,
        type WhitelistMaterialPropertyListElement
    } from "./whitelistData";
    import {type LotType, TooltipPlacements} from "../types/enums";
    import {createEventDispatcher, getContext, onMount, tick} from "svelte";
    import {breadcrumbs} from "../stores";
    import {WhitelistView} from "./whitelist";
    import Tooltip from "../atoms/Tooltip.svelte";
    import type {Writable} from "svelte/store";

    const dispatch = createEventDispatcher();
    const headerFontProps: FontProps = DefaultRobotoFontProps('1rem', '0.75rem', undefined, undefined, 500);

    // context stores
    let whitelistConfigData: Writable<Map<LotType, WhitelistCatalog[]>> = getContext('whitelistConfigData');
    let allSelectedData: Writable<AllWhitelistData> = getContext('selectedWhitelistData');
    let selectedLotTypes: Writable<LotType[]> = getContext('selectedLotTypes');
    let selectedLotType: Writable<LotType> = getContext('currentLotType');

    // exported variables
    export let errorMaterials: string[] = [];

    let activeData: WhitelistCatalog[];
    let activeMaterial: WhitelistMaterial;
    let activeCatalog: WhitelistCatalog;
    let propertiesTitle: string;
    let selectedMaterialsCount = 0; // the variable stores the amount of selected materials
    let selectedListElementIds: Map<string, string[]> = new Map<string, string[]>(); // contains the ids of the selected chips for a specific material

    let disabledTabsMap: Map<LotType, boolean> = new Map<LotType, boolean>();
    $selectedLotTypes.forEach(lotType => disabledTabsMap.set(lotType as LotType, false));

    let materialListView;
    let errorTooltips: any = {};

    onMount(() => {
        updateActiveElements();
    })

    function updatePropertiesTitle() {
        propertiesTitle = $t('UI.whitelist.modal.propertiesTitle', {materialName: activeMaterial?.name});
    }

    /**
     * Convert an item to a WhitelistMaterialPropertyListElement because e.detail.selectedList return list object as in StaticChipList.
     *
     * @param {any} item - The item to convert.
     * @return {WhitelistMaterialPropertyListElement} - The converted WhitelistMaterialPropertyListElement.
     */
    function convertToWhitelistMaterialPropertyListElement(item: any): WhitelistMaterialPropertyListElement {
        return {
            name: item.label,
        };
    }

    /**
     * Updates the active material and catalog.
     *
     * @param {WhitelistMaterial} material - The new active material.
     * @param {WhitelistCatalog} catalog - The new active catalog.
     */
    async function updatePropertiesAndElements(material: WhitelistMaterial, catalog: WhitelistCatalog) {
        if (activeCatalog.id !== catalog.id) {
            activeCatalog = {...catalog};
        }
        await tick();
        activeMaterial = {...material};
        // we changed the material, so we need to get the selectedListElements for this material
        selectedListElementIds = $allSelectedData.getPropertyListElementsPerProperty($selectedLotType, activeCatalog, activeMaterial);
    }

    /**
     * adds a property list item to the selection data of the user and updates the errorMaterials, if there
     * are already any errors
     * @param e
     */
    function addItemToSelectedData(e) {
        let addedElement: WhitelistMaterialPropertyListElement = convertToWhitelistMaterialPropertyListElement(e.detail.selectedItem);
        $allSelectedData.addListElement($selectedLotType, addedElement, activeCatalog, activeMaterial, e.detail.identifier);
        // we update the variable here, however, if we did not call the check method, the list will be empty.
        // we only check when clicking on the next button
        // we do not trigger the error checks, but simply look if there are errors left after adding the item
        errorMaterials = $allSelectedData.getErrorMaterials();
        $allSelectedData = $allSelectedData;
    }

    function removeItemFromSelectedData(e) {
        let removedElement: WhitelistMaterialPropertyListElement = convertToWhitelistMaterialPropertyListElement(e.detail.selectedItem);
        $allSelectedData.removeListElement($selectedLotType, removedElement, activeCatalog, activeMaterial, e.detail.identifier);
        $allSelectedData = $allSelectedData;
    }

    /**
     * This function handles checkbox changes. When a checkbox of a material is checked, we want to add an empty
     * list for the material key. This should later be used to trigger the user interaction as he must chose properties
     * for this material. If the checkbox is deselected, then we simply drop the entry, that means we also
     * deselect all the properties that were mapped to that material.
     * @param e
     * @param material The material to which the checkbox belongs
     * @param catalog The catalog to which the material belongs
     */
    function handleMaterialSelect(e, material: WhitelistMaterial, catalog: WhitelistCatalog) {
        updatePropertiesAndElements(material, catalog);
        if (e.detail.checked) {
            $allSelectedData.addMaterialKey($selectedLotType, catalog, material);
        } else {
            $allSelectedData.removeMaterialKey($selectedLotType, material, catalog);
            if (material.id === activeMaterial.id) { // we must only update, when we deselect the activeMaterial
                selectedListElementIds = $allSelectedData.getPropertyListElementsPerProperty($selectedLotType, catalog, material);
            }
        }
        dispatch('materialSelectionChange');
        selectedMaterialsCount = $allSelectedData.getMaterialCountForType($selectedLotType);
        updateDisabledTabsMap();
    }

    function updateActiveElements(): void {
        activeData = $whitelistConfigData.get($selectedLotType) || [];
        activeCatalog = activeData[0]
        activeMaterial = activeCatalog.materials[0];
        selectedListElementIds = $allSelectedData.getPropertyListElementsPerProperty($selectedLotType, activeCatalog, activeMaterial);
    }

    // export to call it from the modal when clicking on the next button
    // used by WhitelistModal
    export function updateActiveElementsWithError() {
        // we do not want to directly switch to another material in case we just selected a property for the first error material
        // we need to wait until the user manually selects another material
        // errorsReduced says that a material has been removed from the errors list and has long as no material is selected by
        // hand, we prevent updating the activeMaterial automatically
        if (errorMaterials.length > 0) {
            for (let catalog of ($whitelistConfigData.get($selectedLotType) || [])) {
                let errorMaterialInCatalog = catalog.materials.find(m => m.id === errorMaterials[0]);
                if (errorMaterialInCatalog) {
                    activeCatalog = catalog;
                    activeMaterial = errorMaterialInCatalog;
                    let materialSpan = document.getElementById(`material-${activeMaterial.id}`);
                    materialSpan?.scrollIntoView({behavior: 'smooth'});
                    return;
                }
            }
        }
    }

    function handleSwitchTab(lotType: LotType) {
        // also here show the errors when trying to navigate
        errorMaterials = $allSelectedData.checkSelectedMaterials($selectedLotType);
        if (errorMaterials.length > 0) {
            activeMaterial = activeMaterial;
            return;
        }
        // we need to check both, because stepping back should always be possible
        if ($allSelectedData.getMaterialCountForType(lotType) === 0 && $allSelectedData.getMaterialCountForType($selectedLotType) === 0) {
            return;
        }
        $selectedLotType = lotType;
        // if selected lot type is in breadcrumbs -> pop breadcrumbs until breadcrumb lot type is equal selected lot type
        // if not add lot type to breadcrumb
        $breadcrumbs.pop()
        const breadcrumbTitle = whitelistModalUtils.getBreadcrumbTitleMaterialType($selectedLotType)
        dispatch('updateBreadcrumbs', {title: breadcrumbTitle, view: WhitelistView.MATERIAL_TYPE, lotType})
    }

    // Needed to initialize the Svelte Chips
    function initializeListElements(listElements: WhitelistMaterialPropertyListElement[]) {
        return listElements.map(element => ({
            label: element.name,
            value: element.name,
            msg: ""
        }));
    }

    function shouldTabBeDisabled(lotType: LotType) {
        if (lotType === $selectedLotType) { // if it is the active LotType, should not be disabled
            return false;
        }
        if ($allSelectedData.getMaterialCountForType(lotType) > 0) { // if has selected materials, should not be disabled
            return false;
        }
        let selectedLotTypeIdx = $selectedLotTypes.indexOf($selectedLotType);
        let requestedLotTypeIdx = $selectedLotTypes.indexOf(lotType);
        if (requestedLotTypeIdx <= selectedLotTypeIdx) { // if it is before actual lotType, should not be disabled
            return false;
        }
        // if we selected materials, the next tab should be visible
        if (selectedLotTypeIdx + 1 === requestedLotTypeIdx && $allSelectedData.getMaterialCountForType($selectedLotType) > 0) {
            return false;
        }
        let res = true;
        // if all materials until this material have materials selected, should not be disabled, otherwise disabled
        for (let i = selectedLotTypeIdx + 1; i < requestedLotTypeIdx; i++) {
            res = res && $allSelectedData.getMaterialCountForType($selectedLotTypes[i]) === 0;
        }
        return res;

    }

    function updateDisabledTabsMap() {
        if (!$selectedLotTypes || !disabledTabsMap) {
            return;
        }
        $selectedLotTypes.forEach(lotType => disabledTabsMap.set(lotType as LotType, shouldTabBeDisabled(lotType)));
        disabledTabsMap = disabledTabsMap;
    }


    function showErrorTooltips() {
        let firstShown = false;
        for (let errorTooltipKey in errorTooltips) {
            let currentTooltip = errorTooltips[errorTooltipKey];
            if (currentTooltip) {
                if (errorMaterials.includes(errorTooltipKey)) {
                    currentTooltip.enable();
                    if (!firstShown) {
                        currentTooltip.show(true);
                        firstShown = true;
                    }
                } else {
                    currentTooltip.disable();
                }
            }
        }
    }

    $: activeMaterial, updatePropertiesTitle();
    $: $selectedLotType, updateActiveElements(), updatePropertiesTitle();
    $: $allSelectedData, selectedMaterialsCount = $allSelectedData.getMaterialCountForType($selectedLotType);
    $: $allSelectedData, selectedListElementIds = $allSelectedData.getPropertyListElementsPerProperty($selectedLotType, activeCatalog, activeMaterial);
    $: $allSelectedData, $selectedLotType, updateDisabledTabsMap();
    $: errorMaterials, showErrorTooltips();


</script>


<div class="whitelist-view-materialType">
    <div class="whitelist-view-header">
        <span>
            {$t('UI.whitelist.modal.materialType.materialHeader', {
                prefix: whitelistModalUtils.getPrefix($selectedLotType),
                materialType: whitelistModalUtils.getLotTypeStringResource($selectedLotType)
            })}
        </span>
        <span id="materialsCount">{selectedMaterialsCount === 1 ? $t('UI.whitelist.modal.selectedMaterialsCount.singular') : $t('UI.whitelist.modal.selectedMaterialsCount.plural', {number: selectedMaterialsCount})}</span>
    </div>
    <nav class="whitelist-view-nav">
        <ul>
            <AnimateSharedLayout>
                {#each $selectedLotTypes as lotType}
                    <SwitchableTab disabled={disabledTabsMap.get(lotType)} active={$selectedLotType === lotType}
                                   fontProps={DefaultRobotoFontProps('0.625', '0.75rem', '#9d9d9d', 'black', 400 )}
                                   label={whitelistModalUtils.getLotTypeStringResource(lotType)}
                                   on:click={() => handleSwitchTab(lotType)}/>
                {/each}
            </AnimateSharedLayout>
        </ul>
    </nav>
    <div bind:this={materialListView} class="whitelist-view-material-list">
        <div>
            {#each activeData as catalog (catalog.id)}
                <h4 class="catalog-title">{catalog.name}</h4>
                <ul class="material-list">
                    {#each catalog.materials as material (material.id)}
                        {@const active = material.id === activeMaterial.id}
                        {@const hasError = errorMaterials.includes(material.id)}
                        <li>
                            <Tooltip bind:this={errorTooltips[material.id]}
                                     placementTtip={TooltipPlacements.BOTTOM_START}
                                     msg={$t('UI.whitelist.materialSelect.error.tt')}
                                     maxWidthTtip={350}
                                     appendToObject={materialListView}
                                     removeCloseIconAfterClose isHtml>
                                <span id="material-{material.id}" class="material-name" class:active
                                      class:error={hasError}
                                      on:click={() => updatePropertiesAndElements(material, catalog)}>
                                    {material.name}
                                </span>
                            </Tooltip>
                            <Checkbox id={"ctlg-" + catalog.id + "-mtrl-" + material.id}
                                      checked={$allSelectedData.hasMaterialForType($selectedLotType, catalog.id, material.id)}
                                      on:checkboxChange={(e) => handleMaterialSelect(e, material, catalog)}/>
                        </li>
                        <li class="separator"></li>
                    {/each}
                </ul>
            {/each}
        </div>
        <div>
            <h4 class="properties-title">{propertiesTitle}</h4>
            {#each activeMaterial.properties as property, i (property.name)}
                <StaticChipList id={"mtrl-" + activeMaterial.id + "-prprty-" + property.name}
                                identifier={property}
                                header={property.name} fontProps={headerFontProps}
                                activeChips={selectedListElementIds.get(property.name)}
                                list={initializeListElements(property.propertyListElements)}
                                headerPaddingBottom={"1 rem"}
                                on:addItem={addItemToSelectedData}
                                on:removeItem={removeItemFromSelectedData}
                                showSelectAll/>
            {/each}
        </div>
    </div>
</div>

<style lang="scss">

  @use "../../styles/global";

  .whitelist-view-materialType {
    display: grid;
    grid-template-rows: max-content;
    grid-gap: 10px;
    width: 100%;

    & > div.whitelist-view-header {
      @include global.flex-row($justify: space-between);

      & > span:first-child {
        @include global.roboto-font(1rem, 500, 0.75rem, black);
      }

      & > span:last-child {
        @include global.roboto-font(undefined, 500, 0.875rem, #5888C0);
      }
    }

    & > nav.whitelist-view-nav {
      background: white;
      border-radius: 10px 10px 0 0;
      border-bottom: 1px solid #E9E9E9;
      height: 33px;

      & > ul {
        display: flex;
        width: max-content;
        padding-left: 6px;
        list-style: none;
        direction: ltr;
        height: 100%;
      }
    }

    & > div.whitelist-view-material-list {
      @include global.flex-row(2.625rem, $justify: space-between);
      position: relative;
      overflow: hidden;

      & > div {
        @extend .scrollable;
        height: 21.75rem;
        overflow-x: hidden;
        width: 100%;

        &:first-child {
          padding-left: 0.875rem;
          max-width: 250px;
          overflow-y: scroll;
          transform: scaleX(-1);
        }
      }
    }
  }

  .catalog-title {
    @include global.roboto-font(18px, 400, 0.875rem, #000);
    background: linear-gradient(90deg, rgba(192, 192, 192, 0.20) 99.99%, rgba(217, 217, 217, 0.00) 100%);
    width: 100%;
    height: 2.063rem;
    padding: 10px 40px 10px 14px;
    transform: scaleX(-1); // reverse the scale from the parent div that is necessary because of the scrollbar on the left
  }

  .properties-title {
    @include global.roboto-font(18px, 500, 0.875rem, #000);
    text-align: start;
    width: 26.5rem;
    margin-top: 10px;
    margin-bottom: auto;
  }

  .material-list {
    padding: 0;
    list-style: none none;
    transform: scaleX(-1);


    & span {
      width: 100%;
      text-align: left;
      padding-left: 14px;
      @include global.roboto-font(18px, 400, 0.75rem, #000);

      &.active {
        color: global.$primaryGreen;
        font-weight: 500;
      }

      &.error {
        color: global.$primaryRed;
        font-weight: 500;
      }

      &:hover {
        color: #58C079;
        cursor: pointer;
      }
    }


    & > li:nth-child(odd) {
      @include global.flex-row(1rem, $justify: space-between);
      margin-bottom: 5px;
      margin-top: 5px;

      & :global(div.tooltip-container-slot) {
        display: flex;
      }

    }

    & > li.separator {
      border-top: 1px solid #C0C0C033;
      line-height: 0;
      margin-top: 5px;
    }
  }

</style>
