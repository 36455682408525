import {number, object, string} from "yup";

export type UserAccountData = {
    id: number,
    firstName: string,
    lastName: string
}

export function DefaultUserAccountData(id: number = null, firstName: string = "", lastName: string = ""): UserAccountData {
    let userAccount: UserAccountData = {id: id, firstName: firstName, lastName: lastName};
    return userAccount;
}

export function getUserAccountAutocompleteText(userAccount: UserAccountData) {
    return `${userAccount.firstName} ${userAccount.lastName}`
}

export function copyUserAccountData(userAccount: UserAccountData) {
    let copy: UserAccountData = DefaultUserAccountData();
    Object.assign(copy, userAccount);
    return copy;
}

export const userAccountDataSchema= object({
    id: number().notRequired(),
    firstName: string().notRequired(),
    lastName: string().notRequired()
})
