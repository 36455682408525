import {Facts} from "./facts";
import {RequestType} from "./requestDto";

export type Offer = {
    publicId: string,
    offerLocation?: string,
    requestType: RequestType,
    facts: Facts,
    furtherDetailsComment: string,
    optimizerPublicComment: string,
    offerState: OfferState,
}

export function EmptyOffer(requestType: RequestType = RequestType.MATERIAL): Offer {
    return {
        publicId: "",
        offerLocation: "",
        requestType: requestType,
        facts: new Facts(),
        furtherDetailsComment: "",
        optimizerPublicComment: ""
    }
}

export enum OfferState {
    ACCEPTED = "ACCEPTED",
    DISCARDED = "DISCARDED",
    DRAFT = "DRAFT",
    READY = "READY",
    REJECTED = "REJECTED",
    REJECTED_MM = "REJECTED_MM",
    SENT = "SENT",
    WITHDRAWN = "WITHDRAWN"
}
