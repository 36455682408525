<script lang="ts">

    import DropdownMenu from "../../dropdown/SimpleDropdownMenu.svelte";
    import SimpleDropdownLinkItem from "../../dropdown/SimpleDropdownLinkItem.svelte";
    import MenuItem from "../../MenuItem.svelte";
    import {UserPermission} from "../../../types/enums";
    import {fetchUtils} from "../../../utils/fetchUtils";
    import {t} from "../../../../i18n/i18n";
    import DropdownMenuSection from "../../dropdown/DropdownMenuSection.svelte";

    const USER_ACCOUNTS_PERMISSIONS = `(${UserPermission.MANAGE_SYSTEM_USER}&${UserPermission.FLAG_SYSTEM_TENANT})`
        + `|(${UserPermission.MANAGE_USER_ACCOUNTS}&!${UserPermission.FLAG_SYSTEM_TENANT})`;

    function syncMaterials() {
        fetchUtils.get("/api/mmpvcache/reload", false);
    }

    const REGISTRY_MENU_PERMISSIONS = `${UserPermission.MANAGE_POPS}`
        + `|${UserPermission.MANAGE_BASEDATA}`
        + `|${UserPermission.FLAG_SYSTEM_TENANT_MEMBER}`
        + `|${UserPermission.MANAGE_TENANTS}`
        + `|${UserPermission.MATERIAL_PRIORITIES_EDITOR}`
        + `|${UserPermission.MANAGE_MASTERDATA}`
        + `|${UserPermission.MANAGE_FILES}`
        + `|${UserPermission.EXECUTE_JOBS}`
        + `|${UserPermission.MANAGE_PROCESSES}`
        + `|${UserPermission.MANAGE_CODE_LISTS}`
        + `|${USER_ACCOUNTS_PERMISSIONS}`;

</script>

<MenuItem title={$t("Menu.settings")} url="#" let:active permissions={REGISTRY_MENU_PERMISSIONS}>
    <DropdownMenu {active}>
        <DropdownMenuSection>
            <SimpleDropdownLinkItem titleKey="Model.partnerAndPops" url="/pops"
                                    permissions={UserPermission.MANAGE_POPS}/>
            <SimpleDropdownLinkItem titleKey="Team.plural" url="/teams"
                                    permissions={USER_ACCOUNTS_PERMISSIONS}/>
            <SimpleDropdownLinkItem titleKey="Model.material.substitutes.mapping"
                                    url="/materialData/substitutes/mapping"
                                    permissions={UserPermission.FLAG_SYSTEM_TENANT_MEMBER}/>
            <SimpleDropdownLinkItem titleKey="Model.material.plural" url="/catalogs"
                                    permissions={UserPermission.FLAG_SYSTEM_TENANT_MEMBER}/>
            <SimpleDropdownLinkItem titleKey="Model.material.reloadCache"
                                    permissions={UserPermission.FLAG_SYSTEM_TENANT_MEMBER}
                                    on:click={syncMaterials}/>
            <SimpleDropdownLinkItem titleKey="Model.material.prioritize"
                                    url="/materialData/priorities"
                                    permissions={`${UserPermission.MANAGE_TENANTS}&${UserPermission.MATERIAL_PRIORITIES_EDITOR}`}/>
            <SimpleDropdownLinkItem titleKey="VehicleCategory.plural" url="/vehicle-categories"
                                    permissions={UserPermission.MANAGE_BASEDATA}/>
            <SimpleDropdownLinkItem titleKey="CompanyCategory.plural" url="/company-categories"
                                    permissions={UserPermission.MANAGE_BASEDATA}/>
            <SimpleDropdownLinkItem titleKey="LocationType.plural" url="/location-types"
                                    permissions={UserPermission.MANAGE_BASEDATA}/>
            <SimpleDropdownLinkItem titleKey="TermsAndConditions.plural" url="/terms"
                                    permissions={UserPermission.MANAGE_MASTERDATA}/>
            <SimpleDropdownLinkItem titleKey="UI.help.helpSystem.title" url="/hlpConfiguration"
                                    permissions={UserPermission.EDIT_HELP_ENTRIES}/>
            <SimpleDropdownLinkItem titleKey="Tenant.plural" url="/tenants"
                                    permissions={UserPermission.MANAGE_TENANTS}/>
            <SimpleDropdownLinkItem titleKey="UserAccount.plural" url="/user-accounts"
                                    permissions={USER_ACCOUNTS_PERMISSIONS}/>
            <SimpleDropdownLinkItem titleKey="VFSController.root" url="/fs"
                                    permissions={UserPermission.MANAGE_FILES}/>
            <SimpleDropdownLinkItem titleKey="JobsController.jobs" url="/jobs"
                                    permissions={UserPermission.EXECUTE_JOBS}/>
            <SimpleDropdownLinkItem titleKey="Process.plural" url="/ps"
                                    permissions={UserPermission.MANAGE_PROCESSES}/>
            <SimpleDropdownLinkItem titleKey="CodeList.plural" url="/code-lists"
                                    permissions={UserPermission.MANAGE_CODE_LISTS}/>
            <SimpleDropdownLinkItem titleKey="UI.corporateDesign.header" url="/corporateDesign"
                                    permissions={UserPermission.CUSTOMIZE_PUBPAGES}/>
        </DropdownMenuSection>
    </DropdownMenu>
</MenuItem>
