<script lang="ts">

    import {getContext} from "svelte";
    import type {Writable} from "svelte/store";
    import {createTable, Render, Subscribe} from "svelte-headless-table";
    import {addSortBy, addTableFilter} from "svelte-headless-table/plugins";
    import SortingArrowIcons from "../atoms/SortingArrowIcons.svelte";
    import {t} from "../../i18n/i18n";

    const tableData: Writable<any[]> = getContext('table-data'); // tableData MUST ALWAYS be a list/array
    const colModel: Writable<any[]> = getContext('table-cols');
    let table = createTable(tableData, {
        sort: addSortBy(),
        tableFilter: addTableFilter({
            fn: ({filterValue, value}) => {
                if (!filterValue) return true;
                if (!value) return false;
                if (typeof value !== 'string') {
                    value = "" + value;
                }
                return value.toLowerCase().includes(filterValue.toLowerCase());
            }
        })
    });
    let columns = table.createColumns([...$colModel.map(col => table.column(col))].flat(1));
    let {visibleColumns, headerRows, rows, tableAttrs, tableBodyAttrs, pluginStates} = table.createViewModel(columns);
    const {filterValue} = pluginStates.tableFilter;


    export let emptyMessage: string = ""
    export let searchTerm: string = '';
    $: searchTerm, $filterValue = searchTerm;


</script>


<table {...$tableAttrs} class="headless-table">
    <!-- style this via the parent component .parent :global(.headless-table) -->
    <thead>
    {#each $headerRows as headerRow (headerRow.id)}
        <Subscribe rowAttrs={headerRow.attrs()} let:rowAttrs>
            <tr {...rowAttrs} class="headless-table-header">
                {#each headerRow.cells as cell (cell.id)}
                    <Subscribe attrs={cell.attrs()} let:attrs props={cell.props()} let:props>
                        <th {...attrs} class="headless-table-header-cell headless-table-header-cell-{cell.id}"
                            on:click={props.sort.toggle} class:sorted={props.sort.order !== undefined}
                            class:sortable={!props.sort.disabled}>
                            <div class="header-row-sortable-cell">
                                <Render of={cell.render()}/>
                                <!-- This "if-massacre" is necessary, otherwise changes to what order is set are not propagated. -->
                                {#if !props.sort.disabled}
                                    {#if !props.sort.order}
                                        <SortingArrowIcons/>
                                    {:else}
                                        {#if props.sort.order === "asc"}
                                            <SortingArrowIcons order="asc"/>
                                        {/if}
                                        {#if props.sort.order === "desc"}
                                            <SortingArrowIcons order="desc"/>
                                        {/if}
                                    {/if}
                                {/if}
                            </div>
                        </th>
                    </Subscribe>
                {/each}
            </tr>
        </Subscribe>
    {/each}
    </thead>

    <tbody {...$tableBodyAttrs}>
    {#each $rows as row, i (row.id)}
        <Subscribe rowAttrs={row.attrs()} let:rowAttrs>
            <slot name="headless-table-section-header" rows={$rows} idx={i} data={row.original}/> <!-- possibly give complete row -->
            <tr {...rowAttrs} class="headless-table-row" id={row.original.key ?? row.original.id}>
                <!-- in case we have the key or id defined -->
                {#each row.cells as cell (cell.id)}
                    <Subscribe attrs={cell.attrs()} let:attrs props={cell.props()} let:props>
                        <!-- allows to target specific cols -->
                        <td {...attrs} class="headless-table-cell headless-table-cell-{cell.id}"
                            class:sorted={props.sort.order !== undefined}>
                            <Render of={cell.render()}/>
                        </td>
                    </Subscribe>
                {/each}
            </tr>
        </Subscribe>
    {:else} <!-- table empty state -->
        <tr>
            <td colspan={$visibleColumns.length}>
                <div class="headless-table-empty-state">
                    <span>{$t("UI.headlessTable.emptyState")}</span>
                    {#if emptyMessage}
                        <span>{@html emptyMessage}</span>
                    {/if}
                </div>
            </td>
        </tr>
    {/each}
    </tbody>
</table>


<style lang="scss">
  @import "../../styles/global";

  .headless-table {
    width: var(--table-width, 100%);
  }

  /* default padding for the first column of all tables**/
  /* todo possibly this needs to be adapted in case we want actions/data in this col at some point*/
  tr.headless-table-header > th.headless-table-header-cell:first-of-type,
  tr.headless-table-row > td.headless-table-cell:first-of-type {
    padding-left: 68px;
  }

  .headless-table-header {
    height: var(--table-header-height, 24px);
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: var(--table-shadow-bottom, inset 0 -1px 0 $primaryGreen); // we use this with position sticky to have a border at the bottom
    background: white;
    //border-bottom: var(--table-shadow-bottom, 1px solid #58C079);
  }

  .headless-table-row {
    border-bottom: var(--table-body-border-bottom, 1px solid #E9E9E9);
  }

  .headless-table-header-cell {
    @include roboto-font(var(--table-header-line-height, 18px), var(--table-header-font-weight, 400), var(--table-header-font-size, 1rem), var(--table-header-color, black));
    padding: var(--table-header-padding, 10px 13px);
  }

  .headless-table-cell {
    @include roboto-font(var(--table-cell-line-height, 18px), var(--table-cell-font-weight, 400), var(--table-cell-font-size, 0.875rem), var(--table-cell-color, black));
    padding: var(--table-cell-padding, 1.5rem 13px);
  }

  .headless-table-empty-state {
    padding: 2.75rem 25%;
    min-height: 200px;
    background: $grey-600;
    @include flex-col($alignment: flex-start);

    & span:first-child {
      @include roboto-font(var(--table-empty-line-height, 2rem), var(--table-empty-font-weight, 400), var(--table-empty-font-size, 1rem), var(--table-empty-color, black));
    }

    & span:last-child {
      @include roboto-font(var(--table-empty-line-height, 1rem), var(--table-empty-font-weight, 400), var(--table-empty-font-size, 0.75rem), var(--table-empty-color, black));
    }
  }

  .sortable {
    cursor: pointer;
  }

  .headless-table-header-cell :global(.transition-transform) {
    transition: transform 200ms ease-in-out;
  }

  .headless-table-header-cell :global(.-scale-y-100) {
    transform: scaleY(-1);
  }

  .header-row-sortable-cell {
    @include flex-row($col-gap: 0.275rem, $justify: flex-start);

    & > div {
      @include flex-col();
    }
  }

</style>
