<script lang="ts">
    import {t} from "../../i18n/i18n";
    import {formatNumber} from "../utils/formatters"

    export let value: number | string;
    export let displayShortUnit: boolean = true;
    export let needsFormatting: boolean = false;
</script>
{#if value}
    {@html needsFormatting ? formatNumber(value.toString()) : value}&nbsp;{displayShortUnit ? $t('UI.unit.eur.short') : $t('UI.unit.eur')}
{/if}