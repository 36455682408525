<script lang="ts">
    import {inputFieldFormats, markFieldLabelRequired} from "../utils/formatters";
    import {CalendarStyle} from '@beyonk/svelte-datepicker/src/calendar-style.js'
    import 'flatpickr/dist/flatpickr.css';
    import 'flatpickr/dist/themes/light.css';
    import Flatpickr from "svelte-flatpickr"
    import dayjs from "dayjs";
    import type {FontProps} from "../types/fontProps";
    import {DefaultInputFieldProps, getFontPropsVarString} from "../types/fontProps";
    import MaterialDatePicker from "../molecules/MaterialDatePicker.svelte";
    import {createEventDispatcher, getContext, hasContext} from "svelte";
    import {AnimatePresence, AnimateSharedLayout, Motion} from "svelte-motion";
    import {openCloseVariants} from "../utils/svelteMotionUtils";
    import {
        getExactPathErrorMessage,
        getPathErrorMessage,
        isExactPathInErrorList,
        isPathInErrorList
    } from "../utils/formValidation";
    import {German} from "flatpickr/dist/l10n/de.js"
    import type {Writable} from "svelte/store";
    import {writable} from "svelte/store";
    import type {FormError} from "../types/formError";

    const now = new Date();

    export let id = '';
    export let label = "Date";
    export let date: number | null;
    export let format = inputFieldFormats.EXPANDED;
    export let fontProps: FontProps = DefaultInputFieldProps();
    export let placeholder = "Datum auswählen";
    export let materialWidth: string = "100%";
    export let disabled: boolean = false;
    export let required: boolean = false;
    export let disableDays = () => {
    };
    export let maxDate = new Date(now.getFullYear() + 5, 11, 31);
    export let minDate = new Date(now.getFullYear() - 5, 0, 1);
    export let errorPath: string = '';

    /* Handling of the error message indication with a context store that is set on the parent including the form */
    let formErrors: Writable<FormError[]> = hasContext('formErrors') ? getContext('formErrors') : writable([]);
    let error: string;

    const useMaterialDesign: boolean = hasContext('useMaterialDesign') ? getContext('useMaterialDesign') : false;
    const dispatch = createEventDispatcher();

    let internalValue: string;
    let internalValueInitialized: boolean = false;

    let datePickerStyle = new CalendarStyle();
    datePickerStyle.highlightColor = '#58c079';
    datePickerStyle.timeConfirmButtonColor = '#58c079';
    datePickerStyle.buttonWidth = '';

    let flatpickrOptions;
    let open = false; // toggles the border when the calendar is opened
    const toggleOpen = () => open = !open;

    // cyclic reactivity doesn't work, but this works...
    function onDatePickerChanged(event) {
        // prevent sending an event when the value is adapted to the right format for the first time
        if (internalValueInitialized) {
            date = dayjs(event.detail[0]).unix();
            dispatch("change", event);
        }
        // since this event is triggered, when internalValue changes, we know that after running this for the first time,
        // the value must be initialized
        internalValueInitialized = true;
    }

    $: if (errorPath && errorPath !== '' && $formErrors?.length > 0) {
        let paths = errorPath.includes('||') ? errorPath.split('||') : [errorPath];
        error = "";
        paths.forEach(path => {
            if (path.includes('.*')) {
                error += isPathInErrorList($formErrors, path) ? getPathErrorMessage($formErrors, path) : '';
            } else {
                error += isExactPathInErrorList($formErrors, path) ? getExactPathErrorMessage($formErrors, path) : '';
            }
        })
    }

    $: flatpickrOptions = {
        dateFormat: "d.m.Y",
        maxDate: maxDate,
        minDate: minDate,
        clickOpens: !disabled,
        weekNumbers: true,
        locale: German,
        disable: [disableDays]
    }

    $: date, internalValue = date ? dayjs.unix(date).format('DD.MM.YYYY') : "";
    $: if (required) {
        label = markFieldLabelRequired(label);
    }
</script>
{#if useMaterialDesign}
    <MaterialDatePicker {id} bind:value={internalValue} {label} {placeholder} {flatpickrOptions} {disabled} {fontProps}
                        width={materialWidth} on:change={onDatePickerChanged} on:open={toggleOpen}
                        on:close={toggleOpen}/>
{:else}
    <AnimateSharedLayout>
        <div class="input-field-error-message-container">
            <div class="labeled-input-field" class:fullWidth={format === inputFieldFormats.FULL}>
                {#if label && label !== "" && label !== "undefined"}
                    <span>{label}</span>
                {/if}
                <div class="input-field-focus date-time-field input" class:open
                     style="width: {format.maxWidth}; max-width: {format.maxWidth};{getFontPropsVarString(fontProps)}">
                    <Flatpickr {id} bind:value={internalValue} {placeholder} options={flatpickrOptions}
                               on:change={onDatePickerChanged} on:open={toggleOpen} on:close={toggleOpen}/>
                </div>
            </div>
            <AnimatePresence list={error && error !== '' ? [{ key: 1 }] : []}>
                <Motion let:motion={collapse} initial="collapsed" animate="open" exit="collapsed"
                        variants={openCloseVariants}>
                    <span class="form-field-error" use:collapse>{@html error}</span>
                </Motion>
            </AnimatePresence>
        </div>
    </AnimateSharedLayout>
{/if}

<style lang="scss">
  @import "../../styles/global";
  @import "../../styles/inputFields";

  .date-time-field .input {
    @extend .input-field;
    height: 32px;
    background-color: white !important;
  }

  div :global(.flatpickr-input) {
    border: none;
    box-shadow: none;
    height: 16px;
    padding: 0;
    width: 100%; /* we set the width to 100% such that the placeholder is as long as the container */

    &::placeholder {
      color: var(--placeholder-color);
    }
  }

  :global(.flatpickr-day.selected) {
    background: $primaryColor !important;
    border-color: $primaryColor !important;
  }

  .fullWidth :global(.flatpickr-input) {
    width: 100%;
  }

  .open {
    border: 1px solid $primaryColor;
    outline: none !important;
  }

  :global(.flatpickr-calendar.animate.open) {
    z-index: 1000003;
  }

</style>
