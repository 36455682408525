<script lang="ts">
    import {getContext} from "svelte";
    import {jwt} from "../../utils/jwt";
    import type {Writable} from "svelte/store";

    export let refLot: string;
    export let startObject: string;
    export let match: string;
    export let minDistance: number;
    export let maxDistance: number;

    window.mm = window.mm || {};

    let ready = false;

    let map: Writable<google.maps.Map> = getContext("map");

    $: {
        window.mm.map = $map;
        window.mm.refLot = refLot;
        window.mm.startObject = startObject;
        window.mm.match = match;
        window.mm.minDistance = minDistance;
        window.mm.maxDistance = maxDistance;
    }

    jwt.subscribe(data => {
        window.mm.jwt = data;
        ready = true;
    })

    function submitRequestMatches() {
        const requestMatchForm: HTMLFormElement | null = document.getElementById('requestMatchForm') as HTMLFormElement;
        requestMatchForm?.submit();
    }

    // noinspection JSUnusedGlobalSymbols
    let antiCacheSuffix = '?' + Date.now();
</script>

<svelte:head>
    <!-- FIXME assets-->
    <link rel="stylesheet" media="screen" href="/assets/wondergem/wondergem.css">
    <link rel="stylesheet" media="screen" href="/assets/wondergem/stylesheets/application.css">
    <link rel="stylesheet" href="/assets/no-cache/map/styles.css">
    <link rel="stylesheet" href="/assets/no-cache/stylesheets/toggle_switch.css">
    <link rel="stylesheet" href="/assets/no-cache/frontend/jquery-ui-1.12.1/jquery-ui.min.css">
    <link rel="stylesheet" href="/assets/no-cache/frontend/jquery-ui-1.12.1/jquery-ui.structure.min.css">
    <link rel="stylesheet" href="/assets/no-cache/frontend/jquery-ui-1.12.1/jquery-ui.theme.min.css">
    {#if ready}
        <script src="/assets/wondergem/wondergem.js{antiCacheSuffix}" type="text/javascript"></script>
        <script src="/assets/js/tippy/popper.js{antiCacheSuffix}" type="text/javascript"></script>
        <script src="/assets/js/tippy/tippy.js{antiCacheSuffix}" type="text/javascript"></script>
        <script defer src="/assets/no-cache/map/new/map.js{antiCacheSuffix}"></script>
        <script src="/assets/js/materialPropertiesModal.js{antiCacheSuffix}"></script>
        <script src="/assets/js/materialPropertiesParser.js{antiCacheSuffix}"></script>
    {/if}
</svelte:head>

<div id="request-matches" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="request-matchesTitle"
     style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Schließen">×</button>
                <h4 class="modal-title" id="request-matchesTitle">Match anfragen</h4>
            </div>


            <div class="modal-body">


                <p>Möchten Sie das Match per E-Mail anfragen?</p>
            </div>


            <div class="modal-footer">

                <a on:click={submitRequestMatches} class="btn btn-primary">Ja</a>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Abbrechen</button>
            </div>

        </div>
    </div>
</div>

