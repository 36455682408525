<script lang="ts">

    import MenuAdmin from "../../MenuAdmin.svelte";
    import DropdownMenu from "../../dropdown/SimpleDropdownMenu.svelte";
    import SimpleDropdownLinkItem from "../../dropdown/SimpleDropdownLinkItem.svelte";
    import {UserPermission} from "../../../types/enums";
    import {t} from "../../../../i18n/i18n";
    import DropdownMenuSection from "../../dropdown/DropdownMenuSection.svelte";

    export let hamburgerActive: boolean = false;
</script>

{#if !hamburgerActive}
    <MenuAdmin let:active>
        <DropdownMenu {active}>
            <DropdownMenuSection>
                <SimpleDropdownLinkItem titleKey="LoggedMessage.plural" url="/system/logs"
                                        permissions={UserPermission.SYSTEM_PROTOCOLS}/>
                <SimpleDropdownLinkItem titleKey="StoredIncident.plural" url="/system/errors"
                                        permissions={UserPermission.SYSTEM_PROTOCOLS}/>
                <SimpleDropdownLinkItem titleKey="MailProtocol.plural" url="/system/mails"
                                        permissions={UserPermission.SYSTEM_PROTOCOLS}/>
                <SimpleDropdownLinkItem titleKey="JournalEntry.plural" url="/system/protocol"
                                        permissions={UserPermission.SYSTEM_JOURNAL}/>
                <SimpleDropdownLinkItem titleKey="DisasterController.title" url="/system/disaster"
                                        permissions={UserPermission.CONTROL_DISASTER_MODE}/>
                <SimpleDropdownLinkItem title="Status" url="/system/state"
                                        permissions={UserPermission.FLAG_SYSTEM_TENANT_MEMBER}/>
                <SimpleDropdownLinkItem title="System Load" url="/system/load"
                                        permissions={UserPermission.FLAG_SYSTEM_TENANT_MEMBER}/>
                <SimpleDropdownLinkItem title="Konsole" url="/system/console"
                                        permissions={UserPermission.SYSTEM_CONSOLE}/>
                <SimpleDropdownLinkItem title="Timing" url="/system/timing"
                                        permissions={UserPermission.SYSTEM_TIMING}/>
                <SimpleDropdownLinkItem title="Schema" url="/system/schema"
                                        permissions={UserPermission.FLAG_SYSTEM_ADMINISTRATOR}/>
                <SimpleDropdownLinkItem title="SQL" url="/system/sql"
                                        permissions={UserPermission.FLAG_SYSTEM_ADMINISTRATOR}/>
                <SimpleDropdownLinkItem title="Redis" url="/system/redis"
                                        permissions={UserPermission.FLAG_SYSTEM_ADMINISTRATOR}/>
                <SimpleDropdownLinkItem title="DB Query Tool" url="/system/query"
                                        permissions={UserPermission.FLAG_SYSTEM_ADMINISTRATOR}/>
                <SimpleDropdownLinkItem title="Cluster" url="/system/cluster"
                                        permissions={UserPermission.SYSTEM_CLUSTER}/>
                <!--<SimpleDropdownLinkItem title="Scripting" url="/system/scripting"
                                        permissions={UserPermission.SYSTEM_SCRIPTING}/>-->
                <SimpleDropdownLinkItem title="Tags" url="/system/tags" permissions={UserPermission.SYSTEM_TAGS}/>
                <SimpleDropdownLinkItem titleKey="Backup.plural" url="/system/backups"
                                        permissions={UserPermission.SYSTEM_BACKUPS}/>
                <SimpleDropdownLinkItem titleKey="UI.matchcheck.title" url="/system/matching"
                                        permissions={UserPermission.FLAG_SYSTEM_ADMINISTRATOR}/>
            </DropdownMenuSection>
        </DropdownMenu>
    </MenuAdmin>
{/if}
