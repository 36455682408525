<script lang="ts">
    /**
     * This component handles the creation of the analyses/analytics, sets the metadata from the received parameters.
     * It also opens the Modal to the select the necessary parameters for the analytics and reacts to closing it.
     * The data is then given to AnalyticsTool, which handles the actual presentation of the data.
     */

    import {openModal} from "svelte-modals";
    import SelectAnalyticProcedureModal from "./SelectAnalyticProcedureModal.svelte";
    import {addNotification, closeModalOnOutsideClick} from "../stores";
    import {AnalyticalParentType, AnalyticalType} from "../types/enums";
    import LoadingSkeleton from "../animations/LoadingSkeleton.svelte";
    import {fetchUtils} from "../utils/fetchUtils";
    import type {AnalyticalResponse} from "./types/analyticalResponse";
    import {onDestroy, onMount, tick} from "svelte";
    import {analyticalMetadata, analyticsToolParams, loadRecommendations, parameterStore, values} from "./stores";
    import {NotificationType} from "../types/notification";
    import AnalyticsTool from "./AnalyticsTool.svelte";
    import type {Unsubscriber} from "svelte/store";
    import {replaceUrl} from "../utils/misc";
    import {DefaultNotification} from "../types/notification.js";
    import NoAccess403 from "../organisms/NoAccess403.svelte";
    import UnderConstruction404 from "../organisms/UnderConstruction404.svelte";
    import type {AnalyticData, PossibleParameters} from "./types/analyticData";
    import type {AnalyticalValue} from "./types/analyticalValue";

    let analyticalId: string | null;
    let analyticalType: AnalyticalType;
    let parentType: AnalyticalParentType;
    let parentId: string;
    let unsubscribeParameters: Unsubscriber;
    let origin: string | null; // in case we were redirected from another page to this page, we need to get the origin

    let promise: Promise<AnalyticalResponse>;

    onMount(async () => {
        await getParameters();

        // remove the session storage in case we access the analysis check from another page to not have prefilled values
        const referrerUrl = document.referrer ? new URL(document.referrer) : null;
        if (!referrerUrl || (!referrerUrl.pathname.startsWith("/analysis") && !referrerUrl.pathname.startsWith("/analytic"))) {
            if (sessionStorage.getItem("analyticalValues") !== null) {
                sessionStorage.removeItem("analyticalValues");
            }
        }
    })

    onDestroy(() => {
        unsubscribeParameters();
    });

    async function getParameters() {
        const urlParams = new URLSearchParams(window.location.search);
        // get origin to be able to redirect back
        if (urlParams.has("origin")) {
            origin = decodeURIComponent(urlParams.get("origin") as string);
        }
        analyticalType = window.location.pathname.toUpperCase().replace('/', '') as AnalyticalType;

        // handle opening existing analytics, if already handled, we get true here
        let openExistingAnalytical: boolean = handleOpenExistingAnalyticals(urlParams);

        // handle creation of new analytics with selection of procedure and parameters
        if (!openExistingAnalytical) {
            let params = await collectExistingAnalyticalParameter(urlParams); // collect all possible parameters that can be given in the url
            let technicalName: string;

            // check if we have parent parameters to which the created analytical should be created to
            getParentParameters(urlParams);

            // check if any parameters are given to skip the modal completely or only one step of it
            if (params) {
                if (params.complete) { // all parameters are given -> we can skip the modal
                    delete params.complete; // remove unnecessary boolean
                    // give params to store -> rest is handled by subscription to store
                    $analyticsToolParams = {
                        ...params,
                        origin: origin ?? encodeURIComponent(window.parent.location.href)
                    }
                } else { // only technicalName should be given
                    // we need to check if we can skip step 1 of the Modal
                    if (params.analyticTechnicalName) {
                        technicalName = params.analyticTechnicalName as string;
                        openSelectAnalyticProcedureModal(technicalName)
                    } else {
                        openSelectAnalyticProcedureModal(); // open without any parameters default values
                    }
                }
            } else {
                openSelectAnalyticProcedureModal();
                // when the modal is finished, the data will be loaded as url params in the store -> we react to that event
            }
        }
        $analyticalMetadata = {
            analyticalId,
            analyticalType,
            parentType,
            parentId,
            technicalName: $analyticalMetadata.technicalName || null
        };
    }

    function handleOpenExistingAnalyticals(urlParams: URLSearchParams) {
        if (urlParams.has("lotanalysis")) {
            analyticalId = urlParams.get("lotanalysis");
            parentType = AnalyticalParentType.LOT;
            promise = fetchAnalytical();
            return true;
        } else if (urlParams.has("lotanalytic")) {
            analyticalId = urlParams.get("lotanalytic");
            parentType = AnalyticalParentType.LOT;
            promise = fetchAnalytical();
            return true;
        } else if (urlParams.has("popanalysis")) {
            analyticalId = urlParams.get("popanalysis");
            parentType = AnalyticalParentType.POP;
            promise = fetchAnalytical();
            return true;
        } else if (urlParams.has("popanalytic")) {
            analyticalId = urlParams.get("popanalytic");
            parentType = AnalyticalParentType.POP;
            promise = fetchAnalytical();
            return true;
        }
        return false;
    }

    function getParentParameters(urlParams: URLSearchParams) {
        if (urlParams.has("parentId") && urlParams.has("parentType")) {
            parentId = urlParams.get("parentId") as string;
            parentType = urlParams.get("parentType") as AnalyticalParentType;
        } else {
            parentId = "-1";
            parentType = AnalyticalParentType.NONE;
        }
    }

    async function collectExistingAnalyticalParameter(urlParams: URLSearchParams): Promise<Record<string, string | boolean> | null> {
        if (urlParams.has("analyticTechnicalName")) {
            let analyticTechnicalName: string = decodeURIComponent(urlParams.get("analyticTechnicalName") as string);
            let analytic: AnalyticData | undefined = await fetchUtils.get(`/api/analytic-procedures/autocomplete/svelte`)
                .then((data: AnalyticData[]) => {
                    for (let analyticData of data) {
                        if (analyticData.technicalName === analyticTechnicalName) {
                            return analyticData;
                        }
                    }
                    return undefined;
                });
            if (!analytic) { // we did not find an existing analytic procedure for the given analyticTechnicalName
                return null;
            }
            if (analytic.requiredParameters.length === 0) { // we do not need to select any parameters and are done
                return {analyticTechnicalName, complete: true}; // set complete true to indicate all parameters are given
            }
            let foundParams: Record<string, string> | null = {}; // initiate with empty object to be able to make null check
            if (analyticalType === AnalyticalType.ANALYSIS) {
                // check if all requiredParameters are given in the url -> skip Modal completely
                // otherwise only take technicalName -> skip Modal step 1
                analytic.requiredParameters.forEach(param => {
                    if (urlParams.has(param) && foundParams) { // in case we did already miss one parameter, we add no more parameters
                        foundParams[param] = decodeURIComponent(urlParams.get(param) as string);
                        return;
                    }
                    foundParams = null;
                });
            } else {
                if (urlParams.has("satisfying") && urlParams.has("selectedResult")) {
                    foundParams["satisfying"] = decodeURIComponent(urlParams.get("satisfying") as string);
                    foundParams["selectedResult"] = decodeURIComponent(urlParams.get("selectedResult") as string);
                } else { // return technicalName only
                    return {analyticTechnicalName, complete: false};
                }
                let parametersForResult: PossibleParameters = analytic.resultParameters[foundParams["selectedResult"]];
                if (parametersForResult) {
                    Object.keys(parametersForResult).forEach(param => {
                        if (urlParams.has(param) && foundParams) { // in case we did already miss one parameter, we add no more parameters
                            foundParams[param] = decodeURIComponent(urlParams.get(param) as string);
                            return;
                        }
                        foundParams = null;
                    })
                }
            }
            if (foundParams) { // if foundParams is not null, we know all requiredParams are found for both cases
                return {...foundParams, analyticTechnicalName, complete: true}
            } else {
                return {analyticTechnicalName, complete: false}; // if not, we know it is not complete and we only return the technicalName
            }
        }
        return null;
    }


    async function createAnalytical(params: Record<string, string>) {
        const url = `/api/${analyticalType.toLowerCase()}/create`; // analyticalType is set globally in onMount
        // this contains already all the parameters of the analytic to be created
        let payload: Record<string, string> = {parentType, parentId, ...params};
        let parameterizedUrl = new URL(url, document.baseURI);
        for (let paramKey in payload) {
            parameterizedUrl.searchParams.set(paramKey, payload[paramKey]);
        }
        return await fetchUtils.get(parameterizedUrl).then((data: AnalyticalResponse) => {
            // we must update this here, since from now on we only have an id for the analysis
            // -> to load recommendations, we need to set that value in the metadata
            $analyticalMetadata.analyticalId = data.analyticalId;
            analyticalId = data.analyticalId;
            return processAnalyticalData(data);
        });

    }

    async function fetchAnalytical() {
        const url: string = `/api/${parentType.toLowerCase()}${analyticalType.toLowerCase()}/${analyticalId}`;
        return await fetchUtils.get(url).then(processAnalyticalData);
    }

    async function fetchToolData(params: Record<string, string>) {
        const url = new URL("/api/analysis/tool", window.location.href);
        for (let key in params) {
            url.searchParams.set(key, params[key]);
        }
        url.searchParams.set("parentType", AnalyticalParentType.NONE);
        url.searchParams.set("parentId", "-1");
        return await fetchUtils.get(url, false).then(processAnalyticalData);
    }

    async function processAnalyticalData(data: AnalyticalResponse) {
        updateParameterStore(data);
        $analyticalMetadata.technicalName = data.analytic.technicalName;
        $analyticalMetadata = $analyticalMetadata;
        await tick();
        if (analyticalType === AnalyticalType.ANALYSIS) {
            loadRecommendations();
        }

        // in case we already gave some values and wanted to copy them for a new analysis with recommendations, we load
        // these values from the session, to double check we only do that when we have no parent given
        let sessionValues = window.sessionStorage.getItem("analyticalValues");
        if (sessionValues && (parentType === AnalyticalParentType.NONE || parentId === "-1")) {
            // we must get the possible values from the backend and only fill the values for those with
            // the same keys, so only fill those values that also existed for the other analytical
            let mergedVals = mergeStoreDataIntoBackendData(data.values, JSON.parse(sessionValues));
            values.set(mergedVals);
            window.sessionStorage.removeItem("analyticalValues");
        } else {
            // if no session values are present or we are not on a analysis check, we take the values from the response
            values.set(data.values);
        }

        showValueErrorMessages(data);

        // we build the URL params for both cases
        let searchParams: URLSearchParams = new URLSearchParams();
        // in case we have a parent, we redirect to something like /analysis?lotanalysis=123
        if (parentType !== AnalyticalParentType.NONE) {
            searchParams.set(`${parentType.toLowerCase()}${analyticalType.toLowerCase()}`, data.analyticalId);
            if (origin) { // if we have a origin, we add it
                searchParams.set("origin", origin || "");
            }
        } else {
            if ($analyticalMetadata.technicalName) {
                // we add the technicalName and the parameters we chose -> when reloading, we do not have to select again
                searchParams.set("analyticTechnicalName", $analyticalMetadata.technicalName);
                let paramsForTechnicalName = $parameterStore.parameters || [];
                for (let key in paramsForTechnicalName) {
                    if (paramsForTechnicalName[key]) {
                        searchParams.set(key, paramsForTechnicalName[key])
                    }
                }

            }
        }
        replaceUrl(`/${analyticalType.toLowerCase()}`, window, searchParams);
        return data;
    }

    const showValueErrorMessages = (data: AnalyticalResponse) => {
        (data?.errorMessages || []).forEach((message: string) => {
            const notification = DefaultNotification(message, NotificationType.ERROR, false);
            return addNotification(notification);
        });
    }

    /**
     * Opens the Select Analytic Procedure Modal.
     *
     * @param {string | null} technicalName - The technical name of the analytic procedure. Defaults to null.
     * If this is given, we skip step 1 of the modal.
     */
    function openSelectAnalyticProcedureModal(technicalName: string | null = null) {
        // somehow this produced materialNeed = undefined several times, therefore, we need !! to make sure it is boolean
        let materialNeed = !!(analyticalType === AnalyticalType.ANALYTIC);
        $closeModalOnOutsideClick = false;
        openModal(SelectAnalyticProcedureModal, {
            materialNeed,
            technicalName,
        });
    }

    function updateParameterStore(analyticalData: AnalyticalResponse) {
        let dirty = false;
        for (let i = 0; i < analyticalData.parameters?.length; i++) {
            let parameter = analyticalData.parameters[i];
            if (!$parameterStore.parameters[parameter.name]) {
                $parameterStore.parameters[parameter.name] = parameter.value;
                dirty = true;
            }
        }
        if (dirty) {
            $parameterStore = $parameterStore;
        }
    }

    function mergeStoreDataIntoBackendData(backendData: AnalyticalValue[], sessionData: AnalyticalValue[]) {
        let result = [];
        for (let backendAnalyticalValue of backendData) {
            let sessionValue = sessionData.find(sessionAnalyticalValue => sessionAnalyticalValue.key === backendAnalyticalValue.key);
            // if we find a value for this key in the session, add that. if that value is null or undefined,
            // we add the value from the backend
            result.push(sessionValue ?? backendAnalyticalValue);
        }
        return result;
    }

    /**
     * When the Modal on which we select the procedure and the parameters is closed, the selected data is given in the params
     */
    analyticsToolParams.subscribe((params: Record<string, string>) => {
        if (!params) {
            return;
        }
        if (params['analyticTechnicalName']) {
            $analyticalMetadata.technicalName = params["analyticTechnicalName"];
        }
        if (parentType && parentType !== AnalyticalParentType.NONE && parentId && parentId !== "-1") {
            promise = createAnalytical(params);
        } else {
            promise = fetchToolData(params);
        }
    })


</script>

{#await promise}
    <LoadingSkeleton repeat={4}/>
{:then analyticalData}
    <AnalyticsTool {...analyticalData}
                   on:reload={() => promise = fetchAnalytical()}
                   on:changeProcedure={() => openSelectAnalyticProcedureModal()}/>
{:catch error}
    {#if error.code === 403}
        <NoAccess403 description={error.message}/>
    {:else}
        <UnderConstruction404 description={error.message}/>
    {/if}
{/await}
