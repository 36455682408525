import {derived, writable} from "svelte/store";

export const locale = writable("de");
let translation = null;

export async function loadTranslation(devMode: boolean = false) {
    // this is necessary to be able to resolve the translations from storybook
    // for production mode, the links are redirected correctly by vite, however, for dev mode, we fetch hardcoded via localhost
    let url = devMode ? 'http://localhost:9000/api/i18n' : '/api/i18n';
    const response = await fetch(url);
    let data = await response.json();
    translation = data.data;
}

export function requireI18n(callback) {
    if (translation === null) {
        loadTranslation().then(r => callback());
    } else {
        callback()
    }
}

export function translate(locale, key, vars) {
    // Let's throw some errors if we're trying to use keys/locales that don't exist.
    // We could improve this by using Typescript and/or fallback values.
    if (!key) throw new Error("no key provided to $t()");
    if (!locale) throw new Error(`no translation for key "${key}"`);

    // Grab the translation from the translations object.
    if (translation == null) {
        return key;
    }
    let text = translation[key];

    // if (!text) throw new Error(`no translation found for ${locale}.${key}`);
    if (!text) return key;

    // Replace any passed in variables in the translation string.
    Object.keys(vars).map((k) => {
        const regex = new RegExp(`{{${k}}}`, "g");
        text = text.replace(regex, vars[k]);
    });

    return text;
}

export const t = derived(locale, ($locale) => (key: any, vars = {}) =>
    translate($locale, key, vars)
);
