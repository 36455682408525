<script lang="ts">
    import NumberInput from "../atoms/NumberInput.svelte";
    import TextInput from "../atoms/TextInput.svelte";
    import Tooltip from "../atoms/Tooltip.svelte";
    import {inputFieldFormats, markFieldLabelRequired} from "../utils/formatters";
    import {
        DefaultInputFieldProps,
        DefaultLabelFontProps,
        type FontProps,
        getFontPropsVarString
    } from "../types/fontProps";
    import {getContext, hasContext} from "svelte";
    import {AnimatePresence, AnimateSharedLayout, Motion} from "svelte-motion";
    import {openCloseVariants} from "../utils/svelteMotionUtils";
    import {InputIcons} from "../types/enums";
    import {
        getExactPathErrorMessage,
        getPathErrorMessage,
        isExactPathInErrorList,
        isPathInErrorList
    } from "../utils/formValidation";
    import type {Writable} from "svelte/store";
    import type {FormError} from "../types/formError";

    export let useMaterialDesign: boolean = hasContext('useMaterialDesign') ? getContext('useMaterialDesign') : false;

    export let id: string = "input-id" + Math.random().toString(16).slice(2);
    export let addonText: string = '';
    export let value: string | number | null | undefined = '';
    export let label: string = '';

    /**
     * This can only be specified, if we do not use the material design, as it would be hard to show the
     * info icon with the animation.
     */
    export let ttText: string = '';
    export let format = inputFieldFormats.NORMAL;
    export let placeholder: string | Number = "";
    export let isNumeric: boolean = false;

    /**
     * Must be used with isNumeric, or is useless otherwise. Adds the unit directly in the input field
     */
    export let unit: string = "";
    export let forcedDecimalPlaces: number = 0;
    export let borderless: boolean = false;
    export let fontProps: FontProps = DefaultInputFieldProps();
    export let errorHighlight: boolean = false;
    export let headerFontProps: FontProps = DefaultLabelFontProps();
    export let readOnly: boolean = false;
    export let materialWidth: string = "100%";
    export let required: boolean = false;
    export let containerWidth: string = "100%";
    export let icon: InputIcons = InputIcons.NONE;
    export let isTooltipHtml: boolean = false;
    export let showTooltipByCreate: boolean = false;
    /* Text on the left side of the input field */
    export let prependText: string = "";


    /* Handling of the error message indication with a context store that is set on the parent including the form */
    export let errorPath: string = '';
    let formErrors: Writable<FormError[]> | null = hasContext('formErrors') ? getContext('formErrors') : null;
    let error: string;
    $: if (errorPath && errorPath !== '' && $formErrors !== null) {
        let paths = errorPath.includes('||') ? errorPath.split('||') : [errorPath];
        error = "";
        paths.forEach(path => {
            if (path.includes('.*')) {
                error += isPathInErrorList($formErrors, path) ? getPathErrorMessage($formErrors, path) : '';
            } else {
                error += isExactPathInErrorList($formErrors, path) ? getExactPathErrorMessage($formErrors, path) : '';
            }

        })
    }


    $: if (required && label) {
        label = markFieldLabelRequired(label);
    }
    $: if (required && !label && placeholder) {
        placeholder = markFieldLabelRequired(placeholder);
    }

</script>

<AnimateSharedLayout>
    <div class="input-field-error-message-container" style="--container-width: {containerWidth}">
        <div class="container-label-info-input" class:fullWidth={format === inputFieldFormats.FULL}>
            {#if label && !useMaterialDesign}
                <div>
                    <span class="input-label-variable"
                          style="{getFontPropsVarString(headerFontProps)}">{label}</span>
                    {#if ttText !== undefined && ttText !== ""}
                        <div>
                            <Tooltip msg={ttText} isHtml={isTooltipHtml} showOnCreate={showTooltipByCreate}/>
                        </div>
                    {/if}
                </div>
            {/if}
            <div class="container-input" class:fullWidth={format === inputFieldFormats.FULL}>
                {#if isNumeric}
                    <NumberInput {id} bind:value {format} {placeholder} {unit} {forcedDecimalPlaces} {borderless}
                                 {fontProps} {readOnly} {materialWidth} {errorHighlight} materialLabel={label}
                                 on:change on:focusout on:input on:keydown/>
                {:else}
                    <TextInput {id} bind:value {format} {placeholder} {borderless} {fontProps} {readOnly}
                               {materialWidth} {errorHighlight} {icon} {prependText} materialLabel={label}
                               on:change on:focusout on:input on:keydown/>
                {/if}
                {#if addonText !== undefined && addonText.length > 0}
                    <div class="textfield-addon">
                        <span class="">{addonText}</span>
                    </div>
                {/if}
            </div>
        </div>
        <AnimatePresence list={error && error !== '' ? [{ key: 1 }] : []}>
            <Motion let:motion={collapse} initial="collapsed" animate="open" exit="collapsed"
                    variants={openCloseVariants}>
                <span class="form-field-error" use:collapse>{@html error}</span>
            </Motion>
        </AnimatePresence>

    </div>
</AnimateSharedLayout>

<style lang="scss">
  @import "../../styles/inputFields";
  @import "../../styles/icons";


  .container-label-info-input {
    @include flex-col(0.375rem, $alignment: flex-start, $justify: flex-start);
    width: var(--container-width);

    & > div:first-child:not(.container-input) {
      @include flex-row(0.25rem, $alignment: flex-end);
      position: relative;
    }
  }

  .container-input {
    @include flex-row($justify: flex-end);
    width: var(--container-width);
    flex-wrap: nowrap;
  }

  .textfield-addon {
    background-color: $bluish-grey-200;
    padding: 0 0.875rem;
    min-height: inherit; // needed because otherwise the unit addon is not as high as the input field
    max-width: max-content; // this way, the units like EUR/T are not seperated in two rows / do not line-break
    margin-left: 2px;
    @include flex-row(); // just to center it vertically and horizontally
    height: 32px;
    width: 32px; // set the width fixed

    & > span {
      @include roboto-font(13px, 400, 0.875rem, black);
      white-space: nowrap;
    }
  }


</style>
