<script lang="ts">
    export let borderRadius: string = "0.25rem";
    export let boxShadow: string = "0 0 6px rgba(0, 0, 0, 0.25)";
    export let width: string = "100%";
    export let height: string = "initial";
    export let padding: string = "0";

</script>

<div class="card" style={`--card-border-radius: ${borderRadius}; --card-box-shadow: ${boxShadow}; --card-width: ${width};
                          --card-height: ${height}; --card-padding: ${padding}`}>
    <slot/>
</div>

<style lang="scss">
  @import "../../styles/colors";

  .card {
    border: 1px solid $grey-600;
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-box-shadow);
    display: block;
    width: var(--card-width);
    height: var(--card-height, initial);
    padding: var(--card-padding, 0);
  }
</style>
