<script lang="ts">
    /* https://svelte-modals.mattjennings.io/api */
    import {closeModal} from 'svelte-modals';
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import Icon from "../atoms/Icon.svelte";
    import {t} from "../../i18n/i18n";
    import PrimaryButton, {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import {fly} from 'svelte/transition';
    import {ButtonIcons} from "../types/enums";
    import {fetchUtils} from "../utils/fetchUtils";
    import {NotificationType} from "../types/notification";
    import {addNotification} from "../stores";
    import type {DealPartnerConnect} from "../types/dealPartnerConnect";
    import {DefaultDealPartnerConnect} from "../types/dealPartnerConnect";

    import ChipList from "./ChipList.svelte";
    import {setContext} from "svelte";
    import iconClose from "@/icons/icon_close.svg";
    import {DefaultRobotoFontProps} from "../types/fontProps";
    import type {Lot} from "../map/types/lot";
    import DealPartnerConnectForm from "./DealPartnerConnectForm.svelte";


    export let lotId: string;
    export let dealPartnerConnect: DealPartnerConnect = DefaultDealPartnerConnect();
    export let update: (lot: Lot) => void;
    export let isOpen;
    export let showCloseIcon = false;
    export let padding = "1.75rem 1.875rem 1.125rem 2.125rem";
    export let maxWidth = "650px";

    setContext('useMaterialDesign', true);


    function connectPartners() {
        fetchUtils.post(`/api/lot/${lotId}/informPartners`, dealPartnerConnect)
            .then(data => {
                let notification = {message: $t('UI.modal.connectPartners.notification.success'), type: NotificationType.SUCCESS, dismissible: false};
                addNotification(notification);
                if (update) {
                    update(data);
                }
                closeModal();
            })
            .catch(fetchUtils.catchErrorAndShowNotification());
    }

</script>

{#if isOpen}
    <div role="dialog" class="modal" in:fly="{{ y: 1000, duration: 400 }}" out:fly={{y: 1000, duration: 400}}>
        <div class="contents" style="--content-padding: {padding}; --max-width: {maxWidth}">
            {#if showCloseIcon}
                <div class="cancel-btn" on:click={() => {document.body.style.overflow = 'auto'; closeModal();}}>
                    <Icon src={iconClose} size={24}/>
                </div>
            {/if}
            <h1 class="green-title">{$t('UI.modal.connectPartners.title')}</h1>
            <div class="actual-content">
                <ChipList bind:list={dealPartnerConnect.generalEmails} placeholder={$t('UI.modal.connectPartners.generalMails.ph')}
                          chipFontProps={DefaultRobotoFontProps('0.75rem', '0.625rem', '#9d9d9d', 'black')}/>
                <DealPartnerConnectForm bind:data={dealPartnerConnect.offerer} title={$t('UI.modal.connectPartners.offerer.title')}/>
                <DealPartnerConnectForm bind:data={dealPartnerConnect.optimizer} title={$t('UI.modal.connectPartners.optimizer.title')}/>
            </div>

            <div class="actions">
                <SecondaryButton label={$t('UI.button.cancel')} color={SecondaryBtnColors.GREY} sizeAdaptingToText on:click={closeModal}/>
                <PrimaryButton label={$t('connectPartners.dialog.title')} color={PrimaryBtnColors.GREEN} sizeAdaptingToText on:click={connectPartners}
                               leftIcon={ButtonIcons.CHECKMARK_BOLD} whiteIcon/>
            </div>
        </div>
    </div>
{/if}

<style lang="scss">
  @import "../../styles/modals"; // also includes global.scss

  h1.green-title {
    color: $primaryColor;
    margin-bottom: 0;
  }

  .contents {
    max-width: var(--max-width);
    min-width: 620px;
    width: 620px;
    max-height: 816px;
  }

  .actual-content {
    @include flex-col(1.375rem, $alignment: flex-start, $justify: flex-start);
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    padding-top: 0.75rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem; // then we can scroll a bit higher

    &::-webkit-scrollbar {
      width: 20px;
      margin: 2px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px $bluish-grey-200;
      border: solid 8px transparent;
      border-radius: 20px;
      background-clip: padding-box;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px $primaryGreen;
      border: solid 8px transparent;
      border-radius: 20px;
      background-clip: padding-box;
    }
  }
</style>
