<script lang="ts">
    import {t} from "../../../i18n/i18n"
    import type {Lot} from "../lot";
    import {createEventDispatcher, getContext} from "svelte";

    import Salutation from "../../atoms/Salutation.svelte";
    import InfoCard from "../../organisms/InfoCard.svelte";
    import ProjectInformationGrid from "../../project/ProjectInformationGrid.svelte";
    import TextArea from "../../atoms/TextArea.svelte";
    import FileUpload from "../../organisms/FileUpload.svelte";
    import {CalcMethod, OfferViewMode} from "../../types/enums";
    import OptimizerDecisionButtons from "../../bid/OptimizerDecisionButtons.svelte";
    import OfferInfo from "../../bid/OfferInfo.svelte";
    import PrimaryButton, {PrimaryBtnColors} from "../../atoms/PrimaryButton.svelte";
    import RejectOfferComponent from "../RejectOfferComponent.svelte";
    import {userInfo, userIsLoggedIn} from "../../stores";
    import AcceptOfferComponent from "../../bid/AcceptOfferComponent.svelte";
    import PublicPageHeader from "../../molecules/PublicPageHeader.svelte";
    import PublicPageFooter from "../../molecules/PublicPageFooter.svelte";
    import Basic from "../../templates/Basic.svelte";
    import {fetchRequestTenantLogo} from "../../corporateDesign/corporateDesignUtils";
    import OfferFactsForm from "../../bid/OfferFactsForm.svelte";
    import {type Offer, OfferState} from "../../bid/types/offer";
    import type {Writable} from "svelte/store";
    import type {ContactData} from "../../types/contactData";
    import type {UserInfo} from "../../types/userInfo";
    import type {PubPageParams} from "../../bid/types/pubPageParams";
    import {isEmptyOffer, loadOfferAttachments} from "../../bid/offerUtils";
    import {type RequestDto, RequestType} from "../../bid/types/requestDto";
    import ProjectNavigationButtons from "../../molecules/ProjectNavigationButtons.svelte";
    import {NextButtonWantedObject} from "../../bid/types/nextButtonDto";

    const lot: Writable<Lot> = getContext("lot");
    const request: Writable<RequestDto | null> = getContext("request");
    const offerMaterial: Writable<Offer> = getContext("offerMaterial");
    const offerMaterialWithTransport: Writable<Offer> = getContext("offerMaterialWithTransport");
    const urlParams: PubPageParams | null = getContext("urlParams") || null;

    export let optimizerContactData: ContactData;

    const dispatch = createEventDispatcher();

    let offerViewMode: OfferViewMode = OfferViewMode.VIEW_OFFER;


    let attachmentsOffer: any[] = [];
    let attachmentsUploadUrl: string;
    let attachmentsDeletedUrl: string;
    let attachmentLinkUrl: string;
    let linkAttachments;
    let currentUserInfo: UserInfo;
    let isDifferentTenant: boolean = false;
    let logo: string = "";
    let emptyOffers: boolean;


    $: {
        attachmentsUploadUrl = `/api/request/${urlParams?.requestPublicId}/requested/${urlParams?.requestedEntityType}/${urlParams?.requestedEntityId}/user/${urlParams?.offerUserPublicId}/attachment/new`;
        if ($offerMaterial.publicId || $offerMaterialWithTransport.publicId) {
            attachmentsUploadUrl = `/api/offer/${$offerMaterial?.publicId || $offerMaterialWithTransport?.publicId}/attachment/new`;
            attachmentsDeletedUrl = `/api/offer/${$offerMaterial?.publicId || $offerMaterialWithTransport?.publicId}/attachment/delete`;
            attachmentLinkUrl = `/api/offer/${$offerMaterial?.publicId || $offerMaterialWithTransport?.publicId}/attachment/link`
            // at the moment, we only need to load attachments of one offer, since we sync them for both offers, will be adapted later
            loadOfferAttachments($offerMaterial?.publicId || $offerMaterialWithTransport?.publicId, urlParams)
                .then(data => {
                    attachmentsOffer = data;
                });
        }
    }


    function linkAttachmentsByAccept() {
        linkAttachments();
    }

    async function fetchLogo() {
        logo = await fetchRequestTenantLogo(urlParams?.requestPublicId) ?? "";
    }

    function reloadAttachments(data: any) {
        attachmentsOffer = data;
        if (!$offerMaterial.publicId && !$offerMaterialWithTransport.publicId) {
            // after first upload of an offer attachment we have to fetch the request data again to get the created draft offer
            dispatch("offerChanged")
        }
    }

    function checkEmptyOffers(): boolean {
        emptyOffers = (!$offerMaterial && !$offerMaterialWithTransport)
        || !!(isEmptyOffer($offerMaterial, RequestType.MATERIAL) && isEmptyOffer($offerMaterialWithTransport, RequestType.MATERIAL_WITH_TRANSPORT)) || $offerMaterial.offerState === OfferState.DRAFT;
    }

    userInfo.subscribe(value => currentUserInfo = value);

    const offerChanged = () => dispatch("offerChanged");
    const viewOffer = () => offerViewMode = OfferViewMode.VIEW_OFFER;
    const viewEditOffer = () => offerViewMode = OfferViewMode.EDIT_OFFER;
    const viewAcceptOffer = () => offerViewMode = OfferViewMode.ACCEPT_OFFER;
    const viewRejectOffer = () => offerViewMode = OfferViewMode.REJECT_OFFER;

    $: if (currentUserInfo?.tenantName && $lot?.tenantName) {
        isDifferentTenant = currentUserInfo.tenantName !== $lot.tenantName;
    }

    $: if ($lot?.isSaasInternalLot && urlParams?.requestPublicId) {
        fetchLogo();
    }

    $: $offerMaterial, $offerMaterialWithTransport, checkEmptyOffers();

    function getFilePrefix(): string {
        let filePrefix = $t('UI.attachments.optimizer.prefix');
        if (currentUserInfo.tenantName) {
            filePrefix += currentUserInfo.tenantName + '_' + currentUserInfo.userLogin + '-';
        } else {
            filePrefix += optimizerContactData.fullName?.replace(/\s/g, "_") + "-";
        }
        return filePrefix;
    }

</script>

{#if !$userIsLoggedIn || isDifferentTenant}
    <PublicPageHeader requestPublicId={urlParams?.requestPublicId}/>
{/if}

<Basic showStandardHeaderFooter={$userIsLoggedIn && !isDifferentTenant}>
    {#if $lot.isSaasInternalLot && !isDifferentTenant && $userIsLoggedIn && logo}
        <div class="pp-logo-top">
            <img alt="Logo" src={logo}/>
        </div>
    {/if}
    <Salutation name={optimizerContactData.fullName} address={$lot.address.zip + " " + $lot.address.city}
                saasOwnerName={$lot.ownerName} tenantName={$lot.tenantName} showSaasData/>
    <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12">
            <InfoCard/>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12">
            <div class="container-project-information">
                <ProjectInformationGrid title={$t('UI.project.information')} {...$lot} anonymizeAddress/>
                <TextArea title={$t('UI.materialDetailsComment.header')} text={$lot.lotRemarks}
                          placeholder={$t('UI.remarks.noRemarks')} readonly/>
                <TextArea title={$t('UI.projectDetailsComment.header')} text={$lot.projectRemarks || ''}
                          placeholder={$t('UI.remarks.noRemarks')} readonly isHtml/>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 1.875rem">
        <div class="col-lg-12 col-sm-12">
            <FileUpload uploadUrl={attachmentsUploadUrl} deleteUrl={attachmentsDeletedUrl}
                        parentPublicId={$offerMaterial.publicId} linkUrl={attachmentLinkUrl}
                        filePrefix={getFilePrefix()}
                        visibleToCustomer={0} visibleToPartner={1} bind:eventByAccept={linkAttachments}
                        updateFun={() => loadOfferAttachments($offerMaterial?.publicId || $offerMaterialWithTransport?.publicId, urlParams).then(data => reloadAttachments(data))}
                        attachments={attachmentsOffer}
                        canNotDelete={$offerMaterial?.offerState !== OfferState.DRAFT || $offerMaterialWithTransport?.offerState !== OfferState.DRAFT}/>
        </div>
    </div>

    {#if offerViewMode === OfferViewMode.VIEW_OFFER}
        {#if !emptyOffers}
            <div class="col-lg-12 col-sm-12 columns" style="margin-top: 1.875rem">
                <OfferInfo onOptimizerPage/>
                <div class="align-right">
                    <PrimaryButton
                            label={$lot.calcMethod === CalcMethod.REQUEST_OFFER ? $t("UI.optimizerPage.priceOffer.editor.title.edit") : $t("UI.optimizerPage.counterOffer.editor.title.edit")}
                            color={PrimaryBtnColors.ORANGE}
                            on:click={viewEditOffer}/>
                </div>
            </div>
        {:else}
            <OptimizerDecisionButtons calcMethod={$lot.calcMethod}
                                      on:acceptOffer={viewAcceptOffer}
                                      on:declineOffer={viewRejectOffer}
                                      on:editOffer={viewEditOffer}/>
        {/if}
    {/if}
    {#if offerViewMode === OfferViewMode.ACCEPT_OFFER}
        <AcceptOfferComponent hideCheckbox isExternalUser
                              on:cancel={viewOffer}
                              on:offerChanged={offerChanged}
                              on:linkAttachmentsByAccept={linkAttachmentsByAccept}/>
    {/if}

    {#if offerViewMode === OfferViewMode.EDIT_OFFER}
        <OfferFactsForm hideAgbCheckbox
                        on:cancelEdit={viewOffer}
                        on:linkAttachmentsByAccept={linkAttachmentsByAccept}
                        on:offerChanged={() => {offerChanged(); viewOffer();}}/>
    {/if}

    {#if offerViewMode === OfferViewMode.REJECT_OFFER}
        <RejectOfferComponent pub on:cancel={viewOffer} on:offerChanged={offerChanged}/>
    {/if}

    {#if $userIsLoggedIn}
        <div class="row">
            <div class="col-lg-12 col-sm-12">
                <ProjectNavigationButtons currentId={$request.publicId} wantedObject={NextButtonWantedObject.REQUEST}
                                          returnUrl="/tenantlots/bid" isSaas/>
            </div>
        </div>
    {/if}
</Basic>

{#if !$userIsLoggedIn || isDifferentTenant}
    <PublicPageFooter/>
{/if}


<style lang="scss">
  @import "../../../styles/pubPage";

  .columns {
    @include flex-col(1.875rem, $alignment: flex-end);
    width: 100%;
  }
</style>