<script lang="ts">
    export let label: string;
    export let iconSrc: string | null = null;
</script>


<li class="action-item" title={label} on:click|stopPropagation>
    {#if iconSrc}
        <img src={iconSrc} alt="action"/>
    {/if}
    <span>{label}</span>
</li>

<style lang="scss">
  @import "../../styles/global";

  li.action-item {
    @include flex-row(0.625rem, $justify: flex-start);
    @include roboto-font(18px, 400, 0.75rem, black);
    list-style: none;
    padding: 0.625rem;

    &:hover {
      font-weight: 500;
    }

    & img {
      width: 20px;
      height: 20px;
      opacity: 0.65;
    }

    &:hover img {
      opacity: 1;
    }

  }

</style>