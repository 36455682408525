<script lang="ts">
    import type {MaterialProperty, MaterialPropertyListElement} from "./types/materialProperty";
    import {materialPropertyListElementSchema} from "./types/materialProperty";
    import {createEventDispatcher, getContext} from "svelte";
    import MaterialPropertySelection from "./MaterialPropertySelection.svelte";
    import PrimaryButton from "../atoms/PrimaryButton.svelte";
    import InputField from "../molecules/InputField.svelte";
    import type {FontProps} from "../types/fontProps";
    import {DefaultInputFieldProps, DefaultRobotoFontProps} from "../types/fontProps";
    import {inputFieldFormats} from "../utils/formatters";
    import {t} from "../../i18n/i18n";
    import SecondaryButton from "../atoms/SecondaryButton.svelte";
    import Tooltip from "../atoms/Tooltip.svelte";
    import type {Writable} from "svelte/store";
    import {getFormErrors} from "../utils/formValidation";
    import {TooltipPlacements} from "../types/enums";

    const dispatch = createEventDispatcher();
    const fontProps: FontProps = DefaultInputFieldProps();
    const headerFontProps: FontProps = DefaultRobotoFontProps('15px', '0.875rem');
    const MATERIAL_PROPERTY_LIST_ELEMENT_FORM_ERROR_PATH_PREFIX = 'materialPropertyListElement.'

    const formErrors: Writable<{ path: string, message: string }[]> = getContext("formErrors");
    const properties: Writable<Map<string, MaterialProperty>> = getContext('materialProperties');// if context is not working, give it as argument
    const materialPropertyParents: Writable<Map<string, string[]>> = getContext('materialPropertyParents');

    export let propertyElement: MaterialPropertyListElement;
    export let selectedProperty: MaterialProperty;

    let mappedProperties = [];

    $: if (propertyElement) {
        mappedProperties = []
        propertyElement.propertyChildIds.forEach((propertyId: string) => mappedProperties.push($properties.get(propertyId)))
    }


    function addPropertyToElement() {
        // prevent error in list of properties to disable list of property with id new from store
        const propertyAlreadyAdded = propertyElement.propertyChildIds.indexOf(selectedProperty.id) > -1
        if (selectedProperty.id !== "new" && !propertyAlreadyAdded) {
            propertyElement.propertyChildIds.push(selectedProperty.id)
            propertyElement = propertyElement;
        }
        $materialPropertyParents = $materialPropertyParents;
        selectedProperty = null; // reset value to empty the input field
    }

    export async function saveMaterialPropertyListElement(propertyElement: MaterialPropertyListElement) {
        try {
            $formErrors = [];
            let validatedPropertyListElement: MaterialPropertyListElement;
            validatedPropertyListElement = await materialPropertyListElementSchema.validate(propertyElement, {
                stripUnknown: false,
                abortEarly: false
            })
            return validatedPropertyListElement;
        } catch (error) {
            let err = getFormErrors(error, MATERIAL_PROPERTY_LIST_ELEMENT_FORM_ERROR_PATH_PREFIX);
            console.log('ValidationError: ');
            console.dir(err);
            $formErrors = err;
            return null;
        }

    }

    function addCreatePropertyBreadcrumb() {
        dispatch('addCreatePropertyBreadcrumb');
    }

    function editMaterialProperty(materialProperty: MaterialProperty) {
        dispatch('editMaterialProperty', {materialProperty: materialProperty});
    }

    function removePropertyFromListElement(propertyId: string) {
        const indexOfProp = propertyElement.propertyChildIds.indexOf(propertyId)
        if (indexOfProp > -1) {
            propertyElement.propertyChildIds.splice(indexOfProp, 1);
            let currentParents = $materialPropertyParents.get(propertyId);
            let idxOfParent = currentParents.indexOf(propertyElement.propertyId);
            if (idxOfParent > -1) {
                currentParents = currentParents.splice(idxOfParent, 1)
            }
            $materialPropertyParents.set(propertyId, currentParents);
            $materialPropertyParents = $materialPropertyParents;
        }
        propertyElement = propertyElement;
    }

    /**
     * Constructs tooltip content for a property element that contains list elements.
     * Content is built according to the number of list elements in the property.
     * The options are: one, two or more list elements.
     * We ignore 0, because we do not show the tooltip anyways
     * @param {MaterialProperty} property - The property for which the tooltip content is to be built.
     * @returns {string} The tooltip content string.
     */
    function buildTooltipContentForPropertyElementWithListElements(property: MaterialProperty) {
        let res;
        switch (property.propertyListElementIds.length) {
            case 1:
                res = $t('UI.mmpv.materialPropertyListElement.addedListElement.one.tt',
                    {name: $properties.get(property.id).propertyListElements[0].name});
                break;
            case 2:
                res = $t('UI.mmpv.materialPropertyListElement.addedListElement.two.tt', {
                    name: $properties.get(property.id).propertyListElements[0].name,
                    name2: $properties.get(property.id).propertyListElements[1].name
                });
                break;
            default:
                res = $t('UI.mmpv.materialPropertyListElement.addedListElement.default.tt', {
                    name: $properties.get(property.id).propertyListElements[0].name,
                    name2: $properties.get(property.id).propertyListElements[1].name
                });
        }
        return res;
    }


</script>

<div class="material-property-element-list-edit-container">
    <div style="grid-area: name">
        <InputField label={$t('UI.mmpv.materialProperty.createListElement.ph')} bind:value={propertyElement.name}
                    format={inputFieldFormats.FULL} {fontProps} {headerFontProps}
                    errorPath={MATERIAL_PROPERTY_LIST_ELEMENT_FORM_ERROR_PATH_PREFIX + "name"}/>
    </div>
    <div style="grid-area: priority">
        <InputField label={$t('UI.mmpv.materialProperty.createListElement.priority.label')}
                    bind:value={propertyElement.priority} format={inputFieldFormats.FULL}
                    {fontProps} {headerFontProps}
                    errorPath={MATERIAL_PROPERTY_LIST_ELEMENT_FORM_ERROR_PATH_PREFIX + "priority"}/>
    </div>
    <span class="modal-subheader">{$t('UI.mmpv.materialPropertyListElement.values.subheader')}</span>
    <div class="property-list-element-values-container">
        <span style="grid-area: info">{@html $t('UI.mmpv.propertyListElement.values.hint', {
            name: propertyElement.name
        })}
        </span>
        <div style="grid-area: list">
            <div class="add-property-element">
                <div>
                    <MaterialPropertySelection bind:materialProperty={selectedProperty}
                                               bind:alreadyMappedProperties={mappedProperties}
                                               propertyListElement={{...propertyElement}}/>
                </div>
                <PrimaryButton label={$t('UI.mmpv.property.addToMaterial')} on:click={addPropertyToElement}
                               sizeAdaptingToText/>
            </div>
            {#each propertyElement.propertyChildIds.map(propertyId => $properties.get(propertyId)).sort((a, b) => a.priority - b.priority) as property}
                <div class="material-property-element">
                    <div>
                        <span on:click={() => editMaterialProperty(property)}>{property.name}</span>
                        {#if property.propertyListElements.length > 0}
                            <div class="info-icon">
                                <Tooltip maxWidthTtip={370}
                                         ttipIconHeight="20px" ttipIconWidth="20px"
                                         placementTtip={TooltipPlacements.RIGHT}
                                         msg={"<div style='line-height: 20px;'> " + buildTooltipContentForPropertyElementWithListElements(property)  + "</div>"}
                                         isHtml/>
                            </div>
                        {/if}
                    </div>
                    <div>
                        <button class="property-delete-btn" title={$t('UI.delete')}
                                on:click={ ()=> removePropertyFromListElement(property.id)}>
                        </button>
                    </div>
                </div>
            {/each}
        </div>
    </div>

</div>
<div class="create-new-property-element">
    <SecondaryButton label={$t('UI.mmpv.materialProperty.createNew')} on:click={addCreatePropertyBreadcrumb}
                     sizeAdaptingToText/>
</div>
<style lang="scss">
  @import "../../styles/global";

  .material-property-element-list-edit-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1.875rem;
    grid-row-gap: 1.25rem;
    grid-template-areas:
    "name name name priority"
    "subheader subheader subheader subheader"
    "propertyElements propertyElements propertyElements propertyElements";
    width: 100%;
    align-items: baseline;

    & > span:first-child {
      @include roboto-font(15px, 400, 0.75rem, $primaryGreen);
    }

    & > span:nth-of-type(2) {
      @include roboto-font(15px, 400, 0.75rem, black);
    }
  }

  span.modal-subheader {
    @include roboto-font(21px, 500, 0.875rem, $primaryBlue);
    grid-area: subheader;
  }

  .property-list-element-values-container {
    display: grid;
    grid-template-areas: "info info info info"
                        "list list list list";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    grid-area: propertyElements;

    & > span {
      @include roboto-font(15px, 400, 0.75rem, black);
    }
  }

  .create-new-property-element {
    @include flex-row($justify: flex-start);
    width: 100%;

  }

  .buttons-row {
    width: 100%;
    @include flex-row(0.875rem, $justify: flex-end);
  }

  div.material-property-element {
    @include flex-row(0.875rem, $justify: flex-start, $alignment: flex-end);
    width: 100%;
    border-bottom: 1px solid $grey-600;
    padding: 0.75rem 0;

    & > div {
      width: 100%;

      &:first-child {
        @include flex-row(0.25rem, $justify: flex-start);
        @include roboto-font(10px, 400, 0.75rem, $primaryGreen)
      }

      &:last-child {
        @include flex-row(0.5rem, $justify: flex-end);
        margin-right: 0.875rem;
      }

      & > span {
        cursor: pointer;
      }
    }

    & .property-delete-btn {
      background: url("@/icons/icon_close.svg") no-repeat center;
      border: none;
      width: 24px;
      height: 24px;
      visibility: hidden;
      cursor: pointer;
    }

    &:hover {
      & .property-delete-btn {
        visibility: visible;
      }
    }
  }

  div.add-property-element {
    @include flex-row(0.875rem, $justify: flex-start, $alignment: flex-end);
    width: 100%;
    padding-top: 1.25rem;

    & > div:first-child {
      min-width: 50%;
      flex-basis: 100%;
    }
  }

  .material-property-element-list-edit-container :global(.sv-control) {
    height: 32px; // override sv control to have the same height as the usual input fields
  }

  .material-property-element-list-edit-container :global(.checkbox-container) {
    justify-content: flex-start !important; // override the checkbox to be at the beginning of the row
  }

</style>
