<script lang="ts">

    import type {Writable} from "svelte/store";
    import {getContext} from "svelte";

    const openDropdown: Writable<string> = getContext('openDropdown');
    const SYSTEM_TITLE_FOR_STORE: string = 'system-dropdown';


    // determines if the dropdown menu is opened, if a dropdown menu exists
    let active: boolean = false;
    $:$openDropdown, active = $openDropdown === SYSTEM_TITLE_FOR_STORE;

    function handleDropdownClick() {
        if (!active) {
            $openDropdown = SYSTEM_TITLE_FOR_STORE;
        } else {
            $openDropdown = "";
            active = false;
        }
    }

</script>

<div class="menu__system nav_link dropdown__btn" aria-haspopup="true"
     aria-expanded={active? "true" : "false"} aria-label={SYSTEM_TITLE_FOR_STORE}
     on:click|stopPropagation={handleDropdownClick}>
    <i class="bx bx-server bx-md"/>
    <slot {active}/>
</div>


<style lang="scss">
  @import "../../styles/menubar";
</style>
