<script lang="ts">
    /** This component is used to select the analytical procedures and parameters for the analytic standalone tool.
     * It consists of two steps, the first step to select the analytical procedure (SELECTION_STATE.ANALYTIC_PROCEDURE)
     * and a second step to select the parameters for this procedure (SELECTION_STATE.PARAMETERS) in case there are
     * parameters to be selected.
     * Also see the story for this modal, {@see ./stories/SelectAnalyticaModal.stories.svelte}
     */
    import {t} from "../../i18n/i18n";
    import {fly} from 'svelte/transition';
    import {onMount} from "svelte";
    import {type AnalyticData} from "./types/analyticData";
    import {fetchUtils} from "../utils/fetchUtils";
    import Notifications from "../organisms/Notifications.svelte";
    import RadioButton from "../atoms/RadioButton.svelte";
    import PrimaryButton from "../atoms/PrimaryButton.svelte";
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import {addNotification, closeModalOnOutsideClick} from "../stores";
    import {DefaultNotification, NotificationType} from "../types/notification";
    import AnalyticalParameterSelection from "./AnalyticalParameterSelection.svelte";
    import {analyticsToolParams} from "./stores";
    import {closeModal} from "svelte-modals";

    enum SELECTION_STATE {
        ANALYTIC_PROCEDURE, PARAMETERS
    }

    export let isOpen: boolean = true;
    export let materialNeed: boolean = false; // default is false, materialNeed is analytic, !materialNeed is analysis
    export let technicalName: string | null = null;
    let state: SELECTION_STATE = SELECTION_STATE.ANALYTIC_PROCEDURE;
    let primaryButtonDisabled: boolean = false;

    // ANALYTIC_PROCEDURE step values
    let analyticProcedures: AnalyticData[] = [];
    let selectedAnalyticalProcedure: AnalyticData | null;

    // PARAMETER step values
    let selectedResult: string | undefined;
    let selectedParameters: Record<string, string>;
    let resultDescription: string;

    onMount(async () => {
        await fetchAnalyticalProcedures();
        if (technicalName) {
            for (let analyticData of analyticProcedures) {
                if (analyticData.technicalName === technicalName) {
                    selectedAnalyticalProcedure = analyticData;
                }
            }
            state = selectedAnalyticalProcedure ? SELECTION_STATE.PARAMETERS : SELECTION_STATE.ANALYTIC_PROCEDURE;
        }
    })

    /**
     * Fetches the analytical procedures from the server.
     *
     * @async
     * @function fetchAnalyticalProcedures
     *
     * @returns {Promise<void>} A promise that resolves when the data is fetched successfully.
     * @throws {Error} If there is an error in fetching the data, a notification is shown in the modal.
     */
    async function fetchAnalyticalProcedures(): Promise<void> {
        await fetchUtils.get("/api/analytic-procedures/autocomplete/svelte")
            .then(data => analyticProcedures = [...data])
            .catch(fetchUtils.catchErrorAndShowNotification('modal'));
    }

    /**
     * simple functionality of the back button, just resets the state
     */
    function backToProcedure() {
        state = SELECTION_STATE.ANALYTIC_PROCEDURE;
    }

    /**
     * Collects the selected parameters in a Record<string, string> format.
     *
     * @returns {Record<string, string>} The selected parameters in a key-value pair format.
     */
    function getSelectedParameters(): Record<string, string> {
        let params: Record<string, string> = {};
        // selectedAnalyticalProcedure should never be null, as we always catch that case before
        params["analyticTechnicalName"] = selectedAnalyticalProcedure?.technicalName ?? "";
        if (materialNeed && resultDescription) {
            params["satisfying"] = resultDescription;
        }
        for (let param in selectedParameters) {
            params[param] = selectedParameters[param];
        }
        params["origin"] = new URLSearchParams(window.parent.location.search).get("origin")
            || encodeURIComponent(window.parent.location.href);
        return params;
    }

    function setParameterAndCloseModal() {
        $analyticsToolParams = getSelectedParameters();
        closeModal();
        $closeModalOnOutsideClick = true;
    }

    /**
     * Handles the selection of an analytical procedure.
     *
     * If a procedure is selected and there are no parameters required for that procedure,
     * the method sets the parameter and closes the modal.
     *
     * If a procedure is selected and there is only one satisfying result and no parameters to select,
     * the method sets the parameter and closes the modal.
     *
     * If a procedure is selected and none of the above conditions are met,
     * the method sets the state to the next state and resets the selected parameters.
     *
     * If no procedure is selected, an error notification is shown.
     *
     * @returns {void}
     */
    function handleAnalyticalProcedureSelection(): void {
        if (selectedAnalyticalProcedure) {
            // in case there are no parameters to be selected for the selected procedure, we can skip step 2
            if (!materialNeed && selectedAnalyticalProcedure.requiredParameters.length === 0) {
                setParameterAndCloseModal();
                return;
            }
            // in case we have only one satisfying result and no parameters to select (e.g. DepV Geologische Barriere), we can skip step 2
            if (materialNeed && selectedAnalyticalProcedure.possibleSatisfyingResults.length == 1) {
                let result = selectedAnalyticalProcedure.possibleSatisfyingResults[0];
                if (Object.keys(selectedAnalyticalProcedure.resultParameters[result]).length == 0) {
                    setParameterAndCloseModal();
                    return;
                }
            }
            // otherwise, we set the state to the next state
            state = SELECTION_STATE.PARAMETERS;
            selectedParameters = {};
        } else {
            // error handling -> show notification
            let notification = DefaultNotification($t("Analytics.Notification.noProcedureSelected"), NotificationType.ERROR, false, 3000, "modal");
            addNotification(notification);
        }
    }

    function showParameterSelectionNotification() {
        let notification = DefaultNotification($t("Analytics.Notification.selectParameterValues"), NotificationType.INFO, true, 4500, "modal");
        addNotification(notification);
        return;
    }

    /**
     * Checks the selected parameters and handles the parameter selection.
     * Shows an error notification if any parameter is empty for the selected analytical procedure.
     *
     * @return {void}
     */
    function handleParameterSelection(): void {
        // error handling -> for all parameters some value has to be selected
        if (selectedAnalyticalProcedure) {
            if (materialNeed) {
                if (isAnyAnalyticParameterEmpty()) {
                    showParameterSelectionNotification();
                }
            } else {
                // error handling -> for all parameters some value has to be selected
                if (isAnyAnalysisParameterEmpty()) {
                    showParameterSelectionNotification();
                }
            }
            setParameterAndCloseModal();
        }
    }

    /**
     * Handles the primary button action based on the current state.
     */
    function handlePrimaryButton() {
        if (state === SELECTION_STATE.ANALYTIC_PROCEDURE) {
            handleAnalyticalProcedureSelection();
        } else {
            handleParameterSelection();
        }
    }

    /**
     * Checks if any of the analytic parameters is empty.
     * @returns {boolean} Returns true if any of the analytic parameters is empty, otherwise returns false.
     */
    function isAnyAnalyticParameterEmpty(): boolean {
        let parametersForResult = selectedAnalyticalProcedure?.resultParameters[selectedResult as string];
        return parametersForResult ? Object.keys(parametersForResult).some(param => selectedParameters && !selectedParameters[param]) : true;
    }

    /**
     * Checks if any analysis parameter is empty.
     * @return {boolean} True if any analysis parameter is empty, false otherwise.
     */
    function isAnyAnalysisParameterEmpty(): boolean {
        return selectedAnalyticalProcedure?.requiredParameters.some(param => selectedParameters && !selectedParameters[param]) ?? true;
    }

    /**
     * Checks if the primary button should be disabled based on the current state and selected parameters.
     * @returns {boolean} - Returns true if the primary button should be disabled, false otherwise.
     */
    function getPrimaryButtonDisabled(): boolean {
        let isAnyParameterEmpty = false;
        if (materialNeed) {
            isAnyParameterEmpty = isAnyAnalyticParameterEmpty();
        } else {
            isAnyParameterEmpty = isAnyAnalysisParameterEmpty();
        }
        // we need to select the parameters and some of the values are not selected
        return (state === SELECTION_STATE.PARAMETERS && (!selectedResult || !resultDescription || isAnyParameterEmpty))
            || (state === SELECTION_STATE.ANALYTIC_PROCEDURE && !selectedAnalyticalProcedure);
    }

    $: state, selectedResult, resultDescription, selectedParameters, selectedAnalyticalProcedure, primaryButtonDisabled = getPrimaryButtonDisabled();
</script>

{#if isOpen}
    <div role="dialog" class="modal modal-backdrop" in:fly="{{ y: -1000, duration: 400 }}"
         out:fly={{y: -1000, duration: 400}}>
        <div class="contents">
            <Notifications target="modal" padding="0" marginTop="0" top="0"/>
            <div class="modal-title__left">
                <h1>{@html state === SELECTION_STATE.ANALYTIC_PROCEDURE ? $t("Basedata.selectAnalytic") : $t("AnalysisCheck.selectParameter.title") }</h1>
            </div>
            <div class="actual-content">
                <p class="modal-message">{@html state === SELECTION_STATE.ANALYTIC_PROCEDURE ? $t("AnalysisCheck.selectProcedure.msg.styled") : $t("AnalysisCheck.selectParameter.msg.styled")}</p>
                <div class="content__header">
                    <div>
                        {#if state === SELECTION_STATE.ANALYTIC_PROCEDURE}
                            <div>{$t('Analytics.testMethod')}</div>
                            {#if selectedAnalyticalProcedure}
                                <div>{selectedAnalyticalProcedure?.name || "-"}&nbsp;ausgewählt</div>
                            {:else}
                                <!-- TODO this should be a search lens: as long as no procedure is selected, we should be able to search for the procedure -->
                            {/if}
                        {:else if state === SELECTION_STATE.PARAMETERS}
                            <div>{$t('Analytics.evaluationParameter.title')}</div>
                            <div></div>
                        {/if}
                    </div>
                    <hr/>
                </div>
                <div class="content__main {state === SELECTION_STATE.ANALYTIC_PROCEDURE ? 'analyticProcedure' : 'soilType'}">
                    {#if state === SELECTION_STATE.ANALYTIC_PROCEDURE}
                        {#each analyticProcedures as config, id (id)}
                            <RadioButton bind:selected={selectedAnalyticalProcedure} {id}
                                         groupName="analyticProcedure" label={config.name} value={config}/>
                        {/each}
                    {:else if state === SELECTION_STATE.PARAMETERS}
                        <AnalyticalParameterSelection bind:selectedResult bind:selectedParameters
                                                      bind:resultDescription {materialNeed}
                                                      analyticData={selectedAnalyticalProcedure}/>
                    {/if}
                </div>
            </div>
            <div class="buttons">
                {#if state === SELECTION_STATE.PARAMETERS}
                    <SecondaryButton id="backToAnalyticProcedureBtn" label={$t("UI.button.back")}
                                     color={SecondaryBtnColors.GREEN} sizeAdaptingToText
                                     on:click={backToProcedure}/>
                {/if}
                <PrimaryButton label={$t("UI.button.confirm")} disabled={primaryButtonDisabled}
                               sizeAdaptingToText
                               on:click={handlePrimaryButton}/>
            </div>
        </div>
    </div>
{/if}


<style lang="scss">
  @use "../../styles/modals"; // also includes global.scss

  .modal {
    background: rgba(58, 58, 58, .4);
    pointer-events: all;
  }

  .contents {
    row-gap: 0.625rem;
    --content-padding: 2.25rem 1.5rem 2rem 2.25rem;
  }

  .actual-content {
    padding-right: 0.5rem;
    width: 100%;
    @include modals.flex-col(0.625rem, self-start)
  }

  .content__header {
    width: 100%;

    & > div {
      @include modals.flex-row(2rem, center, space-between);
      @include modals.roboto-font(normal, 500, 0.75rem, black);

      & > div:last-child {
        text-align: end;
      }
    }
  }

  .content__main {
    width: 100%;

    &.analyticProcedure {
      @include modals.flex-col(0.625rem, flex-start, unset);
      max-height: 11.625rem;
      overflow-y: auto;
      @extend .scrollable;
    }

    &.soilType {
      margin-bottom: 1rem;
    }
  }

  .buttons {
    width: 100%;
    @include modals.flex-row(0.625rem, center, flex-end);
  }

  p.modal-message {
    @include modals.roboto-font(150%, 500, 0.75rem, #949494);
  }

  hr {
    color: modals.$primaryGreen;
    opacity: 1;
    margin: 0.625rem 0;
  }

</style>
