<script lang="ts">

    import {t} from "../../i18n/i18n.js";
    import {termsCheckboxChanged} from "../offerer/termsAndConditions";
    import {PrimaryBtnColors} from "../atoms/PrimaryButton.svelte";
    import AgbButton from "../organisms/AgbButton.svelte";
    import {createEventDispatcher, getContext} from 'svelte';
    import {fetchUtils} from "../utils/fetchUtils";
    import SecondaryButton, {SecondaryBtnColors} from "../atoms/SecondaryButton.svelte";
    import type {PubPageParams} from "./types/pubPageParams";
    import {type Notification, NotificationType} from "../types/notification";
    import {addNotification} from "../stores";

    const dispatch = createEventDispatcher();
    const urlParams: PubPageParams = getContext("urlParams");
    let {requestPublicId, requestedEntityId, requestedEntityType, offerUserPublicId} = urlParams;

    export let isOfferer: boolean = false;
    export let hideCheckbox: boolean = false;
    export let isExternalUser: boolean = false;

    let agbAccepted: boolean = false;
    let url: string;

    async function acceptOffer() {
        if (isExternalUser) {
            url = `/api/optimizer/public/${requestPublicId}/requested/${requestedEntityType}/${requestedEntityId}/user/${offerUserPublicId}/createDefaultOffer`
        } else {
            url = `/api/optimizer/${requestPublicId}/requested/${requestedEntityType}/${requestedEntityId}/user/${offerUserPublicId}/createDefaultOffer`
        }
        await fetchUtils.get(url)
            .then(() => {
                sendAcceptEvent();
                sendLinkAttachmentsEvent();
                let notification: Notification = {message: "Projekt erfolgreich angenommen", type: NotificationType.SUCCESS};
                addNotification(notification);
            })
            .catch(fetchUtils.catchErrorAndShowNotification)
        cancel();
    }


    const cancel = () => dispatch("cancel");
    const sendAcceptedAgbEvent = () => dispatch('acceptedAgb');
    const sendAcceptEvent = () => dispatch('offerChanged');
    const sendLinkAttachmentsEvent = () => dispatch('linkAttachmentsByAccept');

    function agbClicked() {
        termsCheckboxChanged(
            urlParams?.lotPublicId,
            agbAccepted,
            $t('UI.termsConditions.acceptOffer.AGB.clicked')
        );
    }
</script>


<div class="row">
    {#if !isOfferer}
    <div style="display: flex; flex-direction: row; margin-top: 15px">
        <span class="block-title">{$t('UI.optimizerPage.buttons.accept')}</span>
    </div>
    {/if}
    <div class="col-lg-12 col-xs-12">
        {#if isOfferer}
            <AgbButton {hideCheckbox} label={$t("UI.offererPage.buttons.accept")} color={PrimaryBtnColors.GREEN}
                       description={$t('UI.acceptOffer.terms.description.normal')}
                       bind:agbAccepted
                       on:checkboxChange={agbClicked}
                       on:click={sendAcceptedAgbEvent}/>
        {:else}
            <AgbButton {hideCheckbox} label={$t("UI.optimizerPage.buttons.accept")} color={PrimaryBtnColors.GREEN}
                       description={$t('UI.acceptProject.terms.description.normal')}
                       bind:agbAccepted
                       on:checkboxChange={agbClicked}
                       on:click={acceptOffer}/>
        {/if}
    </div>
</div>
<div style="display: flex; justify-content: flex-end;">
    <div class="mr-3">
        <SecondaryButton color={SecondaryBtnColors.GREY} label={$t("UI.button.cancel")} on:click={cancel}/>
    </div>
</div>


<style lang="scss">
  @import "../../styles/global";
  @import "../../styles/icons";

  .block-title {
    text-align: left;
    @include roboto-font(16px, 500, 1rem, black);
  }

  .info-icon {
    background-image: url("@/images/info-icon-filled-16px.svg");
    margin-left: 20px;
    margin-top: -2px;
    z-index: 9999;
    width: 16px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    align-self: center;
    position: relative;
  }
</style>
