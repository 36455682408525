import type {AddressData} from "./addressData";
import {DefaultAddressData} from "./addressData";
import type {PersonData} from "./personData";
import {DefaultPersonData} from "./personData";

export type DealPartnerConnect = {
    dealPublicId: string,
    offerer: DealPartnerConnectData,
    optimizer: DealPartnerConnectData,
    generalEmails: [string]
}

export function DefaultDealPartnerConnect(): DealPartnerConnect {
    return {
        dealPublicId: "",
        generalEmails: [""],
        offerer: DefaultDealPartnerConnectData(),
        optimizer: DefaultDealPartnerConnectData()
    };
}

export type DealPartnerConnectData = {
    address: AddressData,
    person: PersonData,
    contactPhone: string,
    emails: [string],
    companyName: string
}

export function DefaultDealPartnerConnectData(): DealPartnerConnectData {
    return {address: DefaultAddressData(), companyName: "", contactPhone: "", emails: [""], person: DefaultPersonData()};
}