<script lang="ts">

    import {t} from "../../../../i18n/i18n";
    import TextArea, {textAreaHeights} from "../../../atoms/TextArea.svelte";
    import type {FontProps} from "../../../types/fontProps";
    import {DefaultRobotoFontProps} from "../../../types/fontProps";
    import {ProjectState} from "../../../types/enums";
    import {currentProject} from "../utils/fakStores";
    import NullableDataWrapper from "../../../utils/NullableDataWrapper.svelte";


    const headerFontProps: FontProps = DefaultRobotoFontProps('14px', '0.75rem');

    $: readonly = !$currentProject?.isEditable;

    function onCommentsChange() {
        if ($currentProject && $currentProject.id !== "new") {
            dispatchEvent(new CustomEvent("saveProject"));
        }
    }

</script>


<div style="padding-right: 0.656rem;">
    <div class="styled-scrollbar">
        <NullableDataWrapper data={$currentProject}>
            <TextArea bind:text={$currentProject.remarks} id="project-comment-details" title={$t('UI.map.comments.projectRemarks')}
                      height={textAreaHeights.MID} placeholder={$t('UI.pubRemarks.placeholder')}
                      {headerFontProps} marginTop="0.375rem" {readonly} labelGap="0.625rem"
                      on:change={onCommentsChange}/>
            <TextArea bind:text={$currentProject.commentBackoffice} id="project-comment-q" title={$t('UI.map.comments.backoffice')}
                      height={textAreaHeights.MID}
                      placeholder={$t('UI.pubRemarks.placeholder')} {headerFontProps} {readonly} labelGap="0.625rem"
                      on:change={onCommentsChange}/>
            <TextArea bind:text={$currentProject.commentSales} id="project-comment-sales" title={$t('UI.map.comments.sales')}
                      height={textAreaHeights.MID}
                      placeholder={$t('UI.pubRemarks.placeholder')} {headerFontProps} {readonly} labelGap="0.625rem"
                      on:change={onCommentsChange}/>
            {#if $currentProject.state === ProjectState.CLOSED}
                <TextArea bind:text={$currentProject.commentAccounting} title={$t('UI.map.comment.commentAccounting')}
                          height={textAreaHeights.MID}
                          placeholder={$t('UI.pubRemarks.placeholder')} {headerFontProps} {readonly} labelGap="0.625rem"
                          on:change={onCommentsChange}/>
            {/if}
        </NullableDataWrapper>
    </div>
</div>


<style lang="scss">
  @import "../../../../styles/colors";

  .styled-scrollbar {
    padding-left: 1.313rem;
    padding-right: 0.656rem;
    padding-bottom: 0.656rem;
    scrollbar-track-color: transparent;
    scrollbar-color: $primaryGreen $bluish-grey-200;
    scroll-margin-right: 4px;

    /* Remove this if comments should not have max height */
    max-height: 382px;
    overflow-y: auto;
    /** remove until here */

    &::-webkit-scrollbar {
      width: 20px;
      margin: 2px
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px $bluish-grey-200;
      border: solid 8px transparent;
      border-radius: 20px;
      background-clip: padding-box;

    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px $primaryGreen;
      border: solid 8px transparent;
      border-radius: 20px;
      background-clip: padding-box;
    }
  }
</style>
