import {fetchUtils} from "../utils/fetchUtils";

const CD_LOGO_BASE_URL: string = "/api/cd/logo";

// Logo of the current users tenant
export async function fetchTenantLogo() {
    // Do not use the cache when fetching the logo, otherwise changes might not be shown correctly.
    return await fetch(CD_LOGO_BASE_URL + "/config",
        {
            method: 'GET',
            cache: "no-store"
        })
        .then(async (data: Response) => {
            if (data !== null && data.status === 200) {
                let file = await data.blob();
                return URL.createObjectURL(file);
            }
            return null;
        })
        .catch(e => {
            // ignore
            console.error("Error fetching tenant logo: " + e.statusText);
            return null;
        });
}

// Logo of the request tenant
export async function fetchRequestTenantLogo(requestPublicId: string) {
    return await fetch(`${CD_LOGO_BASE_URL}/request/${requestPublicId}`, {
        method: 'GET',
        cache: "no-store"
    })
        .then(async (data: Response) => {
            if (data !== null && data.status === 200) {
                let file = await data.blob();
                return URL.createObjectURL(file);
            }
            return null;
        })
        .catch(e => {
            // ignore
            console.error("Error fetching tenant logo: " + e.statusText);
            return null;
        });
}

export async function uploadLogo(file: File) {
    return await fetchUtils.post(CD_LOGO_BASE_URL + "/upload", file, false, false)
        .then(() => true)
        .catch(() => false);
}

export async function deleteTenantLogo() {
    return await fetchUtils.get(CD_LOGO_BASE_URL + "/delete", false)
        .then(() => true)
        .catch(() => false);
}
