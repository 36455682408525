<script lang="ts">
    import PrimaryButton from "../atoms/PrimaryButton.svelte";
    import InputField from "../molecules/InputField.svelte";
    import PriceTableCell from "../atoms/PriceTableCell.svelte";
    import VolumeTableCell from "../atoms/VolumeTableCell.svelte";
    import {checkNumber, inputFieldFormats} from "../utils/formatters";
    import {fetchUtils} from "../utils/fetchUtils";
    import {t} from "../../i18n/i18n";
    import {NotificationType} from "../types/notification";
    import {addNotification} from "../stores";
    import DatePickerInput from "../atoms/DatePickerInput.svelte";
    import type {PubPageParams} from "../bid/types/pubPageParams";
    import {getContext} from "svelte";
    import type {Facts} from "../bid/types/facts";
    import type {Writable} from "svelte/store";
    import type {Deal} from "../bid/types/deal";
    import type {ActualPosition} from "../types/actualPosition";
    import type {ActualPositionDates} from "../types/actualPositionDates";

    const zeroPriceFormat = '0,00';
    const urlParams: PubPageParams = getContext("urlParams");
    const deal: Writable<Deal> = getContext("deal");

    export let readOnly: boolean = false;
    export let isOfferer: boolean = false;

    let targetFacts: Facts;
    let actualFacts: Facts;
    let targetSum: number, actualSum: number, difference: number;

    function triggerSumUpdate() {
        targetSum = checkNumber((targetFacts?.volume ?? 0) * (targetFacts?.price ?? targetFacts?.kickback ?? 0)) ?? 0;
        updateActualSumAndDifference();
    }

    function updateActualSumAndDifference() {
        if (actualFacts?.volume) {
            let actualVolume = actualFacts?.volume ?? 0;
            if (actualFacts?.price) {
                actualSum = checkNumber(actualVolume * (actualFacts?.price ?? actualFacts?.kickback ?? 0)) ?? 0;
            } else {
                actualSum = checkNumber(actualVolume * (targetFacts?.price ?? targetFacts?.kickback ?? 0)) ?? 0;
            }
            difference = checkNumber(actualSum - targetSum) ?? 0;
        }
    }

    /*    function handleStartDateChanges() {
            // this is a workaround for a svelte bug that invalidates the expression in the condition though it didn't change
            // https://github.com/sveltejs/svelte/issues/7749
            if (changesStart < 3) {
                changesStart++;
                actualStart = positionDates.actualPositionDates.startDate ? dayjs(positionDates.actualPositionDates.startDate, 'YYYY-MM-DD').unix() : null;
            }
        }*/

    /*    function handleEndDateChanges() {
            // this is a workaround for a svelte bug that invalidates the expression in the condition though it didn't change
            // https://github.com/sveltejs/svelte/issues/7749
            if (changesEnd < 3) {
                changesEnd++;
                actualEnd = positionDates.actualPositionDates.endDate ? dayjs(positionDates.actualPositionDates.endDate, 'YYYY-MM-DD').unix() : null;
            }
        }*/

    function updatePosition() {
        let url: string;
        let transportCost: number;
        let materialPrice: number;
        if (isOfferer) {
            url = `/api/offerer/lot/${urlParams?.lotPublicId}/updateActualPositionData`;
            transportCost = $deal?.requestActualValues?.transportCost?.number || 0;
            materialPrice = $deal?.requestActualValues?.materialPrice?.number || actualFacts?.price || targetFacts?.price;
        } else {
            url = `/api/optimizer/deal/${$deal.publicId}/updateActualPositionData`;
            transportCost = $deal?.offerActualValues?.transportCost?.number || 0;
            materialPrice = $deal?.offerActualValues?.materialPrice?.number || actualFacts?.price || targetFacts?.price;
        }

        const payload: { actualPosition: ActualPosition } & ActualPositionDates = {
            startDate: actualFacts?.startAsIsoDate,
            endDate: actualFacts?.endAsIsoDate,
            actualPosition: {
                volume: actualFacts?.volume,
                grossPrice: actualFacts?.price || targetFacts?.price,
                transportCost,
                materialPrice
                // the others are not given in the form, so we omit them
            }
        }

        fetchUtils.post(url, payload).then(() => {
            let notification = {
                message: $t('UI.notification.saved'),
                type: NotificationType.SUCCESS,
                timeout: 4000,
                dismissible: false
            };
            addNotification(notification)
            updateActualSumAndDifference();
        })
            .catch(error => {
                let notification = {
                    message: $t("UI.error.save"),
                    type: NotificationType.ERROR,
                    timeout: 0,
                    dismissible: true
                };
                addNotification(notification);
                notification = {message: error.message, type: NotificationType.ERROR, timeout: 0, dismissible: true};
                addNotification(notification);
            });
    }

    /** on changes to positionDates.actualPositionDates, we must trigger this reactive statement */
    // $: positionDates.actualPositionDates.startDate, handleStartDateChanges();
    // $: positionDates.actualPositionDates.endDate, handleEndDateChanges();
    $: targetFacts, actualFacts, triggerSumUpdate();
    $: if ($deal) {
        targetFacts = $deal?.dealFacts;
        actualFacts = isOfferer ? $deal?.requestActualValues?.totalFacts : $deal?.offerActualValues?.totalFacts;
    }
</script>

<div class="actual-comparison">

    <span>{$t('UI.Model.should.is.comparison')}</span>

    <div class="actual-comparison-grid"> <!-- header row -->
        <div>{$t('Position')}</div>
        <div>{$t('Position.material')}</div>
        <div>{$t('UI.lot.volume.label')}</div>
        <div>{$t('TargetPrice.price')}</div>
        <div></div>
    </div>
    <hr/>
    <div class="actual-comparison-grid odd"> <!-- target values row -->
        <div>{$t('Position.should')}</div>
        <div>{targetFacts?.materialName}</div>
        <div>
            <VolumeTableCell value={targetFacts?.volumeFormattedAsString}/>
        </div>
        <div id="shallTargetGrossPrice">
            <PriceTableCell value={targetFacts?.priceValFormattedAsString || zeroPriceFormat}/>
        </div>
        <div></div>
    </div>

    <div class="actual-comparison-grid"> <!-- actual values row -->
        <div>{$t('Position.is')}</div>
        <div>{targetFacts?.materialName}</div>
        {#if readOnly}
            <div id="volume">
                <VolumeTableCell value={actualFacts?.volumeFormattedAsString}/>
            </div>
        {:else}
            <div>
            <InputField id="volume" bind:value={actualFacts.volume} placeholder={actualFacts?.volumeFormattedAsString}
                        unit={$t("UI.unit.ton.short.small")} forcedDecimalPlaces={2} format={inputFieldFormats.EXPANDED}
                        isNumeric on:focusout={updateActualSumAndDifference}/>
            </div>
        {/if}
        <div id="actualTargetGrossPrice">
            <PriceTableCell value={actualFacts?.price || targetFacts?.price || zeroPriceFormat} needsFormatting/>
        </div>
        <div></div>
    </div>

    <hr/>
    <div class="actual-comparison-grid"> <!-- result row -->
        <div class="sum" style="margin-top:-30px">{$t('UI.Mode.sum')}</div>
        <div></div>
        <div></div>
        <div>
            <div>{$t('UI.Model.shouldVolumes')}</div>
            <div>{$t('UI.Model.isVolumes')}</div>
            <div>{$t('UI.Model.difference')}</div>
        </div>
        <div class="sum">
            <div id="targetSum">
                <PriceTableCell value={targetSum || zeroPriceFormat} needsFormatting/>
            </div>
            <div id="actualSum">
                <PriceTableCell value={actualSum || zeroPriceFormat} needsFormatting/>
            </div>
            <div id="difference">
                <PriceTableCell value={difference || zeroPriceFormat} needsFormatting/>
            </div>
        </div>
    </div>

    <span>{$t('Project.timeframe')}</span>

    <div class="actual-comparison-grid">
        <div>{$t('Position')}</div>
        <div>{$t('UI.startDate.label')}</div>
        <div>{$t('UI.endDate.label')}</div>
    </div>
    <hr/>
    <div class="actual-comparison-grid odd">
        <div>{$t('Position.should')}</div>
        {#if targetFacts?.start}
            <div>{targetFacts.startAsFormattedDate}</div>
        {:else}
            <div>&ndash;</div>
        {/if}
        {#if targetFacts?.end}
            <div>{targetFacts.endAsFormattedDate}</div>
        {:else}
            <div>&ndash;</div>
        {/if}
        {#if !targetFacts?.start && !targetFacts?.end}
            <div style="font-style: italic; width: max-content">{$t("UI.timeframe.noneGiven")}</div>
        {/if}
    </div>
    <div class="actual-comparison-grid">
        <div>{$t('Position.is')}</div>
        {#if readOnly}
            <div>{actualFacts?.startAsFormattedDate}</div>
            <div>{actualFacts?.endAsFormattedDate}</div>
        {:else}
            <DatePickerInput id="startDate" bind:date={actualFacts.start} format={inputFieldFormats.FULL} label=""/>
            <DatePickerInput id="endDate" bind:date={actualFacts.end} format={inputFieldFormats.FULL} label=""/>
        {/if}
    </div>

    {#if readOnly === false}
        <div style="float: right;">
            <PrimaryButton id="saveComparisonBtn" label={$t('UI.button.save')} on:click={updatePosition}/>
        </div>
    {/if}
</div>

<style lang="scss">
  @import "../../styles/global";

  .readOnly {
    display: none;
  }

  .actual-comparison {
    font-size: 14px;
    margin-top: 1.875rem;
    text-align: left;
    min-width: min-content;

    & > span {
      font-weight: bold;
    }

    .odd {
      background-color: #F0F2F8;
    }

    & > hr {
      height: 2px;
      color: $primaryColor;
      opacity: 1;
      margin: 0 auto;
    }

    .actual-comparison-grid {
      width: 100%;
      display: grid;
      grid-template-columns:  80px repeat(4, 150px);
      grid-row-gap: 1.125rem;
      grid-column-gap: 3em;
      justify-content: space-between;
      align-items: center;
      margin: 0;

      & > div {
        width: fit-content;
        margin: 12px;
      }

      .sum {
        color: $primaryColor;
      }

      & :global(.container-label-info-input),
      & :global(.labeled-input-field) {
        margin: 12px 0;
      }

      & :global(.container-input) {
        justify-content: flex-start;
      }
    }
  }

</style>
