<script lang="ts">
    /* https://svelte-modals.mattjennings.io/api */
    import {fly} from 'svelte/transition';
    import {copyHelpEntry, type HelpEntry, helpEntrySchema} from "../types/helpEntry";
    import TextArea from "../../atoms/TextArea.svelte";
    import SecondaryButton from "../../atoms/SecondaryButton.svelte";
    import PrimaryButton from "../../atoms/PrimaryButton.svelte";
    import {closeModal} from "svelte-modals";
    import {fetchUtils} from "../../utils/fetchUtils";
    import InputField from "../../molecules/InputField.svelte";
    import {HelpVisibility} from "../../types/enums";
    import {inputFieldFormats} from "../../utils/formatters";
    import Tags from "svelte-tags-input";
    import StaticChipList from "../../organisms/StaticChipList.svelte";
    import {t} from "../../../i18n/i18n";
    import {NotificationType} from "../../types/notification";
    import {addNotification} from "../../stores";
    import {setContext} from "svelte";
    import {type Writable, writable} from "svelte/store";
    import {getFormErrors} from "../../utils/formValidation";
    import {DefaultLabelFontProps, type FontProps} from "../../types/fontProps";
    import {getFontPropsStyleString} from "../../types/fontProps.js";
    import type {FormError} from "../../types/formError";


    setContext("useMaterialDesign", false);

    // provided by <Modals />
    export let isOpen: boolean;

    export let helpEntry: HelpEntry;
    export let onSave: () => void = () => {
    };

    const labelFontProps: FontProps = DefaultLabelFontProps("1.3125rem", 500, "0.875rem", "#000");
    const FORM_ERROR_PATH_PREFIX: string = 'helpEntry.';

    let showPageIdentifierHelpText: boolean = false;
    let formErrors: Writable<FormError[]> = setContext("formErrors", writable([{path: "", message: ""}]));
    let editedHelpEntry: HelpEntry = copyHelpEntry(helpEntry);


    async function saveEntry() {
        helpEntrySchema.validate(editedHelpEntry, {stripUnknown: true, abortEarly: false})
            .then(validatedEntry => {

                fetchUtils.post("/api/saveHelpEntry", validatedEntry, false)
                    .then(() => {
                        let notification = {
                            message: $t("UI.help.notification.add.success"),
                            type: NotificationType.SUCCESS,
                            timeout: 3000,
                            dismissible: true
                        };
                        addNotification(notification);
                        closeModal();
                        onSave();
                    })
                    .catch(e => {
                        let notification = {
                            message: e.message, type: NotificationType.ERROR, timeout: 0,
                            dismissible: true
                        };
                        addNotification(notification);
                        closeModal();
                    })
            })
            .catch(e => {
                $formErrors = getFormErrors(e, FORM_ERROR_PATH_PREFIX);

            });
    }

    const visibilityOptions = Object.keys(HelpVisibility).map((o: string) => {
        return {label: $t(("UI.help.visibility." + o.toLowerCase())), value: o, msg: ""}
    });

    function sanitizePathString() {
        if (editedHelpEntry.pageIdentifier) {
            // Remove all whitespaces
            editedHelpEntry.pageIdentifier = editedHelpEntry.pageIdentifier.replace(/\s/g, '');
            // If pageIdentifier doesn't start with /, we add this to the start
            if (!editedHelpEntry.pageIdentifier.startsWith("/")) {
                editedHelpEntry.pageIdentifier = "/" + editedHelpEntry.pageIdentifier;
            }
            // Replace all occurrences of two or more // with just one /
            editedHelpEntry.pageIdentifier = editedHelpEntry.pageIdentifier.replaceAll(/\/+/g, '/');
            // Remove / at the end
            if (editedHelpEntry.pageIdentifier.endsWith('/')) {
                editedHelpEntry.pageIdentifier = editedHelpEntry.pageIdentifier.slice(0, -1);
            }
        }
    }

    function sanitizeLink() {
        if (editedHelpEntry.link) {
            // Remove all whitespaces
            editedHelpEntry.link = editedHelpEntry.link.replace(/\s/g, '');
            editedHelpEntry.link = editedHelpEntry.link.replace("http://", "https://")
            if (!editedHelpEntry.link?.startsWith("https://")) {
                editedHelpEntry.link = "https://" + editedHelpEntry.link;
            }
        }
    }

    function triggerPageIdentifierHelpText() {
        showPageIdentifierHelpText = !showPageIdentifierHelpText;
    }

</script>


{#if isOpen}
    <div id="help-config-modal" role="dialog" class="modal modal-backdrop" in:fly="{{ y: -100, duration: 600 }}"
         out:fly={{y: -200, duration: 600}}>
        <div class="contents">
            <span class="help-modal-header">{$t(editedHelpEntry.id === "new" ? "UI.help.newEntry.title" : "UI.help.editEntry.title")}</span>
            <span class="help-modal-description">{$t("UI.help.modal.description")}</span>
            <div class="help-entry-form">
                <div style="width: 40%;">
                    <StaticChipList bind:activeChips={editedHelpEntry.visibility} id="help-modal-visibility"
                                    list={visibilityOptions} identifier="helpEntryVisibilityOptions"
                                    header="{$t('UI.help.entry.visibility') + '*'}"
                                    fontProps={labelFontProps}
                                    errorPath="{FORM_ERROR_PATH_PREFIX}visibility"/>
                </div>
                <div style="width: 100%;">
                    <InputField bind:value={editedHelpEntry.pageIdentifier} on:focusout={sanitizePathString}
                                id="help-modal-page-identifier"
                                label="{$t('UI.help.entry.pageIdentifier') + '*'}"
                                headerFontProps={labelFontProps}
                                format={inputFieldFormats.FULL} prependText={window.location.origin}
                                errorPath="{FORM_ERROR_PATH_PREFIX}pageIdentifier"
                                placeholder="{$t('UI.help.entry.pageIdentifier.placeholder')}"/>
                    <div class="help-modal-page-identifier-help">
                        <span style="height: {showPageIdentifierHelpText ? 'auto' : '0'};">
                            <!-- Adding the text here, otherwise the style for the code tags does not work -->
                            Es gibt verschiedene Möglichkeiten das Anzeigen der Hilfseinträge für mehrere Seiten zu definieren.
                            <br>
                            <br><code>?</code> - Entspricht exakt einem Zeichen
                            <br> <code>*</code> - Entspricht keinem oder beliebig vielen Zeichen bis zum nächsten <code>/</code>
                            <br> <code>**</code> - Entspricht keinem oder beliebig vielen Zeichen (inkl. <code>/</code>)
                            <br><code>&#123;A,B,C&#125;</code> - Entspricht entweder Zeichenfolge <code>A</code>, <code>B</code> oder <code>C</code> (beliebig viele möglich)
                            <br>
                            <br> Alle Möglichkeiten lassen sich beliebig kombinieren.
                            <br>Achten Sie darauf, dass an keiner Stelle zwei Schrägstriche (<code>//</code>) hintereinander stehen.
                        </span>
                        <button on:click={triggerPageIdentifierHelpText}>
                            {showPageIdentifierHelpText ? $t("UI.help.pageIdentifier.help.close") : $t("UI.help.pageIdentifier.help.open")}
                        </button>
                    </div>
                </div>

                <div class="help-entry-form-row">
                    <div style="width: 50%;">
                        <TextArea bind:text={editedHelpEntry.headline} id="help-modal-headline"
                                  headerFontProps={labelFontProps}
                                  height="15.25rem" marginTop="0" labelGap="0.375rem"
                                  errorPath="{FORM_ERROR_PATH_PREFIX}headline" maxLength={150}
                                  title="{$t('UI.help.entry.headline') + '*'}"
                                  placeholder="{$t('UI.help.entry.headline.placeholder')}"/>
                        <span class="help-entry-headline-count"
                              style="{editedHelpEntry.headline.length > 149 ? 'color: #C05858; font-weight: 400' : ''}">
                            {editedHelpEntry.headline.length}{$t('UI.help.entry.headline.characterCount')}
                        </span>
                    </div>
                    <div style="width: 50%;">
                        <TextArea bind:text={editedHelpEntry.description}
                                  id="help-modal-description"
                                  height="15.25rem"
                                  marginTop="0"
                                  labelGap="0.375rem"
                                  errorPath="{FORM_ERROR_PATH_PREFIX}description"
                                  title="{$t('UI.help.entry.description') + '*'}"
                                  headerFontProps={labelFontProps}
                                  placeholder="{$t('UI.help.entry.description.placeholder')}"/>
                    </div>
                </div>

                <div class="help-entry-form-row">
                    <div style="width: 90%;">
                        <span class="help-entry-tags-label"
                              style="{getFontPropsStyleString(labelFontProps)}">{$t("UI.help.entry.tags")}</span>
                        <div class="help-entry-tags">
                            <!-- Add new tags by pressing, tab (9), enter (13), space (32) or comma (188), or focus out -->
                            <Tags bind:tags={editedHelpEntry.searchStrings}
                                  id="help-modal-search-strings"
                                  placeholder={$t("UI.help.entry.tags.placeholder")}
                                  allowPaste allowDrop onlyUnique allowBlur
                                  minChars={3} addKeys={[9, 13, 32, 188]}
                            />
                        </div>
                    </div>
                    <div style="width: 10%;">
                        <InputField bind:value={editedHelpEntry.priority}
                                    id="help-modal-priority"
                                    isNumeric headerFontProps={labelFontProps}
                                    errorPath="{FORM_ERROR_PATH_PREFIX}priority"
                                    label="{$t('UI.help.entry.priority') + '*'}"/>
                    </div>
                </div>

                <div class="help-entry-form-row">
                    <div style="width: 50%;">
                        <InputField bind:value={editedHelpEntry.link} on:focusout={sanitizeLink}
                                    id="help-modal-link"
                                    format={inputFieldFormats.FULL}
                                    label="{$t('UI.help.entry.link')}"
                                    headerFontProps={labelFontProps}
                                    errorPath="{FORM_ERROR_PATH_PREFIX}link"
                                    placeholder="{$t('UI.help.entry.link.placeholder')}"/>
                    </div>

                    <div style="width: 50%;">
                        <InputField bind:value={editedHelpEntry.linkText}
                                    id="help-modal-link-text"
                                    format={inputFieldFormats.FULL}
                                    label="{$t('UI.help.entry.linkText')}"
                                    headerFontProps={labelFontProps}
                                    errorPath="{FORM_ERROR_PATH_PREFIX}linkText"
                                    placeholder="{$t('UI.help.entry.linkText.placeholder')}"/>
                    </div>
                </div>
            </div>
            <div class="btn-row">
                <div>
                    <SecondaryButton on:click={closeModal} id="help-modal-btn-close" label="{$t('UI.button.close')}"
                                     width="6rem"/>
                    <PrimaryButton on:click={saveEntry} id="help-modal-btn-save" label="{$t('UI.button.save')}"
                                   width="6.25rem"/>
                </div>
            </div>
        </div>
    </div>
{/if}


<style lang="scss">
  @import "../../../styles/modals"; // also includes global.scss

  .contents {
    width: 100%;
    max-width: 58.125rem;
    padding: 3.12rem;
    align-items: normal;
  }

  .help-modal-header {
    @include roboto-font(1.5rem, 500, 1rem, #1E1E1E);
    padding-bottom: 0.75rem;
  }

  .help-modal-description {
    @include roboto-font(1.125rem, 500, 0.75rem, #1E1E1E);
    padding-bottom: 1.25rem;
  }

  .help-entry-form {
    @include flex-col($row-gap: 1.94rem, $alignment: flex-start);
    width: 100%;
    padding-bottom: 2rem;
  }

  .help-modal-page-identifier-help {
    padding-top: 0.75rem;

    & > span {
      @include roboto-font(1.125, 300, 0.75rem);
      display: block;
      overflow: hidden;
      border: 1px;

      & > code {
        color: black;
        background-color: #eee;
        border-radius: 3px;
        font-family: courier, monospace;
        padding: 0 3px;
      }
    }

    & > button {
      @include roboto-font(1.125, 400, 0.75rem, $primaryGreen);
      background: none;
      border: none;
      padding-left: 0;
      padding-top: 0.75rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .help-entry-form-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 1.32rem;
  }

  .help-entry-headline-count {
    @include roboto-font(1.125rem, 300, 0.75rem, #1E1E1E);
    display: block;
    padding-top: 0.25rem;
  }

  .help-entry-tags-label {
    display: block;
    padding-bottom: 0.375rem;
  }

  .btn-row {
    width: 100%;

    & > div {
      @include flex-row($col-gap: 0.375rem, $alignment: flex-end, $justify: flex-end);
    }
  }

  /* Tags styling (have to have !important to override default) */
  .help-entry-tags :global(.svelte-tags-input-layout:hover), .help-entry-tags :global(.svelte-tags-input-layout) {
    border: 1px solid #D9D9D9 !important;
  }

  .help-entry-tags :global(.svelte-tags-input-layout:focus), .help-entry-tags :global(.svelte-tags-input-layout:focus-within) {
    outline: 2px solid $primaryGreen !important;
    border-color: transparent !important;
  }

  .help-entry-tags :global(.svelte-tags-input-tag) {
    background: #fff !important;
    border: 1px solid $primaryGreen !important;
    color: #000 !important;
    border-radius: 1.25rem !important;
    padding-left: 0.625rem !important;
    padding-top: 0 !important;
  }

  .help-entry-tags :global(.svelte-tags-input) {
    font-family: 'Roboto', sans-serif !important;
    font-style: normal !important;
    line-height: 1.125rem !important;
    font-size: 0.75rem !important;
  }

  .help-entry-tags :global(.svelte-tags-input::placeholder) {
    font-family: 'Roboto', sans-serif !important;
    font-style: normal !important;
    line-height: 1.125rem !important;
    color: #9D9D9D !important;
    font-size: 0.75rem !important;
  }

  .help-entry-tags :global(.svelte-tags-input-tag-remove) {
    color: #9D9D9D;
    padding-right: 1px;
  }

</style>
