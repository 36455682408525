<script lang="ts">

    import {DefaultLabelFontProps, type FontProps} from "../types/fontProps";
    import {DefaultRobotoFontProps, getFontPropsVarString} from "../types/fontProps";
    import Tooltip from "../atoms/Tooltip.svelte";
    import {createEventDispatcher} from "svelte";
    import {t} from "../../i18n/i18n";
    import {StaticChipListColor, TooltipPlacements} from "../types/enums";
    import type {Writable} from "svelte/store";
    import {getContext, hasContext} from "svelte";
    import {
        getExactPathErrorMessage,
        getPathErrorMessage,
        isExactPathInErrorList,
        isPathInErrorList
    } from "../utils/formValidation";
    import {openCloseVariants} from "../utils/svelteMotionUtils";
    import {AnimatePresence, Motion} from "svelte-motion";
    import type {FormError} from "../types/formError";

    const dispatch = createEventDispatcher();

    export let list: {
        label: string,
        value: string,
        msg: string
    }[] = [];
    export let activeChips: string[] = [];
    export let identifier: (string | Object);
    export let fontProps: FontProps = DefaultLabelFontProps();
    export let header: string;
    export let id: string = "chiplist";
    export let disabled: boolean = false;
    export let headerPaddingBottom: string = "";
    export let chipFontProps: FontProps = DefaultRobotoFontProps('0.625rem', '0.75rem', '#9d9d9d', 'white', 400);
    export let chipPadding: string = "";
    export let chipRadius: string = "";
    export let style: StaticChipListColor = StaticChipListColor.DARK_BLUE;
    let internalStyle = style.toString().toLowerCase().replace(/_/g, '-');


    /*
    This value states whether we want to see the select All button at all.
     */
    export let showSelectAll: boolean = false;
    let showSelectAllInternal: boolean = showSelectAll && (activeChips?.length ?? 0) < (list?.length ?? 1)


    function selectAll() {
        list.filter(chip => !activeChips?.includes(chip.value))
            .forEach(chip => {
                const eventDetails = {
                    selectedItem: {value: chip.value, label: chip.label},
                    identifier
                };
                activeChips = (activeChips || []).concat(chip.value);
                return dispatch('addItem', eventDetails);
            })
        showSelectAllInternal = false;
    }

    function toggleItem(value: string, label: string) {
        const eventDetails = {
            selectedItem: {value, label},
            identifier
        };

        // in case the item already is an active chip, remove it
        // we also catch the case that activeChips is nullish value, and jump in the else case then
        if (activeChips?.includes(value) ?? false) {
            activeChips = activeChips.filter(chip => chip !== value);
            dispatch('removeItem', eventDetails)
        } else {
            // otherwise, add it to the active chips
            activeChips = (activeChips || []).concat(value); // concats to activeChips or empty list, if activeChips is nullish
            dispatch('addItem', eventDetails)
        }

        showSelectAllInternal = showSelectAll && (activeChips?.length ?? 0) < (list?.length ?? 1)
    }

    let pad, radius, hpb;
    $: chipPadding, pad = chipPadding && chipPadding !== "" ? "--chip-padding: " + chipPadding + ";" : "";
    $: chipRadius, radius = chipRadius && chipRadius !== "" ? "--chip-radius: " + chipRadius + ";" : "";
    $: headerPaddingBottom, hpb = headerPaddingBottom !== "" ? "--padding-bottom: " + headerPaddingBottom + ";" : "";
    $: style, internalStyle = style.toString().toLowerCase().replace(/_/g, '-');
    $: activeChips, list, showSelectAllInternal = showSelectAll && (activeChips?.length ?? 0) < (list?.length ?? 1);

    /* Handling of the error message indication with a context store that is set on the parent including the form */
    export let errorPath: string = '';
    let formErrors: Writable<FormError[]> | null = hasContext('formErrors') ? getContext('formErrors') : null;
    let error: string;
    $: if (errorPath && errorPath !== '' && $formErrors != null) {
        let paths = errorPath.includes('||') ? errorPath.split('||') : [errorPath];
        error = "";
        paths.forEach(path => {
            if (path.includes('.*')) {
                error += isPathInErrorList($formErrors, path) ? getPathErrorMessage($formErrors, path) : '';
            } else {
                error += isExactPathInErrorList($formErrors, path) ? getExactPathErrorMessage($formErrors, path) : '';
            }

        })
    }

</script>


<div class="chip-list-container">
    {#if header && header !== ""}
        <span style="{hpb}{getFontPropsVarString(fontProps)}">{header}</span>
    {/if}
    <div class="chip-list" style="{getFontPropsVarString(chipFontProps)}">
        {#each list.filter(i => i.value !== "") as {value, label, msg}, i}
            {#if msg}
                <Tooltip {msg} placementTtip={TooltipPlacements.BOTTOM_START}>
                    <div id={`${id}-chip-${i}`} class="chip chip-{i} {internalStyle}"
                         class:active={activeChips?.includes(value)}
                         on:click={() => toggleItem(value, label)} style="{pad}{radius}">
                        {#if (activeChips?.includes(value))}
                            <div class="button-icon icon-checkmark-bold whiteIcon" class:icon-filter-disabled={disabled}
                                 style="--icon-size: 24px"></div>
                        {/if}
                        <span>{label}</span>
                    </div>
                </Tooltip>
            {:else}
                <div id={`${id}-chip-${i}`} class="chip chip-{i} {internalStyle}"
                     class:active={activeChips?.includes(value)}
                     on:click={() => toggleItem(value, label)} style="{pad}{radius}">
                    {#if (activeChips?.includes(value))}
                        <div class="button-icon icon-checkmark-bold whiteIcon" class:icon-filter-disabled={disabled}
                             style="--icon-size: 24px"></div>
                    {/if}
                    <span>{label}</span>
                </div>
            {/if}

        {:else}
            <i style="font-size: 0.875rem">Keine Filter vorhanden.</i>
        {/each}
        {#if showSelectAllInternal}
            <div id={`${id}-chip-selectAll`} class="selectAll">
                <span on:click={selectAll}>{$t('UI.staticChipList.selectAll')}</span>
            </div>

        {/if}
    </div>
</div>
<AnimatePresence list={error && error !== '' ? [{ key: 1 }] : []}>
    <Motion let:motion={collapse} initial="collapsed" animate="open" exit="collapsed"
            variants={openCloseVariants}>
        <div style="width: 100%; padding-top: .31rem;">
            <span class="form-field-error" use:collapse>{@html error}</span>
        </div>
    </Motion>
</AnimatePresence>


<style lang="scss">
  @use "../../styles/global";
  @use "../../styles/buttons";
  @import "../../styles/inputFields";

  .chip-list-container {
    @include global.flex-col(0.375rem, $alignment: flex-start);
    width: 100%;

    & span {
      @include global.roboto-font(var(--line-height), var(--font-weight), var(--font-size), var(--color));
    }

    & > span {
      padding-bottom: var(--padding-bottom);
    }

    & div.chip-list {
      @include global.flex-row(0.375rem, $justify: flex-start);
      flex-wrap: wrap;
      row-gap: 0.375rem;
    }

    & div.chip {
      height: 32px;
      @include global.flex-row(0.25rem, $alignment: center);
      border-radius: 1.25rem;
      padding: var(--chip-padding, 0.25rem .75rem 0.25rem .75rem);
      cursor: pointer;


      & > span {
        line-height: revert;
        margin-top: 2px;
      }
    }
  }

  .light-blue {
    background-color: rgba(86, 162, 186, 0.3);
    --color: #56A2BA;
    border: 1px solid #56A2BA;

    &.active {
      background-color: #56A2BA;
      --color: white;
    }
  }

  .dark-blue {
    background-color: global.$secondaryDarkBlue-30;
    --color: #487EBF; // somehow we cannot set variables to the value of constants, that's why we hardcode it here
    border: 1px solid global.$secondaryDarkBlue;

    &.active {
      --color: white;
      background-color: global.$secondaryDarkBlue;
    }
  }

  .green-icon {
    background-color: global.$primaryGreen;
    border-radius: 4px;
    margin-right: 0.375rem;
    width: 24px;
    height: 24px;
    @include global.flex-col();
  }

  .selectAll {
    text-align: center;
    margin-left: 0.75rem;

    & > span {
      @include global.roboto-font(0.625rem, 500, 0.75rem, global.$primaryGreen);

      &:hover {
        cursor: pointer;
      }
    }
  }

</style>
