<script lang="ts">

    import {
        DefaultSubstituteMatrixData,
        MatchType,
        type SubstituteData,
        type SubstituteMatrixData,
        type SubstituteType
    } from "./types/substitutes";
    import {getContext, onMount} from "svelte";
    import {createTippy} from "svelte-tippy";
    import {fetchUtils} from "../utils/fetchUtils";
    import AutocompleteInputField from "../autocomplete/AutocompleteInputField.svelte";
    import {AutocompleteConfig, type AutocompleteFieldConfig} from "../types/autocompleteFieldConfig";
    import {DefaultInputFieldProps, type FontProps} from "../types/fontProps";
    import {t} from "../../i18n/i18n";
    import type {ExtendedMaterial, Material} from "./types/material";
    import {type Writable} from "svelte/store";

    let substituteMatrixData: SubstituteMatrixData = DefaultSubstituteMatrixData();
    let substituteTypeId: string = "";
    export let substituteType: SubstituteType;
    let substituteTypes: SubstituteType[] = [];
    let showSubstituteTableData: boolean = true; // this is need because svelte reactivity doesn't trigger changes by allVisibleNodes size

    const fontProps: FontProps = DefaultInputFieldProps();

    const SUBSTITUTE_FORM_ERROR_PATH_PREFIX = 'substitute.'
    let substituteTypeOptions = substituteTypes.map(type => ({text: type.name, value: type}));

    let typeAcConfig: AutocompleteFieldConfig = AutocompleteConfig('value', 'text');

    let allExtendedMaterials: Writable<ExtendedMaterial[]> = getContext('extended-materials');
    let allNodes: Map<string, Material> = new Map();
    let allVisibleNodes: Map<string, string>;
    let allNodeNames: Map<string, string>;

    onMount(async () => {
        await fetchSubstituteTypes();
    })

    function handleExtendedMaterialsChange() {
        [...$allExtendedMaterials.values()].forEach(material => {
            allNodes.set(material.id, material);
        });
        $allExtendedMaterials = $allExtendedMaterials;
        updateVisibleNodesTableData();
    }


    function updateVisibleNodesTableData() {
        allVisibleNodes = new Map();
        allNodeNames = new Map();

        for (let [key, value] of allNodes) {
            // build table only if there are substitutes
            if (substituteMatrixData) {
                if (substituteMatrixData.substitutes.has(key)) {
                    substituteMatrixData.substitutes.get(key)?.forEach((substituteData: SubstituteData) => {
                        allVisibleNodes.set(substituteData.candidateMaterialId, <string>allNodes.get(substituteData.candidateMaterialId)?.parentCode + allNodes.get(substituteData.candidateMaterialId)?.code);
                        allNodeNames.set(substituteData.candidateMaterialId, substituteData.candidateMaterial.name);
                    })
                    allVisibleNodes.set(key, value.parentCode + value.code)
                    let substituteData = substituteMatrixData.substitutes.get(key)[0];
                    allNodeNames.set(substituteData.referenceMaterialId, substituteData.referenceMaterial.name);
                }
            }
        }
        showSubstituteTableData = true;
    }

    function addTooltip(content, id) {
        let tippy = createTippy({
            interactive: true,
            placement: 'right',
            maxWidth: 4000,
            theme: 'mmttip',
            content: content,
            hideOnClick: false,
            delay: [0, 200],
            allowHTML: true,
            appendTo: () => document.body
        });

        const idTest = '#' + id
        tippy(idTest)
    }

    async function fetchSubstituteTypes() {
        await fetchUtils.get('/api/substitutes/types').then(data => {
            Object.assign(substituteTypes, data);
            if (substituteTypes.length > 0) {
                substituteType = substituteTypes[0];
                // update selected lot type
                substituteTypeOptions = substituteTypes.map(type => ({text: type.name, value: type}));
                substituteTypeId = substituteType.publicId;
                // if lot type is present start fetching substitute data of selected lot type
                fetchSubstituteMatrixData();
            }
        }).catch(fetchUtils.catchErrorAndShowNotification())
    }

    async function fetchSubstituteMatrixData() {
        showSubstituteTableData = false;
        await fetchUtils.get('/api/substitutes/matrix/' + substituteTypeId).then(data => {
            Object.assign(substituteMatrixData, data);
            // this is needed because substituteMatrixData.substitutes is not allowing to iterate
            const substituteMap = new Map(Object.entries(substituteMatrixData.substitutes));
            substituteMatrixData.substitutes = new Map();
            for (let [key, value] of substituteMap.entries()) {
                substituteMatrixData.substitutes.set(key, value);
            }
            updateVisibleNodesTableData();
        }).catch(fetchUtils.catchErrorAndShowNotification());
    }

    function updateSubstituteMatchDataInTable() {
        allVisibleNodes = allVisibleNodes;
        allNodeNames = allNodeNames;
        let table = document.getElementById('substitute-table');
        if (substituteMatrixData) {
            // update nodes with same id in table by add match type match
            for (let [key, value] of allVisibleNodes) {
                const materialId = key;
                let row = document.getElementById('row-' + materialId);
                let column = document.getElementById('col-' + materialId);
                if (column) {
                    const colIndex = column.cellIndex;
                    const rowIndex = row.rowIndex;
                    table.rows[rowIndex].cells[colIndex].innerHTML = $t('UI.MaterialData.substitutes.table.matchType.MATCH');

                    //set tooltip for material numbers
                    addTooltip(allNodeNames.get(key), table.rows[rowIndex].cells[0].id)
                    addTooltip(allNodeNames.get(key), table.rows[0].cells[colIndex].id)
                }
            }
            // iterate through substitute data and update substitutes with tooltip and match type tag
            for (let [key, value] of substituteMatrixData.substitutes) {
                const materialId = key;
                // then iterate through array list of substituteData
                for (let substituteData of value) {
                    // for each substitute data find row of substitute map id and col of substitute match id
                    let row = document.getElementById('row-' + materialId);
                    let column = document.getElementById('col-' + substituteData.candidateMaterialId);
                    if (column) {
                        const colIndex = column.cellIndex;
                        const rowIndex = row.rowIndex;
                        table.rows[rowIndex].cells[colIndex].innerHTML = $t(`UI.MaterialData.substitutes.table.matchType.${MatchType[MatchType[substituteData.type]]}`);
                        table.rows[rowIndex].cells[colIndex].id = 'match-ref-' + substituteData.candidateMaterialId + substituteData.referenceMaterialId
                        addTooltip(JSON.stringify(substituteData).replaceAll(',', ', <br>'), table.rows[rowIndex].cells[colIndex].id)
                    }
                }
            }
        }
    }

    $: substituteType, substituteTypeId = substituteType?.publicId;

    $: if (allNodes.size > 0) {
        updateVisibleNodesTableData();
    }

    $: $allExtendedMaterials, handleExtendedMaterialsChange();

</script>

<div>
    <div style="width: 20rem;padding-bottom: 2rem">
        <AutocompleteInputField label="Type Dropdown" bind:value={substituteType} {fontProps}
                                placeholder="Substitut Typen" options={substituteTypeOptions}
                                autocompleteConfig={typeAcConfig} hideDropdownArrow={false}
                                errorPath={SUBSTITUTE_FORM_ERROR_PATH_PREFIX + 'type'}
                                on:change={fetchSubstituteMatrixData}/>
    </div>

    <div class="wrapper-substitute-table scrollable">
        {#if allVisibleNodes?.size > 0 && substituteType && showSubstituteTableData}
            <table id="substitute-table" use:updateSubstituteMatchDataInTable>
                <thead>
                <tr>
                    <td></td>
                    {#each [...allVisibleNodes.keys()] as nodeId, i}

                        <th scope="col" id="{'col-' + nodeId}">{allVisibleNodes.get(nodeId)}</th>

                    {/each}
                </tr>
                </thead>
                <tbody>
                {#each [...allVisibleNodes.keys()] as nodeId, i}
                    <tr id="{'row-' + nodeId}" class="substitute-rows">
                        <th id="{'row-headercell-' + nodeId}" scope="row">{allVisibleNodes.get(nodeId)}</th>

                        {#each Array(allVisibleNodes.size) as _, i}
                            <td>{'-'}</td>
                        {/each}

                    </tr>
                {/each}
                </tbody>
            </table>
        {/if}
    </div>
</div>

<style lang="scss">
  @import "../../styles/global";

  table, th, td {
    @include roboto-font(0.5rem, 400, 0.7rem, black);
    border: 0.15rem solid black;
    border-collapse: collapse;
  }

  td {
    min-width: 3rem;
    height: 3rem;
    @include roboto-font(0.5rem, 500, 0.8rem, black);
  }

  th {
    padding: 0 0.2rem 0 0.2rem;
  }

  .wrapper-substitute-table {
    width: fit-content;
    max-width: 100%;
    aspect-ratio: 1;
    overflow: auto;
    display: flex;
    padding: 2rem;
    border: 1px solid grey;

  }

  .scrollable::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  .scrollable::-webkit-scrollbar-track {
    background: #F0F2F8;
    border-radius: 4px;
    margin-top: 1px;
    margin-bottom: 1px;
    margin-right: 12px;
  }

  /* Handle */
  .scrollable::-webkit-scrollbar-thumb {
    background: #58C079;
    border-radius: 4px;
  }

</style>