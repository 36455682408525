import {type Writable, writable} from "svelte/store";
import type {JwtPayload} from "jsonwebtoken";
import LEP from "logical-expression-parser";
import {userIsLoggedIn} from "../stores";

export const jwt: Writable<JwtPayload> = writable({});

const url: string = "/api/jwt";

function parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload) as JwtPayload;
}

export async function getJwt() {
    const response = await fetch(url);
    const json = await response.json();
    if (json.success) {
        let data = parseJwt(json.token);
        jwt.update(_ => data);
        userIsLoggedIn.set(true);
    } else {
        userIsLoggedIn.set(false);
    }
}

// Top-level await is not available in the configured target environment ("chrome64", "edge79", "es2020", "firefox67", "safari12")
// noinspection JSIgnoredPromiseFromCall
getJwt();
