<script lang="ts">
    import {fetchUtils} from "../utils/fetchUtils";
    import type {Writable} from "svelte/store";
    import {getContext, onMount} from "svelte";
    import {defaultTenantColors, type TenantColors} from "./tenantColors";
    import type {PubPageParams} from "../bid/types/pubPageParams";

    const urlParams: PubPageParams = getContext("urlParams") || null;
    const isSaasInternalLot: Writable<boolean> = getContext("isSaasInternalLot");

    export let requestPublicId: string | undefined = urlParams?.requestPublicId;

    let colors: TenantColors = {...defaultTenantColors};
    let themedDiv: HTMLElement;

    async function fetchThemeProps() {
        if ($isSaasInternalLot && requestPublicId) {
            await fetchUtils.get(`/api/cd/colors/request/${requestPublicId}`, false)
                .then((data: TenantColors) => {
                    colors = {...defaultTenantColors, ...data};
                })
                .catch(e => {
                    colors = {...defaultTenantColors};
                    // ignore
                    console.log("Error while fetching theme props: " + e.message);
                });
        }
    }

    onMount(() => {
        // Trigger reactivity after themable-div is mounted. No need to fetch again, this is done after quotePubId is set.
        colors = colors;
    })

    $: if ($isSaasInternalLot && requestPublicId) {
        fetchThemeProps();
    }

    $: if (colors && themedDiv) {
        for (const [k, v] of Object.entries(colors)) {
            if (v) {
                themedDiv.style.setProperty("--c-" + k, v);
            }
        }
    }

</script>

<div id="themable-div" bind:this={themedDiv}>
    <slot/>
</div>
