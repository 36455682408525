<script lang="ts">

    import {t} from "../../i18n/i18n.js";
    import Tooltip from "../atoms/Tooltip.svelte";
    import paragraphIcon from '@/icons/icon_paragraph.svg';
    import {TooltipPlacements} from "../types/enums";

    export let footnote = "";

    let footnoteInfo = $t("UI.footnote.info");

</script>

<div>
    {#if (footnote !== "")}
        <Tooltip msg={footnoteInfo + footnote} placementTtip={TooltipPlacements.BOTTOM_START} ttipIconImage={paragraphIcon}
                 isHtml/>
    {:else}
        <div style="width: 24px; height: 24px"></div>
    {/if}
</div>

<style lang="scss">
  @import "../../styles/global";
</style>