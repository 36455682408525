<script context="module" lang="ts">
    export enum inputTypes {
        PRICE = "price",
        TONNAGE = "tonnage",
        OTHER = "other"
    }
</script>
<script lang="ts">
    import {formatNumber} from "../utils/formatters";
    import Tooltip from "./Tooltip.svelte";
    import {TooltipPlacements} from "../types/enums";
    import attentionIcon from "@/images/attentionIcon.svg"

    export let title: string = '';
    export let value: string = '';
    export let ttText: string = '';
    export let unit: string = "";
    export let type: inputTypes = inputTypes.OTHER;
    export let isOutdated: boolean = false;
    export let tooltipMessage: string = '';
    export let isHtml = false;

</script>


<div class="container-header-value">
    <div>
        <span>{title}</span>
        {#if ttText !== undefined && ttText !== ""}
            <div>
                <Tooltip msg={ttText} {isHtml}/>
            </div>
        {/if}
    </div>
    {#if !value || value === "" || (isNaN(value) && value.includes("undefined")) || value.toString().includes("undefined")}
        <span>-</span>
    {:else}
        {#if type === inputTypes.PRICE}
            <span>{@html formatNumber(value.toString())} {unit}</span>
        {:else if type === inputTypes.TONNAGE}
            {#if isOutdated}
                <div>
                    <span style="color:#C05858;font-weight: 600;margin-top: 1.5px">{@html formatNumber(value.toString()).replace(',00', '')} {unit}</span>
                    <Tooltip msg={tooltipMessage} placementTtip={TooltipPlacements.TOP_START} ttipIconImage={attentionIcon}
                             delayTtip={[2500,200]} isHtml showOnCreate/>

                </div>
            {:else}
                <span>{@html formatNumber(value.toString()).replace(',00', '')} {unit}</span>
            {/if}
        {:else}
            <span>{@html value} {unit}</span>
        {/if}
    {/if}

</div>


<style lang="scss">
  @import "../../styles/dataMapping";
  @import "../../styles/icons";
</style>
